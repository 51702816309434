/* eslint-disable */
import React, { Component } from 'react'
import style from './style.css'
import { withStyles } from '@material-ui/core/styles';
import CustomizedExpansionPannel from '../../custom-components/CustomizedExpansionPanel/index'
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import RefreshButton from '../../custom-components/RefreshButton'
import LogisticService from '../../core/services/LogisticService'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import TableCell from '@material-ui/core/TableCell';
import MaterialTable from 'material-table';
import flashgreen from './GreenFlash.png'


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#37B766',
    color: 'white',
    fontSize: '0.9em',
  },
  body: {
    fontSize: '0.8em'
  },
}))(TableCell);


class TheFlash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stonecode: '',
      loading: false,
      lastUpdated: undefined,
      selectedDistrict: 'Distrito Geral'
    };


    this.refreshReportData = this.refreshReportData.bind(this)
  }

  refreshReportData() {
    const {today} = this.state
    this.setState({
      // loading: true,
      lastUpdated: undefined,
      accessNoPendencies: {
        graph: undefined,
        loaded: false
      },
    })

    LogisticService.getResumeTheFlash('Geral').then((resume) => {
        this.setState({
        resumeTable: resume.data
        })
    })

    LogisticService.getOSListTheFlash('Geral').then((resume) => {
        this.setState({
        osListTable: resume.data
        })
    })

    LogisticService.getAngelsListTheFlash('Geral').then((resume) => {
        this.setState({
        angelsListTable: resume.data
        })
    })

  }

  componentDidMount() {
    this.refreshReportData()
  }

  inputBlock() {
    return (
      <Col sm="12" lg="8">
        <Form.Group as={Col} controlId="formGridState" >
          <Form.Control id="region" as="select" onChange = {e => this.handleChange(e.target.value)}>
            <option value="Geral">Geral</option>
            <option value="LAGOS">Distrito LAGOS</option>
            <option value="BH">Distrito BH</option>
            <option value="RJC1">Distrito RJC1</option>
            <option value="OSP">Distrito OSP</option>
            <option value="MTMS">Distrito MTMS</option>
            <option value="TM">Distrito TM</option>
            <option value="RJE">Distrito RJE</option>
            <option value="NSP">Distrito NSP</option>
            <option value="BA">Distrito BA</option>
            <option value="BA2">Distrito BA2</option>
            <option value="CAMPINAS">Distrito CAMPINAS</option>
            <option value="CB">Distrito CB</option>
            <option value="CB2">Distrito CB2</option>
            <option value="CETE">Distrito CETE</option>
            <option value="CIP">Distrito CIP</option>
            <option value="DF">Distrito DF</option>
            <option value="DNA">Distrito DNA</option>
            <option value="ES">Distrito ES</option>
            <option value="DF">Distrito DF</option>
            <option value="GO">Distrito GO</option>
            <option value="NORTE">Distrito NORTE</option>
            <option value="NP">Distrito NP</option>
            <option value="NRS">Distrito NRS</option>
            <option value="NSC">Distrito NSC</option>
            <option value="OP">Distrito OP</option>
            <option value="OSC">Distrito OSC</option>
            <option value="PB">Distrito PB</option>
            <option value="PE">Distrito PE</option>
            <option value="PE2">Distrito PE2</option>
            <option value="RJC2">Distrito RJC2</option>
            <option value="RJC3">Distrito RJC3</option>
            <option value="RN">Distrito RN</option>
            <option value="SEAL">Distrito SEAL</option>
            <option value="SMG">Distrito SMG</option>
            <option value="SP CENTRO">Distrito SP CENTRO</option>
            <option value="SP EXTREMO OESTE">Distrito SP EXTREMO OESTE</option>
            <option value="SP LESTE">Distrito SP LESTE</option>
            <option value="SP NOROESTE">Distrito SP NOROESTE</option>
            <option value="SP SUL">Distrito SP SUL</option>
            <option value="SRS">Distrito SRS</option>
            <option value="SSC">Distrito SSC</option>
            <option value="VI">Distrito VI</option>
            <option value="VP INTERIOR">Distrito VP INTERIOR</option>
            <option value="VP LITORAL">Distrito VP LITORAL</option>
            <option value="SEAL">Distrito SEAL</option>
          </Form.Control>
        </Form.Group>
      </Col>
    )}

  ScoreCard(props) {

    var color='black'

      return (
        <Card>
          <CardContent>
            <label style={{color: 'grey'}}>
              {props.title}
            </label>
            <Row style={{marginLeft: '2em', marginTop: '1em'}}>
              {props.icon}
              <Typography variant="h4" style={{marginLeft: '1em', color: color}}>
                {props.realScore}
              </Typography>
            </Row>
          </CardContent>
        </Card>
      );
    }


  handleChange(response){
    this.setState({selectedDistrict: `Distrito ${response}`})
  }

  SummaryBoxes() {

    if (this.state.resumeTable == undefined) {
      return
    }
     if(this.state.resumeTable.data[0]['arrivalTime'] == null){
      var time = 0
     }
     else{
      var time = this.state.resumeTable.data[0]['arrivalTime']
     }



    return(
    <Box>
      <Row>
        <Col xl sm = {12} md lg xl = {2}>
          <Row style={{marginLeft: '2em', marginTop: '1em'}}>
            {<i class="material-icons greenFlash">bolt</i>}
          </Row>
        </Col>
        <Col xl sm = {12} md lg xl = {2}>
          <this.ScoreCard
            title="Abertas Agora"
            icon={<MotorcycleIcon fontSize="large"/>}
            realScore={`${this.state.resumeTable.data[0]['open']}`}
            />
        </Col>
        <Col xl sm = {12} md lg xl = {2}>
          <this.ScoreCard
            title="Total Feito"
            icon={<CheckCircleIcon fontSize="large"/>}
            realScore={`${this.state.resumeTable.data[0]['done']}`}
            />
        </Col>
        <Col xl sm = {12} md lg xl = {2}>
          <this.ScoreCard
            title="SLA"
            icon={<InsertEmoticonIcon fontSize="large"/>}
            realScore={`${(this.state.resumeTable.data[0]['onSla']*100/this.state.resumeTable.data[0]['done']).toFixed(1)}`}
            />
        </Col>
        <Col xl sm = {12} md lg xl = {3}>
          <this.ScoreCard
            title="Tempo Médio (Dentro do SLA)"
            icon={<AccessTimeIcon fontSize="large"/>}
            realScore={`${time}`}
          />
        </Col>
      </Row>
    </Box>
    )
  }

  introducingBox(){
    return(
    <Box>
      <Row>
        <Col xl sm = {12} md lg xl = {3}>
          {this.inputBlock()}
        </Col>
      </Row>
      <Row>
        <Col xl sm = {12} md lg xl = {5}>
        </Col>
        <Col xl sm = {12} md lg xl = {1}>
        </Col>
      </Row>
    </Box>
  )}

  Tables(){
    return(
    <Box>
      <Row>
      <Col xl sm = {12} md lg xl = {4}>
        {this.buildAngelsTable()}
      </Col>
        <Col xl sm = {12} md lg xl = {8}>
          {this.buildNewOSsTable()}
        </Col>
      </Row>
    </Box>
  )}

  buildAngelsTable(){
      var response = ''
      let rows = []
      if(this.state.angelsListTable!= undefined){
        this.state.angelsListTable.data.forEach(element => {
          if(element.district==this.state.selectedDistrict || this.state.selectedDistrict=='Distrito Geral'){
            rows.push({
              angel: element.technicianName,
              os: element.os
            })
          }
        })
      }

  return (
      <MaterialTable
      title="Avanços"
      columns={[
        { title: 'Angel', field: 'angel', type: 'numeric', cellStyle: {fontSize: '0.8em'}},
        { title: 'OSs', field: 'os',  cellStyle: {fontSize: '0.8em', whiteSpace: 'nowrap'}}
      ]}
      data={rows}
      options={{
        showTitle: false,
        exportAllData: true,
        pageSize: 5,
        padding: 'dense',
        exportButton: true,
      }}
      localization={{
          toolbar: {
              searchPlaceholder: 'Pesquisar'
          },
          body: {
              emptyDataSourceMessage: 'Sem OSs para mostrar',
              filterRow: {
                  filterTooltip: 'Filtrar'
              }
          }
      }}
    />
    )
  }

  buildNewOSsTable(){
      var response = ''
      let rows = []
      if(this.state.osListTable != undefined){
        this.state.osListTable.data.forEach(element => {
          if(element.district==this.state.selectedDistrict || this.state.selectedDistrict=='Distrito Geral'){
            rows.push({
              os: element.orderNumber,
              opening: element.openingDate,
              status: element.status,
              service: element.serviceGroup,
              district: element.district,
              hub: element.provider,
              angel: element.technicianName
          })
          }
    })
      }

  return (
      <MaterialTable
      title="Avanços"
      columns={[
        { title: 'OS', field: 'os', type: 'numeric', cellStyle: {fontSize: '0.8em'}},
        { title: 'Data Abertura', field: 'opening',  cellStyle: {fontSize: '0.8em', whiteSpace: 'nowrap'}},
        { title: 'Status', field: 'status',  cellStyle: {fontSize: '0.8em', whiteSpace: 'nowrap'}},
        { title: 'Serviço', field: 'service',  cellStyle: {fontSize: '0.8em', whiteSpace: 'nowrap'}},
        { title: 'Distrito', field: 'district',  cellStyle: {fontSize: '0.8em', whiteSpace: 'nowrap'}},
        { title: 'Polo', field: 'hub',  cellStyle: {fontSize: '0.8em', whiteSpace: 'nowrap'}},
        { title: 'Angel', field: 'angel',  cellStyle: {fontSize: '0.8em', whiteSpace: 'nowrap'}},
      ]}
      data={rows}
      options={{
        showTitle: false,
        exportAllData: true,
        pageSize: 5,
        padding: 'dense',
        exportButton: true,
      }}
      localization={{
          toolbar: {
              searchPlaceholder: 'Pesquisar'
          },
          body: {
              emptyDataSourceMessage: 'Sem OSs para mostrar',
              filterRow: {
                  filterTooltip: 'Filtrar'
              }
          }
      }}
    />
    )
  }


  render() {

    let refreshContent
    let lastUpdatedText = ''

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format('DD/MM HH:mm')}`
    }

    if (!this.state.loading) {
      refreshContent = (
        <div style={{textAlign: 'right', marginBottom: '1em'}}>
          <label style={{margin:'1em'}}><i>{lastUpdatedText}</i></label>
          <RefreshButton onClick={() => { this.refreshReportData() }}/>
        </div>
      )
    }

    return (
      <div style={{backgroundImage: `url(${flashgreen}` }}>
      <Container fluid background-image={flashgreen}>
      {refreshContent}

      {this.introducingBox()}
      {this.SummaryBoxes()}
      <CustomizedExpansionPannel
        title='Detalhamento'
        content={this.Tables()}
      />
      <div>
      </div>
      </Container>
      </div>
    );
  }
}



export default TheFlash
