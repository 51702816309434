/* eslint-disable */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import './style.css'
import LogisticService from '../../core/services/LogisticService'
import RefreshButton from '../../custom-components/RefreshButton'
import MaterialTable from 'material-table'
import { Container, Col, Row } from 'react-bootstrap'
import LoadingSpinner from '../../components/LoadingSpinner'

class GestaoAtivos extends Component {
  constructor () {
    super()

    this.state = {
      loading: false,
      lastUpdated: undefined,
      oms: undefined,
    }

    this.refreshReportData = this.refreshReportData.bind(this)
  }

  componentDidMount () {
    this.refreshReportData()
  }

  refreshReportData () {
    this.setState({
      loading: true,
      lastUpdated: undefined,
      oms: undefined,
    })

    LogisticService.getTopAgingOMs(10).then((response) => {
      this.setState({
        loading: false,
        lastUpdated: moment(),
        oms: response.data.data.data
      })
    })
  }


  reportContent () {
    if (this.state.loading === true || !this.state.oms) {
      return (
        <h1>Carregando informações do report...</h1>
      )
    }

    return (
      <div>
        <h2 className={style.reportSectionHeader}>OMs pendentes com maior atraso</h2>
        <div>{this.renderTopAgingOMs()}</div>
      </div>
    )
  }

  organize_data (data) {
    let cols = [];
    let rows = [];
    data[1].forEach(function (col) {
        cols.push({field: col, title: col});
    });
    data.shift();
    data.shift();
    data.forEach(function (row) {
        let apartedRows = {};
        for (let i = 0; i < row.length; i++) {
            apartedRows[cols[i].field] = row[i];
        }
        rows.push(apartedRows);
    });
    return {rows: rows, cols: cols} ;
  };

  buildOMsTable() {
   
    if (!this.state.oms ) {
      return <div style={{textAlign:"center", margin: '5em'}}>
                <LoadingSpinner/>
              </div>
    }

    let rows = []

    this.state.oms.map(om => (
        rows.push({
          orderNumber: om.orderNumber, //status: om.status, 
          criationDate: om.criationDate, transitDate: om.transitDate, 
          transferType: om.transferType, origin: om.origin, 
          destination: om.destination, quantity: om.quantity
        })
      )
    )

      return (
        <MaterialTable 
        columns={[
          { title: 'Número da OM', field: 'orderNumber', type: 'numeric', cellStyle: {fontSize: '0.8em', width: '3em'}},
          // { title: 'Status', field: 'status', cellStyle: {fontSize: '0.8em', width: '10em'}},
          { title: 'Data de Criação', field: 'criationDate', type: 'date' , cellStyle: {fontSize: '0.8em', width: '10em'}},
          { title: 'Data de Trânsito', field: 'transitDate', type: 'date', cellStyle: {fontSize: '0.8em', width: '10em'}},
          { title: 'Tipo', field: 'transferType', cellStyle: {fontSize: '0.8em', width: '8em'}},
          { title: 'Quantidade', field: 'quantity', type: 'numeric', cellStyle: {fontSize: '0.8em', width: '5em'}},
          { title: 'Origem', field: 'origin', cellStyle: {fontSize: '0.8em', width: '15em'}},
          { title: 'Destino', field: 'destination', cellStyle: {fontSize: '0.8em', width: '25em'}}
        ]}
        data={rows}
        title="OMs em Trânsito"
        options={{
          exportAllData: true,
          pageSize: 10,
          padding: 'dense',
          exportButton: true,
          // filtering: true,
          maxBodyHeight: 500,
          columns: {
            cellStyle: {
              fontSize: '0.2em',  
              color: 'white'
            }
          },
          // headerStyle: {
          //   backgroundColor: '#103a21',
          //   color: 'white'
          // },
          rowStyle: rowData => ({
            backgroundColor: (rowData.tableData.id % 2) ? '#cfefc6' : '#FFF',
          })
        }
        }
        />
    )
  }


  render () {
    let refreshContent
    let lastUpdatedText = ''

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format('DD/MM HH:mm')}`
    }

    if (!this.state.loading) {
      refreshContent = (
        <div style={{textAlign: 'right', marginBottom: '1em'}}>
          <label style={{margin:'1em'}}><i>{lastUpdatedText}</i></label>
          <RefreshButton onClick={() => { this.refreshReportData() }}/>
        </div>
      )
    }
    return (
      <Container fluid>
        <Col lg="12" xs="12" sm="12">
        {refreshContent}
        {this.buildOMsTable()}
      </Col>
      </Container>
    )
  }
}

export default GestaoAtivos






