import React from 'react'
import Graph from '../../../components/Logistic/graphic/graph'
import Box from '@material-ui/core/Box';
import _ from 'lodash'
import moment from 'moment'
import '../style.css'


const StockHistoryGraph = props =>  {
      
        const {stockHistory} = props;

        if (!stockHistory) return null

        const graphObj = {
          data: {
            labels: [],
            datasets: [
              {
                label: 'Disponível CD',
                backgroundColor: '#27ae60',
                borderColor: '#2c3e50',
                borderWidth: 0.5,
                hoverBackgroundColor: '#27ae60',
                hoverBorderColor: '#27ae60',
                data: [],
                type: 'line',
                pointRadius: 0,
              },
              {
                label: 'Transp Base',
                backgroundColor: '#006400',
                borderColor: '#2c3e50',
                borderWidth: 0.5,
                hoverBackgroundColor: '#006400',
                hoverBorderColor: '#006400',
                data: [],
                type: 'line',
                pointRadius: 0,
              },
              {
                label: 'Disponível Base',
                backgroundColor: '#90ee90',
                borderColor: '#2c3e50',
                borderWidth: 0.5,
                hoverBackgroundColor: '#90ee90',
                hoverBorderColor: '#90ee90',
                data: [],
                type: 'line',
                pointRadius: 0,
              },
              {
                label: 'Ciclo Triagem',
                backgroundColor: '#c8a2c8',
                borderColor: '#2c3e50',
                borderWidth: 0.5,
                hoverBackgroundColor: '#c8a2c8',
                hoverBorderColor: '#c8a2c8',
                data: [],
                type: 'line',
                pointRadius: 0,
              },
              {
                label: 'Bad',
                backgroundColor: '#c0392b',
                borderColor: '#2c3e50',
                borderWidth: 0.5,
                hoverBackgroundColor: '#c0392b',
                hoverBorderColor: '#c0392b',
                data: [],
                type: 'line',
                pointRadius: 0,
              },
              {
                label: 'Ciclo Lab',
                backgroundColor: '#ffc300',
                borderColor: '#2c3e50',
                borderWidth: 0.5,
                hoverBackgroundColor: '#ffc300',
                hoverBorderColor: '#ffc300',
                data: [],
                type: 'line',
                pointRadius: 0,
              },
              {
                label: 'Divergência',
                backgroundColor: '#808080',
                borderColor: '#2c3e50',
                borderWidth: 0.5,
                hoverBackgroundColor: '#808080',
                hoverBorderColor: '#808080',
                data: [],
                type: 'line',
                pointRadius: 0,
              },
            ]
          },
          options: {
            responsive: undefined,
            maintainAspectRatio: false,
            tooltips: {
              mode: 'index',
              intersect: false,
            },
            scales: {
              xAxes: [{
                gridLines: {
                  display: false,
                },
              }],
              yAxes: [{
                stacked: true,
                ticks: {
                  suggestedMax: 180 * 1000,
                  min: 0,
                  callback: function (value) {
                               return (value/1000) + "k"
                             },
                },
              }],
            },
            title: {
              display: true,
              fontColor: 'black',
              fontSize: 20,
              text: 'Posição Cadeia',
              fontFamily: 'GoBold'
            },
            plugins: {
              datalabels: {
                font: {
                  size: 0,
                },
                color: 'black',
              },
            },
            pan: {
              enabled: true,
              mode: 'x',
              speed: 200.2
            },
            zoom: {
              enabled: true,
              mode: 'x',
              speed: 0.9
            }
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: 'black',
            },
          },
        }
    
        _.orderBy(stockHistory, 'date').forEach((element) => {
          graphObj.data.labels.push(moment(element.date).format('DD/MM/YYYY'))
          graphObj.data.datasets[6].data.push(element.divergencia)
          graphObj.data.datasets[5].data.push(element.cicloLabExclBad)
          graphObj.data.datasets[4].data.push(element.bad)
          graphObj.data.datasets[3].data.push(element.cicloTriagem)
          graphObj.data.datasets[2].data.push(element.disponivelBase)
          graphObj.data.datasets[1].data.push(element.TranspBase)
          graphObj.data.datasets[0].data.push(element.disponivelCd)
        })
      
        const isDesktop =  window.innerWidth > 1300

        return (
            <Box width={isDesktop ? "80%" : "100%"} height="100%">
              <Graph
                data={graphObj.data}
                legend={graphObj.legend}
                options={graphObj.options}
                width={isDesktop ? window.innerWidth/2.2 : 300}
                height={isDesktop ? 500 : 400}
            />
            </Box>
          )
    }

export default StockHistoryGraph