/* eslint-disable */
import React, { Component } from 'react'
import moment from 'moment'
import SummaryIdt from './SummaryIdt'
import LogisticService from '../../core/services/LogisticService'
import RefreshButton from '../../custom-components/RefreshButton'
import Graph from '../../components/Logistic/graphic/graph'
import LoadingSpinner from '../../components/LoadingSpinner'
import { Container, Col, Row, Form } from 'react-bootstrap'
import { Statistic, Icon, Card} from 'antd'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import CustomizedExpansionPannel from '../../custom-components/CustomizedExpansionPanel/index'
import './style.css'
import Box from '@material-ui/core/Box';

const SimpleBox = props => {
  const { header, result, goal, direction, percentage } = props
  let headlightSymbol;
  let headlightColor;
  return (
      <Card>
        <Statistic
          title={<h3 style={{fontFamily: 'GoBold'}}>{header}</h3>}
          value={result + (percentage ? '%' : '')}
          valueStyle={{ color: {headlightColor} }}
        />
    </Card>
    )
}
class IDTReport extends Component {

  constructor () {
    super()

    this.state = {
      selectedDefect:'(POS) Bateria',
      selectedModel:'-1',
      selectedScreening:'-1',
      monthRankingGraph: {
        loaded: false
      },
      weekRankingGraph: {
        loaded: false
      },
      monthSparePartGraph: {
        loaded: false
      },
      monthDefectGraph: {
        loaded: false
      },
      weekDefectGraph: {
        loaded: false
      },
      weekSparePartGraph: {
        loaded: false
      },
      monthIdtTable:{
        loaded: false
      },
      weekIdtTable:{
        loaded : false
      },
      selectedScreening:'-1',
      idtValues:{
        loaded:false
      },
      onboardingValues:{
        loaded:false
      },
      onboardingScreening:{
        loaded:false
      },
      monthOnBoardingRankingGraph: {
        loaded: false
      },
      weekOnBoardingRankingGraph: {
        loaded: false
      },
      monthOBoardingIdtTable:{
        loaded: false
      },
      regionTable: undefined

    }

    this.refreshReportData = this.refreshReportData.bind(this)
  }

  clearStates(){
  this.setState({
    monthRankingGraph: {
        loaded: false,
        graph: undefined
      },
      weekRankingGraph: {
        loaded: false,
        graph: undefined
      },
      monthSparePartGraph: {
        loaded: false,
        graph: undefined
      },
      monthDefectGraph: {
        loaded: false,
        graph: undefined
      },
      weekDefectGraph: {
        loaded: false,
        graph: undefined
      },
      weekSparePartGraph: {
        loaded: false,
        graph: undefined
      },
      monthIdtTable:{
        loaded: false
      },
      weekIdtTable:{
        loaded : false
      },
      idtValues:{
        data:false,
        loaded:false
      },
      onboardingValues:{
        data:false,
        loaded:false
      },
      onboardingScreening:{
        loaded:false
      },
      monthOnBoardingRankingGraph: {
        loaded: false,
        graph: undefined
      },
      weekOnBoardingRankingGraph: {
        loaded: false,
        graph: undefined
      },
      monthOBoardingIdtTable:{
        loaded: false,
        graph: undefined
      }
    })
  }

  clearDefect(){
    this.setState({
      
        monthDefectGraph: {
          loaded: false,
          graph: undefined
        },
        weekDefectGraph: {
          loaded: false,
          graph: undefined
        },
      })
    }

  componentDidMount() {
    this.refreshReportData(this.state.selectedModel,this.state.selectedDefect)
  }

  refreshDefect(model,defect) {
    const today = new Date();
    const date = `${(today.getFullYear() - 1)}-${("0" + (today.getMonth() + 1)).slice(-2)}-01`;

    LogisticService.getIdtDefect(model,date,defect,'week')
    .then((defect_response) => {
      this.buildWeekDefectGraph(defect_response.data.data)
    })

    LogisticService.getIdtDefect(model,date,defect,'month')
    .then((defect_response) => {
      this.buildMonthDefectGraph(defect_response.data.data)
    })
  }

  refreshReportData(model,defect) {
    const today = new Date();
    const date = `${(today.getFullYear() - 1)}-${("0" + (today.getMonth() + 1)).slice(-2)}-01`;

    this.state.loading = true
    this.state.lastUpdated = undefined

    LogisticService.getIdtSparePart(date,model,'month')
    .then((response) => {
      this.buildMonthSparePartGraph(response.data.data)
    })

    LogisticService.getIdtSparePart(date,model,'week')
    .then((response) => {
      this.buildWeekSparePartGraph(response.data.data)
    })

    LogisticService.getIdtDefect(model,date,null,'month')
    .then((response) => {
      this.setState({
        monthRankingGraph:{
          graph: this.buildMonthRankigGraph(response.data.data),
          loaded: true
        },
      })
    })

    LogisticService.getIdtDefect(model,date,null,'week')
    .then((response) => {
      this.setState({
        weekRankingGraph:{
          graph: this.buildWeekRankigGraph(response.data.data),
          loaded: true
        },
      })
    })

    LogisticService.getIdtDefect(model,date,defect,'week')
    .then((defect_response) => {
      this.buildWeekDefectGraph(defect_response.data.data)
    })

    LogisticService.getIdtDefect(model,date,defect,'month')
    .then((defect_response) => {
      this.buildMonthDefectGraph(defect_response.data.data)
    })

    LogisticService.getIDTSummary(model,'months')
    .then((response) => {
      this.setState({
        monthIdtTable:{
          data : response.data.data,
          loaded : true
        }
      })
    })

    LogisticService.getIDTSummary(model,'week')
    .then((response) => {
      this.setState({
        weekIdtTable:{
          data : response.data.data,
          loaded : true
        }
      })
    })

    LogisticService.getIdtDefect('-1',date,null,'month')
    .then((response) => {
      this.setState({
        idtValues:{
          data:response.data.data,
          loaded:true
        }
      })

    LogisticService.getIDTOnBoarding(date,'week')
    .then((onBoarding) => {
      this.setState({
        weekOnBoardingRankingGraph:{
          graph: this.buildWeekOnboardingRankigGraph(onBoarding.data.data),
          loaded: true
        },
      })
    })

    LogisticService.getIDTOnBoarding(null,null)
    .then((onBoarding) => {
      this.setState({
        monthOBoardingIdtTable:{
          data   : onBoarding.data.data,
          loaded : true
        }
      })
    })

    LogisticService.getIDTOnBoarding(null,'screening')
    .then((onBoarding) => {
      this.setState({
        onboardingScreening:{
          data   : onBoarding.data.data,
          loaded : true
        }
      })
    })

      LogisticService.getIDTOnBoarding(date,'month')
      .then((onBoarding) => {
        this.setState({
          onboardingValues:{
            data:onBoarding.data.data,
            loaded:true
          },
        })
        this.buildMonthOnboardingRankigGraph(response.data.data,onBoarding.data.data)
      })
    })

  }

  //Filter Defect
  inputDefect () {
    return (
      <Form>
            <Form.Group as={Col} controlId="defect" >
              <Form.Label>Defeito</Form.Label>
              <Form.Control as="select" 
              value={this.state.selectedDefect}
              onChange = {e => this.updateDefect()}>
              <option>{'(POS) Bateria'}</option>
              <option>{'(POS) Não Reconhece Nenhum Cartão'}</option>
              <option>{'(POS) Problemas/Perda do carregador'}</option>
              <option>{'(POS) Falha Permanente de Conexão 3G/GPRS'}</option>
              <option>{'Danificado pelo cliente'}</option>
              <option>{'(POS) Tamper (LLT ou Alert Inruption ou Nand Fail)'}</option>
              <option>{'Outros'}</option>
              <option>{'(POS) Travado Em Tela Específica'}</option>
              <option>{'(POS) Problemas de impressão'}</option>
              <option>{'Preferência Por Outro Modelo'}</option>
              <option>{'Teclado com defeito'}</option>
              <option>{'(POS) Touch com defeito'}</option>
              <option>{'Desliga no final da transação'}</option>
              <option>{'(POS) Impressora não reconhece bobina'}</option>
              <option>{'(POS) Não Reconhece Nenhum Chip de Operadora'}</option>
              <option>{'(POS) Problema na Configuração do WIFI'}</option>
              <option>{'(POS) Falha Permanente de Conexão Ethernet'}</option>
              <option>{'(POS) Problema no cabo Lan (ethernet)'}</option>
              <option>{'POS sem chave (1026 recorrente)'}</option>
              </Form.Control>
            </Form.Group>
      </Form>
    )}

  updateDefect(){
    let defect = document.getElementById("defect")
    this.clearDefect()
    this.setState({
      selectedDefect : defect[defect.selectedIndex].text
    })
    this.refreshDefect(this.state.selectedModel,defect[defect.selectedIndex].text)
  }

    //Filter POS Model
    inputModel () {
      return (
        <Form>
              <Form.Group as={Col} controlId="model" >
                <Form.Label>Modelo POS</Form.Label>
                <Form.Control as="select" 
                value={this.state.selectedModel}
                onChange = {e => this.updateModel()}>
                  <option> {'Total'} </option>
                  <option> {'S920'} </option>
                  <option> {'D210N'} </option>
                  <option> {'A8'} </option>
                  <option> {'V240M'} </option>
                  <option> {'Q92'} </option>
                </Form.Control>
              </Form.Group>
        </Form>
      )}

    updateModel(){
      let model = document.getElementById("model")
      this.clearStates()
      this.setState({
        selectedModel : model[model.selectedIndex].text === 'Total'?'-1':model[model.selectedIndex].text
      })
      this.refreshReportData(model[model.selectedIndex].text === 'Total'?'-1':model[model.selectedIndex].text,this.state.selectedDefect)
    }


    buildMonthOnboardingRankigGraph(idt,onboarding) {
      var graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'IDT',
              stack: 'Stack 0',
              backgroundColor: '#c7c7c7',
              data: [],
              yAxisID: 'B',
              fill: false,
              display:false,
              
            },
            {
              label: 'PREFERÊNCIA',
              stack: 'Stack 0',
              backgroundColor: '#adadad',
              data: [],
              yAxisID: 'B',
              fill: false,
              display:false,
              
            },
            {
              label: 'SPARE PART',
              stack: 'Stack 0',
              backgroundColor: '#969696',
              data: [],
              yAxisID: 'B',
              fill: false,
              display:false,
              
            },
            {
              label: 'IDM',
              stack: 'Stack 0',
              backgroundColor: '#757575',
              data: [],
              yAxisID: 'B',
              fill: false,
              display:false,
              
            },
            {
              label: 'IDR',
              stack: 'Stack 0',
              backgroundColor: 'white',
              data: [],
              yAxisID: 'B',
              fill: false,
              display:false,
              
            },
            {
              label: 'IDT ONBOARDING',
              stack: 'Stack 1',
              backgroundColor: '#27e32d',
              data: [],
              yAxisID: 'B',
              fill: false,
              display:false,
              
            },
            {
              label: 'PREFERÊNCIA ONBOARDING',
              stack: 'Stack 1',
              backgroundColor: '#21b826',
              data: [],
              yAxisID: 'B',
              fill: false,
              display:false,
              
            },
            {
              label: 'SPARE PART ONBOARDING',
              stack: 'Stack 1',
              backgroundColor: '#1a8a1e',
              data: [],
              yAxisID: 'B',
              fill: false,
              display:false,
              
            },
            {
              label: 'IDM ONBOARDING',
              stack: 'Stack 1',
              backgroundColor: '#146117',
              data: [],
              yAxisID: 'B',
              fill: false,
              display:false,
              
            },
            {
              label: 'IDR ONBOARDING',
              stack: 'Stack 1',
              backgroundColor: 'white',
              data: [],
              yAxisID: 'B',
              fill: false,
              display:false,
              
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [
            {
              id: 'B',
              display:false,
              stacked: true,
              anchor: 'end',
              align: 'top',
              ticks: {
                min: 0,
                callback: function(value) {
                  return `${value.toFixed(2)}%`
                },
              },
            }
          ]
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: 'black',
            fontSize: 20,
            text: 'IDR Mensal',
          },
          plugins:{
            datalabels: {
              display: function (graphObj) {
                if(graphObj.dataset.label !== 'Meta')
                  return graphObj.dataset.data[graphObj.dataIndex] > 0
                else
                  return false
              },
              font: {
                size: 12,
                weight: 'bold'
              },
              position: 'bottom',
              align: 'center',
              color:'black',
              
            },
          },
    
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'black',
          },
        },
      }
      _.orderBy(idt, 'month').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.month))
          graphObj.data.datasets[0].data.push((100*element.total/element.activeTotal).toFixed(2))
          graphObj.data.datasets[1].data.push((100*element.change/element.activeTotal).toFixed(2))
          graphObj.data.datasets[2].data.push((100*element.sparepart/element.activeTotal).toFixed(2))
          graphObj.data.datasets[3].data.push((100*element.chip/element.activeTotal).toFixed(2))
          graphObj.data.datasets[4].data.push(((100*element.total/element.activeTotal)+(100*element.sparepart/element.activeTotal)+(100*element.change/element.activeTotal)+(100*element.chip/element.activeTotal)).toFixed(2))
        }
      )
      _.orderBy(onboarding, 'month').forEach((element) => {
          graphObj.data.datasets[5].data.push((100*element.idt/element.parqueNovo).toFixed(2))
          graphObj.data.datasets[6].data.push((100*element.change/element.parqueNovo).toFixed(2))
          graphObj.data.datasets[7].data.push((100*element.sparepart/element.parqueNovo).toFixed(2))
          graphObj.data.datasets[8].data.push((100*element.chip/element.parqueNovo).toFixed(2))
          graphObj.data.datasets[9].data.push(((100*element.idt/element.parqueNovo)+(100*element.sparepart/element.parqueNovo)+(100*element.change/element.parqueNovo)+(100*element.chip/element.parqueNovo)).toFixed(2))
        }
      )
      this.setState({
        monthOnBoardingRankingGraph:{
          graph: graphObj,
          loaded: true
        },
        lastUpdated : moment()
      })
    }

  buildWeekRankigGraph(obj) {
  var graphObj =
  {
    data: {
      labels: [],
      datasets: [
        {
          label: 'IDR',
          stack: 'Stack 1',
          type: 'line',
          backgroundColor: 'white',
          pointBorderColor: 'white',
          pointBackgroundColor: 'white',
          pointHoverBackgroundColor: 'white',
          pointHoverBorderColor: 'white',
          pointRadius : 0,
          showLine:false,
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'IDT',
          stack: 'Stack 0',
          backgroundColor: '#27e32d',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
        },
        {
          label: 'Preferência outro equipamento',
          stack: 'Stack 0',
          backgroundColor: '#21b826',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
        },
        {
          label: 'Spare Part',
          stack: 'Stack 0',
          backgroundColor: '#1a8a1e',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
        },
        {
          label: 'IDM',
          stack: 'Stack 0',
          backgroundColor: '#146117',
          data: [],
          yAxisID: 'B',
        }
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          categoryPercentage: 0.9,
          gridLines: {
            display: false,
          },
        }],
        yAxes: [
        {
          id: 'B',
          display:true,
          stacked: true,
          anchor: 'end',
          align: 'top',
          ticks: {
            min: 0,
            suggestedMax:this.state.selectedModel !== 'A8' && this.state.selectedModel !== 'V240M'?2:5,
            callback: function(value) {
              return `${value.toFixed(2)}%`
            },
          },
        },
          {
            id: 'A',
            display:false,
            stacked: false,
            anchor: 'top',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          }],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: true,
        fontColor: 'black',
        fontSize: 20,
        text: 'IDR Semanal',
      },
      plugins:{
        datalabels: {
          display: function (graphObj) { 
            if(graphObj.dataset.label !== 'Meta' && graphObj.dataset.label !== 'IDR')
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            else
              return false
          },
          font: {
            size: 12,
          },
          position: 'bottom',
          align: 'center',
          color:'black',
          formatter: function (value) {
            return value
          }
        },
      },

    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'black',
      },
    },
  }
  _.orderBy(obj, 'week').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.week))
        if(element.activeTotal !== 0){
          graphObj.data.datasets[1].data.push((100*element.total/element.activeTotal).toFixed(2))
          graphObj.data.datasets[2].data.push((100*element.change/element.activeTotal).toFixed(2))
          graphObj.data.datasets[3].data.push((100*element.sparepart/element.activeTotal).toFixed(2))
          graphObj.data.datasets[4].data.push((100*element.chip/element.activeTotal).toFixed(2))
          graphObj.data.datasets[0].data.push(((100*element.total/element.activeTotal)+(100*element.sparepart/element.activeTotal)+(100*element.change/element.activeTotal)+(100*element.chip/element.activeTotal)).toFixed(2))
        }
        else{
          graphObj.data.datasets[1].data.push(0)
          graphObj.data.datasets[2].data.push(0)
          graphObj.data.datasets[3].data.push(0)
          graphObj.data.datasets[4].data.push(0)
          graphObj.data.datasets[0].data.push(0)
        }
    }
  )

  return graphObj
}

renderWeekRankigGraph() {
  if (this.state.weekRankingGraph.loaded) {
    return (
      <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
        <Graph
          data={this.state.weekRankingGraph.graph.data}
          legend={this.state.weekRankingGraph.graph.legend}
          options={this.state.weekRankingGraph.graph.options}
          width={this.state.isDesktop ? 400 : 310}
          height={420}
        />
      </Box>)
  }

  return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
    <LoadingSpinner />
  </div>)
  }

buildMonthRankigGraph(obj) {
  var graphObj =
  {
    data: {
      labels: [],
      datasets: [
        {
          label: 'Meta',
          stack: 'Stack 1',
          type: 'line',
          borderColor: 'red',
          backgroundColor: 'red',
          pointBorderColor: 'red',
          pointBackgroundColor: 'red',
          pointHoverBackgroundColor: 'red',
          pointHoverBorderColor: 'red',
          pointRadius : 0,
          // showLine:false,
          data: [],
          fill: false,
          display:false,
          yAxisID: 'A',
          
        },
        {
          label: 'IDR',
          stack: 'Stack 2', 
          type: 'line',
          borderColor: '#27e32d',
          backgroundColor: '#27e32d',
          pointBorderColor: '#27e32d',
          pointBackgroundColor: '#27e32d',
          pointHoverBackgroundColor: '#27e32d',
          pointHoverBorderColor: '#27e32d',
          pointRadius : 0,
          data: [],
          yAxisID: 'A',
          fill: false,
          display:false,
          
        },
        {
          label: 'IDT',
          stack: 'Stack 0',
          backgroundColor: '#27e32d',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'Preferência outro equipamento',
          stack: 'Stack 0',
          backgroundColor: '#21b826',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'Spare Part',
          stack: 'Stack 0',
          backgroundColor: '#1a8a1e',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'IDM',
          stack: 'Stack 0',
          backgroundColor: '#146117',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          categoryPercentage: 0.9,
          gridLines: {
            display: false,
          },
        }],
        yAxes: [
        {
          id: 'B',
          display:true,
          stacked: true,
          anchor: 'end',
          align: 'top',
          ticks: {
            min: 0,
            suggestedMax:this.state.selectedModel !== 'A8'?7:20,
            callback: function(value) {
              return `${value.toFixed(2)}%`
            },
          },
        },
        {
          id: 'A',
          display:false,
          stacked: false,
          anchor: 'top',
          align: 'top',
          ticks: {
            min: 0,
            callback: function(value) {
              return `${value.toFixed(2)}%`
            },
          },
        }
      ]
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: true,
        fontColor: 'black',
        fontSize: 20,
        text: 'IDR Mensal',
      },
      plugins:{
        datalabels: {
          display: function (graphObj) {
            if(graphObj.dataset.label !== 'Meta' && graphObj.dataset.label !== 'IDR')
              return true
            else
              return false
          },
          font: {
            size: 12,
          },
          position: 'bottom',
          align: function (graphObj) {
            if(graphObj.dataset.label !== 'IDR')
              return 'center'
            else
              return 'top'
          },
          color:'black',
          fontSize:'bold',
        },
      },

    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'black',
      },
    },
  }
  _.orderBy(obj, 'month').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.month))
        graphObj.data.datasets[0].data.push(element.goal)
        graphObj.data.datasets[1].data.push(((100*element.total/element.activeTotal)+(100*element.sparepart/element.activeTotal)+(100*element.change/element.activeTotal)+(100*element.chip/element.activeTotal)).toFixed(2))
        graphObj.data.datasets[2].data.push((100*element.total/element.activeTotal).toFixed(2))
        graphObj.data.datasets[3].data.push((100*element.change/element.activeTotal).toFixed(2))
        graphObj.data.datasets[4].data.push((100*element.sparepart/element.activeTotal).toFixed(2))
        graphObj.data.datasets[5].data.push((100*element.chip/element.activeTotal).toFixed(2))        
    }
  )
  return graphObj
}

renderMonthRankigGraph() {
  if (this.state.monthRankingGraph.loaded) {
    return (
      <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
        <Graph
          data={this.state.monthRankingGraph.graph.data}
          legend={this.state.monthRankingGraph.graph.legend}
          options={this.state.monthRankingGraph.graph.options}
          width={this.state.isDesktop ? 400 : 310}
          height={420}
        />
      </Box>)
  }

  return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
    <LoadingSpinner />
  </div>)
  }

  buildMonthDefectGraph(obj) {
    var graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: `${obj[0].defect}`,
              stack: 'Stack 0',
              backgroundColor: '#008000',
              data: [],
              yAxisID: 'B',
              fill: false,
              display:false,
            }
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [
            {
              id: 'B',
              display:true,
              stacked: true,
              anchor: 'end',
              align: 'top',
              ticks: {
                min: 0,
                callback: function(value) {
                  return `${value.toFixed(2)}%`
                },
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: 'black',
            fontSize: 20,
            text: 'Top Defeitos',
          },
          plugins:{
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 12,
              },
              position: 'bottom',
              align: 'center',
              color: 'white',
              formatter: function (value) {
                return value
              }
            },
          },

        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'black',
          },
        },
      }

        _.orderBy(obj, 'month').forEach((element) => {
              graphObj.data.labels.push(element.month)
              graphObj.data.datasets[0].data.push((100*element.qtd/element.total).toFixed(2))
          })



    this.setState({
      monthDefectGraph:{
        graph: graphObj,
        loaded: true
      },
      lastUpdated : moment()
    })
  }

  renderMonthDefectGraph() {
    
      if(this.state.monthDefectGraph.loaded){return (
        <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
          <Graph
            data={this.state.monthDefectGraph.graph.data}
            legend={this.state.monthDefectGraph.graph.legend}
            options={this.state.monthDefectGraph.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={420}
          />
        </Box>)}
      else{
        return(
    <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)}
  
    }

    buildMonthSparePartGraph(obj) {
      var graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Troca de Bateria',
              stack: 'Stack 0',
              backgroundColor: '#008000',
              borderWidth: 2,
              data: [],
              hidden: false,
            },
            {
              label: 'Troca de Tampa(Bobina)',
              stack: 'Stack 0',
              backgroundColor: '#67a53b',
              borderWidth: 2,
              data: [],
              hidden: false,
            },
            {
              label: 'Troca de Carregador',
              stack: 'Stack 0',
              backgroundColor: '#8de053',
              borderWidth: 2,
              data: [],
              hidden: false,
            }
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              id: 'A',
              stacked: true,
              position: 'bottom',
              align: 'center',
              ticks: {
                min: 0,
                callback: function(value) {
                  return `${value}`
              },
              }
            },
            {
              id: 'B',
              display:false,
              stacked: true,
              anchor: 'end',
              align: 'top',
              ticks: {
                min: 0,
                callback: function(value) {
                  return `${value.toFixed(2)}%`
                },
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: 'black',
            fontSize: 20,
            text: 'Impacto Spare Parts',
          },
          plugins:{
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 12,
              },
              position: 'bottom',
              align: 'center',
              color: 'black',
              formatter: function (value) {
                return value
              }
            },
          },

        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'black',
          },
        },
      }

      _.orderBy(obj, 'referenceDate').forEach((element) => {
            graphObj.data.labels.push(" ".concat(element.referenceDate))
            graphObj.data.datasets[0].data.push((element.battery*100/element.max  ).toFixed(2))
            graphObj.data.datasets[1].data.push((element.bobina*100/element.max   ).toFixed(2))
            graphObj.data.datasets[2].data.push((element.recharge*100/element.max ).toFixed(2))
        }
      )


      this.setState({
        monthSparePartGraph:{
          graph: graphObj,
          loaded: true
        },
        lastUpdated : moment()
      })
    }
    
    renderMonthDefectSparePartGraph() {
      if (this.state.monthSparePartGraph.loaded) {
        return (
          <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
            <Graph
              data={this.state.monthSparePartGraph.graph.data}
              legend={this.state.monthSparePartGraph.graph.legend}
              options={this.state.monthSparePartGraph.graph.options}
              width={this.state.isDesktop ? 400 : 310}
              height={420}
            />
          </Box>)
      }
  
      return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
        <LoadingSpinner />
      </div>)
      }
  


      buildWeekSparePartGraph(obj) {
        var graphObj =
        {
          data: {
            labels: [],
            datasets: [
              {
                label: 'Troca de Bateria',
                stack: 'Stack 0',
                backgroundColor: '#008000',
                borderWidth: 2,
                data: [],
                hidden: false,
              },
              {
                label: 'Troca de Tampa(Bobina)',
                stack: 'Stack 0',
                backgroundColor: '#67a53b',
                borderWidth: 2,
                data: [],
                hidden: false,
              },
              {
                label: 'Troca de Carregador',
                stack: 'Stack 0',
                backgroundColor: '#8de053',
                borderWidth: 2,
                data: [],
                hidden: false,
              }
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [{
                stacked: true,
                categoryPercentage: 0.9,
                gridLines: {
                  display: false,
                },
              }],
              yAxes: [{
                id: 'A',
                stacked: true,
                position: 'bottom',
                align: 'center',
                ticks: {
                  min: 0,
                  callback: function(value) {
                    return `${value}`
                },
                }
              },
              {
                id: 'B',
                display:false,
                stacked: true,
                anchor: 'end',
                align: 'top',
                ticks: {
                  min: 0,
                  callback: function(value) {
                    return `${value.toFixed(2)}%`
                  },
                },
              }],
            },
            tooltips: {
              mode: 'index',
              intersect: false,
            },
            title: {
              display: true,
              fontColor: 'black',
              fontSize: 20,
              text: 'Impacto Spare Parts',
            },
            plugins:{
              datalabels: {
                display: function (graphObj) {
                  return graphObj.dataset.data[graphObj.dataIndex] > 0
                },
                font: {
                  size: 12,
                },
                position: 'bottom',
                align: 'center',
                color: 'black',
                formatter: function (value) {
                  return value
                }
              },
            },
  
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: 'black',
            },
          },
        }
  
        _.orderBy(obj, 'week').forEach((element) => {
              graphObj.data.labels.push(" ".concat(element.week))

              if(element.activeTotal !== 0){
                graphObj.data.datasets[0].data.push((element.battery*100/element.activeTotal  ).toFixed(2))
                graphObj.data.datasets[1].data.push((element.bobina*100/element.activeTotal   ).toFixed(2))
                graphObj.data.datasets[2].data.push((element.recharge*100/element.activeTotal ).toFixed(2))
              }
              else{
                graphObj.data.datasets[0].data.push(0)
                graphObj.data.datasets[1].data.push(0)
                graphObj.data.datasets[2].data.push(0)
              }
          }
        )
  
  
        this.setState({
          weekSparePartGraph:{
            graph: graphObj,
            loaded: true
          },
          lastUpdated : moment()
        })
      }
      
      renderWeekSparePartGraph() {
        if (this.state.weekSparePartGraph.loaded) {
          return (
            <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
              <Graph
                data={this.state.weekSparePartGraph.graph.data}
                legend={this.state.weekSparePartGraph.graph.legend}
                options={this.state.weekSparePartGraph.graph.options}
                width={this.state.isDesktop ? 400 : 310}
                height={420}
              />
            </Box>)
        }
    
        return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
          <LoadingSpinner />
        </div>)
        }


      renderIdtSummary(information) {
        if(information.loaded){
          
          return (
            <SummaryIdt
              information = {information}
            />
          )
        }
        else
          return (
            <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
              <LoadingSpinner />
            </div>
          )
      }
  
    buildWeekDefectGraph(obj) {
      var graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: `${obj[0].defect}`,
              stack: 'Stack 0',
              backgroundColor: '#008000',
              data: [],
              yAxisID: 'B',
              fill: false,
              display:false,
            }
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [
            {
              id: 'B',
              display:true,
              stacked: true,
              anchor: 'end',
              align: 'top',
              ticks: {
                min: 0,
                callback: function(value) {
                  return `${value.toFixed(2)}%`
                },
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: 'black',
            fontSize: 20,
            text: 'Top Defeitos',
          },
          plugins:{
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 12,
              },
              position: 'bottom',
              align: 'center',
              color: 'white',
              formatter: function (value) {
                return value
              }
            },
          },

        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'black',
          },
        },
      }

        _.orderBy(obj, 'week').forEach((element) => {
              graphObj.data.labels.push(element.week)
              graphObj.data.datasets[0].data.push((100*element.qtd/element.total).toFixed(2))
          })

      this.setState({
        weekDefectGraph:{
          graph: graphObj,
          loaded: true
        },
        lastUpdated : moment()
      })

    }

    renderWeekDefectGraph() {
      if (this.state.weekDefectGraph.loaded) {
        return (
          <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
            <Graph
              data={this.state.weekDefectGraph.graph.data}
              legend={this.state.weekDefectGraph.graph.legend}
              options={this.state.weekDefectGraph.graph.options}
              width={this.state.isDesktop ? 400 : 310}
              height={420}
            />
          </Box>)
      }

      return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
        <LoadingSpinner />
      </div>)
      }
  updateScreen(){
    this.clearStates()
    this.refreshReportData(this.state.selectedModel,this.state.selectedDefect)
  } 
  renderWeekOnboardingRankigGraph() {
    if (this.state.weekOnBoardingRankingGraph.loaded) {
      return (
        <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
          <Graph
            data={this.state.weekOnBoardingRankingGraph.graph.data}
            legend={this.state.weekOnBoardingRankingGraph.graph.legend}
            options={this.state.weekOnBoardingRankingGraph.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </Box>)
    }
  
    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
    }   

  renderMonthOnboardingRankigGraph() {
    if(this.state.idtValues.loaded&&this.state.onboardingValues.loaded){
      if(this.state.monthOnBoardingRankingGraph.loaded){
        return (
          <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
            <Graph
              data={this.state.monthOnBoardingRankingGraph.graph.data}
              legend={this.state.monthOnBoardingRankingGraph.graph.legend}
              options={this.state.monthOnBoardingRankingGraph.graph.options}
              width={this.state.isDesktop ? 400 : 310}
              height={380}
            />
          </Box>)
      }
      else
        return (
          <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
            <LoadingSpinner />
          </div>
        )
    }
    else
          return (
            <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
              <LoadingSpinner />
            </div>
          )
  }


renderTopPainel() {
  if(this.state.idtValues.loaded&&this.state.onboardingValues.loaded){
    let data = this.state.idtValues.data.filter(item => item.month === '30 dias')
    let onBoarding = this.state.onboardingValues.data.filter(item => item.month === '30 dias')
    return (
    <div>
      <Row>
          <Col md lg xl = {1}/>
          <Col md lg xl = {2}>
            <SimpleBox
              header='IDR'
              direction ='normal'
              result={(((data[0].sparepart+data[0].chip+data[0].total+data[0].change)/data[0].activeTotal)*100).toFixed(1)}
              percentage={true}
            />
            </Col>
            <Col md lg xl = {2}>
              <SimpleBox
                header='IDT'
                result={(((data[0].total)/data[0].activeTotal)*100).toFixed(1)}
                percentage={true}
                />
            </Col>
            <Col md lg xl = {2}>
            <SimpleBox
              header='PREFERENCIA'
              result={(((data[0].change)/data[0].activeTotal)*100).toFixed(1)}
              percentage={true}
            />
            </Col>
            <Col md lg xl = {2}>
              <SimpleBox
                header='SPARE PART'
                result={(((data[0].sparepart)/data[0].activeTotal)*100).toFixed(1)}
                percentage={true}
                />
            </Col>
            <Col md lg xl = {2}>
            <SimpleBox
              header='IDM'
              result={(((data[0].chip)/data[0].activeTotal)*100).toFixed(1)}
              percentage={true}
            />
            </Col>
        </Row>
        <Row>
          <Col md lg xl = {1}/>
          <Col md lg xl = {2}>
            <SimpleBox
              header='IDR ONBOARDING'
              result={(((onBoarding[0].idt+onBoarding[0].sparepart+onBoarding[0].chip+onBoarding[0].change)/onBoarding[0].parqueNovo)*100).toFixed(1)}
              percentage={true}
            />
            </Col>
            <Col md lg xl = {2}>
              <SimpleBox
                header='IDT ONBOARDING'
                result={(((onBoarding[0].idt)/onBoarding[0].parqueNovo)*100).toFixed(1)}
                percentage={true}
                />
            </Col>
            <Col md lg xl = {2}>
            <SimpleBox
              header='PREFERENCIA ONB.'
              result={(((onBoarding[0].change)/onBoarding[0].parqueNovo)*100).toFixed(1)}
              percentage={true}
            />
            </Col>
            <Col md lg xl = {2}>
              <SimpleBox
                header='SPARE PART ONB.'
                result={(((onBoarding[0].sparepart)/onBoarding[0].parqueNovo)*100).toFixed(1)}
                percentage={true}
                />
            </Col>
            <Col md lg xl = {2}>
            <SimpleBox
              header='IDM ONBOARDING'
              result={(((onBoarding[0].chip)/onBoarding[0].parqueNovo)*100).toFixed(1)}
              percentage={true}
            />
            </Col>
        </Row>
      </div>
    )
  }
  else
    return (
      <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
          <LoadingSpinner />
      </div>
    )
 }

  buildWeekOnboardingRankigGraph(obj) {

    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'IDT',
            stack: 'Stack 0',
            backgroundColor: '#27e32d',
            data: [],
            yAxisID: 'B',
            fill: false,
            display:false,
          },
          {
            label: 'Preferência outro equipamento',
            stack: 'Stack 0',
            backgroundColor: '#21b826',
            data: [],
            yAxisID: 'B',
            fill: false,
            display:false,
          },
          {
            label: 'Spare Part',
            stack: 'Stack 0',
            backgroundColor: '#1a8a1e',
            data: [],
            yAxisID: 'B',
            fill: false,
            display:false,
          },
          {
            label: 'IDM',
            stack: 'Stack 0',
            backgroundColor: '#146117',
            data: [],
            yAxisID: 'B',
          },
          {
            label: 'IDR',
            stack: 'Stack 0',
            backgroundColor: 'white',
            data: [],
            yAxisID: 'B',
          }
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'B',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'IDR Semanal',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            position: 'bottom',
            align: 'center',
            color:'black',
            formatter: function (value) {
              return value
            }
          },
        },

      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
      },
    }
    _.orderBy(obj, 'week').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.week))
          graphObj.data.datasets[0].data.push((100*element.idt/element.parqueNovo).toFixed(2))
          graphObj.data.datasets[1].data.push((100*element.change/element.parqueNovo).toFixed(2))
          graphObj.data.datasets[2].data.push((100*element.sparepart/element.parqueNovo).toFixed(2))
          graphObj.data.datasets[3].data.push((100*element.chip/element.parqueNovo).toFixed(2))
          graphObj.data.datasets[4].data.push(((100*element.idt/element.parqueNovo)+(100*element.sparepart/element.parqueNovo)+(100*element.change/element.parqueNovo)+(100*element.chip/element.parqueNovo)).toFixed(2))
      }
    )

    return graphObj
  }
  

  renderIdtOnboardingSummary(information,screening) {
    if(this.state.selectedScreening === '-1')
      if(information.loaded && screening.loaded){
        return (
          <SummaryIdt
            information = {information}
            screening= {screening}
          />
        )
      }
      else
        return (
          <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
            <LoadingSpinner />
          </div>
        )
    else{
      if(screening.loaded){
        let screeningSelected = {data:screening.data.filter(item => item.location === this.state.selectedScreening)}
        return (
          <SummaryIdt
            information = {screeningSelected}
          />
        )
      }
      else
        return (
          <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
            <LoadingSpinner />
          </div>
        )
    }
  }

  refreshScreening(screening) {
    LogisticService.getIDTOnBoarding(null,'screening',screening)
    .then((onBoarding) => {
      this.setState({
        onboardingScreening:{
          data   : onBoarding.data.data,
          loaded : true
        }
      })
    })
  }

  //Filter Defect
  inputScreening () {
    return (
      <Form>
            <Form.Group as={Col} controlId="screening" >
              <Form.Label>Triagem</Form.Label>
              <Form.Control as="select" 
              value={this.state.selectedScreening}
              onChange = {e => this.updateScreening()}>
              <option>{'Geral'}</option>
              <option>{'POLO'}</option>
              <option>{'CD'}</option>
              <option>{'STONE BOX'}</option>
              </Form.Control>
            </Form.Group>
      </Form>
    )}

  updateScreening(){
    let screening = document.getElementById("screening")
    this.setState({
      selectedScreening : screening[screening.selectedIndex].text === 'Geral'? '-1':screening[screening.selectedIndex].text
    })
  }

// Render
  render() {
    let refreshContent
    let lastUpdatedText = ''

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format('DD/MM HH:mm')}`
    }

      refreshContent = (
        <div>
          <Row>
            <Col xl sm md lg xl = {4}>
              {this.inputModel()}
            </Col>
            <Col xl sm md lg xl = {4}>
            </Col>
            <Col xl sm md lg xl = {4}>
              <div style={{textAlign: 'right'}}>
                <label style={{margin:'1em'}}><i>{lastUpdatedText}</i></label>
                <RefreshButton onClick={() => { this.updateScreen() }}/>
              </div>
            </Col>
          </Row>
        </div>
      )

    return (
      <Container fluid>
          {this.state.lastUpdated?refreshContent:''}
          <div style={{color: 'black'}}>
            <Tabs defaultActiveKey="month" id="noanim-tab-example" variant='tabs' style={{backgroundColor: '#103a21', color: '#fff'}}>
              <Tab eventKey="month" title="IDR Mensal" unmountOnExit='false' >
                <CustomizedExpansionPannel
                  title="IDR POR MODELO"
                  content={
                    <Row>
                      <Col xl sm md lg xl = {12}>
                        {this.renderMonthRankigGraph()}
                      </Col>
                    </Row>
                  }
                  helpMessage={<p>
                    <b>IDR Mensal</b>
                    <p >Leva em consideração todas as OSs de troca que tiveram como solução 'Troca de Equipamento' ou 'Preferência por Troca de Equipamento'.</p></p>}
                  tooltip={true}
                />
                <Row>  
                  {this.state.monthDefectGraph.loaded?this.inputDefect():''}
                </Row>
                <CustomizedExpansionPannel
                  title="DEFEITOS - IDR"
                  content={
                    <Row>
                      <Col xl sm md lg xl = {12}>
                        {this.renderMonthDefectGraph()}
                      </Col>
                    </Row>
                  }
                  helpMessage={<p>
                    <b>Top Defeitos IDR</b>
                    <p >São destacados os 5 principais motivos do último mês visto ao longo dos meses anteriores.</p></p>}
                  tooltip={true}
                />
                <CustomizedExpansionPannel
                  title="SPARE PARTS"
                  content={
                    <Row>
                      <Col xl sm md lg xl = {12}>
                        {this.renderMonthDefectSparePartGraph()}
                      </Col>
                    </Row>
                  }
                  helpMessage={<p>
                    <b>Top Spare Part</b>
                    <p >São destacados os 3 principais motivos do último mês visto ao longo dos meses anteriores.</p></p>}
                  tooltip={true}
                />
                <CustomizedExpansionPannel
                title="IDR GERAL"
                content={
                  <Row>
                    <Col xs sm md = {0} lg xl = {2}/>
                    <Col xs sm md = {12} lg xl = {10}>
                      {this.renderIdtSummary(this.state.monthIdtTable)}
                    </Col>
                  </Row>
                }
                helpMessage={<p>
                  <b>IDR Geral</b>
                  <p>Tabela para acompanhar os principais defeitos (IDT e Spare Parts) e a evolução deles.</p>
                  <p>%Δ calculado em relação ao resultado médio de 3 meses.</p>
                  </p>}
                tooltip={true}
                />
              </Tab>
              <Tab eventKey="treatment" title="IDR Semanal" unmountOnExit='false' >
              <CustomizedExpansionPannel
                title="IDR POR MODELO"
                content={
                  <Row>
                    <Col xl sm md lg xl = {12}>
                      {this.renderWeekRankigGraph()}
                    </Col>
                  </Row>
                }
                helpMessage={<p>
                  <b>IDR Semanal</b>
                  <p >Leva em consideração todas as OSs de troca que tiveram como solução 'Troca de Equipamento' ou 'Preferência por Troca de Equipamento'.</p></p>}
                tooltip={true}
              />
              <Row>  
                {this.state.weekDefectGraph.loaded?this.inputDefect():''}
              </Row>
              <CustomizedExpansionPannel
                title="DEFEITOS"
                content={
                  <Row>
                    <Col xl sm md lg xl = {12}>
                      {this.renderWeekDefectGraph()}
                    </Col>
                  </Row>
                }
                helpMessage={<p>
                  <b>Top Defeitos IDR</b>
                  <p >São destacados os 5 principais motivos do último mês visto ao longo das últimas 12 semanas.</p></p>}
                tooltip={true}
              />
              <CustomizedExpansionPannel
                  title="SPARE PART"
                  content={
                    <Row>
                      <Col xl sm md lg xl = {12}>
                        {this.renderWeekSparePartGraph()}
                      </Col>
                    </Row>
                  }
                  helpMessage={<p>
                    <b>Top Spare Part</b>
                    <p >São destacados os 3 principais motivos das últimas 12 semanas</p></p>}
                  tooltip={true}
                />
              <CustomizedExpansionPannel
              title="IDR GERAL"
              content={
                <Row>
                  <Col xs sm md = {0} lg xl = {1}/>
                    <Col xs sm md = {12} lg xl = {11}>
                      {this.renderIdtSummary(this.state.weekIdtTable)}
                    </Col>
                </Row>
              }
              helpMessage={<p>
                <b>IDR Geral</b>
                <p>Tabela para acompanhar os principais defeitos (IDT e Spare Parts) e a evolução mensal deles.</p>
                <p>%Δ calculado em relação ao resultado médio de 3 semanas.</p>
                </p>}
              tooltip={true}
              />
              </Tab>
              {/* <Tab eventKey="Onboarding" title="IDR Onboarding" unmountOnExit='false'>
                <CustomizedExpansionPannel
                  title="IDR - Resumo 30D"
                  content={
                    <Row>
                      <Col xl sm md lg xl = {12}>
                        {this.renderTopPainel()}
                      </Col>
                    </Row>
                  }
                  helpMessage={<p>
                    <b>Comparação entre IDR geral e Onboarding 30 dias</b>
                    <p>Essa comparação é boa para dar visibilidade da dor do cliente onboarding.</p></p>}
                  tooltip={true}
                />
                <CustomizedExpansionPannel
                  title="IDR - EVOLUÇÃO ANUAL"
                  content={
                    <Row>
                      <Col xl sm md lg xl = {12}>
                        {this.renderMonthOnboardingRankigGraph()}
                      </Col>
                    </Row>
                  }
                  helpMessage={<p>
                    <b>Evolução mês a mês</b>
                    <p>Novamente mostra a comparação e o quanto temos ou não evoluído no assunto.</p></p>}
                  tooltip={true}
                />
                <CustomizedExpansionPannel
                  title="IDR ONBOARDING - EVOLUÇÃO SEMANAL 30D"
                  content={
                    <Row>
                      <Col xl sm md lg xl = {12}>
                        {this.renderWeekOnboardingRankigGraph()}
                      </Col>
                    </Row>
                  }
                  helpMessage={<p>
                    <b>Evolução semanal</b>
                    <p>Mostra apenas o onboarding 30d dos principais defeitos (igual aos principais do dash de IDR), para identificarmos rapidamente melhorias ou problemas. Gráfico de barras empilhadas.</p></p>}
                  tooltip={true}
                />
                <Row>  
                  {(this.state.monthOBoardingIdtTable.loaded&&this.state.onboardingScreening)?this.inputScreening():''}
                </Row>
                <CustomizedExpansionPannel
                title="IDR - ONBOARDING GERAL"
                content={
                  <Row>
                    <Col xs sm md = {0} lg xl = {2}/>
                    <Col xs sm md = {12} lg xl = {10}>
                      {this.renderIdtOnboardingSummary(this.state.monthOBoardingIdtTable,this.state.onboardingScreening)}
                    </Col>
                  </Row>
                }
                helpMessage={<p>
                  <b>IDR Geral</b>
                  <p>Tabela para acompanhar os indicadores IDT OnBoard e indicador de onde o POS foi triado, evolução dos últimos 4 meses.</p>
                  <p>Δ% : a diferença do mês em relação à média dos 3 meses anteriores.</p>
                  </p>}
                tooltip={true}
                />
              </Tab> */}
            </Tabs>
          </div>
    </Container>
    )
  }
}

export default IDTReport
