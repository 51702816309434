import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'

import style from './style.css' // eslint-disable-line

class Table extends Component {
  constructor (props) {
    super(props)

    this.state = {
      sorted: []
    }
  }

  getSortedComponent (id) {
    const sortInfo = this.state.sorted.filter(item => item.id === id)
    if (sortInfo.length) {
      return sortInfo[0].desc ?
        <div id="#sort__desc" className={style.sort__desc} /> :
        <div id="#sort__asc" className={style.sort__asc} />
    }
    return <div />
  }

  headerStyle () {
    return {
      Header: props => (
        <span>
          {props.column.headerText} {this.getSortedComponent(props.column.id)}
        </span>
      )
    }
  }

  buildHeaders () {
    return this.props.headers.map(header => ({
      ...this.headerStyle(),
      headerText: header.headerText,
      accessor: header.accessor,
      width: header.width
    }))
  }

  render () {
    const { pageSize, tableTitle, data, defaultSortedId, tableClassName } = this.props

    return (
      <div className={style[tableClassName]}>
        <ReactTable
          loading={!data.length}
          onSortedChange={sorted => this.setState({ sorted })}
          data={data}
          columns={[{
            Header: tableTitle,
            columns: this.buildHeaders()

          }]}
          defaultPageSize={pageSize}
          getTableProps={() => ({
            className: style.ReactTable
          })}
          getTheadGroupProps={() => ({
            className: style['rt-table']
          })}
          getTheadGroupThProps={() => ({
            className: style['rt-tr-group']
          })}
          getTheadProps={() => ({
            className: style['rt-thead']
          })}
          getTheadTrProps={() => ({
            className: style['rt-tr']
          })}
          getTheadThProps={() => ({
            className: style['rt-th']
          })}
          getTbodyProps={() => ({
            className: style['rt-tbody']
          })}
          getTrGroupProps={() => ({
            className: style['rt-tr-group']
          })}
          getTrProps={() => ({
            className: style['rt-tr']
          })}
          getThProps={() => ({
            className: style['rt-th']
          })}
          getTdProps={() => ({
            className: style['rt-td']
          })}
          getLoadingProps={() => ({
            className: style['-loading']
          })}
          getPaginationProps={() => ({
            className: style['-pagination']
          })}
          previousText="Anterior"
          nextText="Próxima"
          pageText="Página"
          ofText="de"
          rowsText="Linhas"
          defaultSortDesc
          defaultSorted={[{
            id: defaultSortedId,
            desc: !!defaultSortedId
          }]}
        />
      </div>
    )
  }
}

Table.propTypes = {
  pageSize: PropTypes.number,
  defaultSortedId: PropTypes.string,
  tableClassName: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tableTitle: PropTypes.string.isRequired
}

Table.defaultProps = {
  pageSize: 8,
  tableClassName: 'table',
  defaultSortedId: ''
}

export default Table
