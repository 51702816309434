/* eslint-disable */
import React, { Component } from 'react';
import moment from 'moment';
import LogisticService from '../../core/services/LogisticService';
import Graph from '../../components/Logistic/graphic/graph';
import { Container, Col, Row } from 'react-bootstrap';
import LoadingSpinner from '../../components/LoadingSpinner';

class SwapsAndMaintenanceSection extends Component {

    constructor () {
        super()
        this.state = {
          today: moment().format('YYYY-MM-DD'),
          loaded: false,
          lastUpdated: undefined,
          osTypeHistory:  {
            graph: undefined,
            loaded: false
          },
          progressGoals:  {
            graph: undefined,
            loaded: false
          },
          monthRankingGraph: {
            loaded: false,
            graph: undefined
          },
        }
    }

    componentDidMount () {
        this.refreshReportData()
      }

    refreshReportData() {
      let today = new Date()
      today.setDate(today.getMonth() - 1)
      today.setFullYear(today.getFullYear() - 1)
      let date = today.getFullYear().toString()+'-'+today.getMonth().toString()+'-01'
      
        LogisticService.getOSHistoryType()
        .then((response) => {
        this.buildHistoryOSType(response.data)
        })

        LogisticService.getIdtDefect('-1',date,null,'month')
        .then((response) => {
          this.setState({
            monthRankingGraph:{
              graph: this.buildMonthRankigGraph(response.data.data),
              loaded: true
            },
          })
        })

  }


  buildMonthRankigGraph(obj) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Meta',
            stack: 'Stack 1',
            type: 'line',
            borderColor: 'red',
            backgroundColor: 'red',
            pointBorderColor: 'red',
            pointBackgroundColor: 'red',
            pointHoverBackgroundColor: 'red',
            pointHoverBorderColor: 'red',
            pointRadius : 0,
            // showLine:false,
            data: [],
            fill: false,
            display:false,
            yAxisID: 'A',
            
          },
          {
            label: 'IDR',
            stack: 'Stack 2', 
            type: 'line',
            borderColor: '#27e32d',
            backgroundColor: '#27e32d',
            pointBorderColor: '#27e32d',
            pointBackgroundColor: '#27e32d',
            pointHoverBackgroundColor: '#27e32d',
            pointHoverBorderColor: '#27e32d',
            pointRadius : 0,
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            
          },
          {
            label: 'IDT',
            stack: 'Stack 0',
            backgroundColor: '#27e32d',
            data: [],
            yAxisID: 'B',
            fill: false,
            display:false,
            
          },
          {
            label: 'Preferência outro equipamento',
            stack: 'Stack 0',
            backgroundColor: '#21b826',
            data: [],
            yAxisID: 'B',
            fill: false,
            display:false,
            
          },
          {
            label: 'Spare Part',
            stack: 'Stack 0',
            backgroundColor: '#1a8a1e',
            data: [],
            yAxisID: 'B',
            fill: false,
            display:false,
            
          },
          {
            label: 'IDM',
            stack: 'Stack 0',
            backgroundColor: '#146117',
            data: [],
            yAxisID: 'B',
            fill: false,
            display:false,
            
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'B',
            display:true,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              suggestedMax:this.state.selectedModel !== 'A8'?7:20,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
          {
            id: 'A',
            display:false,
            stacked: false,
            anchor: 'top',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'IDR Mensal',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
              if(graphObj.dataset.label !== 'Meta' && graphObj.dataset.label !== 'IDR')
                return true
              else
                return false
            },
            font: {
              size: 12,
            },
            position: 'bottom',
            align: function (graphObj) {
              if(graphObj.dataset.label !== 'IDR')
                return 'center'
              else
                return 'top'
            },
            color:'black',
            fontSize:'bold',
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
      },
    }
    _.orderBy(obj, 'month').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.month))
          graphObj.data.datasets[0].data.push(element.goal)
          graphObj.data.datasets[1].data.push(((100*element.total/element.activeTotal)+(100*element.sparepart/element.activeTotal)+(100*element.change/element.activeTotal)+(100*element.chip/element.activeTotal)).toFixed(2))
          graphObj.data.datasets[2].data.push((100*element.total/element.activeTotal).toFixed(2))
          graphObj.data.datasets[3].data.push((100*element.change/element.activeTotal).toFixed(2))
          graphObj.data.datasets[4].data.push((100*element.sparepart/element.activeTotal).toFixed(2))
          graphObj.data.datasets[5].data.push((100*element.chip/element.activeTotal).toFixed(2))        
      }
    )
    return graphObj
  }

  buildHistoryOSType(obj) {
    console.log(obj)
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Instalação',
            stack: 'Stack 0',
            backgroundColor: '#18641e',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Desinstalação',
            stack: 'Stack 0',
            backgroundColor: '#c7220f',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Troca',
            stack: 'Stack 0',
            backgroundColor: '#ffbc14',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Manutenção',
            stack: 'Stack 0',
            backgroundColor: '#00942A',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
            ticks: {
              min: '2018-09',
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
              max:100
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: '#2e3136',
          fontSize: 20,
          text: 'Proporção de OSs por Tipo',
          fontFamily: 'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 11,
              weight: 'bold'
            },
            color: 'white',
            formatter: function (value) {
              if (value >= 1000) {
                return String((value/1000).toFixed(1)) + 'k'
              }
              else {
                return value.toFixed(1)
              }
            },
            // anchor: 'end',
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          usePointStyle: true
        },
      },
    }
  
  
    _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(element.date)
        graphObj.data.datasets[0].data.push(element.installation*100/(element.installation+element.desinstallation+element.change+element.fix))
        graphObj.data.datasets[1].data.push(element.desinstallation*100/(element.installation+element.desinstallation+element.change+element.fix))
        graphObj.data.datasets[2].data.push(element.change*100/(element.installation+element.desinstallation+element.change+element.fix))
        graphObj.data.datasets[3].data.push(element.fix*100/(element.installation+element.desinstallation+element.change+element.fix))
      }
    )
  
  
  
    this.setState({
      osTypeHistory: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }
  
  renderOSHistoryType() {
    if (this.state.osTypeHistory.loaded ) {
      return (
        <div>
          <Graph
            data={this.state.osTypeHistory.graph.data}
            legend={this.state.osTypeHistory.graph.legend}
            options={this.state.osTypeHistory.graph.options}
            width={this.state.isDesktop ? 500 : 350}
            height={this.state.isDesktop ? 400 : 350}
      />
        </div>)
    }
  
  }



buildProgressGoalsGraph(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Meta',
            stack: 'Stack 1',
            backgroundColor: 'red',
            borderWidth: 2,
            data: [],
            type: 'line',
            fill: false,
            backgroundColor: 'red',
            borderColor: 'red',
            hoverBackgroundColor: 'red',
            hoverBorderColor: 'red',
            // borderColor: 'red',
            // backgroundColor: 'red',
            // pointBorderColor: 'red',
            // pointBackgroundColor: 'red',
            // pointHoverBackgroundColor: 'red',
            // pointHoverBorderColor: 'red',
            // pointRadius: 3,
            // pointHoverRadius: 5,
          },
          {
            label: 'Realizado',
            stack: 'Stack 0',
            backgroundColor: '#18641E',
            borderWidth: 2,
            data: [],
            fill: false,
            backgroundColor: '#18641E',
          }
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            type: 'linear',
            stacked: false,
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
              callback: function t(value) {
                return `${value}%`
            },
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: '#2e3136',
          fontSize: 20,
          text: 'IDT Histórico',
          fontFamily: 'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0 && graphObj.dataset.label === 'Realizado'
            },
            anchor: 'end',
            align: 'top',
            font: {
              size: 12,
            },
            color: 'black',
            formatter: function (value) {
              return `${value}%`
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          usePointStyle: true
        },
      },
    }
  
    _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.date))
        graphObj.data.datasets[1].data.push((100*element.changes/element.max).toFixed(1))
        graphObj.data.datasets[0].data.push(element.meta)
      }
    )
  
    this.setState({
      progressGoals: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }
  
  renderIDRGraph() {
    if (this.state.monthRankingGraph.loaded) {
      return (
        <div>
          <Graph
            data={this.state.monthRankingGraph.graph.data}
            legend={this.state.monthRankingGraph.graph.legend}
            options={this.state.monthRankingGraph.graph.options}
            width={this.state.isDesktop ? 500 : 350}
            height={this.state.isDesktop ? 400 : 350}
      />
        </div>)
    }
  
  }
  
  
  render () {

    if (this.state.monthRankingGraph.loaded) {
    return (
      <Row style={{marginTop: '1em'}}>
        <Col xs sm md = {12} lg xl = {6}>
          {this.renderOSHistoryType()}
        </Col>
        <Col xs sm md = {12} lg xl = {6}>
          {this.renderIDRGraph()}
        </Col>
      </Row>
      )
    }
    return (
      <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
        <LoadingSpinner />
      </div>)
  
    }
}

export default SwapsAndMaintenanceSection
