/* eslint-disable */
import React, { Component } from 'react';
import moment from 'moment';
import LogisticService from '../../core/services/LogisticService';
import Graph from '../../components/Logistic/graphic/graph';
import { Container, Col, Row } from 'react-bootstrap';
import LoadingSpinner from '../../components/LoadingSpinner';

class ShareAndQualitySection extends Component {

    constructor () {
        super()
        this.state = {
          today: moment().format('YYYY-MM-DD'),
          loaded: false,
          lastUpdated: undefined,
          realizedHistory:  {
            graph: undefined,
            loaded: false
          },
          csat:  {
            graph: undefined,
            loaded: false
          }
        }
    }

    componentDidMount () {
        this.refreshReportData()
      }

    refreshReportData() {
        LogisticService.getCSATOnePage()
        .then((response) => {
          this.buildCSATGraph(response.data.data)
        })

        LogisticService.getRealizedOSHistory()
        .then((response) => {
          this.buildRealizedOSHistory(response.data.data)
        })

  }


    //Renderiza o gráfico de status de OS por data de abertura
    renderRealizedOSHistory() {
        if (this.state.realizedHistory.loaded) {
        return (
            <div>
            <Graph
                data={this.state.realizedHistory.graph.data}
                legend={this.state.realizedHistory.graph.legend}
                options={this.state.realizedHistory.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 400 : 350}
        />
            </div>)
        }

    }

    buildRealizedOSHistory(obj) {
        const graphObj =
        {
        data: {
            labels: [],
            datasets: [
                {
                    label: "Redirect",
                    stack: "Stack 0",
                    backgroundColor: "#07AA35",
                    borderWidth: 2,
                    data: [],
                },
                {
                    label: "Angels",
                    stack: "Stack 0",
                    backgroundColor: "#018f01",
                    borderWidth: 2,
                    data: [],
                },
                {
                    label: "Terceira",
                    stack: "Stack 0",
                    backgroundColor: "#046e04",
                    borderWidth: 2,
                    data: [],
                },
                {
                    label: "Franquias",
                    stack: "Stack 0",
                    backgroundColor: "#03551A",
                    borderWidth: 2,
                    data: [],
                },
                {
                    label: "Delivery",
                    stack: "Stack 0",
                    backgroundColor: "#04553F",
                    borderWidth: 2,
                    data: [],
                },
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
            xAxes: [{
                gridLines: {
                display: false,
                },
                ticks: {
                min:'2018-09'
                }
            }],
            yAxes: [{
                type: 'linear',
                stacked: true,
                gridLines: {
                display: true,
                },
                ticks: {
                min: 0,
                max:100,
                callback: function t(value) {
                    return `${parseFloat(value).toFixed(1)}%`
                },
                },
            }],
            },
            tooltips: {
            mode: 'index',
            intersect: false,
            },
            title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Histórico de OSs Realizadas por Angels',
            fontFamily: 'GoBold'
            },
            plugins: {
            datalabels: {
                display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
                },
                align: 'center',
                font: {
                size: 11,
                weight: 'bold',
                },
                color: 'white',
                formatter: function (value) {
                return `${parseFloat(value).toFixed(1)}`
                }
            },
            zoom: {
                pan: {
                enabled: true,
                mode: 'x',
                },
                zoom: {
                enabled: true,
                mode: 'x',
                speed: 0.1
                }
            }
            },
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontColor: 'black',
                usePointStyle: true
            },
        },
        }

        _.orderBy(obj, 'semana').forEach((element) => {
            var data = element.periodo.split('-');
            var mes = data[1];
            var ano = data[0];
            graphObj.data.labels.push(mes + "/" + ano);
            graphObj.data.datasets[0].data.push(element.redirect * 100);
            graphObj.data.datasets[1].data.push(element.angels * 100);
            graphObj.data.datasets[2].data.push(element.third * 100);
            graphObj.data.datasets[3].data.push(element.franchise * 100);
            graphObj.data.datasets[4].data.push(element.delivery * 100);
        }
        )

        this.setState({
        realizedHistory: {
            graph: graphObj,
            loaded: true
        },
        lastUpdated: moment(),
        })
    }

    buildCSATGraph(obj) {
        const graphObj =
        {
        data: {
            labels: [],
            datasets: [
              {
                  label: 'Meta',
                  stack: 'Stack 0',
                  backgroundColor: '#00942A',
                  borderWidth: 2,
                  data: [],
                  type: 'line',
                  fill: false,
                  backgroundColor: 'red',
                  borderColor: 'red',
                  hoverBackgroundColor: 'red',
                  hoverBorderColor: 'red',
              },
            {
                label: 'CSAT',
                stack: 'Stack 0',
                backgroundColor: '#18641E',
                borderWidth: 2,
                data: [],
            }
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
            xAxes: [{
                stacked: true,
                categoryPercentage: 0.9,
                gridLines: {
                display: false,
                },
                ticks: {
                min: "2019-1",
                },
            }],
            yAxes: [{
                stacked: true,
                gridLines: {
                display: true,
                },
                ticks: {
                min: 90,
                max:100
                },
            }],
            },
            tooltips: {
            mode: 'index',
            intersect: false,
            },
            title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'CSAT',
            fontFamily: 'GoBold'
            },
            plugins: {
            datalabels: {
                display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0 && graphObj.dataset.label == 'CSAT'
                },
                font: {
                size: 12,
                },
                color: 'black',
                anchor: 'end',
                align: 'top',
                formatter: function (value) {
                if (value >= 1000) {
                    return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                    return value.toFixed(1)
                }
                },
            },
            },
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
            fontColor: 'black',
            usePointStyle: true
            },
        },
        }




        _.orderBy(obj, 'date').forEach((element) => {
            graphObj.data.labels.push("".concat(element.date))
            graphObj.data.datasets[1].data.push(element.good*100/element.total)
            graphObj.data.datasets[0].data.push(95)
        }
        )



        this.setState({
        csat: {
            graph: graphObj,
            loaded: true
        },
        lastUpdated: moment(),
        })
    }



    renderCSATGraph() {
        if (this.state.csat.loaded ) {
        return (
            <div>
            <Graph
                data={this.state.csat.graph.data}
                legend={this.state.csat.graph.legend}
                options={this.state.csat.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 400 : 350}
        />
            </div>)
        }

    }


    render () {

        if (this.state.realizedHistory.loaded && this.state.csat.loaded) {
          return (
            <Row style={{marginTop: '1em'}}>
              <Col xs sm md = {12} lg xl = {6}>
                {this.renderRealizedOSHistory()}
              </Col>
              <Col xs sm md = {12} lg xl = {6}>
                {this.renderCSATGraph()}
              </Col>
            </Row>
          )
        }
        return (
        <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
          <LoadingSpinner />
        </div>)
      }

}

export default ShareAndQualitySection
