/* eslint-disable */
import React, { Component } from "react";
import LogisticService from "../../../core/services/LogisticService";
import SortingTable from "../../../custom-components/SortingTable";

const columnsCSAT = [
  {
    title: "Angel",
    dataIndex: "technicianName",
    sorter: (a, b) => (a.technicianName < b.technicianName ? -1 : 1),
  },
  {
    title: "CSAT",
    dataIndex: "csat",
    sorter: (a, b) => (a.csat < b.csat ? -1 : 1),
  },
  {
    title: "Incrível",
    dataIndex: "amazing",
    sorter: (a, b) => a.amazing - b.amazing,
  },
  {
    title: "Bom",
    dataIndex: "good",
    sorter: (a, b) => a.good - b.good,
  },
  {
    title: "Razoavel",
    dataIndex: "normal",
    sorter: (a, b) => a.normal - b.normal,
  },
  {
    title: "Ruim",
    dataIndex: "bad",
    sorter: (a, b) => a.bad - b.bad,
  },
  {
    title: "Péssimo",
    dataIndex: "terrible",
    sorter: (a, b) => a.terrible - b.terrible,
  },
];

export class CSAT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      district: props.district,
      date: props.date,
      csat: {
        loaded: false,
      },
      graph: undefined,
      loading: false,
      lastUpdated: undefined,
    };
  }

  componentDidMount() {
    LogisticService.getLmpAttendance(
      "CSAT",
      this.state.district,
      null,
      `${this.state.date}-01`,
      `${this.state.date}-01`
    ).then((response) => {
      response.data.data.forEach((item) => {
        item.csat = `${(
          ((item.amazing +
            item.good * 0.8 +
            item.normal * 0.6 +
            item.bad * 0.4 +
            item.terrible * 0.2) *
            100) /
          (item.amazing + item.good + item.normal + item.bad + item.terrible)
        ).toFixed(1)}%`;
      });
      this.setState({
        csatData: response.data.data,
        loaded: true,
      });
    });
  }

  render() {
    return (
      <SortingTable title="CSAT" columns={columnsCSAT} data={this.state.csatData} scroll={650} />
    );
  }
}

export default CSAT;
