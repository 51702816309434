/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import style from './style.css'
import LogisticService from '../../core/services/LogisticService'
import RefreshButton from '../../custom-components/RefreshButton'
import { Container, Col, Row } from 'react-bootstrap'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LoadingSpinner from '../../components/LoadingSpinner'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#103a21',
    color: 'white',
    fontSize: '0.9em',
  },
  body: {
    fontSize: '0.8em'
  },
}))(TableCell);



class AllFranchisesWallboard extends Component {
  constructor () {
    super()

    this.state = {
      today: moment().format('YYYY-MM-DD'),
      state: '',
      loading: false,
      providers: undefined,
      lastUpdated: undefined,
    }

    this.refreshReportData = this.refreshReportData.bind(this)
  }

  componentDidMount () {
    this.refreshReportData()
  }

  refreshReportData () {

    this.setState({
      today: moment().format('YYYY-MM-DD'),
      loading: true,
      providers: undefined,
      lastUpdated: undefined,
    })

    LogisticService.getOsWallboard('provider', 'STONE PAGAMENTOS', 'STONE FRANQUIA', this.state.today)
      .then((response) => {
            this.setState({
              lastUpdated: moment(),
              providers: response.data.data,
            })

          })

  }

  reportContent () {
    if (!this.state.providers) {
      return (
        <div style={{textAlign: "center"}}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      )
    }

    const orderedProviders = this.state.providers.sort(function(a, b){
        let keyA = a.atrasada,
            keyB = b.atrasada;
        // Compare the 2 keys
        if(keyA < keyB) return 1;
        if(keyA > keyB) return -1;
        return 0;
    });


    const results = orderedProviders.map(provider => {
      if (!(provider.groupName == null)) {
        return (
            <TableRow hover>
              <StyledTableCell align="center">
                <Link to={`/franchise/provider/${provider.groupName}`}>
                  {provider.groupName}
                </Link>
              </StyledTableCell>
              <StyledTableCell style={{color: 'black'}} align="center">{provider.atendidasHoje}</StyledTableCell>
              <StyledTableCell style={{color: 'black'}} align="center">{provider.atrasada}</StyledTableCell>
              <StyledTableCell style={{color: 'black'}} align="center">{provider.atrasadaEmCampo}</StyledTableCell>
              <StyledTableCell style={{color: 'black'}} align="center">{provider.carteiraDia}</StyledTableCell>
              <StyledTableCell style={{color: 'black'}} align="center">{provider.carteiraDiaEmCampo}</StyledTableCell>
              <StyledTableCell style={{color: 'black'}} align="center">{(provider.slaMtd *100).toFixed(1)}%</StyledTableCell>
            </TableRow>
          )
        }
        else
          return
        })

    return (
      <Table>
        <TableHead>
          <TableRow hover>
            <StyledTableCell align="center" colSpan="2" style={{backgroundColor: 'white'}}/>
            <StyledTableCell align="center" colSpan="2" style={{ backgroundColor: 'white', color: 'gray'}}>Atrasados</StyledTableCell>
            <StyledTableCell align="center" colSpan="2" style={{ backgroundColor: 'white', color: 'gray'}}>Vencem hoje</StyledTableCell>
            <StyledTableCell style={{backgroundColor: 'white'}}/>
          </TableRow>
          <TableRow hover>
            <StyledTableCell align="center">Base</StyledTableCell>
            <StyledTableCell align="center">Feitos no dia</StyledTableCell>
            <StyledTableCell align="center" style={{ borderLeft: 'solid' }}>Total</StyledTableCell>
            <StyledTableCell align="center">Em Campo</StyledTableCell>
            <StyledTableCell align="center" style={{ borderLeft: 'solid' }}>Total</StyledTableCell>
            <StyledTableCell align="center">Em Campo</StyledTableCell>
            <StyledTableCell align="center" style={{ borderLeft: 'solid' }}>SLA MTD</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableRow hover>
          <StyledTableCell style={{backgroundColor: '#bcbcbc', fontWeight: 'bold', color: 'black'}} align="center">TOTAL</StyledTableCell>
          <StyledTableCell style={{backgroundColor: '#bcbcbc', fontWeight: 'bold', color: 'black'}} align="center">{orderedProviders.reduce((acumulator,currentValue) => acumulator + currentValue.atendidasHoje, 0)/2}</StyledTableCell>
          <StyledTableCell style={{backgroundColor: '#bcbcbc', fontWeight: 'bold', color: 'black'}} align="center">{orderedProviders.reduce((acumulator,currentValue) => acumulator + currentValue.atrasada, 0)/2}</StyledTableCell>
          <StyledTableCell style={{backgroundColor: '#bcbcbc', fontWeight: 'bold', color: 'black'}} align="center">{orderedProviders.reduce((acumulator,currentValue) => acumulator + currentValue.atrasadaEmCampo, 0)/2}</StyledTableCell>
          <StyledTableCell style={{backgroundColor: '#bcbcbc', fontWeight: 'bold', color: 'black'}} align="center">{orderedProviders.reduce((acumulator,currentValue) => acumulator + currentValue.carteiraDia, 0)/2}</StyledTableCell>
          <StyledTableCell style={{backgroundColor: '#bcbcbc', fontWeight: 'bold', color: 'black'}} align="center">{orderedProviders.reduce((acumulator,currentValue) => acumulator + currentValue.carteiraDiaEmCampo, 0)/2}</StyledTableCell>
          <StyledTableCell style={{backgroundColor: '#bcbcbc', fontWeight: 'bold', color: 'black'}} align="center">{(orderedProviders[0].slaMtd * 100).toFixed(1)}%</StyledTableCell>
        </TableRow>
        <TableBody>
          {results}
        </TableBody>
      </Table>
    )
  }

  render () {
    let refreshContent
    let lastUpdatedText = ''

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format('DD/MM HH:mm')}`
    }

    refreshContent = (
      <div style={{textAlign: 'right', marginBottom: '1em', marginTop: '1em'}}>
        <label style={{marginRight:'1em'}}>{lastUpdatedText}</label>
        <RefreshButton  onClick={() => { this.refreshReportData() }}/>
      </div>
    )
  
    return (
      <Container fluid>
        <Col lg="12" xs="12" sm="12">
            {refreshContent}
            {this.reportContent()}
        </Col>
      </Container>
    )
  }
}


AllFranchisesWallboard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
}


export default AllFranchisesWallboard
