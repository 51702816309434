/* eslint-disable */
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import { Card, Icon, Statistic } from 'antd'
import _ from 'lodash'
import React, { Component } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Modal from 'react-responsive-modal'
import LoadingSpinner from '../../components/LoadingSpinner'
import Graph from '../../components/Logistic/graphic/graph'
import LogisticService from '../../core/services/LogisticService'
import CustomizedExpansionPannel from '../../custom-components/CustomizedExpansionPanel/index'
import TablePaging from '../../custom-components/CustomizedTablePaging'
import RefreshButton from '../../custom-components/RefreshButton'
import './style.css'
import Button from '@material-ui/core/Button';



const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: 'white',
    color: 'dark',
    fontSize: '0.9em',
    fontFamily: 'GoBold'
  },
  body: {
    fontSize: '0.8em',
    fontFamily: 'GoBold'
  },
}))(TableCell); 

const SimpleBox = props => {
  const { header, result, goal, direction, percentage,decimal } = props
  let headlightSymbol;
  let headlightColor;

  if (direction === 'normal') {
    headlightSymbol = (result > goal ? 'check-circle' : 
      (result > 0.9 * goal) ?  'minus-circle' : 'close-circle')
    headlightColor = (result > goal ? '#28A745' : 
    (result > 0.9 * goal) ?  'orange' : 'red')
  } else if (direction === 'reverse') {
    headlightSymbol = (result < goal ? 'check-circle' : 
    (result < 1.1 * goal) ?  'minus-circle' : 'close-circle')
    headlightColor = (result < goal ? '#28A745' : 
    (result < 1.1 * goal) ?  'orange' : 'red')
  }
  return (
      <Card>
        <Statistic
          title={<h3 style={{fontFamily: 'GoBold'}}>{header}</h3>}
          value={ result === undefined ? 0 + (percentage ? '%' : ''):result.toFixed(decimal) + (percentage ? '%' : '')}
          valueStyle={{ color: {headlightColor} }}
          prefix={<Icon type={headlightSymbol} theme="twoTone" style={{verticalAlign: 'middle'}} twoToneColor={headlightColor} />}
        />
    </Card>
    )
}
class DashLiga extends Component {

  constructor() {
  
    super()

    this.state = {
    //Dash Principal
      slaAttendance:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      slaSTF:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      inefficiency:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      posRegain:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      processAccuracy:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      evolutionOne:{
        graph:undefined,
        loaded : undefined
      },
      evolutionTwo:{
        graph:undefined,
        loaded : undefined
      },
      evolutionThree:{
        graph:undefined,
        loaded : undefined
      },
      evolutionFour:{
        graph:undefined,
        loaded : undefined
      },
      evolutionFive:{
        graph:undefined,
        loaded : undefined
      },
      
    //Dash On Time
      monthlyEvolutionOnTime:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      MtdEvolutionOnTime:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      answeredAttendance:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      productivityD1:{
        data:undefined,
        loaded : undefined
      },
      productivity:{
        data:undefined,
        loaded : undefined
      },
      
    //Dash Off Time
      slaOff:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },

      //Ineficiencia
        inefficiencyHistory:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        inefficiencyReasons:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        inefficiencyService:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        inefficiencyWeek:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        inefficiencySla:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        inefficiencySlaReason:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        inefficiencySlaService:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        inefficiencySlaWeek:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        //Global
      loading:false,
    }

    this.refreshReportData = this.refreshReportData.bind(this)
  }

  componentDidMount() {
    this.refreshReportData()
  }

  clearStates(){
    this.setState({
      slaAttendance:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      slaSTF:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      inefficiency:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      posRegain:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      processAccuracy:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      evolutionOne:{
        graph:undefined,
        loaded : undefined
      },
      evolutionTwo:{
        graph:undefined,
        loaded : undefined
      },
      evolutionThree:{
        graph:undefined,
        loaded : undefined
      },
      evolutionFour:{
        graph:undefined,
        loaded : undefined
      },
      evolutionFive:{
        graph:undefined,
        loaded : undefined
      },
      
    //Dash On Time
      monthlyEvolutionOnTime:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      MtdEvolutionOnTime:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      answeredAttendance:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },
      productivityD1:{
        data:undefined,
        loaded : undefined
      },
      productivity:{
        data:undefined,
        loaded : undefined
      },
      
    //Dash Off Time
      slaOff:{
        data:undefined,
        tolerance : undefined,
        loaded : undefined
      },

      //Ineficiencia
        inefficiencyHistory:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        inefficiencyReasons:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        inefficiencySla:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        inefficiencySlaReason:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        inefficiencyWeek:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        },
        inefficiencySlaWeek:{
          graph: undefined,
          options: undefined,
          loaded :undefined
        }
    })
  }

  refreshReportData(){
    this.clearStates()
    
    var today = new Date();
    var dd = today.getDate()
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    let date = yyyy + '-' + mm 
    let date_strat = (yyyy-1).toString()+'-'+mm+'-01'
    let date_strat_week = (yyyy).toString()+'-01-01'
    
    //On Time
    LogisticService.getLigaOnTime().then((response)=>{  
      this.setState({
             answeredAttendance:{
               data:response.data.data,
               tolerance:0,
               loaded: true
             },
           })
         })
     LogisticService.getLigaOnTime('sla').then((response)=>{
       this.setState({
         productivity:{
           data:response.data.data.filter(data =>data.nickName !== 'Total' && data.type === 1),
           loaded: true
         },
         productivityD1:{
           data:response.data.data.filter(data =>data.nickName !== 'Total' && data.type === 2),
           loaded: true
         },
         slaAttendance:{
           data:response.data.data.filter(data => data.date === date && data.type === 3),
           tolerance:0,
           loaded: true
         },
         monthlyEvolutionOnTime:{
           graph: this.buildFrontPageGraph(response.data.data.filter(data =>data.nickName === 'Total' && data.type === 3),'SLA Atendimento','Valor','Meta'),
           loaded :true
         },
         MtdEvolutionOnTime:{
          data:response.data.data.filter(data =>data.type === 4),
          graph: this.buildFrontPageGraph(response.data.data.filter(data =>data.nickName === 'Total' && data.type === 4),'SLA Atendimento','Valor','Meta'),
          loaded :true
        }
       })
     })

     //OFF Time
      LogisticService.getLigaOffTime().then((response)=>{

        this.setState({
          slaOff:{
            data:response.data.data.filter(data =>data.date === date),
            loaded :true
          },
          evolutionTwo:{
            graph: this.buildFrontPageGraph(response.data.data,'SLA OFF','Valor','Meta'),
            loaded :true
          }
        })      
      })
    //Principal
    LogisticService.getLigaPosRegain().then((response)=>{
          this.setState({
            posRegain:{
              data:response.data.data.filter(data => data.date === date),
              loaded : true
            }
          })
      this.setState({
        evolutionFour:{
          graph: this.buildFrontPageGraph(response.data.data,'Terminais Recuperados','Valor','Meta'),
          loaded :true
        }
      })
    })

    LogisticService.getLigaAccuracy().then((response)=>{    
      this.setState({
            processAccuracy:{
              data:response.data.data.filter(data => data.date === date),
              tolerance:0,
              loaded: true
            }
          })
      this.setState({
        evolutionFive:{
          graph: this.buildFrontPageGraph(response.data.data,'Acuracidade dos Processos','Valor','Meta'),
          loaded :true
        }
      })
    })
    //Ineficiencia

    LogisticService.getLigaIneficiencia('month',null,date_strat).then((response) => {
      this.setState({
        inefficiency:{
          data:response.data.data.filter(data => data.date === date),
          loaded : true
        },
        evolutionThree:{
          graph: this.buildFrontPageGraph(response.data.data,'Ineficiência','Valor','Meta'),
          loaded :true,
        },
        inefficiencyHistory:{
          graph: this.buildTwoValuesGraphs(response.data.data,'Ineficiência Histórica','Própria','Paytec',false),
          loaded :true
        },
        inefficiencyService:{
          graph: this.buildInefficiencyServicegraphs(response.data.data,'Ineficiência histórica tipo de serviço','Instalação','Desistalação','Troca','Manutenção',false),
          loaded :true
        },
        inefficiencySla:{
          graph: this.buildTwoValuesGraphs(response.data.data,'Ineficiência histórica - Impacto no SLA','Própria','Paytec',true),
          loaded :true
        },
        inefficiencySlaService:{
          graph: this.buildInefficiencyServicegraphs(response.data.data,'Ineficiência histórica tipo de serviço - SLA','Instalação','Desistalação','Troca','Manutenção',true),
          loaded :true
        },
      })
    })

    LogisticService.getLigaIneficiencia('month','reason',date_strat).then((response) => {
      this.setState({
        inefficiencyReasons:{
          graph: this.buildInefficiencyReasongraphs(response.data.data,'Motivo Ineficiência','Estabelecimento Fechado','Erro Stone','Endereço Incorreto','Desistiu do Serviço','Outros','Equipamento não apresenta defeito','Problema do EC','Incidente operação'),
          loaded :true
        },
        inefficiencySlaReason:{
          graph: this.buildInefficiencyReasonSlagraphs(response.data.data,'Motivo Ineficiência - SLA','Estabelecimento Fechado','Endereço Incorreto','Desistiu do Serviço','Equipamento não apresenta defeito','Problema do EC','Incidente operação'),
          loaded :true
        },
      })
    })

    LogisticService.getLigaIneficiencia('week',null,date_strat_week).then((response)=>{
      this.setState({
        inefficiencyWeek:{
          graph: this.buildTwoValuesGraphsWeek(response.data.data,'Ineficiência Histórica - Semanal','Própria','Paytec',false),
          loaded :true
        },
        inefficiencySlaWeek:{
          graph: this.buildTwoValuesGraphsWeek(response.data.data,'Ineficiência Histórica - Semanal SLA','Própria','Paytec',true),
          loaded :true
        }
      })
    })
  }


  buildLineGraph(response,color,name) {
      let title     = []
      let label    = []
      let dataset = []
      let datas = []

      for(let i = 0; i < response.length;i++){
        if(label.indexOf(response[i].date) === -1)
          label.push(response[i].date)
      }
      
      title.push(name)
      for(let i = 0; i <title.length ; i++){
        let data  = []
        let value = i*label.length;
        for(let j = value; j < value + label.length; j++){
          data.push(
            (response[j].value)
          )
        }

        datas.push(data)
      }
      for(let i = 0; i <title.length ; i++){
        let data  = []
        let value = i*label.length;
        for(let j = value; j < value + label.length; j++){
          data.push(
            (response[j].tolerance)
          )
        }
        datas.push(data)
      }

      title.push('Meta')

        for(let i = 0; i < title.length;i++){
          dataset.push(
            {
              label: title[i],
              data : datas[i],
              fill: false,          // Don't fill area under the line
              borderColor: color[i],  // Line color
              borderDash: [0,0] 
            }
          )
        }
        const dataGraph = {
        labels: label,
        datasets: dataset
      }

    return dataGraph
  }

  renderDashPrincipalTop(){
    if(this.state.slaAttendance.loaded &&this.state.inefficiency.loaded && this.state.posRegain.loaded && this.state.processAccuracy.loaded && this.state.slaOff.loaded){
      return(
        <div style={{marginBottom:'2%',fontFamily: 'GoBold'}}>
          <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Col  lg xl = {2}> 
                    <SimpleBox
                      header='SLA Atendimento'
                      direction ='normal'
                      result={this.state.slaAttendance.data === undefined || this.state.slaAttendance.data.length === 0 ? 0 : this.state.slaAttendance.data[0].value}
                      goal={0}
                      percentage={true}
                      decimal={2}
                    />
                  </Col>
                  {/* <Col  lg xl = {2}> 
                    <SimpleBox
                      header='SLA das Filas do SF'
                      direction ='normal'
                      result={this.state.slaOff.data === undefined || this.state.slaOff.data.length === 0 ? 0 : this.state.slaOff.data[0].value}
                      goal={0}
                      percentage={true}
                      decimal={2}
                    />
                  </Col> */}
                  <Col  lg xl = {2}> 
                    <SimpleBox
                      header='Ineficiência'
                      direction ='normal'
                      result={this.state.inefficiency.data[0] === undefined ? 0 : this.state.inefficiency.data[0].value}
                      goal={0}
                      percentage={true}
                      decimal={2}
                    />
                  </Col>
                  <Col  lg xl = {2}> 
                    <SimpleBox 
                      header='Terminais Recuperados'
                      direction ='normal'
                      result={this.state.posRegain.data[0] === undefined ? 0 : this.state.posRegain.data[0].value}
                      goal={0}
                      percentage={false}
                      decimal={0}
                    />
                  </Col>
                  {/* <Col  lg xl = {2}> 
                    <SimpleBox
                      header='Acuracidade dos Processos'
                      direction ='normal'
                      result={this.state.processAccuracy.data[0] === undefined ? 0 : this.state.processAccuracy.data[0].value}
                      goal={0}
                      percentage={true}
                      decimal={2}
                    />
                  </Col> */}
              </Row>
          </div>
      )
    }
    else
      return(
        <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
            <LoadingSpinner/>
        </div>
      )
  }

  inputChatLigaButton() {
    this.setState({
      inputChatLiga: true
    })
  }

  cadastrarChatLiga(ano, mes, intime, total){
    let info = {};
    info.ano = ano;
    info.mes = mes;
    info.intime = intime;
    info.total = total;
    LogisticService.postChatLiga(info).then(() => {
      this.setState({
        inputChatLiga: false,
      });
      alert("Cadastrado com sucesso!");
    })
  }

  renderDashPrincipalBottom(){
    return(
      <div style={{marginBottom:'2%',fontFamily: 'GoBold'}}>
          <CustomizedExpansionPannel
          title="Evolução Histórica SLA - Atendimento"
          content={
            <div className='graphDivDuo'>
              <Button
                onClick={e => this.inputChatLigaButton() }
                variant="contained"
                style={{
                  background: 'rgb(40, 167, 69)',
                  color: 'white',
                  fontFamily: 'GoBold',
                  padding: '8px 24px',
                  margin: '18px',
                  fontSize: '16px',
                }}
              >Adicionar quantidade Chat</Button>
                {/* Evolução 1 */}                     
                {this.state.monthlyEvolutionOnTime.loaded && this.state.evolutionTwo.loaded?
                <Row> 
                  <Col lg xl = "12" xs sm md = "12">  
                  <h3 style={{textAlign: 'center','fontFamily': 'GoBold'}}>Evolução Histórica SLA Atendimento</h3>      
                    <div>
                      <Graph
                        data={this.state.monthlyEvolutionOnTime.graph.data}
                        legend={this.state.monthlyEvolutionOnTime.graph.legend}
                        options={this.state.monthlyEvolutionOnTime.graph.options}
                        height={300}
                      />
                    </div>                
                  </Col>
                  {/* <Col lg xl = "6" xs sm md = "12">      
                    <h3 style={{textAlign: 'center','fontFamily': 'GoBold'}}>Evolução Histórica SLA das Filas do SF</h3>   
                    <div>
                      <Graph
                        data={this.state.evolutionTwo.graph.data}
                        legend={this.state.evolutionTwo.graph.legend}
                        options={this.state.evolutionTwo.graph.options}
                        height={300}
                      />
                    </div>                  
                  </Col>   */}
              </Row>   
                :
                <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                  <LoadingSpinner/>
                </div>
                }
            </div>
          }/>

          <CustomizedExpansionPannel
          title="Evolução Histórica - Ineficiência /  Terminais Recuperados"
          content={
              this.state.evolutionThree.loaded && this.state.evolutionFour.loaded?
              <div className='graphDivDuo'>
                <Row> 
                  <Col lg xl = "6" xs sm md = "12">   
                    <h3 style={{textAlign: 'center','fontFamily': 'GoBold'}}>Evolução Histórica Ineficiência</h3>         
                    <div>
                      <Graph
                        data={this.state.evolutionThree.graph.data}
                        legend={this.state.evolutionThree.graph.legend}
                        options={this.state.evolutionThree.graph.options}
                        height={300}
                      />
                    </div>               
                  </Col>                   
                {/* Evolução 4 */}
                  <Col lg xl = "6" xs sm md = "12">                
                    <h3 style={{textAlign: 'center','fontFamily': 'GoBold'}}>Evolução Histórica Terminais Recuperados</h3>   
                    <div>
                      <Graph
                        data={this.state.evolutionFour.graph.data}
                        legend={this.state.evolutionFour.graph.legend}
                        options={this.state.evolutionFour.graph.options}
                        height={300}
                      />
                    </div>        
                  </Col>     
                </Row>
              </div>
                :
                <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                    <LoadingSpinner/>
                </div>
                }/>
          {/* Evolução 5 */}
          {/* <CustomizedExpansionPannel
          title="Evolução Histórica Acuracidade"
          content={
              <div className='graphDiv'>
                                        
                  {this.state.evolutionFive.loaded?
                    <Row>
                      <Col lg xl = "12" xs sm md = "12">     
                        <h3 style={{textAlign: 'center','fontFamily': 'GoBold'}}>Evolução Histórica Acuracidade dos Processos</h3>    
                        <div>
                          <Graph
                          data={this.state.evolutionFive.graph.data}
                          legend={this.state.evolutionFive.graph.legend}
                          options={this.state.evolutionFive.graph.options}
                          height={250}
                          />
                        </div>       
                      </Col>   
                    </Row>  
                  :
                    <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                        <LoadingSpinner/>
                    </div>
                  }
                
              </div>   
          }/> */}


        {this.state.inputChatLiga ? this.renderInputChatLiga() : <div></div>}
      </div>
    )
  }

  renderInputChatLiga() {
    return (

      <Modal open={true} onClose={e => this.setState({ inputChatLiga: false })} showCloseIcon={true} center={true} className="inputChatLigaModal">
        <p style={{ color: "black", fontSize: "20px"}}>Chat</p>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "2em", width: 'fitContent' }}>
          <Row>
            <Col>
              <Form.Label size="md">Ano</Form.Label>
              <Form.Control id="anoChatLiga" type="number" size="sm" placeholder="Ano" onInput={e => { }} />
            </Col>
            <Col>
              <Form.Label size="md">Mês</Form.Label>
              <Form.Control id="mesChatLiga" type="number" size="sm" placeholder="Mês" onInput={e => { }} />
            </Col>
            <Col>
              <Form.Label size="md">Intime</Form.Label>
              <Form.Control id="intimeChatLiga" type="number" size="sm" placeholder="Intime" onInput={e => { }} />
            </Col>
            <Col>
              <Form.Label size="md">Total</Form.Label>
              <Form.Control id="totalChatLiga" type="number" size="sm" placeholder="Total" onInput={e => { }} />
            </Col>
            <Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button
                onClick={e => {
                  this.cadastrarChatLiga(
                    document.getElementById("anoChatLiga").value,
                    document.getElementById("mesChatLiga").value,
                    document.getElementById("intimeChatLiga").value,
                    document.getElementById("totalChatLiga").value
                  ) 
                }}
                variant="contained"
                style={{
                  background: 'rgb(40, 167, 69)',
                  color: 'white',
                  fontFamily: 'GoBold',
                  padding: '8px 24px',
                  margin: '18px',
                  fontSize: '16px',
                }}
              >Cadastrar</Button>
            </Col>

          </Row>

        </div>
      </Modal>

    )
  }

  renderDashOnTop(){
    return(
      <div style={{fontFamily: 'GoBold'}}>
        <CustomizedExpansionPannel
         title="Visão Geral - On Time"
          content={
            this.state.slaAttendance.loaded && this.state.answeredAttendance.loaded && this.state.MtdEvolutionOnTime.loaded?
              <div >
                <Row>
                  <Col  lg xl = {1}/> 
                    <Col  lg xl = {2} className='topBox'> 
                        <SimpleBox
                            header='SLA Atendimento - MTD'
                            direction ='normal'
                            result={(this.state.slaAttendance.data === undefined || this.state.slaAttendance.data.length == 0) ? 0 : this.state.slaAttendance.data[0].value}
                            goal={0}
                            percentage={true}
                            decimal={2}
                          />
                    </Col>
                    <Col  lg xl = {2} className='topBox'> 
                      <SimpleBox
                        header='% Atendidas Ligação - MTD'
                        direction ='normal'
                        result={(this.state.answeredAttendance.data == undefined || this.state.answeredAttendance.data.filter(data => data.departament ==='8030' && data.type===1).length == 0)? 0: this.state.answeredAttendance.data.filter(data => data.departament ==='8030' && data.type===1)[0].percAttendance}
                        goal={0}
                        percentage={true}
                        decimal={2}
                      />
                    </Col>
                    <Col  lg xl = {2} className='topBox'> 
                      <SimpleBox
                        header='Nível de serviço Ligação - MTD'
                        direction ='normal'
                        result={(this.state.answeredAttendance.data == undefined || this.state.answeredAttendance.data.filter(data => data.departament ==='8030' && data.type===1).length == 0)? 0:this.state.answeredAttendance.data.filter(data => data.departament ==='8030' && data.type===1)[0].nsAttendance}
                        goal={0}
                        percentage={true}
                        decimal={2}
                      />
                    </Col>
                    <Col  lg xl = {2} className='topBox'> 
                      <SimpleBox
                        header='% Atendidas Chat - MTD'
                        direction ='normal'
                        result={this.state.answeredAttendance.data == undefined || this.state.answeredAttendance.data.filter(data => data.departament ==='8030' && data.type===1).length == 0? 0:this.state.answeredAttendance.data.filter(data => data.departament !=='8030' && data.type===1)[0].percAttendance}
                        goal={0}
                        percentage={true}
                        decimal={2}
                      />
                    </Col>
                    <Col  lg xl = {2} className='topBox'> 
                      <SimpleBox
                        header='Nível de serviço chat - MTD'
                        direction ='normal'
                        result={this.state.answeredAttendance.data == undefined || this.state.answeredAttendance.data.filter(data => data.departament ==='8030' && data.type===1).length == 0? 0:this.state.answeredAttendance.data.filter(data => data.departament !=='8030' && data.type===1)[0].nsAttendance}
                        goal={0}
                        percentage={true}
                        decimal={2}
                      />
                    </Col>
                  </Row>
                  <Row>
                  <Col  lg xl = {1}/> 
                    <Col  lg xl = {2} className='topBox'> 
                        <SimpleBox
                            header='SLA Atendimento - D1'
                            direction ='normal'
                            result={this.state.MtdEvolutionOnTime.data[this.state.MtdEvolutionOnTime.data.length - 2] !== undefined?this.state.MtdEvolutionOnTime.data[this.state.MtdEvolutionOnTime.data.length - 2].value:0}
                            goal={0}
                            percentage={true}
                            decimal={2}
                          />
                    </Col>
                    <Col  lg xl = {2} className='topBox'> 
                      <SimpleBox
                        header='% Atendidas Ligação - D1'
                        direction ='normal'
                        result={this.state.answeredAttendance.data.filter(data=> data.type===2)[0] !== undefined? this.state.answeredAttendance.data.filter(data => data.type===2)[0].percAttendance:0}
                        goal={0}
                        percentage={true}
                        decimal={2}
                      />
                    </Col>
                    <Col  lg xl = {2} className='topBox'> 
                      <SimpleBox
                        header='NS Ligação - D1'
                        direction ='normal'
                        result={this.state.answeredAttendance.data.filter(data=> data.type===2)[0] !== undefined? this.state.answeredAttendance.data.filter(data => data.type===2)[0].nsAttendance:0}
                        goal={0}
                        percentage={true}
                        decimal={2}
                      />
                    </Col>
                    <Col  lg xl = {2} className='topBox'> 
                      <SimpleBox
                        header='% Atendidas Chat - D1'
                        direction ='normal'
                        result={this.state.answeredAttendance.data.filter(data=> data.type===2)[0] !== undefined? this.state.answeredAttendance.data.filter(data => data.type===2)[0].percAttendance:0}
                        goal={0}
                        percentage={true}
                        decimal={2}
                      />
                    </Col>
                    <Col  lg xl = {2} className='topBox'> 
                      <SimpleBox
                        header='NS chat - D1'
                        direction ='normal'
                        result={this.state.answeredAttendance.data.filter(data=> data.type===2)[0] !== undefined? this.state.answeredAttendance.data.filter(data => data.type===2)[0].nsAttendance:0}
                        goal={0}
                        percentage={true}
                        decimal={2}
                      />
                    </Col>
                  </Row>
              </div>
              :
            <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
              <LoadingSpinner/>
            </div>
          }
          helpMessage={
            <p>
              <b>SLA de Atendimento</b>
                <p>(Ligações atendidas com tempo de espera {'<='} 20s + Chats com tempo de espera {'<='} 30s) / (Total de ligações atendidas e perdidas + Total de chats atendidos e perdidos)</p>
            </p>}
            tooltip={true}
          />
          <CustomizedExpansionPannel
          title="Evolução MTD SLA Atendimento"
          content={
            this.state.MtdEvolutionOnTime.loaded?
            <div className='divGraph'>
                <Row> 
                  <Col lg xl = "12" xs sm md = "12">     
                      <div>
                        <Graph
                          data={this.state.MtdEvolutionOnTime.graph.data}
                          legend={this.state.MtdEvolutionOnTime.graph.legend}
                          options={this.state.MtdEvolutionOnTime.graph.options}
                          height={300}
                        />
                      </div>                
                    </Col>  
                  </Row>
            </div>
            :
            <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
              <LoadingSpinner/>
            </div>
          }
        />
        </div>
    )
  }

  renderDashOnBottom(){
    
        //Title
        let titleMethodsD1 = [{name:"Produtividade D-1",icon:''}]       

        //Sub Titles        
        let subTitleMethodsD1 = [{name:"Agente", icon:""},{name:"Total Atendidas", icon:""}]

        //Title
        let titleMethods = [{name:"Produtividade Mês",icon:''}]       

        //Sub Titles        
        let subTitleMethods = [{name:"Agente", icon:""},{name:"Total Atendidas", icon:""}]

        let dataMethodsM0 = []
        let dataMethodsD1 = []
        if(this.state.productivity.loaded && this.state.productivityD1.loaded){
          this.state.productivity.data.filter(data => data.nickName !=='Total').forEach(information =>(
              dataMethodsM0.push(
                [
                  information.nickName,
                  information.total
                ]           
              )
          ))
          this.state.productivityD1.data.filter(data => data.nickName !=='Total').forEach(information =>(
            dataMethodsD1.push(
                [
                  information.nickName,
                  information.total
                ]           
              )
          ))
        }
    return(
      <div style={{marginBottom:'2%',fontFamily: 'GoBold'}}>
        {this.state.productivity.loaded && this.state.productivityD1.loaded?
          <CustomizedExpansionPannel
          title="Visão Agente"
          content={
            
            <div className='tableAgents'>
              <Row> 
                {/* Evolução 1 */}              
                  <Col lg xl = "6" xs sm md = "12">             
                    <TablePaging 
                      data ={dataMethodsD1} 
                      title ={titleMethodsD1}
                      subTitle ={subTitleMethodsD1} 
                      colSpan ={"2"}
                      totalPages = {0}
                      rowSpan = {8}
                      >
                    </TablePaging>
                  </Col>   
                {/* Evolução 2 */}
                <Col lg xl = "6" xs sm md = "12">  
                    <TablePaging 
                      data ={dataMethodsM0} 
                      title ={titleMethods}
                      subTitle ={subTitleMethods} 
                      colSpan ={"2"}
                      totalPages = {0}
                      rowSpan = {8}
                      >
                    </TablePaging>   
                  </Col> 
              </Row>
            </div>
          }/>   
          :
          <CustomizedExpansionPannel
          content={
          <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
            <LoadingSpinner/>
          </div> 
          } />
        }           
      </div>
    )
  }


  renderDashOffTop(){
    
    return(
      <div style={{fontFamily: 'GoBold'}}>
          <CustomizedExpansionPannel
          content={
            <div className='divGraph'>
                <Row> 
                    <Col md lg xl = {12}> 
                          {this.state.evolutionTwo.loaded?
                        <Col lg xl xs sm md = "12">             
                          <h3 style={{textAlign: 'center','fontFamily': 'GoBold'}}>Evolução Histórica SLA das Filas do SF</h3>   
                          <div>
                            <Graph
                              data={this.state.evolutionTwo.graph.data}
                              legend={this.state.evolutionTwo.graph.legend}
                              options={this.state.evolutionTwo.graph.options}
                              height={500}
                            />
                          </div>    
                        </Col>     
                      :
                      <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                        <LoadingSpinner/>
                      </div>
                      }
                    </Col>
                  </Row>
            </div>
          }
        />
        </div>
    )
  }

  renderDashOffBottom(){
    
        //Title
        let titleMethodsD1 = [{name:"Distribuição da demanda D-1",icon:''}]       

        //Sub Titles        
        let subTitleMethodsD1 = [{name:"Agente", icon:""},{name:"Demandas", icon:""}]

        //Title
        let titleMethods = [{name:"Distribuição da demanda Mês",icon:''}]       

        //Sub Titles        
        let subTitleMethods = [{name:"Agente", icon:""},{name:"Média Demandas Mês", icon:""}]

    return(
      <div style={{marginBottom:'2%',fontFamily: 'GoBold'}}>
          <CustomizedExpansionPannel
          content={
            <div className='graphDivDuo'>
              <Row> 
                {/* Evolução 1 */}                     
                {this.state.evolutionTwo.loaded?
                  <Col lg xl = "5" xs sm md = "12">             
                    <TablePaging 
                      data ={['']} 
                      title ={titleMethodsD1}
                      subTitle ={subTitleMethodsD1} 
                      colSpan ={"2"}
                      totalPages = {0/*(capthureMethods.length / 5) + (capthureMethods.length % 5 == 0 ? 0 : 1)*/}
                      rowSpan = {5}
                      >
                    </TablePaging>
                  </Col>     
                :
                <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                  <LoadingSpinner/>
                </div>
                }
                {/* Evolução 2 */}
                {this.state.evolutionTwo.loaded?
                <Col lg xl = "5" xs sm md = "12">  
                    <TablePaging 
                      data ={['']} 
                      title ={titleMethods}
                      subTitle ={subTitleMethods} 
                      colSpan ={"2"}
                      totalPages = {0/*(capthureMethods.length / 5) + (capthureMethods.length % 5 == 0 ? 0 : 1)*/}
                      rowSpan = {5}
                      >
                    </TablePaging>   
                  </Col> 
                :
                <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                  <LoadingSpinner/>
                </div>
                }
              </Row>
            </div>
          }/>                
      </div>
    )
  }

  buildInefficiencyReasongraphs(obj,upperTitle,titleOne,titleTwo,titleThree,titleFour,titleFive,titleSix,titleSeven,titleEight){
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: titleOne,
            stack: 'Stack 0',
            backgroundColor: '#1CE000',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleTwo,
            stack: 'Stack 0',
            backgroundColor: '#37D121',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleThree,
            stack: 'Stack 0',
            backgroundColor: '#27A814',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleFour,
            stack: 'Stack 0',
            backgroundColor: '#228913',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleFive,
            stack: 'Stack 0',
            backgroundColor: '#D2D2D2',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleSix,
            stack: 'Stack 0',
            backgroundColor: '#B8BBB8',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleSeven,
            stack: 'Stack 0',
            backgroundColor: '#8F928F',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleEight,
            stack: 'Stack 0',
            backgroundColor: '#7B7B7B',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: upperTitle,
        },
        plugins: {
          datalabels: {
             // display: function(graphObj) {
            //   return graphObj.dataset.data[graphObj.dataIndex]> 0
            // },
            display:false,
            font: {
              size: 14,
            },
            color: 'black',
            formatter: function(value) {
              return parseFloat(value).toFixed(1)
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
      },
    }
    _.orderBy(obj, 'date').forEach((element) => {
      graphObj.data.labels.push(element.date)
      graphObj.data.datasets[0].data.push(element.estabelecimentoFechadoPorc)
      graphObj.data.datasets[1].data.push(element.erroStonePorc)
      graphObj.data.datasets[2].data.push(element.enderecoIncorretoPorc)
      graphObj.data.datasets[3].data.push(element.desistiuServicoPorc)
      graphObj.data.datasets[4].data.push(element.outrosPorc)
      graphObj.data.datasets[5].data.push(element.equipNDefeitoPorc)
      graphObj.data.datasets[6].data.push(element.problemasEcPorc)
      graphObj.data.datasets[7].data.push(element.incidenteOperaçãoPorc)
    })
    return graphObj
  }

  buildInefficiencyReasonSlagraphs(obj,upperTitle,titleOne,titleTwo,titleThree,titleFour,titleFive,titleSix){
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: titleOne,
            stack: 'Stack 0',
            backgroundColor: '#1CE000',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleTwo,
            stack: 'Stack 0',
            backgroundColor: '#37D121',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleThree,
            stack: 'Stack 0',
            backgroundColor: '#27A814',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleFour,
            stack: 'Stack 0',
            backgroundColor: '#D2D2D2',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleFive,
            stack: 'Stack 0',
            backgroundColor: '#B8BBB8',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleSix,
            stack: 'Stack 0',
            backgroundColor: '#8F928F',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: upperTitle,
        },
        plugins: {
          datalabels: {
            // display: function(graphObj) {
            //   return graphObj.dataset.data[graphObj.dataIndex]> 0
            // },
            display:false,
            font: {
              size: 14,
            },
            color: 'black',
            formatter: function(value) {
              return parseFloat(value).toFixed(1)
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
      },
    }
    _.orderBy(obj, 'date').forEach((element) => {
      graphObj.data.labels.push(element.date)
      graphObj.data.datasets[0].data.push(element.estabelecimentoFechadoPorcSla)
      graphObj.data.datasets[1].data.push(element.enderecoIncorretoPorcSla)
      graphObj.data.datasets[2].data.push(element.desistiuServicoPorcSla)
      graphObj.data.datasets[3].data.push(element.equipNDefeitoPorcSla)
      graphObj.data.datasets[4].data.push(element.problemasEcPorcSla)
      graphObj.data.datasets[5].data.push(element.incidenteOperaçãoPorcSla)
    })
    return graphObj
  }
  buildTwoValuesGraphs(obj,upperTitle,titleOne,titleTwo,sla){
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: titleOne,
            stack: 'Stack 0',
            backgroundColor: '#0bd457',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleTwo,
            stack: 'Stack 0',
            backgroundColor: '#D2D2D2',
            borderWidth: 2,
            data: [],
          },

        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: upperTitle,
        },
        plugins: {
          datalabels: {
             display: function(graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex]> 0
            },
            font: {
              size: 14,
            },
            color: 'black',
            formatter: function(value) {
              return parseFloat(value).toFixed(1)
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
      },
    }
     if(sla){
      _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(element.date)
        graphObj.data.datasets[0].data.push(element.valueonesla)
        graphObj.data.datasets[1].data.push(element.valuetwosla)
      })
    }
    else{
      _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(element.date)
        graphObj.data.datasets[0].data.push(element.valueone)
        graphObj.data.datasets[1].data.push(element.valuetwo)
      })
    }
    return graphObj
  }

  buildFrontPageGraph(obj,upperTitle,titleOne,titleTwo){
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: titleOne,
            stack: 'Stack 0',
            backgroundColor: '#0bd457',
            borderWidth: 2,
            data: [],
          },
          // {
          //   type: 'line',
          //   label: titleTwo,
          //   stack: 'Stack 0',
          //   fill: false,   
          //   borderColor: '#D2D2D2',
          //   borderWidth: 2,
          //   data: [],
          // },

        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: upperTitle,
        },
        plugins: {
          datalabels: {
             display: function(graphObj) {
               if(graphObj.dataset.type === "line"){
                return false
               }
              else
                return graphObj.dataset.data[graphObj.dataIndex]> 0
            },
            font: {
              size: 14,
            },
            color: 'black',
            formatter: function(value) {
              return parseFloat(value).toFixed(1)
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
      },
    }
    _.orderBy(obj, 'date').forEach((element) => {
      graphObj.data.labels.push(element.date)
      graphObj.data.datasets[0].data.push(element.value)
      // graphObj.data.datasets[1].data.push(0)
    })
    return graphObj
  }

  buildInefficiencyServicegraphs(obj,upperTitle,titleOne,titleTwo,titleThree,titleFour,sla){
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: titleOne,
            stack: 'Stack 0',
            backgroundColor: '#37D121',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleTwo,
            stack: 'Stack 0',
            backgroundColor: '#228913',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleThree,
            stack: 'Stack 0',
            backgroundColor: '#B8BBB8',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleFour,
            stack: 'Stack 0',
            backgroundColor: '#8F928F',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: upperTitle,
        },
        plugins: {
          datalabels: {
            display:false,
            font: {
              size: 14,
            },
            color: 'black',
            formatter: function(value) {
              return parseFloat(value).toFixed(1)
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
      },
    }
    if(sla){
      _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(element.date)
        graphObj.data.datasets[0].data.push(element.inefStoneInstalacaoSla)
        graphObj.data.datasets[1].data.push(element.inefStoneDesinstalacaoSla)
        graphObj.data.datasets[2].data.push(element.inefStoneTrocaSla)
        graphObj.data.datasets[3].data.push(element.inefStoneManutencaoSla)           
      })
    }
    else{
      _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(element.date)
        graphObj.data.datasets[0].data.push(element.inefStoneInstalacao)
        graphObj.data.datasets[1].data.push(element.inefStoneDesinstalacao)
        graphObj.data.datasets[2].data.push(element.inefStoneTroca)
        graphObj.data.datasets[3].data.push(element.inefStoneManutencao)   
      })
    }
    return graphObj
  }
  buildTwoValuesGraphsWeek(obj,upperTitle,titleOne,titleTwo,sla){
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: titleOne,
            stack: 'Stack 0',
            backgroundColor: '#0bd457',
            borderWidth: 2,
            data: [],
          },
          {
            label: titleTwo,
            stack: 'Stack 0',
            backgroundColor: '#D2D2D2',
            borderWidth: 2,
            data: [],
          },

        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: upperTitle,
        },
        plugins: {
          datalabels: {
             display: function(graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex]> 0
            },
            font: {
              size: 14,
            },
            color: 'black',
            formatter: function(value) {
              return parseFloat(value).toFixed(1)
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
      },
    }
    if(sla){
      _.orderBy(obj, 'semana').forEach((element) => {
        graphObj.data.labels.push(element.semana)
        graphObj.data.datasets[0].data.push(element.valueonesla)
        graphObj.data.datasets[1].data.push(element.valuetwosla)
      })
    }
    else{
      _.orderBy(obj, 'semana').forEach((element) => {
        graphObj.data.labels.push(element.semana)
        graphObj.data.datasets[0].data.push(element.valueone)
        graphObj.data.datasets[1].data.push(element.valuetwo)
      })
    }
    return graphObj
  }
  renderInefficiencyTop(){ 
    return(
      <div>
        <CustomizedExpansionPannel
        title="Ineficiência Evolução Histórica"
        content={   
          this.state.inefficiencyHistory.loaded?             
          <div>
            <Row>
              <Col lg xl = "12" xs sm md = "12">  
                <Graph
                  data={this.state.inefficiencyHistory.graph.data}
                  legend={this.state.inefficiencyHistory.graph.legend}
                  options={this.state.inefficiencyHistory.graph.options}
                  height={450}
                  />
              </Col>
            </Row>
          </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
          }
        />
        <CustomizedExpansionPannel
          title="Ineficiência Evolução Semanal"
          content={
            this.state.inefficiencyWeek.loaded?      
            <div>
              <Row>
                <Col lg xl = "12" xs sm md = "12">  
                  <Graph
                    data={this.state.inefficiencyWeek.graph.data}
                    legend={this.state.inefficiencyWeek.graph.legend}
                    options={this.state.inefficiencyWeek.graph.options}
                    height={450}
                    />
                </Col>
              </Row>
            </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
          }
        />
        <CustomizedExpansionPannel
          title="Ineficiência Evolução Motivo"
          content={  
            this.state.inefficiencyReasons.loaded?              
            <div>
              <Row>
                <Col lg xl = "12" xs sm md = "12">  
                  <Graph
                    data={this.state.inefficiencyReasons.graph.data}
                    legend={this.state.inefficiencyReasons.graph.legend}
                    options={this.state.inefficiencyReasons.graph.options}
                    height={450}
                    />
                </Col>
              </Row>
            </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
            }
          />
        <CustomizedExpansionPannel
          title="Ineficiência Histórica - Tipo de Serviço"
          content={ 
            this.state.inefficiencyService.loaded?         
            <div>
              <Row>
                <Col lg xl = "12" xs sm md = "12">  
                  <Graph
                    data={this.state.inefficiencyService.graph.data}
                    legend={this.state.inefficiencyService.graph.legend}
                    options={this.state.inefficiencyService.graph.options}
                    height={450}
                    />
                </Col>
              </Row>
            </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
            }
          />
      </div>
    )
    // else{
    //   return(
    //       <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
    //         <LoadingSpinner/>
    //       </div>
    //   )
    // }
  }
  renderInefficiencyBottom(){ 
    // if(this.state.inefficiencySla.loaded  && this.state.inefficiencySlaWeek.loaded && this.state.inefficiencySlaReason.loaded && this.state.inefficiencySlaService.loaded) 
    return(
      <div>
        <CustomizedExpansionPannel
        title="Ineficiência Histórica - Impacto SLA"
        content={ 
          this.state.inefficiencySla.loaded?               
          <div>
            <Row>
              <Col lg xl = "12" xs sm md = "12">  
                <Graph
                  data={this.state.inefficiencySla.graph.data}
                  legend={this.state.inefficiencySla.graph.legend}
                  options={this.state.inefficiencySla.graph.options}
                  height={450}
                  />
              </Col>
            </Row>
          </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
          }
        />
        <CustomizedExpansionPannel
          title="Ineficiência Histórica - Semanal SLA"
          content={
            this.state.inefficiencySlaWeek.loaded?      
            <div>
                <Row>
                  <Col lg xl = "12" xs sm md = "12">  
                    <Graph
                      data={this.state.inefficiencySlaWeek.graph.data}
                      legend={this.state.inefficiencySlaWeek.graph.legend}
                      options={this.state.inefficiencySlaWeek.graph.options}
                      height={450}
                      />
                  </Col>
                </Row>
            </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
          }
        />
        <CustomizedExpansionPannel
          title="Motivos Ineficiência  - impacto SLA"
          content={  
            this.state.inefficiencySlaReason.loaded?              
            <div>
              <Row>
                <Col lg xl = "12" xs sm md = "12">  
                  <Graph
                    data={this.state.inefficiencySlaReason.graph.data}
                    legend={this.state.inefficiencySlaReason.graph.legend}
                    options={this.state.inefficiencySlaReason.graph.options}
                    height={450}
                    />
                </Col>
              </Row>
            </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
            }
          />
          <CustomizedExpansionPannel
            title="Ineficiência Histórica - Tipo de Serviço SLA"
            content={  
              this.state.inefficiencySlaService.loaded?              
              <div>
                <Row>
                  <Col lg xl = "12" xs sm md = "12">  
                    <Graph
                      data={this.state.inefficiencySlaService.graph.data}
                      legend={this.state.inefficiencySlaService.graph.legend}
                      options={this.state.inefficiencySlaService.graph.options}
                      height={450}
                      />
                  </Col>
                </Row>
              </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
              }
            />
      </div>
    )
    // else{
    //   return(
    //       <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
    //         <LoadingSpinner/>
    //       </div>
    //   )
    // }
  }

  // Render
  render() {
    let refreshContent
    refreshContent = (
      <div>
        <div style={{textAlign: 'right'}}>
          <RefreshButton onClick={() => { this.refreshReportData() }}/>
        </div>
      </div>
    )
    return (
      <Container fluid>
          {this.state.slaAttendance.loaded &&this.state.inefficiency.loaded && this.state.posRegain.loaded && this.state.processAccuracy.loaded && this.state.slaOff.loaded?refreshContent:''}
          <div style={{color: 'black'}}>
            <Tabs defaultActiveKey="principal" id="noanim-tab-example" variant='tabs'>
              <Tab eventKey="principal" title="Dash Principal" tabClassName="tabLink" unmountOnExit='false'>
                <CustomizedExpansionPannel
                  title="Visão Geral"
                  content={
                    <Row>
                      <Col xl sm md lg xl = {12}>
                        {this.renderDashPrincipalTop()}
                      </Col>
                    </Row>
                  }
                  helpMessage={
                  <p>
                    <b>SLA de Atendimento</b>
                      <p>(Ligações atendidas com tempo de espera {'<='} 20s + Chats com tempo de espera {'<='} 30s) / (Total de ligações atendidas e perdidas + Total de chats atendidos e perdidos)</p>
                      
                    <b>SLA das Filas do SF</b>
                      <p>Casos das filas da logística resolvidos dentro do SLA acordado / Todos os casos recebidos pelas filas da logística</p>

                    <b>Ineficiência</b>
                      <p>(OS baixadas com revista(motivos de ineficiência) + OS canceladas com ou sem revista(motivos de ineficiência)) / Total de OS</p>
                      
                    <b>Terminais Recuperados</b>
                      <p>Quantidade de OS de desinstalação abertas pelo time de caça próprio que foram baixadas + quatidade de POS + pinpads dos clientes que foram reativados pelo time de caça próprio</p>
                      
                    <b>Acuricidade dos processos</b>
                      <p>Quantidade de erros operacionais / Total de possibilidades de erros operacionais</p>
                  </p>}
                  tooltip={true}
                />
                <Row>
                      <Col xl sm md lg xl = {12}>
                        {this.renderDashPrincipalBottom()}
                      </Col>
                  </Row>
              </Tab>
              {/* <Tab eventKey="onTime" title="Dash On Time" tabClassName="tabLink" unmountOnExit='false'>
                <Row>
                  <Col xl sm md lg xl = {12}>
                    {this.renderDashOnTop()}
                  </Col>
                </Row>
                <Row>
                  <Col xl sm md lg xl = {12}>
                    {this.renderDashOnBottom()}
                  </Col>
                </Row> 
              </Tab> */}
              {/* <Tab eventKey="offTime" title="Dash Off Time" tabClassName="tabLink" unmountOnExit='false' >
                <Row>
                  <Col xl sm md lg xl = {12}>
                    {this.renderDashOffTop()}
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col xl sm md lg xl = {12}>
                    {this.renderDashOffBottom()}
                  </Col>
                </Row>  */}
              {/* </Tab> */}
              <Tab eventKey="inefficiency" title="Ineficiência" tabClassName="tabLink" unmountOnExit='false' >
                <Row>
                  <Col xl sm md lg xl = {12}>
                    {this.renderInefficiencyTop()}
                  </Col>
                </Row>
                <Row>
                  <Col xl sm md lg xl = {12}>
                    {this.renderInefficiencyBottom()}
                  </Col>
                </Row> 
              </Tab>
            </Tabs>
          </div>
    </Container>
    )
  }
}

export default DashLiga
