/* eslint-disable */
import React, { Component } from 'react'

import LogisticService from '../../../core/services/LogisticService'
import style from '../style.css'
import Graph from '../../../components/Logistic/graphic/graph'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Box from '@material-ui/core/Box';


class ArrivalHour extends Component {
constructor(props) {
    super(props);
    this.state = {
        district: props.district,
        date: props.date,
        arrival:{
        loaded:false
        },
        graph: undefined,
        loading: false,
        lastUpdated: undefined,
    };
    this.refreshReportData = this.refreshReportData.bind(this)
}

refreshReportData() {
    //Get People Daily Productive
    LogisticService.getLmpPeople('arrivalHour',this.state.district,`${this.state.date}-01`,`${this.state.date}-01`).then((response)=>{ 
        this.buildArrivalHourGraph(response.data.data)  
    })
}

componentDidMount() {
    this.refreshReportData()
  }
  

buildArrivalHourGraph(obj) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: "OS's",
            stack: 'Stack 0',
            type: 'bar',
            yAxisID: 'A',
            backgroundColor: '#13aa4c',
            data: [],
            fill: false,
            display:false,
            
          },
          {
            label: '% Total',
            stack: 'Stack 1',
            type: 'line',
            yAxisID: 'B',
            borderColor: 'grey',
            backgroundColor: 'grey',
            pointBorderColor: 'grey',
            pointBackgroundColor: 'grey',
            pointHoverBackgroundColor: 'grey',
            pointHoverBorderColor: 'grey',
            pointRadius : 0,
            borderDash: [1,1],
            data: [],
            fill: false,
            display:false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'B',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
          {
            id: 'A',
            display:false,
            stacked: false,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontFamily: 'GoBold',
          fontSize: 12,
          text: 'Horário de Baixa',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              weight: "bold",
              family: 'GoBold'
            },
            position: 'center',
            align: function (graphObj) {
                return 'center'
            },
            color:'black',
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }
    let total = 0
    obj.forEach((element) => {total += element.os})
    obj.forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.hour))
          graphObj.data.datasets[0].data.push(element.os)
          graphObj.data.datasets[1].data.push((((element.os*1.0)/(total))*100).toFixed(1))
      }
    )
  
    this.setState({
      arrival:{
              graph: graphObj,
              loaded: true
        },
    })
  }
    
    renderContent(){
    if(this.state.arrival.loaded){
        return(
            <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
                <Graph
                data={this.state.arrival.graph.data}
                legend={this.state.arrival.graph.legend}
                options={this.state.arrival.graph.options}
                width={this.state.isDesktop ? 400 : 310}
                height={340}
                />
            </Box>
        )
    }
    else{
        return(
            <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
            </div>
        )
    }
    }

    render(){
        return(this.renderContent())
    }


}
export default ArrivalHour