/* eslint-disable */
import React, { Component } from 'react'
import moment from 'moment'
import SummaryIdt from '../IDTReport/SummaryIdt'
import LogisticService from '../../core/services/LogisticService'
import RefreshButton from '../../custom-components/RefreshButton'
import Graph from '../../components/Logistic/graphic/graph'
import LoadingSpinner from '../../components/LoadingSpinner'
import { Container, Col, Row, Form } from 'react-bootstrap'
import { Statistic, Icon, Card} from 'antd'
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import TableCell from '@material-ui/core/TableCell';
import CustomizedExpansionPannel from '../../custom-components/CustomizedExpansionPanel/index'
import './style.css'
import Box from '@material-ui/core/Box';
import CustomizedTable from '../../custom-components/CustomizedTable'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#103a21',
    color: 'white',
    fontSize: '0.9em',
  },
  body: {
    fontSize: '0.8em',
  },
}))(TableCell);

const StyledSubTableCell = withStyles(theme => ({
  head: {
    backgroundColor: 'black',
    color: 'white',
    fontSize: '0.7em',
  },
  body: {
    fontSize: '0.6em',
  },
}))(TableCell);

const SimpleBox = props => {
  const { header, result, goal, direction, percentage } = props
  let headlightSymbol;
  let headlightColor;
  return (
      <Card>
        <Statistic
          title={<h3 style={{fontFamily: 'GoBold'}}>{header}</h3>}
          value={result + (percentage ? '%' : '')}
          valueStyle={{ color: {headlightColor} }}
        />
    </Card>
    )
}

class IDTReport extends Component {

  static getClassNameForStatus (status) {
    if (status > 10) return <span class="redDot"></span>
    if (status >= 0) return <span class="yellowDot"></span>
    if (status < 0) return <span class="greenDot"></span>
    return <span class="grayDot"></span>  
  }


  constructor () {
    super()

    this.state = {
      selectedScreening:'-1',
      idtValues:{
        loaded:false
      },
      onboardingValues:{
        loaded:false
      },
      onboardingScreening:{
        loaded:false
      },
      monthRankingGraph: {
        loaded: false
      },
      weekRankingGraph: {
        loaded: false
      },
      monthSparePartGraph: {
        loaded: false
      },
      monthIdtTable:{
        loaded: false
      },

    }

    this.refreshReportData = this.refreshReportData.bind(this)
  }

  clearStates(){
  this.setState({
    idtValues:{
      data:false,
      loaded:false
    },
    onboardingValues:{
      data:false,
      loaded:false
    },
    onboardingScreening:{
      loaded:false
    },
    monthRankingGraph: {
      loaded: false,
      graph: undefined
    },
    weekRankingGraph: {
      loaded: false,
      graph: undefined
    },
    monthSparePartGraph: {
      loaded: false,
      graph: undefined
    },
    monthIdtTable:{
      loaded: false,
      graph: undefined
    }
  })
}

  componentDidMount() {
    this.refreshReportData()
  }

  refreshReportData() {
    this.state.loading = true
    this.state.lastUpdated = undefined
    let today = new Date()
    today.setDate(today.getMonth() - 1)
    today.setFullYear(today.getFullYear() - 1)
    let date = today.getFullYear().toString()+'-'+today.getMonth().toString()+'-01'
    

    
    LogisticService.getIDTOnBoarding(date,'week')
      .then((onBoarding) => {
        this.buildWeekRankigGraph(onBoarding.data.data)
      })

    LogisticService.getIDTOnBoarding(null,null)
    .then((onBoarding) => {
      this.setState({
        monthIdtTable:{
          data   : onBoarding.data.data,
          loaded : true
        }
      })
    })

    LogisticService.getIDTOnBoarding(null,'screening')
    .then((onBoarding) => {
      this.setState({
        onboardingScreening:{
          data   : onBoarding.data.data,
          loaded : true
        }
      })
    })

    LogisticService.getIdtDefect('-1',date,null,'month')
    .then((response) => {
      this.setState({
        idtValues:{
          data:response.data.data,
          loaded:true
        }
      })

      LogisticService.getIDTOnBoarding(date,'month')
      .then((onBoarding) => {
        this.setState({
          onboardingValues:{
            data:onBoarding.data.data,
            loaded:true
          }
        })
        this.buildMonthRankigGraph(response.data.data,onBoarding.data.data)
      })
    })
  }

  buildWeekRankigGraph(obj) {

  var graphObj =
  {
    data: {
      labels: [],
      datasets: [
        {
          label: 'IDT',
          stack: 'Stack 0',
          backgroundColor: '#27e32d',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
        },
        {
          label: 'Preferência outro equipamento',
          stack: 'Stack 0',
          backgroundColor: '#21b826',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
        },
        {
          label: 'Spare Part',
          stack: 'Stack 0',
          backgroundColor: '#1a8a1e',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
        },
        {
          label: 'IDM',
          stack: 'Stack 0',
          backgroundColor: '#146117',
          data: [],
          yAxisID: 'B',
        },
        {
          label: 'IDR',
          stack: 'Stack 0',
          backgroundColor: 'white',
          data: [],
          yAxisID: 'B',
        }
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          categoryPercentage: 0.9,
          gridLines: {
            display: false,
          },
        }],
        yAxes: [
        {
          id: 'B',
          display:false,
          stacked: true,
          anchor: 'end',
          align: 'top',
          ticks: {
            min: 0,
            callback: function(value) {
              return `${value.toFixed(2)}%`
            },
          },
        }],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: true,
        fontColor: 'black',
        fontSize: 20,
        text: 'IDR Semanal',
      },
      plugins:{
        datalabels: {
          display: function (graphObj) {
            return graphObj.dataset.data[graphObj.dataIndex] > 0
          },
          font: {
            size: 12,
            weight: 'bold'
          },
          position: 'bottom',
          align: 'center',
          color:'black',
          formatter: function (value) {
            return value
          }
        },
      },

    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'black',
      },
    },
  }
  _.orderBy(obj, 'week').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.week))
        graphObj.data.datasets[0].data.push((100*element.idt/element.parqueNovo).toFixed(2))
        graphObj.data.datasets[1].data.push((100*element.change/element.parqueNovo).toFixed(2))
        graphObj.data.datasets[2].data.push((100*element.sparepart/element.parqueNovo).toFixed(2))
        graphObj.data.datasets[3].data.push((100*element.chip/element.parqueNovo).toFixed(2))
        graphObj.data.datasets[4].data.push(((100*element.idt/element.parqueNovo)+(100*element.sparepart/element.parqueNovo)+(100*element.change/element.parqueNovo)+(100*element.chip/element.parqueNovo)).toFixed(2))
    }
  )

  this.setState({
    weekRankingGraph:{
      graph: graphObj,
      loaded: true
    },
    lastUpdated : moment()
  })
}

renderWeekRankigGraph() {
  if (this.state.weekRankingGraph.loaded) {
    return (
      <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
        <Graph
          data={this.state.weekRankingGraph.graph.data}
          legend={this.state.weekRankingGraph.graph.legend}
          options={this.state.weekRankingGraph.graph.options}
          width={this.state.isDesktop ? 400 : 310}
          height={380}
        />
      </Box>)
  }

  return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
    <LoadingSpinner />
  </div>)
  }

buildMonthRankigGraph(idt,onboarding) {
  var graphObj =
  {
    data: {
      labels: [],
      datasets: [
        {
          label: 'IDT',
          stack: 'Stack 0',
          backgroundColor: '#c7c7c7',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'PREFERÊNCIA',
          stack: 'Stack 0',
          backgroundColor: '#adadad',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'SPARE PART',
          stack: 'Stack 0',
          backgroundColor: '#969696',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'IDM',
          stack: 'Stack 0',
          backgroundColor: '#757575',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'IDR',
          stack: 'Stack 0',
          backgroundColor: 'white',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'IDT ONBOARDING',
          stack: 'Stack 1',
          backgroundColor: '#27e32d',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'PREFERÊNCIA ONBOARDING',
          stack: 'Stack 1',
          backgroundColor: '#21b826',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'SPARE PART ONBOARDING',
          stack: 'Stack 1',
          backgroundColor: '#1a8a1e',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'IDM ONBOARDING',
          stack: 'Stack 1',
          backgroundColor: '#146117',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
        {
          label: 'IDR ONBOARDING',
          stack: 'Stack 1',
          backgroundColor: 'white',
          data: [],
          yAxisID: 'B',
          fill: false,
          display:false,
          
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          categoryPercentage: 0.9,
          gridLines: {
            display: false,
          },
        }],
        yAxes: [
        {
          id: 'B',
          display:false,
          stacked: true,
          anchor: 'end',
          align: 'top',
          ticks: {
            min: 0,
            callback: function(value) {
              return `${value.toFixed(2)}%`
            },
          },
        }
      ]
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: true,
        fontColor: 'black',
        fontSize: 20,
        text: 'IDR Mensal',
      },
      plugins:{
        datalabels: {
          display: function (graphObj) {
            if(graphObj.dataset.label !== 'Meta')
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            else
              return false
          },
          font: {
            size: 12,
            weight: 'bold'
          },
          position: 'bottom',
          align: 'center',
          color:'black',
          
        },
      },

    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'black',
      },
    },
  }
  _.orderBy(idt, 'month').forEach((element) => {
      graphObj.data.labels.push(" ".concat(element.month))
      graphObj.data.datasets[0].data.push((100*element.total/element.activeTotal).toFixed(2))
      graphObj.data.datasets[1].data.push((100*element.change/element.activeTotal).toFixed(2))
      graphObj.data.datasets[2].data.push((100*element.sparepart/element.activeTotal).toFixed(2))
      graphObj.data.datasets[3].data.push((100*element.chip/element.activeTotal).toFixed(2))
      graphObj.data.datasets[4].data.push(((100*element.total/element.activeTotal)+(100*element.sparepart/element.activeTotal)+(100*element.change/element.activeTotal)+(100*element.chip/element.activeTotal)).toFixed(2))
    }
  )
  _.orderBy(onboarding, 'month').forEach((element) => {
      graphObj.data.datasets[5].data.push((100*element.idt/element.parqueNovo).toFixed(2))
      graphObj.data.datasets[6].data.push((100*element.change/element.parqueNovo).toFixed(2))
      graphObj.data.datasets[7].data.push((100*element.sparepart/element.parqueNovo).toFixed(2))
      graphObj.data.datasets[8].data.push((100*element.chip/element.parqueNovo).toFixed(2))
      graphObj.data.datasets[9].data.push(((100*element.idt/element.parqueNovo)+(100*element.sparepart/element.parqueNovo)+(100*element.change/element.parqueNovo)+(100*element.chip/element.parqueNovo)).toFixed(2))
    }
  )
  this.setState({
    monthRankingGraph:{
      graph: graphObj,
      loaded: true
    },
    lastUpdated : moment()
  })
}

renderMonthRankigGraph() {
    if(this.state.idtValues.loaded&&this.state.onboardingValues.loaded){
      if(this.state.monthRankingGraph.loaded){
        return (
          <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
            <Graph
              data={this.state.monthRankingGraph.graph.data}
              legend={this.state.monthRankingGraph.graph.legend}
              options={this.state.monthRankingGraph.graph.options}
              width={this.state.isDesktop ? 400 : 310}
              height={380}
            />
          </Box>)
      }
      else
        return (
          <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
            <LoadingSpinner />
          </div>
        )
    }
    else
          return (
            <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
              <LoadingSpinner />
            </div>
          )
  }


renderTopPainel() {
  if(this.state.idtValues.loaded&&this.state.onboardingValues.loaded){
    let data = this.state.idtValues.data.filter(item => item.month === '30 dias')
    let onBoarding = this.state.onboardingValues.data.filter(item => item.month === '30 dias')
    return (
    <div>
      <Row>
          <Col md lg xl = {1}/>
          <Col md lg xl = {2}>
            <SimpleBox
              header='IDR'
              direction ='normal'
              result={(((data[0].sparepart+data[0].chip+data[0].total+data[0].change)/data[0].activeTotal)*100).toFixed(1)}
              percentage={true}
            />
            </Col>
            <Col md lg xl = {2}>
              <SimpleBox
                header='IDT'
                result={(((data[0].total)/data[0].activeTotal)*100).toFixed(1)}
                percentage={true}
                />
            </Col>
            <Col md lg xl = {2}>
            <SimpleBox
              header='PREFERENCIA'
              result={(((data[0].change)/data[0].activeTotal)*100).toFixed(1)}
              percentage={true}
            />
            </Col>
            <Col md lg xl = {2}>
              <SimpleBox
                header='SPARE PART'
                result={(((data[0].sparepart)/data[0].activeTotal)*100).toFixed(1)}
                percentage={true}
                />
            </Col>
            <Col md lg xl = {2}>
            <SimpleBox
              header='IDM'
              result={(((data[0].chip)/data[0].activeTotal)*100).toFixed(1)}
              percentage={true}
            />
            </Col>
        </Row>
        <Row>
          <Col md lg xl = {1}/>
          <Col md lg xl = {2}>
            <SimpleBox
              header='IDR ONBOARDING'
              result={(((onBoarding[0].idt+onBoarding[0].sparepart+onBoarding[0].chip+onBoarding[0].change)/onBoarding[0].parqueNovo)*100).toFixed(1)}
              percentage={true}
            />
            </Col>
            <Col md lg xl = {2}>
              <SimpleBox
                header='IDT ONBOARDING'
                result={(((onBoarding[0].idt)/onBoarding[0].parqueNovo)*100).toFixed(1)}
                percentage={true}
                />
            </Col>
            <Col md lg xl = {2}>
            <SimpleBox
              header='PREFERENCIA ONB.'
              result={(((onBoarding[0].change)/onBoarding[0].parqueNovo)*100).toFixed(1)}
              percentage={true}
            />
            </Col>
            <Col md lg xl = {2}>
              <SimpleBox
                header='SPARE PART ONB.'
                result={(((onBoarding[0].sparepart)/onBoarding[0].parqueNovo)*100).toFixed(1)}
                percentage={true}
                />
            </Col>
            <Col md lg xl = {2}>
            <SimpleBox
              header='IDM ONBOARDING'
              result={(((onBoarding[0].chip)/onBoarding[0].parqueNovo)*100).toFixed(1)}
              percentage={true}
            />
            </Col>
        </Row>
      </div>
    )
  }
  else
    return (
      <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
          <LoadingSpinner />
      </div>
    )
 }

  

  renderIdtSummary(information,screening) {
    if(this.state.selectedScreening === '-1')
      if(information.loaded && screening.loaded){
        return (
          <SummaryIdt
            information = {information}
            screening= {screening}
          />
        )
      }
      else
        return (
          <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
            <LoadingSpinner />
          </div>
        )
    else{
      if(screening.loaded){
        let screeningSelected = {data:screening.data.filter(item => item.location === this.state.selectedScreening)}
        console.log(screeningSelected)
        return (
          <SummaryIdt
            information = {screeningSelected}
          />
        )
      }
      else
        return (
          <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
            <LoadingSpinner />
          </div>
        )
    }
  }

  refreshScreening(screening) {
    LogisticService.getIDTOnBoarding(null,'screening',screening)
    .then((onBoarding) => {
      this.setState({
        onboardingScreening:{
          data   : onBoarding.data.data,
          loaded : true
        }
      })
    })
  }

  //Filter Defect
  inputScreening () {
    return (
      <Form>
            <Form.Group as={Col} controlId="screening" >
              <Form.Label>Triagem</Form.Label>
              <Form.Control as="select" 
              value={this.state.selectedScreening}
              onChange = {e => this.updateScreening()}>
              <option>{'Geral'}</option>
              <option>{'POLO'}</option>
              <option>{'CD'}</option>
              <option>{'STONE BOX'}</option>
              </Form.Control>
            </Form.Group>
      </Form>
    )}

  updateScreening(){
    let screening = document.getElementById("screening")
    this.setState({
      selectedScreening : screening[screening.selectedIndex].text === 'Geral'? '-1':screening[screening.selectedIndex].text
    })
  }
  
    
// Render
  render() {
    let refreshContent
    let lastUpdatedText = ''

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format('DD/MM HH:mm')}`
    }

      refreshContent = (
        
          <div style={{textAlign: 'right'}}>
            <label style={{margin:'1em'}}><i>{lastUpdatedText}</i></label>
            <RefreshButton onClick={() => { this.refreshReportData() }}/>
          </div>
          
      )

    return (
      <Container fluid>
          {this.state.idtValues.loaded&&this.state.onboardingValues.loaded&&this.state.monthRankingGraph.loaded&&this.state.weekRankingGraph.loaded&&this.state.monthIdtTable.loaded&&this.state.onboardingScreening.loaded?refreshContent:''}
          <div style={{color: 'black'}}>
                <CustomizedExpansionPannel
                  title="IDR - Resumo 30D"
                  content={
                    <Row>
                      <Col xl sm md lg xl = {12}>
                        {this.renderTopPainel()}
                      </Col>
                    </Row>
                  }
                  helpMessage={<p>
                    <b>Comparação entre IDR geral e Onboarding 30 dias</b>
                    <p>Essa comparação é boa para dar visibilidade da dor do cliente onboarding.</p></p>}
                  tooltip={true}
                />
                <CustomizedExpansionPannel
                  title="IDR - EVOLUÇÃO ANUAL"
                  content={
                    <Row>
                      <Col xl sm md lg xl = {12}>
                        {this.renderMonthRankigGraph()}
                      </Col>
                    </Row>
                  }
                  helpMessage={<p>
                    <b>Evolução mês a mês</b>
                    <p>Novamente mostra a comparação e o quanto temos ou não evoluído no assunto.</p></p>}
                  tooltip={true}
                />
                <CustomizedExpansionPannel
                  title="IDR ONBOARDING - EVOLUÇÃO SEMANAL 30D"
                  content={
                    <Row>
                      <Col xl sm md lg xl = {12}>
                        {this.renderWeekRankigGraph()}
                      </Col>
                    </Row>
                  }
                  helpMessage={<p>
                    <b>Evolução semanal</b>
                    <p>Mostra apenas o onboarding 30d dos principais defeitos (igual aos principais do dash de IDR), para identificarmos rapidamente melhorias ou problemas. Gráfico de barras empilhadas.</p></p>}
                  tooltip={true}
                />
                <Row>  
                  {(this.state.monthIdtTable.loaded&&this.state.onboardingScreening)?this.inputScreening():''}
                </Row>
                <CustomizedExpansionPannel
                title="IDR - ONBOARDING GERAL"
                content={
                  <Row>
                    <Col xs sm md = {0} lg xl = {2}/>
                    <Col xs sm md = {12} lg xl = {10}>
                      {this.renderIdtSummary(this.state.monthIdtTable,this.state.onboardingScreening)}
                    </Col>
                  </Row>
                }
                helpMessage={<p>
                  <b>IDR Geral</b>
                  <p>Tabela para acompanhar os indicadores IDT OnBoard e indicador de onde o POS foi triado, evolução dos últimos 4 meses.</p>
                  <p>Δ% : a diferença do mês em relação à média dos 3 meses anteriores.</p>
                  </p>}
                tooltip={true}
                />
          </div>
    </Container>
    )
  }
}

export default IDTReport
