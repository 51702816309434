import React from 'react'
import Graph from '../../../components/Logistic/graphic/graph'
import Box from '@material-ui/core/Box';
import _ from 'lodash'
import moment from 'moment'
import '../style.css'


const LabHistoryGraph = props =>  {
      
        const {labHistory} = props;

        if (!labHistory) return null

        const graphObj = {
          data: {
            labels: [],
            datasets: [
              {
                label: '% Parque Total',
                backgroundColor: '#e74c3c',
                borderColor: '#e74c3c',
                hoverBackgroundColor: '#e74c3c',
                hoverBorderColor: '#e74c3c',
                data: [],
                type: 'line',
                pointRadius: 0,
                fill: false,
                yAxisID: 'B',
              },
              {
               label: 'Quantidade (milhares)',
               backgroundColor: '#f1c40f',
               borderColor: '#2c3e50',
               borderWidth: 0.5,
               hoverBackgroundColor: '#f1c40f',
               hoverBorderColor: '#f1c40f',
               data: [],
               type: 'line',
               pointRadius: 0,
               yAxisID: 'A',
              },
            ]
          },
          options: {
            responsive: undefined,
            maintainAspectRatio: false,
            tooltips: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: (tooltipItem, data) => {
                  if (tooltipItem.datasetIndex === 0) {
                    return `${(tooltipItem.yLabel * 100).toFixed(1)}%`
                  }
                  return tooltipItem.yLabel
                }
              }
            },
            scales: {
              xAxes: [{
                gridLines: {
                  display: false,
                },
              }],
              yAxes: [
                {
                  id: 'A',
                  type: 'linear',
                  position: 'left',
                  ticks: {
                    suggestedMax: 70 * 1000,
                    min: 0,
    
                  callback: function (value) {
                               return (value/1000) + "k"
                             },
    
    
                  },
                },
                {
                  id: 'B',
                  type: 'linear',
                  position: 'right',
                  ticks: {
                    max: 0.1,
                    min: 0,
                    callback: function (value) {
                                 return (value*100).toFixed(0) + '%'; // convert it to percentage
                             },
                  },
                  gridLines: {
                    display: false,
                  },
                }
              ]
            },
            title: {
              display: true,
              fontColor: 'black',
              fontSize: 20,
              text: 'Ciclo Lab',
              fontFamily: 'GoBold'
            },
            plugins: {
              datalabels: {
                font: {
                  size: 0,
                },
                color: 'black',
              },
              pan: {
                enabled: true,
                mode: 'x',
                speed: 0.2
              },
              zoom: {
                enabled: true,
                mode: 'x',
                speed: 0.2
              }
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: 'black',
            },
          },
        }
    
        _.orderBy(labHistory, 'date').forEach((element) => {
          graphObj.data.labels.push(moment(element.date).format('DD/MM/YYYY'))
          graphObj.data.datasets[1].data.push(element.cicloLab)
          graphObj.data.datasets[0].data.push(element.cicloLab / element.totalTerminals)
        })

        const isDesktop =  window.innerWidth > 1300
        return (
            <Box width={isDesktop ? "80%" : "100%"} height="100%">
              <Graph
                data={graphObj.data}
                legend={graphObj.legend}
                options={graphObj.options}
                width={isDesktop ? window.innerWidth/2.2 : 300}
                height={isDesktop ? 500 : 400}
            />
            </Box>
          )
    }

export default LabHistoryGraph