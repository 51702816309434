/* eslint-disable */

import React, {Component, PropTypes} from 'react'
import moment from 'moment'
import _ from 'lodash'
import style from './style.css'
import MapContainer from './google-map'
// import MapContainer from '../../custom-components/GoogleMaps/google-map'
import LogisticService from '../../core/services/LogisticService'
import LoadingSpinner from '../../components/LoadingSpinner'
import { Container, Form, Col, Card, Button, Alert, Row } from 'react-bootstrap'
import CustomizedSnackbars from '../../custom-components/SnackBars'
import distanceIcon from './distanceIcon.png'
import TablePaging from '../../custom-components/CustomizedTablePaging'
import { withStyles } from '@material-ui/core/styles';

class MapLinkRadar extends Component {
  constructor() {

    super()

    this.state = {
      today: moment().format('YYYY-MM-DD'),
      loading: false,
      modalIsOpen: false,
      lastUpdated: undefined,
      serial: undefined,
      address: undefined,
      lat: undefined,
      lng: undefined,
      error: false,
      selectedHub: 'MG - BELO HORIZONTE STONE PAGAMENTOS',
      selectedAngel: 'bh_1',
      avaiableAngels: ['bh_1']
    }
    this.refreshReportData = this.refreshReportData.bind(this)
  }

  componentDidMount () {
    this.refreshReportData()
  }

refreshReportData () {

  LogisticService.getMapLinkCoords()
  .then((response) => {
    this.setState({
    coords: response.data.data
    })
  })

}

  handleChange (event) {
    this.setState({
      serial: document.getElementById("serial").value,
    })
  }


  renderMap() {


    if (this.state.coords == undefined ) {
      return(
        <div style={{ width: 'center', textAlign: 'center' }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      )
    }
    var data = []
    var avaiableAngels = []

    this.state.coords.forEach(element => {

      if(element.provider == this.state.selectedHub && element.vehicle != avaiableAngels[avaiableAngels.length-1]){
        avaiableAngels.push(element.vehicle)
      }
      if(element.provider == this.state.selectedHub && element.vehicle == this.state.selectedAngel){
        data.push(element)
      }
    })
    this.state.avaiableAngels = avaiableAngels
    console.log(data)
    if(data.length == 0){
      this.state.selectedAngel = avaiableAngels[0]
      this.state.coords.forEach(element => {
        if(element.provider == this.state.selectedHub && element.vehicle == this.state.selectedAngel){
          data.push(element)
        }
      })
    }
    console.log(this.state.selectedAngel)
    console.log(this.state.selectedHub)

    return (
        <MapContainer
          pointsList={data}
        ></MapContainer>
    )
  }

  renderTable(response){

    if(this.state.coords == undefined){
      return
    }

      let rows = []



  if(this.state.coords != undefined){
    this.state.coords.forEach(element => {
      if( this.state.coords.length != 0 ){
        if(element.provider == this.state.selectedHub && element.vehicle == this.state.selectedAngel){
          rows.push(
            [
              element.orderNumber,
              element.neighborhood,
              element.city
            ]
          )
        }
      }

})
  }


  let title = [{name:"OSs"}]

  return (
      <TablePaging
        data ={rows}
        title ={title}
        subTitle ={""}
        colSpan ={"7"}
        totalPages = {(this.state.coords.length / 7) + (this.state.coords.length % 7 == 0 ? 0 : 1)}
        rowSpan = {7}
        >
      </TablePaging>
   )
  }

  refreshOptions() {

    const hub = document.getElementById("hub")
    const angel = document.getElementById("angel")

    const selectedHub = hub[hub.selectedIndex].text
    const selectedAngel = angel[angel.selectedIndex].text


    this.setState({
       selectedHub: selectedHub,
       selectedAngel:selectedAngel
      })
      this.refreshReportData()
    }

    buildOptionsFromArray(array){

      if (array.length === 0)
        return (<option>   </option>)

      const results = array.map(element =>
        (<option> {element} </option>)
    )
      return results
    }



  render() {

    return (
      <Container fluid>
      <Form.Row style={{textAlign: 'center'}}>
        <Col sm="12" lg="1">
        <img src={distanceIcon} className="iconRoutes"/>
        </Col>
        <Col sm="12" lg="3" style={{margin: '1em'}}>
          <Form.Control id="hub" type="email" size="sm" placeholder="Status" as="select" onChange={e => this.refreshOptions()}>
            <option>MG - BELO HORIZONTE STONE PAGAMENTOS</option>
            <option>RN - NATAL STONE PAGAMENTOS</option>
            <option>PB - JOAO PESSOA STONE PAGAMENTOS</option>
          </Form.Control>
        </Col>
        <Col sm="12" lg="3" style={{margin: '1em'}}>
          <Form.Control id="angel" type="email" size="sm" placeholder="Status" as="select" onChange={e => this.refreshOptions()}>
            {this.buildOptionsFromArray(this.state.avaiableAngels)}
          </Form.Control>
        </Col>
      </Form.Row>
      <Row>
        <Col sm="12" lg="8">
          <div style={{ width: '100%',height:'300px', textAlign: 'center', marginLeft: 'auto',  marginTop: '1em' }}>
            {!this.state.loading ? this.renderMap() : null}
          </div>
        </Col>
        <Col sm="12" lg="4">
          <div style={{ width: '100%', textAlign: 'center'}}>
            {this.renderTable()}
          </div>
        </Col>
      </Row>
      </Container>)
  }

}

export default MapLinkRadar
