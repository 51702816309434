/* eslint-disable */
import React, { Component } from "react";
import LogisticService from "../../../core/services/LogisticService";
import SortingTable from "../../../custom-components/SortingTable";

const columnsCollact = [
  {
    title: "Angel",
    dataIndex: "angel",
    sorter: (a, b) => (a.angel < b.angel ? -1 : 1),
  },
  {
    title: "Total",
    dataIndex: "total",
    sorter: (a, b) => a.total - b.total,
  },
  {
    title: "Ativados",
    dataIndex: "active",
    sorter: (a, b) => a.active - b.active,
  },
  {
    title: "% Ativados",
    dataIndex: "activePercentualView",
    sorter: (a, b) => a.activePercentual - b.activePercentual,
  },
];

export class Collact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      district: props.district,
      date: props.date,
      collact: {
        loaded: false,
      },
      graph: undefined,
      loading: false,
      lastUpdated: undefined,
    };
  }

  componentDidMount() {
    LogisticService.getLmpActivation(
      this.state.district,
      "Collact",
      `${this.state.date}-01`,
      `${this.state.date}-01`
    ).then((response) => {
      response.data.data.forEach((item) => {
        item.activePercentual = (
          ((item.active * 1.0) / item.total) *
          100
        ).toFixed(1);
        item.activePercentualView = `${item.activePercentual}%`;
      });
      this.setState({
        portalData: response.data.data,
        loaded: true,
      });
    });
  }

  render() {
    return (
      <SortingTable
        title="Collact"
        columns={columnsCollact}
        data={this.state.portalData}
      />
    );
  }
}

export default Collact;
