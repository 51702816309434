/* eslint-disable */
import axios from 'axios'
import { authenticatedAxios } from '../../http';

import appConfig from './appConfig'

export default class EqualsService {

  static requestWithAuth (url) {
    return authenticatedAxios().get(url)
  }


  static getMerchantInfo (documentNumber) {
    console.log(`getting mechant info for ${documentNumber}`)
    var result = EqualsService.requestWithAuth(`${appConfig.logisticsUrl}/customer-info/${documentNumber}/equals`);
    console.log(result)
    return result
  }

  static uploadTerm (onboardingId, operatorName, documentNumber, file) {
    let data = new FormData()
    data.append('idOnboarding', onboardingId)
    data.append('nomeOperadora', operatorName)
    data.append('cnpjEstabelecimento', documentNumber)

    const filename = file.name.split(' ').join('-')
    data.append(filename, file)

    const url = `${appConfig.logisticsUrl}/customer-info/${documentNumber}/equals`

    return authenticatedAxios().post(url, data)
  }

  static getPreOnboarding (stonecode) {
    return EqualsService.requestWithAuth(`${appConfig.equalsUrl}/stonecodes/${stonecode}/pre-onboarding`)
  }

  static updatePreOnboarding (stonecode, documentNumber, operator, operatorCode) {
    const data = {
      document_number: documentNumber,
      operator,
      operator_code: operatorCode,
    }

    const url = `${appConfig.equalsUrl}/stonecodes/${stonecode}/pre-onboarding`

    return authenticatedAxios().put(url, data, config)
  }

  static updateEqualsPreOnboarding (documentNumber, filiationData) {
    const data = filiationData

    const url = `${appConfig.equalsUrl}/merchants/${documentNumber}/update-filiation`

    return authenticatedAxios().post(url, data)
  }

  static onboardToEquals (stonecode) {
    const url = `${appConfig.equalsUrl}/stonecodes/${stonecode}/onboard-to-equals`

    return authenticatedAxios().post(url, {})
  }

}
