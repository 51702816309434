import React, { Component } from 'react'
import { Table } from 'antd';
import {ExportCSV} from '../../../custom-components/ExportCSV'
import _ from 'lodash';


export class capexTable extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
        originalData: this.props.data,
        currentData: this.props.data
        }
      }
      
      onChange(pagination, filters, sorter, extra) {
        console.log(filters)
        var filtro = []
        var validRow

        this.state.originalData.forEach(row => {
            validRow = true
            Object.keys(filters).forEach(filter => {
                if (filters[filter].length === 0 ) {
                    return
                }
                if (!(filters[filter].includes(row[filter]))) {
                    validRow = false
                }
            })
            if (validRow) {
                filtro.push(row)
            }
        })
        this.setState({currentData: filtro})
      }

      
      render () {

          const columns = [
              {
              title: 'Contratante',
              dataIndex: 'Contratante',
              onFilter: (value, record) => record['Contratante'].indexOf(value) === 0,
              filters: _.uniqBy(this.state.currentData, 'Contratante').map( e => {
                          return ({
                              text: e['Contratante'],
                              value: e['Contratante']
                          })
                      })
              },
              {
              title: 'Posição',
              dataIndex: 'Posição',
              onFilter: (value, record) => record['Posição'].indexOf(value) === 0,
              filters: _.uniqBy(this.state.currentData, 'Posição').map( e => {
                          return ({
                              text: e['Posição'],
                              value: e['Posição']
                          })
                      })
              },
              {
              title: 'Posição Cadeia',
              dataIndex: 'Posição Cadeia',
              onFilter: (value, record) => { if ('Posição Cadeia' in record) return ( record['Posição Cadeia'].indexOf(value) === 0 )},
              filters: _.uniqBy(this.state.currentData, 'Posição Cadeia').map( e => {
                return ({
                    text: e['Posição Cadeia'],
                    value: e['Posição Cadeia']
                })
            })
              },
              {
              title: 'Status Estoque',
              dataIndex: 'Status Estoque',
              onFilter: (value, record) => record['Status Estoque'].indexOf(value) === 0,
              filters: _.uniqBy(this.state.currentData, 'Status Estoque').map( e => {
                          return ({
                              text: e['Status Estoque'],
                              value: e['Status Estoque']
                          })
                      })
              },
              {
              title: 'Operador Logístico',
              dataIndex: 'Operador Logístico',
              onFilter: (value, record) => record['Operador Logístico'].indexOf(value) === 0,
              filters: _.uniqBy(this.state.currentData, 'Operador Logístico').map( e => {
                          return ({
                              text: e['Operador Logístico'],
                              value: e['Operador Logístico']
                          })
                      })
              },
              {
              title: 'Prestador',
              dataIndex: 'Prestador',
              onFilter: (value, record) => record['Prestador'].indexOf(value) === 0,
              filters: _.uniqBy(this.state.currentData, 'Prestador').map( e => {
                          return ({
                              text: e['Prestador'],
                              value: e['Prestador']
                          })
                      })
              },
              {
              title: 'Tecnologia',
              dataIndex: 'Tecnologia',
              onFilter: (value, record) => record['Tecnologia'].indexOf(value) === 0,
              filters: _.uniqBy(this.state.currentData, 'Tecnologia').map( e => {
                          return ({
                              text: e['Tecnologia'],
                              value: e['Tecnologia']
                          })
                      })
              },
              {
              title: 'Modelo',
              dataIndex: 'Modelo',
              onFilter: (value, record) => record['Modelo'].indexOf(value) === 0,
              filters: _.uniqBy(this.state.currentData, 'Modelo').map( e => {
                          return ({
                              text: e['Modelo'],
                              value: e['Modelo']
                          })
                      })
              },
              {
              title: 'qtd',
              dataIndex: 'qtd'
              }
          ]
      
    return(
        <div>
        <div style={{float: 'right'}}>
          <ExportCSV csvData={this.state.currentData} fileName={"Parque Total Agrupado"} />
        </div>
        <div style={{ clear: 'both' }}></div>
        <div style={{marginTop: '1.5em'}}>
            <Table size="small" columns={columns} dataSource={this.state.currentData} onChange={(p, f, s, e) => this.onChange(p, f, s, e)} />
        </div>
      </div>
    )
    }

}


export default capexTable
