import Table from "@material-ui/core/Table";
import { TableSortLabel } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Pagination } from "antd";

export default function CustomizedTable(props) {
  //Define Table Cells style

  const { classes, onSelectAllClick, numSelected, rowCount } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Atraso");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "white",
      color: "dark",
      fontSize: "0.9em",
    },
    body: {
      fontSize: "0.8em",
      alignItems: "center",
      textAlign: "center",
    },
  }))(TableCell);

  //Create variable for table subTitles
  let subTitle = [];

  props.subTitle.forEach((item) => {
    if (item.name !== "")
      subTitle.push(
        <StyledTableCell className="subtitleTable">
          <TableSortLabel
            active={true}
            onClick={createSortHandler(item.name)}
            direction={orderBy === item.name ? order : "asc"}
          >
            {item.icon} {item.name}
          </TableSortLabel>
        </StyledTableCell>
      );
  });
  const [page, setPage] = useState(1);
  let [rows, setRows] = useState([]);
  let [data, setData] = useState([]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  //Using filtered data to create table rows
  function defineRows() {
    rows = [];
    data = props.data.slice((page - 1) * props.rowSpan, props.rowSpan * page);
    data.forEach((item) => {
      let step = [];
      for (let value in item) {
        step.push(<StyledTableCell>{item[value]}</StyledTableCell>);
      }
      rows.push(<TableRow>{step}</TableRow>);
    });
  }

  //execute in the first time
  defineRows();

  //When the page is changed, execute this one
  useEffect(() => {
    defineRows();
  });

  return (
    <Box
      mx="auto"
      width={window.innerWidth < 1300 ? "100%" : "100%"}
      overflow="auto"
    >
      <Table>
        {props.title !== undefined ? (
          <TableHead>
            <TableRow>
              <StyledTableCell
                align="center"
                className="titleClient"
                colSpan={props.colSpan}
              >
                {props.title[0].icon}
                {props.title[0].name}
              </StyledTableCell>
            </TableRow>
          </TableHead>
        ) : (
          ""
        )}
        <TableBody>
          <TableRow>{subTitle}</TableRow>
          {rows}
        </TableBody>
      </Table>
      <br />
      <div className="float-right">
        <Pagination
          defaultCurrent={1}
          page={page}
          total={props.data.length}
          defaultPageSize={props.rowSpan}
          onChange={setPage}
        />
      </div>
    </Box>
  );
}
