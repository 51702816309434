import React from "react";
import LogisticService from "../../core/services/LogisticService";
import XLSX from "xlsx";
import MaterialTable from 'material-table';
import { Alert, Button } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";

export class ListLeadtimes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            sucess: false,
            loading: false,
            changedData: [],
            success_with_errors: false,
            error_list: [],
            data: props.data,
            filtros: props.filtros,
            cols: [
                // { title: 'Id', field: 'id', hidden: true },
                { title: 'Polo', field: 'hub' },
                { title: 'Transportadora', field: 'carrier' },
                { title: 'Tipo', field: 'type' },
                { title: 'Modo', field: 'mode' },
                { title: 'Dias', field: 'days' }
            ]
        };

        this.exportFile = this.exportFile.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({ data: props.data, filtros: props.filtros });
    }

    exportFile() {
        /* convert state to workbook */

        let organized_data = [];

        let i = 0;

        if (this.state.data) {
            this.state.data.forEach(function (row) {
                let index_row = [];
                organized_data.push(index_row);
                for (var ob in row) {
                    if (i == 0) {
                        index_row.push(ob);
                        continue
                    }
                    index_row.push(row[ob])
                }
                i++
            });

            organized_data[0].pop()
        } else {
            organized_data = ['emergencialLeadtime', 'leadtime', 'provider']
        }

        const ws = XLSX.utils.aoa_to_sheet(organized_data);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, "sheetjs.xlsx");
    }

    saveMultipleExpeditions = () => {
        this.setState({
            loading: true,
        });

        LogisticService.postLeatime({ 'leadtime_list': this.state.changedData }).then((response) => {

            if (response.data.success_with_errors) {
                this.setState({
                    success_with_errors: true,
                    loading: false,
                    error_list: response.data.ids
                })
            } else {
                this.setState({
                    loading: false,
                    sucess: true,
                });
            }
        }, (reason => {
            this.setState({
                loading: false,
                error: true
            });
        }));
    };

    render() {
        return (
            <div hidden={!this.state.data.length} style={{ 'marginLeft': '10px' }}>
                <Alert hidden={!this.state.success_with_errors} variant="warning">
                    <Alert.Heading>Boa!</Alert.Heading>
                    <p>
                        Leadtimes cadastrados com sucesso, porém algumas leadtimes não estão com campos válidos!
                        Segue os id's:
                    </p>
                    {this.state.error_list.map(item => (
                        <p>{item}</p>
                    ))}
                    <Button onClick={() => this.setState({ success_with_errors: false })} variant="outline-success">
                        Ok!
                    </Button>
                </Alert>
                <Alert hidden={!this.state.sucess} variant="success">
                    <Alert.Heading>Boa!</Alert.Heading>
                    <p>
                        Leadtimes cadastrados com sucesso!
                    </p>
                    <Button onClick={() => this.setState({ sucess: false })} variant="outline-success">
                        Ok!
                    </Button>
                </Alert>
                <Alert hidden={!this.state.error} variant="danger">
                    <Alert.Heading>Ocorreu um erro! ):</Alert.Heading>
                    <p>
                        Algo de errado ocorreu! Tente novamente mais tarde!
                    </p>
                    <Button onClick={() => this.setState({ error: false })} variant="outline-danger">
                        Ok :/
                    </Button>
                </Alert>
                <div hidden={!this.state.loading} style={{ width: 'center', textAlign: 'center' }}>
                    <LoadingSpinner />
                </div>
                {/* <button
                    disabled={!this.state.data.length}
                    className="btn btn-success"
                    onClick={this.exportFile}
                    style={{'marginLeft': '10px', 'marginBottom': '10px'}}
                >
                    Export
                </button> */}
                <MaterialTable
                    title="Leadtimes"
                    style={{ padding: '0 16px', marginBottom: '24px', zIndex: 0 }}
                    columns={this.state.cols}
                    data={this.state.data}
                    options={{
                        exportAllData: true,
                        rowStyle: rowData => ({
                            backgroundColor: (rowData.modificationDate > rowData.solicitationDate && rowData.omNumber === '0') ? '#ffb1ab' : '#FFF'
                        }),
                        exportButton: true,
                        pageSize: 20
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                        },
                        header: {
                            actions: 'Ações'
                        },
                        body: {
                            emptyDataSourceMessage: 'Nenhumo leadtime encontrado',
                            filterRow: {
                                filterTooltip: 'Filter'
                            },
                            // editRow: {
                            //     deleteText: 'Tem certeza que deseja deletar?'
                            // }
                        }
                    }}
                    editable={{
                        // onRowUpdate: (newData) =>
                        //     new Promise(resolve => {
                        //         setTimeout(() => {
                        //             resolve();
                        //             LogisticService.postLeatime({ 'leadtime_list': [newData]}).then(res => {
                        //                 LogisticService.getLeadtimes(this.state.filtros).then(response => {
                        //                     this.setState({
                        //                         data: response.data.data
                        //                     })
                        //                     window.alert('Leadtime editado com sucesso!');
                        //                 });
                        //             })
                        //         }, 600);
                        //     }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    console.log(oldData);
                                    LogisticService.deleteLeadtime(oldData).then(res => {
                                        LogisticService.getLeadtimes(this.state.filtros).then(response => {
                                            window.alert('Leadtime apagado com sucesso!');
                                            this.setState({
                                                data: response.data.data
                                            })
                                        });
                                    })
                                }, 600);
                            }),
                    }} />
            </div>

        );
    }
}

