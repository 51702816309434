import { Check, Close } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MaterialTable from 'material-table';
import { toUpper } from "ramda";
import React from "react";
import LogisticService from "../../core/services/LogisticService";

export default class ListAngels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editDaysName: '',
            newRow: {
                "sunday": false,
                "monday": false,
                "tuesday": false,
                "wednesday": false,
                "thursday": false,
                "friday": false,
                "saturday": false,
            },
            data: props.data,
            hub: props.hub,
            cols: [
                { title: "Angel", field: "Nome", editable: 'never' },
                { title: "Email", field: "email", editable: 'onAdd' },
                { title: "Modal", field: "Modal", validate: rowData => rowData.Modal != undefined ? (["CARRO", "MOTO"]).includes(toUpper(rowData.Modal)) ? '' : 'Modal deve ser "Carro" ou "Moto"' : '' },
                { title: "Partida - Lat", field: "partidaLat" },
                { title: "Partida - Long", field: "partidaLong" },
                { title: "Retorno - Lat", field: "retornoLat" },
                { title: "Retorno - Long", field: "retornoLong" },
                { title: "Horas", field: "HorasTrabalhadas", type: 'numeric' },
                {
                    title: "Dias Semana", field: "DiasSemana", editable: 'onAdd, onEdit', render: rowData => {
                        if (rowData === undefined ) {
                            return this.diasRoteirizacaoEdit(rowData ? rowData.DiasSemana : this.state.newRow)
                        } else if(rowData.tableData.editing) {
                            return this.diasRoteirizacaoEdit(rowData ? rowData.DiasSemana : this.state.newRow, rowData.Nome)
                        }else {
                            return this.diasRoteirizacao(rowData.DiasSemana, rowData.Nome)
                        }
                    } }
            ]
        };
    }

    formControlLabel(control, label) {
        return <FormControlLabel
            control={control}
            label={label}
            labelPlacement="top"
        />
    }
    
    diasRoteirizacao(data) {
        return (
            <FormGroup id='testeDias' aria-label="position" className="roteirizacaoCheckbox" row>
                {this.formControlLabel((data['sunday'] ? <Check style={{ color: "green"}} /> : <Close style={{ color: "red"}} />), "D")}
                {this.formControlLabel((data['monday'] ? <Check style={{ color: "green"}} /> : <Close style={{ color: "red"}} />), "S")}
                {this.formControlLabel((data['tuesday'] ? <Check style={{ color: "green"}} /> : <Close style={{ color: "red"}} />), "T")}
                {this.formControlLabel((data['wednesday'] ? <Check style={{ color: "green"}} /> : <Close style={{ color: "red"}} />), "Q")}
                {this.formControlLabel((data['thursday'] ? <Check style={{ color: "green"}} /> : <Close style={{ color: "red"}} />), "Q")}
                {this.formControlLabel((data['friday'] ? <Check style={{ color: "green"}} /> : <Close style={{ color: "red"}} />), "S")}
                {this.formControlLabel((data['saturday'] ? <Check style={{ color: "green"}} /> : <Close style={{ color: "red"}} />), "S")}
            </FormGroup>
        )
    }

    changeCheck(data, day, nome){
        data[day] = !data[day]
        this.state.newRow = data
        this.state.editDaysName = nome
    }

    diasRoteirizacaoEdit(data, nome) {
        return (
            <FormGroup aria-label="position" className="roteirizacaoCheckbox" row>
                {this.formControlLabel((<Checkbox onChange={e => this.changeCheck(data, 'sunday', nome)} style={{ color: "green"}} defaultChecked={data['sunday']} />), "D")}
                {this.formControlLabel((<Checkbox onChange={e => this.changeCheck(data, 'monday', nome)} style={{ color: "green"}} defaultChecked={data['monday']} />), "S")}
                {this.formControlLabel((<Checkbox onChange={e => this.changeCheck(data, 'tuesday', nome)} style={{ color: "green"}} defaultChecked={data['tuesday']} />), "T")}
                {this.formControlLabel((<Checkbox onChange={e => this.changeCheck(data, 'wednesday', nome)} style={{ color: "green"}} defaultChecked={data['wednesday']} />), "Q")}
                {this.formControlLabel((<Checkbox onChange={e => this.changeCheck(data, 'thursday', nome)} style={{ color: "green"}} defaultChecked={data['thursday']} />), "Q")}
                {this.formControlLabel((<Checkbox onChange={e => this.changeCheck(data, 'friday', nome)} style={{ color: "green"}} defaultChecked={data['friday']} />), "S")}
                {this.formControlLabel((<Checkbox onChange={e => this.changeCheck(data, 'saturday', nome)} style={{ color: "green"}} defaultChecked={data['saturday']} />), "S")}
            </FormGroup>
        )
    }

    render() {
        return (
            <MaterialTable
                title="Angels"
                width="90vw"
                style={{ width: '90vw' }}
                columns={this.state.cols}
                data={this.state.data}
                options={{
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.modificationDate > rowData.solicitationDate && rowData.omNumber === '0') ? '#ffb1ab' : '#FFF'
                    }),
                    exportButton: false,
                    pageSize: 3,
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}',
                    },
                    header: {
                        actions: 'Ações'
                    },
                    body: {
                        emptyDataSourceMessage: 'Nenhum angel encontrado',
                        filterRow: {
                            filterTooltip: 'Filter'
                        },
                        editRow: {
                            deleteText: 'Tem certeza que deseja deletar?'
                        }
                    }
                }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                let data = this.state.data;
                                if(this.state.editDaysName == newData.Nome){
                                    newData['DiasSemana'] = this.state.newRow
                                }
                                else{
                                    newData['DiasSemana'] = false
                                }
                                LogisticService.getSenninhaConfigAdd({ 'angel': newData }, this.state.hub).then((response) => {
                                    LogisticService.postSenninhaConfigLog({ 'id': response.data.id, 'action': 'edit' })
                                    window.alert(response.data.message)
                                    LogisticService.getSenninhaConfig(this.state.hub).then((response) => {
                                        this.setState({ data: response.data.data })
                                    })
                                })
                                data[data.indexOf(oldData)] = newData;
                                this.setState({ data: data })
                            }, 600);
                        }),
                        onRowDelete: oldData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                LogisticService.deleteSenninhaConfigDelete(oldData.Nome).then(response => {
                                    LogisticService.postSenninhaConfigLog({ 'id': response.data.id, 'action': 'delete', 'info': oldData })
                                    window.alert(response.data.message)
                                    this.setState({ success: response.data.success });
                                    LogisticService.getSenninhaConfig(this.state.hub).then((response) => {
                                        this.setState({ data: response.data.data })
                                    })
                                })
                                
                            }, 600);
                        }),
                    onRowAdd: (newData, rowData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if(this.state.editDaysName == newData.Nome){
                                    newData['DiasSemana'] = this.state.newRow
                                }
                                LogisticService.getSenninhaConfigAdd({ 'angel': newData }, this.state.hub).then((response) => {
                                    LogisticService.postSenninhaConfigLog({ 'id': response.data.id, 'action': 'add' })
                                    window.alert(response.data.message)
                                    LogisticService.getSenninhaConfig(this.state.hub).then((response) => {
                                        this.setState({ data: response.data.data })
                                    })
                                })
                                

                                resolve();
                            }, 1000);
                        }),
                }} />
        );
    }

}
