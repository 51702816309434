/* eslint-disable */
import React, { Component } from 'react'

import LogisticService from '../../../core/services/LogisticService'
import style from '../style.css'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CustomizedTable from '../../../custom-components/CustomizedTable';


const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: "#103a21",
      color: "white",
      fontSize: "0.9em",
      textalign: "center",
    },
    body: {
      fontSize: "0.13em",
      textalign: "center",
    },
    }))(TableCell);
class GoodScreening extends Component {
constructor(props) {
    super(props);
    this.state = {
        district: props.district,
        date: props.date,
        goodScreening:{
        loaded:false
        },
        graph: undefined,
        loading: false,
        lastUpdated: undefined,
    };
    this.refreshReportData = this.refreshReportData.bind(this)
}

refreshReportData() {
//Get Attendance Delay Reason
LogisticService.getLmpEfficiency(this.state.district,null,`${this.state.date}-01`,`${this.state.date}-01`).then((response)=>{  
    this.setState({
        goodScreening:{
                data:response.data.data,
                loaded: true
                },
            })
    })
  }

  componentDidMount() {
    this.refreshReportData()
  }

createQuadrantsTable(title,data){
    let subtitle = [{name:''},{name:'Semana 1'},{name:'Semana 2'},{name:'Semana 3'},{name:'Semana 4'}]
    let row = []
    let indicators = []
    let rowOne =[]
    let rowTwo =[]
    let rowTrhee =[]
    let rowFour =[]
    let rowFive =[]
    let rowSix =[]
    let rowSeven =[]
    let rowEight =[]

    rowOne.push(<StyledTableCell>Desinstalação</StyledTableCell>)
    rowTwo.push(<StyledTableCell>Troca</StyledTableCell>)
    rowTrhee.push(<StyledTableCell>Triados Good</StyledTableCell>)
    rowFour.push(<StyledTableCell></StyledTableCell>)
    rowFive.push(<StyledTableCell>Meta</StyledTableCell>)
    rowSix.push(<StyledTableCell>Realizado</StyledTableCell>)
    rowSeven.push(<StyledTableCell>Atingimento</StyledTableCell>)
    rowEight.push(<StyledTableCell>Atingimento Com Teto</StyledTableCell>)

    data.forEach((item)=>{
        rowOne.push(<StyledTableCell>{item.desistalacao}</StyledTableCell>)
        rowTwo.push(<StyledTableCell>{item.troca}</StyledTableCell>)
        rowTrhee.push(<StyledTableCell>{item.triadogood}</StyledTableCell>)
        rowFour.push(<StyledTableCell></StyledTableCell>)
        rowFive.push(<StyledTableCell>{((((item.desistalacao*0.77) + (item.troca*0.42))/(item.desistalacao+item.troca))*100).toFixed(1)  + '%'}</StyledTableCell>)
        rowSix.push(<StyledTableCell>{((item.triadogood*1.0/(item.desistalacao+item.troca))*100).toFixed(1) + '%'}</StyledTableCell>)
        rowSeven.push(<StyledTableCell>{(((item.triadogood*1.0/(item.desistalacao+item.troca))/(((item.desistalacao*0.77) + (item.troca*0.42))/(item.desistalacao+item.troca)))*100).toFixed(1)  + '%'}</StyledTableCell>)
        rowEight.push(<StyledTableCell>{(((item.triadogood*1.0/(item.desistalacao+item.troca))/(((item.desistalacao*0.77) + (item.troca*0.42))/(item.desistalacao+item.troca)))*100) > 130.0?
                                        '130.0%' : 
                                        (((item.triadogood*1.0/(item.desistalacao+item.troca))/(((item.desistalacao*0.77) + (item.troca*0.42))/(item.desistalacao+item.troca)))*100).toFixed(1)  + '%'}</StyledTableCell>)
    })
    row.push(<TableRow >{rowOne}</TableRow>)
    row.push(<TableRow >{rowTwo}</TableRow>)
    row.push(<TableRow >{rowTrhee}</TableRow>)
    row.push(<TableRow >{rowFour}</TableRow>)
    row.push(<TableRow >{rowFive}</TableRow>)
    row.push(<TableRow >{rowSix}</TableRow>)
    row.push(<TableRow >{rowSeven}</TableRow>)
    row.push(<TableRow >{rowEight}</TableRow>)

    
    return(
        <CustomizedTable
        rows ={row}
        title ={title}
        subTitle ={subtitle}
        colSpan ={"5"}
        /> 
      )
  }
  renderContent(){
    if(this.state.goodScreening.loaded){
       return(
        this.createQuadrantsTable([{name:'Eficiência' }],this.state.goodScreening.data)
        )
    }
    else{
        return(
            <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
            </div>
        )
    }
  }

    render(){
        return(this.renderContent())
  
    }

}
export default GoodScreening