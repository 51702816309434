/* eslint-disable */
import React, { Component } from "react";
import LogisticService from "../../core/services/LogisticService";
import Carousel from "react-bootstrap/Carousel";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { withStyles } from "@material-ui/core/styles";
import { Container, Col, Row, Button, Form, Card } from "react-bootstrap";
import Graph from "../../components/Logistic/graphic/graph";
import LoadingSpinner from "../../components/LoadingSpinner";
import DownloadButton from "../../custom-components/DownloadButton";
import Modal from "react-responsive-modal";
import SenninhaBar from "./senninha";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMotorcycle } from "@fortawesome/free-solid-svg-icons";

import Attention from "./newImagens/attention.png";
import Bad from "./newImagens/bad.png";
import Bomb from "./newImagens/bomb.png";
import Good from "./newImagens/good.png";
import Star from "./newImagens/star.png";
//Attendance
import AttendancePrincipal from "./Attendance/attendance";
import Sla from "./Attendance/sla";
import DelayReason from "./Attendance/delay_reason";
import TmaTmp from "./Attendance/tma_tmp";
//Efficiency
import Redirect from "./Attendance/redirect";
import CSAT from "./Attendance/csat";
import D0 from "./Attendance/d0";
import EfficiencyPrincipal from "./Efficiency/efficiency";
import GoodScreening from "./Efficiency/good_screening";
import Reverse from "./Efficiency/reverse";
import ScreeningCycle from "./Efficiency/screening_cycle";
//Activation
import ActivationPrincipal from "./Activation/activation";
import Xray from "./Activation/xray";
import Collact from "./Activation/collact";
import Portal from "./Activation/portal";
//People
import PeoplePrincipal from "./People/people";
import Accidents from "./People/accidents";
import DailyProd from "./People/dailyProd";
import ArrivalHour from "./People/arrivalHour";
import Absenteeism from "./People/absenteeism";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CustomizedTable from "../../custom-components/CustomizedTable";
import TablePaging from "../../custom-components/CustomizedTablePagingSort";
import Box from "@material-ui/core/Box";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#103a21",
    color: "white",
    fontSize: "0.9em",
    textalign: "center",
  },
  body: {
    fontSize: "0.13em",
    textalign: "center",
  },
}))(TableCell);

class DistrictPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sla: {
        loaded: false,
      },
      delayReason: {
        loaded: false,
      },
      tma: {
        loaded: false,
      },
      graph: undefined,
      loading: false,
      lastUpdated: undefined,
      detailModal: false,
      district: props.district,
      date: props.date,
    };

    this.refreshReportData = this.refreshReportData.bind(this);
  }

  refreshReportData() {

    const {today} = this.state
    LogisticService.periodosDiponiveisRV()
        .then(response => {
          
          LogisticService.getAllDistricts()
          .then(months => {
            this.setState({
              availableDistrict : months.data.data,
              availableMonth: response.data.data
            });
          })
          
        })
      

  }

  returnStatus(status) {
    if (status > 3) return <img className="iconCard" src={Star}></img>;
    if (status === 3) return <img className="iconCard" src={Good}></img>;
    if (status === 2) return <img className="iconCard" src={Attention}></img>;
    if (status === 1) return <img className="iconCard" src={Bad}></img>;
    if (status < 1) return <img className="iconCard" src={Bomb}></img>;
    return <span class="grayDot"></span>;
  }

  componentDidMount() {
    this.refreshReportData();
  }

  objectToCSVRow(dataObject) {
    var dataArray = new Array();
    for (var o in dataObject) {
      var innerValue = dataObject[o] === null ? "" : dataObject[o].toString();
      var result = innerValue.replace(/"/g, '""');
      result = result + ",";
      dataArray.push(result);
    }
    return dataArray.join(" ") + "\r\n";
  }

  exportToCSV(arrayOfObjects, title) {
    if (!arrayOfObjects.length) {
      return;
    }

    LogisticService.getLmpAttendance(
      "Analytics",
      this.state.district,
      null,
      `${this.state.date}-01`,
      `${this.state.date}-01`
    ).then((response) => {
      arrayOfObjects = response.data.data
      
    var csvContent = "data:text/csv;charset=utf-8,";

    // headers
    // csvContent += this.objectToCSVRow(Object.keys(arrayOfObjects[0]));

    arrayOfObjects.forEach( (item) => {
      csvContent += this.objectToCSVRow(item);
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", title);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
    })

  }

  exportData(content) {
    switch (content) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
    }
  }

  handleDistrictChange() {
    var district = document.getElementById("district");
    district = district[district.selectedIndex].text;

    var date = document.getElementById("date");
    date = date[date.selectedIndex].text;

    this.setState({
      district: district,
      date: date,
    });
  }

  createQuadrantsTable(title, subtitle, dataTitle, data, type) {
    let rows = [];
    if (type === "Principal") {
      for (let i = 0; i < dataTitle.length; i++) {
        rows.push(
          <TableRow>
            <StyledTableCell>{dataTitle[i]}</StyledTableCell>
            <StyledTableCell>{this.returnStatus(data[i])}</StyledTableCell>
          </TableRow>
        );
      }
      return (
        <CustomizedTable
          rows={rows}
          title={title ? title : [{ name: "" }]}
          subTitle={subtitle ? subtitle : ""}
          colSpan={"2"}
        />
      );
    } else {
      data.forEach((item) => {
        let aux = [];
        for (let i = 0; i < item.length; i++) aux.push(item[i]);
        rows.push(aux);
      });
      return (
        <TablePaging
          data={rows}
          title={title ? title : [{ name: "" }]}
          subTitle={subtitle ? subtitle : [{ name: "" }]}
          colSpan={rows.length}
          rowSpan={5}
        />
      );
    }
  }

  renderGraph(content) {
    return (
      <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
        <Graph
          data={content.data}
          legend={content.legend}
          options={content.options}
          width={this.state.isDesktop ? 400 : 310}
          height={340}
        />
      </Box>
    );
  }

  buildOptionsFromArray(parameter, array) {
    if (array.length === 0) return <option> </option>;

    if (parameter == "date") {
      const results = [];
      array.forEach((element) => {
        if (element.referenceYear >= "2020") {
          if (
            !(
              element.referenceYear == "2020" &&
              (element.referenceMonth == "1" || element.referenceMonth == "2")
            )
          ) {
            results.push(
              <option>
                {element.referenceYear}-{element.referenceMonth}
              </option>
            );
          }
        }
      });
      return results;
    }

    const results = [];
    array.forEach((element) => {
      if (element.name != "ESCRITORIO") {
        results.push(<option> {element.name} </option>);
      }
    });
    return results;
  }

  renderSearchBlock() {
    return (
      <Row style={{ marginTop: "7%" }}>
        <Col lg="4" />
        <Col lg="4" sm="12" xs="12">
          <Card className="text-center">
            {/* <Card.Header style={{'backgroundColor': '#37B766', 'color':'white'}}>Informações do cliente</Card.Header> */}
            <Card.Body>
              <FontAwesomeIcon style={{ width: "100%" }} icon={faMotorcycle} />
              <p>Escolha o Distrito e Data que deseja ver.</p>
              <Form.Control
                id="district"
                type="email"
                size="sm"
                placeholder="Status"
                as="select"
                onInput={(e) => console.log("district")}
              >
                {this.buildOptionsFromArray(
                  undefined,
                  this.state.availableDistrict
                )}
              </Form.Control>
              <Form.Control
                style={{ marginTop: "2em" }}
                id="date"
                type="email"
                size="sm"
                placeholder="Status"
                as="select"
                onInput={(e) => console.log("date")}
              >
                {this.buildOptionsFromArray("date", this.state.availableMonth)}
              </Form.Control>
              <Button
                style={{ marginTop: "2em" }}
                variant="success"
                onClick={(e) => this.handleDistrictChange(e)}
              >
                {" "}
                Buscar{" "}
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4" />
      </Row>
    );
  }

  renderDetailModal() {
    if (this.state.parameter == "Pessoas") {
      return (
        <Modal
          className="modal-dialog"
          open={open}
          center={true}
          onClose={this.closeModal.bind(this)}
        >
          {this.renderCarroselDetail(this.renderPeople())}
        </Modal>
      );
    } else if (this.state.parameter == "Atendimento") {
      return (
        <Modal
          className="modal-dialog"
          open={open}
          center={true}
          onClose={this.closeModal.bind(this)}
        >
          {this.renderCarroselDetail(this.renderAttendance())}
        </Modal>
      );
    } else if (this.state.parameter == "Ativação") {
      return (
        <Modal
          className="modal-dialog"
          open={open}
          center={true}
          onClose={this.closeModal.bind(this)}
        >
          {this.renderCarroselDetail(this.renderActivation())}
        </Modal>
      );
    } else if (this.state.parameter == "Eficiência") {
      return (
        <Modal
          className="modal-dialog"
          open={open}
          center={true}
          onClose={this.closeModal.bind(this)}
        >
          {this.renderCarroselDetail(this.renderEfficiency())}
        </Modal>
      );
    }
    return (
      <Modal open={open} center={true} onClose={this.closeModal.bind(this)}>
        <h>oi</h>
      </Modal>
    );
  }

  closeModal() {
    this.setState({
      detailModal: false,
    });
    // this.state.closeModal=false
  }

  renderAttendance() {
    let attendanceContent = [];
    attendanceContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Atendimento")}
        >
          Atendimento
        </h>
        {/* <DownloadButton
                                id="exportAll"
                                text = ''
                                onClick={ e => this.exportData(0)}
                                btnId = "btnExportAll"
                              /> */}
        <AttendancePrincipal
          district={this.state.district}
          style={{ width: "60vw" }}
          date={this.state.date}
        ></AttendancePrincipal>
      </div>
    );
    attendanceContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Atendimento")}
        >
          Atendimento
        </h>
        <Sla
          district={this.state.district}
          style={{ width: "60vw" }}
          date={this.state.date}
        ></Sla>
      </div>
    );
    attendanceContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Atendimento")}
        >
          Atendimento
        </h>
        <DelayReason
          district={this.state.district}
          style={{ width: "60vw" }}
          date={this.state.date}
        ></DelayReason>
      </div>
    );
    attendanceContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Atendimento")}
        >
          Atendimento
        </h>
        <TmaTmp
          district={this.state.district}
          style={{ width: "60vw" }}
          date={this.state.date}
        ></TmaTmp>
      </div>
    );
    attendanceContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Atendimento")}
        >
          Atendimento
        </h>
        <Redirect
          district={this.state.district}
          style={{ width: "60vw" }}
          date={this.state.date}
        ></Redirect>
      </div>
    );
    attendanceContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Atendimento")}
        >
          Atendimento
        </h>
        <CSAT
          district={this.state.district}
          style={{ width: "60vw" }}
          date={this.state.date}
        ></CSAT>
      </div>
    );
    attendanceContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Atendimento")}
        >
          Atendimento
        </h>
        <D0
          district={this.state.district}
          style={{ width: "60vw" }}
          date={this.state.date}
        ></D0>
      </div>
    );
    return attendanceContent;
  }

  renderEfficiency() {
    let efficiencyContent = [];

    efficiencyContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Eficiência")}
        >
          Eficiência
        </h>
        {/* <DownloadButton
            id="exportAll"
            text = ''
            onClick={ e => this.exportData(0)}
            btnId = "btnExportAll"
          /> */}
        <EfficiencyPrincipal
          district={this.state.district}
          date={this.state.date}
          style={{ width: "60vw" }}
        ></EfficiencyPrincipal>
      </div>
    );
    efficiencyContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Eficiência")}
        >
          Eficiência
        </h>
        <ScreeningCycle
          district={this.state.district}
          date={this.state.date}
          style={{ width: "60vw" }}
        ></ScreeningCycle>
      </div>
    );
    efficiencyContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Eficiência")}
        >
          Eficiência
        </h>
        <GoodScreening
          district={this.state.district}
          date={this.state.date}
          style={{ width: "60vw" }}
        ></GoodScreening>
      </div>
    );
    efficiencyContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Eficiência")}
        >
          Eficiência
        </h>
        <Reverse
          district={this.state.district}
          date={this.state.date}
          style={{ width: "60vw" }}
        ></Reverse>
      </div>
    );
    return efficiencyContent;
  }

  renderActivation() {
    let activationContent = [];
    activationContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Ativação")}
        >
          Ativação
        </h>
        {/* <DownloadButton
                                id="exportAll"
                                text = ''
                                onClick={ e => this.exportData(0)}
                                btnId = "btnExportAll"
                              /> */}
        <ActivationPrincipal
          district={this.state.district}
          date={this.state.date}
          style={{ width: "60vw" }}
        ></ActivationPrincipal>
      </div>
    );
    activationContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Ativação")}
        >
          Ativação
        </h>
        <Portal district={this.state.district} date={this.state.date} />
      </div>
    );
    // activationContent.push(
    //   <div>
    //     <h
    //       style={{ fontFamily: "GoBold" }}
    //       onClick={(e) => this.handleSubmit("Ativação")}
    //     >
    //       Ativação
    //     </h>
    //     <Collact district={this.state.district} date={this.state.date} />
    //   </div>
    // );
    activationContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Ativação")}
        >
          Ativação
        </h>
        <Xray district={this.state.district} date={this.state.date} />
      </div>
    );
    return activationContent;
  }

  handleSubmit(parameter) {
    this.setState({
      detailModal: true,
      parameter: parameter,
    });
  }

  handleClean(event) {
    this.setState({
      district: undefined,
    });
  }

  renderPeople() {
    let peolpeContent = [];
    peolpeContent.push(
      <div>
        
        <h
          onClick={(e) => this.handleSubmit("Pessoas")}
        >
          Pessoas
        </h>
        
        <PeoplePrincipal
          district={this.state.district}
          date={this.state.date}
          style={{ width: "60vw" }}
        ></PeoplePrincipal>
      </div>
    );
    peolpeContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Pessoas")}
        >
          Pessoas
        </h>
        <Absenteeism district={this.state.district} date={this.state.date} />
      </div>
    );
    peolpeContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Pessoas")}
        >
          Pessoas
        </h>
        <Accidents district={this.state.district} date={this.state.date} />
      </div>
    );
    // peolpeContent.push(
    //   <div>
    //     <h
    //       style={{ fontFamily: "GoBold" }}
    //       onClick={(e) => this.handleSubmit("Pessoas")}
    //     >
    //       Pessoas
    //     </h>
    //     <DailyProd district={this.state.district} date={this.state.date} />
    //   </div>
    // );
    peolpeContent.push(
      <div>
        <h
          onClick={(e) => this.handleSubmit("Pessoas")}
        >
          Pessoas
        </h>
        <ArrivalHour district={this.state.district} date={this.state.date} />
      </div>
    );

    return peolpeContent;
  }

  renderCarrosel(content) {
    let data = [];
    content.forEach((item) =>
      data.push(
        <Carousel.Item style={{ width: "80%", marginLeft: "10%" }}>
          {item}
        </Carousel.Item>
      )
    );
    return (
      <div
        class="overflow-auto"
        style={{
          backgroundColor: "white",
          border: "1px",
          marginBottom: "10px",
          minHeight: "340px",
          maxHeight: "340px",
        }}
      >
        <div style={{ paddingTop: "5px" }}>
          <Carousel
            style={{ height: "100%" }}
            interval={false}
            slide={false}
            indicators={false}
            nextIcon={<NavigateNextIcon color="secondary" />}
            prevIcon={<NavigateBeforeIcon color="secondary" />}
          >
            {data}
          </Carousel>
        </div>
      </div>
    );
  }

  renderCarroselDetail(content) {
    let data = [];
    content.forEach((item) =>
      data.push(
        <Carousel.Item style={{ width: "80%", margin: "0 10%" }}>
          {item}
        </Carousel.Item>
      )
    );
    return (
      <div
        class="overflow-auto"
        style={{
          backgroundColor: "white",
          border: "1px",
          marginBottom: "10px",
          width: "760px",
        }}
      >
        <div style={{ paddingTop: "5px" }}>
          <Carousel
            style={{ height: "100%" }}
            interval={false}
            slide={false}
            indicators={false}
            nextIcon={<NavigateNextIcon color="secondary" />}
            prevIcon={<NavigateBeforeIcon color="secondary" />}
          >
            {data}
          </Carousel>
        </div>
      </div>
    );
  }

  render() {
    if (
      this.state.availableDistrict == undefined ||
      this.state.availableMonth == undefined
    ) {
      return (
        <div
          style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}
        >
          <LoadingSpinner />
        </div>
      );
    }
    return (
      <div>
        {this.state.detailModal ? this.renderDetailModal() : ""}
        {this.state.district == undefined ? (
          this.renderSearchBlock()
        ) : (
          <div>
            <Row>
              <Col xs sm md={2} lg xl={2}>
                <Button
                  style={{ marginLeft: "2em", marginRight: "2em" }}
                  variant="success"
                  onClick={(e) => this.handleClean(e)}
                >
                  Voltar
                </Button>
              </Col>
              <Col xs sm md={3} lg xl={2}>
                {this.state.district} - {this.state.date}
                <space></space>
                <DownloadButton
                                id="exportAll"
                                text = ''
                                onClick={ e => this.exportToCSV([['serviceOrders','data'],['mariana','oi'],[1]],'analitico.csv')}
                                btnId = "btnExportAll"
                              />
              </Col>
              <Col xs sm md={4} lg xl={5}>
              
              </Col>
              <Col xs sm md={3} lg xl={3}>
                <SenninhaBar
                  style={{ marginBottom: "2em" }}
                  district={this.state.district}
                  date={this.state.date}
                ></SenninhaBar>
              </Col>
            </Row>

            <Row className="DistrictRow">
              {/* <Col xs sm md  = {12}  lg xl = {2}>
              <div class="wrapper">
                  <nav id="sidebar">
                    <SideBarDistrict district={this.state.district} date={this.state.date}/>
                  </nav>
              </div>
            </Col> */}
              <Col xs sm md={12} lg xl={12}>
                <div>
                  <nav id="content">
                    <Container fluid>
                      <Row>
                        <Col xs sm md={3} lg xl={2}></Col>
                        <Col xs sm md={6} lg xl={7}></Col>
                        <Col xs sm md={3} lg xl={2}>
                          {/* <SenninhaBar style={{marginBottom: '2em'}} district={this.state.district} date={this.state.date} ></SenninhaBar> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs
                          sm={12}
                          md
                          lg
                          xl={6}
                          style={{ paddingLeft: "0px" }}
                        >
                          {this.renderCarrosel(this.renderAttendance())}
                        </Col>
                        <Col
                          xs
                          sm={12}
                          md
                          lg
                          xl={6}
                          style={{ paddingLeft: "0px" }}
                        >
                          {this.renderCarrosel(this.renderEfficiency())}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs
                          sm={12}
                          md
                          lg
                          xl={6}
                          style={{ paddingLeft: "0px" }}
                        >
                          {this.renderCarrosel(this.renderActivation())}
                        </Col>
                        <Col
                          xs
                          sm={12}
                          md
                          lg
                          xl={6}
                          style={{ paddingLeft: "0px" }}
                        >
                          {this.renderCarrosel(this.renderPeople())}
                        </Col>
                      </Row>
                    </Container>
                  </nav>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default DistrictPortal;
