import React from 'react'
import Graph from '../../../components/Logistic/graphic/graph'
import Box from '@material-ui/core/Box';
import _ from 'lodash'
import moment from 'moment'
import '../style.css'


const TriageHistoryGraph = props =>  {
      
        const {triageHistory} = props;

        if (!triageHistory) return null

        const graphObj = {
          data: {
            labels: [],
            datasets: [
              {
                label: 'Ciclo Triagem (% do total)',
                backgroundColor: '#e74c3c',
                borderColor: '#e74c3c',
                hoverBackgroundColor: '#e74c3c',
                hoverBorderColor: '#e74c3c',
                data: [],
                type: 'line',
                pointRadius: 0,
                fill: false,
                yAxisID: 'B',
              },
              {
                label: 'Transp + CD',
                backgroundColor: '#c8a2c8',
                borderColor: '#2c3e50',
                borderWidth: 0.5,
                hoverBackgroundColor: '#c8a2c8',
                hoverBorderColor: '#c8a2c8',
                data: [],
                type: 'line',
                pointRadius: 0,
                yAxisID: 'A',
              },
              {
                label: 'Retirado',
                backgroundColor: '#808080',
                borderColor: '#2c3e50',
                borderWidth: 0.5,
                hoverBackgroundColor: '#808080',
                hoverBorderColor: '#808080',
                data: [],
                type: 'line',
                pointRadius: 0,
                yAxisID: 'A',
              },
            ]
          },
          options: {
            responsive: undefined,
            maintainAspectRatio: false,
            tooltips: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: (tooltipItem, data) => {
                  if (tooltipItem.datasetIndex === 0) {
                    return `${(tooltipItem.yLabel * 100).toFixed(1)}%`
                  }
                  return tooltipItem.yLabel
                }
              }
            },
            scales: {
              xAxes: [{
                gridLines: {
                  display: false,
                },
              }],
              yAxes: [
                {
                  id: 'A',
                  type: 'linear',
                  position: 'left',
                  stacked: true,
                  ticks: {
                    max: 45 * 1000,
                    min: 0,
                    callback: function (value) {
                                 return (value/1000) + "k"
                               },
                  },
                },
                {
                  id: 'B',
                  type: 'linear',
                  position: 'right',
                  stacked: undefined,
                  ticks: {
                    max: 0.18,
                    min: 0,
                    callback: function (value) {
                                 return (value*100).toFixed(0) + '%'; // convert it to percentage
                             },
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
            title: {
              display: true,
              fontColor: 'black',
              fontSize: 20,
              text: 'Ciclo Triagem',
              fontFamily: 'GoBold'
            },
            plugins: {
              datalabels: {
                font: {
                  size: 0,
                },
                color: 'black',
              },
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: 'black',
            },
          },
        }
    
        _.orderBy(triageHistory, 'date').forEach((element) => {
          graphObj.data.labels.push(moment(element.date).format('DD/MM/YYYY'))
          graphObj.data.datasets[1].data.push(element.cicloTriagemCd)
          graphObj.data.datasets[2].data.push(element.cicloTriagemBase)
          graphObj.data.datasets[0].data.push((element.cicloTriagemCd + element.cicloTriagemBase) / element.totalTerminals)
        })
        
        const isDesktop =  window.innerWidth > 1300
        
        return (
            <Box height="100%">
              <Graph
                data={graphObj.data}
                legend={graphObj.legend}
                options={graphObj.options}
                width={isDesktop ? window.innerWidth/2.2 : 300}
                height={isDesktop ? 500 : 400}
            />
            </Box>
          )
    }

export default TriageHistoryGraph