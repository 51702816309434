/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
// import api from '../../services/api';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LoadingSpinner from '../../components/LoadingSpinner'

import LogisticService from '../../core/services/LogisticService';

import './styles.css';
import { getUserEmail } from '../../storage';

export default class TruckerDash extends Component {

    constructor() {
        super();

        this.state = { orders: undefined, available_orders: [], orders_tra: undefined, orders_pre: undefined };
    }

    componentDidMount() {
        this.loadOrders()
        this.loadOrdersTRA()
        this.loadOrdersPRE()
        this.loadAvailableOrders()
    };

    // Pega os dados na API (Mesmo processo pra TRA e PRE)
    loadOrders = async () => {

        const response_origin = LogisticService.getOrigin('/origin');

        console.log(response_origin)

        /* const response_origin = await api.get('/origin');
        const response_deadline = await api.get('/deadline');
        const response_gettruckers = await api.get('/trucker_name'); */

        this.handleOrders((await response_origin).data.data);
    }

    // Junta esses dados puros em um array (Mesmo processo pra TRA e PRE)
    handleOrders = (rawOrderNumber, rawOrigin, rawAvailableTruckers) => {

        let formattedOrders = [];

        // Push dos números de OM no array
        rawOrderNumber.forEach(element => {
            formattedOrders.push(element)
        })

        // Match dos elementos de Origem no array formatado
        // rawOrigin.forEach(element => {
        //     const searchedId = element.id

        //     const updatedIndex = formattedOrders.findIndex(e => e.id === searchedId)

        //     formattedOrders[updatedIndex].deadline = element.deadline

        //     this.setState({ orders: formattedOrders })
        // })

        let select = {
            "select": false,
        }

        formattedOrders.map(order => {
            order.select = select.select
        })

        this.setState({ orders: formattedOrders })
        
    }

    loadOrdersTRA = async () => {
        const data = {
            "trucker_name": getUserEmail(),
        }

        const response_origin_tra = LogisticService.getOriginTRA(data);
 

        if ((await response_origin_tra).data.data !== undefined) {
            this.handleOrdersTRA((await response_origin_tra).data.data);
        }


    }

    handleOrdersTRA = (rawOrderNumberTRA) => {
        let formattedOrders = [];

        if (rawOrderNumberTRA !== []) {

        // Push dos números de OM no array
        rawOrderNumberTRA.forEach(element => {
            formattedOrders.push(element)
        })

        }

        this.setState({ orders_tra: formattedOrders })
    }

    loadOrdersPRE = async () => {
        const data = {
            "trucker_name": getUserEmail(),
        }

        const response_origin_pre = LogisticService.getOriginPRE(data);
        // const response_deadline_pre = LogisticService.getDeadlinePRE('deadline_pre');


        /* const response_origin_pre = await api.get('/origin_pre');
        const response_deadline_pre = await api.get('/deadline_pre'); */

        
        if ((await response_origin_pre).data.data !== undefined) {
            this.handleOrdersPRE((await response_origin_pre).data.data);
        }
        
        
    }
    
    handleOrdersPRE = (rawOrderNumberPRE) => {
        let formattedOrders = [];

        if (rawOrderNumberPRE !== []) {

        // Push dos números de OM no array
        rawOrderNumberPRE.forEach(element => {
            formattedOrders.push(element)
        })

        // rawDeadlinePRE.forEach(element => {
        //     const searchedId = element.id

        //     const updatedIndex = formattedOrders.findIndex(e => e.id === searchedId)


        //     formattedOrders[updatedIndex].deadline = element.deadline

        // })

        }

        this.setState({ orders_pre: formattedOrders })
    }

    loadAvailableOrders = async () => {
        


        const param = {
            "trucker_name": getUserEmail(),
        }

        const get_data = LogisticService.getTrucker(param)

        this.handleAvailableOrders((await get_data).data.data)
    
    }

    handleAvailableOrders = (rawAvailableOrders) => {
        let formattedOrders = [];

        rawAvailableOrders.forEach(element => {
            formattedOrders.push(element)
        })

        this.setState({ available_orders: formattedOrders })
    }

    FilterType() {
        const slt = document.getElementById('tipo')

        if (slt.value === "tipo") {
            window.location.reload(false);
        }

        let filteredOrders = [];

        this.state.orders.map(order=> {
                if (order.destination === 'CD PAYTEC' || order.destination === 'CD BESTLOG' || order.destination === 'CD STONE BOX' && slt.value === 'Reversa') {
                    filteredOrders.push(order)
                }

                if (order.origin === 'CD PAYTEC' || order.origin === 'CD BESTLOG' || order.origin === 'CD STONE BOX' && slt.value === 'Avanço') {
                    filteredOrders.push(order)
                }
        })

        this.setState({ orders: filteredOrders })
    }

    FilterOrigin() {

        const slt = document.getElementById('origem')

        if (slt.value === "origem") {
            window.location.reload(false);
        }

        let filteredOrders = [];

        this.state.orders.map(order=> {
            if (order.origin === slt.value) {
                filteredOrders.push(order)
            }
        })

        this.setState({ orders: filteredOrders })

    }

    updateReasons(order) {

        const slt = document.getElementById('reasons')

        const data = {
            "inefficiency_reason": slt.value,
            "order_number": order.id
        }

        LogisticService.updateTrucker('/update_inefficiency', data);
        
            /* Axios.put('/update_inefficieny', data)
            .then((data) => {
                console.log(data);
            })

            .catch((err) => {
                console.log(err)
            }) */
    }

    setOM() {

         this.state.orders.map(order => {

            const slt_reason = document.getElementById('reasons')
            
            const data_trucker = {
                "order_number": order.id,
                "trucker_name": getUserEmail(),
                // "inefficiency_reason": slt_reason.value,
            }

            if (order.select === true) {
                
                LogisticService.updateTrucker(data_trucker);
                
                // LogisticService.updateTrucker('/update_inefficiency', data);

            }

        })

        this.loadOrders()
        this.loadAvailableOrders()

        this.forceUpdate();
    
        // window.location.reload(false);

    }

    unsetOM() {

        this.state.available_orders.map(order => {

           const slt_reason = document.getElementById('reasons')
           
           const data_trucker = {
               "order_number": order.id,
               "trucker_name": 'rota',
               // "inefficiency_reason": slt_reason.value,
           }

           if (order.select === true) {
               
               LogisticService.updateTrucker(data_trucker);
               
               // LogisticService.updateTrucker('/update_inefficiency', data);

           }

       })

       this.loadOrders()
       this.loadAvailableOrders()

       this.forceUpdate();
   
       // window.location.reload(false);

   }



    setOMTRA() {
        this.state.available_orders.map(order => {

            const input_data = {
                "order_number": order.id,
                "trucker_name": getUserEmail(),
            }
                
            if (order.select === true) {
              
            LogisticService.updateTruckerTRA(input_data);

            }

        })

        this.loadAvailableOrders()
        this.loadOrdersTRA()

        this.forceUpdate();

        // window.location.reload(false);
    }

    setOMPRE() {

        this.state.orders_tra.map(order => {

            const input_data = {
                "order_number": order.id,
                "trucker_name": getUserEmail(),
                "destination": order.destination,
            }
                
            if (order.select === true) {
            console.log(1);  
            LogisticService.updateTruckerPRE(input_data);

            }

        })
        this.loadOrdersTRA()
        this.loadOrdersPRE()
        this.forceUpdate();

        // window.location.reload(false);
    }

    updateSelect(order) {

            const checkBox = document.getElementById('check')

            order.select = true
            console.log(order.select)            
        
    }

    render() {
        
        if (this.state.orders === undefined || this.state.orders_tra === undefined || this.state.orders_pre == undefined) {
            return (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )
        }

        return (
            <Tabs defaultIndex={0}>
                <TabList>
                    <Tab>Pendentes</Tab>
                    <Tab>Alocadas</Tab>
                    <Tab>Em Andamento</Tab>
                    <Tab>Entregues</Tab>
                </TabList>
                <TabPanel>
                    <div className="type-dropdown-tipo">
                        <select name="tipo" id="tipo" onChange={()=> this.FilterType()}>
                            <option value="tipo">Tipo</option>
                            <option value="Avanço">Avanço</option>
                            <option value="Reversa">Reversa</option>
                        </select>
                    </div>
                    <div className="type-dropdown-origem">
                        <select name="origem" id="origem" onChange={()=>this.FilterOrigin()}>
                        <option value="origem">Origem</option>
                            <option value="CD STONE BOX">CD STONE BOX</option>
                            <option value="CD PAYTEC">CD PAYTEC</option>
                            <option value="CD BESTLOG">CD BESTLOG</option>
                        </select>
                    </div>
                    <div className="alocar-button">
                        <button class="button" value="Alocar" onClick={()=>this.setOM()}>Alocar</button>
                    </div>
                    <br></br>
                    <div className="main-table">
                        <TableContainer>
                            <Table className="simple-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell stickyHeader className="teste">Selecionar</TableCell>
                                        <TableCell className="teste">ID OM</TableCell>
                                        <TableCell align="right" className="table-head">Origem</TableCell>
                                        <TableCell align="right" className="table-head">Destino</TableCell>
                                        <TableCell align="right" className="table-head">Data de Abertura</TableCell>
                                        <TableCell align="right" className="table-head">Data Limite</TableCell>
                                        <TableCell align="right" className="table-head">Ineficiência</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.orders.map(order => (
                                        <TableRow key={order.id}>
                                            <TableCell component="th" scope="row" className="row-value">
                                               <input type='checkbox' id='check' onClick={()=> this.updateSelect(order)}></input> 
                                            </TableCell>
                                            <TableCell component="th" scope="row" className="row-value">{order.id}</TableCell>
                                            <TableCell align="right" className="row-value">{order.origin}</TableCell>
                                            <TableCell align="right" className="row-value">{order.destination}</TableCell>
                                            <TableCell align="right" className="row-value">{order.created_date}</TableCell>
                                            <TableCell align="right" className="row-value">{order.deadline}</TableCell>
                                            <TableCell align="right" className="row-value">
                                                <div className="dropdown-trucker">
                                                    <select name="inefficiency" id="reasons">
                                                        <option value="Razão da Ineficiência">Razão da Ineficiência</option>
                                                        <option value="Fechado">Fechado</option>
                                                        <option value="Ninguém para receber">Ninguém para receber</option>
                                                    </select>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="alocar-button">
                        <button class="unbutton" value="Alocar" onClick={()=>this.unsetOM()}>Desalocar</button>
                        <button class="button" value="Alocar" onClick={() => this.setOMTRA()}>Em Trânsito</button>
                    </div>
                    <br></br>
                    <div className="main-table">
                            <TableContainer>
                                <Table className="simple-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="table-head">Selecionar</TableCell>
                                            <TableCell className="table-head">ID OM</TableCell>
                                            <TableCell align="right" className="table-head">Origem</TableCell>
                                            <TableCell align="right" className="table-head">Destino</TableCell>
                                            <TableCell align="right" className="table-head">Data de Abertura</TableCell>
                                            <TableCell align="right" className="table-head">Data Limite</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.available_orders.map(order => (
                                            <TableRow key={order.id}>
                                                <TableCell component="th" scope="row" className="row-value">
                                                <input type='checkbox' id='check' onClick={()=> this.updateSelect(order)}></input> 
                                                </TableCell>
                                                <TableCell component="th" scope="row" className="row-value">{order.id}</TableCell>
                                                <TableCell align="right" className="row-value">{order.origin}</TableCell>
                                                <TableCell align="right" className="row-value">{order.destination}</TableCell>
                                                <TableCell align="right" className="row-value">{order.created_date}</TableCell>
                                                <TableCell align="right" className="row-value">{order.deadline}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div> 
                </TabPanel>
                <TabPanel>
                <div className="alocar-button">
                        <button class="button" value="Alocar" onClick={() => this.setOMPRE()}>Pré-Recebimento</button>
                    </div>
                    <br></br>
                    <div className="main-table">
                        <TableContainer>
                            <Table className="simple-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="table-head">Selecionar</TableCell>
                                        <TableCell className="table-head">ID OM</TableCell>
                                        <TableCell align="right" className="table-head">Origem</TableCell>
                                        <TableCell align="right" className="table-head">Destino</TableCell>
                                        <TableCell align="right" className="table-head">Data de Abertura</TableCell>
                                        <TableCell align="right" className="table-head">Data Limite</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.orders_tra.map(order => (
                                        <TableRow key={order.id}>
                                            <TableCell component="th" scope="row" className="row-value">
                                                <input type='checkbox' id='check' onClick={()=> this.updateSelect(order)}></input> 
                                            </TableCell>
                                            <TableCell component="th" scope="row" className="row-value">{order.id}</TableCell>
                                            <TableCell align="right" className="row-value">{order.origin}</TableCell>
                                            <TableCell align="right" className="row-value">{order.destination}</TableCell>
                                            <TableCell align="right" className="row-value">{order.created_date}</TableCell>
                                            <TableCell align="right" className="row-value">{order.deadline}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="main-table">
                        <TableContainer>
                            <Table className="simple-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="table-head">ID OM</TableCell>
                                        <TableCell align="right" className="table-head">Origem</TableCell>
                                        <TableCell align="right" className="table-head">Destino</TableCell>
                                        <TableCell align="right" className="table-head">Data de Abertura</TableCell>
                                        <TableCell align="right" className="table-head">Data de Entrega</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.orders_pre.map(order => (
                                        <TableRow key={order.id}>
                                            <TableCell component="th" scope="row" className="row-value">{order.id}</TableCell>
                                            <TableCell align="right" className="row-value">{order.origin}</TableCell>
                                            <TableCell align="right" className="row-value">{order.destination}</TableCell>
                                            <TableCell align="right" className="row-value">{order.created_date}</TableCell>
                                            <TableCell align="right" className="row-value">{order.deadline}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </TabPanel>
            </Tabs>
        )
    }
}