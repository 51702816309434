/* eslint react/prop-types: 0 */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import LoadingSpinner from "../../../components/LoadingSpinner";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#103a21",
    color: "white",
    fontSize: "1.1em",
    fontFamily: "GoBold",
  },
  body: {
    fontSize: "0.8em",
    lineHeight: "2",
  },
}))(TableCell);

const StockSummaryTable = (props) => {
  const { summary } = props;

  if (summary !== undefined) {
    const baixoGiro = summary.filter(
      (a) => a["Posição Cadeia"] !== "INDISPONÍVEL"
        && a["Posição Cadeia"] !== "INDISPONIVEL"
        && a.Tecnologia !== "GPRS-WIFI",
    );
    const altoGiro = summary.filter(
      (a) => a["Posição Cadeia"] !== "INDISPONÍVEL"
        && a["Posição Cadeia"] !== "INDISPONIVEL"
        && a.Tecnologia === "GPRS-WIFI",
    );

    const baixoGiroInstalado = _.sumBy(
      baixoGiro.filter((a) => a["Posição Cadeia"] === "EM PRODUÇÃO"),
      (o) => o.qtd,
    );

    const baixoGiroAtivo = _.sumBy(
      baixoGiro.filter((a) => a["Posição"] === "CLIENTE ATIVO"),
      (o) => o.qtd,
    );
    const altoGiroAtivo = _.sumBy(
      altoGiro.filter((a) => a["Posição"] === "CLIENTE ATIVO"),
      (o) => o.qtd,
    );

    const altoGiroInstalado = _.sumBy(
      altoGiro.filter((a) => a["Posição Cadeia"] === "EM PRODUÇÃO"),
      (o) => o.qtd,
    );

    const goodBaixoGiro = _.sumBy(
      baixoGiro.filter((a) => ["AVANÇO", "DISPONÍVEL BASE", "DISPONÍVEL CD"].includes(
        a["Posição Cadeia"],
      )),
      (o) => o.qtd,
    );
    const goodAltoGiro = _.sumBy(
      altoGiro.filter((a) => ["AVANÇO", "DISPONÍVEL BASE", "DISPONÍVEL CD"].includes(
        a["Posição Cadeia"],
      )),
      (o) => o.qtd,
    );

    const badBaixoGiro = _.sumBy(
      baixoGiro.filter((a) => ["BAD CD", "CICLO TRIAGEM", "CICLO LAB"].includes(a["Posição Cadeia"])),
      (o) => o.qtd,
    );
    const badAltoGiro = _.sumBy(
      altoGiro.filter((a) => ["BAD CD", "CICLO TRIAGEM", "CICLO LAB"].includes(a["Posição Cadeia"])),
      (o) => o.qtd,
    );

    const divergenciaBaixoGiro = _.sumBy(
      baixoGiro.filter((a) => a["Posição Cadeia"] === "DIVERGÊNCIA"),
      (o) => o.qtd,
    );
    const divergenciaAltoGiro = _.sumBy(
      altoGiro.filter((a) => a["Posição Cadeia"] === "DIVERGÊNCIA"),
      (o) => o.qtd,
    );

    return (
      <Table size="small">
        <TableHead>
          <TableRow hover>
            <StyledTableCell align="center" />
            <StyledTableCell align="center">BAIXO GIRO</StyledTableCell>
            <StyledTableCell align="center">ALTO GIRO</StyledTableCell>
            <StyledTableCell align="center">TOTAL</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover>
            <StyledTableCell align="left" style={{ backgroundColor: "#fff" }}>
              EFICIÊNCIA DA CADEIA
            </StyledTableCell>
            <StyledTableCell align="center" style={{ backgroundColor: "#fff" }}>
              {(
                (baixoGiroAtivo * 100)
                / (baixoGiroInstalado + badBaixoGiro + goodBaixoGiro)
              ).toFixed(2)}
              %
            </StyledTableCell>
            <StyledTableCell align="center" style={{ backgroundColor: "#fff" }}>
              {(
                (altoGiroAtivo * 100)
                / (altoGiroInstalado + badAltoGiro + goodAltoGiro)
              ).toFixed(2)}
              %
            </StyledTableCell>
            <StyledTableCell align="center" style={{ backgroundColor: "#fff" }}>
              {(
                ((altoGiroAtivo + baixoGiroAtivo)
                  / (altoGiroInstalado
                    + baixoGiroInstalado
                    + badAltoGiro
                    + goodAltoGiro
                    + badBaixoGiro
                    + goodBaixoGiro
                    + divergenciaBaixoGiro
                    + divergenciaAltoGiro))
                * 100
              ).toFixed(2)}
              %
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell align="left" style={{ backgroundColor: "#fff" }}>
              PARQUE TOTAL
            </StyledTableCell>
            <StyledTableCell align="center" style={{ backgroundColor: "#fff" }}>
              {(
                baixoGiroInstalado
                + badBaixoGiro
                + goodBaixoGiro
                + divergenciaBaixoGiro
              ).toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center" style={{ backgroundColor: "#fff" }}>
              {(
                altoGiroInstalado
                + badAltoGiro
                + goodAltoGiro
                + divergenciaAltoGiro
              ).toLocaleString(
                "pt",
              )}
            </StyledTableCell>
            <StyledTableCell align="center" style={{ backgroundColor: "#fff" }}>
              {(
                altoGiroInstalado
                + baixoGiroInstalado
                + goodBaixoGiro
                + badBaixoGiro
                + goodAltoGiro
                + badAltoGiro
                + divergenciaBaixoGiro
                + divergenciaAltoGiro
              ).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell
              align="left"
              style={{ backgroundColor: "#99bc95" }}
            >
              INSTALADOS
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ backgroundColor: "#99bc95" }}
            >
              {baixoGiroInstalado.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ backgroundColor: "#99bc95" }}
            >
              {altoGiroInstalado.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ backgroundColor: "#99bc95" }}
            >
              {(baixoGiroInstalado + altoGiroInstalado).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell>ATIVO</StyledTableCell>
            <StyledTableCell align="center">
              {baixoGiroAtivo.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {altoGiroAtivo.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {(baixoGiroAtivo + altoGiroAtivo).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell>0 TPV</StyledTableCell>
            <StyledTableCell align="center">
              {(baixoGiroInstalado - baixoGiroAtivo).toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {(altoGiroInstalado - altoGiroAtivo).toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {(
                baixoGiroInstalado
                - baixoGiroAtivo
                + (altoGiroInstalado - altoGiroAtivo)
              ).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell
              align="left"
              style={{ backgroundColor: "#99bc95" }}
            >
              NÃO INSTALADOS
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ backgroundColor: "#99bc95" }}
            >
              {(goodBaixoGiro + badBaixoGiro + divergenciaBaixoGiro).toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ backgroundColor: "#99bc95" }}
            >
              {(goodAltoGiro + badAltoGiro + divergenciaAltoGiro).toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ backgroundColor: "#99bc95" }}
            >
              {(
                goodBaixoGiro
                + badBaixoGiro
                + goodAltoGiro
                + badAltoGiro
                + divergenciaBaixoGiro
                + divergenciaAltoGiro
              ).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell>CICLO GOOD</StyledTableCell>
            <StyledTableCell align="center">
              {goodBaixoGiro.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {goodAltoGiro.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {(goodBaixoGiro + goodAltoGiro).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell>CICLO BAD</StyledTableCell>
            <StyledTableCell align="center">
              {badBaixoGiro.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {badAltoGiro.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {(badBaixoGiro + badAltoGiro).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell>DIVERGÊNCIA</StyledTableCell>
            <StyledTableCell align="center">
              {divergenciaBaixoGiro.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {divergenciaAltoGiro.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {(divergenciaBaixoGiro + divergenciaAltoGiro).toLocaleString(
                "pt",
              )}
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  return <LoadingSpinner />;
};

export default StockSummaryTable;
