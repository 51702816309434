import React, { Component } from "react";
import moment from "moment";
import LogisticService from "../../core/services/LogisticService";
import LoadingSpinner from "../../components/LoadingSpinner";
import RefreshButton from "../../custom-components/RefreshButton";
import { Container, Col, Row } from "react-bootstrap";
import CustomizedExpansionPannel from "../../custom-components/CustomizedExpansionPanel/index";
import Box from "@material-ui/core/Box";
import "./style.css";
import StockPositionTable from "./components/stockPositionTable";
import SummarySection from "./components/summarySection";
import HuntHistoryGraph from "./components/huntHistoryGraph";
import LabHistoryGraph from "./components/labHistoryGraph";
import TriageHistoryGraph from "./components/triageHistoryGraph";
import StockHistoryGraph from "./components/stockHistoryGraph";
import AllCapexGrouped from "./components/allCapexGrouped";
import CoverageHistoryGraph from "./components/coverageHistory"

class CapexPositionReport extends Component {
  static getClassNameForStatus(status) {
    if (status === "red") return "redDot";
    if (status === "yellow") return "yellowDot";
    if (status === "green") return "greenDot";
    return "grayDot";
  }

  constructor() {
    super();

    this.state = {
      consumption: undefined,
      coverageHistory:undefined,
      capexHistory: undefined,
      summaryRefactor: undefined,
    };
  }

  componentDidMount() {
    this.refreshReportData();
  }

  refreshReportData() {
    LogisticService.getCapexHistory().then((response) => {
      this.setState({
        lastUpdated: moment(),
        capexHistory: response.data.data,
      });
    });

    LogisticService.getCapexConsumptionRefactor().then((response) => {
      this.setState({ consumption: response.data.data });
    });

    LogisticService.getCoverageHistory().then((response) => {
      console.log(response.data.data)
      this.setState({ coverageHistory: response.data.data });
    });

    LogisticService.getCapexSummaryRefactor().then((response) => {
      const summary = response.data.data;
      const stoneSummary = summary.filter((a) =>
        ["ELAVON BRASIL", "STONE PAGAMENTOS"].includes(a["Contratante"])
      );
      this.setState({ summaryRefactor: stoneSummary });
    });
  }

  render() {
    const { summaryRefactor, capexHistory, consumption } = this.state;

    if (!capexHistory || !consumption || !summaryRefactor) {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let refreshContent;
    let lastUpdatedText = "";

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format(
        "DD/MM HH:mm"
      )}`;
    }

    if (!this.state.loading) {
      refreshContent = (
        <div style={{ textAlign: "right", marginBottom: "1em" }}>
          <label style={{ margin: "1em" }}>
            <i>{lastUpdatedText}</i>
          </label>
          <RefreshButton
            onClick={() => {
              this.refreshReportData();
            }}
          />
        </div>
      );
    }

    return (
      <Container fluid>
        <Col lg="12" xs="12" sm="12">
          {refreshContent}
          <CustomizedExpansionPannel
            title="Visão Geral"
            tooltip
            helpMessage="A eficiência do CAPEX é calculada dividindo-se o total de terminais ativos pelo total de terminais do parque. Um terminal é considerado ativo
            se tiver processado pelo menos uma transação acima de 1 real nos últimos 30 dias"
            content={
              <SummarySection
                capexHistory={this.state.capexHistory}
                summary={this.state.summaryRefactor}
              />
            }
          />
          <CustomizedExpansionPannel
            title="Estoque - Logística Direta"
            tooltip
            helpMessage="O Consumo diário é calculado com base na média diária de terminais instalados na ponta."
            content={
              <Row>
                <Col xs sm md={12} lg xl={4}>
                  <StockPositionTable
                    data={this.state.summaryRefactor}
                    consumptionData={this.state.consumption}
                    supplyChainPosition="DISPONÍVEL CD"
                  />
                </Col>
                <Col xs sm md={12} lg xl={4}>
                  <StockPositionTable
                    data={this.state.summaryRefactor}
                    consumptionData={this.state.consumption}
                    supplyChainPosition="AVANÇO"
                  />
                </Col>
                <Col xs sm md={12} lg xl={4}>
                  <StockPositionTable
                    data={this.state.summaryRefactor}
                    consumptionData={this.state.consumption}
                    supplyChainPosition="DISPONÍVEL BASE"
                  />
                </Col>
              </Row>
            }
          />
          <CustomizedExpansionPannel
            title="Estoque - Logística Reversa"
            tooltip
            helpMessage="O Consumo diário é calculado com base na média diária de terminais instalados na ponta. A cobertura na logística
            reversa significa a quantidade de dias 'retidos' de estoque, com base no consumo diário dos polos."
            content={
              <Row>
                <Col xs sm md={12} lg xl={4}>
                  <StockPositionTable
                    data={this.state.summaryRefactor}
                    consumptionData={this.state.consumption}
                    supplyChainPosition="CICLO TRIAGEM"
                  />
                </Col>
                <Col xs sm md={12} lg xl={4}>
                  <StockPositionTable
                    data={this.state.summaryRefactor}
                    consumptionData={this.state.consumption}
                    supplyChainPosition="CICLO LAB"
                  />
                </Col>
                <Col xs sm md={12} lg xl={4}>
                  <StockPositionTable
                    data={this.state.summaryRefactor}
                    consumptionData={this.state.consumption}
                    supplyChainPosition="BAD CD"
                  />
                </Col>
              </Row>
            }
          />
          <CustomizedExpansionPannel
            title="Estoque - Gráficos"
            content={
              <Row>
                <Col xs sm md={12} lg xl={6}>
                  <Box width="100%">
                    <StockHistoryGraph stockHistory={this.state.capexHistory} />
                  </Box>
                </Col>
                <Col xs sm md={12} lg xl={6}>
                  <Box width="100%">
                    <HuntHistoryGraph huntHistory={this.state.capexHistory} />
                  </Box>
                </Col>
                <Col xs sm md={12} lg xl={6}>
                  <Box width="100%">
                    <TriageHistoryGraph
                      triageHistory={this.state.capexHistory}
                    />
                  </Box>
                </Col>
                <Col xs sm md={12} lg xl={6}>
                  <Box width="100%">
                    <LabHistoryGraph labHistory={this.state.capexHistory} />
                  </Box>
                </Col>
                <Col xs sm md={12} lg xl={6}>
                  <Box width="100%">
                    <CoverageHistoryGraph coverageHistory={this.state.coverageHistory} />
                  </Box>
                </Col>
              </Row>
            }
          />
          <CustomizedExpansionPannel
            title="Terminais da Cadeia Agrupados"
            content={
              <Row>
                <Col xs sm md={12}>
                  <AllCapexGrouped data={
                    this.state.summaryRefactor.filter((a) =>
                    (a["Operador Logístico"] != null && a["Prestador"] != null)
                    )
                  } />
                </Col>
              </Row>
            }
          />
        </Col>
      </Container>
    );
  }
}

export default CapexPositionReport;
