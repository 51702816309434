//  Sheet. js indicator
import React from "react";
import XLSX from "xlsx";
import { Form } from "react-bootstrap";
import { message } from "antd";
import LogisticService from "../../core/services/LogisticService";

const successMessage = () => message.success("Dados enviados com sucesso");
const errorMessage = (text) => message.error(text);

const SheetJsInidicator = (props) => {
  const { tipo, onUpdate } = props;

  const handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      let ws;

      if (tipo === "acidentes") {
        ws = wb.Sheets["Acidentes"];
      } else if (tipo === "multas") {
        ws = wb.Sheets["Multas"];
      } else if (tipo === "indicadores") {
        ws = wb.Sheets["Indicadores"];
      }
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        range: 0,
        defval: "",
      });

      let organizedData;
      try {
        organizedData = organize_data(data);
      } catch (e) {
        errorMessage(e);
        return;
      }

      if (organizedData === [])
        errorMessage("As colunas importadas não batem com o padrão!");

      LogisticService.postMultipleIndicatorsExcel(tipo, {
        data: organizedData.rows,
      })
        .then(() => {
          successMessage().then(() => onUpdate());
        })
        .catch((error) => errorMessage(error.message));
    };

    rABS ? reader.readAsBinaryString(file) : reader.readAsArrayBuffer(file);
  };

  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) handleFile(files[0]);
  };

  return (
    <Form.Group controlId="inputRV">
      <Form.File
        accept={SupportedFiles}
        onChange={handleChange}
        id="importExcel"
        label="Importar Excel"
        custom
      />
    </Form.Group>
  );
};

const SupportedFiles = ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "ods"]
  .map((x) => "." + x)
  .join(",");

const organize_data = (data) => {
  let cols = [];
  let rows = [];

  data[0].forEach((col) => {
    const column = { field: col, title: col };
    if (["ACIDENTES", "MULTAS", "RESULTADO GERAL"].includes(col)) {
      column.type = "string";
    }
    cols.push(column);
  });

  data.shift();

  data.forEach((row) => {
    let apartedRows = {};
    for (let i = 0; i < row.length; i++) {
      apartedRows[cols[i].field] = row[i];
    }
    rows.push(apartedRows);
  });

  if (rows.length === 0) {
    throw "Planilha sem dados";
  }

  return { rows, cols };
};

export { SheetJsInidicator };
