/* eslint-disable */

import React from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";

import LastMileReport from "../pages/LastMileReport";
import CapexPositionReport from "../pages/CapexPositionReport";
import WallboardRegions from "../pages/WallboardRegions";
import WallboardDistricts from "../pages/WallboardDistricts";
import WallboardHubs from "../pages/WallboardHubs";
import Hub from "../pages/Hub";
import GestaoAtivos from "../pages/AssetManagement";
import PaytecStatesWallboard from "../pages/Paytec-States-Wallboard";
import PaytecProvidersWallboard from "../pages/Paytec-Providers-Wallboard";
import PaytecProviderDetail from "../pages/Paytec-Provider-Detail";
import AllFranchisesWallboard from "../pages/Franchises-General-Wallboard";
import FranchiseProviderDetail from "../pages/Franchises-Provider-Detail";
import CustomerInfo from "../pages/CustomerInfo";
import XrayReport from "../pages/XrayReport";
import XrayRegion from "../pages/XrayRegion";
import IDTReport from "../pages/IDTReport";
import UsefulLinks from "../pages/UsefulLinks";
import OnePage from "../pages/OnePageReport";
import MovementOrders from "../pages/MovementOrders";
import VariableRemunerationSearchPage from "../pages/VariableRemuneration/SearchPage";
import RemuneracaoVariavelNova from "../pages/VariableRemuneration/NovaRV";
import RemuneracaoVariavelGreenAngelV1 from "../pages/VariableRemuneration/AngelsResults/index";
import RemuneracaoVariavelGuardianAngelV1 from "../pages/VariableRemuneration/GuardiansResults";
import RemuneracaoVariavelSupplierAngelV1 from "../pages/VariableRemuneration/SuppliersResults";
import RemuneracaoVariavelHostAngelV1 from "../pages/VariableRemuneration/HostAngelsResults";
import RemuneracaoVariavelDistritaisV1 from "../pages/VariableRemuneration/DistrictsResults";
import RegionsVariableRemuneration from "../pages/VariableRemuneration/RegionsResults";
import TheFlash from "../pages/TheFlash";
import Leadtime from "../pages/Leadtimes";
import VariableRemunerationRankingPage from "../pages/VariableRemuneration/Ranking";
import LabCycle from "../pages/LabCycle";
import ScreeningCycle from "../pages/ScreeningCycle";
import CaseClassification from "../pages/CasesClassification";
import PeopleInsert from "../pages/PeopleInsert";
import MapLinkRadar from "../pages/MapLinkMap";
import PricingMovementOrders from "../pages/PricingMovementOrders";
import SecurityPanel from "../pages/SecurityPanel";
import Expedition from "../pages/Expedition";
import Otif from "../pages/OTIF";
import IDTOnBoard from "../pages/IDTOnBoard";
import DashLiga from "../pages/DashLiga";
import DistrictPortal from "../pages/DistrictPortal";
import HuntersChallenge from "../pages/Challenge-Hunter-Angels";
import DashLog from "../pages/DashLog";
import DashHunter from "../pages/DashHunter";
import TruckerDash from "../pages/TruckerDash";
import RoutesMaps from "../pages/RoutesMaps";
import NatanMap from "../pages/RoutesMaps/ConfigMaps";
import LigaTree from "../pages/LigaTree/index";
import AppNotifications from "../pages/AppNotifications";
import InputDadosSeguranca from "../pages/InputDadosSeguranca";
import { PrivateRoute, AuthMiddleware, useAuthContext, getAuthenticatedAxiosInstance } from 'stonelog-auth-components'

export default (
  <>
      <PrivateRoute exact path="/" component={<></>} />
      <PrivateRoute exact path="/reports/last-mile" component={LastMileReport} />
      <PrivateRoute exact path="/reports/capex-position" component={CapexPositionReport} />
      <PrivateRoute exact path="/regions" component={WallboardRegions} />
      <PrivateRoute exact path="/region-detail/:regionId" component={WallboardDistricts} />
      <PrivateRoute exact path="/district-detail/:districtId" component={WallboardHubs} />
      <PrivateRoute exact path="/hub/:name" component={Hub} />
      <PrivateRoute exact path="/asset-management" component={GestaoAtivos} />
      <PrivateRoute exact path="/states/paytec" component={PaytecStatesWallboard} />
      <PrivateRoute
        exact path="/providers/paytec/:stateName"
        component={PaytecProvidersWallboard}
      />
      <PrivateRoute
        exact path="/paytec/provider/:stateName/:providerName"
        component={PaytecProviderDetail}
      />
      <PrivateRoute exact path="/providers/franchise" component={AllFranchisesWallboard} />
      <PrivateRoute
        exact path="/franchise/provider/:providerName"
        component={FranchiseProviderDetail}
      />
      <PrivateRoute exact path="/client_data" component={CustomerInfo} />
      <PrivateRoute exact path="/xray" component={XrayReport} />
      <PrivateRoute exact path="/xray-region/:regiao" component={XrayRegion} />
      <PrivateRoute exact path="/idtreport" component={IDTReport} />
      <PrivateRoute exact path="/useful-links" component={UsefulLinks} />
      <PrivateRoute exact path="/one-page" component={OnePage} />
      <PrivateRoute exact path="/movement-orders" component={MovementOrders} />
      <PrivateRoute
        exact path="/variable-remuneration"
        component={VariableRemunerationSearchPage}
      />
      <PrivateRoute
        exact path="/rv/green-angels"
        component={RemuneracaoVariavelGreenAngelV1}
      />
      <PrivateRoute exact path="/rv/v2/green-angels" component={RemuneracaoVariavelNova} />
      <PrivateRoute
        exact path="/rv/guardian-angels"
        component={RemuneracaoVariavelGuardianAngelV1}
      />
      <PrivateRoute exact path="/rv/v2/guardian-angels" component={RemuneracaoVariavelNova} />
      <PrivateRoute
        exact path="/rv/supplier-angels"
        component={RemuneracaoVariavelSupplierAngelV1}
      />
      <PrivateRoute exact path="/rv/v2/supplier-angels" component={RemuneracaoVariavelNova} />
      <PrivateRoute exact path="/rv/host-angels" component={RemuneracaoVariavelHostAngelV1} />
      <PrivateRoute exact path="/rv/v2/host-angels" component={RemuneracaoVariavelNova} />
      <PrivateRoute exact path="/rv/distritos" component={RemuneracaoVariavelDistritaisV1} />
      <PrivateRoute exact path="/rv/v2/distritos" component={RemuneracaoVariavelNova} />
      <PrivateRoute
        exact path="/regions-variable-remuneration"
        component={RegionsVariableRemuneration}
      />
      <PrivateRoute exact path="/the-flash" component={TheFlash} />
      <PrivateRoute exact path="/leadtime" component={Leadtime} />
      <PrivateRoute
        exact path="/rv/v2/ranking/:ano/:mes"
        component={VariableRemunerationRankingPage}
      />
      <PrivateRoute exact path="/lab-cycle" component={LabCycle} />
      <PrivateRoute exact path="/screening-cycle" component={ScreeningCycle} />
      <PrivateRoute exact path="/case-classification" component={CaseClassification} />
      <PrivateRoute exact path="/people-insert" component={PeopleInsert} />
      <PrivateRoute exact path="/maplink" component={MapLinkRadar} />
      <PrivateRoute exact path="/pricing-oms" component={PricingMovementOrders} />
      <PrivateRoute exact path="/security" component={SecurityPanel} />
      <PrivateRoute exact path="/expedition" component={Expedition} />
      <PrivateRoute exact path="/otif" component={Otif} />
      <PrivateRoute exact path="/dash-liga" component={DashLiga} />
      <PrivateRoute exact path="/district-portal" component={DistrictPortal} />
      <PrivateRoute exact path="/hunter-angel" component={HuntersChallenge} />
      <PrivateRoute exact path="/dash-log" component={DashLog} />
      <PrivateRoute exact path="/hunter" component={DashHunter} />
      <PrivateRoute exact path="/trucker-dash" component={TruckerDash} />
      <PrivateRoute exact path="/routes-maps" component={RoutesMaps} />
      <PrivateRoute exact path="/routes-maps-config" component={NatanMap} />
      <PrivateRoute exact path="/input-liga" component={LigaTree} />
      <PrivateRoute exact path="/app-notifications" component={AppNotifications} />
      <PrivateRoute exact path="/seguranca/resultados" component={InputDadosSeguranca} />
    </>
);
