import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { styles } from "./styles";
import LogisticService from "../../core/services/LogisticService";
import Graph from "../../components/Logistic/graphic/graph";
import LoadingSpinner from "../../components/LoadingSpinner";
import RefreshButton from "../../custom-components/RefreshButton";
import { Container, Col } from "react-bootstrap";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { Paper, Table, TableRow, TableBody, TableCell, TableHead } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#103a21",
    color: "white",
    fontSize: "0.9em",
    textAlign: "center",
  },
  body: {
    fontSize: "0.8em",
    textAlign: "center",
  },
}))(TableCell);

class WallboardHubs extends Component {
  constructor() {
    super();

    this.state = {
      lastMileLoading: false,
      supplyLoading: false,
      lastUpdated: undefined,
      triageHistory: undefined,
      hubs: undefined,
      districtName: "",
      redirect: false,
      additionalServices: [{ name: "REVERSÃO", enabled: false }],
      triageByHub: undefined,
    };

    this.refreshReportData = this.refreshReportData.bind(this);
  }

  static getClassNameForStatus(status) {
    if (status === "red") return styles.redDot;
    if (status === "yellow") return styles.yellowDot;
    if (status === "green") return styles.greenDot;
    return styles.grayDot;
  }

  status(resultado, meta) {
    if (resultado < meta) {
      return "green";
    } else if (resultado < (1, 1) * meta) {
      return "yellow";
    } else {
      return "red";
    }
  }

  componentDidMount() {
    this.refreshReportData();
  }

  refreshReportData() {
    this.setState({
      lastMileLoading: true,
      supplyLoading: true,
      hubs: undefined,
      lastUpdated: undefined,
      triageHistory: undefined,
      triageByHub: undefined,
    });

    const { districtId } = this.props.match.params;

    const services = this.state.additionalServices
      .filter((service) => service.enabled === true)
      .map((service) => service.name);

    //Requisição para obter os distritos da região e suas estatísticas
    LogisticService.getHubsWithMetrics(districtId, true, services).then((response) => {
      this.setState({
        lastMileLoading: false,
        hubs: response.data.data,
        districtName: response.data.district.name,
        lastUpdated: moment(),
      });

      //Requisição para o histórico do ciclo triagem da Região
      //Está dentro da requisição anterior porque depende do nome da região
      LogisticService.getTriageSituation(null, response.data.district[0].name, null).then((triage_response) => {
        this.setState({
          supplyLoading: false,
          lastUpdated: moment(),
          triageHistory: triage_response.data.total,
        });
      });

      const triagemPolos = [];

      this.state.hubs.forEach((hub) => {
        LogisticService.getTriageSituation(null, null, hub.name).then((response) => {
          const ciclo_tiagem_polo = response.data.status;

          triagemPolos.push({
            nome: hub.name,
            bad: ciclo_tiagem_polo.map((e) => (e.status == "BAD" ? e.qtd : 0)).reduce((a, b) => a + b, 0),
            reservaom: ciclo_tiagem_polo.map((e) => (e.status == "RESERVA OM" ? e.qtd : 0)).reduce((a, b) => a + b, 0),
            retirado: ciclo_tiagem_polo.map((e) => (e.status == "RETIRADO" ? e.qtd : 0)).reduce((a, b) => a + b, 0),
            transpcd: ciclo_tiagem_polo.map((e) => (e.status == "TRANSP_CD" ? e.qtd : 0)).reduce((a, b) => a + b, 0),
            resultado: ciclo_tiagem_polo.map((e) => e.qtd).reduce((a, b) => a + b, 0),
          });

          this.setState({
            supplyLoading: false,
            lastUpdated: moment(),
            triageByHub: triagemPolos,
          });
        });
      });
    });
  }

  getFormattedDate(days_in_the_past) {
    var today = new Date();
    var dd = today.getDate() - days_in_the_past;
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd;

    return today;
  }

  // Construtor da tabela principal, contendo todos os distritos e suas respectivas estatísticas
  summaryTable() {
    const results = this.state.hubs.map((hub) => (
      <TableRow>
        <StyledTableCell>
          <Link
            to={{
              pathname: `/hub/${hub.name}`,
            }}
          >
            {hub.name}
          </Link>
        </StyledTableCell>
        <StyledTableCell>{hub.completed_today}</StyledTableCell>
        <StyledTableCell>{hub.late_orders_count}</StyledTableCell>
        <StyledTableCell>{hub.due_today.NOVA}</StyledTableCell>
        <StyledTableCell>{hub.due_today.RECEBIDA}</StyledTableCell>
        <StyledTableCell>{hub.due_today["EM CAMPO"]}</StyledTableCell>
        <StyledTableCell>{hub.due_today.REPIQUE}</StyledTableCell>
        <StyledTableCell>{hub.sla_mtd.onTimePct}%</StyledTableCell>
      </TableRow>
    ));

    return (
      <Paper style={{ width: "100%", overflowX: "auto", marginTop: "1em" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Polos</StyledTableCell>
              <StyledTableCell>Feitos no dia</StyledTableCell>
              <StyledTableCell>Pendentes em atraso</StyledTableCell>
              <StyledTableCell>Nova</StyledTableCell>
              <StyledTableCell>Recebida</StyledTableCell>
              <StyledTableCell>Em Campo</StyledTableCell>
              <StyledTableCell>Repique</StyledTableCell>
              <StyledTableCell>SLA MTD</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{results}</TableBody>
        </Table>
      </Paper>
    );
  }

  // Construtor do gráfico com evolução histórica do ciclo triagem
  triageGraph() {
    const triageHistory = this.state.triageHistory;

    const graphObj = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Ciclo Triagem (Qtd)",
            backgroundColor: "#30a032",
            borderColor: "#30a032",
            hoverBackgroundColor: "#30a032",
            hoverBorderColor: "#30a032",
            borderWidth: 1,

            data: [],
            type: "line",
            fill: false,
            pointRadius: 3,
            yAxisID: "A",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: (tooltipItem, data) => {
              return tooltipItem.yLabel;
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              id: "A",
              type: "linear",
              position: "left",
              stacked: false,
              ticks: {
                min: 0,
                callback: function (value) {
                  return value / 1000 + "k";
                },
              },
            },
          ],
        },
        title: {
          display: false,
          fontColor: "black",
          fontSize: 20,
          text: "Ciclo Triagem",
        },
        plugins: {
          datalabels: {
            font: {
              size: 0,
            },
            color: "black",
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "black",
        },
      },
    };

    // eslint-disable-next-line no-undef
    _.orderBy(triageHistory, "referenceDate").forEach((element) => {
      graphObj.data.labels.push(moment(element.referenceDate).format("DD-MM-YYYY"));

      graphObj.data.datasets[0].data.push(element.qtd);
    });

    return (
      <div>
        <Graph data={graphObj.data} legend={graphObj.legend} options={graphObj.options} width={100} height={500} />
      </div>
    );
  }

  triageTable() {
    return (
      <Paper style={{ width: "100%", overflowX: "auto", marginTop: "1em" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Polo</StyledTableCell>
              <StyledTableCell>Bad</StyledTableCell>
              <StyledTableCell>Reserva OM</StyledTableCell>
              <StyledTableCell>Retirado</StyledTableCell>
              <StyledTableCell>Transp CD</StyledTableCell>
              <StyledTableCell>Total do Ciclo Triagem</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.triageByHub.map((hub) => (
              <TableRow>
                <StyledTableCell>{hub.nome}</StyledTableCell>
                <StyledTableCell>{hub.bad}</StyledTableCell>
                <StyledTableCell>{hub.reservaom}</StyledTableCell>
                <StyledTableCell>{hub.retirado}</StyledTableCell>
                <StyledTableCell>{hub.transpcd}</StyledTableCell>
                <StyledTableCell>{hub.resultado}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  lastMileContent() {
    if (this.state.lastMileLoading === true || !this.state.hubs) {
      return (
        <div style={{ textAlign: "center", width: "100%" }}>
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <Col xs sm md lg xl={12}>
        {this.summaryTable()}
      </Col>
    );
  }

  screeningCycleContent() {
    if (this.state.supplyLoading === true || !this.state.triageHistory || !this.state.triageByHub) {
      return (
        <div style={{ textAlign: "center", width: "100%" }}>
          <LoadingSpinner />
        </div>
      );
    }
    return (
      <Col xs sm md lg xl={12}>
        <h3 style={{ textAlign: "center" }}>Evolução da Triagem:</h3>
        {this.triageGraph()}
      </Col>
    );
  }
  stockContent() {
    if (this.state.supplyLoading === true || !this.state.triageHistory || !this.state.triageByHub) {
      return (
        <div style={{ textAlign: "center", width: "100%" }}>
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <Col xs sm md lg xl={12}>
        {this.triageTable()}
      </Col>
    );
  }

  render() {
    let refreshContent;
    let lastUpdatedText = "";

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format("DD/MM HH:mm")}`;
    }

    if (!this.state.loading) {
      refreshContent = (
        <div style={{ textAlign: "right", marginBottom: "1em" }}>
          <label style={{ margin: "1em" }}>
            <i>{lastUpdatedText}</i>
          </label>
          <RefreshButton
            onClick={() => {
              this.refreshReportData();
            }}
          />
        </div>
      );
    }

    return (
      <Container fluid>
        <Col lg="12" xs="12" sm="12">
          {refreshContent}
          <ExpansionPanel defaultExpanded style={styles.ExpansionPanel}>
            <ExpansionPanelSummary style={styles.ExpansionPanelSummary}>
              <p style={{ margin: "0.5em", fontSize: "1.3em", fontWeight: "bold", fontFamily: "Segoe UI" }}>
                Last Mile
              </p>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{this.lastMileContent()}</ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded style={styles.ExpansionPanel}>
            <ExpansionPanelSummary style={styles.ExpansionPanelSummary}>
              <p style={{ margin: "0.5em", fontSize: "1.3em", fontWeight: "bold", fontFamily: "Segoe UI" }}>
                Ciclo Triagem
              </p>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{this.screeningCycleContent()}</ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded style={styles.ExpansionPanel}>
            <ExpansionPanelSummary style={styles.ExpansionPanelSummary}>
              <p style={{ margin: "0.5em", fontSize: "1.3em", fontWeight: "bold", fontFamily: "Segoe UI" }}>
                Situação de Estoque
              </p>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{this.stockContent()}</ExpansionPanelDetails>
          </ExpansionPanel>
        </Col>
      </Container>
    );
  }
}

WallboardHubs.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      // eslint-disable-next-line react/no-typos
      districtId: PropTypes.integer,
    }),
  }).isRequired,
};

export default WallboardHubs;
