/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import style from "./style.css";
import LogisticService from "../../core/services/LogisticService";
import TableYann from "../../components/Table";
import RefreshButton from "../../custom-components/RefreshButton";
import { Container, Col, Row } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import CustomizedExpansionPannel from "../../custom-components/CustomizedExpansionPanel/index";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#103a21",
    color: "white",
    fontSize: "0.9em",
  },
  body: {
    fontSize: "0.8em",
  },
}))(TableCell);

class PaytecProviderDetail extends Component {
  // Seleciona o farol correspondente, de acordo com a situação do estoque

  static getClassNameForStatus(status) {
    if (status === "red") return "redDot";
    if (status === "yellow") return "yellowDot";
    if (status === "green") return "greenDot";
    return "grayDot";
  }

  constructor() {
    super();
    this.state = {
      today: moment().format("YYYY-MM-DD"),
      providerName: "",
      loaded: false,
      pendingOrders: undefined,
      lastUpdated: undefined,
      tableIncomingMovementOrders: undefined,
      tableStockSituation: undefined,
    };
  }

  componentDidMount() {
    this.refreshReportData();
  }

  refreshReportData() {
    const name = this.props.match.params.providerName;

    this.setState({
      today: moment().format("YYYY-MM-DD"),
      providerName: name,
      loaded: false,
      pendingOrders: undefined,
      tableStockSituation: undefined,
      tableIncomingMovementOrders: undefined,
      lastUpdated: moment(),
    });

    LogisticService.getPendingOrdersThirdParty(this.state.today, name).then((pendingOrders_response) => {
      this.buildtablePendingOrders(pendingOrders_response.data);
    });

    LogisticService.getStockSituation(name).then((stock_response) => {
      let tableStockSituation = this.buildTableStock(stock_response.data.data);

      this.setState({
        loaded: true,
        tableStockSituation: tableStockSituation,
        lastUpdated: moment(),
      });
    });

    LogisticService.getIncomingOrders(name).then((oms_response) => {
      let tableIncomingMovementOrders = this.buildTableMovementOrders(oms_response.data);

      this.setState({
        loaded: true,
        tableIncomingMovementOrders: tableIncomingMovementOrders,
        lastUpdated: moment(),
      });
    });
  }

  buildtablePendingOrders(response) {
    let data = [];

    response.data.forEach((os) => {
      data.push({
        os: os.orderNumber,
        stonecode: os.customer,
        quantity: os.quantity,
        status: os.status,
        customerName: os.customerName,
        serviceGroup: os.serviceGroup,
        installedTerminalType: os.installedTerminalType,
        neighborhood: os.neighborhood,
        city: os.city,
        openingDate: os.openingDate,
        deadlineDate: os.deadlineDate,
        customerDeadlineDate: os.customerDeadlineDate,
      });
    });

    const table = (
      <TableYann
        data={data}
        pageSize={20}
        tableClassName="table-full"
        resizable="true"
        defaultSortedId="isWorrying"
        headers={[
          {
            headerText: "Número OS",
            accessor: "os",
          },
          {
            headerText: "Stonecode",
            accessor: "stonecode",
          },
          {
            headerText: "Quantidade",
            accessor: "quantity",
          },
          {
            headerText: "Status",
            accessor: "status",
          },
          {
            headerText: "Cliente",
            accessor: "customerName",
          },
          {
            headerText: "Serviço",
            accessor: "serviceGroup",
          },
          {
            headerText: "Consome",
            accessor: "installedTerminalType",
          },
          {
            headerText: "Bairro",
            accessor: "neighborhood",
          },
          {
            headerText: "Cidade",
            accessor: "city",
          },
          {
            headerText: "Data de Abertura",
            accessor: "openingDate",
          },
          {
            headerText: "Data Limite",
            accessor: "deadlineDate",
          },
          {
            headerText: "Data Limite Cliente",
            accessor: "customerDeadlineDate",
          },
        ]}
      />
    );

    this.setState({
      pendingOrders: table,
      lastUpdated: moment(),
    });
  }

  rendertablePendingOrders() {
    if (this.state.pendingOrders) {
      return (
        <Box width={window.innerWidth < 1300 ? "100%" : "100%"} mx="auto" overflow="auto">
          {this.state.pendingOrders}
        </Box>
      );
    }
    return (
      <div style={{ margin: "2em", textAlign: "center" }}>
        <LoadingSpinner />
      </div>
    );
  }

  buildTableStock(skus) {
    let stockSituation = [];
    let status = undefined;
    skus.forEach((sku) => {
      if (sku.status === null) {
        status = "green";
      } else {
        status = sku.status;
      }

      if (sku.consumoDiario != null) {
        sku.consumoDiario = sku.consumoDiario.toFixed(1);
      } else {
        sku.consumoDiario = "0.0";
      }

      if (sku.cobertura > 200 || sku.cobertura === null) {
        sku.cobertura = "∞";
      } else {
        sku.cobertura = sku.cobertura.toFixed(1);
      }

      stockSituation.push(
        <TableRow hover>
          <StyledTableCell align="center">{sku.tecnologia}</StyledTableCell>
          <StyledTableCell align="center">{sku.modelo}</StyledTableCell>
          <StyledTableCell align="center">{sku.quantidade}</StyledTableCell>
          <StyledTableCell align="center">{sku.agingMaximo} dias</StyledTableCell>
          <StyledTableCell align="center">{sku.agingMedio} dias</StyledTableCell>
          <StyledTableCell align="center">{sku.consumoDiario}</StyledTableCell>
          <StyledTableCell align="center">{sku.cobertura}</StyledTableCell>
        </TableRow>
      );
    });

    return (
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Tecnologia</StyledTableCell>
            <StyledTableCell align="center">Modelo</StyledTableCell>
            <StyledTableCell align="center">Quantidade</StyledTableCell>
            <StyledTableCell align="center">Aging Máximo</StyledTableCell>
            <StyledTableCell align="center">Aging Médio</StyledTableCell>
            <StyledTableCell align="center">Consumo Diário</StyledTableCell>
            <StyledTableCell align="center">Cobertura (em dias)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{stockSituation}</TableBody>
      </Table>
    );
  }

  renderTableStock() {
    if (this.state.loaded) {
      return (
        <Box width={window.innerWidth < 1300 ? "100%" : "75%"} mx="auto" overflow="auto">
          {this.state.tableStockSituation}
        </Box>
      );
    }

    return <div></div>;
  }

  buildTableMovementOrders(oms) {
    let trs = [];
    oms.data.forEach((om) => {
      trs.push(
        <TableRow hover>
          <StyledTableCell align="center">{om.orderNumber}</StyledTableCell>
          <StyledTableCell align="center">{om.dataDeEnvio}</StyledTableCell>
          <StyledTableCell align="center">{om.previsaoChegada}</StyledTableCell>
          <StyledTableCell align="center">{om.origem}</StyledTableCell>
          <StyledTableCell align="center">{om.transferType}</StyledTableCell>
          <StyledTableCell align="center">{om.status}</StyledTableCell>
          <StyledTableCell align="center">{om.model}</StyledTableCell>
          <StyledTableCell align="center">{om.quantidade}</StyledTableCell>
        </TableRow>
      );
    });

    return (
      <Table>
        <TableHead>
          <TableRow hover>
            <StyledTableCell align="center">Número da OM</StyledTableCell>
            <StyledTableCell align="center">Data de Envio</StyledTableCell>
            <StyledTableCell align="center">Previsão de Chegada</StyledTableCell>
            <StyledTableCell align="center">Origem</StyledTableCell>
            <StyledTableCell align="center">Tipo de Transferência</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="center">Modelo</StyledTableCell>
            <StyledTableCell align="center">Quantidade</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{trs}</TableBody>
      </Table>
    );
  }

  renderIncomingOMS() {
    if (this.state.tableIncomingMovementOrders != undefined) {
      return (
        <Box width={window.innerWidth < 1300 ? "100%" : "75%"} mx="auto" overflow="auto">
          {this.state.tableIncomingMovementOrders}
        </Box>
      );
    }
    return <div></div>;
  }

  render() {
    let refreshContent;
    let lastUpdatedText = "";

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format("DD/MM HH:mm")}`;
    }

    refreshContent = (
      <div style={{ textAlign: "right", marginBottom: "1em", marginTop: "1em" }}>
        <label style={{ marginRight: "1em" }}>{lastUpdatedText}</label>
        <RefreshButton
          onClick={() => {
            this.refreshReportData();
          }}
        />
      </div>
    );

    return (
      <Container fluid>
        <h2>Base {this.state.providerName}</h2>
        <Col xs sm md lg xl={12}>
          {refreshContent}
          <CustomizedExpansionPannel
            title="OSs atrasadas ou com vencimento hoje:"
            content={this.rendertablePendingOrders()}
          />
          <CustomizedExpansionPannel title="Estoque" content={this.renderTableStock()} />
          <CustomizedExpansionPannel title="OMs a caminho" content={this.renderIncomingOMS()} />
        </Col>
      </Container>
    );
  }
}

PaytecProviderDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default PaytecProviderDetail;
