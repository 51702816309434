/* eslint-disable */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import style from './style.css'
import { withStyles } from '@material-ui/core/styles';
import CustomizedExpansionPannel from '../../custom-components/CustomizedExpansionPanel/index'
import Graph from '../../components/Logistic/graphic/graph'

import LoadingSpinner from '../../components/LoadingSpinner'
import RefreshButton from '../../custom-components/RefreshButton'
import LogisticService from '../../core/services/LogisticService'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import TableCell from '@material-ui/core/TableCell';


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#37B766',
    color: 'white',
    fontSize: '0.9em',
  },
  body: {
    fontSize: '0.8em'
  },
}))(TableCell);


class ScreeningCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stonecode: '',
      loading: false,
      lastUpdated: undefined,
      usefulDays:undefined,
      omsClosed: {
        graph: undefined,
        loaded: false
      },
      dayPaytec: {
        graph: undefined,
        loaded: false
      },
      dayBestlog: {
        graph: undefined,
        loaded: false
      },
      dayBox: {
        graph: undefined,
        loaded: false
      },
      dayDOA: {
        graph: undefined,
        loaded: false
      },
      monthScreening: {
        graph: undefined,
        loaded: false
      },
      monthGoodScreening: {
        graph: undefined,
        loaded: false
      },
      monthShareScreening: {
        graph: undefined,
        loaded: false
      },
      dayGoodScreening: {
        graph: undefined,
        loaded: false
      },
      divisionScreening: {
        graph: undefined,
        loaded: false
      },
      monthScreeningGlobal: {
        graph: undefined,
        loaded: false
      },
      screeningDivision: {
        graph: undefined,
        loaded: false
      },
      screeningDivisionOperator: {
        graph: undefined,
        loaded: false
      },
    };


    this.refreshReportData = this.refreshReportData.bind(this)
  }

  refreshReportData() {
    const {today} = this.state
    this.setState({
      // loading: true,
      lastUpdated: today,
    })

    LogisticService.getUsefulDays().then((response) => {
      var now = new Date();
      if(now.getMonth()+1<10){
        var d = ''.concat(now.getFullYear()).concat('-0').concat(now.getMonth()+1)
      }
      else{
        var d = ''.concat(now.getFullYear()).concat('-').concat(now.getMonth()+1)
      }
      var useful = ''
      var mtdUseful = ''
      response.data.data.forEach((holiday) => {
        if(holiday.month == d){
          useful = holiday.useful
          mtdUseful = holiday.mtdUseful
        }
      })
      this.setState({
      usefulDays: mtdUseful
      })
    })

    LogisticService.getScreeningCycleResumeDay().then((response) => {
        this.setState({
          dayResume: response.data.data
        })
        LogisticService.getScreeningCycleDayDivision().then((responseDivision) => {
          this.buildDayGoodScreeningCycle(responseDivision.data.data)  
          this.buildDayHubsScreening(responseDivision.data.data,this.state.dayResume)  
          this.buildDayPaytecScreening(responseDivision.data.data,this.state.dayResume)  
          this.buildDayBoxScreening(responseDivision.data.data,this.state.dayResume)  
        })
    })

    LogisticService.getLabCycleDivisionOperator().then((responseOperator) => {
      this.buildScreeningDivisionOperator(responseOperator.data.data)
    })

    LogisticService.getLabCycleDivision().then((responseDivision) => {
      this.buildScreeningDivision(responseDivision.data.data)  
  })

  LogisticService.getScreeningGoodBad().then((responseDivision) => {
    this.buildMonthGoodScreeningCycle(responseDivision.data.data)  
})
  

  LogisticService.getScreeningCycleCDDivision().then((responseDivision) => {
    this.buildMonthScreeningCycle(responseDivision.data.data)  
    this.buildMonthShareScreeningCycle(responseDivision.data.data)
})

  


    LogisticService.getScreeningCycleResumeMonth().then((response) => {
        this.setState({
        monthResume: response.data.data
        })
        // this.buildMonthScreeningCycle(this.state.monthResume)
        this.buildMonthScreeningCycleGlobal(this.state.monthResume)
        // this.buildMonthGoodScreeningCycle(this.state.monthResume)
        this.buildDOAScreening(this.state.monthResume)
        // this.buildMonthShareScreeningCycle(this.state.monthResume)
        // this.buildDOAScreening(this.state.dayResume)
        LogisticService.getScreeningCycleDivision().then((response) => {
            this.setState({
            divisionCycle: response.data.data
            })
            this.buildScreeningCycleDivision(this.state.monthResume, this.state.divisionCycle)
          })
        
    })

  }

  componentDidMount() {
    this.refreshReportData()
  }

  buildScreeningDivisionOperator(objDivisionOperator) {
    const graphObjOperator =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Polo',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
            yAxisID: 'A',
          },
          {
            label: 'Base',
            stack: 'Stack 0',
            fill: false,
            borderWidth: 2,
            data: [],
            borderColor: '#00cc00',
            hoverBackgroundColor: '#00cc00',
            hoverBorderColor: '#00cc00',
            backgroundColor: '#00cc00',
            yAxisID: 'A',
          },
          {
            label: 'Franquia',
            stack: 'Stack 0',
            fill: false,
            borderWidth: 2,
            data: [],
            borderColor: 'green',
            hoverBackgroundColor: 'green',
            hoverBorderColor: 'green',
            backgroundColor: 'green',
            yAxisID: 'A',
          },
          {
            label: 'Caça',
            stack: 'Stack 0',
            fill: false,
            borderWidth: 2,
            data: [],
            borderColor: '#c8cfcc',
            hoverBackgroundColor: '#c8cfcc',
            hoverBorderColor: '#c8cfcc',
            backgroundColor: '#c8cfcc',
            yAxisID: 'A',
          },
          {
            label: '',
            stack: 'Stack 0',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            hoverBackgroundColor: 'transparent',
            hoverBorderColor: 'transparent',
            borderWidth: 2,
            data: [],
            type: 'line',
            fill: false,
            yAxisID: 'A',
          },
          {
            label: 'Polo %',
            stack: 'Stack 1',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
            yAxisID: 'B',
          },
          {
            label: 'Base %',
            stack: 'Stack 1',
            fill: false,
            borderWidth: 2,
            data: [],
            borderColor: '#00cc00',
            hoverBackgroundColor: '#00cc00',
            hoverBorderColor: '#00cc00',
            backgroundColor: '#00cc00',
            yAxisID: 'B',
          },
          {
            label: 'Franquia %',
            stack: 'Stack 1',
            fill: false,
            borderWidth: 2,
            data: [],
            borderColor: 'green',
            hoverBackgroundColor: 'green',
            hoverBorderColor: 'green',
            backgroundColor: 'green',
            yAxisID: 'B',
          },
          {
            label: 'Caça %',
            stack: 'Stack 1',
            fill: false,
            borderWidth: 2,
            data: [],
            borderColor: '#c8cfcc',
            hoverBackgroundColor: '#c8cfcc',
            hoverBorderColor: '#c8cfcc',
            backgroundColor: '#c8cfcc',
            yAxisID: 'B',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            id: 'A',
            stacked: true,
            gridLines: {
              display: false,
            },
            position: 'left',
            ticks: {
              min: 0,
              suggestedMax: 5000
            }
          },
          {
            id: 'B',
            stacked: true,
            gridLines: {
              display: false,
            },
            position: 'right',
            ticks: {
              min: 0,
              suggestedMax: 2
            }
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
        },
        plugins: {
          datalabels: {
            display: function (graphObjOperator) {
              return graphObjOperator.dataset.data[graphObjOperator.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            anchor: 'center',
            align: 'center',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1))
              // }
              // else {
              //   return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }

    _.orderBy(objDivisionOperator, 'referenceDate').forEach((element) => {
      
        graphObjOperator.data.labels.push("Real")
        graphObjOperator.data.labels.push("Meta")
        graphObjOperator.data.datasets[0].data.push(element.polo)
        graphObjOperator.data.datasets[1].data.push(element.base)
        graphObjOperator.data.datasets[2].data.push(element.franquia)
        graphObjOperator.data.datasets[3].data.push(element.hunt)
        graphObjOperator.data.datasets[4].data.push(element.hunt+element.franquia+element.base+element.polo)
        graphObjOperator.data.datasets[5].data.push((element.polo*100/element.total).toFixed(2))
        graphObjOperator.data.datasets[6].data.push((element.base*100/element.total).toFixed(2))
        graphObjOperator.data.datasets[7].data.push((element.franquia*100/element.total).toFixed(2))
        graphObjOperator.data.datasets[8].data.push((element.hunt*100/element.total).toFixed(2))
      }
    )

    console.log(graphObjOperator.data)


    this.setState({
      screeningDivisionOperator: {
        graph: graphObjOperator,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }


  renderScreeningDivisionOperator() {
    if (this.state.screeningDivisionOperator.loaded) {
      return (
      <div>
        <div>
          <Graph
            data={this.state.screeningDivisionOperator.graph.data}
            legend={this.state.screeningDivisionOperator.graph.legend}
            options={this.state.screeningDivisionOperator.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>
      </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>
    )
  }

  buildScreeningDivision(objDivision) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Ponta',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
            yAxisID: 'A',
          },
          {
            label: 'Transporte',
            stack: 'Stack 0',
            fill: false,
            borderWidth: 2,
            data: [],
            borderColor: '#e74c3c',
            hoverBackgroundColor: '#e74c3c',
            hoverBorderColor: '#e74c3c',
            backgroundColor: '#e74c3c',
            yAxisID: 'A',
          },
          {
            label: 'CD',
            stack: 'Stack 0',
            fill: false,
            borderWidth: 2,
            data: [],
            borderColor: 'green',
            hoverBackgroundColor: 'green',
            hoverBorderColor: 'green',
            backgroundColor: 'green',
            yAxisID: 'A',
          },
          {
            label: '',
            stack: 'Stack 0',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            hoverBackgroundColor: 'transparent',
            hoverBorderColor: 'transparent',
            borderWidth: 2,
            data: [],
            type: 'line',
            fill: false,
            yAxisID: 'A',
          },
          {
            label: 'Ponta %',
            stack: 'Stack 1',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
            yAxisID: 'B',
          },
          {
            label: 'Transporte %',
            stack: 'Stack 1',
            fill: false,
            borderWidth: 2,
            data: [],
            borderColor: '#e74c3c',
            hoverBackgroundColor: '#e74c3c',
            hoverBorderColor: '#e74c3c',
            backgroundColor: '#e74c3c',
            yAxisID: 'B',
          },
          {
            label: 'CD %',
            stack: 'Stack 1',
            fill: false,
            borderWidth: 2,
            data: [],
            borderColor: 'green',
            hoverBackgroundColor: 'green',
            hoverBorderColor: 'green',
            backgroundColor: 'green',
            yAxisID: 'B',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            id: 'A',
            stacked: true,
            gridLines: {
              display: false,
            },
            // position: 'start',
            ticks: {
              min: 0,
              suggestedMax: 5000
            }
          },
          {
            id: 'B',
            stacked: true,
            gridLines: {
              display: false,
            },
            position: 'right',
            ticks: {
              min: 0,
              suggestedMax: 3
            }
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            // anchor: 'center',
            align: 'center',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1))
              // }
              // else {
              //   return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }

    _.orderBy(objDivision, 'referenceDate').forEach((element) => {

        console.log(element)
      
        graphObj.data.labels.push("Real")
        graphObj.data.labels.push("Meta")
        graphObj.data.datasets[0].data.push(element.ponta)
        graphObj.data.datasets[1].data.push(element.transporte)
        graphObj.data.datasets[2].data.push(element.cd)
        graphObj.data.datasets[3].data.push(element.cd+element.transporte+element.ponta)
        graphObj.data.datasets[4].data.push((element.ponta*100/element.total).toFixed(2))
        graphObj.data.datasets[5].data.push((element.transporte*100/element.total).toFixed(2))
        graphObj.data.datasets[6].data.push((element.cd*100/element.total).toFixed(2))
        // graphObj.data.datasets[7].data.push((((element.cd+element.ponta+element.transporte)*100)/element.total).toFixed(2))
      }
    )


    this.setState({
      screeningDivision: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }


  renderScreeningDivision() {
    if (this.state.screeningDivision.loaded) {
      return (
      <div>
        <div>
          <Graph
            data={this.state.screeningDivision.graph.data}
            legend={this.state.screeningDivision.graph.legend}
            options={this.state.screeningDivision.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>
      </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>
    )
  }

  renderDivisionBlock(){
    return(
      <Row>
        <Col md lg xl = {6}>
          {this.renderScreeningDivision()}
        </Col>
        <Col md lg xl = {6}>
          {this.renderScreeningDivisionOperator()}
        </Col>
      </Row>
    )
  }


  buildDayHubsScreening(obj,resumeObj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Triagens Polo',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Quantidade Disponível',
            stack: 'Stack 0',
            fill: false,
            borderWidth: 2,
            data: [],
            type: 'line',
            borderColor: '#e74c3c',
            hoverBackgroundColor: '#e74c3c',
            hoverBorderColor: '#e74c3c',
            backgroundColor: '#e74c3c',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:5000
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1))
              // }
              // else {
              //   return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }


this.state.hubsScreen = 0
this.state.hubsScreenDays = 0

    _.orderBy(obj, 'scrDate').forEach((element) => {
      resumeObj.forEach((resumeElement) =>{
        if(resumeElement.referenceDate == element.scrDate){
          graphObj.data.datasets[1].data.push(resumeElement.hubAvailable)
          var now = new Date
      if((element.scrDate >= ('2020-0'+now.getMonth()+1+'-01') && now.getMonth()<9) || (('2020-'+now.getMonth()+1+'-01') && now.getMonth()>=9)){
        this.state.hubsScreen += element.hubs
        this.state.hubsScreenDays += 1
        graphObj.data.labels.push(" ".concat(element.scrDate))
        graphObj.data.datasets[0].data.push(element.hubs)
        
      }
        }
        
      })
      
      }
    )



    this.setState({
      omsClosed: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderDayHubsScreening() {
    if (this.state.omsClosed.loaded && this.state.usefulDays!=undefined) {
      return (
      <div>
        <div>
          <Graph
            data={this.state.omsClosed.graph.data}
            legend={this.state.omsClosed.graph.legend}
            options={this.state.omsClosed.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>
        <div align='center' style={{fontFamily: 'GoBold'}}>
          <b>Média Realizada: {(this.state.hubsScreen/this.state.usefulDays).toFixed(1)} </b>
        </div>
      </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>
    )
  }

  buildDayPaytecScreening(obj,objResume){
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Triagens Paytec',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Quantidade Disponível',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
            type: 'line',
            backgroundColor: '#e74c3c',
            borderColor: '#e74c3c',
            hoverBackgroundColor: '#e74c3c',
            hoverBorderColor: '#e74c3c',
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:0.7
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1))
              // }
              // else {
              //   return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }


    this.state.paytecScreen = 0
    this.state.paytecScreenDays = 0


    _.orderBy(obj, 'scrDate').forEach((element) => {
      objResume.forEach((resumeElement) => {
        if(resumeElement.referenceDate == element.scrDate){
          graphObj.data.datasets[1].data.push(resumeElement.paytecAvailable)
          var now = new Date
      if((element.scrDate >= ('2020-0'+now.getMonth()+1+'-01') && now.getMonth()<9) || (('2020-'+now.getMonth()+1+'-01') && now.getMonth()>=9)){
        this.state.paytecScreen += element.paytec
        this.state.paytecScreenDays += 1
        graphObj.data.labels.push(" ".concat(element.scrDate))
        graphObj.data.datasets[0].data.push(element.paytec)
        
      }

        }
      })
      
      }
    )



    this.setState({
      dayPaytec: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderDayPaytecScreening() {
    if (this.state.dayPaytec.loaded && this.state.usefulDays!=undefined) {
      return (
      <div>
        <div>
          <Graph
            data={this.state.dayPaytec.graph.data}
            legend={this.state.dayPaytec.graph.legend}
            options={this.state.dayPaytec.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>
        <div align='center' style={{fontFamily: 'GoBold'}}>
          <b>Média Realizada: {(this.state.paytecScreen/this.state.usefulDays).toFixed(1)} </b>
        </div>
      </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }


  buildDayBestlogScreening(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Quantidade Disponível',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
            type: 'line',
            backgroundColor: '#e74c3c',
            borderColor: '#e74c3c',
            hoverBackgroundColor: '#e74c3c',
            hoverBorderColor: '#e74c3c',
            fill: false,
          },
          {
            label: 'Triagens Bestlog',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:0.7
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1))
              // }
              // else {
              //   return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }

this.state.bestlogScreen = 0
this.state.bestlogScreenDays = 0

    _.orderBy(obj, 'referenceDate').forEach((element) => {
      var now = new Date
      if((element.referenceDate >= ('2020-0'+now.getMonth()+1+'-01') && now.getMonth()<9) || (('2020-'+now.getMonth()+1+'-01') && now.getMonth()>=9)){
        this.state.bestlogScreen += element.bestlogQty
        this.state.bestlogScreenDays += 1
        graphObj.data.labels.push(" ".concat(element.referenceDate))
        graphObj.data.datasets[1].data.push(element.bestlogTotal)
        graphObj.data.datasets[0].data.push(element.bestlogAvailable)
      }
      }
    )



    this.setState({
      dayBestlog: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }


  //Renderiza o gráfico de status de OS por data de abertura
  renderDayBestlogScreening() {
    if (this.state.dayBestlog.loaded && this.state.usefulDays!=undefined) {
      return (
        <div>
        <div>
          <Graph
            data={this.state.dayBestlog.graph.data}
            legend={this.state.dayBestlog.graph.legend}
            options={this.state.dayBestlog.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>
        <div align='center' style={{fontFamily: 'GoBold'}}>
          <b>Média Realizada: {(this.state.bestlogScreen/this.state.usefulDays).toFixed(1)} </b>
        </div>
      </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

  buildDayBoxScreening(obj,resumeObj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Quantidade Disponível',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
            type: 'line',
            backgroundColor: '#e74c3c',
            borderColor: '#e74c3c',
            hoverBackgroundColor: '#e74c3c',
            hoverBorderColor: '#e74c3c',
            fill: false,
          },
          {
            label: 'Triagens Box',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:0.7
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1))
              // }
              // else {
              //   return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }

this.state.boxScreen = 0
this.state.boxScreenDays = 0


    _.orderBy(obj, 'scrDate').forEach((element) => {
      resumeObj.forEach((resumeElement) => {
        if(resumeElement.referenceDate == element.scrDate){
          graphObj.data.datasets[0].data.push(resumeElement.boxAvailable)
          var now = new Date
      if((element.scrDate >= ('2020-0'+now.getMonth()+1+'-01') && now.getMonth()<9) || (('2020-'+now.getMonth()+1+'-01') && now.getMonth()>=9)){
        this.state.boxScreen += element.stonelog
        this.state.boxScreenDays += 1
        graphObj.data.labels.push(" ".concat(element.scrDate))
        graphObj.data.datasets[1].data.push(element.stonelog)
        
      }
        }
      })
      
      }
    )



    this.setState({
      dayBox: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }


  //Renderiza o gráfico de status de OS por data de abertura
  renderDayBoxScreening() {
    if (this.state.dayBox.loaded && this.state.usefulDays !=undefined ) {
      return (
        <div>
        <div>
          <Graph
            data={this.state.dayBox.graph.data}
            legend={this.state.dayBox.graph.legend}
            options={this.state.dayBox.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>
        <div align='center' style={{fontFamily: 'GoBold'}}>
          <b>Média Realizada: {(this.state.boxScreen/this.state.usefulDays).toFixed(1)} </b>
        </div>
      </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

  buildDOAScreening(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: '% Expedido',
            stack: 'Stack 0',
            backgroundColor: '#b4b3b1',
            borderWidth: 2,
            data: [],
            type: 'line',
            fill: false,
            yAxisID: 'B',
            borderColor:'#b4b3b1'
          },
          {
            label: 'DOA',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            id: 'A',
            stacked: false,
            gridLines: {
              display: false,
            },
            position: 'left',
            ticks: {
              min: 0,
              suggestedMax: 2000
            }
          },
          {
            id: 'B',
            stacked: false,
            gridLines: {
              display: false,
            },
            position: 'right',
            ticks: {
              min: 0,
              suggestedMax: 10
            }
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1))
              // }
              // else {
              //   return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }




    _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.date))
        graphObj.data.datasets[0].data.push((element.doa*100.0/element.totalExpeditions).toFixed(1))
        graphObj.data.datasets[1].data.push(element.doa)
      }
    )



    this.setState({
      dayDOA: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderDOAScreening() {
    if (this.state.dayDOA.loaded ) {
      return (
        <div>
          <Graph
            data={this.state.dayDOA.graph.data}
            legend={this.state.dayDOA.graph.legend}
            options={this.state.dayDOA.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

  buildMonthScreeningCycleGlobal(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Meta',
            stack: 'Stack 0',
            backgroundColor: '#C8CFCC',
            borderWidth: 2,
            data: [],
            type: 'line',
            fill: false,
            yAxisID: 'B',
            borderColor:'#C8CFCC'
          },
          {
            label: '% Realizado',
            stack: 'Stack 0',
            backgroundColor: '#103A21',
            borderWidth: 2,
            data: [],
            type: 'line',
            fill: false,
            yAxisID: 'B',
            borderColor:'#103A21'
          },
          {
            label: 'Ciclo Triagem Absoluto',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
            yAxisID: 'A',
          },

        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            id: 'A',
            stacked: false,
            gridLines: {
              display: false,
            },
            position: 'left'
          },
          {
            id: 'B',
            stacked: false,
            gridLines: {
              display: false,
            },
            position: 'right',
            ticks: {
              min: 0,
              suggestedMax: 10
            }
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
                // return String((value/1000).toFixed(1))
              // }
              // else {
              //   return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }

    this.state.goals = {
    '2019-07': 2.24,
    '2019-08': 1.82,
    '2019-09': 1.98,
    '2019-10': 2.58,
    '2019-11': 1.51,
    '2019-12': 1.49,
    '2020-01': 2.00,
    '2020-02': 2.17,
    '2020-03': 2.00,
    '2020-04': 1.80,
    '2020-05': 1.60,
    '2020-06': 1.40
    }


    _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.date))
        graphObj.data.datasets[0].data.push(this.state.goals[element.date])
        graphObj.data.datasets[1].data.push((100*element.screeningCycleGlobal/element.gprsWifiStock).toFixed(2))
        graphObj.data.datasets[2].data.push((element.screeningCycleGlobal/1000).toFixed(1))
      }
    )



    this.setState({
      monthScreeningGlobal: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderMonthScreeningCycleGlobal() {
    if (this.state.monthScreeningGlobal.loaded ) {
      return (
        <div>
          <Graph
            data={this.state.monthScreeningGlobal.graph.data}
            legend={this.state.monthScreeningGlobal.graph.legend}
            options={this.state.monthScreeningGlobal.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

  buildMonthScreeningCycle(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Triagens Paytec',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
            yAxisID: 'A',
          },
          {
            label: 'Triagens Bestlog',
            stack: 'Stack 0',
            backgroundColor: '#8DBB6E',
            borderWidth: 2,
            data: [],
            yAxisID: 'A',
          },
          {
            label: 'Triagens Stone Box',
            stack: 'Stack 0',
            backgroundColor: '#BCF7A1',
            borderWidth: 2,
            data: [],
            yAxisID: 'A',
          },

        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            id: 'A',
            stacked: true,
            gridLines: {
              display: false,
            },
            position: 'left',
            ticks: {
              suggestedMax: 30000
            }
          },
          {
            id: 'B',
            stacked: false,
            gridLines: {
              display: false,
            },
            position: 'right',
            ticks: {
              min: 0,
              suggestedMax: 10
            }
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            // anchor: 'end',
            // align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
                // return String((value/1000).toFixed(1))
              // }
              // else {
              //   return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }




    _.orderBy(obj, 'scrDate').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.scrDate))
        graphObj.data.datasets[0].data.push(element.paytec)
        graphObj.data.datasets[1].data.push(element.bestlog)
        graphObj.data.datasets[2].data.push(element.stonelog)
      }
    )



    this.setState({
      monthScreening: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderMonthScreeningCycle() {
    if (this.state.monthScreening.loaded ) {
      return (
        <div>
          <Graph
            data={this.state.monthScreening.graph.data}
            legend={this.state.monthScreening.graph.legend}
            options={this.state.monthScreening.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

  buildMonthGoodScreeningCycle(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: '% Aproveitamento',
            stack: 'Stack 0',
            backgroundColor: '#b4b3b1',
            borderWidth: 2,
            data: [],
            type: 'line',
            fill: false,
            yAxisID: 'B',
            borderColor:'#b4b3b1'
          },
          {
            label: 'Triagens GOOD',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
            yAxisID: 'A'
          },
          {
            label: 'Triagens BAD',
            stack: 'Stack 0',
            backgroundColor: '#67a53b',
            borderWidth: 2,
            data: [],
            yAxisID: 'A'
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            id: 'A',
            stacked: true,
            gridLines: {
              display: false,
            },
            position: 'left'
          },
          {
            id: 'B',
            stacked: false,
            gridLines: {
              display: false,
            },
            position: 'right',
            ticks: {
              min: 0,
              max: 100
            }
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            anchor: 'start',
            align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
                // return String((value/1000).toFixed(1))
              // }
              // else {
              //   return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }




    _.orderBy(obj, 'scrDate').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.scrDate))
        graphObj.data.datasets[0].data.push((element.good*100/(element.bad+element.good)).toFixed(1))
        graphObj.data.datasets[1].data.push((element.good/1000).toFixed(1))
        graphObj.data.datasets[2].data.push((element.bad/1000).toFixed(1))
      }
    )



    this.setState({
      monthGoodScreening: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderMonthGoodScreeningCycle() {
    if (this.state.monthGoodScreening.loaded ) {
      return (
        <div>
          <Graph
            data={this.state.monthGoodScreening.graph.data}
            legend={this.state.monthGoodScreening.graph.legend}
            options={this.state.monthGoodScreening.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

  buildMonthShareScreeningCycle(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Polos',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Bestlog',
            stack: 'Stack 0',
            backgroundColor: '#67a53b',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Paytec',
            stack: 'Stack 0',
            backgroundColor: '#07aa35',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Stone Box',
            stack: 'Stack 0',
            backgroundColor: '#103A21',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:0.7
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'white',
            weight: 'bold',
            anchor: 'center',
            align: 'center',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
                // return String((value/100).toFixed(1))
              // }
              // else {
              //   return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }




    _.orderBy(obj, 'scrDate').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.scrDate))
        graphObj.data.datasets[0].data.push((element.hubs*100.0/(element.hubs + element.paytec + element.bestlog + element.stonelog)).toFixed(1))
        graphObj.data.datasets[1].data.push((element.bestlog*100.0/(element.hubs + element.paytec + element.bestlog + element.stonelog)).toFixed(1))
        graphObj.data.datasets[2].data.push((element.paytec*100.0/(element.hubs + element.paytec + element.bestlog + element.stonelog)).toFixed(1))
        graphObj.data.datasets[3].data.push(((element.stonelog*100.0)/(element.hubs+element.paytec+element.bestlog+element.stonelog)).toFixed(1))
      }
    )



    this.setState({
      monthShareScreening: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderMonthShareScreeningCycle() {
    if (this.state.monthShareScreening.loaded ) {
      return (
        <div>
          <Graph
            data={this.state.monthShareScreening.graph.data}
            legend={this.state.monthShareScreening.graph.legend}
            options={this.state.monthShareScreening.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

buildScreeningCycleDivision(objResume,objDivision) {
  const graphObj =
  {
    data: {
      labels: [],
      datasets: [
        {
          label: 'Ciclo Triagem (%)',
          stack: 'Stack 0',
          backgroundColor: '#b4b3b1',
          borderWidth: 2,
          data: [],
        },
        {
          label: '',
          stack: 'Stack 1',
          backgroundColor: '#FFFFFF',
          borderWidth: 0,
          data: [],
          yAxisID: 'A',
        },
        {
          label: 'No CD há mais de 3 dias',
          stack: 'Stack 1',
          backgroundColor: '#00cc6d',
          borderWidth: 2,
          data: [],
          yAxisID: 'A',
        },
        {
          label: '',
          stack: 'Stack 2',
          backgroundColor: '#FFFFFF',
          borderWidth: 0,
          data: [],
          yAxisID: 'A',
        },
        {
          label: 'Terminais há mais de 30 dias',
          stack: 'Stack 2',
          backgroundColor: '#67a53b',
          borderWidth: 2,
          data: [],
          yAxisID: 'A',
        },
        {
          label: '',
          stack: 'Stack 3',
          backgroundColor: '#FFFFFF',
          borderWidth: 0,
          data: [],
          yAxisID: 'A',
        },
        {
          label: 'Em Trânsito (Atrasado)',
          stack: 'Stack 3',
          backgroundColor: '#7ef482',
          borderWidth: 2,
          data: [],
          yAxisID: 'A',
        },
        
        {
          label: '',
          stack: 'Stack 4',
          backgroundColor: '#FFFFFF',
          borderWidth: 0,
          data: [],
          yAxisID: 'A',
        },
        {
          label: 'Falta de Insumo',
          stack: 'Stack 4',
          backgroundColor: '#bcf7a1',
          borderWidth: 2,
          data: [],
          yAxisID: 'A',
        },
        
        {
          label: '',
          stack: 'Stack 5',
          backgroundColor: '#FFFFFF',
          borderWidth: 0,
          data: [],
          yAxisID: 'A',
        },
        {
          label: 'Reserva OM',
          stack: 'Stack 5',
          backgroundColor: '#d4f2c6',
          borderWidth: 2,
          data: [],
          yAxisID: 'A',
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          categoryPercentage: 0.9,
          gridLines: {
            display: false,
          },
        }],


yAxes: [{
      id: 'A',
      stacked: true,
      gridLines: {
        display: false,
      },
      position: 'left',
      ticks: {
          min: 0,
          suggestedMax: 5
        }
    },
    // {
    //   id: 'B',
    //   stacked: false,
    //   gridLines: {
    //     display: false,
    //   },
    //   position: 'right',
    //   ticks: {
    //     min: 0,
    //     max: 5
    //   }
    // }
  ],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: false,
        fontColor: 'black',
        fontSize: 20,
        text: '',
      },
      plugins: {
        datalabels: {
          display: function (graphObj) {
            return graphObj.dataset.data[graphObj.dataIndex] > 0
          },
          font: {
            size: 12,
            family: 'GoBold'
          },
          color: 'black',
          anchor: 'end',
          align: 'top',
          formatter: function (value) {
            return value
            // if (value >= 1000) {
              // return String((value/1000).toFixed(1))
            // }
            // else {
            //   return value
            // }
          }
        },
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'black',
        fontFamily: 'GoBold'
      },
    },
  }


  _.orderBy(objResume, 'date').forEach((element) => {
    this.state.recentCycle = ((element.screeningCycleGlobal)*100.0/element.gprsWifiStock).toFixed(2)
    this.state.recentStock = element.gprsWifiStock
  })
  objDivision.forEach((element) => {

    graphObj.data.labels.push(" ")
    graphObj.data.datasets[0].data.push(this.state.recentCycle)
    graphObj.data.datasets[1].data.push(this.state.recentCycle - (element.cd3days*100/this.state.recentStock).toFixed(2))
    graphObj.data.datasets[2].data.push((element.cd3days*100/this.state.recentStock).toFixed(2))
    graphObj.data.datasets[3].data.push((this.state.recentCycle - (element.cd3days*100/this.state.recentStock).toFixed(2) - (element.pos30days*100/this.state.recentStock).toFixed(2)).toFixed(2))
    graphObj.data.datasets[4].data.push((element.pos30days*100/this.state.recentStock).toFixed(2))
    graphObj.data.datasets[5].data.push((this.state.recentCycle - (element.cd3days*100/this.state.recentStock).toFixed(2) - (element.pos30days*100/this.state.recentStock).toFixed(2) - (element.transitSerials*100/this.state.recentStock).toFixed(2)).toFixed(2))
    graphObj.data.datasets[6].data.push((element.transitSerials*100/this.state.recentStock).toFixed(2))
    graphObj.data.datasets[7].data.push((this.state.recentCycle - (element.cd3days*100/this.state.recentStock).toFixed(2) - (element.pos30days*100/this.state.recentStock).toFixed(2) - (element.transitSerials*100/this.state.recentStock).toFixed(2) - (element.trdoGood*100/this.state.recentStock).toFixed(2)).toFixed(2))
    graphObj.data.datasets[8].data.push((element.trdoGood*100/this.state.recentStock).toFixed(2))
    graphObj.data.datasets[9].data.push((this.state.recentCycle - (element.cd3days*100/this.state.recentStock).toFixed(2) - (element.pos30days*100/this.state.recentStock).toFixed(2) - (element.transitSerials*100/this.state.recentStock).toFixed(2) - (element.trdoGood*100/this.state.recentStock).toFixed(2) - (element.reservaOm*100/this.state.recentStock).toFixed(2)).toFixed(2))
    graphObj.data.datasets[10].data.push((element.reservaOm*100/this.state.recentStock).toFixed(2))
  })






  this.setState({
    divisionScreening: {
      graph: graphObj,
      loaded: true
    },
    lastUpdated: moment(),
  })
}

//Renderiza o gráfico de status de OS por data de abertura
renderDivisionScreeningCycle() {
  if (this.state.divisionScreening.loaded ) {
    return (
      <div>
      <div>
        <Graph
          data={this.state.divisionScreening.graph.data}
          legend={this.state.divisionScreening.graph.legend}
          options={this.state.divisionScreening.graph.options}
          width={this.state.isDesktop ? 400 : 310}
          height={380}
        />
      </div>
    </div>)
  }

  return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
    <LoadingSpinner />
  </div>)
}



  buildDayGoodScreeningCycle(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Triagem CD',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
            yAxisID: 'A',
          },
          {
            label: 'Triagem na Ponta',
            stack: 'Stack 0',
            backgroundColor: '#67a53b',
            borderWidth: 2,
            data: [],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],


yAxes: [{
        id: 'A',
        stacked: true,
        gridLines: {
          display: false,
        },
        position: 'left',
        ticks: {
          min: 0,
          suggestedMax: 4000
        }
      },
      {
        id: 'B',
        stacked: false,
        gridLines: {
          display: false,
        },
        position: 'right',
        ticks: {
          min: 0,
          suggestedMax: 5
        }
      }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            // anchor: 'end',
            // align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
                // return String((value/1000).toFixed(1))
              // }
              // else {
              //   return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }


    _.orderBy(obj, 'scrDate').forEach((element) => {
      var now = new Date
      if((element.scrDate >= ('2020-0'+now.getMonth()+1+'-01') && now.getMonth()<9) || (('2020-'+now.getMonth()+1+'-01') && now.getMonth()>=9)){
        if(this.state.totalScreen == undefined || this.state.totalScreenDays ==undefined){
          this.state.totalScreen=0
          this.state.totalScreenDays = 0
        }
        graphObj.data.labels.push(" ".concat(element.scrDate))
        this.state.totalScreen += (element.hubs + element.paytec + element.bestlog + element.stonelog)
        this.state.totalScreenDays += 1
        graphObj.data.datasets[1].data.push(element.hubs)
        graphObj.data.datasets[0].data.push(element.paytec + element.bestlog + element.stonelog)
      }

      }
    )



    this.setState({
      dayGoodScreening: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderDayGoodScreeningCycle() {
    if (this.state.dayGoodScreening.loaded && this.state.usefulDays!=undefined) {
      return (
        <div>
        <div>
          <Graph
            data={this.state.dayGoodScreening.graph.data}
            legend={this.state.dayGoodScreening.graph.legend}
            options={this.state.dayGoodScreening.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>
        <div align='center' style={{fontFamily: 'GoBold'}}>
          <b>Média Realizada: {(this.state.totalScreen/this.state.usefulDays).toFixed(1)} </b>
        </div>
      </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }


  render() {

    let refreshContent
    let lastUpdatedText = ''

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format('DD/MM HH:mm')}`
    }

    if (!this.state.loading) {
      refreshContent = (
        <div style={{textAlign: 'right', marginBottom: '1em', fontFamily: 'GoBold'}}>
          <label style={{margin:'1em', fontFamily: 'GoBold'}}><i>{lastUpdatedText}</i></label>
          <RefreshButton onClick={() => { this.refreshReportData() }}/>
        </div>
      )
    }

    return (
      <Container fluid>
      {refreshContent}

      <CustomizedExpansionPannel
        title='Ciclo Triagem Mensal'
        content={this.renderMonthScreeningCycleGlobal()}
        helpMessage={<p>Ciclo Triagem mensal =  Quantidade total de triagens por mês / Total de GPRS-WIFI.</p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='Triagem Mensal'
        content={this.renderMonthScreeningCycle()}
        helpMessage={<p>Triagem mensal =  Quantidade total de triagens por mês.</p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='Aproveitamento e Triagens GOOD/BAD'
        content={this.renderMonthGoodScreeningCycle()}
        helpMessage={<p>% Aproveitamento Triagem =  Quantidade total de triagens GOOD em relação a  Quantidade total de triagens.</p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='SHARE - Triagem'
        content={this.renderMonthShareScreeningCycle()}
        helpMessage={<p>% Share Triagem = Quantidade total de triagens do operador/ Quantidade total de triagens.</p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='Triagens Por dia'
        content={this.renderDayGoodScreeningCycle()}
        helpMessage={<p>Triagem diária = Quantidade total de triagens good + Quantidade total de triagens bad. Indicador do Ciclo Triagem = Ciclo Triagem/ Parque Total</p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='Triagens Polos'
        content={this.renderDayHubsScreening()}
        helpMessage={<p>Triagens Polos = Quantidade de terminais triados nos Polos. E a relação da quantidade disponível de triagens GPRS-WIFI.</p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='Triagens Paytec'
        content={this.renderDayPaytecScreening()}
        helpMessage={<p>Triagens Paytec = Quantidade de terminais triados na Paytec. E a relação da quantidade disponível de triagens GPRS-WIFI.</p>}
        tooltip={true}
      />
      {/* <CustomizedExpansionPannel
        title='Triagens Bestlog'
        content={this.renderDayBestlogScreening()}
        helpMessage={<p>Triagens Bestlog = Quantidade de terminais triados na Bestlog. E a relação da quantidade disponível de triagens GPRS-WIFI.</p>}
        tooltip={true}
      /> */}
      <CustomizedExpansionPannel
        title='Triagens Stone Box'
        content={this.renderDayBoxScreening()}
        helpMessage={<p>Triagens Box = Quantidade de terminais triados no CD Stone Box. E a relação da quantidade disponível de triagens GPRS-WIFI.</p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='Potencial de Redução Ciclo Triagem'
        content={this.renderDivisionScreeningCycle()}
        helpMessage={<p>No cd há mais de 3 dias : status EM TRIAGEM (AGING > 3)  + PRÉ - RECEBIDOS (AGING > 3) + TRIAGEM CQ  (AGING > 3); Em trânsito : Oms que estão em trânsito acima do leadtime de reversa; Terminais há mais de 30 dias: status BAD (AGING >30) no polo/franquia/base terceira +status RETIRADO (AGING > 30) no polo /franquia/base terceira.</p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='Elos do Ciclo'
        content={this.renderDivisionBlock()}
        helpMessage={<p>No cd há mais de 3 dias : status EM TRIAGEM (AGING > 3)  + PRÉ - RECEBIDOS (AGING > 3) + TRIAGEM CQ  (AGING > 3); Em trânsito : Oms que estão em trânsito acima do leadtime de reversa; Terminais há mais de 30 dias: status BAD (AGING >30) no polo/franquia/base terceira +status RETIRADO (AGING > 30) no polo /franquia/base terceira.</p>}
        tooltip={true}
      />
      <div>
      </div>
      </Container>
    );
  }
}



export default ScreeningCycle
