/* eslint-disable */
import React, { Component } from "react";
import LogisticService from "../../../core/services/LogisticService";
import SortingTable from "../../../custom-components/SortingTable";

const columnsAccidents = [
  {
    title: "Polo",
    dataIndex: "polo",
    sorter: (a, b) => (a.polo < b.polo ? -1 : 1),
  },
  {
    title: "C/Afast.",
    dataIndex: "comafastamento",
    sorter: (a, b) => a.comafastamento - b.comafastamento,
  },
  {
    title: "S/Afast.",
    dataIndex: "semafastamento",
    sorter: (a, b) => a.semafastamento - b.semafastamento,
  },
  {
    title: "C/Patr.",
    dataIndex: "compatrimonio",
    sorter: (a, b) => a.compatrimonio - b.compatrimonio,
  },
  {
    title: "Total",
    dataIndex: "total",
    sorter: (a, b) => a.total - b.total,
  },
];

export class Accidents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      district: props.district,
      date: props.date,
      accidents: {
        loaded: false,
      },
      graph: undefined,
      loading: false,
      lastUpdated: undefined,
    };
  }

  componentDidMount() {
    LogisticService.getLmpPeople(
      "accidents",
      this.state.district,
      `${this.state.date}-01`,
      `${this.state.date}-01`
    ).then((response) => {
      this.setState({
        accidentsData: response.data.data,
        loaded: true,
      });
    });
  }

  render() {
    return (
      <SortingTable
        title="Acidentes"
        columns={columnsAccidents}
        data={this.state.accidentsData}
      />
    );
  }
}

export default Accidents;
