/* eslint-disable */
import React, { Component } from "react";
import  style from "./style.css";
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import Logo from './img/hunters.png'
import Podium from './img/podium.png'
import TableTest from './img/tableTest.png'
import TablePaging from './rankingMTDTable';
import RankingMTD from './rankingMTDTable'
import LoadingSpinner from '../../components/LoadingSpinner'
import LogisticService from '../../core/services/LogisticService'

class HuntersChallenge extends Component {

    constructor() {
  
        super()
    
        this.state = {
          loaded:false,
          rankFirstPerson:{
            data:undefined,
            loaded: false
          },
          rankFirstGroup:{
            data:undefined,
            loaded: false
          },
          rankFirstGoals:{
            data:undefined,
            loaded: false
          },
          rankOSTotal:{
            data:undefined,
            loaded: false
          },
          rankOSDay:{
            data:undefined,
            loaded: false
          },
        }
    
        this.refreshReportData = this.refreshReportData.bind(this)
      }

      componentDidMount() {
        this.refreshReportData()
      }

      refreshReportData(){

        LogisticService.getChallengeHunterOSRank('day').then((response)=>{  
            var i = 1
            var resultList = []
            _.orderBy(response.data.data, 'openingOs', 'desc').forEach((element) => {
                if(i<7){
                    resultList.push([i,element.group,element.cases,element.openingOs,element.arrivalOs])
                    i+=1
                }
            })
          this.setState({
            rankOSDay:{
                data:resultList,
                loaded: true
            },
          })
        })

        LogisticService.getChallengeHunterOSRank('total').then((response)=>{  
            var i = 1
            var resultList = []
            _.orderBy(response.data.data, 'arrivalOs', 'desc').forEach((element) => {
                if(i<7){
                    resultList.push([i,element.group,element.cases,element.openingOs,element.arrivalOs])
                    i+=1
                }
            })
          this.setState({
            rankOSTotal:{
                data:resultList,
                loaded: true
            },
          })
        })
        
        LogisticService.getChallengeHunterFirstRank('person').then((response)=>{  
            var i = 1
            var resultList = []
            _.orderBy(response.data.data, 'points', 'desc').forEach((element) => {
                if(i<7){
                    resultList.push([i,element.name,element.group,(element.points.toFixed(2))])
                    i+=1
                }
            })
          this.setState({
            rankFirstPerson:{
                data:resultList,
                loaded: true
            },
          })
        })

        LogisticService.getChallengeHunterFirstRank('group').then((responsePerson)=>{  
            var goalDict = {}
            LogisticService.getChallengeHunterFirstRank('goals').then((response)=>{  
                _.orderBy(response.data.data, 'goal').forEach((element) => {
                    goalDict[element.group]=[element.goalDay, element.goal]
                })
                
                this.setState({
                  rankFirstGoals:{
                      data:goalDict,
                      loaded: true                    
                  },
                })
              
            var i = 1
            var orderList = []
            var resultList = []
            _.orderBy(responsePerson.data.data, 'points', 'desc').forEach((element) => {
                var group = element.group.toString()
                if(i<7 && goalDict[element.group] != undefined){
                    console.log(goalDict[element.group])
                    console.log(goalDict[element.group][0])
                    console.log(element.pointDay/goalDict[element.group][0])
                    console.log(element.pointDay)
                    orderList.push({
                        'order': (element.point*100/parseInt(goalDict[element.group][1])),
                        'list': ([element.group,(element.pointDay).toFixed(2),`${(element.pointDay*100/goalDict[element.group][0]).toFixed(0)}%`,(element.point).toFixed(2),`${(element.point*100/parseInt(goalDict[element.group][1])).toFixed(0)}`])
                    })

                }
            })
            var i = 1
            _.orderBy(orderList, 'order', 'desc').forEach((element) => {
                
                if(i<7){
                    resultList.push([i, element.list[0], element.list[1], element.list[2], element.list[3], `${element.list[4]}%` ])
                    i+=1
                }
            })
            this.setState({
              rankFirstGroup:{
                  data:resultList,
                  loaded: true
                
              },
            })
            })
          })

          
      }            


    render(){
      if(!(this.state.rankFirstGoals.loaded && this.state.rankFirstPerson.loaded && this.state.rankFirstGroup.loaded && this.state.rankOSTotal.loaded && this.state.rankOSDay.loaded)){
          return(
            <Container className='container'>
                <div style={{ 'justifyContent': 'center', 'marginLeft': '45vw', backgroundColor: '#000000','width':'100vw', 'height': '200vh' }}>
                    <LoadingSpinner/>
                </div>
            </Container>
          )
      }
      else{
        return(
            <Container className='container'>
                <div className='head'>
                    <img className="logoHunters" src={Logo} />
                    <p className="logoText" >Campanha Caça POS . Junho - Julho 2020</p>
                    <p className="firstGroup">{this.state.rankFirstGroup.data[0][1]}</p>
                    <p className="secondGroup">{this.state.rankFirstGroup.data[1][1]}</p>
                    <p className="thirdGroup">{this.state.rankFirstGroup.data[2][1]}</p>
                    <img className="podiumIcon" src={Podium} />
                    <p className="firstPerson">{this.state.rankFirstPerson.data[0][1]}</p>
                    <p className="secondPerson">{this.state.rankFirstPerson.data[1][1]}</p>
                    <p className="thirdPerson">{this.state.rankFirstPerson.data[2][1]}</p>
                    <img className="podiumIconPeople" src={Podium} />
                    <div className="firstTableLeft">
                    <TablePaging
                        data ={this.state.rankOSTotal.data}
                        subtitleTable ={'subTitleDistrict'} 
                        title ={[{name:'Ranking Recuperações - MTD'}]}
                        subTitle ={[{name:'#Rank'},{name:'Grupo'},{name:'Clientes Tratados'},{name:'OSs Sem Atendimento'},{name:'Recuperações (OSs Baixadas)'}]}
                        colSpan ={1}
                        rowSpan = {6}
                        dataQtd = {5}
                    />     
                    </div>
                    <div className="firstTableRight">
                    <TablePaging
                        data ={this.state.rankOSDay.data}
                        subtitleTable ={'subTitleDistrict'} 
                        title ={[{name:'Ranking Agendamentos - Hoje'}]}
                        subTitle ={[{name:'#Rank'},{name:'Grupo'},{name:'Clientes Tratados'},{name:'OSs Abertas no Dia'},{name:'Recuperações (OSs Baixadas)'}]}
                        colSpan ={1}
                        rowSpan = {6}
                        dataQtd = {5}
                    />     
                    </div>
                    <div className="secondTableLeft">
                    <TablePaging
                        data ={this.state.rankFirstGroup.data}
                        subtitleTable ={'subTitleDistrict'} 
                        title ={[{name:'Placar - 1° Turno'}]}
                        subTitle ={[{name:'#Rank'},{name:'Grupo'},{name:'Pontos no Dia'},{name:'% no Dia'},{name:'Pontos Acumulados'},{name:'% Acumulado'}]}
                        colSpan ={1}
                        rowSpan = {6}
                        dataQtd = {6}
                    />     
                    </div>
                    <div className="secondTableRight">
                    <TablePaging
                        data ={this.state.rankFirstPerson.data}
                        subtitleTable ={'subTitleDistrict'} 
                        title ={[{name:'Artilheiros'}]}
                        subTitle ={[{name:'Top'},{name:'Pessoa'},{name:'Grupo'},{name:'Pontos'}]}
                        colSpan ={1}
                        rowSpan = {6}
                        dataQtd = {4}
                    />     
                    </div>
                </div>
            </Container>
        )
      }
    }
}

export default HuntersChallenge;
