/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.css'
import LogisticService from '../../core/services/LogisticService'
import Button from '../../components/Button'

class XrayRegion extends Component {
  constructor () {
    super()

    this.state = {
      lastMileLoading: false,
      supplyLoading: false,
      lastUpdated: undefined,
      triageHistory: undefined,
      districts: undefined,
      regionName: '',
      redirect: false,
      additionalServices: [ { name: 'REVERSÃO', enabled: false }, ],
      triageByDistrict: undefined,
    }

    this.refreshReportData = this.refreshReportData.bind(this)
  }


  componentDidMount() {
    this.refreshReportData()
  }


  refreshReportData() {
    const {today} = this.state
    this.setState({
      loading: true,
      lastUpdated: undefined,
    })

    const {regiao} = this.props.match.params

    const services = this.state.additionalServices
         .filter((service) => service.enabled === true)
         .map((service) => service.name)



      LogisticService.getXrayDistricts(regiao)
        .then((response) => {
          this.setState({
            regionTable: response.data.data
          })
        })


      LogisticService.getXrayHubs(regiao)
        .then((response) => {
          this.setState({
            districtTable: response.data.data
          })
        })
      // if (region == 'Nordeste'){
      //   LogisticService.getXrayNordesteDistricts()
      //     .then((response) => {
      //       this.setState({
      //         regionTable: response.data.data
      //       })
      //     })
      //   LogisticService.getXrayNordesteHubs()
      //     .then((response) => {
      //       this.setState({
      //         districtTable: response.data.data
      //       })
      //     })
      // }
      //
      //
      // if (region == 'CO'){
      //   LogisticService.getXrayCODistricts()
      //     .then((response) => {
      //       this.setState({
      //         regionTable: response.data.data
      //       })
      //     })
      //   LogisticService.getXrayCOHubs()
      //     .then((response) => {
      //       this.setState({
      //         districtTable: response.data.data
      //       })
      //     })
      // }
      //
      // if (region == 'Rio'){
      //   LogisticService.getXrayRioMinasDistricts()
      //     .then((response) => {
      //       this.setState({
      //         regionTable: response.data.data
      //       })
      //     })
      //   LogisticService.getXrayRioMinasHubs()
      //     .then((response) => {
      //       this.setState({
      //         districtTable: response.data.data
      //       })
      //     })
      // }
      //
      // if (region == 'SP'){
      //   LogisticService.getXraySPDistricts()
      //     .then((response) => {
      //       this.setState({
      //         regionTable: response.data.data
      //       })
      //     })
      //   LogisticService.getXraySPHubs()
      //     .then((response) => {
      //       this.setState({
      //         districtTable: response.data.data
      //       })
      //     })
      // }
      //
      // if (region == 'SUL'){
      //   LogisticService.getXraySulDistricts()
      //     .then((response) => {
      //       this.setState({
      //         regionTable: response.data.data
      //       })
      //     })
      //   LogisticService.getXraySulHubs()
      //     .then((response) => {
      //       this.setState({
      //         districtTable: response.data.data
      //       })
      //     })
      // }

  }


  // Construtor da tabela principal, contendo todos os distritos e suas respectivas estatísticas
  summaryTable(){


    const results = this.state.regionTable.map(regionTable => (
      <tr>
        <td>{regionTable.distrito}</td>
        <td>{regionTable.total}</td>
        <td>{regionTable.ativado}</td>
        <td>{regionTable.relativo}%</td>
      </tr>
      ))

    return (
      <div>
        <table className={style.cadenceTable}>
          <tr>
            <th colSpan="8">Resultado da Ativação por Distrito (MTD)</th>
          </tr>
          <tr>
            <th>Distritos</th>
            <th>Clientes visitados com Raio-X</th>
            <th>Ativados</th>
            <th>% de ativação</th>
          </tr>
          {results}
        </table>
        <h1></h1>
      </div>
    )
  }

  // Renderiza a tabela principal, contendo todos os distritos e suas respectivas estatísticas
  renderSummaryTable(){

    if (this.state.regionTable !== undefined) {
      return (
        <div style={{ margin: '1em', textAlign: 'justify' }}>
        {this.summaryTable()}
        </div>
      )
    }
  }

//Tabela de distritos:

  HubsTable(){


    const results = this.state.districtTable.map(districtTable => (
      <tr>
        <td>{districtTable.polo}</td>
        <td>{districtTable.distrito}</td>
        <td>{districtTable.total}</td>
        <td>{districtTable.ativado}</td>
        <td>{districtTable.relativo}%</td>
      </tr>
      ))

    return (
      <div>
        <table className={style.cadenceTable}>
          <tr>
            <th colSpan="8">Resultado da Ativação por Polo (MTD)</th>
          </tr>
          <tr>
            <th>Polos</th>
            <th>Distrito</th>
            <th>Clientes visitados com Raio-X</th>
            <th>Ativados</th>
            <th>% de ativação</th>
          </tr>
          {results}
        </table>
        <h1></h1>
      </div>
    )
  }

  // Renderiza a tabela principal, contendo todos os distritos e suas respectivas estatísticas
  renderHubsTable(){

    if (this.state.districtTable !== undefined) {
      return (
        <div>
        {this.HubsTable()}
        </div>
      )
    }
  }





  render() {

    let buttonContent
    if (!this.state.loading) {
      buttonContent = (
        <Button onClick={this.refreshReportData}>Atualizar Relatóri</Button>
      )
    }

    let lastUpdatedText = ''
    if (this.state.lastUpdated) {
      lastUpdatedText = ` - Atualizado em ${this.state.lastUpdated.format('DD/MM HH:mm:ss')}`
    }

    return (<div className={style.app_container}>
    <h1 className={style.loadingMessage}>Regional{lastUpdatedText} {buttonContent}</h1>
      <div id="home" className={style.app_container}>
        <div id="current_prospect" className={style.reportSection}>

        <div style={{ margin: '1em', textAlign: 'justify' }}>
        </div>
        {this.renderSummaryTable()}

        <div style={{ margin: '1em', textAlign: 'justify' }}>
        </div>
        {this.renderHubsTable()}


        </div>
      </div>
    </div>)
  }



}

XrayRegion.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      regionid: PropTypes.string,
    }),
  }).isRequired,
}

export default XrayRegion
