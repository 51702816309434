import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import MaterialTable from "material-table";
import LogisticService from "../../../core/services/LogisticService";

const defaultTableLocalization = {
  toolbar: {
    searchTooltip: "Pesquisar",
    searchPlaceholder: "Pesquisar",
    exportName: "Baixar CSV",
    exportTitle: "Download",
  },
  pagination: {
    labelRowsSelect: "linhas",
    labelDisplayedRows: "{from}-{to} de {count}",
    previousAriaLabel: "Página anterior",
    previousTooltip: "Página anterior",
    nextAriaLabel: "Próxima página",
    nextTooltip: "Próxima página",
    lastAriaLabel: "Última página",
    lastTooltip: "Última página",
    firstAriaLabel: "Primeira página",
    firstTooltip: "Primeira página",
  },
  body: {
    emptyDataSourceMessage: "Nada para mostrar",
  },
};

const defaultTableOptions = {
  exportAllData: true,
  pageSize: 10,
  showTitle: false,
  padding: "dense",
  exportButton: true,
  maxBodyHeight: 500,
  filtering: true,
};

const AbasDeDetalhamento = (props) => {
  const { TabPane } = Tabs;
  const { angel, polo, ano, mes, resultado, distrito } = props;

  const [tabOneContent, setTabOneContent] = useState(props.resultado);
  const [tabTwoContent, setTabTwoContent] = useState(undefined);
  const [tabThreeContent, setTabThreeContent] = useState(undefined);
  const [tabFourContent, setTabFourContent] = useState(undefined);
  const [tabFiveContent, setTabFiveContent] = useState(undefined);
  const [tabSixContent, setTabSixContent] = useState(undefined);
  const [tabSevenContent, setTabSevenContent] = useState(undefined);

  useEffect(() => {
    setTabOneContent(props.resultado);
  }, [props]);

  function formatDate(date, hours) {
    var datePart = date.slice(0, 10);

    if (hours === undefined) {
      return (
        datePart.split("-")[2] +
        "/" +
        datePart.split("-")[1] +
        "/" +
        datePart.split("-")[0]
      );
    }

    return (
      datePart.split("-")[2] +
      "/" +
      datePart.split("-")[1] +
      "/" +
      datePart.split("-")[0] +
      " " +
      date.slice(11)
    );
  }

  function callback(key) {
    if (key === "1" && tabOneContent === undefined) {
      setTabOneContent([]);
    }
    if (key === "2" && tabTwoContent === undefined) {
      LogisticService.getAngelOrdersData(ano, mes, angel, polo).then(
        (response) => {
          setTabTwoContent(
            response.data.data.map((order) => ({
              orderNumber: order.orderNumber,
              city: order.city,
              customer: order.customer,
              customerName: order.customerName,
              technicianName: order.technicianName,
              considerInSlaAccount: order.considerInSlaAccount,
              considerInTmaAccount: order.considerInTmaAccount,
              solvedWithSlaOnTime: order.solvedWithSlaOnTime,
              solvedWithSlaCustomerOnTime: order.solvedWithSlaCustomerOnTime,
              considerInDzeroAccount: order.considerInDzeroAccount,
              solvedInDzero: order.solvedInDzero,
              solvedWithAnticipation: order.solvedWithAnticipation,
              redirectPolo: order.redirectPolo,
              serviceGroup: order.serviceGroup,
              status: order.status,
              cancellationReason: order.cancellationReason,
              openingDate: formatDate(order.openingDate, true),
              arrivalDate: formatDate(order.arrivalDate, true),
              deadlineDate: formatDate(order.deadlineDate, true),
              customerDeadlineDate: formatDate(
                order.customerDeadlineDate,
                true
              ),
              visitReason1: order.visitReason1,
            }))
          );
        }
      );
    }
    if (key === "3" && tabThreeContent === undefined) {
      LogisticService.getAngelCSATData(ano, mes, angel).then((response) => {
        setTabThreeContent(
          response.data.data.map((os) => ({
            angel: os.angel,
            hub: os.hub,
            district: os.district,
            region: os.region,
            satisfaction: os.satisfaction,
          }))
        );
      });
    }

    if (key === "4" && tabFourContent === undefined) {
      LogisticService.getAngelXrayData(ano, mes, distrito).then((response) => {
        setTabFourContent(
          response.data.data.map((os) => {
            var pendencies = os.pendencies ? "Sim" : "Não";
            var abono = os.abono ? "Sim" : "Não";

            var openingDate =
              os.openingDate.split("-")[2] +
              "/" +
              os.openingDate.split("-")[1] +
              "/" +
              os.openingDate.split("-")[0];
            var firstAccess;

            firstAccess =
              os.openingDate == null
                ? "-"
                : os.openingDate.split("-")[2] +
                  "/" +
                  os.openingDate.split("-")[1] +
                  "/" +
                  os.openingDate.split("-")[0];

            return {
              xrayOrderNumber: os.xrayOrderNumber,
              customer: os.customer,
              openingDate: openingDate,
              firstAccess: firstAccess,
              pendencies: pendencies,
              abono: abono,
              angel: os.angel,
              hub: os.hub,
              district: os.district,
            };
          })
        );
      });
    }

    if (key === "5" && tabFiveContent === undefined) {
      LogisticService.getDistrictPenaltiesData(ano, mes, distrito).then(
        (response) => {
          setTabFiveContent(
            response.data.data.map((os) => {
              var date =
                os.arrivalDate.split("-")[2] +
                "/" +
                os.arrivalDate.split("-")[1] +
                "/" +
                os.arrivalDate.split("-")[0];
              return {
                driver: os.driver,
                district: os.districtName,
                arrivalDate: date,
                severity: os.severity,
              };
            })
          );
        }
      );
    }

    if (key === "6" && tabSixContent === undefined) {
      LogisticService.getDistrictAccidentsData(ano, mes, distrito).then(
        (response) => {
          setTabSixContent(
            response.data.data.map((os) => {
              var date =
                os.date.split("-")[2] +
                "/" +
                os.date.split("-")[1] +
                "/" +
                os.date.split("-")[0];

              return {
                type: os.type,
                date: date,
                angel: os.angel,
                district: os.district,
              };
            })
          );
        }
      );
    }
  }

  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Resumo" key="1">
        <div style={{ padding: "0px 100px" }}>
          <MaterialTable
            columns={[
              { title: "Pilar", field: "name" },
              { title: "Atingimento", field: "score" },
              { title: "Limite Máximo", field: "limit" },
            ]}
            options={{
              showTitle: false,
              search: false,
              paging: false,
              toolbar: false,
            }}
            data={
              !tabOneContent
                ? []
                : Object.values(tabOneContent).map((p) => {
                    return {
                      name: p.nome,
                      score: p.atingimento + "%", //resultado[p],
                      limit:
                        p.indicadores
                          .filter((i) => i.tipo !== "alavanca")
                          .map((i) => parseFloat(i.peso))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(1) + "%",
                      indicadores: p.indicadores,
                    };
                  })
            }
            detailPanel={(rowData) => {
              return (
                <div style={{ padding: "30px 0px" }}>
                  <MaterialTable
                    columns={[
                      {
                        title: "Indicador",
                        field: "indicador",
                      },
                      {
                        title: "Resultado",
                        field: "resultado",
                      },
                      {
                        title: "Atingimento Pós-Régua",
                        field: "atingimentoRegua",
                      },
                      {
                        title: "Peso",
                        field: "peso",
                      },
                      {
                        title: "Impacto no Pilar",
                        field: "contribuicaoTotal",
                      },
                    ]}
                    data={rowData.indicadores.map((indicador) => ({
                      indicador: indicador.nome,
                      resultado: indicador.resultado,
                      atingimentoRegua: indicador.atingimento + "%",
                      peso:
                        indicador.tipo === "alavanca"
                          ? "Bônus"
                          : indicador.peso + "%",
                      contribuicaoTotal:
                        indicador.tipo === "alavanca"
                          ? indicador.atingimento + "%"
                          : (indicador.atingimento / 100) * indicador.peso +
                            "%",
                    }))}
                    localization={defaultTableLocalization}
                    options={{
                      ...defaultTableOptions,
                      filtering: false,
                      pageSize: 5,
                      exportButton: false,
                      search: false,
                      paging: false,
                      toolbar: false,
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
      </TabPane>
      <TabPane tab="Chamados Atendidos" key="2">
        <MaterialTable
          columns={[
            {
              title: "Número da OS",
              field: "orderNumber",
              type: "numeric",
            },
            {
              title: "Stonecode",
              field: "customer",
            },
            {
              title: "Nome do Cliente",
              field: "customerName",
            },
            {
              title: "Cidade",
              field: "city",
            },
            {
              title: "Angel",
              field: "technicianName",
            },
            {
              title: "Conta pro SLA",
              field: "considerInSlaAccount",
              type: "boolean",
            },
            {
              title: "Conta pro TMA",
              field: "considerInTmaAccount",
              type: "boolean",
            },
            {
              title: "SLA no Prazo",
              field: "solvedWithSlaOnTime",
              type: "boolean",
            },
            {
              title: "SLA Cliente no Prazo",
              field: "solvedWithSlaCustomerOnTime",
              type: "boolean",
            },
            {
              title: "Conta pro D0",
              field: "considerInDzeroAccount",
              type: "boolean",
            },
            {
              title: "Resolvida em D0",
              field: "solvedInDzero",
              type: "boolean",
            },
            {
              title: "Chamado Antecipado",
              field: "solvedWithAnticipation",
              type: "boolean",
            },
            {
              title: "Grupo de Serviço",
              field: "serviceGroup",
            },
            {
              title: "Status",
              field: "status",
            },
            {
              title: "Motivo da Primeira Visita",
              field: "visitReason1",
            },
            {
              title: "Motivo de Cancelamento",
              field: "cancellationReason",
            },
            {
              title: "Data de Abertura",
              field: "openingDate",
            },
            {
              title: "Data de Atendimento",
              field: "arrivalDate",
            },
            {
              title: "Data Limite",
              field: "deadlineDate",
            },
            {
              title: "Data Limite Cliente",
              field: "customerDeadlineDate",
            },
            {
              title: "Redirect",
              field: "redirectPolo",
              type: "boolean",
            },
          ]}
          data={tabTwoContent}
          title="Chamados Atendidos"
          localization={defaultTableLocalization}
          isLoading={!tabTwoContent}
          options={defaultTableOptions}
        />
      </TabPane>
      <TabPane tab="CSAT" key="3">
        <MaterialTable
          columns={[
            {
              title: "Angel",
              field: "angel",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Polo",
              field: "hub",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Regional",
              field: "region",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Satisfação",
              field: "satisfaction",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabThreeContent}
          isLoading={!tabThreeContent}
          title="CSAT"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
      <TabPane tab="Raio-X" key="4">
        <MaterialTable
          columns={[
            {
              title: "Número da OS",
              field: "xrayOrderNumber",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Stonecode",
              field: "customer",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Data de Instalação",
              field: "openingDate",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Primeiro Acesso",
              field: "firstAccess",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Pendencias",
              field: "pendencies",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Abono",
              field: "abono",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Green Angel",
              field: "angel",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Polo",
              field: "hub",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabFourContent}
          isLoading={!tabFourContent}
          title="Raio-X"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
      <TabPane tab="Multas" key="5">
        <MaterialTable
          columns={[
            {
              title: "Motorista",
              field: "driver",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Data de Recimento",
              field: "arrivalDate",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Gravidade",
              field: "severity",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabFiveContent}
          isLoading={!tabFiveContent}
          title="Multas"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
      <TabPane tab="Acidentes" key="6">
        <MaterialTable
          columns={[
            {
              title: "Tipo",
              field: "type",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Data",
              field: "date",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Angel",
              field: "angel",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabSixContent}
          isLoading={!tabSixContent}
          title="Acidentes"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
    </Tabs>
  );
};

export default AbasDeDetalhamento;
