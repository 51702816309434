import React from 'react';
// import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {Button} from 'antd'


export default function DownloadButton(props) {

  return (
    <div style={{width: '10em'}}>
      <Button 
        color="default"
        onClick={props.onClick}
        variant="contained"
        >
        Download  <CloudDownloadIcon style={{marginLeft: '1em'}} />
      </Button>
      </div>
  );
}
