/* eslint-disable */
import React, { Component } from "react";
import LogisticService from "../../../core/services/LogisticService";
import SortingTable from "../../../custom-components/SortingTable";

const columnsRedirect = [
  {
    title: "Polo",
    dataIndex: "provider",
    sorter: (a, b) => (a.provider < b.provider ? -1 : 1),
  },
  {
    title: "Total",
    dataIndex: "total",
    sorter: (a, b) => a.total - b.total,
  },
  {
    title: "Redirects",
    dataIndex: "redirectPercentualView",
    sorter: (a, b) => a.redirectPercentual - b.redirectPercentual,
  },
];

export class Redirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      district: props.district,
      date: props.date,
      redirect: {
        loaded: false,
      },
      graph: undefined,
      loading: false,
      lastUpdated: undefined,
    };
  }

  componentDidMount() {
    LogisticService.getLmpAttendance(
      "Redirect",
      this.state.district,
      null,
      `${this.state.date}-01`,
      `${this.state.date}-01`
    ).then((response) => {
      response.data.data.forEach((item) => {
        item.redirectPercentual = ((item.redirect * 100) / item.total).toFixed(2);
        item.redirectPercentualView = `${((item.redirect * 100) / item.total).toFixed(1)}%`;
      });
      this.setState({
        redirectsData: response.data.data,
        loaded: true,
      });
    });
  }

  render() {
    return (
      <SortingTable
        title="Redirect"
        columns={columnsRedirect}
        data={this.state.redirectsData}
      />
    );
  }
}

export default Redirect;
