/* eslint-disable */
import React, { Component } from 'react'
import moment from 'moment'
import style from './style.css'
import { withStyles } from '@material-ui/core/styles';
import CustomizedExpansionPannel from '../../custom-components/CustomizedExpansionPanel/index'
import Graph from '../../components/Logistic/graphic/graph'
import Modal from 'react-responsive-modal';
import LoadingSpinner from '../../components/LoadingSpinner'
import RefreshButton from '../../custom-components/RefreshButton'
import LogisticService from '../../core/services/LogisticService'
import { Container, Col, Row, Form } from 'react-bootstrap'
import TableCell from '@material-ui/core/TableCell';
import { Button } from '@material-ui/core';


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#37B766',
    color: 'white',
    fontSize: '0.9em',
    fontFamily: 'GoBold'
  },
  body: {
    fontSize: '0.8em',
    fontFamily: 'GoBold'
  },
}))(TableCell);


class LabCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stonecode: '',
      loading: false,
      lastUpdated: undefined,
      goalMTDPaxPaytec: 21000,
      goalMTDPaytecPax: 20517,
      configModal: false,
      omsClosed: {
        graph: undefined,
        loaded: false
      },
      gprsBadCD: {
        graph: undefined,
        loaded: false
      },
      mtdPax: {
        graph: undefined,
        loaded: false
      },
      receivedPOS: {
        graph: undefined,
        loaded: false
      },
      sentPOS: {
        graph: undefined,
        loaded: false
      },
      labExtPOS: {
        graph: undefined,
        loaded: false
      },
      monthLabCycle: {
        graph: undefined,
        loaded: false
      },
      gprsBadCDPaytec: {
        graph: undefined,
        loaded: false
      },
      gprsBadCDStoneBox: {
        graph: undefined,
        loaded: false
      },
      gprsBadCDBestlog: {
        graph: undefined,
        loaded: false
      },
    };


    this.refreshReportData = this.refreshReportData.bind(this)
  }

  refreshReportData() {
    const {today} = this.state
    this.setState({
      // loading: true,
      lastUpdated: today
    })

    LogisticService.getScreeningCycleResumeMonth().then((response) => {
      this.setState({
          monthResume: response.data.data
      })

      this.buildMonthLabCycle(this.state.monthResume)
        
      LogisticService.getLabCycleOMsClosed().then((response) => {
        this.setState({
          omsClosed: response.data.data
        })
        this.buildLabCycleOMsClosed(this.state.omsClosed)
    })
  })

    LogisticService.getLabCycleGPRSBadCD().then((response) => {
        this.setState({
        gprsBadCDResponse: response.data.data
        })
        this.buildLabCycleBadCD(this.state.gprsBadCDResponse)
        // this.buildLabCycleGPRSBadCDPaytec(this.state.gprsBadCDResponse)
        // this.buildLabCycleGPRSBadCDBox(this.state.gprsBadCDResponse)
        // this.buildLabCycleGPRSBadCDBestlog(this.state.gprsBadCDResponse)
    })

    LogisticService.getLabCycleMTDPax().then((response) => {
        LogisticService.getUsefulDays().then((holidaysResponse) => {
          this.setState({
          mtdPax: response.data.data,
          holidays: holidaysResponse.data.data
          })
          this.buildLabCycleMTDPax(this.state.mtdPax,this.state.holidays)
        })
    })

    LogisticService.getLabCycleReceivedPOS().then((response) => {
        this.setState({
        receivedPOS: response.data.data
        })
        this.buildLabCycleReceivedPOS(this.state.receivedPOS)
    })

    LogisticService.getLabCycleSentPOS().then((response) => {
        this.setState({
        sentPOS: response.data.data
        })
        this.buildLabCycleSentPOS(this.state.sentPOS)
    })

    LogisticService.getLabCycleLabExtPOS().then((response) => {
        this.setState({
        labExtPOS: response.data.data
        })
        this.buildLabCycleLabExtPOS(this.state.labExtPOS)
    })
  }

  componentDidMount() {
    this.refreshReportData()
  }

  buildLabCycleOMsClosed(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Saldo',
            stack: 'Stack 0',
            fill: false,
            borderWidth: 2,
            data: [],
            type: 'line',
            borderColor: '#e74c3c',
            hoverBackgroundColor: '#e74c3c',
            hoverBorderColor: '#e74c3c',
            backgroundColor: '#e74c3c',
          },
          {
            label: 'OMs Fechadas de Lab',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Geração de BAD',
            stack: 'Stack 1',
            backgroundColor: '#bfbfbf',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:30
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
          fontFamily: 'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 11,
              family: 'GoBold'
            },
            color: 'black',
            anchor: 'center',
            align: 'top',
            formatter: function (value) {
              // return String((value/1000).toFixed(1))
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1))
              // }
              // else {
                return value
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }




    _.orderBy(obj, 'date').forEach((element) => {
          var total = (element.count/1000-(element.badScr/1000)).toFixed(1)
          if(total < 0 ){
            total = - total
          }
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[1].data.push((element.count/1000).toFixed(1))
          graphObj.data.datasets[2].data.push((element.badScr/1000).toFixed(1))
          graphObj.data.datasets[0].data.push(total)
        
    })



    this.setState({
      omsClosed: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderLabCycleOMsClosed() {
    if (this.state.omsClosed.loaded ) {
      return (
        <div>
          <Graph
            data={this.state.omsClosed.graph.data}
            legend={this.state.omsClosed.graph.legend}
            options={this.state.omsClosed.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

  buildLabCycleBadCD(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'BESTLOG',
            stack: 'Stack 0',
            backgroundColor: 'gray',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'PAYTEC',
            stack: 'Stack 0',
            backgroundColor: '#3f3f3f',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'STONE BOX',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'TOTAL',
            stack: 'Stack 0',
            backgroundColor: 'white',
            borderWidth: 0,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:3500
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
          fontFamily: 'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: function (graphObj) {
              if(graphObj.dataset.label === "PAYTEC")
                return 'white'
              else
                return 'black'
            },
            align: 'center',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1)) + 'k'
              // }
              // else {
              //   return parseFloat(value*100).toFixed(1)
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }

      this.state.omsTotal = 0
      this.state.bestlog   = 0
      this.state.paytec    = 0
      this.state.stoneBox  = 0
    _.orderBy(obj, 'days').forEach((element) => {
      this.state.omsTotal  +=  element.count
      this.state.bestlog   +=  element.bestlog
      this.state.paytec    +=  element.paytec  
      this.state.stoneBox  +=  element.stoneBox
    }
    )
    graphObj.data.labels.push(" ".concat('TOTAL'))
    graphObj.data.datasets[0].data.push(this.state.bestlog)
    graphObj.data.datasets[1].data.push(this.state.paytec)
    graphObj.data.datasets[2].data.push(this.state.stoneBox)
    graphObj.data.datasets[3].data.push(this.state.omsTotal)

    _.orderBy(obj, 'days').forEach((element) => {
        if(element.days == 2){
            element.label = 'Até 2'
        }
        if(element.days == 10){
            element.label = '2-10'
        }
        if(element.days == 30){
            element.label = '10-30'
        }
        if(element.days == 60){
            element.label = '30-60'
        }
        if(element.days == 90){
            element.label = '60-90'
        }
        if(element.days == 180){
            element.label = '90-180'
        }
        if(element.days == 360){
            element.label = '180-360'
        }
        if(element.days == 361){
            element.label = 'Acima de 360'
        }
        graphObj.data.labels.push(" ".concat(element.label))
        graphObj.data.datasets[0].data.push(element.bestlog )
        graphObj.data.datasets[1].data.push(element.paytec  )
        graphObj.data.datasets[2].data.push(element.stoneBox)
        graphObj.data.datasets[3].data.push(element.count)
      }
    )



    this.setState({
      gprsBadCD: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderLabCycleGPRSBadCD() {
    if (this.state.gprsBadCD.loaded ) {
      return (
        <div>
          <div>
            <Graph
              data={this.state.gprsBadCD.graph.data}
              legend={this.state.gprsBadCD.graph.legend}
              options={this.state.gprsBadCD.graph.options}
              width={this.state.isDesktop ? 400 : 310}
              height={380}
            />
          </div>
        </div>
        )
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

  buildLabCycleGPRSBadCDBestlog(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'OMs Fechadas de Lab',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:3500
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
          fontFamily: 'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1)) + 'k'
              // }
              // else {
              //   return parseFloat(value*100).toFixed(1)
              // }
            }
          },
        },
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }

this.state.omsTotalBestlog = 0

    _.orderBy(obj, 'days').forEach((element) => {
        if(element.days == 2){
            element.label = 'Até 2'
        }
        if(element.days == 10){
            element.label = '2-10'
        }
        if(element.days == 30){
            element.label = '10-30'
        }
        if(element.days == 60){
            element.label = '30-60'
        }
        if(element.days == 90){
            element.label = '60-90'
        }
        if(element.days == 180){
            element.label = '90-180'
        }
        if(element.days == 360){
            element.label = '180-360'
        }
        if(element.days == 361){
            element.label = 'Acima de 360'
        }
        this.state.omsTotalBestlog += element.bestlog
        graphObj.data.labels.push(" ".concat(element.label))
        graphObj.data.datasets[0].data.push(element.bestlog)
      }
    )



    this.setState({
      gprsBadCDBestlog: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderLabCycleGPRSBadCDBestlog() {
    if (this.state.gprsBadCDBestlog.loaded ) {
      return (
        <div>
          <div>
            <Graph
              data={this.state.gprsBadCDBestlog.graph.data}
              legend={this.state.gprsBadCDBestlog.graph.legend}
              options={this.state.gprsBadCDBestlog.graph.options}
              width={this.state.isDesktop ? 400 : 310}
              height={380}
            />
          </div>
          <div align='center'>
            <b>Total: {this.state.omsTotalBestlog} </b>
          </div>
        </div>
        )
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

  buildLabCycleGPRSBadCDBox(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'OMs Fechadas de Lab',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:3500
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
          fontFamily: 'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1)) + 'k'
              // }
              // else {
              //   return parseFloat(value*100).toFixed(1)
              // }
            }
          },
        },
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }

this.state.omsTotalStoneBox = 0

    _.orderBy(obj, 'days').forEach((element) => {
        if(element.days == 2){
            element.label = 'Até 2'
        }
        if(element.days == 10){
            element.label = '2-10'
        }
        if(element.days == 30){
            element.label = '10-30'
        }
        if(element.days == 60){
            element.label = '30-60'
        }
        if(element.days == 90){
            element.label = '60-90'
        }
        if(element.days == 180){
            element.label = '90-180'
        }
        if(element.days == 360){
            element.label = '180-360'
        }
        if(element.days == 361){
            element.label = 'Acima de 360'
        }
        this.state.omsTotalStoneBox += element.stoneBox
        graphObj.data.labels.push(" ".concat(element.label))
        graphObj.data.datasets[0].data.push(element.stoneBox)
      }
    )



    this.setState({
      gprsBadCDStoneBox: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderLabCycleGPRSBadCDBox() {
    if (this.state.gprsBadCDStoneBox.loaded ) {
      return (
        <div>
          <div>
            <Graph
              data={this.state.gprsBadCDStoneBox.graph.data}
              legend={this.state.gprsBadCDStoneBox.graph.legend}
              options={this.state.gprsBadCDStoneBox.graph.options}
              width={this.state.isDesktop ? 400 : 310}
              height={380}
            />
          </div>
          <div align='center'>
            <b>Total: {this.state.omsTotalStoneBox} </b>
          </div>
        </div>
        )
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

  buildLabCycleGPRSBadCDPaytec(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'OMs Fechadas de Lab',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:3500
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
          fontFamily:'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1)) + 'k'
              // }
              // else {
              //   return parseFloat(value*100).toFixed(1)
              // }
            }
          },
        },
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }

  this.state.omsTotalPaytec = 0

    _.orderBy(obj, 'days').forEach((element) => {
        if(element.days == 2){
            element.label = 'Até 2'
        }
        if(element.days == 10){
            element.label = '2-10'
        }
        if(element.days == 30){
            element.label = '10-30'
        }
        if(element.days == 60){
            element.label = '30-60'
        }
        if(element.days == 90){
            element.label = '60-90'
        }
        if(element.days == 180){
            element.label = '90-180'
        }
        if(element.days == 360){
            element.label = '180-360'
        }
        if(element.days == 361){
            element.label = 'Acima de 360'
        }
        this.state.omsTotalPaytec += element.paytec
        graphObj.data.labels.push(" ".concat(element.label))
        graphObj.data.datasets[0].data.push(element.paytec)
      }
    )



    this.setState({
      gprsBadCDPaytec: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderLabCycleGPRSBadCDPaytec() {
    if (this.state.gprsBadCDPaytec.loaded ) {
      return (
        <div>
          <div>
            <Graph
              data={this.state.gprsBadCDPaytec.graph.data}
              legend={this.state.gprsBadCDPaytec.graph.legend}
              options={this.state.gprsBadCDPaytec.graph.options}
              width={this.state.isDesktop ? 400 : 310}
              height={380}
            />
          </div>
          <div align='center'>
            <b>Total: {this.state.omsTotalPaytec} </b>
          </div>
        </div>
        )
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }


  buildLabCycleReceivedPOS(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Pax',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Ronin',
            stack: 'Stack 0',
            backgroundColor: '#7ef482',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Paytec',
            stack: 'Stack 0',
            backgroundColor: '#9e9e9e',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Stone BOX',
            stack: 'Stack 0',
            backgroundColor: '#000000',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:0.7
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
          fontFamily:'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'white',
            // anchor: 'end',
            // align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1)) + 'k'
              // }
              // else {
              //   return parseFloat(value*100).toFixed(1)
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }

this.state.totalReceivedPOS = 0
this.state.totalReceivedDays = 0


    _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.date))
        this.state.totalReceivedPOS += (element.pax+element.ronin+element.apsis)
        this.state.totalReceivedDays += 1
        graphObj.data.datasets[0].data.push(element.pax)
        graphObj.data.datasets[1].data.push(element.ronin)
        graphObj.data.datasets[2].data.push(element.paytec)
        graphObj.data.datasets[3].data.push(element.stonelog)
      }
    )



    this.setState({
      receivedPOS: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderLabCycleReceivedPOS() {
    if (this.state.receivedPOS.loaded ) {
      return (
        <div>
        <div>
          <Graph
            data={this.state.receivedPOS.graph.data}
            legend={this.state.receivedPOS.graph.legend}
            options={this.state.receivedPOS.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>
        <div align='center' style={{'fontFamily': 'GoBold'}}>
          <b>Média: {(this.state.totalReceivedPOS/this.state.totalReceivedDays).toFixed(1)} </b>
        </div>
      </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

  buildLabCycleSentPOS(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Pax',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Ronin',
            stack: 'Stack 0',
            backgroundColor: '#7ef482',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Paytec',
            stack: 'Stack 0',
            backgroundColor: '#9e9e9e',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'Stone BOX',
            stack: 'Stack 0',
            backgroundColor: '#000000',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:0.7
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
          fontFamily:'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'white',
            // anchor: 'end',
            // align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1)) + 'k'
              // }
              // else {
              //   return parseFloat(value*100).toFixed(1)
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }

this.state.totalSentPOS=0
this.state.totalDays=0


    _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.date))
        this.state.totalSentPOS += (element.pax+element.ronin+element.apsis)
        this.state.totalDays += 1
        graphObj.data.datasets[0].data.push(element.pax)
        graphObj.data.datasets[1].data.push(element.ronin)
        graphObj.data.datasets[2].data.push(element.paytec)
        graphObj.data.datasets[3].data.push(element.stonelog)
      }
    )



    this.setState({
      sentPOS: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderLabCycleSentPOS() {
    if (this.state.sentPOS.loaded ) {
      return (
        <div>
        <div>
          <Graph
            data={this.state.sentPOS.graph.data}
            legend={this.state.sentPOS.graph.legend}
            options={this.state.sentPOS.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>
        <div align='center' style={{'fontFamily': 'GoBold'}}>
          <b>Média: {(this.state.totalSentPOS/this.state.totalDays).toFixed(1)} </b>
        </div>
      </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

  buildLabCycleLabExtPOS(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'S920',
            stack: 'Stack 0',
            backgroundColor: '#00cc6d',
            borderWidth: 2,
            data: [],
          },
          {
            label: 'D210N',
            stack: 'Stack 0',
            backgroundColor: '#bfbfbf',
            borderWidth: 2,
            data: [],
          },
          {
            label: '',
            stack: 'Stack 0',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            hoverBackgroundColor: 'transparent',
            hoverBorderColor: 'transparent',
            borderWidth: 2,
            data: [],
            type: 'line',
            fill: false
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              suggestedMax:0.7
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: false,
          fontColor: 'black',
          fontSize: 20,
          text: '',
          fontFamily:'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              family: 'GoBold'
            },
            color: 'black',
            // anchor: 'end',
            // align: 'top',
            formatter: function (value) {
              return value
              // if (value >= 1000) {
              //   return String((value/1000).toFixed(1)) + 'k'
              // }
              // else {
              //   return parseFloat(value*100).toFixed(1)
              // }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }


    this.state.total = 0

    _.orderBy(obj, 'days').forEach((element) => {
      this.state.total += element.s920 + element.d210n
      if(element.days == 'total'){
        element.label = 'Total'
    }
      if(element.days == 10){
          element.label = 'Até 10'
      }
      if(element.days == 20){
          element.label = '10-30'
      }
      if(element.days == 30){
          element.label = '20-30'
      }
      if(element.days == 60){
          element.label = '30-60'
      }
      if(element.days == 90){
          element.label = '60-90'
      }
      if(element.days == 180){
          element.label = '90-180'
      }
      if(element.days == 360){
          element.label = '180-360'
      }
      if(element.days == 361){
          element.label = 'Acima de 360'
      }
        graphObj.data.labels.push(" ".concat(element.label))
        graphObj.data.datasets[0].data.push(element.s920)
        graphObj.data.datasets[1].data.push(element.d210n)
        graphObj.data.datasets[2].data.push(element.d210n+element.s920)
      }
    )



    this.setState({
      labExtPOS: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderLabCycleLabExtPOS() {
    if (this.state.labExtPOS.loaded ) {
      return (
        <div>
        <div>
          <Graph
            data={this.state.labExtPOS.graph.data}
            legend={this.state.labExtPOS.graph.legend}
            options={this.state.labExtPOS.graph.options}
            width={this.state.isDesktop ? 400 : 310}
            height={380}
          />
        </div>
        <div align='center'>
          <b>Total: {this.state.total} </b>
        </div>
      </div>)
    }

    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
  }

buildLabCycleMTDPax(obj,holidayObj) {
  const graphObj =
  {
    data: {
      labels: [],
      datasets: [
        {
          label: 'Real Envio PAX',
          stack: 'Stack 0',
          backgroundColor: '#00cc6d',
          borderWidth: 2,
          data: [],
        },
        {
          label: 'MTD Envio PAX',
          stack: 'Stack 0',
          borderWidth: 2,
          data: [],
          // type: 'line',
          // yAxisID: 'B',
          fill: false,
          display:false,
        },
        {
          label: 'Real Recebimento PAX',
          stack: 'Stack 1',
          backgroundColor: '#00cc6d',
          borderWidth: 2,
          data: [],
        },
        {
          label: 'MTD Recebimento PAX',
          stack: 'Stack 1',
          borderWidth: 2,
          data: [],
          // type: 'line',
          // yAxisID: 'B',
          fill: false,
          display:false,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          categoryPercentage: 0.9,
          gridLines: {
            display: false,
          },
        }],
        yAxes: [{
          stacked: false,
          gridLines: {
            display: false,
          },
          ticks: {
            min: 0,
            suggestedMax:25
          },
        }],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        datalabels: {
          display: function (graphObj) {
            return graphObj.dataset.data[graphObj.dataIndex] > 0
          },
          font: {
            size: 12,
            family: 'GoBold'
          },
          color: 'black',
          anchor: 'end',
          align: 'top',
          formatter: function (value) {
            return value
            // if (value >= 1000) {
            //   return String((value/1000).toFixed(1)) + 'k'
            // }
            // else {
            //   return parseFloat(value*100).toFixed(1)
            // }
          }
        },
      },
      title:{
          display: true,
          text:'ENVIO PAX                                               RECEBIMENTO PAX',
          position: 'top',
          fontSize: 20,
          color: 'black',
          fontFamily: 'GoBold'
      },
    },
    
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'black',
        fontFamily: 'GoBold'
      },
    },
  }

  _.orderBy(obj, 'days').forEach((element) => {
      var date = new Date();
      if(date.getMonth()+1<10){
        var d = ''.concat(date.getFullYear()).concat('-0').concat(date.getMonth()+1)
      }
      else{
        var d = ''.concat(date.getFullYear()).concat('-').concat(date.getMonth()+1)
      }
      var useful = ''
      var mtdUseful = ''
      holidayObj.forEach((holiday) => {
        if(holiday.month == d){
          useful = holiday.useful
          mtdUseful = holiday.mtdUseful
        }
      })
      this.state.paxPercent = (element.paxPaytex*100/(mtdUseful*(this.state.goalMTDPaxPaytec/useful))).toFixed(2)
      this.state.paytecPercent = (element.paytecPax*100/(mtdUseful*(this.state.goalMTDPaytecPax/useful))).toFixed(2)
      graphObj.data.labels.push(" ")
      graphObj.data.datasets[0].data.push((element.paxPaytex/1000).toFixed(2))
      graphObj.data.datasets[1].data.push((((element.paxPaytex/(1000*this.state.paxPercent))*100)).toFixed(2))
      graphObj.data.datasets[2].data.push((element.paytecPax/1000).toFixed(2))
      graphObj.data.datasets[3].data.push((((element.paytecPax/(1000*this.state.paytecPercent))*100)).toFixed(2))
    }
  )



  this.setState({
    mtdPax: {
      graph: graphObj,
      loaded: true
    },
    lastUpdated: moment(),
  })
}

//Renderiza o gráfico de status de OS por data de abertura
renderLabCycleMTDPax() {
  if (this.state.mtdPax.loaded ) {
    return (
      <div>
      <Row>
        <Col md lg xl = {10} />
        <Col md lg xl = {2} style={{fontFamily: 'GoBold'}}>
            <Button onClick={e => this.setState({configModal:true})} style={{ backgroundColor: '#bdecc8'}}>Alterar Combinado</Button>
          </Col>
      </Row>
      <Row>
        <Col md lg xl = {7}>
        <Graph
          data={this.state.mtdPax.graph.data}
          legend={this.state.mtdPax.graph.legend}
          options={this.state.mtdPax.graph.options}
          width={this.state.isDesktop ? 400 : 310}
          height={380}
        />
        </Col>
        <Col md lg xl = {4} style={{marginTop:'10%', fontFamily: 'GoBold'}}>
        <strong>ENVIO PAX(%):</strong> {this.state.paxPercent}<br/>
        <strong>RECEBIMENTO PAX(%):</strong> {this.state.paytecPercent}
        </Col>
      </Row>
      </div>)
  }

  return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
    <LoadingSpinner />
  </div>)
}


buildMonthLabCycle(obj) {
  const graphObj =
  {
    data: {
      labels: [],
      datasets: [
        {
          label: 'Ciclo Lab (%)',
          stack: 'Stack 0',
          backgroundColor: '#2caf1e',
          borderWidth: 2,
          data: [],
          type: 'line',
          fill: false,
          yAxisID: 'B',
          borderColor:'#2caf1e'
        },
        {
          label: 'Meta',
          stack: 'Stack 0',
          backgroundColor: '#C8CFCC',
          borderWidth: 2,
          data: [],
          type: 'line',
          fill: false,
          yAxisID: 'B',
          borderColor:'#C8CFCC'
        },
        {
          label: 'Ciclo Lab',
          stack: 'Stack 0',
          backgroundColor: '#00cc6d',
          borderWidth: 2,
          data: [],
          yAxisID: 'A',
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          categoryPercentage: 0.9,
          gridLines: {
            display: false,
          },
        }],
        yAxes: [{
          id: 'A',
          stacked: false,
          gridLines: {
            display: false,
          },
          position: 'left',
          ticks: {
            min: 0,
            suggestedMax: 50
          }
        },
        {
          id: 'B',
          // stacked: false,
          gridLines: {
            display: false,
          },
          position: 'right',
          ticks: {
            min: 0,
            suggestedMax: 6
          }
        }],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: false,
        fontColor: 'black',
        fontSize: 20,
        text: '',
        fontFamily: 'GoBold'
      },
      plugins: {
        datalabels: {
          display: function (graphObj) {
            return graphObj.dataset.data[graphObj.dataIndex] > 0
          },
          font: {
            size: 12,
            family: 'GoBold'
          },
          color: 'black',
          anchor: 'start',
          align: 'top',
          formatter: function (value) {
            // return String((value/1000).toFixed(1))
            // if (value >= 1000) {
            //   return String((value/1000).toFixed(1))
            // }
            // else {
              return value
            // }
          }
        },
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'black',
        fontFamily: 'GoBold'
      },
    },
  }

this.state.goals = {
  '2019-07': 1.8,
  '2019-08': 3.0,
  '2019-09': 2.7,
  '2019-10': 2.3,
  '2019-11': 2.2,
  '2019-12': 2.2,
  '2020-01': 4.0,
  '2020-02': 4.1,
  '2020-03': 3.5,
  '2020-04': 3.4,
  '2020-05': 2.8,
  '2020-06': 2.3,
  '2020-07': 1.8,
  '2020-08': 1.6,
  '2020-09': 1.4,
  '2020-10': 1.5,
  '2020-11': 1.7,
  '2020-12': 1.8,
}
  _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.date))        
        graphObj.data.datasets[0].data.push(element.gprsWifiStock === null?0:(element.totalLabCycle*100/element.gprsWifiStock).toFixed(1))
        graphObj.data.datasets[1].data.push(this.state.goals[element.date])
        graphObj.data.datasets[2].data.push((element.totalLabCycle/1000).toFixed(1))
  })

  this.setState({
    monthLabCycle: {
      graph: graphObj,
      loaded: true
    },
    lastUpdated: moment(),
  })
}

//Renderiza o gráfico de status de OS por data de abertura
renderMonthLabCycle() {
  if (this.state.monthLabCycle.loaded ) {
    return (
      <div>
        <Graph
          data={this.state.monthLabCycle.graph.data}
          legend={this.state.monthLabCycle.graph.legend}
          options={this.state.monthLabCycle.graph.options}
          width={this.state.isDesktop ? 400 : 310}
          height={380}
        />
      </div>)
  }

  return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
    <LoadingSpinner />
  </div>)
}

closeModal(){
  this.setState({
    configModal: false
  });
}

saveCase(){
  var paytecPax = document.getElementById("paytecPax").value
  var paxPaytec = document.getElementById("paxPaytec").value

  console.log(paytecPax, paxPaytec)
  this.setState({
    configModal: false
  });
}

renderConfigModal(){
  return(
    <div>
      <Modal onClose={e=> this.closeModal()} showCloseIcon={false} open={open} center={true} >
        <Row>
        <Col>
          <Form.Control id="paytecPax" type="text" size="sm" placeholder="Paytec - PAX" />
          <Form.Control id="paxPaytec" type="text" size="sm" placeholder="PAX - Paytec" />
        </Col>
        <Col> 
          <Button onClick={e => this.saveCase(e)} >Salvar</Button>
          <Button onClick={e => this.closeModal(e)} >Sair</Button>
          </Col>
        </Row>
        
      </Modal>
    </div>
  )
}



  render() {

    let refreshContent
    let lastUpdatedText = ''

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format('DD/MM HH:mm')}`
    }

    if (!this.state.loading) {
      refreshContent = (
        <div style={{textAlign: 'right', marginBottom: '1em', fontFamily: 'GoBold'}}>
          <label style={{margin:'1em'}}><i>{lastUpdatedText}</i></label>
          <RefreshButton onClick={() => { this.refreshReportData() }}/>
        </div>
      )
    }

    return (
      <Container fluid>
      {refreshContent}
      { (this.state.configModal ) ? this.renderConfigModal(): ''}

      <CustomizedExpansionPannel
        title='Ciclo Lab Mensal'
        content={this.renderMonthLabCycle()}
        helpMessage={<p>O Ciclo LAB WIFI  considera as máquinas que são identificadas como BAD na triagem até sua devolução reparada pelo laboratório ao CD. A porcentagem representa este número absoluto sobre o parque ativo GPRS-WIFI.</p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='Entradas e Saídas do Ciclo'
        content={this.renderLabCycleOMsClosed()}
        helpMessage={<p>O ciclo LAB WIFI tem como entrada tudo que foi gerado de BAD e como saída as OMs fechadas de Laboratório</p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='MTD Pax'
        content={this.renderLabCycleMTDPax()}
        helpMessage={<p>Trimestralmente fechamos acordo com a Paytec de envio para laboratório e com a PAX de retorno de máquinas reparadas de laboratório. O MTD considera tudo o que deveria ter sido enviado/recebido até o dia atual, e o realizado considera o numero real do que foi enviado/recebido no mês.</p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='Recebido'
        content={this.renderLabCycleReceivedPOS()}
        helpMessage={<p>Total de POS recebidos por dia de acordo com cada Laboratório que enviamos as GPRS-WIFI. </p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='Enviado'
        content={this.renderLabCycleSentPOS()}
        helpMessage={<p>Total de POS enviados por dia de acordo com cada Laboratório que enviamos as GPRS-WIFI. </p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='Bad no CD (em dias)'
        content={this.renderLabCycleGPRSBadCD()}
        helpMessage={<p>O número de BADs no CD considera todas as máquinas de reversa que foram triadas como BAD em nossos CDs, além disso o gráfico mostra a quanto tempo elas estão neste status.</p>}
        tooltip={true}
      />
      <CustomizedExpansionPannel
        title='Terminais em Lab Ext Pax (em dias)'
        content={this.renderLabCycleLabExtPOS()}
        helpMessage={<p>Quantidade de terminais BADs no laboratório PAX para reparo.</p>}
        tooltip={true}
      />
      <div>
      </div>
      </Container>
    );
  }
}



export default LabCycle
