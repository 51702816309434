import React, { Component } from 'react'
import style from './style.css'
import LogisticService from '../../core/services/LogisticService'
import ContactsTable from '../../core/models/ContactsTable'
import { Col } from 'react-bootstrap'
import TablePaging from '../../custom-components/CustomizedTablePaging'
import  Person  from './img/person.png'

class ContactTable extends Component {

    constructor(props) {
  
        super(props)
    
        this.state = {
            stonecode:props.stonecode,
            contactsData: ''
        }
    
        this.refreshReportData = this.refreshReportData.bind(this)
      }
    
    refreshReportData() {
        this.setState({
            contactsData: ''
        });

    
    
    LogisticService.getContacts(this.state.stonecode).then((contactsInfo) => {
        if(contactsInfo.data.data != false){
            const contacts = contactsInfo.data.data.map(contact => new ContactsTable(contact))
         //Create rows using data
         let dataContacts = []

         for(var i = 0; i < contacts.length;i++){
           dataContacts.push(
             [
               contacts[i].name,
               contacts[i].email,
               contacts[i].mobilePhone
             ]
           )
         }
 
         //Title
         let titleContacts = [{name:"Contatos do cliente",icon:<img src={Person} alt="Person" className="titleIconClient" />}]
 
         //Sub Titles
         let subTitleContacts = [{name:"Nome", icon:""},{name:"Email", icon:""},{name:"Telefone", icon:""}]
 
         const contactsData =
           <TablePaging
             data ={dataContacts}
             title ={titleContacts}
             subTitle ={subTitleContacts}
             colSpan ={"3"}
             totalPages = {(contacts.length / 3) + (contacts.length % 3 == 0 ? 0 : 1)}
             rowSpan = {3}
             >
           </TablePaging>
 
 
         this.setState({
           contactsData: contactsData
         })
 
        }

    })
    
    }

    componentDidMount() {
        this.refreshReportData()
    }

    render(){
        return(
        <Col   className="customizedTable right" xs sm md = "12" lg xl = "5" align="right" style={{marginTop: '5px'}}>
          {this.state.contactsData}
        </Col> 
        )
    }

      
}

export default ContactTable