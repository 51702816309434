/* eslint-disable */
import React, { Component } from 'react'

import LogisticService from '../../../core/services/LogisticService'
import style from '../style.css'
import Graph from '../../../components/Logistic/graphic/graph'
import LoadingSpinner from '../../../components/LoadingSpinner'

import Box from '@material-ui/core/Box';

class Sla extends Component {
constructor(props) {
    super(props);
    this.state = {
        district: props.district,
        date: props.date,
        sla:{
        loaded:false
        },
        graph:{
          loaded:false
        },
        mtd:{
          loaded:false
        },
        loading: false,
        lastUpdated: undefined,
    };
    this.refreshReportData = this.refreshReportData.bind(this)
}
refreshReportData() {
  LogisticService.getLmpAttendance('SLA',this.state.district,null,`${this.state.date}-01`,`${this.state.date}-01`).then((mtd)=>{   
    LogisticService.getLmpAttendance('SLA',this.state.district,'day',`${this.state.date}-01`,`${this.state.date}-01`).then((response)=>{
        this.buildSlaTimeGraph(response.data.data, mtd.data.data)
      })
    })
  }

  componentDidMount() {
    this.refreshReportData()
  }

  buildSlaTimeGraph(obj,mtd) {   
    let colors = ['#13aa4d','#26c965','#3bd476','#5ae891','#00bd00','#009400','#006600','#005900','#002e00'] 
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: (tooltipItem, data) => {
              if ([0, 1, 2].includes(tooltipItem.datasetIndex)) {
                return `${tooltipItem.yLabel.toFixed(1)}%`;
              }
              return `${tooltipItem.yLabel.toFixed(1)}%`;
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              display:false,
              ticks: {
                callback: function (value) {
                  return value + "%"; // convert to percentage
                },
              },
            },
          ],
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 12,
          fontFamily: 'Gobold',
          text: 'SLA Diário',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
              // if(graphObj.dataset.label === 'Meta')
              //   return false
              // else
              //   return graphObj.dataset.data[graphObj.dataIndex] > 0
              return false
            },
            font: {
              size: 12,
              weight: "bold",
              family: 'Gobold'
            },
            position: 'center',
            align: function (graphObj) {
                return 'center'
            },
            color:'black',
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }
    let i = 0
    obj.forEach((item)=>{
     if(graphObj.data.datasets.filter(content => content.label === item.provider.slice(0,-16)).length > 0 && graphObj.data.datasets.length > 0){}
     else{
        graphObj.data.datasets.push(
          {
            label: item.provider.slice(0,-16),
            stack: 'Stack 0',
            type: 'line',
            borderColor: colors[i],
            backgroundColor: 'white',
            pointRadius : 0,
            // showLine:false,
            data: [],
            fill: false,
            display:false,
            
          }
        )
        i++
      }
    }),
    graphObj.data.datasets.push(
    {
      label: 'Meta',
      stack: 'Stack 0',
      type: 'line',
      borderColor: 'grey',
      backgroundColor: 'white',
      pointRadius : 0,
      // showLine:false,
      borderDash: [1,1],
      data: [],
      fill: false,
      display:false,
      
    },
    {
      label: 'MTD Distrito',
      stack: 'Stack 0',
      type: 'line',
      borderColor: '#0b705d',
      backgroundColor: 'white',
      pointRadius : 0,
      // showLine:false,
      borderDash: [1,1],
      data: [],
      fill: false,
      display:false,
      
    },
    {
      label: 'MTD Regional',
      stack: 'Stack 0',
      type: 'line',
      borderColor: 'yellow',
      backgroundColor: 'white',
      pointRadius : 0,
      // showLine:false,
      borderDash: [1,1],
      data: [],
      fill: false,
      display:false,
      
    })
    let mtdOnTime = 0
    let mtdTotal  = 0

    obj.forEach((item)=>{
        mtdOnTime += item.onTimeQty
        mtdTotal  += item.totalQty
    })

    _.orderBy(obj, 'date').forEach((element) => {
        if(graphObj.data.labels.includes(element.date)){
          graphObj.data.datasets.filter(item => item.label === element.provider.slice(0,-16))[0].data.push((((element.sla*1.0)/element.total)*100).toFixed(1))
        }
        else{
          graphObj.data.labels.push(element.date)
          graphObj.data.datasets.filter(item => item.label === element.provider.slice(0,-16))[0].data.push((((element.sla*1.0)/element.total)*100).toFixed(1))
          graphObj.data.datasets[graphObj.data.datasets.length - 3].data.push(98)
          graphObj.data.datasets[graphObj.data.datasets.length - 2].data.push((mtd[0].slaDistrict*100/mtd[0].totalDistrict).toFixed(1))
          graphObj.data.datasets[graphObj.data.datasets.length - 1].data.push((mtd[0].slaRegion*100/mtd[0].totalRegion).toFixed(1))
        }
      }
    )
    this.setState({
      sla:{
              graph: graphObj,
              loaded: true
        },
      })
  }

  renderContent(){
      if(this.state.sla.loaded){
        return(
              <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
                  <Graph
                  data={this.state.sla.graph.data}
                  legend={this.state.sla.graph.legend}
                  options={this.state.sla.graph.options}
                  width={this.state.isDesktop ? 400 : 310}
                  height={340}
                  />
              </Box>
          )
      }
    else{
        return(
            <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
            </div>
        )
    }
  }

  render(){
        return(this.renderContent())
    }

}
export default Sla