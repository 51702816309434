/* eslint-disable */
import React, { Component } from "react";
import style from "./style.css";
import { Container, Form, Row, Col, Card, Button } from "react-bootstrap";
import CustomizedSnackbars from "../../custom-components/SnackBars";
import GoogleAnalytics from "react-ga";

import SummaryTable from "./summaryTable";
import XavierTable from "./xavierTable";
import XrayTable from "./xrayTable";
import POSTable from "./posTable";
import ContactTable from "./contactsTable";
import LinksTable from "./linksTable";


class ClientData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stonecode: "",
      error: false,
      loaded: false,
      foundStonecode: false,
      errorMessage: "",
      modalIsOpen: false,
      equalsInformation: undefined,
      refresh: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClean = this.handleClean.bind(this);
  }

  handleChange(event) {
    this.setState({
      stonecode: event.target.value,
    });
  }

  handleClean(event) {
    this.setState({
      foundStonecode: false,
    });
  }

  handleSubmit(event) {
    let stonecode = this.state.stonecode;

    GoogleAnalytics.initialize("UA-156091472-1");
    GoogleAnalytics.event({
      category: "Stone Code Search",
      action: stonecode,
    });
    event.preventDefault();
    this.clientData();
  }

  clientData() {
    this.setState({
      error: false,
      foundStonecode: true,
      loaded: true,
    });
  }

  renderSearchBlock() {
    return (
      <Row style={{ marginTop: "3%" }}>
        <Col lg="4" />
        <Col lg="4" sm="12" xs="12">
          <Card className="text-center">
            <Card.Body>
              <p className="serchClientLabel">Digite o Stonecode</p>
              <Form>
                <Form.Control type="text" placeholder="" onChange={(e) => this.handleChange(e)} />
              </Form>
              <br />
              <Button variant="success" onClick={(e) => this.handleSubmit(e)}>
                Buscar
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4" />
      </Row>
    );
  }

  PrimaryTablesBlock() {
    return (
      <Row>
        <SummaryTable stonecode={this.state.stonecode} />
        <XavierTable stonecode={this.state.stonecode} />
        <XrayTable stonecode={this.state.stonecode} />
      </Row>
    );
  }

  SecundaryTablesBlock() {
    return (
      <Row>
        <POSTable stonecode={this.state.stonecode} />
        <ContactTable stonecode={this.state.stonecode} />
      </Row>
    );
  }

  ThirdTablesBlock() {
    return <LinksTable />;
  }

  render() {
    return (
      <Container fluid>
        {this.state.foundStonecode ? (
          <Button variant="success" onClick={(e) => this.handleClean(e)}>
            Voltar
          </Button>
        ) : (
          ""
        )}
        <h1 className="pageTitle">Dados do Cliente</h1>

        {!this.state.foundStonecode ? this.renderSearchBlock() : ""}
        <div className="w-100 p-2">{this.state.foundStonecode ? this.PrimaryTablesBlock() : ""}</div>
        <div className="w-100 p-2">{this.state.foundStonecode ? this.SecundaryTablesBlock() : ""}</div>
        <div className="w-100 p-2">{this.state.foundStonecode ? this.ThirdTablesBlock() : ""}</div>
        <CustomizedSnackbars open={this.state.error} variant="error" message={this.state.errorMessage} />
      </Container>
    );
  }
}

export default ClientData;
