/* eslint-disable */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import style from './style.css'
import LogisticService from '../../core/services/LogisticService'
import LoadingSpinner from '../../components/LoadingSpinner'
import { Container } from 'react-bootstrap'
import CustomizedExpansionPannel from '../../custom-components/CustomizedExpansionPanel/index'
import Box from '@material-ui/core/Box';
import ServiceOrdersSection from './serviceOrdersSection'
import CapexSection from './capexSection'
import HubsStatusSection from './hubStatusSection'
import GreenAngelsSection from './greenAngelsSection'
import ShareAndQualitySection from './shareAndQuality'
import SwapsAndMaintenanceSection from './swapsAndMaintenance'
import { Statistic, Icon, Col, Row, Card} from 'antd'
import { shadows } from '@material-ui/system';


const SimpleBox = props => {
  const { header, result, goal, direction, percentage } = props
  let headlightSymbol;
  let headlightColor;

  if (direction === 'normal') {
    headlightSymbol = (result > goal ? 'check-circle' :
      (result > 0.9 * goal) ?  'minus-circle' : 'close-circle')
    headlightColor = (result > goal ? '#28A745' :
    (result > 0.9 * goal) ?  'orange' : 'red')
  } else if (direction === 'reverse') {
    headlightSymbol = (result < goal ? 'check-circle' :
    (result < 1.1 * goal) ?  'minus-circle' : 'close-circle')
    headlightColor = (result < goal ? '#28A745' :
    (result < 1.1 * goal) ?  'orange' : 'red')
  }
  return (
      <Card>
        <Statistic
          title={<h3>{header}</h3>}
          value={result.toFixed(1) + (percentage ? '%' : '')}
          valueStyle={{ color: {headlightColor} }}
          prefix={<Icon type={headlightSymbol} theme="twoTone" style={{verticalAlign: 'middle'}} twoToneColor={headlightColor} />}
        />
    </Card>
    )
}



class OnePage extends Component {

  constructor () {
    super()
    this.state = {
      today: moment().format('YYYY-MM-DD'),
      loaded: false,
      lastUpdated: undefined,
      summarySection: undefined,
      summary: undefined,
      parque: undefined
    }
  }

  componentDidMount () {
    this.refreshReportData()
  }

  refreshReportData () {
    const {today} = this.state
    this.setState({

      loaded: false,
      lastUpdated: undefined,
    })

    LogisticService.getMtdSlaGrouped(today)
    .then((response) => {


        LogisticService.getAngelsRealizedOSs()
        .then((realized_angels_os_response) => {

        LogisticService.getAngelsAccidents()
        .then((accidents_angels_response) => {


        LogisticService.getXrayNewCustomers()
        .then((new_products_response) => {

        LogisticService.getLogisticMembersHistory()
        .then((responseMembers) => {
          LogisticService.getTerminalLosts()
          .then((responseLosts) => {
            LogisticService.getCSATOnePage()
            .then((responsecsat) => {
              LogisticService.mtdRavActivate()
              .then((responseRav) => {
                this.buildSummarySection(
                    response.data,
                    new_products_response.data.data[0],
                    accidents_angels_response.data.data[(accidents_angels_response.data.data).length - 1],
                    responseMembers.data.data[(responseMembers.data.data).length - 1],
                    responseLosts.data.data[(responseLosts.data.data).length - 1],
                    responsecsat.data.data[(responsecsat.data.data).length - 1],
                    responseRav.data.data
                  )
              })
            })
          })
        })
      })
    })
  })
  })

  LogisticService.getHistoryIDM()
  .then((response) => {
    this.buildIDMHistory(response.data.data)
  })


    // LogisticService.getCapexSummaryRefactor()
    // .then((response) => {
    //   this.setState({
    //     loading: false,
    //     lastUpdated: moment(),
    //     summary: response.data.data
    //   })

      LogisticService.getCapexSummaryRefactor()
      .then((response) => {
        const summary = response.data.data
        const stoneSummary = summary.filter(a => ['ELAVON BRASIL', 'STONE PAGAMENTOS'].includes(a['Contratante']))
        this.setState({summary: stoneSummary})
      })

    // })


}

    // buildSimpleBox(header, data, icon) {
    //   return (
    //     <Card title={header} style={{ width: 300 }}>
    //       <p>Card content</p>
    //       <p>Card content</p>
    //       <p>Card content</p>
    //     </Card>
    //   )
    // }

    buildSimpleBox(header, data) {
      const headlightSymbol = (data > 70 ? 'check-circle' : 'close-circle')
      const headlightColor = (data > 70 ? 'green' : 'red')
      return (
        <Card>
          <Statistic
            title={<h6>{header}</h6>}
            value={data}
            valueStyle={{ color: {headlightColor} }}
            prefix={<Icon type={headlightSymbol} theme="twoTone" style={{verticalAlign: 'middle'}} twoToneColor={headlightColor} />}
          />
      </Card>
      )
    }

    findOperator(operators, name) {
      return operators.data.find(
        (element) => {
          if (element.operator === name) {
            return true
          }
          return false
        }
      )
    }

    buildSummarySection(obj,new_products_obj,accidents_obj,members_obj,losts_obj,csat_obj,rav_obj) {

     const stonePayments = this.findOperator(obj, 'TOTAL')
     const { summary } = this.state
     const baixoGiro = summary.filter(
       a =>
         a["Posição Cadeia"] !== "INDISPONIVEL" &&
         a["Tecnologia"] !== "GPRS-WIFI"
     );
     const altoGiro = summary.filter(
       a =>
         a["Posição Cadeia"] !== "INDISPONIVEL" &&
         a["Tecnologia"] === "GPRS-WIFI"
     );

     const baixoGiroInstalado = _.sumBy(
       baixoGiro.filter(a => a["Posição Cadeia"] === "EM PRODUÇÃO"),
       function(o) {
         return o["qtd"];
       }
     );
     const baixoGiroNaoInstalado = _.sumBy(
       baixoGiro.filter(a => a["Posição Cadeia"] !== "EM PRODUÇÃO"),
       function(o) {
         return o["qtd"];
       }
     );
     const altoGiroInstalado = _.sumBy(
       altoGiro.filter(a => a["Posição Cadeia"] === "EM PRODUÇÃO"),
       function(o) {
         return o["qtd"];
       }
     );
     const altoGiroNaoInstalado = _.sumBy(
       altoGiro.filter(a => a["Posição Cadeia"] !== "EM PRODUÇÃO"),
       function(o) {
         return o["qtd"];
       }
     );

         const baixoGiroAtivo = _.sumBy(
           baixoGiro.filter(a => a["Posição"] === "CLIENTE ATIVO"),
           function(o) {
             return o["qtd"];
           }
         );
         const altoGiroAtivo = _.sumBy(
           altoGiro.filter(a => a["Posição"] === "CLIENTE ATIVO"),
           function(o) {
             return o["qtd"];
           }
         );

         const goodBaixoGiro = _.sumBy(
           baixoGiro.filter(a =>
             ["AVANÇO", "DISPONÍVEL BASE", "DISPONÍVEL CD"].includes(
               a["Posição Cadeia"]
             )
           ),
           function(o) {
             return o["qtd"];
           }
         );
         const goodAltoGiro = _.sumBy(
           altoGiro.filter(a =>
             ["AVANÇO", "DISPONÍVEL BASE", "DISPONÍVEL CD"].includes(
               a["Posição Cadeia"]
             )
           ),
           function(o) {
             return o["qtd"];
           }
         );

         const badBaixoGiro = _.sumBy(
           baixoGiro.filter(a =>
             ["BAD CD", "CICLO TRIAGEM", "CICLO LAB"].includes(a["Posição Cadeia"])
           ),
           function(o) {
             return o["qtd"];
           }
         );
         const badAltoGiro = _.sumBy(
           altoGiro.filter(a =>
             ["BAD CD", "CICLO TRIAGEM", "CICLO LAB"].includes(a["Posição Cadeia"])
           ),
           function(o) {
             return o["qtd"];
           }
         );

      const csat_result = ( 100 * csat_obj['good'] / csat_obj['total'])
      const rav_result = ( 100 * rav_obj[0]['activate'] / rav_obj[0]['total'])
      const new_products_result = ((new_products_obj.activateCollact+new_products_obj.total - new_products_obj.pendingAccess - new_products_obj.pending+new_products_obj.doublePending)*100/(new_products_obj.totalCollact+new_products_obj.total))
      const capex_efficiency_result = (
        ((altoGiroAtivo + baixoGiroAtivo) /
          (altoGiroInstalado +
            baixoGiroInstalado +
            badAltoGiro +
            goodAltoGiro +
            badBaixoGiro +
            goodBaixoGiro)) *
        100)

      const turnover_result = 100 * (members_obj.voluntaryTurnover + members_obj.involuntaryTurnover) / members_obj.activateAngels

      const result = (
        <div style={{marginLeft: '1em'}}>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
         <Col md lg xl = {12}>
           <h5><Icon type='smile' style={{verticalAlign: 'top', marginRight: '0.5em'}}/> Atendimento</h5>
         </Col>
         <Col md lg xl = {12}>
          <h5><Icon type='team' style={{verticalAlign: 'top', marginRight: '0.5em'}}/> Pessoas</h5>
         </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
          <Box boxShadow={3}>
            <Col md lg xl = {5}>
            <SimpleBox
              header='SLA Geral (MTD)'
              direction ='normal'
              result={stonePayments.mtdSla}
              goal={97}
              percentage={true}
            />
            </Col>
            <Col md lg xl = {5}>
              <SimpleBox
                header='CSAT'
                direction ='normal'
                result={csat_result}
                goal={90}
                percentage={true}
                />
            </Col>
            <Col md lg xl = {2}/>
            <Col md lg xl = {5}>
            <SimpleBox
              header='Acidentes c/ Afastamento'
              direction ='reverse'
              result={accidents_obj.accidents}
              goal={10}
            />
              </Col>
            {/* <Col md lg xl = {1}/> */}
          </Box>
          <Col md lg xl = {5}>
            <SimpleBox
              header='Turnover'
              direction ='reverse'
              result={turnover_result}
              goal={2.7}
              percentage={true}
            />
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]} style={{marginTop: '1em'}}>
          <Col md lg xl = {12}>
            <h5><Icon type='gold' style={{verticalAlign: 'top', marginRight: '0.5em'}}/> Cadeia</h5></Col>
          <Col md lg xl = {12}>
          <h5><Icon type='wifi' style={{verticalAlign: 'top', marginRight: '0.5em'}}/> Ativação</h5></Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
          <Col md lg xl = {5}>
           <SimpleBox
              header='Eficiência da Cadeia'
              direction ='normal'
              result={capex_efficiency_result}
              goal={70.2}
              percentage={true}
            />
           </Col>
         <Col md lg xl = {5}>
         <SimpleBox
              header='% de Perdas'
              direction ='reverse'
              result={losts_obj.realized}
              goal={losts_obj.goal}
              percentage={true}
            />
          </Col>
         <Col md lg xl = {2}/>
         <Col md lg xl = {5}>
           <SimpleBox
             header='Ativação - Novos Produtos'
             direction ='normal'
             result={new_products_result}
             goal={57}
             percentage={true}
             />
          </Col>
         {/* <Col md lg xl = {4}> {tma} </Col> */}
         <Col md lg xl = {5}>
            <SimpleBox
              header='Ativação de RAV'
              direction ='normal'
              result={rav_result}
              goal={5}
              percentage={true}
            />
          </Col>
        </Row>
        </div>
    )


      this.setState({
        summarySection: result
      })

    }

buildHistoryOSType(obj) {
  const graphObj =
  {
    data: {
      labels: [],
      datasets: [
        {
          label: 'Instalação',
          stack: 'Stack 0',
          backgroundColor: '#18641e',
          borderWidth: 2,
          data: [],
        },
        {
          label: 'Desinstalação',
          stack: 'Stack 0',
          backgroundColor: '#c7220f',
          borderWidth: 2,
          data: [],
        },
        {
          label: 'Troca',
          stack: 'Stack 0',
          backgroundColor: '#ffbc14',
          borderWidth: 2,
          data: [],
        },
        {
          label: 'Manutenção',
          stack: 'Stack 0',
          backgroundColor: '#00942A',
          borderWidth: 2,
          data: [],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          categoryPercentage: 0.9,
          gridLines: {
            display: false,
          },
          ticks: {
            min: '2018-09',
          },
        }],
        yAxes: [{
          stacked: true,
          gridLines: {
            display: true,
          },
          ticks: {
            min: 0,
            max:100
          },
        }],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: true,
        fontColor: '#2e3136',
        fontSize: 20,
        text: 'Proporção de OSs por Tipo',
      },
      plugins: {
        datalabels: {
          display: function (graphObj) {
            return graphObj.dataset.data[graphObj.dataIndex] > 0
          },
          font: {
            size: 11,
            weight: 'bold'
          },
          color: 'white',
          formatter: function (value) {
            if (value >= 1000) {
              return String((value/1000).toFixed(1)) + 'k'
            }
            else {
              return value.toFixed(1)
            }
          },
          // anchor: 'end',
        },
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'black',
      },
    },
  }


  _.orderBy(obj, 'date').forEach((element) => {
      graphObj.data.labels.push(element.date)
      graphObj.data.datasets[0].data.push(element.installation*100/(element.installation+element.desinstallation+element.change+element.fix))
      graphObj.data.datasets[1].data.push(element.desinstallation*100/(element.installation+element.desinstallation+element.change+element.fix))
      graphObj.data.datasets[2].data.push(element.change*100/(element.installation+element.desinstallation+element.change+element.fix))
      graphObj.data.datasets[3].data.push(element.fix*100/(element.installation+element.desinstallation+element.change+element.fix))
    }
  )



  this.setState({
    osTypeHistory: {
      graph: graphObj,
      loaded: true
    },
    lastUpdated: moment(),
  })
}

renderOSHistoryType() {
  if (this.state.osTypeHistory.loaded ) {
    return (
      <div>
        <Graph
          data={this.state.osTypeHistory.graph.data}
          legend={this.state.osTypeHistory.graph.legend}
          options={this.state.osTypeHistory.graph.options}
          width={this.state.isDesktop ? 500 : 350}
          height={this.state.isDesktop ? 400 : 350}
    />
      </div>)
  }

}


buildProgressGoalsGraph(obj) {
  const graphObj =
  {
    data: {
      labels: [],
      datasets: [
        {
          label: 'Meta',
          stack: 'Stack 1',
          backgroundColor: 'red',
          borderWidth: 2,
          data: [],
          type: 'line',
          fill: false,
          backgroundColor: 'red',
          borderColor: 'red',
          hoverBackgroundColor: 'red',
          hoverBorderColor: 'red'
        },
        {
          label: 'Realizado',
          stack: 'Stack 0',
          backgroundColor: 'green',
          borderWidth: 2,
          data: [],
          fill: false,
          backgroundColor: 'green',
        }
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
          },
        }],
        yAxes: [{
          type: 'linear',
          stacked: false,
          gridLines: {
            display: true,
          },
          ticks: {
            min: 0,
            callback: function t(value) {
              return `${value}%`
          },
          },
        }],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: true,
        fontColor: '#2e3136',
        fontSize: 20,
        text: 'IDT Histórico',
      },
      plugins: {
        datalabels: {
          display: function (graphObj) {
            return graphObj.dataset.data[graphObj.dataIndex] > 0
          },
          anchor: 'end',
          align: 'top',
          font: {
            size: 12,
          },
          color: 'black',
          formatter: function (value) {
            return `${value}%`
          }
        },
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'black',
      },
    },
  }


  _.orderBy(obj, 'date').forEach((element) => {
      graphObj.data.labels.push(" ".concat(element.date))
      graphObj.data.datasets[1].data.push((100*element.changes/element.max).toFixed(1))
      graphObj.data.datasets[0].data.push(element.meta)
    }
  )

  this.setState({
    progressGoals: {
      graph: graphObj,
      loaded: true
    },
    lastUpdated: moment(),
  })
}

//Renderiza o gráfico de status de OS por data de abertura
renderProgressGoalsGraph() {
  if (this.state.progressGoals.loaded) {
    return (
      <div>
        <Graph
          data={this.state.progressGoals.graph.data}
          legend={this.state.progressGoals.graph.legend}
          options={this.state.progressGoals.graph.options}
          width={this.state.isDesktop ? 500 : 350}
          height={this.state.isDesktop ? 400 : 350}
    />
      </div>)
  }

}


buildIDMHistory(obj){
  const graphObj =
  {
    data: {
      labels: [],
      datasets: [
        {
          label: 'CSAT',
          stack: 'Stack 0',
          backgroundColor: '#00942A',
          borderWidth: 2,
          data: [],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          categoryPercentage: 0.9,
          gridLines: {
            display: false,
          },
          ticks: {
            // min: "2019-1",
          },
        }],
        yAxes: [{
          stacked: true,
          gridLines: {
            display: true,
          },
          ticks: {
            // min: 90,
            // max:100
            suggestedMax:2
          },
        }],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: true,
        fontColor: '#2e3136',
        fontSize: 20,
        text: 'IDM Histórico',
      },
      plugins: {
        datalabels: {
          display: function (graphObj) {
            return graphObj.dataset.data[graphObj.dataIndex] > 0
          },
          font: {
            size: 15,
          },
          color: 'black',
          anchor: 'end',
          align: 'top',
          formatter: function (value) {
            if (value >= 1000) {
              return String((value/1000).toFixed(1)) + 'k'
            }
            else {
              return value.toFixed(2)
            }
          },
        },
      },
    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        fontColor: 'black',
      },
    },
  }



  _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push("".concat(element.date))
        graphObj.data.datasets[0].data.push((element.count*100)/element.onlineTerminalsStone)
      }
    )



  this.setState({
    historyIDM: {
      graph: graphObj,
      loaded: true
    },
    lastUpdated: moment(),
  })
}

renderIDMHistoryGraph() {
  if (this.state.historyIDM.loaded ) {
    return (
      <div>
        <Graph
          data={this.state.historyIDM.graph.data}
          legend={this.state.historyIDM.graph.legend}
          options={this.state.historyIDM.graph.options}
          width={this.state.isDesktop ? 500 : 350}
          height={this.state.isDesktop ? 400 : 350}
    />
      </div>)
  }

}


renderBlockLogistics() {
  if (this.state.summarySection !== undefined ) {
    return (
          <div>
            {this.state.summarySection}
          </div>
      )
  }
  return (
    <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
      <LoadingSpinner />
    </div>)
}


  render () {
    return (
      <Container fluid>
        <CustomizedExpansionPannel
            title='Resumo Executivo'
            content={this.renderBlockLogistics()}
            helpMessage={<p><b>SLA Logístico Total.</b><br/>
            <p>Quantidade OSs no Prazo / Total de OSs Vencidas - Operação Própria e Terceira</p>
              <b>SLA Stone Mais</b>
              <p>Qtd OSs no Prazo / Total de OSs Vencidas - Stone Mais</p>
              <b>Eficiência da Cadeia</b>
              <p>Parque Ativo (Gerando Receita) / Parque Total - Para as demais BUs que não Stone Mais.</p>
              <b>Ativação Software</b>
              <p>Qtd de clientes ativados em RX e Collact / Qtd de clientes credenciados nos produtos.</p>
              <b>OSs Realizadas por Angels</b>
              <p>Qtd de OSs realizadas por Angel (Sem Redirect) / Total de OSs Realizadas. Não são consideradas OSs de bobina.</p>
              <b>Acidentes com Afastamento</b>
              <p>Acidentes (apenas com afastamento). Em caso de um acidente com afastamento superior a 15 dias corridos, a meta também é perdida, independente</p> </p>}
            tooltip={true}
        />
        <CustomizedExpansionPannel
          title='Ordens de Serviço'
          content={<ServiceOrdersSection/>}
          helpMessage={<p><b>SLA Geral</b>
          <p>Qtd OSs no Prazo / Total de OSs Vencidas.
          Prazo é dado na visão cliente e não considera bobina.
          Considera operação Própria (Com Redirect) e Terceira (Com Franquia).</p>
          <b>SLA Operação Própria</b>
          <p>Qtd OSs no Prazo / Total de OSs Vencidas
          Prazo é dado na visão cliente e não considera bobina.
          Apenas operação Própria (considerando Redirect).</p>
          <b>SLA Operação Terceira</b>
          <p>Qtd OSs no Prazo / Total de OSs Vencidas
          Prazo é dado na visão cliente e não considera bobina.
          Apenas operação Terceira (Com Franquia).</p> </p>}
          tooltip={true}
          />
        <CustomizedExpansionPannel
            title='Parque de Máquinas'
            content={<CapexSection/>}
            helpMessage={<p>
            <b>Evolução da Eficiência</b>
            <p>Considera todo o parque gerador de receita (ativo ou aluguel cobrado) dentre o parque o total (instalado ou não).
            Parque Ativo (Gerando Receita) / Parque Total. Para as demais BUs que não Stone Mais.</p>
            <b>% Perdas</b>
            <p>(Qtd de Máquinas Perdidas - Qtd de Máquinas Encontradas) / Parque Médio</p>
            <b>Parque 0 TPV</b>
            <p>Considera todo o parque instalado não gerador de receita (ativo ou cobrando aluguel) dentre o parque o instalado.</p></p>}
            tooltip={true}
          />
        <CustomizedExpansionPannel
            title='Status da Ponta'
            content={<HubsStatusSection/>}
            helpMessage={<p>
            <b>Ineficiência</b>
            <p>Quantidade de OSs canceladas e revisitadas / Total de OSs feitas.
            Considerando apenas OSs BAU e desconsiderando Viradas de Operação, Caça,
            Entrega Direta, Incidentes Sistêmicos e Incidentes Operacionais.</p>
            <b>% Stock Out</b>
            <p>Quantidade de chamados retidos na carteira do dia (vencidos ou vencem no dia) sobre total de chamados que vencem no dia (independente de já realizados ou não).
            Após o cálculo diário, o resultado mensal é a média de todos os dias úteis do mês.</p></p>}
            tooltip={true}
          />
        <CustomizedExpansionPannel
            title='% Angels e Qualidade de Atendimento'
            content={<ShareAndQualitySection/>}
            helpMessage={<p>
              <b>Histórico de OSs Realizadas por Angels</b>
              <p>(i) Qtd de OSs realizadas por Angel (Sem Redirect) / Total de OSs Realizadas.
              (ii) Qtd de OSs realizadas por Terceiro / Total de OSs Realizadas.
              (iii) Qtd de OSs Redirect realizadas  / Total de OSs Realizadas.
              Não são consideradas OSs de bobina.</p>
              <b>CSAT</b>
              <p>Índice de Satisfação nos Atendimentos dos Angels
              Número de Avaliações com Notas '4' ou '5' / Total de Avaliações</p></p>}
            tooltip={true}
          />
        <CustomizedExpansionPannel
            title='Trocas e Manutenções'
            content={<SwapsAndMaintenanceSection/>}
            helpMessage={<p>
            <b>IDT Histórico</b>
            <p>Índice de Trocas - Qtd de OSs de Trocas / Parque Ativo.</p>
            <b>IDM Histórico</b>
            <p>Índice de Trocas de Chips - Qtd de OSs Relacionas a Chip / Parque Online.</p></p>}
            tooltip={true}
          />
            <CustomizedExpansionPannel
                title='Green Angels'
                content={<GreenAngelsSection/>}
                helpMessage={<p>
                <b>Headcount</b>
                <p>Leva em consideração apenas os nossos Green Angels.</p>
                <b>Turnover</b>
                <p>Não incluis os Angels que mudaram de função dentro da Stone.</p>
                <b>Produtividade</b>
                <p>Qtd OSs realizadas pelos Angels (Não considera Redirect) / ( Qtd de Dias Úteis * Qtd Angels ) - Não considera bobinas.
                Considera as canceladas que geraram visitas (exceto pelo Cancelada Contratante, Em Análise e OSs duplicadas).
                Considera os dias úteis do mês excluindo os feriados.</p></p>}
                tooltip={true}
              />
      </Container>
    )
  }
}

export default OnePage
