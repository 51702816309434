import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import MaterialTable from "material-table";
import LogisticService from "../../../core/services/LogisticService";
import moment from "moment";

const defaultTableLocalization = {
  toolbar: {
    searchTooltip: "Pesquisar",
    searchPlaceholder: "Pesquisar",
    exportName: "Baixar CSV",
    exportTitle: "Download",
  },
  pagination: {
    labelRowsSelect: "linhas",
    labelDisplayedRows: "{from}-{to} de {count}",
    previousAriaLabel: "Página anterior",
    previousTooltip: "Página anterior",
    nextAriaLabel: "Próxima página",
    nextTooltip: "Próxima página",
    lastAriaLabel: "Última página",
    lastTooltip: "Última página",
    firstAriaLabel: "Primeira página",
    firstTooltip: "Primeira página",
  },
  body: {
    emptyDataSourceMessage: "Nada para mostrar",
  },
};

const defaultTableOptions = {
  exportAllData: true,
  pageSize: 10,
  showTitle: false,
  padding: "dense",
  exportButton: true,
  maxBodyHeight: 500,
  filtering: true,
};

const AbasDeDetalhamento = (props) => {
  const { TabPane } = Tabs;
  const { angel, polo, ano, mes, resultado, distrito } = props;

  const [tabOneContent, setTabOneContent] = useState(props.resultado);
  const [tabTwoContent, setTabTwoContent] = useState(undefined);
  const [tabThreeContent, setTabThreeContent] = useState(undefined);
  const [tabFourContent, setTabFourContent] = useState(undefined);

  useEffect(() => {
    setTabOneContent(props.resultado);
  }, [props]);

  function formatDate(date, hours) {
    var datePart = date.slice(0, 10);

    if (hours === undefined) {
      return (
        datePart.split("-")[2] +
        "/" +
        datePart.split("-")[1] +
        "/" +
        datePart.split("-")[0]
      );
    }

    return (
      datePart.split("-")[2] +
      "/" +
      datePart.split("-")[1] +
      "/" +
      datePart.split("-")[0] +
      " " +
      date.slice(11)
    );
  }

  function callback(key) {
    if (key === "1" && tabOneContent === undefined) {
      setTabOneContent([]);
    }

    if (key === "2" && tabTwoContent === undefined) {
      LogisticService.getDistrictScreeningData(ano, mes, distrito).then(
        (response) => {
          setTabTwoContent(
            response.data.data.map((terminal) => {
              const date = terminal.screeningDate.slice(0, 10).split("-");
              return {
                serial: terminal.serial,
                statusBefore: terminal.statusBefore,
                statusAfter: terminal.statusAfter,
                screeningDate: date[2] + "/" + date[1] + "/" + date[0],
                hub: terminal.provider,
                weekNumber: terminal.weekNumber,
              };
            })
          );
        }
      );
    }

    if (key === "3" && tabThreeContent === undefined) {
      LogisticService.getDistrictRemovedTerminalsData(ano, mes, distrito).then(
        (response) => {
          setTabThreeContent(
            response.data.data.map((terminal) => ({
              serialUninstalled: terminal.serialUninstalled,
              statusBefore: terminal.statusBefore,
              serviceOrderNumber: terminal.serviceOrderNumber,
              serviceGroup: terminal.serviceGroup,
              removalDate: formatDate(terminal.removalDate),
              weekNumber: terminal.weekNumber,
              provider: terminal.provider,
            }))
          );
        }
      );
    }

    if (key === "4" && tabFourContent === undefined) {
      LogisticService.getDistrictStockPhotoData(ano, mes, distrito).then(
        (response) => {
          setTabFourContent(
            response.data.data.map((customer) => ({
              serial: customer.serial,
              status: customer.status,
              provider: customer.provider,
              district: customer.district,
              photoDate: moment(customer.photoDate).format("DD/MM/YYYY"),
            }))
          );
        }
      );
    }
  }

  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Resumo" key="1">
        <div style={{ padding: "0px 100px" }}>
          <MaterialTable
            columns={[
              { title: "Pilar", field: "name" },
              { title: "Atingimento", field: "score" },
              { title: "Limite Máximo", field: "limit" },
            ]}
            options={{
              showTitle: false,
              search: false,
              paging: false,
              toolbar: false,
            }}
            data={
              !tabOneContent
                ? []
                : Object.values(tabOneContent).map((p) => {
                    return {
                      name: p.nome,
                      score: p.atingimento + "%", //resultado[p],
                      limit:
                        p.indicadores
                          .filter((i) => i.tipo !== "alavanca")
                          .map((i) => parseFloat(i.peso))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(1) + "%",
                      indicadores: p.indicadores,
                    };
                  })
            }
            detailPanel={(rowData) => {
              return (
                <div style={{ padding: "30px 0px" }}>
                  <MaterialTable
                    columns={[
                      {
                        title: "Indicador",
                        field: "indicador",
                      },
                      {
                        title: "Resultado",
                        field: "resultado",
                      },
                      {
                        title: "Atingimento Pós-Régua",
                        field: "atingimentoRegua",
                      },
                      {
                        title: "Peso",
                        field: "peso",
                      },
                      {
                        title: "Impacto no Pilar",
                        field: "contribuicaoTotal",
                      },
                    ]}
                    data={rowData.indicadores.map((indicador) => ({
                      indicador: indicador.nome,
                      resultado: indicador.resultado,
                      atingimentoRegua: indicador.atingimento + "%",
                      peso:
                        indicador.tipo === "alavanca"
                          ? "Bônus"
                          : indicador.peso + "%",
                      contribuicaoTotal:
                        indicador.tipo === "alavanca"
                          ? indicador.atingimento + "%"
                          : (indicador.atingimento / 100) * indicador.peso +
                            "%",
                    }))}
                    localization={defaultTableLocalization}
                    options={{
                      ...defaultTableOptions,
                      filtering: false,
                      pageSize: 5,
                      exportButton: false,
                      search: false,
                      paging: false,
                      toolbar: false,
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
      </TabPane>
      <TabPane tab="Terminais Triados" key="2">
        <MaterialTable
          columns={[
            {
              title: "Serial",
              field: "serial",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Status Antes",
              field: "statusBefore",
              cellStyle: { fontSize: "0.8em", width: "3em" },
            },
            {
              title: "Status Depois",
              field: "statusAfter",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Polo",
              field: "hub",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Data da Triagem",
              field: "screeningDate",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Semana",
              field: "weekNumber",
              type: "numeric",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabTwoContent}
          isLoading={!tabTwoContent}
          title="Terminais Triados"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
      <TabPane tab="Terminais Retirados" key="3">
        <MaterialTable
          columns={[
            {
              title: "Número da OS",
              field: "serviceOrderNumber",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Grupo de Serviço",
              field: "serviceGroup",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Serial",
              field: "serialUninstalled",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Polo",
              field: "provider",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Data da Retirada",
              field: "removalDate",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Semana",
              field: "weekNumber",
              type: "numeric",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabThreeContent}
          isLoading={!tabThreeContent}
          title="Terminais Retirados"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
      <TabPane tab="Ciclo Triagem" key="4">
        <MaterialTable
          columns={[
            {
              title: "Serial",
              field: "serial",
              cellStyle: { fontSize: "0.8em", width: "3em" },
            },
            {
              title: "Status",
              field: "status",
              cellStyle: { fontSize: "0.8em", width: "3em" },
            },
            {
              title: "Polo",
              field: "provider",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "5em" },
            },
            {
              title: "Data da Foto",
              field: "photoDate",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabFourContent}
          isLoading={!tabFourContent}
          title="Ciclo Triagem"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
    </Tabs>
  );
};

export default AbasDeDetalhamento;
