import React, { Component } from 'react'
import "./style.css"
import { withStyles } from '@material-ui/core/styles';
import { Line } from 'react-chartjs-2'
import InputLabel from '@material-ui/core/InputLabel';
import { Statistic, Icon, Card, Col as ColAntd, Row as RowAntd } from 'antd'
import _ from 'lodash'
import RefreshButton from '../../custom-components/RefreshButton'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LogisticService from '../../core/services/LogisticService'
import CustomizedExpansionPannel from '../../custom-components/CustomizedExpansionPanel/index'
import CustomizedTable from '../../custom-components/CustomizedTable/index'
import TablePaging from '../../custom-components/CustomizedTablePaging'
import LoadingSpinner from '../../components/LoadingSpinner'
import DownloadButton from '../../custom-components/DownloadButton'
import { Container, Col, Row } from 'react-bootstrap'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Divider } from '@material-ui/core';


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: 'white',
    color: 'dark',
    fontSize: '0.9em',
    fontFamily: 'GoBold'
  },
  body: {
    fontSize: '0.8em',
    fontFamily: 'GoBold'
  },
}))(TableCell);

const SimpleBox = props => {
  const { header, result, goal, direction, percentage, decimal } = props
  let headlightSymbol;
  let headlightColor;

  if (direction === 'normal') {
    headlightSymbol = (result >= goal ? 'check-circle' :
      (result > 0.9 * goal) ? 'minus-circle' : 'close-circle')
    headlightColor = (result >= goal ? '#28A745' :
      (result > 0.9 * goal) ? 'orange' : 'red')
  } else if (direction === 'reverse') {
    headlightSymbol = (result <= goal ? 'check-circle' :
      (result < 1.1 * goal) ? 'minus-circle' : 'close-circle')
    headlightColor = (result <= goal ? '#28A745' :
      (result < 1.1 * goal) ? 'orange' : 'red')
  }
  else if (direction === 'none')
    headlightSymbol = ''


  return (
    <Card>
      <Statistic
        title={<h3 style={{ fontFamily: 'GoBold' }}>{header}</h3>}
        value={result === undefined ? 0 : result.toFixed(decimal)}
        valueStyle={{ color: { headlightColor } }}
        prefix={goal === undefined ? '' : <Icon type={headlightSymbol} theme="twoTone" style={{ verticalAlign: 'middle' }} twoToneColor={headlightColor} />}
        groupSeparator={''}
        decimalSeparator={','}
        suffix={percentage ? '%' : ''}
      />
    </Card>
  )
}

const Trophy = props => {
  return (
    <Row>
      Nenhuma ocorrência!⠀<Icon type='trophy' theme="twoTone" style={{ verticalAlign: 'middle', fontSize: 22, fontWeight: 'bold' }} twoToneColor="orange" />
    </Row>
  )
}

class SecurityPanel extends Component {
  constructor() {

    super()

    this.state = {
      loading: false,
      lastUpdated: undefined,

      TopCar: {
        data: undefined,
        loaded: false,
      },
      TopCycle: {
        data: undefined,
        loaded: false,
      },
      regionsIndicators: {
        data: undefined,
        loaded: false,
      },
      regionsGraph: {
        graph: undefined,
        loaded: false
      },
      districtIndicators: {
        data: undefined,
        loaded: false,
      },
      districtAngelIndicators: {
        data: undefined,
        loaded: false,
      },
      districtsAccidents: {
        data: undefined,
        loaded: false,
      },
      districtsPenalties: {
        data: undefined,
        loaded: false,
      },
      accidentRate: {
        graph: undefined,
        loaded: false,
      },
      fineRate: {
        graph: undefined,
        loaded: false,
      },
      leaveIndex: {
        graph: undefined,
        loaded: false,
      },
      streetSpeedRate: {
        graph: undefined,
        loaded: false,
      },
      districtGraph: {
        graph: undefined,
        loaded: false,
      },
      leaveIndexNumber: {
        value: undefined,
        tolerance: undefined,
        loaded: undefined
      },
      accidentRateNumber: {
        value: undefined,
        tolerance: undefined,
        loaded: undefined
      },
      fineRateNumber: {
        value: undefined,
        tolerance: undefined,
        loaded: undefined
      },
      MTDSummary: {
        VviaExcess: undefined,
        criticity: undefined,
        KmTotal: undefined,
        above90: undefined,
        VviaRate: undefined,
        vviaratetolerance: undefined,
        maximumSpeed: undefined,
        loaded: false,
      },
      date: undefined,
      region: undefined,
      district: undefined,
      selectedDate: undefined,
      selectedRegion: undefined,
      selectedDistrict: undefined
    }


    this.refreshReportData = this.refreshReportData.bind(this)
  }

  componentDidMount() {
    this.refreshReportData()
  }

  cleaningStates() {
    this.setState({
      loading: false,
      lastUpdated: undefined,
      TopCar: {
        data: undefined,
        loaded: false,
      },
      TopCycle: {
        data: undefined,
        loaded: false,
      },
      regionsIndicators: {
        data: undefined,
        loaded: false,
      },
      regionsGraph: {
        graph: undefined,
        loaded: false
      },
      districtIndicators: {
        data: undefined,
        loaded: false,
      },
      districtAngelIndicators: {
        data: undefined,
        loaded: false,
      },
      accidentRate: {
        graph: undefined,
        loaded: false,
      },
      fineRate: {
        graph: undefined,
        loaded: false,
      },
      leaveIndex: {
        graph: undefined,
        loaded: false,
      },
      streetSpeedRate: {
        graph: undefined,
        loaded: false,
      },
      leaveIndexNumber: {
        value: undefined,
        tolerance: undefined,
        loaded: undefined
      },
      accidentRateNumber: {
        value: undefined,
        tolerance: undefined,
        loaded: undefined
      },
      fineRateNumber: {
        value: undefined,
        tolerance: undefined,
        loaded: undefined
      },
      MTDSummary: {
        VviaExcess: undefined,
        criticity: undefined,
        KmTotal: undefined,
        above90: undefined,
        VviaRate: undefined,
        maximumSpeed: undefined,
        loaded: false,
      },
      date: undefined,
      region: undefined,
      district: undefined,
      selectedDate: undefined,
      selectedRegion: undefined,
      selectedDistrict: undefined
    })
  }

  refreshReportData() {
    this.cleaningStates()
    this.updateFilter()
    this.updateData()
    this.updateGeralNumber()
  }

  updateFilter() {
    LogisticService.getSecurityRegions()
      .then((response) => {
        this.setState({
          region: response.data.data,
          selectedRegion: response.data.data[0].name,
        })
        LogisticService.getSecurityDistricts(response.data.data[0].name)
          .then((response) => {
            this.setState({
              district: response.data.data,
              selectedDistrict: response.data.data[0].name,
            })
            LogisticService.getSecurityIndicatorsDistricts(this.state.selectedRegion, response.data.data[0].name, (this.state.selectedDate ? '01/' + this.state.selectedDate : ''))
              .then((response) => {
                this.setState({
                  districtIndicators: {
                    data: response.data.data,
                    loaded: true
                  },
                })
              })

            LogisticService.getSecurityIndicatorsDistrictsAccidents(this.state.selectedRegion, response.data.data[0].name, (this.state.selectedDate ? '01/' + this.state.selectedDate : ''))
              .then((response) => {
                this.setState({
                  districtsAccidents: {
                    data: response.data.data,
                    loaded: true
                  },
                })
              })

            LogisticService.getSecurityIndicatorsDistrictsPenalties(this.state.selectedRegion, response.data.data[0].name, (this.state.selectedDate ? '01/' + this.state.selectedDate : ''))
              .then((response) => {
                this.setState({
                  districtsPenalties: {
                    data: response.data.data,
                    loaded: true
                  },
                })
              })

            LogisticService.getSecurityIndicatorsAngelDistricts(this.state.selectedRegion, response.data.data[0].name, (this.state.selectedDate ? '01/' + this.state.selectedDate : ''))
              .then((response) => {
                this.setState({
                  districtAngelIndicators: {
                    data: response.data.data,
                    loaded: true,
                  },
                })
              })

            LogisticService.getSecurityIndicatorsDistrictsGraph(this.state.selectedRegion, response.data.data[0].name, (this.state.selectedDate ? '01/' + this.state.selectedDate : ''))
              .then((response) => {
                this.buildDistrictGraph(response.data.data, ["Taxa Vvia", "Criticidade Excessos", "Quantidade de Excessos", "KM Rodados"], ['red', 'green', 'rgb(13, 169, 221)', 'black'])
              })
          })
      })

    LogisticService.getSecurityAvailableMonths()
      .then((response) => {
        this.setState({
          date: response.data.data,
          selectedDate: response.data.data[0].period
        })
      })
  }

  updateData() {
    LogisticService.getSecurityAccidents()
      .then((response) => {
        this.setState({
          accidentRateNumber: {
            value: response.data.data[0] === undefined ? 0 : response.data.data[0].accidentsrate,
            tolerance: response.data.data[0] === undefined ? 0 : response.data.data[0].tolerance,
            loaded: true
          }
        })
        if (this.state.accidentRateNumber.loaded)
          LogisticService.getSecurityPenalties()
            .then((response) => {

              this.setState({
                fineRateNumber: {
                  value: response.data.data[0] === undefined ? 0 : response.data.data[0].finerate,
                  tolerance: response.data.data[0] === undefined ? 0 : response.data.data[0].tolerance,
                  loaded: true
                }
              })
              if (this.state.fineRateNumber.loaded)
                LogisticService.getSecurityLeaveIndex()
                  .then((response) => {
                    this.setState({
                      leaveIndexNumber: {
                        value: response.data.data[0] === undefined ? 0 : response.data.data[0].leaveindex,
                        tolerance: undefined,
                        loaded: true
                      }
                    })
                    if (this.state.leaveIndexNumber.loaded)
                      LogisticService.getSecuritySpeedLimits()
                        .then((response) => {
                          this.setState({
                            MTDSummary: {
                              VviaExcess: response.data.data[0] === undefined ? 0 : response.data.data[0].vviaexcess,
                              KmTotal: response.data.data[0] === undefined ? 0 : response.data.data[0].totalkm,
                              VviaRate: response.data.data[0] === undefined ? 0 : response.data.data[0].vviarate,
                              criticity: response.data.data[0] === undefined ? 0 : response.data.data[0].criticity,
                              above90: response.data.data[0] === undefined ? 0 : response.data.data[0].above90,
                              maximumSpeed: response.data.data[0] === undefined ? 0 : response.data.data[0].maximumspeed,
                              vviaratetolerance: response.data.data[0] === undefined ? 0 : response.data.data[0].vviaratetolerance,
                              loaded: true
                            }
                          })
                        })
                  })
            })
      })

    LogisticService.getSecurityTopSpeed()
      .then((response) => {
        this.setState({
          TopCar: {
            data: response.data.data['carro'],
            loaded: true
          },
          TopCycle: {
            data: response.data.data['moto'],
            loaded: true
          },
        })
      })

    LogisticService.getSecurityIndicatorsRegions()
      .then((response) => {
        this.setState({
          regionsIndicators: {
            data: response.data.data,
            loaded: true,
          },
        })
      })

    LogisticService.getSecurityIndicatorsRegionsGraph()
      .then((response) => {
        this.buildRegionGraph(response.data.data, ['red', 'green', 'blue', 'gray', 'black', 'yellow'])
      })
  }

  updateGeralNumber() {
    LogisticService.getSecurityAccidentsHistory()
      .then((response) => {
        this.buildAccidentRate(response.data.data, ["Acidente com afastamento/10k OSs [TRI]", "Tolerância acidentes"], ['#c92525', 'red'])
      })
    LogisticService.getSecurityPenaltiesHistory()
      .then((response) => {
        this.buildFineRate(response.data.data, ["Multa/10k OSs [TRI]", "Tolerância Multas"], ['#ffc000', '#ffc000'])
      })
    LogisticService.getSecurityLeaveIndexHistory()
      .then((response) => {
        this.buildLeaveIndex(response.data.data, ["Índice de Afastamento (Pesos) [TRI]"], ['#70ad47'])
      })
    LogisticService.getSecuritySpeedLimitsHistory('2020-01-01')
      .then((response) => {
        this.buildStreetSpeedRate(response.data.data, ["Excesso Vvia/1k Km rodado", "Tolerância Excessos Vvia"], ['#033921', '#033921'])
      })
  }

  updateDistricts(value) {
    LogisticService.getSecurityDistricts(value)
      .then((response) => {
        this.setState({
          district: response.data.data,
          selectedDistrict: response.data.data[0].name,
        })
      })
  }

  updateDistrictNumbers(value, type) {
    switch (type) {
      case 0:
        this.setState({
          selectedRegion: value
        })
        this.updateDistricts(value)
        break;
      case 1:
        this.setState({
          selectedDistrict: value
        })
        break;
      default:
        this.setState({
          selectedDate: value
        })
        break;
    }
  }

  refreshDistrictNumbers() {
    this.setState({
      districtIndicators: {
        data: undefined,
        loaded: false
      },
      districtAngelIndicators: {
        data: undefined,
        loaded: false
      },
      districtGraph: {
        graph: undefined,
        loaded: false
      },
    })
    LogisticService.getSecurityIndicatorsDistricts(this.state.selectedRegion, this.state.selectedDistrict, (this.state.selectedDate ? '01/' + this.state.selectedDate : ''))
      .then((response) => {
        this.setState({
          districtIndicators: {
            data: response.data.data,
            loaded: true
          },
        })
      })

    LogisticService.getSecurityIndicatorsDistrictsAccidents(this.state.selectedRegion, this.state.selectedDistrict, (this.state.selectedDate ? '01/' + this.state.selectedDate : ''))
      .then((response) => {
        this.setState({
          districtsAccidents: {
            data: response.data.data,
            loaded: true
          },
        })
      })

    LogisticService.getSecurityIndicatorsDistrictsPenalties(this.state.selectedRegion, this.state.selectedDistrict, (this.state.selectedDate ? '01/' + this.state.selectedDate : ''))
      .then((response) => {
        this.setState({
          districtsPenalties: {
            data: response.data.data,
            loaded: true
          },
        })
      })

    LogisticService.getSecurityIndicatorsAngelDistricts(this.state.selectedRegion, this.state.selectedDistrict, (this.state.selectedDate ? '01/' + this.state.selectedDate : ''))
      .then((response) => {
        this.setState({
          districtAngelIndicators: {
            data: response.data.data,
            loaded: true,
          },
        })
      })

    LogisticService.getSecurityIndicatorsDistrictsGraph(this.state.selectedRegion, this.state.selectedDistrict, (this.state.selectedDate ? '01/' + this.state.selectedDate : ''))
      .then((response) => {
        this.buildDistrictGraph(response.data.data, ["Taxa Vvia", "Criticidade Excessos", "Quantidade de Excessos", "KM Rodados"], ['red', 'green', 'blue', 'black'])
      })
  }

  buildRegionGraph(response, color) {
    let title = []
    let label = []
    let dataset = []
    let datas = []
    for (let i = 0; i < response.length; i++) {
      if (label.indexOf(response[i].period) === -1)
        label.push(response[i].period)
    }

    for (let i = 0; i < response.length; i++) {
      if (title.indexOf(response[i].region) === -1)
        title.push(response[i].region)
    }

    for (let i = 0; i < title.length; i++) {
      let data = []
      let value = i * label.length;
      for (let j = value; j < value + label.length; j++) {
        data.push(
          (response[j].vviarate)
        )
      }
      datas.push(data)
    }

    for (let i = 0; i < title.length; i++) {
      dataset.push(
        {
          label: title[i],
          data: datas[i],
          fill: false,
          borderColor: color[i],
          borderDash: [0, 0]
        }
      )
    }

    const dataRegion = {
      labels: label,
      datasets: dataset
    }

    this.setState({
      regionsGraph: {
        graph: dataRegion,
        loaded: true
      }
    })

  }

  buildDistrictGraph(response, title, color) {
    let labels = []
    let lineOne = []
    let lineTwo = []
    let lineThree = []
    let lineFour = []
    for (let i = 0; i < response.length; i++) {

      labels.push(response[i].period)
      lineOne.push(response[i].vviarate)
      lineTwo.push(response[i].criticity)
      lineThree.push(response[i].vviaexcess)
      lineFour.push(response[i].totalkm)
    }

    this.setState({
      districtGraph: {
        graph: this.buildLineGraphGereric(lineOne, lineTwo, lineThree, lineFour, labels, title, color),
        loaded: true
      }
    })

  }

  buildAccidentRate(response, title, color) {
    let labels = []
    let lineOne = []
    let lineTwo = []
    for (let i = 0; i < response.length; i++) {
      labels.push(response[i].quarter)
      lineOne.push(response[i].accidentsrate)
      lineTwo.push(response[i].tolerance)
    }
    this.setState({
      accidentRate: {
        graph: this.buildLineGraph(lineOne, lineTwo, labels, title, color),
        loaded: true
      }
    })

  }

  buildFineRate(response, title, color) {
    let labels = []
    let lineOne = []
    let lineTwo = []
    for (let i = 0; i < response.length; i++) {
      labels.push(response[i].quarter)
      lineOne.push(response[i].finerate)
      lineTwo.push(response[i].tolerance)
    }
    this.setState({
      fineRate: {
        graph: this.buildLineGraph(lineOne, lineTwo, labels, title, color),
        loaded: true
      }
    })
  }

  buildLeaveIndex(response, title, color) {
    let labels = []
    let lineOne = []
    let lineTwo = []
    for (let i = 0; i < response.length; i++) {
      labels.push(response[i].quarter)
      lineOne.push(response[i].leaveindex)
    }
    this.setState({
      leaveIndex: {
        graph: this.buildLineGraph(lineOne, lineTwo, labels, title, color),
        loaded: true
      }
    })
  }

  buildStreetSpeedRate(response, title, color) {
    let labels = []
    let lineOne = []
    let lineTwo = []
    for (let i = 0; i < response.length; i++) {
      labels.push(response[i].month)
      lineOne.push(response[i].vviarate)
      lineTwo.push(response[i].tolerance)
    }
    this.setState({
      streetSpeedRate: {
        graph: this.buildLineGraph(lineOne, lineTwo, labels, title, color),
        loaded: true
      }
    })
  }

  buildLineGraphGereric(lineOne, lineTwo, lineThree, lineFour, label, title, color) {
    let dataset = []
    for (let i = 0; i < title.length; i++) {
      dataset.push(
        {
          label: title[i],
          data: i === 0 ? lineOne : i === 1 ? lineTwo : i === 2 ? lineThree : lineFour,
          fill: false,
          yAxisID: title[i] !== 'KM Rodados' ? 'A' : 'B',
          borderColor: color[i],
          borderDash: [0, 0],
        }
      )
    }
    const data = {
      labels: label,
      datasets: dataset
    }
    return data
  }

  buildLineGraph(values, limit, label, title, color) {
    let dataset = []
    for (let i = 0; i < title.length; i++) {
      dataset.push(
        {
          label: title[i],
          data: i === 0 ? values : limit,
          fill: false,
          borderColor: color[i],
          borderDash: i === 1 ? [5, 5] : [0, 0]
        }
      )
    }

    const data = {
      labels: label,
      datasets: dataset
    }
    return data
  }

  filterRegion(value) {
    this.setState({
      selectedRegion: value
    })
    this.updateDistricts(value)
    this.updateDistrictNumbers(value, this.state.selectedDistrict, this.state.selectedDate)
  }

  filterDistrict(value) {
    this.setState({
      selectedDistrict: value
    })
    this.updateDates(this.state.selectedRegion, value)
    this.updateDistrictNumbers(this.state.selectedRegion, value, this.state.selectedDate)
  }

  filterDate(value) {
    this.setState({
      selectedDate: value
    })
    this.updateDistrictNumbers(this.state.selectedRegion, this.state.selectedDistrict, value)
  }

  refresDistrictData(value, type) {
    switch (type) {
      case 1:
        this.filterRegion(value);
        break;
      case 2:
        this.filterDistrict(value);
        break;
      case 3:
        this.filterDate(value);
        break;
      default:
        break;
    }
  }

  convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ';';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;

  }

  downloadCSV(args, response) {
    var data, filename, link;
    var csv = this.convertArrayOfObjectsToCSV({
      data: response
    });
    if (csv == null) return;

    filename = args.filename || 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  }

  exportData() {
    LogisticService.getSecurityIndicatorsDistrictsAnalytics(this.state.selectedRegion, this.state.selectedDistrict, (this.state.selectedDate ? '01/' + this.state.selectedDate : ''))
      .then((response) => {
        this.downloadCSV({ filename: "Districts-Analytics.csv" }, response.data.data)
      })
  }

  renderMTD() {
    if (this.state.MTDSummary.loaded) {
      return (
        <div style={{ marginLeft: '5%', fontFamily: 'GoBold' }}>
          <Row>
            <Col md lg xl={2}>
              <h5 style={{ fontFamily: 'GoBold', marginTop: '30%' }}> Indicadores Segurança</h5>
            </Col>
            <Col md lg xl={3}>
              <SimpleBox
                header='Taxa Acidentes c/ Afastamento'
                direction='reverse'
                result={this.state.accidentRateNumber.value}
                goal={this.state.accidentRateNumber.tolerance}
                percentage={false}
                decimal={2}
              />
            </Col>
            <Col md lg xl={3}>
              <SimpleBox
                header='Taxa Multas (M-1)'
                direction='reverse'
                result={this.state.fineRateNumber.value}
                goal={this.state.fineRateNumber.tolerance}
                percentage={false}
                decimal={2}
              />
            </Col>
            <Col md lg xl={3}>
              <SimpleBox
                header='Índice de Afastamento'
                direction='normal'
                result={this.state.leaveIndexNumber.value}
                goal={this.state.leaveIndexNumber.tolerance}
                percentage={false}
                decimal={2}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md lg xl={2}>
              <h5 style={{ fontFamily: 'GoBold', marginTop: '30%' }}> Indicadores Telemetria</h5>
            </Col>
            <Col md lg xl={3}>
              <SimpleBox
                header='Excessos Vvia'
                direction='none'
                result={this.state.MTDSummary.VviaExcess}
                goal={this.state.MTDSummary.VviaExcess}
                percentage={false}
                decimal={0}
              />
            </Col>
            <Col md lg xl={3}>
              <SimpleBox
                header='Km rodados'
                direction='none'
                result={this.state.MTDSummary.KmTotal}
                goal={this.state.MTDSummary.KmTotal}
                percentage={false}
                decimal={0}
              />
            </Col>
            <Col md lg xl={3}>
              <SimpleBox
                header='Taxa Vvia / 1000Km'
                direction='reverse'
                result={this.state.MTDSummary.VviaRate}
                goal={this.state.MTDSummary.vviaratetolerance}
                percentage={false}
                decimal={2}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md lg xl={2}>
              <h5 style={{ fontFamily: 'GoBold', marginTop: '30%' }}>Indicadores Criticidade</h5>
            </Col>
            <Col md lg xl={3}>
              <SimpleBox
                header='Criticidade Média de Excessos Vvia'
                direction='reverse'
                result={this.state.MTDSummary.criticity}
                goal={this.state.MTDSummary.criticity}
                decimal={2}
                percentage={true}
              />
            </Col>
            <Col md lg xl={3}>
              <SimpleBox
                header='Excessos 90Km/h'
                direction='none'
                result={this.state.MTDSummary.above90}
                goal={this.state.MTDSummary.above90}
                decimal={0}
              />
            </Col>
            <Col md lg xl={3}>
              <SimpleBox
                header='Velocidade Máxima atingida'
                direction='none'
                result={this.state.MTDSummary.maximumSpeed}
                goal={this.state.MTDSummary.maximumSpeed}
                decimal={0}
              />
            </Col>
          </Row>
          <br />
        </div>
      )
    }
    return (
      <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
        <LoadingSpinner />
      </div>
    )
  }

  renderGeralNumbers() {
    if (this.state.accidentRate.loaded && this.state.fineRate.loaded && this.state.leaveIndex.loaded && this.state.streetSpeedRate.loaded) {

      const options = {
        tooltips: {
          bodyFontSize: 20,
          titleFontSize: 20,
        },
        scales: {
          yAxes: [{
            ticks: {
              fontSize: 16
            },
          }],
          xAxes: [{
            ticks: {
              fontSize: 16
            },
          }]
        },
        plugins: {
          datalabels: {
            display: false,
          }
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontSize: 18,
          }
        },
      };

      return (
        <div style={{ width: "96%", marginLeft: "1%" }}>
          <Row>
            <Col lg xl="6" xs sm md="12">
              <h3 style={{ textAlign: 'center', 'fontFamily': 'GoBold' }}>Taxa de Acidente</h3>
              <Line data={this.state.accidentRate.graph} options={options} />
            </Col>
            <Col lg xl="6" xs sm md="12">
              <h3 style={{ textAlign: 'center', 'fontFamily': 'GoBold' }}>Taxa de Multa</h3>
              <Line data={this.state.fineRate.graph} options={options} />
            </Col>
          </Row>
          <Row>
            <Col lg xl="6" xs sm md="12">
              <h3 style={{ textAlign: 'center', 'fontFamily': 'GoBold' }}>Índice de Afastamento</h3>
              <Line data={this.state.leaveIndex.graph} options={options} />
            </Col>
            <Col lg xl="6" xs sm md="12">
              <h3 style={{ textAlign: 'center', 'fontFamily': 'GoBold' }}>Taxa Velocidade da Via</h3>
              <Line data={this.state.streetSpeedRate.graph} options={options} />
            </Col>
          </Row>
        </div>
      )
    }
    return (
      <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
        <LoadingSpinner />
      </div>
    )
  }

  renderTopTen() {
    if (this.state.TopCar.loaded && this.state.TopCycle.loaded) {
      let subTitle = [{ name: "Nome", icon: "" }, { name: "Placa Atual", icon: "" }, { name: "Distrito", icon: "" }, { name: "Km Rodados", icon: "" }, { name: "Excessos Vvia", icon: "" }, { name: "Taxa Vvia", icon: "" }, { name: "Criticidade Média Vvia", icon: "" }, { name: "Excessos 90KM/H", icon: "" }]
      let rowsCar = []
      let rowsCycle = []

      this.state.TopCar.data.forEach((infosVeiculo) => {
        rowsCar.push(
          [
            infosVeiculo.angel,
            infosVeiculo.plate,
            infosVeiculo.district,
            this.formatNumber(infosVeiculo.totalkm),
            infosVeiculo.vviaexcess,
            this.formatNumber(infosVeiculo.vviarate),
            this.formatNumber(infosVeiculo.criticity) + '%',
            infosVeiculo.above90,
          ]
        )
      }
      )
      this.state.TopCycle.data.forEach((infosVeiculo) => {
        rowsCycle.push(
          [
            infosVeiculo.angel,
            infosVeiculo.plate,
            infosVeiculo.district,
            this.formatNumber(infosVeiculo.totalkm),
            infosVeiculo.vviaexcess,
            this.formatNumber(infosVeiculo.vviarate),
            this.formatNumber(infosVeiculo.criticity) + '%',
            infosVeiculo.above90,
          ]
        )
      }
      )

      return (
        <div>
          <Row>
            <Col xs sm md lg xl="12">
              <TablePaging
                data={rowsCar}
                title={[{ name: "Carro", icon: "" }]}
                subTitle={subTitle}
                colSpan={"5"}
                totalPages={2}
                rowSpan={5}
                dataQtd={8}
              />
            </Col>
          </Row>
          <Row>
            <Col xs sm md lg xl="12">
              <TablePaging
                data={rowsCycle}
                title={[{ name: "Moto", icon: "" }]}
                subTitle={subTitle}
                colSpan={"5"}
                totalPages={2}
                rowSpan={5}
                dataQtd={8}
              />
            </Col>
          </Row>
        </div>
      )
    }
    else {
      return (
        <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
          <LoadingSpinner />
        </div>
      )
    }
  }

  renderRegionNumbers() {
    const options = {
      tooltips: {
        bodyFontSize: 20,
        titleFontSize: 20,
      },
      scales: {
        yAxes: [{
          ticks: {
            fontSize: 16
          },
        }],
        xAxes: [{
          ticks: {
            fontSize: 16
          },
        }]
      },
      plugins: {
        datalabels: {
          display: false,
        }
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontSize: 18,
        }
      },
    };
    if (this.state.regionsIndicators.loaded) {
      let subTitle = [{ name: "Regional", icon: "" }, { name: "Km Rodados", icon: "" }, { name: "Excessos VVia", icon: "" }, { name: "Taxa VVia", icon: "" }, { name: "Criticidade Média Vvia", icon: "" }, { name: "Excessos 90km/h", icon: "" }]
      let rowsRegion = []
      for (let i = 0; i < this.state.regionsIndicators.data.length; i++) {
        rowsRegion.push(
          [
            <TableRow>
              <StyledTableCell>{this.state.regionsIndicators.data[i].region}</StyledTableCell>
              <StyledTableCell>{this.formatNumber(this.state.regionsIndicators.data[i].totalkm)}</StyledTableCell>
              <StyledTableCell>{this.state.regionsIndicators.data[i].vviaexcess}</StyledTableCell>
              <StyledTableCell>{this.state.regionsIndicators.data[i].vviarate}</StyledTableCell>
              <StyledTableCell>{this.state.regionsIndicators.data[i].criticity + '%'}</StyledTableCell>
              <StyledTableCell>{this.state.regionsIndicators.data[i].above90}</StyledTableCell>
            </TableRow>
          ]
        )
      }
      return (
        <div>
          <Row>
            {this.state.regionsGraph.loaded ?
              <Col lg xl="12" xs sm md="12">
                <h3 style={{ textAlign: 'center', 'fontFamily': 'GoBold' }}>Taxa Vvia</h3>
                <div style={{ width: '50%', margin: 'auto', height: '100%' }}>
                  <Line data={this.state.regionsGraph.graph} options={options} />
                </div>
              </Col> :
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner />
              </div>
            }
          </Row>
          <Row>
            <Col xs sm md lg xl="12">
              <CustomizedTable
                rows={rowsRegion}
                title={[{ name: "", icon: "" }]}
                subTitle={subTitle}
                colSpan={"1"}
              />
            </Col>
          </Row>
        </div>
      )
    }
    else {
      return (
        <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
          <LoadingSpinner />
        </div>
      )
    }
  }

  formatNumber(num) {
    return num === undefined ? 0 : num === null ? 0 : num.toString().replace(',', '').replace('.', ',')
  }

  renderDistrictNumbers() {
    if (this.state.districtAngelIndicators.data !== undefined && this.state.districtIndicators.data !== undefined && this.state.districtsAccidents.data !== undefined && this.state.districtsPenalties.data !== undefined) {

      const options = {
        tooltips: {
          bodyFontSize: 20,
          titleFontSize: 20,
        },
        scales: {
          scaleLabel: { fontSize: 18 },
          yAxes: [{
            id: 'A',
            position: 'left',
            ticks: {
              display: true,
              fontSize: 18
            },
          },
          {
            id: 'B',
            position: 'right',
            ticks: {
              display: true,
              fontSize: 18
            },
          }],
          xAxes: [{
            ticks: {
              fontSize: 18
            },
          }]
        },
        plugins: {
          datalabels: {
            display: false,
          }
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontSize: 18,
          }
        },
      };
      let subTitle = [{ name: "Angels | Polo", icon: "" }, { name: "Placa", icon: "" }, { name: "Km Rodados", icon: "" }, { name: "Excessos Vvia", icon: "" }, { name: "Taxa Vvia", icon: "" }, { name: "Criticidade Média Vvia", icon: "" }, { name: "Velocidade Máxima atingida", icon: "" }, { name: "Excessos 90Km/h", icon: "" }]
      let rowsDistrictAngels = []
      let rowDIstricts = []
      for (let i = 0; i < this.state.districtAngelIndicators.data.length; i++) {
        rowsDistrictAngels.push(
          [
            <TableRow>
              <StyledTableCell>{this.state.districtAngelIndicators.data[i].angel} | {this.state.districtAngelIndicators.data[i].polo}</StyledTableCell>
              <StyledTableCell>{this.formatNumber(this.state.districtAngelIndicators.data[i].plate)}</StyledTableCell>
              <StyledTableCell>{this.formatNumber(this.state.districtAngelIndicators.data[i].totalkm)}</StyledTableCell>
              <StyledTableCell>{this.state.districtAngelIndicators.data[i].vviaexcess}</StyledTableCell>
              <StyledTableCell>{this.formatNumber(this.state.districtAngelIndicators.data[i].vviarate)}</StyledTableCell>
              <StyledTableCell>{this.formatNumber(this.state.districtAngelIndicators.data[i].criticity) + '%'}</StyledTableCell>
              <StyledTableCell>{this.state.districtAngelIndicators.data[i].maximumspeed}</StyledTableCell>
              <StyledTableCell>{this.state.districtAngelIndicators.data[i].above90}</StyledTableCell>
            </TableRow>
          ]
        )
      }
      rowDIstricts.push(
        [
          <TableRow>
            <StyledTableCell>Km Rodados</StyledTableCell>
            <StyledTableCell>{this.state.districtIndicators.data[0] === undefined ? 0 : this.formatNumber(this.state.districtIndicators.data[0].totalkm)}</StyledTableCell>
            <StyledTableCell>Excessos Vvia</StyledTableCell>
            <StyledTableCell>{this.state.districtIndicators.data[0] === undefined ? 0 : this.state.districtIndicators.data[0].vviaexcess}</StyledTableCell>
            <StyledTableCell>Taxa Vvia</StyledTableCell>
            <StyledTableCell>{this.state.districtIndicators.data[0] === undefined ? 0 : this.formatNumber(this.state.districtIndicators.data[0].vviarate)}</StyledTableCell>
          </TableRow>,
          <TableRow>
            <StyledTableCell>Criticidade Média Vvia</StyledTableCell>
            <StyledTableCell>{this.state.districtIndicators.data[0] === undefined ? 0 : this.formatNumber(this.state.districtIndicators.data[0].criticity) + '%'}</StyledTableCell>
            <StyledTableCell>Excessos 90Km/h</StyledTableCell>
            <StyledTableCell>{this.state.districtIndicators.data[0] === undefined ? 0 : this.state.districtIndicators.data[0].above90}</StyledTableCell>
            <StyledTableCell>Velocidade Máxima Atingida</StyledTableCell>
            <StyledTableCell>{this.state.districtIndicators.data[0] === undefined ? 0 : this.state.districtIndicators.data[0].maximumspeed}</StyledTableCell>
          </TableRow>,
          <TableRow>
            <StyledTableCell>Mix de Modal (Carro)</StyledTableCell>
            <StyledTableCell>{this.state.districtIndicators.data[0] === undefined ? 0 : this.formatNumber(this.state.districtIndicators.data[0].mixmodal) + '%'}</StyledTableCell>
            <StyledTableCell>Total de Multas</StyledTableCell>
            <StyledTableCell>{this.state.districtsPenalties.data[0] === undefined ? 0 : this.state.districtsPenalties.data[0].penalties}</StyledTableCell>
            <StyledTableCell>Custo total Multas</StyledTableCell>
            <StyledTableCell>{this.state.districtsPenalties.data[0] === undefined ? 0 : 'R$ ' + this.formatNumber(this.state.districtsPenalties.data[0].penaltiescost)}</StyledTableCell>
          </TableRow>,
          <TableRow>
            <StyledTableCell>Dias sem acidente COM AFASTAMENTO</StyledTableCell>
            <StyledTableCell>{this.state.districtsAccidents.data[0] === undefined ? 0 : (this.state.districtsAccidents.data[0].comafastamento === null ? <Trophy /> : this.state.districtsAccidents.data[0].comafastamento)}</StyledTableCell>
            <StyledTableCell>Dias sem acidente SEM AFASTAMENTO</StyledTableCell>
            <StyledTableCell>{this.state.districtsAccidents.data[0] === undefined ? 0 : (this.state.districtsAccidents.data[0].semafastamento === null ? <Trophy /> : this.state.districtsAccidents.data[0].semafastamento)}</StyledTableCell>
            <StyledTableCell>Dias sem acidente COM PATRIMÔNIO</StyledTableCell>
            <StyledTableCell>{this.state.districtsAccidents.data[0] === undefined ? 0 : (this.state.districtsAccidents.data[0].compatrimonio === null ? <Trophy /> : this.state.districtsAccidents.data[0].compatrimonio)}</StyledTableCell>
          </TableRow>
        ]
      )
      return (
        <div>
          <Row style={{ marginLeft: '1%' }}>
            <DownloadButton
              id="exportAll"
              text="Exportar Dados"
              onClick={e => this.exportData()}
              btnId="btnExportAll"
            />
          </Row>
          <Divider />
          <br />
          <Row>
            <Row style={{ width: "96%", marginLeft: "1%" }}>
              <Col xs sm="12" md lg xl="4">
                <FormControl variant="outlined" margin='3em'>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Selecione uma regional
                      </InputLabel>
                  <Select
                    value={this.state.selectedRegion}
                    onChange={(e, value) => this.updateDistrictNumbers(value.props.value, 0)}
                    labelWidth={320}
                    style={{ width: 320, marginTop: '1em' }}
                  >
                    {this.state.region.map(row => {
                      return (<MenuItem value={row.name}>{row.name}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Col>
              <Col xs sm="12" md lg xl="4">
                <FormControl variant="outlined" margin='3em'>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Selecione um distrito
                      </InputLabel>
                  <Select
                    value={this.state.selectedDistrict}
                    onChange={(e, value) => this.updateDistrictNumbers(value.props.value, 1)}
                    labelWidth={320}
                    style={{ width: 320, marginTop: '1em' }}
                  >
                    <MenuItem value=''>Todos</MenuItem>
                    {this.state.district.map(row => {
                      return (<MenuItem value={row.name}>{row.name}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Col>
              <Col xs sm="12" md lg xl="3">
                <FormControl variant="outlined" margin='3em'>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Selecione um período
                      </InputLabel>
                  <Select
                    value={this.state.selectedDate}
                    onChange={(e, value) => this.updateDistrictNumbers(value.props.value, 2)}
                    labelWidth={320}
                    style={{ width: 320, marginTop: '1em' }}
                  >
                    {this.state.date.map(row => {
                      return (<MenuItem value={row.period}>{row.period}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Col>
              <Col xs sm="12" md lg xl="1">
                <RefreshButton onClick={() => { this.refreshDistrictNumbers() }} />
              </Col>
            </Row>
            <Row style={{ width: "98%", marginLeft: "1%" }}>
              <CustomizedTable
                rows={rowDIstricts}
                title={[{ name: "", icon: "" }]}
                subTitle={''}
                colSpan={"1"}
              />
            </Row>
          </Row>
          <Row>
            {this.state.districtGraph.loaded ?
              <Col lg xl="12" xs sm md="12">
                <div style={{ width: '50%', margin: 'auto', height: '100%' }}>
                  <Line data={this.state.districtGraph.graph} options={options} />
                </div>
              </Col>
              :
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner />
              </div>
            }
          </Row>
          <Row style={{ width: "98%", marginLeft: "1%" }}>
            <CustomizedTable
              rows={rowsDistrictAngels}
              title={[{ name: "", icon: "" }]}
              subTitle={subTitle}
              colSpan={"1"}
            />
          </Row>
        </div>
      )
    }
    else {
      return (
        <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
          <LoadingSpinner />
        </div>
      )
    }
  }

  render() {
    return (
      <Container fluid>
        <CustomizedExpansionPannel
          id='mtdSummary'
          title='Resumo MTD'
          content={this.renderMTD()}
          helpMessage={
            <div>
              <p>Racionais de cálculo</p>
              <p>Taxa de acidentes com afastamento = (Quantidade de acidentes com afastamento)/10.000 * OS's</p>
              <p>Taxa de multas = (Quantidade de multas)/10.000 * OS's</p>
              <p>Índice de Afastamento = Somatório [(Peso do acidente)*(Quantidade do acidente)]/10.000 * OS's</p>
              <p>Taxa de Velocidade da Via = Quantidade de Excessos de Velocidade da Via/1000 * Km</p>
              <p>Criticidade Média de Excessos Vvia = Porcentagem acima da Velocidade da Via</p>
            </div>}
          tooltip={true}
        />
        <CustomizedExpansionPannel
          id='geralNumbers'
          title='Indicadores Gerais'
          content={this.renderGeralNumbers()}
        />
        <CustomizedExpansionPannel
          id='TopCar'
          title='Top 20 Taxa Vvia'
          content={this.renderTopTen()}
        />
        <CustomizedExpansionPannel
          id='regionNumbers'
          title='Indicadores por Regional'
          content={this.renderRegionNumbers()}
        />
        <CustomizedExpansionPannel
          id='districtNumbers'
          title='Indicadores por Distrito'
          content={this.renderDistrictNumbers()}
        />
      </Container>
    )
  }
}

export default SecurityPanel;
