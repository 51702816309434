/* eslint-disable */
import React, { Component } from 'react'
import moment from 'moment'
import Graph from '../../components/Logistic/graphic/graph'
import _ from 'lodash'
import LogisticService from '../../core/services/LogisticService'
import {Line } from 'react-chartjs-2'
import LoadingSpinner from '../../components/LoadingSpinner'
import { Container, Col, Row, Form } from 'react-bootstrap'
import { Statistic, Icon, Card} from 'antd'
import RefreshButton from '../../custom-components/RefreshButton'
import { withStyles } from '@material-ui/core/styles';
import CustomizedExpansionPannel from '../../custom-components/CustomizedExpansionPanel/index'
import './style.css'


class DashLog extends Component {

  constructor() {
  
    super()

    this.state = {
    //Dash Principal
      prazo:{
        loaded : false
      },
      qualidade:{
        loaded : false
      },
      produtividade:{
        loaded : false
      },
      cadeia:{
        loaded : false
      },
      hcs:{
        loaded : false
      },
      seguranca:{
        loaded : false
      }
    }

    this.refreshReportData = this.refreshReportData.bind(this)
  }

  componentDidMount() {
    this.refreshReportData()
  }

  clearStates(){
    this.setState({
      prazo:{
        loaded : false
      },
      qualidade:{
        loaded : false
      },
      produtividade:{
        loaded : false
      },
      cadeia:{
        loaded : false
      },
      hcs:{
        loaded : false
      },
      seguranca:{
        loaded : false
      }
    })
  }

  refreshReportData(){
    this.clearStates()
    
    LogisticService.getOperationAttendance('sla').then((response)=>{  
      LogisticService.getOperationAttendance('sla','day').then((day)=>{  
        this.buildSlaGrahp(response.data.data,day.data.data)
      })
    })  

    LogisticService.getOperationAttendance('bu','month').then((response) => {
      LogisticService.getOperationAttendance('bu','day').then((bu) => {
        LogisticService.getOperationAttendance('csat').then((csat)=>{ 
          this.buildCsatGrahp(csat.data.data,response.data.data,bu.data.data)
        })
      }) 
    })  

    LogisticService.getOperationEfficiency('productive').then((response)=>{  
      this.buildProductiveGrahp(response.data.data)
    })  

    LogisticService.getOperationEfficiency('screening').then((response)=>{  
      this.buildScreeningGrahp(response.data.data)
    }) 

    LogisticService.getOperationPeople('hc').then((response)=>{  
      this.buildHCGrahp(response.data.data)
    })

    LogisticService.getOperationPeople('security').then((response)=>{  
      LogisticService.getOperationPeople('security','day').then((day)=>{  
        this.buildSecurityGrahp(response.data.data,day.data.data)
      })
    })  
  }


  buildSlaGrahp(obj,day) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'TMA',
            stack: 'Stack 0',
            backgroundColor: '#008a4a',
            data: [],
            yAxisID: 'A',
            pointStyle: 'rect',
            fill: false,
            display:false,
            
          },
          {
            label: 'SLA',
            stack: 'Stack 0',
            type : 'line',
            backgroundColor: 'white',
            borderColor: '#92c73d',
            pointBorderColor: '#92c73d',
            pointBackgroundColor: '#92c73d',
            pointHoverBackgroundColor: '#92c73d',
            pointHoverBorderColor: '#92c73d',
            pointRadius : 0,
            data: [],
            yAxisID: 'B',
            fill: false,
            fullWidth: false,
            boxWidth: 1,
            display:false,
            pointStyle: 'line',
            
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'B',
            display:false,
            stacked: false,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
          {
            id: 'A',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'Prazo',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            formatter: function(value, context) {
              if(context.dataset.label === 'SLA')
                return value + '%';
              else
                return value
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            position: 'top',
            align: 'top',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle : true,
          fontColor: 'black',
        },
      },
    }
    obj.forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.date))
        graphObj.data.datasets[0].data.push((element.tma).toFixed(2))
        graphObj.data.datasets[1].data.push((element.sla*100).toFixed(0))
      }
    )
    day.forEach((element) => {
      graphObj.data.labels.push(" ".concat(element.date))
      graphObj.data.datasets[0].data.push((element.tma).toFixed(2))
      graphObj.data.datasets[1].data.push((element.sla*100).toFixed(0))
      }
    )

    this.setState({
      prazo:{
        graph: graphObj,
        loaded: true
      },
    })
  }

  buildCsatGrahp(csat,obj,buDay) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'CSAT',
            stack: 'Stack 0',
            type : 'line',
            backgroundColor: 'white',
            borderColor: 'green',
            pointBorderColor: 'green',
            pointBackgroundColor: 'green',
            pointHoverBackgroundColor: 'green',
            pointHoverBorderColor: 'green',
            pointRadius : 0,
            data: [],
            yAxisID: 'A',
            fill: false,
            fullWidth: false,
            boxWidth: 1,
            display:false,
            pointStyle: 'line',
            
          },
          {
            label: '% ANGELS (BU POLOS)',
            stack: 'Stack 0',
            type : 'line',
            backgroundColor: 'white',
            borderColor: '#92c73d',
            pointBorderColor: '#92c73d',
            pointBackgroundColor: '#92c73d',
            pointHoverBackgroundColor: '#92c73d',
            pointHoverBorderColor: '#92c73d',
            pointRadius : 0,
            data: [],
            yAxisID: 'A',
            fill: false,
            fullWidth: false,
            boxWidth: 1,
            display:false,
            pointStyle: 'line',
            
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'A',
            display:false,
            stacked: false,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 60,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'Qualidade',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            formatter: function(value, context) {
                return value + '%';
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            position: 'top',
            align: 'top',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle : true,
          fontColor: 'black',
        },
      },
    }
    csat.forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.month))
        graphObj.data.datasets[0].data.push((((element.good*1.0)/element.total)*100).toFixed(0))
      }
    )
    
    obj.forEach((element)=>{
      graphObj.data.datasets[1].data.push((((element.osAngel*1.0)/element.osTotal)*100).toFixed(0))
    })

    buDay.forEach((element)=>{
      graphObj.data.datasets[1].data.push((((element.osAngel*1.0)/element.osTotal)*100).toFixed(0))
    })

    this.setState({
      qualidade:{
        graph: graphObj,
        loaded: true
      },
    })
  }

  buildProductiveGrahp(obj) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: '< 5',
            stack: 'Stack 0',
            backgroundColor: '#c00000',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
          },
          {
            label: '>= 5 e < 10',
            stack: 'Stack 0',
            backgroundColor: '#ffd966',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
          },
          {
            label: '>= 10 e < 15',
            stack: 'Stack 0',
            backgroundColor: '#92c73d',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
          },
          {
            label: '>= 15 e < 20',
            stack: 'Stack 0',
            backgroundColor: '#008a4a',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
          },
          {
            label: '>= 20',
            stack: 'Stack 0',
            backgroundColor: 'green',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'A',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'Faixa de Produtividade',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            formatter: function(value, context) {
                return value + '%';
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            position: 'bottom',
            align: 'center',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle : true,
          fontColor: 'black',
        },
      },
    }
    console.log(obj)
    obj.forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.month))
        graphObj.data.datasets[0].data.push((((element.valorum*1.0)/element.total)*100).toFixed(0))
        graphObj.data.datasets[1].data.push((((element.valordois*1.0)/element.total)*100).toFixed(0))
        graphObj.data.datasets[2].data.push((((element.valortres*1.0)/element.total)*100).toFixed(0))
        graphObj.data.datasets[3].data.push((((element.valorquatro*1.0)/element.total)*100).toFixed(0))
        graphObj.data.datasets[4].data.push((((element.valorcinco*1.0)/element.total)*100).toFixed(0))

      }
    )

    this.setState({
      produtividade:{
        graph: graphObj,
        loaded: true
      },
    })
  }

  buildScreeningGrahp(obj) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'PARQUE ATIVO',
            stack: 'Stack 0',
            backgroundColor: '#008a4a',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
          },
          {
            label: 'PARQUE 0 TPV',
            stack: 'Stack 0',
            backgroundColor: '#c00000',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
          },
          {
            label: 'CICLO GOOD',
            stack: 'Stack 0',
            backgroundColor: '#92c73d',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
          },
          {
            label: 'CICLO BAD',
            stack: 'Stack 0',
            backgroundColor: '#ffd966',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
          },
          {
            label: 'OBSOLETOS',
            stack: 'Stack 0',
            backgroundColor: 'gray',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
            {
              id: 'A',
              display:false,
              stacked: true,
              anchor: 'end',
              align: 'top',
              ticks: {
                min: 0,
                callback: function(value) {
                  return `${value.toFixed(2)}%`
                },
              },
            }
          ]
          },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'Evolução etapas da cadeia',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
                if(graphObj.dataset.label === 'PARQUE ATIVO' || graphObj.dataset.label === 'PARQUE 0 TPV' )
                  return graphObj.dataset.data[graphObj.dataIndex] > 0
                else
                  return false
            },
            formatter: function(value, context) {
              return value + '%';
           },
            font: {
              size: 12,
              weight: 'bold'
            },
            position: 'bottom',
            align: 'center',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle : true,
          fontColor: 'black',
        },
      },
    }
    obj.forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.date))
        graphObj.data.datasets[0].data.push((element.active*100).toFixed(1))
        graphObj.data.datasets[1].data.push((element.tpv*100).toFixed(1))
        graphObj.data.datasets[2].data.push((element.cicloGood*100).toFixed(1))
        graphObj.data.datasets[3].data.push((element.cicloBad*100).toFixed(1))
        graphObj.data.datasets[4].data.push((element.obsoleto*100).toFixed(1))
      }
    )

    this.setState({
      cadeia:{
        graph: graphObj,
        loaded: true
      },
    })
  }


  buildHCGrahp(obj) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Green Angels + Especialistas',
            stack: 'Stack 0',
            backgroundColor: '#008a4a',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
            
          },
          {
            label: 'Distrital + Regional',
            stack: 'Stack 0',
            backgroundColor: '#93c83e',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
            
          },
          {
            label: 'Liga',
            stack: 'Stack 0',
            backgroundColor: '#ffd966',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
            
          },
          {
            label: 'Maker Angel',
            stack: 'Stack 0',
            backgroundColor: '#9dc3e6',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
            
          },
          {
            label: 'Trucker Angel',
            stack: 'Stack 0',
            backgroundColor: '#f4b183',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
            
          },
          {
            label: 'Total',
            stack: 'Stack 0',
            backgroundColor: 'white',
            borderColor: 'white',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
            
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'B',
            display:false,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
          {
            id: 'A',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'HC Operações',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            position: 'center',
            align: 'center',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle : true,
          fontColor: 'black',
        },
      },
    }
    obj.forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.date))
        graphObj.data.datasets[0].data.push(element.greenspec)
        graphObj.data.datasets[1].data.push(element.distreg)
        graphObj.data.datasets[2].data.push(element.liga)
        graphObj.data.datasets[3].data.push(element.maker)
        graphObj.data.datasets[4].data.push(element.trucker)
        graphObj.data.datasets[5].data.push(element.greenspec+element.distreg+element.liga+element.maker+element.trucker)
      }
    )

    this.setState({
      hcs:{
        graph: graphObj,
        loaded: true
      },
    })
  }

  buildSecurityGrahp(obj,day) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          
          {
            label: 'QTD ACIDENTES',
            stack: 'Stack 0',
            type : 'line',
            backgroundColor: 'white',
            borderColor: 'red',
            pointBorderColor: 'red',
            pointBackgroundColor: 'red',
            pointHoverBackgroundColor: 'red',
            pointHoverBorderColor: 'red',
            pointRadius : 0,
            data: [],
            yAxisID: 'B',
            fill: false,
            fullWidth: false,
            boxWidth: 1,
            display:false,
            pointStyle: 'line',
            
          },
          {
            label: 'TAXA ACIDENTES',
            stack: 'Stack 0',
            backgroundColor: '#008a4a',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect',
            
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'B',
            display:false,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
          {
            id: 'A',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'Segurança',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            position: 'top',
            align: 'bottom',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle : true,
          fontColor: 'black',
        },
      },
    }
    obj.forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.quarter))
        graphObj.data.datasets[1].data.push((element.taxa).toFixed(2))
        graphObj.data.datasets[0].data.push(element.qtdacidentes)
      }
    )

    day.forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.day))
        graphObj.data.datasets[1].data.push((element.taxa).toFixed(2))
        graphObj.data.datasets[0].data.push(element.qtdacidentes)
      }
    )

    this.setState({
      seguranca:{
        graph: graphObj,
        loaded: true
      },
    })
  }

  renderAtendimento(){

    return(
      <CustomizedExpansionPannel
        title="Atendimento"
        content={ 
          this.state.prazo.loaded && this.state.qualidade.loaded?               
          <div>
            <Row>
              <Col lg xl = "6" xs sm md = "12">  
                <Graph
                  data={this.state.prazo.graph.data}
                  legend={this.state.prazo.graph.legend}
                  options={this.state.prazo .graph.options}
                  height={450}
                  />
              </Col>
              <Col lg xl = "6" xs sm md = "12">  
                <Graph
                  data={this.state.qualidade.graph.data}
                  legend={this.state.qualidade.graph.legend}
                  options={this.state.qualidade.graph.options}
                  height={450}
                  />
              </Col>
            </Row>
          </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
          }
          
          helpMessage={
            <p>
              <b>Prazo</b>
                <p>TMA – Tempo Médio de Atendimento em dias úteis. Considera apenas OSs de Instalação e Troca.</p>
                <p>SLA – Visão cliente: Prazo é perdido mesmo que haja ineficiência e/ou indisponibilidade do cliente.</p>

              <b>Qualidade</b>
                <p>CSAT – Resposta aos e-mails após atendimentos com notas 4 ou 5 dentre o total de respostas.</p>
                <p>% Angels – Percentual de OSs da Carteira Polos realizadas por Angels. Redirecet e Terceira, inclusive área de risco, são considerados como não conforme</p>
            </p>}
            tooltip={true}
      />
    )
  }

  renderEficiencia(){

    return(
      <CustomizedExpansionPannel
        title="Eficiência"
        content={ 
          this.state.produtividade.loaded && 
          this.state.cadeia.loaded?                             
          <div>
            <Row>
              <Col lg xl = "6" xs sm md = "12">  
                <Graph
                  data={this.state.produtividade.graph.data}
                  legend={this.state.produtividade.graph.legend}
                  options={this.state.produtividade.graph.options}
                  height={450}
                  />
              </Col>
              <Col lg xl = "6" xs sm md = "12">  
                <Graph
                  data={this.state.cadeia.graph.data}
                  legend={this.state.cadeia.graph.legend}
                  options={this.state.cadeia.graph.options}
                  height={450}
                  />
              </Col>
            </Row>
          </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
          }
          
          helpMessage={
            <p>
              <b>Produtividade</b>
                <p>Faixa de Produtividade – Quantidade de OSs realizadas por Angels por Dias Úteis</p>
                
              <b>Cadeia</b>
                <p>Etapas da Cadeia - Evolução Histórica</p>
            </p>}
            tooltip={true}
      />
    )
  }

  renderSustentabilidade(){

    return(
      <CustomizedExpansionPannel
        title="Sustentabilidade"
        content={ 
          this.state.seguranca.loaded && this.state.hcs.loaded?                          
          <div>
            <Row>
              <Col lg xl = "6" xs sm md = "12">  
                <Graph
                  data={this.state.hcs.graph.data}
                  legend={this.state.hcs.graph.legend}
                  options={this.state.hcs.graph.options}
                  height={450}
                  />
              </Col>
              <Col lg xl = "6" xs sm md = "12">  
                <Graph
                  data={this.state.seguranca.graph.data}
                  legend={this.state.seguranca.graph.legend}
                  options={this.state.seguranca.graph.options}
                  height={450}
                  />
              </Col>
            </Row>
          </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
          }
          
          helpMessage={
            <p>
              <b>HC</b>
                <p>Evolução Histórica do volume de pessoas por função</p>
                <p>Especialistas – São os Green Angels com formação especial: Guardian, Supplier e Host.</p>
                
              <b>Segurança</b>
                <p>Acidentes – Quantidade de acidentes com afastamento.</p>
                <p>Taxa – Quantidade de acidentes com afastamento / 10.000 OSs.</p>
            </p>}
            tooltip={true}
      />
    )
  }
  // Render
  render() {
    let refreshContent
    refreshContent = (
      <div>
        <div style={{textAlign: 'right'}}>
          <RefreshButton onClick={() => { this.refreshReportData() }}/>
        </div>
      </div>
    )
    return (
      <Container fluid>
          {this.state.prazo.loaded && this.state.qualidade.loaded && this.state.cadeia.loaded && this.state.produtividade.loaded && this.state.hcs.loaded && this.state.seguranca.loaded?refreshContent:''}
          <div style={{color: 'black'}}>
            <Row>
              <Col xl sm md lg xl = {12}>
                {this.renderAtendimento()}
              </Col>
            </Row>
            <Row>
              <Col xl sm md lg xl = {12}>
                {this.renderEficiencia()}
              </Col>
            </Row>
            <Row>
              <Col xl sm md lg xl = {12}>
                {this.renderSustentabilidade()}
              </Col>
            </Row>
          </div>
    </Container>
    )
  }
}

export default DashLog
