import React from "react";
import LogisticService from "../../core/services/LogisticService";
import XLSX from "xlsx";
import MaterialTable from 'material-table';

export class TreeList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            filtros: props.filtros,
            cols: [
                { title: 'Id', field: 'id', editable: 'never' },
                { title: 'Tema', field: 'tema' },
                { title: 'Categoria', field: 'categoria' },
                { title: 'Assunto', field: 'assunto' },
                { title: 'Ativo', field: 'ativo', type: 'boolean' },
                { title: 'Date', field: 'date', editable: 'never', type: 'date' }
            ]
        };

        this.exportFile = this.exportFile.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({ data: props.data, filtros: props.filtros });
    }

    exportFile() {
        let organized_data = [];

        let i = 0;

        if (this.state.data) {
            this.state.data.forEach(function (row) {
                let index_row = [];
                organized_data.push(index_row);
                for (var ob in row) {
                    if (i == 0) {
                        index_row.push(ob);
                        continue
                    }
                    index_row.push(row[ob])
                }
                i++
            });

            organized_data[0].pop()
        } else {
            organized_data = ['tema', 'categoria', 'assunto']
        }

        const ws = XLSX.utils.aoa_to_sheet(organized_data);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        XLSX.writeFile(wb, "sheetjs.xlsx");
    }

    render() {
        return (
            <div hidden={!this.state.data.length} style={{ 'marginLeft': '10px' }}>
                <MaterialTable
                    title="Árvores da Liga"
                    style={{ padding: '0 16px', marginBottom: '24px', zIndex: 0 }}
                    columns={this.state.cols}
                    data={this.state.data}
                    options={{
                        showTitle: false,
                        exportAllData: true,
                        exportButton: true,
                        pageSize: 20
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                        },
                        header: {
                            actions: 'Ações'
                        },
                        body: {
                            emptyDataSourceMessage: 'Nenhuma árvore encontrada',
                            filterRow: {
                                filterTooltip: 'Filter'
                            },
                        }
                    }}
                    editable={{
                        onRowUpdate: (newData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    LogisticService.postLigaTree(newData).then(response => {
                                        LogisticService.getLigaTree(this.state.filtros).then(response => {
                                            this.setState({
                                                data: response.data.data
                                            })
                                            window.alert('Árvore editada com sucesso!');
                                        });
                                    })
                                }, 600);
                            }),

                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    LogisticService.deleteLigaTree(oldData.id).then(res => {
                                        LogisticService.getLigaTree(this.state.filtros).then(response => {
                                            this.setState({
                                                data: response.data.data
                                            })
                                            window.alert('Árvore apagada com sucesso!');
                                        });
                                    })
                                }, 600);
                            }),
                    }} />
            </div>

        );
    }
}

