import React from "react";
import LogisticService from "../../core/services/LogisticService";
import XLSX from "xlsx";
import MaterialTable from 'material-table';
import {Alert, Button} from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";

export class ListMembers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            sucess: false,
            loading: false,
            changedData: [],
            success_with_errors: false,
            error_list: [],
            data: props.data,
            cols:  [
                { title: 'Nome', field: 'memberName'},
                { title: 'Estado', field: 'country' },
                { title: 'Função Atual', field: 'currentFunction'},
                { title: 'Status', field: 'status'},
                { title: 'Data de Nascimento', field: 'birthDate'},
                { title: 'CPF', field: 'cpf'},
                { title: 'gênero', field: 'gender'},
                { title: 'Data de Admissão', field: 'admissionDate'},
                { title: 'Email Corporativo', field: 'corpMail'},
                { title: 'Recrutador', field: 'recruiter'},
                { title: 'Check Validador', field: 'finalCheck'},
                { title: 'Unidade Administrativa', field: 'adminUtd'},
                { title: 'Assunto', field: 'topic'}
            ]
        };

        this.exportFile = this.exportFile.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({data: props.data});
    }

    exportFile() {
        /* convert state to workbook */

        let organized_data = [];

        let i = 0;

        if (this.state.data) {
            this.state.data.forEach(function (row) {
                let index_row = [];
                organized_data.push(index_row);
                for (var ob in row) {
                    if (i == 0) {
                        index_row.push(ob);
                        continue
                    }
                    index_row.push(row[ob])
                }
                i++
            });

            organized_data[0].pop()
        } else {
            organized_data = ['emergencialLeadtime', 'leadtime', 'provider']
        }

        const ws = XLSX.utils.aoa_to_sheet(organized_data);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, "sheetjs.xlsx");
    }

    saveMultipleExpeditions = () => {
        this.setState({
            loading: true,
        });

        LogisticService.postLeatime({'members_list': this.state.changedData}).then((response) => {

            if (response.data.success_with_errors) {
                this.setState({
                    success_with_errors: true,
                    loading: false,
                    error_list: response.data.ids
                })
            } else {
                this.setState({
                    loading: false,
                    sucess: true,
                });
            }
        }, (reason => {
            this.setState({
                loading: false,
                error: true
            });
        }));
    };

    render() {
        return (
            <div hidden={!this.state.data.length} style={{'marginLeft': '10px'}}>
                <Alert hidden={!this.state.success_with_errors} variant="warning">
                    <Alert.Heading>Boa!</Alert.Heading>
                    <p>
                        Leadtimes cadastrados com sucesso, porém algumas leadtimes não estão com campos válidos!
                        Segue os id's:
                    </p>
                    {this.state.error_list.map(item => (
                        <p>{item}</p>
                    ))}
                    <Button onClick={() => this.setState({success_with_errors: false})} variant="outline-success">
                        Ok!
                    </Button>
                </Alert>
                <Alert hidden={!this.state.sucess} variant="success">
                    <Alert.Heading>Boa!</Alert.Heading>
                    <p>
                        Leadtimes cadastrados com sucesso!
                    </p>
                    <Button onClick={() => this.setState({sucess: false})} variant="outline-success">
                        Ok!
                    </Button>
                </Alert>
                <Alert hidden={!this.state.error} variant="danger">
                    <Alert.Heading>Ocorreu um erro! ):</Alert.Heading>
                    <p>
                        Algo de errado ocorreu! Tente novamente mais tarde!
                    </p>
                    <Button onClick={() => this.setState({error: false})} variant="outline-danger">
                        Ok :/
                    </Button>
                </Alert>
                <div hidden={!this.state.loading} style={{ width: 'center', textAlign: 'center' }}>
                    <LoadingSpinner/>
                </div>
                <button
                    disabled={!this.state.data.length}
                    className="btn btn-success"
                    onClick={this.exportFile}
                    style={{'marginLeft': '10px', 'marginBottom': '10px'}}
                >
                    Export
                </button>
                <MaterialTable
                    title="Leadtimes"
                    columns={this.state.cols}
                    data={this.state.data}
                    options={{
                        rowStyle: rowData => ({
                            backgroundColor: (rowData.modificationDate > rowData.solicitationDate && rowData.omNumber === '0') ? '#ffb1ab' : '#FFF'
                        }),
                        exportButton: true,
                        pageSize: 5
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                        },
                        header: {
                            actions: 'Ações'
                        },
                        body: {
                            emptyDataSourceMessage: 'Nenhumo leadtime encontrado',
                            filterRow: {
                                filterTooltip: 'Filter'
                            },
                            editRow: {
                                deleteText: 'Tem certeza que deseja deletar?'
                            }
                        }
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    let data = this.state.data;
                                    let changedData = this.state.changedData;
                                    data[data.indexOf(oldData)] = newData;
                                    changedData.push(newData);
                                    this.setState({data: data, changedData: changedData})
                                    LogisticService.postLeatime({'members_list': [newData]}).then(response => {
                                        this.setState({ data: data });
                                    })
                                }, 600);
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    const data = this.state.data;
                                    data.splice(data.indexOf(oldData), 1);
                                    this.setState({ data: data });
                                    LogisticService.deleteLeadtime(oldData.id).then(response => {
                                        this.setState({ data: data });
                                    })
                                }, 600);
                            }),
                    }}/>
            </div>

        );
    }
}
