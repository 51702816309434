import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import CustomizedTable from '../../custom-components/CustomizedTable';
import './style.css'
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#103a21",
    color: "white",
    fontSize: "0.9em",
    textalign: "center",
  },
  body: {
    fontSize: "0.13em",
    textalign: "center",
  },
  }))(TableCell);
  

function SummaryIdt(props) {

    let content = props.information
    
    function getClassNameForStatus (status) {
        if (status > 10) return <span class="redDot"></span>
        if (status >= 0) return <span class="yellowDot"></span>
        if (status < 0) return <span class="greenDot"></span>
        return <span class="grayDot"></span>  
      }
      let screeningPolo = []
      let screeningSB = []
      let screeningCD = []
    if(props.screening){
      props.screening.data.filter(item => item.location === 'POLO').forEach((item) => screeningPolo.push(<StyledTableCell>{(item.idr*100).toFixed(2)}</StyledTableCell>))
      props.screening.data.filter(item => item.location === 'STONE BOX').forEach((item) => screeningSB.push(<StyledTableCell>{(item.idr*100).toFixed(2)}</StyledTableCell>))
      props.screening.data.filter(item => item.location === 'CD').forEach((item) => screeningCD.push(<StyledTableCell>{(item.idr*100).toFixed(2)}</StyledTableCell>))
    }
    
    let rowsSummary     = []
    let idt             = []
    let idr             = []
    let reconheceCartao = []
    let bateria         = []
    let tamper          = []
    let falhaConexao    = []
    let danificado      = []
    let tavadoTela      = []
    let desligaFinal    = []
    let perdaCarregador = []
    let touchDefeito    = []
    let outros          = []
    let spare           = []
    let trocaBateria    = []
    let trocaTampa      = []
    let trocaCarregador = []
    let idm             = []
    let preferencia     = []

    let idtRow              = []
    let idrRow              = []
    let reconheceCartaoRow  = []
    let bateriaRow          = []
    let tamperRow           = []
    let falhaConexaoRow     = []
    let danificadoRow       = []
    let tavadoTelaRow       = []
    let desligaFinalRow     = []
    let perdaCarregadorRow  = []
    let touchDefeitoRow     = []
    let outrosRow           = []
    let spareRow            = []
    let trocaBateriaRow     = []
    let trocaTampaRow       = []
    let trocaCarregadorRow  = []
    let idmRow              = []
    let preferenciaRow      = []

    let idtDelta              = []
    let idrDelta              = []
    let reconheceCartaoDelta  = []
    let bateriaDelta         = []
    let tamperDelta          = []
    let falhaConexaoDelta    = []
    let danificadoDelta      = []
    let tavadoTelaDelta      = []
    let desligaFinalDelta    = []
    let perdaCarregadorDelta = []
    let touchDefeitoDelta    = []
    let outrosDelta          = []
    let spareDelta           = []
    let trocaBateriaDelta    = []
    let trocaTampaDelta      = []
    let trocaCarregadorDelta = []
    let idmDelta             = []
    let preferenciaDelta     = []
    
    content.data.forEach((item)=>{
        idt.push(((item.idt/item.activeTotal)*100).toFixed(2))  
        idr.push((((item.idt/item.activeTotal)+(item.change/item.activeTotal)+(item.chip/item.activeTotal)+(item.sparepart/item.activeTotal))*100).toFixed(2))  
        reconheceCartao.push(((item.reconhececartao/item.activeTotal)*100).toFixed(2))      
        bateria.push(((item.bateria/item.activeTotal)*100).toFixed(2))  
        tamper.push(((item.tamper/item.activeTotal)*100).toFixed(2))  
        falhaConexao.push(((item.falhaconexao/item.activeTotal)*100).toFixed(2))  
        danificado.push(((item.danificado/item.activeTotal)*100).toFixed(2))  
        tavadoTela.push(((item.tavadotela/item.activeTotal)*100).toFixed(2))  
        desligaFinal.push(((item.desligafinal/item.activeTotal)*100).toFixed(2))  
        perdaCarregador.push(((item.perdacarregador/item.activeTotal)*100).toFixed(2))  
        touchDefeito.push(((item.touchdefeito/item.activeTotal)*100).toFixed(2))  
        outros.push(((item.outro/item.activeTotal)*100).toFixed(2))  
        spare.push(((item.sparepart/item.activeTotal)*100).toFixed(2)) 
        trocaBateria.push(((item.trocabateria/item.activeTotal)*100).toFixed(2)) 
        trocaTampa.push(((item.bobina/item.activeTotal)*100).toFixed(2)) 
        trocaCarregador.push(((item.carregador/item.activeTotal)*100).toFixed(2))
        idm.push(((item.chip/item.activeTotal)*100).toFixed(2)) 
        preferencia.push(((item.change/item.activeTotal)*100).toFixed(2))
    })

    idt.forEach((item)=>{idtRow.push(<StyledTableCell>{item}</StyledTableCell>)})           
    idr.forEach((item)=>{idrRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    reconheceCartao.forEach((item)=>{reconheceCartaoRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    bateria.forEach((item)=>{bateriaRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    tamper.forEach((item)=>{tamperRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    falhaConexao.forEach((item)=>{falhaConexaoRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    danificado.forEach((item)=>{danificadoRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    tavadoTela.forEach((item)=>{tavadoTelaRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    desligaFinal.forEach((item)=>{desligaFinalRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    perdaCarregador.forEach((item)=>{perdaCarregadorRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    touchDefeito.forEach((item)=>{touchDefeitoRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    outros.forEach((item)=>{outrosRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    spare.forEach((item)=>{spareRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    trocaBateria.forEach((item)=>{trocaBateriaRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    trocaTampa.forEach((item)=>{trocaTampaRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    trocaCarregador.forEach((item)=>{trocaCarregadorRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    idm.forEach((item)=>{idmRow.push(<StyledTableCell>{item}</StyledTableCell>)})
    preferencia.forEach((item)=>{preferenciaRow.push(<StyledTableCell>{item}</StyledTableCell>)})

    idrDelta.push(((parseFloat(idr[3])-(parseFloat(idr[0])+parseFloat(idr[1])+parseFloat(idr[2]))/3)).toFixed(2))
    idtDelta.push(((parseFloat(idt[3])-(parseFloat(idt[0])+parseFloat(idt[1])+parseFloat(idt[2]))/3)).toFixed(2))
    reconheceCartaoDelta.push(((parseFloat(reconheceCartao[3])-(parseFloat(reconheceCartao[0])+parseFloat(reconheceCartao[1])+parseFloat(reconheceCartao[2]))/3)).toFixed(2))
    bateriaDelta.push(((parseFloat(bateria[3])-(parseFloat(bateria[0])+parseFloat(bateria[1])+parseFloat(bateria[2]))/3)).toFixed(2))
    tamperDelta.push(((parseFloat(tamper[3])-(parseFloat(tamper[0])+parseFloat(tamper[1])+parseFloat(tamper[2]))/3)).toFixed(2))
    falhaConexaoDelta.push(((parseFloat(falhaConexao[3])-(parseFloat(falhaConexao[0])+parseFloat(falhaConexao[1])+parseFloat(falhaConexao[2]))/3)).toFixed(2))
    danificadoDelta.push(((parseFloat(danificado[3])-(parseFloat(danificado[0])+parseFloat(danificado[1])+parseFloat(danificado[2]))/3)).toFixed(2))
    tavadoTelaDelta.push(((parseFloat(tavadoTela[3])-(parseFloat(tavadoTela[0])+parseFloat(tavadoTela[1])+parseFloat(tavadoTela[2]))/3)).toFixed(2))
    desligaFinalDelta.push(((parseFloat(desligaFinal[3])-(parseFloat(desligaFinal[0])+parseFloat(desligaFinal[1])+parseFloat(desligaFinal[2]))/3)).toFixed(2))
    perdaCarregadorDelta.push(((parseFloat(perdaCarregador[3])-(parseFloat(perdaCarregador[0])+parseFloat(perdaCarregador[1])+parseFloat(perdaCarregador[2]))/3)).toFixed(2))
    touchDefeitoDelta.push(((parseFloat(touchDefeito[3])-(parseFloat(touchDefeito[0])+parseFloat(touchDefeito[1])+parseFloat(touchDefeito[2]))/3)).toFixed(2))
    outrosDelta.push(((parseFloat(outros[3])-(parseFloat(outros[0])+parseFloat(outros[1])+parseFloat(outros[2]))/3)).toFixed(2))
    spareDelta.push(((parseFloat(spare[3])-(parseFloat(spare[0])+parseFloat(spare[1])+parseFloat(spare[2]))/3)).toFixed(2))
    trocaBateriaDelta.push(((parseFloat(trocaBateria[3])-(parseFloat(trocaBateria[0])+parseFloat(trocaBateria[1])+parseFloat(trocaBateria[2]))/3)).toFixed(2))
    trocaTampaDelta.push(((parseFloat(trocaTampa[3])-(parseFloat(trocaTampa[0])+parseFloat(trocaTampa[1])+parseFloat(trocaTampa[2]))/3)).toFixed(2))
    trocaCarregadorDelta.push(((parseFloat(trocaCarregador[3])-(parseFloat(trocaCarregador[0])+parseFloat(trocaCarregador[1])+parseFloat(trocaCarregador[2]))/3)).toFixed(2))
    idmDelta.push(((parseFloat(idm[3])-(parseFloat(idm[0])+parseFloat(idm[1])+parseFloat(idm[2]))/3)).toFixed(2))
    preferenciaDelta.push(((parseFloat(preferencia[3])-(parseFloat(preferencia[0])+parseFloat(preferencia[1])+parseFloat(preferencia[2]))/3)).toFixed(2))
    rowsSummary.push(
        [
            <TableRow className="RowUpper">
            <StyledTableCell>IDR</StyledTableCell>
            {idrRow}
            <StyledTableCell>{idrDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(idrDelta))}</StyledTableCell>
            </TableRow>,
            props.screening?
            <TableRow className="RowUpper" style={{ backgroundColor: '#99bc95'}}>
            <StyledTableCell>IDR Triagem</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            </TableRow>:undefined,
            props.screening?
            //Screening
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>Triado Polo</StyledTableCell>
            {screeningPolo}
            <StyledTableCell>{((props.screening.data.filter(item => item.location === 'POLO')[3].idr - (props.screening.data.filter(item => item.location === 'POLO')[0].idr
                              + props.screening.data.filter(item => item.location === 'POLO')[1].idr
                              +props.screening.data.filter(item => item.location  === 'POLO')[2].idr)/3)).toFixed(2) }</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(((props.screening.data.filter(item => item.location === 'POLO')[3].idr - (props.screening.data.filter(item => item.location === 'POLO')[0].idr
                              + props.screening.data.filter(item => item.location === 'POLO')[1].idr
                              +props.screening.data.filter(item => item.location  === 'POLO')[2].idr)/3)).toFixed(2)))}</StyledTableCell>
            </TableRow>:undefined,
            props.screening?
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>Triado CD</StyledTableCell>
            {screeningCD} 
            <StyledTableCell>{((props.screening.data.filter(item => item.location  === 'CD')[3].idr - (props.screening.data.filter(item => item.location === 'CD')[0].idr
                              + props.screening.data.filter(item => item.location  === 'CD')[1].idr
                              +props.screening.data.filter(item => item.location   === 'CD')[2].idr)/3)).toFixed(2) }</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(((props.screening.data.filter(item => item.location  === 'CD')[3].idr - (props.screening.data.filter(item => item.location === 'CD')[0].idr
                              + props.screening.data.filter(item => item.location  === 'CD')[1].idr
                              +props.screening.data.filter(item => item.location   === 'CD')[2].idr)/3)).toFixed(2)))}</StyledTableCell>
            </TableRow>:undefined,
            props.screening?
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>Triado Stone Box</StyledTableCell>
            {screeningSB}
            <StyledTableCell>{((props.screening.data.filter(item => item.location === 'STONE BOX')[3].idr - (props.screening.data.filter(item => item.location === 'STONE BOX')[0].idr
                              + props.screening.data.filter(item => item.location === 'STONE BOX')[1].idr
                              +props.screening.data.filter(item => item.location  === 'STONE BOX')[2].idr)/3)).toFixed(2) }</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(((props.screening.data.filter(item => item.location === 'STONE BOX')[3].idr - (props.screening.data.filter(item => item.location === 'STONE BOX')[0].idr
                              + props.screening.data.filter(item => item.location === 'STONE BOX')[1].idr
                              +props.screening.data.filter(item => item.location  === 'STONE BOX')[2].idr)/3)).toFixed(2)))}</StyledTableCell>
            </TableRow>:undefined,
            //
            <TableRow className="RowUpper" style={{ backgroundColor: '#99bc95'}}>
            <StyledTableCell>IDT</StyledTableCell>
            {idtRow}
            <StyledTableCell>{idtDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(idtDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>(POS) NÃO RECONHECE NENHUM CARTÃO</StyledTableCell>
            {reconheceCartaoRow}
            <StyledTableCell>{reconheceCartaoDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(reconheceCartaoDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>(POS) BATERIA</StyledTableCell>
            {bateriaRow}
            <StyledTableCell>{bateriaDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(bateriaDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>(POS) TAMPER (LLT OU ALERT INRUPTION OU NAND FAIL)</StyledTableCell>
            {tamperRow}
            <StyledTableCell>{tamperDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(tamperDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>(POS) FALHA PERMANENTE DE CONEXÃO 3G/GPRS</StyledTableCell>
            {falhaConexaoRow}
            <StyledTableCell>{falhaConexaoDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(falhaConexaoDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>DANIFICADO PELO CLIENTE</StyledTableCell>
            {danificadoRow}
            <StyledTableCell>{danificadoDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(danificadoDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>(POS) TRAVADO EM TELA ESPECÍFICA</StyledTableCell>
            {tavadoTelaRow}
            <StyledTableCell>{tavadoTelaDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(tavadoTelaDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>DESLIGA NO FINAL DA TRANSAÇÃO</StyledTableCell>
            {desligaFinalRow}
            <StyledTableCell>{desligaFinalDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(desligaFinalDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>(POS) PROBLEMAS/PERDA DO CARREGADOR</StyledTableCell>
            {perdaCarregadorRow}
            <StyledTableCell>{perdaCarregadorDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(perdaCarregadorDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>(POS) TOUCH COM DEFEITO</StyledTableCell>
            {touchDefeitoRow}
            <StyledTableCell> {touchDefeitoDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(touchDefeitoDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>Outros</StyledTableCell>
            {outrosRow}
            <StyledTableCell>{outrosDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(outrosDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowUpper" style={{ backgroundColor: '#99bc95'}}>
            <StyledTableCell>SPARE PARTS</StyledTableCell>
            {spareRow}
            <StyledTableCell>{spareDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(spareDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>TROCA DE BATERIA</StyledTableCell>
            {trocaBateriaRow}
            <StyledTableCell>{trocaBateriaDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(trocaBateriaDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>TROCA DA TAMPA(BOBINA)</StyledTableCell>
            {trocaTampaRow}
            <StyledTableCell>{trocaTampaDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(trocaTampaDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowDatas" style={{ backgroundColor: 'white'}}>
            <StyledTableCell>TROCA DE CARREGADOR</StyledTableCell>
            {trocaCarregadorRow}
            <StyledTableCell>{trocaCarregadorDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(trocaCarregadorDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowUpper" style={{ backgroundColor: '#99bc95'}}>
            <StyledTableCell>IDM</StyledTableCell>
            {idmRow}
            <StyledTableCell>{idmDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(idmDelta))}</StyledTableCell>
            </TableRow>,
            <TableRow className="RowUpper" style={{ backgroundColor: '#99bc95'}}>
            <StyledTableCell>PREFERÊNCIA POR OUTRO </StyledTableCell>
            {preferenciaRow}
            <StyledTableCell>{preferenciaDelta}</StyledTableCell>
            <StyledTableCell>{getClassNameForStatus(parseFloat(preferenciaDelta))}</StyledTableCell>
            </TableRow>
        ]
        )

    //Title
    let titleSummary = [{name:"" }]
    //Sub Titles
    let subTitleSummary = [
    {name:"", icon:""},
    {name:content.data[0] === undefined?'':content.data[0].month, icon:""},
    {name:content.data[1] === undefined?'':content.data[1].month, icon:""},
    {name:content.data[2] === undefined?'':content.data[2].month, icon:""},
    {name:content.data[3] === undefined?'':content.data[3].month, icon:""},
    {name:"Δ%", icon:""},
    {name:"", icon:""}
    ]

    return (             
          <Box width={window.innerWidth < 1300 ? "100%" : "100%"} mx="auto">
            <Paper style={{ width: "85%", overflowX: "auto", marginTop: "1em", marginBottom: "1em" }}>
              <CustomizedTable
              HeadColor = "#103a21"
              fontColor = "white"
              rows ={rowsSummary}
              title ={titleSummary}
              subTitle ={subTitleSummary}
              colSpan ={"7"}
              />    
            </Paper>
          </Box>
        )
}
export default SummaryIdt