
import React from 'react'
import DownloadButton from '../DownloadButton';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportCSV = ({csvData, fileName}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        ws["!margins"]={left:0.7, right:0.7, top:0.75,bottom:0.75,header:0.3,footer:0.3}
        ws["!cols"]= [{ wpx:60 }, { wpx:200 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 },
        { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 },
        { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 },
        { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 },
        { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }, { wpx:100 }]
        const wb = { Sheets: { 'resultados': ws }, SheetNames: ['resultados'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <DownloadButton variant="warning" onClick={(e) => exportToCSV(csvData,fileName)}>
            Export
        </DownloadButton>
    )
}