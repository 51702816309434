import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import MaterialTable from "material-table";
import LogisticService from "../../../core/services/LogisticService";

const defaultTableLocalization = {
  toolbar: {
    searchTooltip: "Pesquisar",
    searchPlaceholder: "Pesquisar",
    exportName: "Baixar CSV",
    exportTitle: "Download",
  },
  pagination: {
    labelRowsSelect: "linhas",
    labelDisplayedRows: "{from}-{to} de {count}",
    previousAriaLabel: "Página anterior",
    previousTooltip: "Página anterior",
    nextAriaLabel: "Próxima página",
    nextTooltip: "Próxima página",
    lastAriaLabel: "Última página",
    lastTooltip: "Última página",
    firstAriaLabel: "Primeira página",
    firstTooltip: "Primeira página",
  },
  body: {
    emptyDataSourceMessage: "Nada para mostrar",
  },
};

const defaultTableOptions = {
  exportAllData: true,
  pageSize: 10,
  showTitle: false,
  padding: "dense",
  exportButton: true,
  maxBodyHeight: 500,
  filtering: true,
};

const AbasDeDetalhamento = (props) => {
  const { TabPane } = Tabs;
  const { resultado, ano, mes, nome: distrito } = props;

  const [tabOneContent, setTabOneContent] = useState(resultado);
  const [tabTwoContent, setTabTwoContent] = useState(undefined);
  const [tabThreeContent, setTabThreeContent] = useState(undefined);
  const [tabFourContent, setTabFourContent] = useState(undefined);
  const [tabFiveContent, setTabFiveContent] = useState(undefined);
  const [tabSixContent, setTabSixContent] = useState(undefined);
  const [tabSevenContent, setTabSevenContent] = useState(undefined);
  const [tabEightContent, setTabEightContent] = useState(undefined);

  useEffect(() => {
    setTabOneContent(resultado);
  }, [resultado]);

  function formatDate(date, hours) {
    const datePart = date.slice(0, 10);

    if (hours === undefined) {
      return `${datePart.split("-")[2]}/${datePart.split("-")[1]}/${
        datePart.split("-")[0]
      }`;
    }

    return `${datePart.split("-")[2]}/${datePart.split("-")[1]}/${
      datePart.split("-")[0]
    } ${date.slice(11)}`;
  }

  function callback(key) {
    if (key === "1" && tabOneContent === undefined) {
      setTabOneContent([]);
    }
    if (key === "2" && tabTwoContent === undefined) {
      LogisticService.getDistrictOrdersData(ano, mes, distrito).then(
        (response) => {
          setTabTwoContent(
            response.data.data.map((order) => ({
              orderNumber: order.orderNumber,
              city: order.city,
              customer: order.customer,
              customerName: order.customerName,
              technicianName: order.technicianName,
              provider: order.provider,
              considerInSlaAccount: order.considerInSlaAccount,
              considerInTmaAccount: order.considerInTmaAccount,
              daysToComplete: order.daysToComplete,
              solvedWithSlaOnTime: order.solvedWithSlaOnTime,
              solvedWithSlaCustomerOnTime: order.solvedWithSlaCustomerOnTime,
              considerInDzeroAccount: order.considerInDzeroAccount,
              solvedInDzero: order.solvedInDzero,
              solvedWithAnticipation: order.solvedWithAnticipation,
              redirectPolo: order.redirectPolo,
              serviceGroup: order.serviceGroup,
              status: order.status,
              cancellationReason: order.cancellationReason,
              openingDate: formatDate(order.openingDate, true),
              arrivalDate: formatDate(order.arrivalDate, true),
              deadlineDate: formatDate(order.deadlineDate, true),
              customerDeadlineDate: formatDate(
                order.customerDeadlineDate,
                true
              ),
              visitReason1: order.visitReason1,
            }))
          );
        }
      );
    }
    if (key === "3" && tabThreeContent === undefined) {
      LogisticService.getDistrictStockPhotoData(ano, mes, distrito).then(
        (response) => {
          setTabThreeContent(
            response.data.data.map((customer) => ({
              serial: customer.serial,
              status: customer.status,
              provider: customer.provider,
              district: customer.district,
              photoDate: formatDate(customer.photoDate),
            }))
          );
        }
      );
    }

    if (key === "4" && tabFourContent === undefined) {
      LogisticService.getDistrictReversesPenaltiesData(ano, mes, distrito).then(
        (response) => {
          setTabFourContent(
            response.data.data.map((penalty) => ({
              origin: penalty.origin,
              qtd: penalty.qtd,
              week: penalty.week,
            }))
          );
        }
      );
    }

    if (key === "5" && tabFiveContent === undefined) {
      LogisticService.getDistrictUninstalledData(ano, mes, distrito).then(
        (response) => {
          setTabFiveContent(
            response.data.data.map((terminal) => ({
              serialUninstalled: terminal.serialUninstalled,
              statusBefore: terminal.statusBefore,
              serviceOrderNumber: terminal.serviceOrderNumber,
              angel: terminal.technicianName,
              provider: terminal.provider,
              removalDate: formatDate(terminal.removalDate),
              weekNumber: terminal.weekNumber,
            }))
          );
        }
      );
    }

    if (key === "6" && tabSixContent === undefined) {
      LogisticService.getDistrictCSATData(ano, mes, distrito).then(
        (response) => {
          setTabSixContent(
            response.data.data.map((os) => ({
              angel: os.angel,
              hub: os.hub,
              district: os.district,
              region: os.region,
              satisfaction: os.satisfaction,
            }))
          );
        }
      );
    }

    if (key === "7" && tabSevenContent === undefined) {
      LogisticService.getDistrictAccidentsData(ano, mes, distrito).then(
        (response) => {
          setTabSevenContent(
            response.data.data.map((accident) => ({
              district: accident.district,
              angel: accident.angel,
              date: accident.date,
              type: accident.type,
            }))
          );
        }
      );
    }

    if (key === "8" && tabEightContent === undefined) {
      LogisticService.getDistrictPenaltiesData(ano, mes, distrito).then(
        (response) => {
          setTabEightContent(
            response.data.data.map((os) => {
              const date = `${os.arrivalDate.split("-")[2]}/${
                os.arrivalDate.split("-")[1]
              }/${os.arrivalDate.split("-")[0]}`;
              return {
                driver: os.driver,
                district: os.districtName,
                arrivalDate: date,
                severity: os.severity,
              };
            })
          );
        }
      );
    }
  }

  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Resumo" key="1">
        <div style={{ padding: "0px 100px" }}>
          <MaterialTable
            columns={[
              { title: "Pilar", field: "name" },
              { title: "Atingimento", field: "score" },
              { title: "Limite Máximo", field: "limit" },
            ]}
            options={{
              showTitle: false,
              search: false,
              paging: false,
              toolbar: false,
            }}
            data={
              !tabOneContent
                ? []
                : Object.values(tabOneContent).map((p) => ({
                    name: p.nome,
                    score: `${p.atingimento}%`, // resultado[p],
                    limit: `${p.indicadores
                      .filter((i) => i.tipo !== "alavanca")
                      .map((i) => parseFloat(i.peso))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(1)}%`,
                    indicadores: p.indicadores,
                  }))
            }
            detailPanel={(rowData) => (
              <div style={{ padding: "30px 0px" }}>
                <MaterialTable
                  columns={[
                    {
                      title: "Indicador",
                      field: "indicador",
                    },
                    {
                      title: "Resultado",
                      field: "resultado",
                    },
                    {
                      title: "Atingimento Pós-Régua",
                      field: "atingimentoRegua",
                    },
                    {
                      title: "Peso",
                      field: "peso",
                    },
                    {
                      title: "Impacto no Pilar",
                      field: "contribuicaoTotal",
                    },
                  ]}
                  data={rowData.indicadores.map((indicador) => ({
                    indicador: indicador.nome,
                    resultado: indicador.resultado,
                    atingimentoRegua: `${indicador.atingimento}%`,
                    peso:
                      indicador.tipo === "alavanca"
                        ? "Bônus"
                        : `${indicador.peso}%`,
                    contribuicaoTotal:
                      indicador.tipo === "alavanca"
                        ? `${indicador.atingimento}%`
                        : `${(indicador.atingimento / 100) * indicador.peso}%`,
                  }))}
                  localization={defaultTableLocalization}
                  options={{
                    ...defaultTableOptions,
                    filtering: false,
                    pageSize: 5,
                    exportButton: false,
                    search: false,
                    paging: false,
                    toolbar: false,
                  }}
                />
              </div>
            )}
          />
        </div>
      </TabPane>
      <TabPane tab="Chamados Atendidos" key="2">
        <MaterialTable
          columns={[
            {
              title: "Número da OS",
              field: "orderNumber",
              cellStyle: { fontSize: "0.8em", width: "3em" },
            },
            {
              title: "Stonecode",
              field: "customer",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Nome do Cliente",
              field: "customerName",
            },
            {
              title: "Cidade",
              field: "city",
            },
            {
              title: "Angel",
              field: "technicianName",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Polo",
              field: "provider",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Conta pro SLA",
              field: "considerInSlaAccount",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Conta pro TMA",
              field: "considerInTmaAccount",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Tempo de Atendimento (dias úteis)",
              field: "daysToComplete",
              cellStyle: { fontSize: "0.8em", width: "5em" },
            },
            {
              title: "SLA no Prazo",
              field: "solvedWithSlaOnTime",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "5em" },
            },
            {
              title: "SLA Cliente no Prazo",
              field: "solvedWithSlaCustomerOnTime",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Conta pro D0",
              field: "considerInDzeroAccount",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Resolvida em D0",
              field: "solvedInDzero",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Chamado Antecipado",
              field: "solvedWithAnticipation",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Grupo de Serviço",
              field: "serviceGroup",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Status",
              field: "status",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Motivo da Primeira Visita",
              field: "visitReason1",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Motivo de Cancelamento",
              field: "cancellationReason",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Data de Abertura",
              field: "openingDate",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Data de Atendimento",
              field: "arrivalDate",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Data Limite",
              field: "deadlineDate",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Data Limite Cliente",
              field: "customerDeadlineDate",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Redirect",
              field: "redirectPolo",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
          ]}
          data={tabTwoContent}
          title="Chamados Atendidos"
          localization={defaultTableLocalization}
          isLoading={!tabTwoContent}
          options={defaultTableOptions}
        />
      </TabPane>
      <TabPane tab="Fotos de Estoque" key="3">
        <MaterialTable
          columns={[
            {
              title: "Serial",
              field: "serial",
              cellStyle: { fontSize: "0.8em", width: "3em" },
            },
            {
              title: "Status",
              field: "status",
              cellStyle: { fontSize: "0.8em", width: "3em" },
            },
            {
              title: "Polo",
              field: "provider",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "5em" },
            },
            {
              title: "Data da Foto",
              field: "photoDate",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabThreeContent}
          isLoading={!tabThreeContent}
          title="Fotos de Estoque"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
      <TabPane tab="Reversas Penalizadas" key="4">
        <MaterialTable
          columns={[
            {
              title: "Origem",
              field: "origin",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Quantidade",
              field: "qtd",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Semana",
              field: "week",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
          ]}
          data={tabFourContent}
          isLoading={!tabFourContent}
          title="Reversas Penalizadas"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
      <TabPane tab="Entrantes no CT" key="5">
        <MaterialTable
          columns={[
            {
              title: "Número da OS",
              field: "serviceOrderNumber",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Serial",
              field: "serialUninstalled",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Angel",
              field: "angel",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Polo",
              field: "provider",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Data da Retirada",
              field: "removalDate",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Semana",
              field: "weekNumber",
              type: "numeric",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabFiveContent}
          isLoading={!tabFiveContent}
          title="Entrantes no CT"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
      <TabPane tab="CSAT" key="6">
        <MaterialTable
          columns={[
            {
              title: "Angel",
              field: "angel",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Polo",
              field: "hub",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Regional",
              field: "region",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Satisfação",
              field: "satisfaction",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabSixContent}
          isLoading={!tabSixContent}
          title="CSAT"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
      <TabPane tab="Acidentes" key="7">
        <MaterialTable
          columns={[
            {
              title: "Tipo",
              field: "type",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Data",
              field: "date",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Angel",
              field: "angel",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabSevenContent}
          isLoading={!tabSevenContent}
          title="Acidentes"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
      <TabPane tab="Multas" key="8">
        <MaterialTable
          columns={[
            {
              title: "Motorista",
              field: "driver",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Data de Recimento",
              field: "arrivalDate",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Gravidade",
              field: "severity",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabEightContent}
          isLoading={!tabEightContent}
          title="Multas"
          localization={defaultTableLocalization}
          options={defaultTableOptions}
        />
      </TabPane>
    </Tabs>
  );
};

export default AbasDeDetalhamento;
