import React from "react";
import { Table } from "antd";

const SortingTable = (props) => {
  return (
    <div style={{ marginTop: "1.5em" }}>
      <p style={{ color: "black", fontSize: "18px" }}>{props.title}</p>
      <Table
        size="medium"
        columns={props.columns}
        scroll={{ x: props.scroll }}
        dataSource={props.data}
        pagination={{ hideOnSinglePage: true, pageSize: 5 }}
      />
    </div>
  );
};

export default SortingTable;
