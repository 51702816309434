/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import style from "./style.css";
import Graph from "../../components/Logistic/graphic/graph";
import LogisticService from "../../core/services/LogisticService";
import RefreshButton from "../../custom-components/RefreshButton";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { Container, Col, Row } from "react-bootstrap";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CustomizedExpansionPannel from "../../custom-components/CustomizedExpansionPanel/index";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import LoadingSpinner from "../../components/LoadingSpinner";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#103a21",
    color: "white",
    fontSize: "0.9em",
  },
  body: {
    fontSize: "0.8em",
  },
}))(TableCell);

class Hub extends Component {
  static getClassNameForStatus(status) {
    if (status === "red") return "redDot";
    if (status === "yellow") return "yellowDot";
    if (status === "green") return "greenDot";
    return "grayDot";
  }

  constructor() {
    super();
    this.state = {
      today: moment().format("YYYY-MM-DD"),
      hubName: "",
      loaded: false,
      tableHub: undefined,
      tableAngels: undefined,
      tableServiceOrders: undefined,
      tableStockSituation: undefined,
      lastUpdated: undefined,
      modalIsOpen: false,
      dataModel: undefined,
      machineConsumption: {
        graph: undefined,
        loaded: false,
        tableMachineConsumption: undefined,
      },
      tableIncomingMovementOrders: undefined,
    };
  }

  componentDidMount() {
    this.refreshReportData();
  }

  refreshReportData() {
    let { name } = this.props.match.params;

    this.setState({
      hubName: name,
      loaded: false,
      tableHub: undefined,
      lastUpdated: undefined,
      lastStock: undefined,
    });

    let { today } = this.state;

    //Requisição para o gráfico de histórico de consumo dos terminais

    LogisticService.getMachineConsumptionByHub(name).then((response) => {
      this.buildMachineConsumptionChart(response.data);
    });

    //Requisição para ver as estatísticas do dia para cada angel do polo +
    //Requisição para ver as estatísticas do dia para o polo +
    //Requisição para ver as OSs pendentes
    LogisticService.getAngelsByHub(name, today).then((response) => {
      LogisticService.getStockSituation(name).then((stock_response) => {
        let tableAngels = this.buildTableAngels(response.data.data.angels);
        let tableServiceOrders = this.buildTableServiceOrders(
          response.data.data.hub
        );
        let tableHub = this.buildTableHub(response.data.data.hub);

        this.setState({
          loaded: true,
          tableAngel: tableAngels,
          tableServiceOrders: tableServiceOrders,
          tableHub: tableHub,
          hubInfo: response.data.data.hub,
          lastUpdated: moment(),
        });
      });

      //Requisição para ver quantos terminais estão no polo, e a cobertura correspondente
      LogisticService.getStockSituation(name).then((stock_response) => {
        let tableStockSituation = this.buildTableStock(
          stock_response.data.data
        );

        this.setState({
          loaded: true,
          tableStockSituation: tableStockSituation,
          lastUpdated: moment(),
        });
      });

      //Requisição para ordens de movimentação a caminho da base
      LogisticService.getIncomingOrders(name).then((oms_response) => {
        let tableIncomingMovementOrders = this.buildTableMovementOrders(
          oms_response.data
        );

        this.setState({
          loaded: true,
          tableIncomingMovementOrders: tableIncomingMovementOrders,
          lastUpdated: moment(),
        });
      });
    });
  }

  //Construtor do gráfico de histórico de consumo
  buildMachineConsumptionChart(response) {
    const installation = 0;
    const swap = 1;
    const graphObj = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Instalação",
            stack: "Stack 0",
            backgroundColor: "#38BB00",
            borderWidth: 2,
            data: [],
          },
          {
            label: "Troca",
            stack: "Stack 0",
            backgroundColor: "#DA2200",
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
              },
              ticks: {
                min: 0,
              },
            },
          ],
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        title: {
          display: true,
          fontColor: "black",
          fontSize: 20,
          text: "Consumo de Terminais",
        },
        plugins: {
          datalabels: {
            font: {
              size: 20,
            },
            color: "black",
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "black",
        },
      },
    };

    _.orderBy(response.data, "date").forEach((element) => {
      graphObj.data.labels.push(moment(element.date).format("DD/MM/YYYY"));
      graphObj.data.datasets[installation].data.push(element.installation);
      graphObj.data.datasets[swap].data.push(element.swap);
    });

    this.setState({
      machineConsumption: {
        graph: graphObj,
        loaded: true,
      },
      lastUpdated: moment(),
    });
  }

  //Construtor da tabela de estatísticas do polo
  buildTableHub(hub) {
    return (
      <Box mx="auto" width="80%" margin="1em" overflow="auto">
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Polo</StyledTableCell>
              <StyledTableCell align="center">Feitos no dia</StyledTableCell>
              <StyledTableCell align="center">
                Pendentes em atraso
              </StyledTableCell>
              <StyledTableCell align="center">Carteira do dia</StyledTableCell>
              <StyledTableCell align="center">SLA MTD</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell align="center">{hub.name}</StyledTableCell>
              <StyledTableCell align="center">
                {hub.completed_today}
              </StyledTableCell>
              <StyledTableCell align="center">
                {hub.late_orders_count}
              </StyledTableCell>
              <StyledTableCell align="center">
                {hub.due_today_count}
              </StyledTableCell>
              <StyledTableCell align="center">
                {hub.sla_mtd.onTimePct}%
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    );
  }

  //Construtor da tabela de estatísticas por angel
  buildTableAngels(angels) {
    let trs = [];

    angels.forEach((element) => {
      if (!element.technicianName.includes("REDIRECT")) {
        trs.push(
          <TableRow>
            <StyledTableCell align="center">
              {element.technicianName}
            </StyledTableCell>
            <StyledTableCell align="center">{element.done}</StyledTableCell>
            <StyledTableCell align="center">{element.today}</StyledTableCell>
            <StyledTableCell align="center">{element.slamtd}%</StyledTableCell>
          </TableRow>
        );
      } else {
        trs.push(
          <TableRow>
            <StyledTableCell align="center">
              {element.technicianName}
            </StyledTableCell>
            <StyledTableCell align="center">{element.done}</StyledTableCell>
            <StyledTableCell align="center">{element.today}</StyledTableCell>
            <StyledTableCell align="center">{element.slamtd}%</StyledTableCell>
          </TableRow>
        );
      }
    });

    return (
      <Box mx="auto" width="80%" margin="1em" overflow="auto">
        <Table
          className={style.TableHub}
          style={{ width: "100%", marginTop: "1%" }}
        >
          <TableHead>
            <StyledTableCell align="center">Angels</StyledTableCell>
            <StyledTableCell align="center">Feitos no dia</StyledTableCell>
            <StyledTableCell align="center">Carteira do dia</StyledTableCell>
            <StyledTableCell align="center">SLA MTD</StyledTableCell>
          </TableHead>
          <TableBody>{trs}</TableBody>
        </Table>
      </Box>
    );
  }

  //Contrutor da tabela de chamados pendentes
  buildTableServiceOrders(hub) {
    let rows = [];
    hub.service_orders.forEach((serviceOrder) => {
      rows.push({
        orderNumber: serviceOrder.orderNumber, //status: om.status,
        customer: serviceOrder.customer,
        status: serviceOrder.status,
        customerName: serviceOrder.customerName,
        serviceType: serviceOrder.serviceType,
        installedTerminalType: serviceOrder.installedTerminalType,
        neighborhood: serviceOrder.neighborhood,
        city: serviceOrder.city,
        technicianName: serviceOrder.technicianName,
        deadline: moment(serviceOrder.deadlineDate).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
      });
    });

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "orderNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Stonecode",
            field: "customer",
            type: "numeric",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Status",
            field: "status",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Nome do Cliente",
            field: "customerName",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Grupo de Serviço",
            field: "serviceType",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Tecnologia",
            field: "installedTerminalType",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Bairro",
            field: "neighborhood",
            cellStyle: { fontSize: "0.8em" },
          },
          { title: "Cidade", field: "city", cellStyle: { fontSize: "0.8em" } },
          {
            title: "Data Limite",
            field: "deadline",
            type: "date",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Reponsável",
            field: "technicianName",
            cellStyle: { fontSize: "0.8em" },
          },
        ]}
        data={rows}
        options={{
          actionsColumnIndex: -1,
          showTitle: false,
          exportAllData: true,
          pageSize: 10,
          padding: "dense",
          exportButton: true,
          // filtering: true,
          maxBodyHeight: 500,
        }}
        actions={[
          {
            icon: "search",
            tooltip: "Mais Detalhes",
            onClick: (event, rowData) =>
              this.buildContentModal(rowData.orderNumber),
          },
        ]}
        localization={{
          toolbar: {
            searchPlaceholder: "Procurar",
            nRowsSelected: "{0} linha(s) selecionadas",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} of {count}",
          },
          header: {
            actions: "Detalhes",
          },
          body: {
            emptyDataSourceMessage: "No records to display",
            filterRow: {
              filterTooltip: "Filter",
            },
          },
        }}
      />
    );
  }

  //Construtor da tabela de cobertura de estoque
  buildTableStock(skus) {
    let stockSituation = [];
    let status = undefined;
    skus.forEach((sku) => {
      if (sku.status === null) {
        status = "green";
      } else {
        status = sku.status;
      }

      if (sku.consumoDiario != null) {
        sku.consumoDiario = sku.consumoDiario.toFixed(1);
      } else {
        sku.consumoDiario = "0.0";
      }

      if (sku.cobertura > 200 || sku.cobertura === null) {
        sku.cobertura = "∞";
      } else {
        sku.cobertura = sku.cobertura.toFixed(1);
      }

      stockSituation.push(
        <TableRow>
          <StyledTableCell align="center">{sku.tecnologia}</StyledTableCell>
          <StyledTableCell align="center">{sku.modelo}</StyledTableCell>
          <StyledTableCell align="center">{sku.quantidade}</StyledTableCell>
          <StyledTableCell align="center">
            {sku.agingMaximo} dias
          </StyledTableCell>
          <StyledTableCell align="center">
            {sku.agingMedio} dias
          </StyledTableCell>
          <StyledTableCell align="center">{sku.consumoDiario}</StyledTableCell>
          <StyledTableCell align="center">{sku.cobertura}</StyledTableCell>
          {/* <StyledTableCell align="center">
            <span className={Hub.getClassNameForStatus(status)}></span>
          </StyledTableCell> */}
        </TableRow>
      );
    });

    return (
      <Box mx="auto" width="80%" margin="1em" overflow="auto">
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Tecnologia</StyledTableCell>
              <StyledTableCell align="center">Modelo</StyledTableCell>
              <StyledTableCell align="center">Quantidade</StyledTableCell>
              <StyledTableCell align="center">Aging Máximo</StyledTableCell>
              <StyledTableCell align="center">Aging Médio</StyledTableCell>
              <StyledTableCell align="center">Consumo Diário</StyledTableCell>
              <StyledTableCell align="center">
                Cobertura (em dias)
              </StyledTableCell>
              {/* <StyledTableCell align="center"></StyledTableCell> */}
            </TableRow>
          </TableHead>
          {stockSituation}
        </Table>
      </Box>
    );
  }

  //Construtor da tabela de Ordens de Movimentação a caminho
  buildTableMovementOrders(oms) {
    let trs = [];
    oms.data.forEach((om) => {
      trs.push(
        <TableRow>
          <StyledTableCell align="center">{om.orderNumber}</StyledTableCell>
          <StyledTableCell align="center">{om.dataDeEnvio}</StyledTableCell>
          <StyledTableCell align="center">{om.previsaoChegada}</StyledTableCell>
          <StyledTableCell align="center">{om.origem}</StyledTableCell>
          <StyledTableCell align="center">{om.transferType}</StyledTableCell>
          <StyledTableCell align="center">{om.status}</StyledTableCell>
          <StyledTableCell align="center">{om.model}</StyledTableCell>
          <StyledTableCell align="center">{om.quantidade}</StyledTableCell>
        </TableRow>
      );
    });

    return (
      <Box mx="auto" width="80%" margin="1em" overflow="auto">
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Número da OM</StyledTableCell>
              <StyledTableCell align="center" style={{ width: "10em" }}>
                Data de Envio
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "10em" }}>
                Previsão de Chegada
              </StyledTableCell>
              <StyledTableCell align="center">Origem</StyledTableCell>
              <StyledTableCell align="center">
                Tipo de Transferência
              </StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Modelo</StyledTableCell>
              <StyledTableCell align="center">Quantidade</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{trs}</TableBody>
        </Table>
      </Box>
    );
  }

  //Construtor do modal que contém os detalhes de cada ordem de serviço
  buildContentModal(serviceOrderNumber) {
    const serviceOrder = this.state.hubInfo.service_orders.find(
      (order) => order.orderNumber == serviceOrderNumber
    );

    let contents = [];
    LogisticService.getRAVSearch(serviceOrder.customer).then((response) => {
      if (response.data.data[0]["hasAutomaticReceivableAdvance"] == true) {
        var ravStatus = "Ativada";
      } else if (
        response.data.data[0]["hasAutomaticReceivableAdvance"] == false
      ) {
        var ravStatus = "Desativada";
      }
      serviceOrder["ravStatus"] = ravStatus;
      const listKeys = Object.keys(serviceOrder);
      listKeys.forEach((key) => {
        let value = serviceOrder[key];
        if (value) {
          if (moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid()) {
            value = moment(value).format("DD/MM/YYYY HH:mm:ss");
          }
          contents.push(
            <p>
              {this.findKey(key)}: {value}
            </p>
          );
        }
      });
      this.openModal(contents);
      // return contents
    });
  }

  findKey(key) {
    const keyValue = {
      arrivalDate: "Data de atendimento",
      cancellationReason: "Motivo de cancelamento",
      city: "Cidade",
      class: "Classe",
      contractor: "Contratante",
      country: "País",
      countryState: "Estado",
      customer: "Codigo do cliente",
      customerName: "Nome do cliente",
      customerDeadlineDate: "Data limite cliente",
      deadlineDate: "Data limite",
      defect: "Defeito",
      installedTerminalModel: "Modelo do terminal instalado",
      installedTerminalSak: "ID do terminal instalado",
      installedTerminalSerialNumber: "Numero de serie do terminal instalado",
      installedTerminalType: "Tipo de equipamento instalado",
      kitName: "Tipo de Kit",
      kitQuantity: "Quantidade de Kits",
      lastModifiedDate: "Data de modificação",
      logisticOperator: "Operador logístico",
      openingDate: "Data de abertura",
      orderNumber: "Número da OS",
      project: "Projeto",
      neighborhood: "Bairro",
      provider: "Prestador",
      serviceGroup: "Grupo de serviço",
      serviceType: "Tipo de serviço",
      status: "Status",
      solution: "Solução",
      technicianName: "Técnico",
      totalVisits: "Numero de visitas",
      uninstalledTerminalModel: "Modelo do terminal desinstalado",
      uninstalledTerminalSak: "ID do terminal desinstalado",
      uninstalledTerminalSerialNumber:
        "Número de serie do terminal desinstalado",
      uninstalledTerminalType: "Tipo de equipamento desinstalado",
      visitReason1: "Motivo de cancelamento da 1ª visita",
      visitReason2: "Motivo de cancelamento da 2ª visita",
      ravStatus: "Antecipação Automática",
    };

    return keyValue[key];
  }

  renderTableHub() {
    if (this.state.loaded) {
      return this.state.tableHub;
    }

    return (
      <div style={{ margin: "2em", textAlign: "center" }}>
        <LoadingSpinner />
      </div>
    );
  }

  renderTableAngels() {
    if (this.state.loaded) {
      return this.state.tableAngel;
    }

    return <div></div>;
  }

  renderTableServiceOrders() {
    if (this.state.loaded) {
      return this.state.tableServiceOrders;
    }

    return <div></div>;
  }

  renderMachineConsumption() {
    if (this.state.machineConsumption.loaded) {
      return (
        <Box mx="auto" width="80%" margin="1em" overflow="auto">
          <Graph
            data={this.state.machineConsumption.graph.data}
            legend={this.state.machineConsumption.graph.legend}
            options={this.state.machineConsumption.graph.options}
            width={500}
            height={450}
          />
        </Box>
      );
    }

    return (
      <div style={{ margin: "2em", textAlign: "center" }}>
        <LoadingSpinner />
      </div>
    );
  }

  renderIncomingOMS() {
    if (this.state.loaded) {
      return this.state.tableIncomingMovementOrders;
    }

    return <div></div>;
  }

  openModal(data) {
    this.setState({ modalIsOpen: true, dataModel: data });
  }

  renderModal() {
    return (
      <Modal open={open} onClose={this.closeModal.bind(this)}>
        <h2>Detalhamento da OS</h2>
        <div>{this.state.dataModel}</div>
      </Modal>
    );
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  renderTableStock() {
    if (this.state.loaded) {
      return this.state.tableStockSituation;
    }

    return <div></div>;
  }

  render() {
    let refreshContent;
    let lastUpdatedText = "";

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format(
        "DD/MM HH:mm"
      )}`;
    }

    if (!this.state.loading) {
      refreshContent = (
        <div style={{ textAlign: "right", marginBottom: "1em" }}>
          <label style={{ margin: "1em" }}>
            <i>{lastUpdatedText}</i>
          </label>
          <RefreshButton
            onClick={() => {
              this.refreshReportData();
            }}
          />
        </div>
      );
    }

    return (
      <Container fluid>
        <Col xs sm md lg xl={12}>
          {refreshContent}
          <h2>Polo {this.state.hubName}</h2>
          <div></div>
          <CustomizedExpansionPannel
            title="Situação Geral do Polo"
            content={this.renderTableHub()}
          />
          <CustomizedExpansionPannel
            title="Situação Angels"
            content={this.renderTableAngels()}
          />
          <CustomizedExpansionPannel
            title="Chamados atrasados e com vencimento hoje"
            content={this.renderTableServiceOrders()}
          />
          <CustomizedExpansionPannel
            title="Situação do Estoque"
            content={this.renderTableStock()}
          />
          <CustomizedExpansionPannel
            title="Histórico de Consumo"
            content={this.renderMachineConsumption()}
          />
          <CustomizedExpansionPannel
            title="OMs a Caminho"
            content={this.renderIncomingOMS()}
          />
          {this.state.modalIsOpen ? this.renderModal() : <div></div>}
        </Col>
      </Container>
    );
  }
}

Hub.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default Hub;
