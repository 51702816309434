import React from "react";
import motorbike from "./motorbike2.gif";

export default function LoadingSpinner(props) {
  const { text } = props;

  const message = text ? text : "Carregando...";

  return (
    <div>
      <img src={motorbike} alt="logo" />
      <br />
      <div style={{ color: "gray" }}>{message}</div>
    </div>
  );
}
