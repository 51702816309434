/* eslint-disable */

import React, {Component, PropTypes} from 'react'
import moment from 'moment'
import _ from 'lodash'
import style from './style.css'
import LogisticService from '../../core/services/LogisticService'
import { Button } from 'antd'
import { Container, Col, Row, Form } from 'react-bootstrap'
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import model from "../CasesClassification/modelo casos.csv"
import { SheetJSCasesApp } from "../../custom-components/SheetJs/SheetJsCases";


class CaseClassification extends Component {
  constructor() {
    super();

    this.state = {
      today: moment().format('YYYY-MM-DD'),
      loading: false,
      lastUpdated: undefined,
      selectedRegion: '',
      selectedDistrict: '',
      selectedHub: ''
    }

    this.refreshReportData = this.refreshReportData.bind(this)
  }

  componentDidMount() {
    this.refreshReportData()
  }

  refreshReportData() {
    const {today} = this.state
    this.setState({
	  lastUpdated: undefined
	})

  LogisticService.getCasesTreeMembers()
  .then((response) => {
    var mailList = []
    var namesList = {}
    response.data.data.forEach((element) => {
      mailList.push(element.memberMail)
      name = element.memberName.split(' ', 1)
      namesList[element.memberMail]=name
    })
    this.setState({
			mailList: mailList,
			namesList: namesList
		})
  })


	LogisticService.getCasesTree()
	.then((response) => {

		var formattedCasesTree = {}

		_.uniq(_.map(response.data.data, 'Tema Logística')).forEach((theme) => {
			formattedCasesTree[theme] = {}

			var filteredByTheme = _.filter(response.data.data, { 'Tema Logística': theme});

			_.uniq(_.map(filteredByTheme, 'Categoria Logística')).forEach((category) => {
				formattedCasesTree[theme][category] === undefined ? formattedCasesTree[theme][category] = [] : null

				var filteredByCategory = _.filter(response.data.data, { 'Tema Logística': theme, 'Categoria Logística': category});

				filteredByCategory.forEach((topic) => formattedCasesTree[theme][category].push(topic['Assunto Logística']))
			})

		})

		this.setState({
			rawCasesTree: response.data.data,
			formattedCasesTree: formattedCasesTree
		})

	})

  }

  saveCase() {

    var number = document.getElementById("number").value
    var status = document.getElementById("status")
    status = status[status.selectedIndex].text
    var qty = document.getElementById("qty").value
    var wpp = document.getElementById("wpp").value
    var observation = document.getElementById("observation").value
    var email = document.getElementById("email")
    email = email[email.selectedIndex].text
    var channel = document.getElementById("channel")
    channel = channel[channel.selectedIndex].text
    var diag = document.getElementById("diag")
    diag = diag[diag.selectedIndex].text
    var diagTopic = document.getElementById("diagTopic")
    diagTopic = diagTopic[diagTopic.selectedIndex].text


    this.setState({
       caseNumber: number,
       status: status,
       qty: qty,
       wpp: wpp,
       observation: observation,
       email: email,
       channel: channel,
       diag: diag,
       diagTopic: diagTopic
      })
      this.refreshReportData()
    }


  refreshOptions() {

    const theme = document.getElementById("theme")
    const category = document.getElementById("category")
    const topic = document.getElementById("topic")

    const selectedTheme = theme[theme.selectedIndex].text
    const selectedCategory = category[category.selectedIndex].text
    const selectedTopic = topic[topic.selectedIndex].text

    let avaiableCategories = []
    let avaiableTopics = []


    if ((selectedTheme) != undefined && (selectedTheme) != '')
      avaiableCategories = [' '].concat(Object.keys(this.state.formattedCasesTree[selectedTheme]))

    if((selectedTheme) != '' && (selectedCategory) != ''){
      if ((this.state.formattedCasesTree[selectedTheme][selectedCategory]) != undefined)
            avaiableTopics = [' '].concat(this.state.formattedCasesTree[selectedTheme][selectedCategory])
    }




    this.setState({
       avaiableCategories: avaiableCategories,
       avaiableTopics: avaiableTopics,
       theme: selectedTheme,
       category: selectedCategory,
       topic: selectedTopic
      })
      this.refreshReportData()
    }


  buildOptionsFromArray(array){

    if (array.length === 0)
      return (<option>   </option>)

    const results = array.map(element =>
      (<option> {element} </option>)
  )
    return results
  }

  postCase(){
    LogisticService.postNewCase(this.state.caseNumber,this.state.status,this.state.qty,this.state.wpp,this.state.observation,this.state.email,
       this.state.theme, this.state.category, this.state.topic, this.state.channel, this.state.diag, this.state.diagTopic).then((response) => {
         if(response.status === 200) {
           alert("Caso registrado com Sucesso!")
           document.location.reload(true);
         }
         else {
           alert("Houve um erro! Tente novamente, caso persista, procure o time de sistemas")
         }

       })
  }
  inputBlock () {

	if (!this.state.formattedCasesTree) {
		return
	}


    return (
	<Form>
      <Form.Row>
        <Col sm="12" lg="4">
          <Form.Control id="number" type="text" placeholder="Número do Caso" onInput = {e => this.saveCase()} />
        </Col>
        <Col sm="12" lg="2">
        </Col>
        <Col sm="12" lg="5">
          <Form.Label size="sm">Status</Form.Label>
          <Form.Control id="status" type="email" size="sm" placeholder="Status" as="select" onInput = {e => this.saveCase()} >
            <option> </option>
            <option>Fechado Com Contato</option>
            <option>Fechado Sem Contato</option>
            <option>Resolvido</option>
            <option>Pendente</option>
            <option>Em Espera</option>
          </Form.Control>
        </Col>
      </Form.Row>
      <Form.Row>
      <Col sm="12" lg="1">
      </Col>
			<Col sm="12" lg="10">
				<Form.Group as={Col} controlId="formGridState" onInput = {e => this.refreshOptions()} >
				<Form.Label>Tema Logística</Form.Label>
				<Form.Control id="theme" as="select" onChange = {e => this.refreshOptions()} onInput = {e => this.refreshOptions()}>
					{this.buildOptionsFromArray([''].concat(Object.keys(this.state.formattedCasesTree)))}
				</Form.Control>
				</Form.Group>
          	</Col>
		</Form.Row>
		<Form.Row>
      <Col sm="12" lg="1">
      </Col>
			<Col sm="12" lg="10">
                <Form.Group as={Col} controlId="formGridState" onInput = {e => this.refreshOptions()} >
                    <Form.Label>Categoria Logística</Form.Label>
                    <Form.Control id="category" as="select" onChange = {e => this.refreshOptions()} onInput = {e => this.refreshOptions()}>
                    	{this.buildOptionsFromArray(this.state.avaiableCategories ? this.state.avaiableCategories : [])}
					</Form.Control>
                </Form.Group>
            </Col>
		</Form.Row>
		<Form.Row>
            <Col sm="12" lg="1">
            </Col>
          	<Col sm="12" lg="10">
          		<Form.Group as={Col} controlId="formGridState" onInput = {e => this.refreshOptions()} >
            		<Form.Label>Assunto Logística</Form.Label>
            		<Form.Control id="topic" as="select" onChange = {e => this.refreshOptions()} onInput = {e => this.refreshOptions()}>
              			{this.buildOptionsFromArray(this.state.avaiableTopics ? this.state.avaiableTopics : [])}
            		</Form.Control>
          		</Form.Group>
          	</Col>
		</Form.Row>
    <Form.Row>
            <Col sm="12" lg="1">
            </Col>
          	<Col sm="12" lg="5">
          		<Form.Group as={Col} controlId="formGridState" onInput = {e => this.refreshOptions()} >
            		<Form.Label>Diagnóstico</Form.Label>
            		<Form.Control id="diag" as="select" onChange = {e => this.saveCase()} onInput = {e => this.saveCase()}>
                    <option>  </option>
                    <option> Inatividade </option>
                    <option> Churn </option>
            		</Form.Control>
          		</Form.Group>
          	</Col>
            <Col sm="12" lg="5">
          		<Form.Group as={Col} controlId="formGridState" onInput = {e => this.refreshOptions()} >
            		<Form.Label>Descrição Diagnóstico</Form.Label>
            		<Form.Control id="diagTopic" as="select" onChange = {e => this.saveCase()} onInput = {e => this.saveCase()}>
                    <option>  </option>
                    <option>Não inaugurou</option>
                    <option>Não recebeu o POS</option>
                    <option>Não solicitou POS</option>
                    <option>Problemas no Cadastro/Conta</option>
                    <option>Defeito no POS</option>
                    <option>Problemas de infraestrutura</option>
                    <option>Em negociação</option>
                    <option>Outros</option>
                    <option>Taxas (MDR/RAV)</option>
                    <option>Aluguel</option>
                    <option>Volume acordado com outra adquirente</option>
                    <option>Encerrou as atividades</option>
                    <option>Sazonalidade</option>
                    <option>Trava bancária</option>
                    <option>Isenção/Benefícios em produtos bancários</option>
                    <option>Falta de Produto</option>
                    <option>Defeito no POS</option>
                    <option>Problemas de infraestrutura</option>
                    <option>Problemas no Cadastro/Conta</option>
                    <option>Mau Atendimento</option>
            		</Form.Control>
          		</Form.Group>
          	</Col>
		</Form.Row>
    <Form.Row>
      <Col sm="12" lg="1">
      </Col>
      <Col sm="12" lg="3">
        <Form.Label size="sm">Canal</Form.Label>
        <Form.Control id="channel" size="sm" as="select" onChange = {e => this.saveCase()} >
          <option> </option>
          <option>E-mail</option>
          <option>Whatsapp</option>
          <option>Telefone</option>
          <option>Comercial</option>
          <option>Chat</option>
          {/* <option>RC</option>
          <option>RT</option> */}
        </Form.Control>
      </Col>
      <Col sm="12" lg="2">
        <Form.Label size="sm" style={{marginTop: '1em'}}> </Form.Label>
        <Form.Control id="qty" type="email" size="sm" placeholder="Qtd de POS" onInput = {e => this.saveCase()} />
      </Col>
      <Col sm="12" lg="4">
        <Form.Label size="sm" style={{marginTop: '1em'}}> </Form.Label>
        <Form.Control id="wpp" type="email" size="sm" placeholder="Whatsapp: (999) 99999-9999" onInput = {e => this.saveCase()} />
      </Col>
    </Form.Row>
    <Form.Row>
      <Col sm="12" lg="1">
      </Col>
      <Col sm="12" lg="10">
        <Form.Label style={{marginTop: '1em'}}>Descrição do Caso</Form.Label>
        <Form.Control id="observation" type="email" placeholder="Escreva Aqui"  onInput = {e => this.saveCase()}/>
      </Col>
    </Form.Row>
		<Form.Row style={{textAlign: 'center'}}>
			<Button style={{margin: '1em'}} onClick = {e => this.postCase()}>Registrar Caso</Button>
      <Col sm="12" lg="3">
      </Col>
      <Col sm="12" lg="5" style={{margin: '1em'}}>
        <Form.Control id="email" type="email" size="sm" placeholder="Status" as="select" onInput = {e => this.saveCase()}>
          <option>{localStorage.userEmail}</option>
          {this.buildOptionsFromArray(this.state.mailList)}
        </Form.Control>
      </Col>
		</Form.Row>
	</Form>
    )}



  render() {
    if(this.state.namesList == undefined){
      return(
        <Container fluid>
        </Container>
      )
    }


      return (
      <Container fluid>
      <Row style={{margin:'1.5em'}}>
      <b><strong>OLÁ, {this.state.namesList[localStorage.userEmail]}</strong><br/></b>
      </Row>
      <Row style={{marginLeft:'1.5em'}}>
        <Col lg="6" xs="6" sm="6">
          <Box
            boxShadow={3}>
            {this.inputBlock()}
          </Box>
            <label style={{marginLeft:'1.3em'}}>
                Importar multiplos casos para o Banco -{" "}
                <a href={model} download>
                  {"Baixar Modelo"}
                </a>
            </label>
            <SheetJSCasesApp/>
        </Col>
      </Row>
      </Container>)

  }

}

export default CaseClassification
