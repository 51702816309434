import React, { Component } from 'react'
import Graph from '../../components/Logistic/graphic/graph'
import _ from 'lodash'
import LogisticService from '../../core/services/LogisticService'
import LoadingSpinner from '../../components/LoadingSpinner'
import { Container, Col, Row, Form } from 'react-bootstrap'
import { Statistic, Icon, Card} from 'antd'
import RefreshButton from '../../custom-components/RefreshButton'
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import CustomizedExpansionPannel from '../../custom-components/CustomizedExpansionPanel/index'
import './style.css'


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: 'white',
    color: 'dark',
    fontSize: '0.9em',
    fontFamily: 'GoBold'
  },
  body: {
    fontSize: '0.8em',
    fontFamily: 'GoBold'
  },
}))(TableCell); 

const SimpleBox = props => {
  const { header, result, goal, direction, percentage,decimal } = props
  let headlightSymbol;
  let headlightColor;

  if (direction === 'normal') {
    headlightSymbol = (result > goal ? 'check-circle' : 
      (result > 0.9 * goal) ?  'minus-circle' : 'close-circle')
    headlightColor = (result > goal ? '#28A745' : 
    (result > 0.9 * goal) ?  'orange' : 'red')
  } else if (direction === 'reverse') {
    headlightSymbol = (result < goal ? 'check-circle' : 
    (result < 1.1 * goal) ?  'minus-circle' : 'close-circle')
    headlightColor = (result < goal ? '#28A745' : 
    (result < 1.1 * goal) ?  'orange' : 'red')
  }
  return (
      <Card>
        <Statistic
          title={<h3 style={{fontFamily: 'GoBold'}}>{header}</h3>}
          value={ result === undefined ? 0 + (percentage ? '%' : ''):result.toFixed(decimal) + (percentage ? '%' : '')}
          valueStyle={{ color: {headlightColor} }}
          prefix={<Icon type={headlightSymbol} theme="twoTone" style={{verticalAlign: 'middle'}} twoToneColor={headlightColor} />}
        />
    </Card>
    )
}
class DashHunter extends Component {

  constructor() {
  
    super()

    this.state = {
      rescued:{
        loaded : false
      },
      prod:{
        loaded : false
      },
      channelRescued:{
        loaded : false
      },
      scheduling:{
        loaded : false
      },
      absent:{
        loaded : false
      },
      openClosed:{
        loaded : false
      },
      efficiency:{
        loaded : false
      },
    }

    this.refreshReportData = this.refreshReportData.bind(this)
  }

  componentDidMount() {
    this.refreshReportData()
  }

  clearStates(){
    this.setState({
      rescued:{
        loaded : false
      },
      prod:{
        loaded : false
      },
      channelRescued:{
        loaded : false
      },
      scheduling:{
        loaded : false
      },
      absent:{
        loaded : false
      },
      openClosed:{
        loaded : false
      },
      efficiency:{
        loaded : false
      },
    })
  }

  refreshReportData(){
    this.clearStates()
    
    LogisticService.getHunterCase('channel').then((cases)=>{  
      this.buildChannelGrahp(cases.data.data)
    })

    LogisticService.getHunterStock().then((stock)=>{  
      this.buildOpenCloseGrahp(stock.data.data)
    })
    
    LogisticService.getHunterOs('openClose','month').then((os)=>{
      // LogisticService.getHunterCase('rescued').then((cases)=>{  
        this.buildRescuedGrahp(os.data.data,'')
      // })
      LogisticService.getHunterPeople('available','month').then((people)=>{ 
        this.buildProduticvityGrahp(os.data.data,people.data.data)
      })
      this.buildEfficiencyGrahp(os.data.data)
    })

    LogisticService.getHunterOs('open','day').then((open)=>{  
      LogisticService.getHunterPeople('available','day').then((available)=>{  
        this.buildSchedulingGrahp(open.data.data,available.data.data)
        LogisticService.getHunterPeople('active').then((active)=>{   
          this.buildAbsentGrahp(available.data.data,active.data.data)
        })
      })
    })
  }


  buildRescuedGrahp(os,cases) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          // {
          //   label: '% RECUPERAÇÃO/TRATADOS',
          //   stack: 'Stack 0',
          //   type : 'line',
          //   borderColor: '#92c73d',
          //   pointBorderColor: '#92c73d',
          //   pointBackgroundColor: '#92c73d',
          //   pointHoverBackgroundColor: '#92c73d',
          //   pointHoverBorderColor: '#92c73d',
          //   pointRadius : 0,
          //   data: [],
          //   yAxisID: 'B',
          //   fill: false,
          //   display:false,
          //   pointStyle: 'line'
          // },
          {
            label: 'RECUPERAÇÕES',
            stack: 'Stack 0',
            backgroundColor: '#008a4a',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect'
            
          },
          
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'B',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
          {
            id: 'A',
            display:true,
            stacked: false,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value}`
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'RECUPERAÇÃO DE TERMINAIS',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            formatter: function(value, context) {
              if(context.dataset.label === '% RECUPERAÇÃO/TRATADOS')
                return value + '%'
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            position: 'bottom',
            align: 'top',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          fontColor: 'black',
        },
      },
    }
    _.orderBy(os, 'data').forEach((element) => {
        graphObj.data.labels.push(element.date.split("-")[1]+'/'+element.date.split("-")[0])
        graphObj.data.datasets[0].data.push(element.closed)
      }
    )

  //   graphObj.data.labels.forEach((lable)=>{
  //     let element = cases.find((content => content.date === lable))
  //         if(element !== undefined)
  //           graphObj.data.datasets[0].data.push((((element.solved*1.0)/element.total)*100).toFixed(2))
  //         else
  //         graphObj.data.datasets[0].data.push(0)
  // })


    this.setState({
      rescued:{
        graph: graphObj,
        loaded: true
      },
    })
  }

  buildProduticvityGrahp(os,people) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Realizado',
            stack: 'Stack 0',
            backgroundColor: '#008a4a',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect'
            
          }
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'B',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
          {
            id: 'A',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'PRODUTIVIDADE',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            anchor:'end',
            position: 'top',
            align: 'top',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          fontColor: 'black',
        },
      },
    }
    os.forEach((element) => {
        let total = people.find(content => content.date === (element.date.split("-")[1]+'/'+element.date.split("-")[0]))
        graphObj.data.labels.push(element.date.split("-")[1]+'/'+element.date.split("-")[0])
        graphObj.data.datasets[0].data.push(((element.opened*1.0)/(total.available * total.workday)).toFixed(0))
      }
    )

    this.setState({
      prod:{
        graph: graphObj,
        loaded: true
      },
    })
  }

  buildChannelGrahp(obj) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Conversão Esperada',
            stack: 'Stack 0',
            backgroundColor: 'grey',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect'
            
          },      
          {
            label: 'Conversão Realizada',
            stack: 'Stack 1',
            backgroundColor: '#008a4a',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect'
            
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'B',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
          {
            id: 'A',
            display:false,
            stacked: false,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'RECUPERAÇÃO POR CANAL',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            formatter: function(value, context) {
              return value + '%'
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            anchor:'end',
            position: 'top',
            align: 'top',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          fontColor: 'black',
        },
      },
    }

    graphObj.data.labels.push('E-MAIL')
    graphObj.data.datasets[0].data.push(5)
    graphObj.data.datasets[1].data.push((((obj[0].mail*1.0)/obj[0].totalemail)*100).toFixed(2))


    graphObj.data.labels.push('WHATSAPP')
    graphObj.data.datasets[0].data.push(22)
    graphObj.data.datasets[1].data.push((((obj[0].whatsapp*1.0)/obj[0].totalwhatsapp)*100).toFixed(2))

    graphObj.data.labels.push('TELEFONE')
    graphObj.data.datasets[0].data.push(15)
    graphObj.data.datasets[1].data.push((((obj[0].phone*1.0)/obj[0].totalphone)*100).toFixed(2))

    this.setState({
      channelRescued:{
        graph: graphObj,
        loaded: true
      },
    })
  }

  buildSchedulingGrahp(os,people) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Esperado',
            stack: 'Stack 0',
            type : 'line',
            borderColor: '#00b04f',
            pointBorderColor: '#92c73d',
            pointBackgroundColor: '#92c73d',
            pointHoverBackgroundColor: '#92c73d',
            pointHoverBorderColor: '#92c73d',
            pointRadius : 0,
            borderDash: [10,10],
            showLine:true,
            data: [],
            yAxisID: 'A',
            fill: false,
            display:true,
            pointStyle: 'line'
            
          },
          {
            label: 'Realizado',
            stack: 'Stack 0',
            backgroundColor: '#008a4a',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect'
            
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'A',
            display:true,
            stacked: false,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(0)}`
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'AGENDAMENTO POR PESSOA',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
                if(graphObj.dataset.label !== 'Esperado')
                  return graphObj.dataset.data[graphObj.dataIndex] > 0
                else
                  return false
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            anchor:'end',
            position: 'top',
            align: 'top',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          fontColor: 'black',
        },
      },
    }

    os.forEach((element) => {
      let total = people.find(content => content.date === element.date)
        graphObj.data.labels.push(" ".concat(element.date))
        graphObj.data.datasets[0].data.push(14)
        graphObj.data.datasets[1].data.push(((element.openingOs*1.0)/total.available).toFixed(0))
      }
    )

    this.setState({
      scheduling:{
        graph: graphObj,
        loaded: true
      },
    })
  }

  buildAbsentGrahp(available,active) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
          label: 'Esperado',
          stack: 'Stack 0',
          type : 'line',
          borderColor: '#00b04f',
          pointBorderColor: '#92c73d',
          pointBackgroundColor: '#92c73d',
          pointHoverBackgroundColor: '#92c73d',
          pointHoverBorderColor: '#92c73d',
          pointRadius : 0,
          borderDash: [10,10],
          showLine:true,
          data: [],
          yAxisID: 'A',
          fill: false,
          display:true,
          pointStyle: 'line'
        },
          {
            label: 'Realizado',
            stack: 'Stack 0',
            backgroundColor: 'grey',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect'
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'A',
            display:true,
            stacked: false,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(0)}%`
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: '% ABSENTEISMO',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
              if(graphObj.dataset.label !== 'Esperado')
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              else
                return false
            },
            formatter: function(value, context) {
              return value + '%'
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            anchor:'end',
            position: 'top',
            align: 'top',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          fontColor: 'black',
        },
      },
    }
    active.forEach((element) => {
        let total = available.find(content => content.date === element.date)
        if(total !== undefined){
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push(4)
          graphObj.data.datasets[1].data.push(100-(((element.agents*1.0)/total.available)*100).toFixed(0))
        }
      }
    )

    this.setState({
      absent:{
        graph: graphObj,
        loaded: true
      },
    })
  }

  buildOpenCloseGrahp(stock) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Estoque',
            stack: 'Stack 0',
            type : 'line',
            borderColor: 'grey',
            pointRadius : 0,
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'line'
            
          },
          {
            label: 'Abriu',
            stack: 'Stack 0',
            backgroundColor: '#008a4a',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect'
            
          },
          {
            label: 'Fechou',
            stack: 'Stack 2',
            backgroundColor: '#92c73d',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect'
            
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'B',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
          {
            id: 'A',
            display:true,
            stacked: false,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return value
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'ABERTURA VS FECHAMENTO',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
              if(graphObj.dataset.type === "line"){
                return graphObj.dataset.data[graphObj.dataIndex] > 0
               }
              else
                return false
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            anchor:'end',
            position: 'top',
            align: 'top',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          fontColor: 'black',
        },
      },
    }
    
    stock.forEach((element) => {
        graphObj.data.labels.push((element.date.split("-")[2]).split("T")[0]+'/'+element.date.split("-")[1])
        graphObj.data.datasets[0].data.push(element.stock)
        graphObj.data.datasets[1].data.push(element.opened)
        graphObj.data.datasets[2].data.push(element.closed)
      }
    )

    this.setState({
      openClosed:{
        graph: graphObj,
        loaded: true
      },
    })
  }

  
  buildEfficiencyGrahp(obj) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Baixada',
            stack: 'Stack 0',
            backgroundColor: '#008a4a',
            data: [],
            yAxisID: 'A',
            fill: false,
            display:false,
            pointStyle: 'rect'
            
          }
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'B',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
          {
            id: 'A',
            display:false,
            stacked: true,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          }
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 20,
          text: 'EFICIÊNCIA DO AGENDAMENTO',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            
            formatter: function(value, context) {
              return value + '%'
            },
            font: {
              size: 12,
              weight: 'bold'
            },
            anchor:'end',
            position: 'top',
            align: 'top',
            color:'black',
            
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          fontColor: 'black',
        },
      },
    }

    obj.forEach((element) => {
        graphObj.data.labels.push(element.date.split('-')[1]+'/'+element.date.split('-')[0])
        graphObj.data.datasets[0].data.push((((element.closed*1.0)/element.arrival)*100).toFixed(0))
      }
    )

    this.setState({
      efficiency:{
        graph: graphObj,
        loaded: true
      },
    })
  }

  renderResultadoGeral(){

    return(
      <CustomizedExpansionPannel
        title="Resultado Geral"
        content={ 
          this.state.rescued.loaded && this.state.prod.loaded?               
          <div>
            <Row>
              <Col lg xl = "6" xs sm md = "12">  
                <Graph
                  data={this.state.rescued.graph.data}
                  legend={this.state.rescued.graph.legend}
                  options={this.state.rescued .graph.options}
                  height={450}
                  />
              </Col>
              <Col lg xl = "6" xs sm md = "12">  
                <Graph
                  data={this.state.prod.graph.data}
                  legend={this.state.prod.graph.legend}
                  options={this.state.prod.graph.options}
                  height={450}
                  />
              </Col>
            </Row>
          </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
          }
      />
    )
  }

  renderResultadoCanal(){

    return(
      <CustomizedExpansionPannel
        title="Resultado por Canal (MTD)"
        content={ 
          this.state.channelRescued.loaded?                         
          <div>
            <Row>
              <Col lg xl = "12" xs sm md = "12">  
                <Graph
                  data={this.state.channelRescued.graph.data}
                  legend={this.state.channelRescued.graph.legend}
                  options={this.state.channelRescued.graph.options}
                  height={450}
                  />
              </Col>
            </Row>
          </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
          }
      />
    )
  }

  renderPessoas(){

    return(
      <CustomizedExpansionPannel
        title="Pessoas"
        content={ 
          this.state.scheduling.loaded && this.state.absent.loaded?                             
          <div>
            <Row>
              <Col lg xl = "6" xs sm md = "12">  
                <Graph
                  data={this.state.scheduling.graph.data}
                  legend={this.state.scheduling.graph.legend}
                  options={this.state.scheduling.graph.options}
                  height={450}
                  />
              </Col>
              <Col lg xl = "6" xs sm md = "12">  
                <Graph
                  data={this.state.absent.graph.data}
                  legend={this.state.absent.graph.legend}
                  options={this.state.absent.graph.options}
                  height={450}
                  />
              </Col>
            </Row>
          </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
          }
      />
    )
  }

  renderResultadoAgendamentos(){

    return(
      <CustomizedExpansionPannel
        title="Resultados dos Agendamentos"
        content={ 
           this.state.openClosed.loaded  && this.state.efficiency.loaded?                          
          <div>
            <Row>
              <Col lg xl = "6" xs sm md = "12">  
                <Graph
                  data={this.state.openClosed.graph.data}
                  legend={this.state.openClosed.graph.legend}
                  options={this.state.openClosed.graph.options}
                  height={450}
                  />
              </Col>
              <Col lg xl = "6" xs sm md = "12">  
                <Graph
                  data={this.state.efficiency.graph.data}
                  legend={this.state.efficiency.graph.legend}
                  options={this.state.efficiency.graph.options}
                  height={450}
                  />
              </Col>
            </Row>
          </div>:
              <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
              </div>
          }
      />
    )
  }
  // Render
  render() {
    let refreshContent
    refreshContent = (
      <div>
        <div style={{textAlign: 'right'}}>
          <RefreshButton onClick={() => { this.refreshReportData() }}/>
        </div>
      </div>
    )
    return (
      <Container fluid>
          {refreshContent}
          <div style={{color: 'black'}}>
            <Row>
              <Col xl sm md lg xl = {12}>
                {this.renderResultadoGeral()}
              </Col>
            </Row>
            <Row>
              <Col xl sm md lg xl = {12}>
                {this.renderResultadoCanal()}
              </Col>
            </Row>
            <Row>
              <Col xl sm md lg xl = {12}>
                {this.renderPessoas()}
              </Col>
            </Row>
            <Row>
              <Col xl sm md lg xl = {12}>
                {this.renderResultadoAgendamentos()}
              </Col>
            </Row>
          </div>
    </Container>
    )
  }
}

export default DashHunter
