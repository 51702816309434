import React from "react";
import Graph from "../../../components/Logistic/graphic/graph";
import Box from "@material-ui/core/Box";
import _ from "lodash";
import moment from "moment";
import "../style.css";

const HuntHistoryGraph = (props) => {
  const { huntHistory } = props;

  if (!huntHistory) return null;

  const graphObj = {
    data: {
      labels: [],
      datasets: [
        {
          label: "Parque Ativo (% do Inst.)",
          backgroundColor: "#e74c3c",
          borderColor: "#e74c3c",
          hoverBackgroundColor: "#e74c3c",
          hoverBorderColor: "#e74c3c",
          data: [],
          type: "line",
          pointRadius: 0,
          fill: false,
          yAxisID: "B",
        },
        {
          label: "Parque Ativo",
          backgroundColor: "#27ae60",
          borderColor: "#2c3e50",
          borderWidth: 0.5,
          hoverBackgroundColor: "#27ae60",
          hoverBorderColor: "#27ae60",
          data: [],
          type: "line",
          pointRadius: 0,
          fill: undefined,
          yAxisID: "A",
        },

        {
          label: "Parque Inativo",
          backgroundColor: "#808080",
          borderColor: "#2c3e50",
          borderWidth: 0.5,
          hoverBackgroundColor: "#808080",
          hoverBorderColor: "#808080",
          data: [],
          type: "line",
          pointRadius: 0,
          fill: undefined,
          yAxisID: "A",
        },
      ],
    },
    options: {
      responsive: undefined,
      maintainAspectRatio: false,
      tooltips: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: (tooltipItem, data) => {
            if (tooltipItem.datasetIndex === 0) {
              return `${(tooltipItem.yLabel * 100).toFixed(2)}%`;
            }
            return tooltipItem.yLabel;
          },
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            id: "A",
            type: "linear",
            position: "left",
            ticks: {
              suggestedMax: 720 * 1000,
              min: 0,
              callback: function (value) {
                return value / 1000 + "k";
              },
            },
            stacked: true,
          },
          {
            id: "B",
            type: "linear",
            position: "right",
            ticks: {
              max: 0.92,
              suggestedMin: 0.8,
              callback: function (value) {
                return value * 100 + "%"; // convert it to percentage
              },
            },
            gridLines: {
              display: false,
            },
            stacked: undefined,
          },
        ],
      },
      title: {
        display: true,
        fontColor: "black",
        fontSize: 20,
        text: "Eficiência do Caça | Parque Ativo",
      },
      plugins: {
        datalabels: {
          font: {
            size: 0,
          },
          color: "black",
        },
      },
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        fontColor: "black",
      },
    },
  };

  _.orderBy(huntHistory, "date").forEach((element) => {
    graphObj.data.labels.push(moment(element.date).format("DD/MM/YYYY"));
    graphObj.data.datasets[2].data.push(element.parqueInstalado - element.activeTerminals);
    graphObj.data.datasets[1].data.push(element.activeTerminals);
    graphObj.data.datasets[0].data.push(element.activeTerminals / element.parqueInstalado);
  });

  const isDesktop = window.innerWidth > 1300;

  return (
    <Box height="100%">
      <Graph
        data={graphObj.data}
        legend={graphObj.legend}
        options={graphObj.options}
        width={isDesktop ? window.innerWidth / 2.2 : 300}
        height={isDesktop ? 500 : 400}
      />
    </Box>
  );
};

export default HuntHistoryGraph;
