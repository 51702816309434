import React, { Component } from 'react';
import RiskAreasMap from './riskAreas';
import AngelRestrict from './angelRestrict';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import  style from "./style.css";
import { Form  } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import Tooltip from "../../../custom-components/Tooltips/index";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import LogisticService from '../../../core/services/LogisticService';

class ConfigsMap extends Component {
    constructor() {
      super();

      this.state = {
          selectedMode: 'Área de Risco',
          selectedAngel: ' ',
          hubsList: [],
          angelsList: [],
          loading: true
    
    };
    
    this.refreshReportData = this.refreshReportData.bind(this)

    }

    componentDidMount() {
        this.refreshReportData()
    }

    refreshReportData() {
        this.state.selectedDistrict = this.props.location.state.district

        LogisticService.getSenninhaAngels(this.state.selectedDistrict).then((response) => {
            var hubs = response.data.data[0]
            var hubsList = Object.keys(hubs)
            
            this.setState({
                hubs: hubs,
                hubsList: hubsList,
                loading: false
            })
          })
      
    }

    chooseAngel(hub){
        var angelsList = this.state.hubs[hub]
        console.log(this.state.hubs)
        console.log(angelsList)
        console.log(hub)
        
        this.setState({
            angelsList: angelsList
          })
    }

    buildOptionsFromArray(array){

        if (array.length === 0)
          return (<option>   </option>)
    
        const results = array.map(element =>
          (<option> {element} </option>)
      )
        return results
      }

    render(){
        return(
            <div>
                <div style={{display:"flex"}}>
                    <Link to={{
                      pathname: `/routes-maps`
                    }}
                    style={{fontSize:"1.5em", marginTop:"0.1em", marginBottom:"0.2em", marginLeft:"1em"}}>
                        <ArrowBackOutlinedIcon/>
                    </Link>
                    <h2 style={{fontSize:"1.5em", marginTop:"0.2em", marginBottom:"0.2em", marginLeft:"1em"}}>{this.props.location.state.district}</h2>
                    <div style={{textAlign: 'right', marginTop: '0.4em', marginLeft:'1em'}}>
                    <Tooltip
                          helpMessage={
                            <div >
                                <h2 style={{fontSize:"1.2em"}}>Área de Risco<br/>Todas as OSs que caírem dentro das áreas delimitadas como risco não serão roteirizadas e serão apenas sinalizadas como "Não Alocadas"</h2>
                                <h2 style={{fontSize:"1.2em"}}>Permitido Moto<br/>Áreas delimitadas para o modal moto, todas as OSs que caírem fora dessas áreas serão alocadas apenas para o modal Carro.
                                Caso não haja nenhuma área de moto delimitada, será considerada a regra atual, levando em consideração que moto pode rodar apenas na cidade do polo.</h2>
                                <h2 style={{fontSize:"1.2em"}}>Proibido Carro<br/>Todas as OSs localizadas dentro dessa área serão proibidas de cair para modal carro, podendo cair pra moto caso esteja em uma área de moto ou não ser roteirizada.</h2>
                                <h2 style={{fontSize:"1.2em"}}>Angel específico<br/>Áreas restritas que podem ser atendidas apenas pelo angel delimitado.
                                Esse angel ainda pode pegar OSs que caiam fora dessa área caso caiba em sua rota.Caso caia mais OSs que a capacidade do angel na área, essas OSs serão dadas como "Não Alocadas"</h2>
                            </div>
                        }
                        />
                </div>
                </div>
                {/* <div style={{"height":"80vh"}}> */}
                <Tabs defaultActiveKey="riskArea" id="noanim-tab-example" variant='tabs' style={{backgroundColor: '#e5e3e3', color: '#37b766'}}>
                    <Tab eventKey="riskArea" title="Áreas de Restrição" unmountOnExit='false' >
                    <div style={{display:"flex"}}>
                        <h2 style={{fontSize:"1em", marginTop:"0.3em", marginBottom:"0", marginLeft:"1em"}}>Desenhar novo polígono de: </h2>
                        <Form.Control style={{width:"15vw", marginLeft:"1em"}} id="mode" type="email" size="sm" placeholder="Status" as="select" onInput = {e => this.setState({selectedMode:document.getElementById("mode")[document.getElementById("mode").selectedIndex].text})} >
                          <option>Área de Risco</option>
                          <option>Permitido Moto</option>
                          <option>Proibido Carro</option>
                        </Form.Control>
                    </div>
                        
                        <RiskAreasMap name={this.state.selectedMode} district={this.props.location.state.district} center={this.props.location.state.mapObj}/>
                    </Tab>
                    <Tab eventKey="angel" title="Especificar Angel" unmountOnExit='false' >
                        {this.state.loading ? <h2>Carregando...</h2> :
                        <div>
                        <div style={{display:"flex"}}>
                            <h2 style={{fontSize:"1em", marginTop:"0.3em", marginBottom:"0", marginLeft:"1em"}}>Desenhar novo polígono de: </h2>
                            <Form.Control style={{width:"20vw", marginLeft:"1em"}} id="hub" type="email" size="sm" placeholder="Status" as="select" onInput = {e => this.chooseAngel(document.getElementById("hub")[document.getElementById("hub").selectedIndex].text)} >
                                <option> </option>
                                {this.buildOptionsFromArray(this.state.hubsList)}
                            </Form.Control>
                            <Form.Control style={{width:"15vw", marginLeft:"1em"}} id="angel" type="email" size="sm" placeholder="Status" as="select" onInput = {e => this.setState({selectedAngel:document.getElementById("angel")[document.getElementById("angel").selectedIndex].text})} >
                                <option> </option>
                                {this.buildOptionsFromArray(this.state.angelsList)}
                            </Form.Control>
                        </div>
                        <AngelRestrict name={this.state.selectedAngel} district={this.props.location.state.district} center={this.props.location.state.mapObj} districtDict={this.state.hubs}/>
                        </div>
                    }
                    </Tab>
                </Tabs>
                {/* </div> */}
            </div>
            
        )
    }
}

export default ConfigsMap