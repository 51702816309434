/* eslint-disable */
import React, { Component } from 'react';
import moment from 'moment';
import LogisticService from '../../core/services/LogisticService';
import Graph from '../../components/Logistic/graphic/graph';
import { Container, Col, Row } from 'react-bootstrap';
import LoadingSpinner from '../../components/LoadingSpinner';

class HubsStatusSection extends Component {

    constructor () {
        super()
        this.state = {
          today: moment().format('YYYY-MM-DD'),
          loaded: false,
          lastUpdated: undefined,
          stockOut:  {
            graph: undefined,
            loaded: false
          },
          inefficiencyOrders:  {
            graph: undefined,
            loaded: false
          },

        }
    }

    componentDidMount () {
        this.refreshReportData()
      }

    refreshReportData() {
        LogisticService.getStockOut()
        .then((response) => {
          this.buildStockOutGraph(response.data.data)
        })

        LogisticService.getInefficiencyServiceOrders()
        .then((response) => {
          this.buildInefficiencyOrders(response.data.data)
        })


  }

  buildInefficiencyOrders(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Ineficiência',
            stack: 'Stack 0',
            backgroundColor: '#18641e',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: '#2e3136',
          fontSize: 20,
          text: 'Ineficiência',
          fontFamily: 'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 15,
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            formatter: function (value) {
              if (value >= 1000) {
                return String((value/1000).toFixed(1)) + 'k'
              }
              else {
                return value
              }
            }
          },
        },
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
      },
    }




    _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.date))
        graphObj.data.datasets[0].data.push(((element.cancelations+element.revisits)*100/element.total).toFixed(1))
      }
    )



    this.setState({
      inefficiencyOrders: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  renderInefficiencyOrders() {
      return (
        <div>
            <Graph
              data={this.state.inefficiencyOrders.graph.data}
              legend={this.state.inefficiencyOrders.graph.legend}
              options={this.state.inefficiencyOrders.graph.options}
              width={this.state.isDesktop ? 500 : 350}
              height={this.state.isDesktop ? 400 : 350}
              />
        </div>)
  }


  buildStockOutGraph(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Meta',
            stack: 'Stack 1',
            backgroundColor: 'red',
            borderWidth: 2,
            data: [],
            type: 'line',
            fill: false,
            backgroundColor: 'red',
            borderColor: 'red',
            hoverBackgroundColor: 'red',
            hoverBorderColor: 'red',
            // borderColor: 'red',
            // backgroundColor: 'red',
            // pointBorderColor: 'red',
            // pointBackgroundColor: 'red',
            // pointHoverBackgroundColor: 'red',
            // pointHoverBorderColor: 'red',
            // pointRadius: 3,
            // pointHoverRadius: 5,
          },
          {
            label: 'Realizado',
            stack: 'Stack 0',
            backgroundColor: '#18641E',
            borderWidth: 2,
            data: [],
            fill: false,
            backgroundColor: '#18641E',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            type: 'linear',
            stacked: false,
            gridLines: {
              display: true,
            },
            ticks: {
              callback: function t(value) {
                return `${value}%`
            },
            suggestedMax: 1.2,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: '#2e3136',
          fontSize: 20,
          text: '% Stock Out',
          fontFamily: 'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0 ? 'auto' : false
            },
            anchor: 'end',
            align: 'top',
            font: {
              size: 11,
              weight: 'bold'
            },
            color: 'black',
            formatter: function (value) {
              return `${value.toFixed(2)}%`
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
      },
    }

    _.orderBy(obj, 'referenceDate').forEach((element) => {
        graphObj.data.labels.push(element.referenceDate)
        graphObj.data.datasets[1].data.push(element.percent/100)
        graphObj.data.datasets[0].data.push(element.goal/100)
      }
    )

    this.setState({
      stockOut: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  renderStockOutGraph() {
    if (this.state.stockOut.loaded) {
      return (
        <div>
          <Graph
            data={this.state.stockOut.graph.data}
            legend={this.state.stockOut.graph.legend}
            options={this.state.stockOut.graph.options}
            width={this.state.isDesktop ? 500 : 350}
            height={this.state.isDesktop ? 400 : 350}
      />
        </div>)
    }
  }


  render () {
    if (this.state.stockOut.loaded && this.state.inefficiencyOrders.loaded) {
    return (

      <Row style={{marginTop: '1em'}}>
        <Col xs sm md = {12} lg xl = {6}>
          {this.renderInefficiencyOrders()}
        </Col>
        <Col xs sm md = {12} lg xl = {6}>
          {this.renderStockOutGraph()}
        </Col>
      </Row>
      )
    }
    return (
      <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
        <LoadingSpinner />
      </div>)
  }
}

export default HubsStatusSection
