import React, { Component } from "react";
import PropTypes from "prop-types";
import LogisticService from "../../../core/services/LogisticService";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Container, Col, Row } from "react-bootstrap";
import Box from "@material-ui/core/Box";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import AppBar from "@material-ui/core/AppBar";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Button } from "antd";
import MaterialTable from "material-table";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Redirect, Link } from "react-router-dom";
import Tooltip from "../../../custom-components/Tooltips";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTrophy } from "@fortawesome/free-solid-svg-icons";

const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#103a21",
    color: "white",
    fontSize: "0.9em",
  },
  body: {
    fontSize: "0.8em",
  },
}))(TableCell);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class VariableRemuneration extends Component {
  constructor(props) {
    super();
    this.state = {
      loaded: false,
      selectedTab: 0,
      angelName: props.location.state.name,
      angelPosition: 0,
      month: props.location.state.month,
      year: props.location.state.year,
      period: props.location.state.month + "-" + props.location.state.year,
      mesesDisponiveis: [],
      lastUpdate: "",
      angelResults: undefined,
      avaiableNames: [],
      redirect: false,
    };
  }

  componentDidMount() {
    this.refreshReportData(
      this.state.month,
      this.state.year,
      this.state.angelName
    );
    this.requestAngelsData(
      this.state.month,
      this.state.year,
      this.state.angelName
    );
    this.getAngels();
    this.getMonths();
  }

  getMonths() {
    LogisticService.periodosDiponiveisRV()
      .then((response) => {
        this.setState({
          mesesDisponiveis: response.data.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getAngels() {
    LogisticService.listarGreenAngelsRV(this.state.year, this.state.month)
      .then((response) => {
        var districts = [];
        response.data.data.forEach((element) => {
          if (!districts.includes(element.districtName)) {
            districts.push(element.districtName);
          }
        });

        this.setState({
          avaiableNames: response.data.data,
          avaiableDistricts: districts,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshReportData(month, year, angel) {
    this.setState({
      loaded: false,
      hubName: undefined,
      lastUpdate: undefined,
      districtName: undefined,
      angelResults: undefined,
      serviceOrdersDetails: undefined,
      portalDetails: undefined,
      reversesDetails: undefined,
      screenedTerminalsDetails: undefined,
      removedTerminalsDetails: undefined,
      csatDetails: undefined,
      xrayDetails: undefined,
      collactDetails: undefined,
    });

    LogisticService.getAngelResults(year, month, angel).then((response) => {
      this.setState({
        angelResults: response.data.data[0].results,
        hubName: response.data.data[0].results.polo_name,
        lastUpdate: moment
          .utc(response.data.data[0].dtmModifiedAt)
          .local()
          .format("DD/MM/YYYY - HH:mm"),
        districtName: response.data.data[0].results.district_name,
      });
    });
  }

  ScoreCard(props) {
    var color;
    if (props.ranking !== undefined) {
      color = "#00000099";
    } else if (props.realScore / props.possibleScore >= 0.8) {
      color = "green";
    } else if (props.realScore / props.possibleScore > 0.6) {
      color = "orange";
    } else {
      color = "red";
    }

    return (
      <Card>
        <CardContent>
          <label style={{ color: "grey" }}>
            {props.ranking !== undefined ? (
              <span className="icon">
                <FontAwesomeIcon icon={faTrophy} />
              </span>
            ) : (
              ""
            )}
            {props.title}
          </label>
          <Row
            style={
              props.ranking !== undefined
                ? { marginLeft: "1em" }
                : { marginLeft: "1em", marginTop: "1em" }
            }
          >
            {props.icon}
            <Typography
              variant="h4"
              style={{ marginLeft: "0.5em", color: color }}
            >
              {props.realScore}
            </Typography>
            {props.ranking !== undefined ? (
              <div>
                <p
                  style={{
                    fontSize: "130%",
                    color: color,
                    marginBottom: "0px",
                  }}
                >
                  °
                </p>
                <Link
                  to={{
                    pathname: "/variable-remuneration-ranking",
                    state: {
                      year: props.year,
                      month: props.month,
                      selectedTab: props.selectedTab,
                    },
                  }}
                >
                  <label
                    style={{
                      cursor: "pointer",
                      fontSize: "15px",
                      color: color,
                      marginLeft: "10px",
                      marginBottom: "-10px",
                      textDecorationLine: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    Ver detalhes
                  </label>
                </Link>
              </div>
            ) : (
              <Typography variant="h6" style={{ color: color }}>
                /{props.possibleScore}
              </Typography>
            )}
          </Row>
        </CardContent>
      </Card>
    );
  }

  SummaryBoxes() {
    // this.requestAngelsData()

    if (this.state.angelResults === undefined) {
      return;
    }

    const efficiency_weight = this.state.angelResults.efficiency_column_max;
    const attendance_weight = this.state.angelResults.attendance_column_max;
    const activation_weight = this.state.angelResults.activation_column_max;
    const security_weight = this.state.angelResults.security_column_max;

    return (
      <Box>
        <Row>
          <Col xl sm={12} md lg xl={3}>
            <this.ScoreCard
              title="Pilar Eficiência"
              icon={<MonetizationOnIcon fontSize="large" />}
              realScore={(
                100 * this.state.angelResults.efficiency_column
              ).toFixed(1)}
              possibleScore={efficiency_weight}
            />
          </Col>
          <Col xl sm={12} md lg xl={3}>
            <this.ScoreCard
              title="Pilar Atendimento"
              icon={<InsertEmoticonIcon fontSize="large" />}
              realScore={(
                100 * this.state.angelResults.attendance_column
              ).toFixed(1)}
              possibleScore={attendance_weight}
            />
          </Col>
          <Col xl sm={12} md lg xl={2}>
            <this.ScoreCard
              title="Pilar Ativação"
              icon={<MobileFriendlyIcon fontSize="large" />}
              realScore={(
                100 * this.state.angelResults.activation_column
              ).toFixed(1)}
              possibleScore={activation_weight}
            />
          </Col>
          <Col xl sm={12} md lg xl={2}>
            <this.ScoreCard
              title="Atingimento Total"
              realScore={(100 * this.state.angelResults.final_score).toFixed(1)}
              possibleScore={100}
            />
          </Col>
          <Col xl sm={12} md lg xl={2}>
            <this.ScoreCard
              ranking="true"
              title="Colocação ranking"
              realScore={this.state.angelPosition}
              year={this.state.year}
              month={this.state.month}
              selectedTab={0}
            />
          </Col>
        </Row>
      </Box>
    );
  }

  efficiencyDetails() {
    return (
      <div
        style={{
          width: "70%",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2em",
          marginBottom: "2em",
        }}
      >
        <Table size="medium" padding="default">
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              <TableCell align="center">Atingimento</TableCell>
              <TableCell align="center">Atingimento pós-régua</TableCell>
              <TableCell align="center">Peso</TableCell>
              <TableCell align="center">Impacto no Pilar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">Triagens Good</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.screening_score).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.final_screening_score).toFixed(
                  2
                )}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.weights.screening).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.adjusted_screening).toFixed(2)}%
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">Reversas</StyledTableCell>
              <StyledTableCell align="center">-</StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.angelResults.final_reverse_oms_score
                ).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">-7,5%/0%</StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.angelResults.final_reverse_oms_score
                ).toFixed(2)}
                %
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">Inventário</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.inventory).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.inventory_bonus).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">-7,5%/+2,5%</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.inventory_bonus).toFixed(2)}%
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">Retroca</StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.angelResults.screening_quality_score
                ).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.angelResults.final_screening_quality_score
                ).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">-2,5%/+2,5%</StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.angelResults.final_screening_quality_score
                ).toFixed(2)}
                %
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  attendanceDetails() {
    var initial_csat_score =
      this.state.angelResults.csat_total_points /
      this.state.angelResults.csat_orders_quantity;

    return (
      <div
        style={{
          width: "70%",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2em",
          marginBottom: "2em",
        }}
      >
        <div style={{ textAlign: "right", margin: "0.7em" }}>
          <Tooltip
            helpMessage={
              <label>
                Impacto no pilar = <br /> Atingimento pós-régua * peso
              </label>
            }
          />
        </div>
        <Table size="medium" padding="default">
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              <TableCell align="center">Atingimento</TableCell>
              <TableCell align="center">Atingimento pós-régua</TableCell>
              <TableCell align="center">Peso</TableCell>
              <TableCell align="center">Impacto no Pilar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">SLA</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.raw_sla).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.final_sla_score).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.weights.sla).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.adjusted_sla).toFixed(2)}%
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">CSAT</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * initial_csat_score).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.final_csat_score).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.weights.csat).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.adjusted_csat).toFixed(2)}%
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">
                D-zero (distrito)
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.district_dzero).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.final_dzero).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {this.state.angelResults.weights.dzero
                  ? (100 * this.state.angelResults.weights.csat).toFixed(2)
                  : "+ 2,5"}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.adjusted_dzero).toFixed(2)}%
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  activationDetails() {
    return (
      <div
        style={{
          width: "70%",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2em",
          marginBottom: "2em",
        }}
      >
        <div style={{ textAlign: "right", margin: "0.7em" }}>
          <Tooltip
            helpMessage={
              <label>
                Impacto no pilar = <br /> Atingimento pós-régua * peso
              </label>
            }
          />
        </div>
        <Table size="medium" padding="default">
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              <TableCell align="center">Atingimento</TableCell>
              <TableCell align="center">Atingimento pós-régua</TableCell>
              <TableCell align="center">Peso</TableCell>
              <TableCell align="center">Impacto no Pilar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">Novos Produtos</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.new_products_score).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.angelResults.final_new_products_score
                ).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.weights.new_products).toFixed(
                  2
                )}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.adjusted_new_products).toFixed(
                  2
                )}
                %
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">Portal</StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 *
                  this.state.angelResults.percentage_of_customer_with_access
                ).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.final_portal_score).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.weights.portal).toFixed(2)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.angelResults.adjusted_portal).toFixed(2)}%
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  renderAngelSummary() {
    return (
      <div>
        <MaterialTable
          columns={[
            { title: "Pilar", field: "name" },
            { title: "Atingimento", field: "score" },
            { title: "Limite Máximo", field: "limit" },
          ]}
          options={{
            showTitle: false,
            search: false,
            paging: false,
            toolbar: false,
          }}
          data={[
            {
              name: "Eficiência",
              score:
                (100 * this.state.angelResults.efficiency_column).toFixed(1) +
                "%",
              limit: "30.0%",
            },
            {
              name: "Atendimento",
              score:
                (100 * this.state.angelResults.attendance_column).toFixed(1) +
                "%",
              limit: "40.0%",
            },
            {
              name: "Ativação",
              score:
                (100 * this.state.angelResults.activation_column).toFixed(1) +
                "%",
              limit: "30.0%",
            },
          ]}
          detailPanel={(rowData) => {
            if (rowData.name === "Eficiência") {
              return this.efficiencyDetails();
            } else if (rowData.name === "Atendimento") {
              return this.attendanceDetails();
            } else if (rowData.name === "Ativação") {
              return this.activationDetails();
            }
          }}
        />
      </div>
    );
  }

  requestOrdersDetails() {
    this.setState({
      serviceOrdersDetails: "loading",
    });

    LogisticService.getAngelOrdersData(
      this.state.year,
      this.state.month,
      this.state.angelName,
      this.state.hubName
    ).then((response) => {
      this.setState({
        serviceOrdersDetails: response.data.data,
      });
    });
  }

  formatDate(date, hours) {
    var datePart = date.slice(0, 10);

    if (hours === undefined) {
      return (
        datePart.split("-")[2] +
        "/" +
        datePart.split("-")[1] +
        "/" +
        datePart.split("-")[0]
      );
    }

    return (
      datePart.split("-")[2] +
      "/" +
      datePart.split("-")[1] +
      "/" +
      datePart.split("-")[0] +
      " " +
      date.slice(11)
    );
  }

  renderOrdersDetails() {
    if (this.state.serviceOrdersDetails === undefined) {
      if (this.state.selectedTab == 1) {
        this.requestOrdersDetails();
      }
      return;
    } else if (this.state.serviceOrdersDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];

    this.state.serviceOrdersDetails.map((order) =>
      rows.push({
        orderNumber: order.orderNumber,
        customer: order.customer,
        technicianName: order.technicianName,
        considerInSlaAccount: order.considerInSlaAccount,
        considerInTmaAccount: order.considerInTmaAccount,
        solvedWithSlaOnTime: order.solvedWithSlaOnTime,
        solvedWithSlaCustomerOnTime: order.solvedWithSlaCustomerOnTime,
        considerInDzeroAccount: order.considerInDzeroAccount,
        solvedInDzero: order.solvedInDzero,
        solvedWithAnticipation: order.solvedWithAnticipation,
        redirectPolo: order.redirectPolo,
        serviceGroup: order.serviceGroup,
        status: order.status,
        cancellationReason: order.cancellationReason,
        openingDate: this.formatDate(order.openingDate, true),
        arrivalDate: this.formatDate(order.arrivalDate, true),
        deadlineDate: this.formatDate(order.deadlineDate, true),
        customerDeadlineDate: this.formatDate(order.customerDeadlineDate, true),
        visitReason1: order.visitReason1,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "orderNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Stonecode",
            field: "customer",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Angel",
            field: "technicianName",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Conta pro SLA",
            field: "considerInSlaAccount",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Conta pro TMA",
            field: "considerInTmaAccount",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "SLA no Prazo",
            field: "solvedWithSlaOnTime",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "5em" },
          },
          {
            title: "SLA Cliente no Prazo",
            field: "solvedWithSlaCustomerOnTime",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Conta pro D0",
            field: "considerInDzeroAccount",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Resolvida em D0",
            field: "solvedInDzero",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Chamado Antecipado",
            field: "solvedWithAnticipation",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Grupo de Serviço",
            field: "serviceGroup",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Status",
            field: "status",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Motivo da Primeira Visita",
            field: "visitReason1",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Motivo de Cancelamento",
            field: "cancellationReason",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Data de Abertura",
            field: "openingDate",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Data de Atendimento",
            field: "arrivalDate",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Data Limite",
            field: "deadlineDate",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Data Limite Cliente",
            field: "customerDeadlineDate",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Redirect",
            field: "redirectPolo",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
        ]}
        data={rows}
        title="Chamados Atendidos"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          filtering: true,
          

        }}
      />
    );
  }

  requestPortalDetails() {
    this.setState({
      portalDetails: "loading",
    });

    LogisticService.getAngelPortalData(
      this.state.year,
      this.state.month,
      this.state.angelName,
      this.state.hubName
    ).then((response) => {
      this.setState({
        portalDetails: response.data.data,
      });
    });
  }

  renderPortalDetails() {
    if (this.state.portalDetails === undefined) {
      if (this.state.selectedTab == 2) {
        this.requestPortalDetails();
      }
      return;
    } else if (this.state.portalDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];

    this.state.portalDetails.map((customer) =>
      rows.push({
        customer: customer.customer,
        customerName: customer.customerName,
        // provider: customer.provider,
        neighborhood: customer.neighborhood,
        withAccess: customer.withAccess ? "Sim" : "Não",
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Stonecode",
            field: "customer",
            // type: "numeric",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Nome do Cliente",
            field: "customerName",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          // { title: 'Polo', field: 'provider', cellStyle: {fontSize: '0.8em', width: '8em'}},
          {
            title: "Bairro",
            field: "neighborhood",
            cellStyle: { fontSize: "0.8em", width: "5em" },
          },
          {
            title: "Acessou o Portal",
            field: "withAccess",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Acessos ao portal"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          filtering: true,
          

        }}
      />
    );
  }

  requestReversesDetails() {
    this.setState({
      reversesDetails: "loading",
    });

    LogisticService.getAngelReversesData(
      this.state.year,
      this.state.month,
      this.state.hubName
    ).then((response) => {
      this.setState({
        reversesDetails: response.data.data,
      });
    });
  }

  renderReversesDetails() {
    if (this.state.reversesDetails === undefined) {
      if (this.state.selectedTab == 3) {
        this.requestReversesDetails();
      }
      return;
    } else if (this.state.reversesDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];

    this.state.reversesDetails.map((customer) =>
      rows.push({
        provider: customer.provider,
        reason: customer.reason,
        scored: customer.scored,
        weekNumber: customer.weekNumber,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Polo",
            field: "provider",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Semana",
            field: "weekNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Pontuou",
            field: "scored",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Motivo",
            field: "reason",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Reversas"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          

        }}
      />
    );
  }

  requestScreenedTerminalsDetails() {
    this.setState({
      screenedTerminalsDetails: "loading",
    });

    LogisticService.getDistrictScreeningData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        screenedTerminalsDetails: response.data.data,
      });
    });
  }

  renderScreenedTerminalsDetails() {
    if (this.state.screenedTerminalsDetails === undefined) {
      if (this.state.selectedTab == 4) {
        this.requestScreenedTerminalsDetails();
      }
      return;
    } else if (this.state.screenedTerminalsDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    var date;
    this.state.screenedTerminalsDetails.map(
      (terminal) => (
        (date = terminal.screeningDate.slice(0, 10).split("-")),
        rows.push({
          serial: terminal.serial,
          statusBefore: terminal.statusBefore,
          statusAfter: terminal.statusAfter,
          screeningDate: date[2] + "/" + date[1] + "/" + date[0],
          hub: terminal.provider,
          weekNumber: terminal.weekNumber,
        })
      )
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Serial",
            field: "serial",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Status Antes",
            field: "statusBefore",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Status Depois",
            field: "statusAfter",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Polo",
            field: "hub",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Data da Triagem",
            field: "screeningDate",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Semana",
            field: "weekNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Triagens Good"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          filtering: true,
          exportButton: true,
          maxBodyHeight: 500,
          

        }}
      />
    );
  }

  requestRemovedTerminalsDetails() {
    this.setState({
      removedTerminalsDetails: "loading",
    });

    LogisticService.getDistrictRemovedTerminalsData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        removedTerminalsDetails: response.data.data,
      });
    });
  }

  renderRemovedTerminalsDetails() {
    if (this.state.removedTerminalsDetails === undefined) {
      if (this.state.selectedTab == 5) {
        this.requestRemovedTerminalsDetails();
      }
      return;
    } else if (this.state.removedTerminalsDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.removedTerminalsDetails.map((terminal) =>
      rows.push({
        serialUninstalled: terminal.serialUninstalled,
        statusBefore: terminal.statusBefore,
        serviceOrderNumber: terminal.serviceOrderNumber,
        serviceGroup: terminal.serviceGroup,
        removalDate: this.formatDate(terminal.removalDate),
        weekNumber: terminal.weekNumber,
        provider: terminal.provider,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "serviceOrderNumber",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Grupo de Serviço",
            field: "serviceGroup",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Serial",
            field: "serialUninstalled",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Polo",
            field: "provider",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Data da Retirada",
            field: "removalDate",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Semana",
            field: "weekNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Terminais retirados"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          filtering: true,
          

        }}
      />
    );
  }

  requestCSATDetails() {
    this.setState({
      csatDetails: "loading",
    });

    LogisticService.getAngelCSATData(
      this.state.year,
      this.state.month,
      this.state.angelName
    ).then((response) => {
      this.setState({
        csatDetails: response.data.data,
      });
    });
  }

  renderCSATDetails() {
    if (this.state.csatDetails === undefined) {
      if (this.state.selectedTab == 6) {
        this.requestCSATDetails();
      }
      return;
    } else if (this.state.csatDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.csatDetails.map((os) =>
      rows.push({
        satisfaction: os.satisfaction,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Satisfação",
            field: "satisfaction",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="CSAT"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          

        }}
      />
    );
  }

  requestXrayDetails() {
    this.setState({
      xrayDetails: "loading",
    });

    LogisticService.getAngelXrayData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        xrayDetails: response.data.data,
      });
    });
  }

  renderXrayDetails() {
    if (this.state.xrayDetails === undefined) {
      if (this.state.selectedTab == 7) {
        this.requestXrayDetails();
      }
      return;
    } else if (this.state.xrayDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.xrayDetails.map((os) => {
      var pendencies = os.pendencies ? "Sim" : "Não";
      var abono = os.abono ? "Sim" : "Não";
      var openingDate =
        os.openingDate.split("-")[2] +
        "/" +
        os.openingDate.split("-")[1] +
        "/" +
        os.openingDate.split("-")[0];
      var firstAccess;
      firstAccess =
        os.activationDate == null
          ? "-"
          : os.activationDate.split("-")[2] +
            "/" +
            os.activationDate.split("-")[1] +
            "/" +
            os.activationDate.split("-")[0];

      rows.push({
        xrayOrderNumber: os.serviceOrderNumber,
        customer: os.customer,
        openingDate: openingDate,
        firstAccess: firstAccess,
        pendencies: pendencies,
        abono: abono,
        angel: os.angel,
        hub: os.hub,
        district: os.district,
      });
    });

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "xrayOrderNumber",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Stonecode",
            field: "customer",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Data de Instalação",
            field: "openingDate",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Primeiro Acesso",
            field: "firstAccess",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Pendencias",
            field: "pendencies",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Abono",
            field: "abono",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Green Angel",
            field: "angel",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Polo",
            field: "hub",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="RaioX"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          filtering: true,
          

        }}
      />
    );
  }

  requestCollactDetails() {
    this.setState({
      collactDetails: "loading",
    });
    LogisticService.getAngelCollactData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        collactDetails: response.data.data,
      });
    });
  }

  renderCollactDetails() {
    if (this.state.collactDetails === undefined) {
      if (this.state.selectedTab == 8) {
        this.requestCollactDetails();
      }
      return;
    } else if (this.state.collactDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.collactDetails.map((os) => {
      var openingDate =
        os.openingDate.split("-")[2] +
        "/" +
        os.openingDate.split("-")[1] +
        "/" +
        os.openingDate.split("-")[0];
      var firstAccess;

      firstAccess =
        os.activationDate == "0001-01-01"
          ? "-"
          : os.activationDate.split("-")[2] +
            "/" +
            os.activationDate.split("-")[1] +
            "/" +
            os.activationDate.split("-")[0];

      rows.push({
        collactOrderNumber: os.collactOrderNumber,
        customer: os.customer,
        openingDate: openingDate,
        activationDate: firstAccess,
        angel: os.angel,
        hub: os.hub,
        district: os.district,
        region: os.region,
      });
    });

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "collactOrderNumber",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Stonecode",
            field: "customer",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Data de Abertura",
            field: "openingDate",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Data de Ativação",
            field: "activationDate",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Angel",
            field: "angel",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Polo",
            field: "hub",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="CSAT"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          

        }}
      />
    );
  }

  tabsPannel() {
    const handleChange = (event, newValue) => {
      this.setState({
        selectedTab: newValue,
      });
    };

    if (this.state.angelResults === undefined) {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    return (
      <div style={{ marginTop: "2em" }}>
        <AppBar position="static" style={{ backgroundColor: "#103A21" }}>
          <Tabs
            value={this.state.selectedTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Resumo" />
            <Tab label="Chamados Atendidos" />
            <Tab label="Portal" />
            <Tab label="Reversas" />
            <Tab label="Terminais Triados" />
            <Tab label="Terminais Retirados" />
            <Tab label="CSAT" />
            <Tab label="Raio-X" />
            <Tab label="Collact" />
            {/* <DownloadButton/> */}
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.selectedTab} index={0}>
          {this.renderAngelSummary()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          {this.renderOrdersDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={2}>
          {this.renderPortalDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={3}>
          {this.renderReversesDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={4}>
          {this.renderScreenedTerminalsDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={5}>
          {this.renderRemovedTerminalsDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={6}>
          {this.renderCSATDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={7}>
          {this.renderXrayDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={8}>
          {this.renderCollactDetails()}
        </TabPanel>
      </div>
    );
  }

  updatedData(value) {
    console.log(value);
    if (value !== "empty") {
      this.setState({
        period: value,
        year: value.split("-")[1],
        month: value.split("-")[0],
        angelResults: undefined,
        serviceOrdersDetails: undefined,
        portalDetails: undefined,
        reversesDetails: undefined,
        screenedTerminalsDetails: undefined,
        removedTerminalsDetails: undefined,
        csatDetails: undefined,
        xrayDetails: undefined,
        collactDetails: undefined,
      });
      this.refreshReportData(
        value.split("-")[0],
        value.split("-")[1],
        this.state.angelName
      );
      this.requestAngelsData(
        value.split("-")[0],
        value.split("-")[1],
        this.state.angelName
      );
    }
  }

  updatedAngelData(value) {
    if (value !== "Selecione um nome") {
      this.setState({
        angelName: value,
      });
      this.refreshReportData(this.state.month, this.state.year, value);
      this.requestAngelsData(this.state.month, this.state.year, value);
    }
  }

  getRangkinPosition(namesRanking, angel) {
    let position = 0;
    for (let i = 1; i <= namesRanking.length; i++) {
      if (angel === namesRanking[i - 1].angelName) {
        position = i;
        return position;
      }
    }
  }

  requestAngelsData(month, year, angel) {
    let angelRanking = undefined;
    LogisticService.getAngelsRanking(year, month).then((response) => {
      angelRanking = response.data.data;
      angelRanking = angelRanking.sort((a, b) =>
        a["results"]["final_score"] >= b["results"]["final_score"] ? -1 : 1
      );
      this.setState({
        angelPosition: this.getRangkinPosition(angelRanking, angel),
      });
    });
  }
  headPanel() {
    if (
      this.state.angelResults === undefined ||
      this.state.avaiableNames.length === 0
    ) {
      return;
    }

    return (
      <div>
        <div class="row">
          <label
            style={{
              float: "left",
              fontSize: "1.5em",
              fontWeight: "bold",
              marginLeft: "1em",
              marginBottom: "0.5em",
              marginTop: "0.5em",
            }}
          >
            Remuneração Variável
          </label>
        </div>
        <div class="row">
          <label
            style={{
              float: "left",
              fontSize: "1.5em",
              marginLeft: "1em",
              marginBottom: "0.5em",
              marginTop: "0.5em",
            }}
          >
            <Button
              color="default"
              variant="contained"
              style={{ marginRight: "1em", verticalAlign: "middle" }}
              onClick={(e) => this.setState({ redirect: true })}
            >
              Voltar
            </Button>
          </label>
        </div>
        <div class="col align-self-start" style={{ width: "100%" }}>
          <FormControl variant="outlined" margin="3em">
            <Select
              value={this.state.angelName || ""}
              onChange={(e, value) => this.updatedAngelData(value.props.value)}
              labelWidth={320}
              style={{ width: 320, marginTop: "1em" }}
            >
              <MenuItem value="Selecione um nome">Angel</MenuItem>
              {this.state.avaiableNames
                .filter((obj) => obj.districtName === this.state.districtName)
                .map((row) => (
                  <MenuItem value={row.angelName}>{row.angelName}</MenuItem>
                ))}{" "}
              )}
            </Select>
          </FormControl>
          <FormControl style={{ marginLeft: "21px" }}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.period}
              onChange={(e, value) => this.updatedData(value.props.value)}
              labelWidth={"130%"}
              style={{ width: "130%", marginTop: "1em" }}
            >
              <MenuItem value="empty">Selecione um mês</MenuItem>
              {this.state.mesesDisponiveis
                .sort(
                  (a, b) =>
                    a.referenceYear - b.referenceYear ||
                    a.referenceMonth - b.referenceMonth
                )
                .map((row) => {
                  const formattedMonth =
                    row.referenceMonth < 10
                      ? "0".concat(row.referenceMonth)
                      : row.referenceMonth;
                  return (
                    <MenuItem
                      value={row.referenceMonth + "-" + row.referenceYear}
                    >
                      {formattedMonth}/{row.referenceYear}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>

        <div class="row">
          <div class="col align-self-end">
            <label
              style={{
                float: "right",
                fontSize: "15px",
                marginLeft: "1em",
                marginBottom: "0.5em",
                marginTop: "1em",
                fontStyle: "italic",
              }}
            >
              {this.state.lastUpdate === ""
                ? ""
                : `Atualizado em ${this.state.lastUpdate}`}
            </label>
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
      </div>
    );
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/variable-remuneration",
          }}
        />
      );
    }

    return (
      <Container fluid>
        {this.headPanel()}
        {this.SummaryBoxes()}
        {this.tabsPannel()}
      </Container>
    );
  }
}

VariableRemuneration.propTypes = {
  name: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
};

VariableRemuneration.defaultProps = {
  name: "JANDERSON THIAGO CIRINO DE ALMEIDA",
  year: "2019",
  month: "10",
};

export default VariableRemuneration;
