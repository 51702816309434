import { AuthStorage } from "stonelog-auth-components";

export const authStorage = new AuthStorage();

export const getUserEmail = () => {
  const credentials = authStorage.getCredentials();

  if (credentials) {
    return credentials.user.email;
  }

  return null;
};
