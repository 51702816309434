/* eslint react/prop-types: 0 */

import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker, InfoWindow, MarkerClusterer, Polyline, Polygon } from 'google-maps-react';
import greenMarker from './greenMarker.png'
import redMarker from './redMarker.png'
import marketIcon from './marketIcon.png'

export class GoogleMap extends Component {

  renderMarket(list){

    const results = list.map(element =>
      ({ lat: element['lat'], lng: element['long'] }))

    const markers = list.map(element =>
      (
        <Marker
         position={{ lat: element['lat'], lng: element['long'] }}
         tittle={'OS'}
         onClick={this.onMarkerClick}
         icon={marketIcon}
        />
      )
    )

    return (
      <Map
        google={this.props.google}
        style={{ width: '100%', height: '100%', textAlign: 'center' }}
        zoom={13}
        initialCenter={results[0]}
        center={results[0]}
        apiKey= 'AIzaSyCZ7GsYNeQVHZs61SIyFzt8G-iq7sdrKzc'
      >
      {markers}
      <Marker
       position={results[0]}
       tittle={'Início'}
       onClick={this.onMarkerClick}
       icon={greenMarker}
      />
      <Polyline
    path={results}
    strokeColor="#468489"
    strokeOpacity={0.8}
    strokeWeight={2}
    />
    <Marker
     position={results[results.length-1]}
     tittle={'Fim'}
     onClick={this.onMarkerClick}
     icon={redMarker}
    />
    </Map>)

  }


  render () {

    return (<div style={{ textAlign: 'center', width: '100%' }}>
      {this.renderMarket(this.props.pointsList)}

    </div>)
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCZ7GsYNeQVHZs61SIyFzt8G-iq7sdrKzc'
})(GoogleMap)

// export default GoogleMap
