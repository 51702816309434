import React from 'react'
import { styles } from './style'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '../Tooltips/index'



export default function CustomizedExpansionPannel(props) {

  var tooltip;

  if (props.tooltip) {
    tooltip = (
      <div style={{textAlign: 'right', margin: '0.7em'}}>
        <Tooltip
          helpMessage={props.helpMessage}
        />
      </div>) 
    }
    else {
      tooltip = <div></div>
  }


  return (
    <ExpansionPanel defaultExpanded style={styles.ExpansionPanel}>
        <ExpansionPanelSummary style={styles.ExpansionPanelSummary} expandIcon={<ExpandMoreIcon style={{color: 'white'}}/>}>
            <p style={{'margin': '0.5em', 'fontSize': '1.6em', 'fontWeight': "Medium", 'fontFamily': 'GoBold'}}>{props.title}</p>
        </ExpansionPanelSummary>
        {tooltip}
        {props.content}
    </ExpansionPanel>
  );
}

