/* eslint-disable */
import axios from "axios";
import { authenticatedAxios } from "../../http";

import appConfig from "./appConfig";

class GaragemApi {
  static requestWithAuth(url) {
    return authenticatedAxios().get(url);
  }
  static patchWithAuth(url, body) {
    return authenticatedAxios().patch(url, body);
  }

  static deleteWithAuth(url) {
    return authenticatedAxios().delete(url);
  }

  static insertWithAuth(url, body) {
    return authenticatedAxios().delete(url, body);
  }
}

function buscarAcidentes(ano, mes, distrito) {
  return GaragemApi.requestWithAuth(
    `${appConfig.logisticsUrl}/seguranca/acidentes`
  );
}
function buscarMultas(ano, mes, distrito) {
  return GaragemApi.requestWithAuth(
    `${appConfig.logisticsUrl}/seguranca/multas`
  );
}
function buscarIndicadores(ano, mes, distrito) {
  return GaragemApi.requestWithAuth(
    `${appConfig.logisticsUrl}/seguranca/indicadores`
  );
}

function inserirAcidente(novosDados) {
  return GaragemApi.insertWithAuth(
    `${appConfig.logisticsUrl}/seguranca/acidentes`,
    novosDados
  );
}

function inserirMulta(novosDados) {
  return GaragemApi.insertWithAuth(
    `${appConfig.logisticsUrl}/seguranca/multas`,
    novosDados
  );
}

function inserirIndicador(novosDados) {
  return GaragemApi.insertWithAuth(
    `${appConfig.logisticsUrl}/seguranca/indicadores`,
    novosDados
  );
}
function atualizarAcidente(id, novosDados) {
  return GaragemApi.patchWithAuth(
    `${appConfig.logisticsUrl}/seguranca/acidentes?id=${id}`,
    novosDados
  );
}
function atualizarMulta(id, novosDados) {
  return GaragemApi.patchWithAuth(
    `${appConfig.logisticsUrl}/seguranca/multas?id=${id}`,
    novosDados
  );
}
function atualizarIndicador(id, novosDados) {
  return GaragemApi.patchWithAuth(
    `${appConfig.logisticsUrl}/seguranca/indicadores?id=${id}`,
    novosDados
  );
}

function removerAcidente(id) {
  return GaragemApi.deleteWithAuth(
    `${appConfig.logisticsUrl}/seguranca/acidentes?id=${id}`
  );
}

function removerMulta(id) {
  return GaragemApi.deleteWithAuth(
    `${appConfig.logisticsUrl}/seguranca/multas?id=${id}`
  );
}

function removerIndicador(id) {
  return GaragemApi.deleteWithAuth(
    `${appConfig.logisticsUrl}/seguranca/indicadores?id=${id}`
  );
}

export {
  buscarAcidentes,
  buscarMultas,
  buscarIndicadores,
  inserirAcidente,
  inserirMulta,
  inserirIndicador,
  atualizarAcidente,
  atualizarMulta,
  atualizarIndicador,
  removerAcidente,
  removerMulta,
  removerIndicador,
};
