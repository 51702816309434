import React from "react";
import {
  Navbar, Nav, NavDropdown, Button,
} from "react-bootstrap";

import Icon from "./teste4.png";
import "./style.css";
import { authStorage, getUserEmail } from "../../storage";

function hasAccess() {
  const users = [
    "gabriel.xara@stone.com.br",
    "boneves@stone.com.br",
    "mtourinho@stone.com.br",
    "mariana.brito@stone.com.br",
    "marcelo.gavioli@stone.com.br",
    "murilo.trassi@stone.com.br",
    "ymarinho@stone.com.br",
    "trossignoli@stone.com.br",
    "maria.ssantos@stone.com.br",
    "kayalla.lino@stone.com.br",
    "ully.siqueira@stone.com.br",
    "leonardo.amazonas@stone.com.br",
    "altieri.silva@stone.com.br",
    "anderson.asilva@stone.com.br",
    "luciano.vieira@stone.com.br",
    "fabio.martins@stone.com.br",
    "daniela.asilva@stone.com.br",
    "celio.junior@stone.com.br",
    "anderson.fsilva@stone.com.br",
    "celio.junior@stone.com.br",
    "fabio.martins@stone.com.br",
    "daniela.asilva@stone.com.br",
    "na.reis@stone.com.br",
    "rodrigo.mendes@stone.com.br",
    "roberto.msilva@stone.com.br",
    "hudson.quirino@stone.com.br",
    "luiz.faustino@stone.com.br",
    "renato.ribeiro@stone.com.br",
    "gustavo.ambrosio@stone.com.br",
    "edson.coelho@stone.com.br",
    "rodrigo.mendes@stone.com.br",
    "andreza.feitosa@stone.com.br",
    "fabricio.mendes@stone.com.br",
    "eric.ribeiro@stone.com.br",
    "felipe.lsouza@stone.com.br",
    "alvaro.melo@stone.com.br",
    "daniel.heinzle@stone.com.br",
  ];
  const user = getUserEmail();

  if (!users.includes(user)) {
    return true;
  }
  return false;
}

const logout = () => {
  // TODO: change to original logout
  authStorage.clearCredentials();
  window.location.href = "/logout";
};

const StoneNavBar = () => (
  <div style={{
    backgroundColor: "#103a21", width: "100%", position: "fixed", zIndex: "2",
  }}
  >
    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Navbar.Brand href="/reports/last-mile">
        <img src={Icon} width="42" height="30" className="d-inline-block align-top" alt="StoneLog" />
        StoneLog
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <NavDropdown title="Ordens de Serviço" id="collasible-nav-dropdown" style={{ marginLeft: "1em" }}>
            <NavDropdown.Item href="/reports/last-mile" className="option">
              {" "}
              Visão Geral
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/regions" className="option">
              Operação Própria
            </NavDropdown.Item>
            <NavDropdown.Item href="/states/paytec" className="option">
              Operação Paytec
            </NavDropdown.Item>
            <NavDropdown.Item href="/providers/franchise" className="option">
              Operação Franquias
            </NavDropdown.Item>
            <NavDropdown.Item href="/the-flash" className="option">
              The Flash
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title="Estoque"
            id="collasible-nav-dropdown"
            style={{ marginLeft: "1em" }}
          >
            <NavDropdown.Item href="/reports/capex-position" className="option">
              Gestão da Cadeia
            </NavDropdown.Item>
            <NavDropdown.Item href="/movement-orders" className="option">
              Ordens de Movimentação
            </NavDropdown.Item>
            <NavDropdown.Item href="/lab-cycle" className="option">
              Ciclo LAB
            </NavDropdown.Item>
            <NavDropdown.Item href="/screening-cycle" className="option">
              Ciclo Triagem
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Novos Produtos" id="collasible-nav-dropdown" style={{ marginLeft: "1em" }}>
            <NavDropdown.Item href="/xray" className="option">
              Novos Produtos
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Outros" id="collasible-nav-dropdown" style={{ marginLeft: "1em" }}>
            <NavDropdown.Item href="/one-page" className="option">
              One Page
            </NavDropdown.Item>
            <NavDropdown.Item href="/useful-links" className="option">
              Links Úteis
            </NavDropdown.Item>
            <NavDropdown.Item href="/terminal-radar" className="option">
              Localizar Ativos
            </NavDropdown.Item>
            <NavDropdown.Item href="/dash-liga" className="option">
              Liga
            </NavDropdown.Item>
            <NavDropdown.Item href="/input-liga" className="option">
              Input de Árvore LIGA
            </NavDropdown.Item>
            <NavDropdown.Item href="/idtreport" className="option">
              IDR
            </NavDropdown.Item>
            <NavDropdown.Item href="/client_data" className="option">
              Dados de clientes
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/variable-remuneration" className="option">
              Remuneração Variável
            </NavDropdown.Item>
            <NavDropdown.Item href="/case-classification" className="option">
              Classificação de casos
            </NavDropdown.Item>
            <NavDropdown.Item href="/district-portal" className="option">
              Distritais
            </NavDropdown.Item>
            <NavDropdown.Item href="/hunter" className="option">
              Caça
            </NavDropdown.Item>
            <NavDropdown.Item href="/dash-log" className="option">
              Operações
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title="Expedições"
            id="collasible-nav-dropdown"
            hidden={hasAccess()}
            style={{ marginLeft: "1em" }}
          >
            <NavDropdown.Item href="/leadtime" className="option">
              Leadtimes
            </NavDropdown.Item>
            <NavDropdown.Item href="/expedition" className="option">
              Expedição
            </NavDropdown.Item>
            <NavDropdown.Item href="/otif" className="option">
              OTIF - Validação
            </NavDropdown.Item>
            <NavDropdown.Item href="/trucker-dash" className="option">
              Trucker
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav style={{ marginLeft: "1em", textAlign: "right" }}>
          <div style={{ display: "inline-block" }}>
            <div style={{ color: "white", paddingRight: "1em", display: "inline-block" }}>{localStorage.userEmail}</div>
            <Button variant="outline-light" size="sm" onClick={logout}>
              Sair
            </Button>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>
);

export default StoneNavBar;
