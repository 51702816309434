/* eslint-disable */
import React, { Component } from "react";
import LogisticService from "../../../core/services/LogisticService";
import SortingTable from "../../../custom-components/SortingTable";

const columnsPortal = [
  {
    title: "Angel",
    dataIndex: "angel",
    sorter: (a, b) => (a.angel < b.angel ? -1 : 1),
  },
  {
    title: "% Acesso",
    dataIndex: "accessPercentualView",
    sorter: (a, b) => a.accessPercentual - b.accessPercentual,
  },
  {
    title: "Acessos Para 88%",
    dataIndex: "accessEightyEight",
    sorter: (a, b) => a.accessEightyEight - b.accessEightyEight,
  },
];

export class Portal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      district: props.district,
      date: props.date,
      portal: {
        loaded: false,
      },
      graph: undefined,
      loading: false,
      lastUpdated: undefined,
    };
  }

  componentDidMount() {
    LogisticService.getLmpActivation(
      this.state.district,
      "Portal",
      `${this.state.date}-01`,
      `${this.state.date}-01`
    ).then((response) => {
      response.data.data.forEach((item) => {
        item.accessPercentual = (((item.active * 1.0) / item.total) * 100).toFixed(0);
        item.accessPercentualView = `${item.accessPercentual}%`;
        item.accessEightyEight =
          item.untilEightyeight - item.active > 0
            ? item.untilEightyeight - item.active
            : 0;
      });
      this.setState({
        portalData: response.data.data,
        loaded: true,
      });
    });
  }

  render() {
    return (
      <SortingTable
        title="Portal"
        columns={columnsPortal}
        data={this.state.portalData}
      />
    );
  }
}

export default Portal;
