import Grid from "@material-ui/core/Grid"
import MaterialTable from 'material-table'
import React from 'react'
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap'
import LoadingSpinner from '../../components/LoadingSpinner'
import LogisticService from '../../core/services/LogisticService'
import { Select } from 'antd';

class AppNotifications extends React.Component {
    constructor() {

        super();

        this.state = {
            children: [],
            message: '',
            loading_list: true,
            loading_save: false,
            error: false,
            data: [],
            success: false,
            titulo: null,
            texto: null,
            polos: [],
            ativo: null,
            cols: [
                { title: 'Data', field: 'data' },
                { title: 'Título', field: 'titulo' },
                { title: 'Texto', field: 'texto' },
                { title: 'Polos', field: 'polos' },
            ],
            polos_list: {},
            all_polos: false
        }
    }

    renderSelect() {
        const { Option } = Select;
        LogisticService.getHubsList().then((response) => {
            response.data.data.map((item) => {
                this.state.children.push(<Option key={item['id']} label={item['hub']}>{item['hub']}</Option>);
            });
        });
    }

    handleChange = (value) => {
        this.setState({
            polos: value
        });
    }

    componentDidMount() {
        this.listaNotificacoes()
        this.renderSelect()
    }

    listaNotificacoes = () => {

        this.setState({
            loading_list: true,
        });

        LogisticService.getAppNotifications().then((response) => {

            LogisticService.getHubsList().then((hubs) => {
                hubs.data.data.map((item) => {
                    this.state.polos_list[item['id']] = item['hub'];
                });

                let list = [];
                let date = new Date();
                let hubs_names = [];

                response.data.data.map((item) => (
                    date = new Date(item.dataEnvio),

                    item.polos.map((polo) => {
                        hubs_names.push(this.state.polos_list[polo])
                    }),
                    list.push({
                        'data': `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}, ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`,
                        'titulo': item.titulo,
                        'texto': item.texto,
                        'polos': hubs_names.join(', ')
                    }),
                    hubs_names = []
                ));

                this.setState({
                    loading_list: false,
                    data: list
                })
            });

        });
    };

    enviaNotificacao = () => {
        this.setState({
            loading_save: true,
            error: false
        });

        let input = {};

        if (this.state.all_polos) {
            this.state.children.map((item) => {
                if (item.props.children.includes("STONE PAGAMENTOS")){
                    this.state.polos.push(item.key);
                }
            })
        }

        input['titulo'] = this.state.titulo;
        input['texto'] = this.state.texto;
        input['polos'] = this.state.polos;

        LogisticService.postAppNotification({ 'input': input }).then((response) => {
            this.setState({
                success: response.data.success,
                error: !response.data.success,
                loading_save: false,
                message: response.data.message
            });
            this.listaNotificacoes();
        })
    };


    toggleCheckbox = () => {
        this.setState({
            all_polos: !this.state.all_polos,
        }, function () { this.handleChange([]); });
    };

    render() {
        return (
            <Grid fluid style={{ 'paddingLeft': 0, 'paddingRight': 0 }}>
                <Row style={{ 'marginLeft': '1em', 'marginRight': '1em', 'fontSize': '0.9em' }}>
                    <Col lg="3" />
                    <Col lg="6" sm="12" xs="12">
                        <Card className="text-center">
                            <Card.Header style={{ 'backgroundColor': '#103a21', 'color': 'white' }}>Enviar notificações App Green Angels</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Group as={Col}>
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control type="text" id="titulo" placeholder="Título da notificação" onChange={(e) => this.setState({ titulo: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Texto</Form.Label>
                                        <Form.Control type="text" as="textarea" id="texto" placeholder="Texto da notificação" onChange={(e) => this.setState({ texto: e.target.value })} style={{ maxHeight: '200px', minHeight: '100px', }} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Polos</Form.Label>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecione os polos"
                                            onChange={this.handleChange}
                                            optionFilterProp="label"
                                        >
                                            {this.state.children}
                                        </Select>
                                        <left>
                                            <input type='checkbox' id='check' onClick={() => this.toggleCheckbox()}></input>
                                            <label for='check'>Selecionar Todos os Polos Stone</label>
                                        </left>
                                    </Form.Group>
                                </Form>
                                <Button variant="success" onClick={this.enviaNotificacao}>Enviar</Button>
                                <div hidden={!this.state.loading_save} style={{ width: 'center', textAlign: 'center' }}>
                                    <LoadingSpinner />
                                </div>
                                <Alert hidden={!this.state.success} variant="success" style={{ margin: '24px' }}>
                                    <Alert.Heading>Boa!</Alert.Heading>
                                    <p>
                                        {this.state.message}
                                    </p>
                                    <Button onClick={() => this.setState({ success: false })} variant="outline-success">
                                        Ok!
                                    </Button>
                                </Alert>
                                <Alert hidden={!this.state.error} variant="danger" style={{ margin: '24px' }}>
                                    <Alert.Heading>Ocorreu um erro! ):</Alert.Heading>
                                    <p>
                                        {this.state.message}
                                    </p>
                                    <Button onClick={() => this.setState({ error: false })} variant="outline-danger">
                                        Ok :/
                                    </Button>
                                </Alert>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ 'margin-left': 0, 'margin-top': '24px', 'margin-right': 0 }}>
                    <Col style={{ 'display': 'flex', 'justifyContent': 'center' }} lg="12" sm="12" xs="12">
                        {this.state.loading_list ?
                            <LoadingSpinner />
                            :
                            <MaterialTable
                                title="Notificações enviadas"
                                style={{ padding: '0 16px', marginBottom: '24px', zIndex: 0, width: '100%' }}
                                columns={this.state.cols}
                                data={this.state.data}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                    },
                                    header: {
                                        actions: 'Ações'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Nenhuma notificação',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        },
                                    }
                                }}
                            />
                        }
                    </Col>
                </Row>
            </Grid>
        )
    }

}


export default AppNotifications;