/* eslint-disable */
import React, { Component } from 'react'

import LogisticService from '../../../core/services/LogisticService'
import style from '../style.css'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { withStyles } from '@material-ui/core/styles';
import { Statistic, Icon, Col, Row, Card} from 'antd';
import Box from '@material-ui/core/Box';

    const SimpleBox = props => {
        const { header, result, goal, direction, percentage } = props
        let headlightSymbol;
        let headlightColor;
      
        return (
            <Card style={{marginTop:'1em', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Statistic
                title={<h3 style={{fontFamily: 'GoBold', fontSize: '0.8em', marginBottom: '0em'}}>{header}</h3>}
                value={result + (percentage ? '%' : '')}
                valueStyle={{ fontFamily: 'GoBold', color: {headlightColor} }}
              />
          </Card>
          )
      };

class Absenteeism extends Component {
constructor(props) {
    super(props);
    this.state = {
        district: props.district,
        date:props.date,
        Accidents:undefined,
        graph: undefined,
        loading: false,
        lastUpdated: undefined,
    };
    this.refreshReportData = this.refreshReportData.bind(this)
}

refreshReportData() {
//Get Attendance Delay Reason
LogisticService.getLmpPeople('absent',this.state.district,`${this.state.date}-01`,`${this.state.date}-01`).then((response)=>{  
    this.setState({
        Accidents:{
                data:response.data.data,
                loaded: true
                },
            })
    })
  }

  componentDidMount() {
    this.refreshReportData()
  }

  renderContent(){
    if(this.state.Accidents != undefined){
        let dataCollact =[]
        this.state.Accidents.data.forEach((item)=>
        {
            dataCollact.push(item.mini,item.med,item.maxi)
        }
      )
       return(
        <div>
        <h3 style={{fontFamily: 'GoBold', fontSize: '0.9em', marginTop:'1em', height: '10px'}}>Absenteísmo</h3>
        <Row >
            <Col md lg xl = {10}>
            <SimpleBox
              header='HC Min'
              direction ='normal'
              result={dataCollact[0]}
              goal={97}
              percentage={false}
            />
            </Col>
            <Col md lg xl = {2}></Col>
            <Col md lg xl = {10}>
              <SimpleBox
                header='HC Med'
                direction ='normal'
                result={dataCollact[1].toFixed(1)}
                goal={90}
                percentage={false}
                />
            </Col>
        </Row>
        <Row >
            <Col md lg xl = {10}>
            <SimpleBox
              header='HC Max'
              direction ='normal'
              result={dataCollact[2]}
              goal={97}
              percentage={false}
            />
            </Col>
            <Col md lg xl = {2}></Col>
            <Col md lg xl = {10}>
              <SimpleBox
                header='Absenteísmo Médio'
                direction ='normal'
                result={(dataCollact[1]*100/dataCollact[2]).toFixed(1)}
                goal={90}
                percentage={true}
                />
            </Col>
        </Row>

        </div>
        )
    }
    else{
        return(
            <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
            </div>
        )
    }
  }

    render(){
        return(this.renderContent())
  
    }

}
export default Absenteeism