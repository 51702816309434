/* eslint-disable */
import React, { Component } from "react";
import LogisticService from "../../../core/services/LogisticService";
import SortingTable from "../../../custom-components/SortingTable";

const columnsReverse = [
  {
    title: "Polo",
    dataIndex: "provider",
    width: 260,
    sorter: (a, b) => (a.provider < b.provider ? -1 : 1),
  },
  {
    title: "Semana 1",
    dataIndex: "weekoneResult",
    sorter: (a, b) => (a.weekone < b.weekone ? -1 : 1),
  },
  {
    title: "Semana 2",
    dataIndex: "weektwoResult",
    sorter: (a, b) => (a.weektwo < b.weektwo ? -1 : 1),
  },
  {
    title: "Semana 3",
    dataIndex: "weekthreeResult",
    sorter: (a, b) => (a.weekthree < b.weekthree ? -1 : 1),
  },
  {
    title: "Semana 4",
    dataIndex: "weekfourResult",
    sorter: (a, b) => (a.weekfour < b.weekfour ? -1 : 1),
  },
];

export class Reverse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      district: props.district,
      date: props.date,
      reverse: {
        loaded: false,
      },
      graph: undefined,
      loading: false,
      lastUpdated: undefined,
    };
  }
  returnRow(value, penalty) {
    return (
      <div>
        {value}
        {"  "}
        {this.getClassNameForStatus(penalty)}
      </div>
    );
  }
  getClassNameForStatus(status) {
    if (status > 0) return <span class="redDot"></span>;
    return <span class="greenDot"></span>;
  }

  componentDidMount() {
    LogisticService.getLmpEfficiency(
      this.state.district,
      "reverse",
      `${this.state.date}-01`,
      `${this.state.date}-01`
    ).then((response) => {
      response.data.data.forEach((item) => {
        item.weekoneResult = this.returnRow(item.weekone, item.penaltyone);
        item.weektwoResult = this.returnRow(item.weektwo, item.penaltytwo);
        item.weekthreeResult = this.returnRow(item.weekthree, item.penaltythree);
        item.weekfourResult = this.returnRow(item.weekfour, item.penaltyfour);
      });
      this.setState({
        reverseData: response.data.data,
        loaded: true,
      });
    });
  }

  render() {
    return (
      <SortingTable
        title="Eficiência - Reversa"
        columns={columnsReverse}
        data={this.state.reverseData}
        scroll={760}
      />
    );
  }
}

export default Reverse;
