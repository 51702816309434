/* eslint-disable */
import React, { Component } from 'react'

import LogisticService from '../../../core/services/LogisticService'
import style from '../style.css'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { withStyles } from '@material-ui/core/styles';

import Attention from '../newImagens/attention.png'
import Bad from '../newImagens/bad.png'
import Bomb from '../newImagens/bomb.png'
import Good from '../newImagens/good.png'
import Star from '../newImagens/star.png'

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CustomizedTable from '../../../custom-components/CustomizedTable';
import TablePaging from '../../../custom-components/CustomizedTablePagingSort';
import Box from '@material-ui/core/Box';

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: "#103a21",
      color: "white",
      fontSize: "0.9em",
      textalign: "center",
    },
    body: {
      fontSize: "0.13em",
      textalign: "center",
    },
    }))(TableCell);

class PeoplePrincipal extends Component {
constructor(props) {
    super(props);
    this.state = {
        district: props.district,
        date: props.date,
        graph: undefined,
        loading: false,
        lastUpdated: undefined,
    };
    this.refreshReportData = this.refreshReportData.bind(this)
}

refreshReportData() {

    LogisticService.getLmpPeople('geral',this.state.district,`${this.state.date}-01`,`${this.state.date}-01`).then((response)=>{  
        this.setState({
            Goals:{
                    data:response.data.data,
                    loaded: true
                    },
                })
        })
    
}

returnStatus (status,meta) {
    if (status == '') return ''  
    if (status > 3) return <img className="iconCard" src={Star}></img>
    if (status === 3) return <img className="iconCard" src={Good}></img>
    if (status === 2) return <img className="iconCard" src={Attention}></img>
    if (status === 1) return <img className="iconCard" src={Bad}></img>
    if (status < 1) return <img className="iconCard" src={Bomb}></img>
    return <span class="grayDot"></span>  
}

createQuadrantsTable(title,subtitle,dataTitle,data,type){
    let rows =[]
    if(type === 'Principal'){
    for(let i = 0; i < dataTitle.length;i++){ 
        rows.push(
        <TableRow >
            <StyledTableCell>{dataTitle[i]}</StyledTableCell>
            <StyledTableCell>{this.returnStatus(data[i])}</StyledTableCell>
        </TableRow>
        )
    }
        return(
        <CustomizedTable
            rows ={rows}
            title ={title?title:[{name:''}]}
            subTitle ={subtitle?subtitle:''}
            colSpan ={"2"}
        /> 
        )
    }
    else{
        data.forEach((item)=>{
            let aux = []
            for(let i = 0; i < item.length ; i++)
            aux.push(item[i])
            rows.push(aux)
        })
        return(
        <TablePaging
            data ={rows}
            title ={title?title:[{name:''}]}
            subTitle ={subtitle?subtitle:[{name:''}]}
            colSpan ={rows.length}
            rowSpan = {5}
        /> 
        )
    }
}

    render(){
        let tableTitle = [{name:'' }]
        let rowsTitle =['Absenteísmo','Acidentes','Horário Baixa']
        let data = ['','','','']
        return(this.createQuadrantsTable(tableTitle,undefined,rowsTitle,data,'Principal'))
    }

}
export default PeoplePrincipal