import React from "react";
import LogisticService from "../../core/services/LogisticService";
import XLSX from "xlsx";
import MaterialTable from 'material-table';
import {Alert, Button} from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";
import { toUpper } from "ramda";

export default class AlocAngels extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            data: props.data,
            hub: props.hub,
            cols:  [
                { title: "Stonecode", field: "stonecode", editable: 'never'}
            ]
        };

    }

    
    render(){

    
    
    return (
                <MaterialTable
                    title="Angels"
                    width="90vw"
                    columns={this.state.cols}
                    data={this.state.data}
                    options={{
                        rowStyle: rowData => ({
                            backgroundColor: (rowData.modificationDate > rowData.solicitationDate && rowData.omNumber === '0') ? '#ffb1ab' : '#FFF'
                        }),
                        exportButton: false,
                        pageSize: 3
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                        },
                        header: {
                            actions: 'Ações'
                        },
                        body: {
                            emptyDataSourceMessage: 'Nenhum angel encontrado',
                            filterRow: {
                                filterTooltip: 'Filter'
                            },
                            editRow: {
                                deleteText: 'Tem certeza que deseja deletar?'
                            }
                        }
                    }}
                    />
        );
    }

}
