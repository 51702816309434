/* eslint-disable */

import React, {Component, PropTypes} from 'react'
import moment from 'moment'
import _ from 'lodash'
import style from './style.css'
import LogisticService from '../../core/services/LogisticService'
import { Button } from 'antd'
import LoadingSpinner from '../../components/LoadingSpinner'
import RefreshButton from '../../custom-components/RefreshButton'
import { Container, Col, Row, Form, Card, Alert } from 'react-bootstrap'
import { SheetJSAppMembers } from "../../custom-components/SheetJs/SheetJsMembers";
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import XLSX from "xlsx";
import { ListMembers } from './materialTable'



class PeopleInsert extends Component {
  constructor() {
    super();

    this.state = {
      today: moment().format('YYYY-MM-DD'),
      loading: false,
      lastUpdated: undefined,
      selectedRegion: '',
      selectedDistrict: '',
      selectedHub: '',
      data: []
    }

    this.refreshReportData = this.refreshReportData.bind(this)
  }

  componentDidMount() {
    this.refreshReportData()
  }

  refreshReportData() {
    const {today} = this.state
    this.setState({
	  lastUpdated: undefined
	})


  }

  searchMembers = () => {
      this.setState({
          loading: true,
      });

      let filters = {'member_name': this.state.memberNameSearch,
          'status': this.state.statusSearch,
          'cpf': this.state.cpfSearch,
          'topic': this.state.topicSearch
      };


      LogisticService.getMembers({'filters': filters}).then((response) => {
          this.setState({
              loading: false,
          });

          this.setState({
              data: response.data.data
          })


      }, (reason => {
          this.setState({
              loading: false,
          });
      }));
  };


  handleChange() {

    var nowFunction = document.getElementById("nowFunction")
    nowFunction = nowFunction[nowFunction.selectedIndex].text
    var status = document.getElementById("status")
    status = status[status.selectedIndex].text
    var contractType = document.getElementById("contractType")
    contractType = contractType[contractType.selectedIndex].text
    var gender = document.getElementById("gender")
    gender = gender[gender.selectedIndex].text
    var topic = document.getElementById("topic")
    topic = topic[topic.selectedIndex].text 
    var adminUtd = document.getElementById("adminUtd")
    adminUtd = adminUtd[adminUtd.selectedIndex].text


    this.setState({
       nowFunction: nowFunction,
       status: status,
       contractType: contractType,
       gender: gender,
       topic: topic,
       adminUtd: adminUtd
      })
    }

    exportFile() {
        let worksheet_example = [['status', 'nome', 'unidadeAdministrativa', 'funcaoAtual', 'assunto', 'estado', 'tipoDeContrato', 'recrutadorResponsavel',
              'dataAdmissao', 'cpf', 'dataNascimento', 'genero', 'corpEmail', 'modalOperacao', 'checkValidadorFinal']]

        const ws = XLSX.utils.aoa_to_sheet(worksheet_example);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, "excelExemplo.xlsx");
    }


  render() {

      return (
        <Grid fluid style={{ 'paddingLeft': 0, 'paddingRight': 0 }}>
            <Row style={{' margin-left': 0, 'margin-right': 0 }}>
                <Col lg="1"/>
                <Col lg="6" sm="12" xs="12">
                    <Card className="text-center">
                        <Card.Header style={{'backgroundColor': '#103a21', 'color':'white'}}>Inserir Membro</Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Row>
                                  <Col lg="9" sm="12" xs="12">
                                    <Form.Group as={Col}>
                                        <Form.Control type="text" id="memberName" placeholder="Nome" onChange={() => this.setState({memberName: document.getElementById("memberName").value})} />
                                    </Form.Group>
                                  </Col>
                                  <Col lg="3" sm="12" xs="12">
                                    <Form.Group as={Col}>
                                      <Form.Control type="text" id="country" placeholder="Estado" onChange={() => this.setState({country: document.getElementById("country").value})} />
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Função Atual</Form.Label>
                                        <Form.Control id="nowFunction" size="sm"  as="select" onChange = {e => this.handleChange()} >
                                          <option> </option>
                                          <option>Green Angel</option>
                                          <option>Maker Angel</option>
                                          <option>Supplier</option>
                                          <option>Trainer</option>
                                          <option>Líder Distrital</option>
                                          <option>Líder Regional</option>
                                          <option>Overhead</option>
                                          <option>Líder Assunto</option>
                                          <option>Guardian Angel</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control id="status" size="sm"  as="select" onChange = {e => this.handleChange()} >
                                          <option> </option>
                                          <option>Ativo</option>
                                          <option>Afastado</option>
                                          <option>Inativo</option>
                                          <option>Trans. Interna</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Tipo de Contrato</Form.Label>
                                        <Form.Control id="contractType" size="sm"  as="select" onChange = {e => this.handleChange()} >
                                          <option> </option>
                                          <option>CLT</option>
                                          <option>JAP</option>
                                          <option>TCE</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <hr />
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Data de Nascimento</Form.Label>
                                        <Form.Control id="birthDate" size="sm"  type="date" onChange={() => this.setState({birthDate: document.getElementById("birthDate").value})} >
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>CPF</Form.Label>
                                        <Form.Control id="cpf" size="sm"  type="text" placeholder="00000000000" onChange={() => this.setState({cpf: document.getElementById("cpf").value})} >
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Gênero</Form.Label>
                                        <Form.Control id="gender" size="sm"  as="select" onChange = {e => this.handleChange()} >
                                          <option> </option>
                                          <option>Feminino</option>
                                          <option>Masculino</option>
                                          <option>Outro</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <hr />
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Data de Admissão</Form.Label>
                                        <Form.Control id="admissionDate" size="sm"  type="date" onChange={() => this.setState({admissionDate: document.getElementById("admissionDate").value})} >
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>E-mail Corporativo</Form.Label>
                                        <Form.Control id="corpMail" size="sm"  type="email" placeholder="exemplo@stone.com.br" onChange={() => this.setState({corpMail: document.getElementById("corpMail").value})} >
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Control id="recruiter" placeholder="Recrutador" size="sm"  type="text" onChange={() => this.setState({recruiter: document.getElementById("recruiter").value})} >
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Control id="finalCheck" placeholder="Check Validador Final" size="sm"  type="text" onChange={() => this.setState({finalCheck: document.getElementById("finalCheck").value})} >
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Assunto</Form.Label>
                                        <Form.Control id="topic" size="sm" as="select"  type="text" onChange = {e => this.handleChange()} >
                                        <option> </option>
                                        <option>BP GENTE - LOGISTICA</option>
                                        <option>BP GENTE - RECRUTAMENTO</option>
                                        <option>FROTAS</option>
                                        <option>GESTAO DE ATIVOS</option>
                                        <option>LAST MILE - OPERAÇÃO PROPRIA</option>
                                        <option>LAST MILE- OPERAÇÃO TERCEIRA</option>
                                        <option>LAST MILE- CENTRAL</option>
                                        <option>LOGISTICA</option>
                                        <option>LOGISTICA-CONTROLE</option>
                                        <option>LOGISTICA-PLANEJAMENTO</option>
                                        <option>LOGISTICA-PLANEJAMENTO E CONTROLE</option>
                                        <option>LOGISTICA-SISTEMAS</option>
                                        <option>NOVOS NEGOCIOS</option>
                                        <option>SUPPLY CHAIN</option>
                                        <option>TELECOM</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Unidade Administrativa</Form.Label>
                                        <Form.Control id="adminUtd" size="sm" as="select"  type="text" onChange = {e => this.handleChange()} >
                                          <option> </option>
                                          <option>Polos</option>
                                          <option>Distrito(Distritais)</option>
                                          <option>Região(Regionais)</option>
                                          <option>Escritório Rio e SP</option>
                                          <option>CD Log</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                            <br/>
                            <Button variant="success" disabled={!this.state.memberName} onClick={this.saveSingleLeadtime} >Salvar</Button>
                            <hr />

                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="1"/>
                <hr />
                <Col lg="4" sm="12" xs="12">
                    <Card className="text-center">
                        <Card.Header style={{'backgroundColor': '#103a21', 'color':'white'}}>Buscar Membro</Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Member</Form.Label>
                                        <Form.Control type="text" id="memberNameSearch" placeholder="Nome" onChange={() => this.setState({memberNameSearch: document.getElementById("memberNameSearch").value})} />
                                    </Form.Group>
                                </Form.Row>
                                <hr />
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control type="text" id="statusSearch" placeholder="Status" onChange={() => this.setState({statusSearch: document.getElementById("statusSearch").value})} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>CPF</Form.Label>
                                        <Form.Control type="text" id="cpfSearch" placeholder="CPF" onChange={() => this.setState({cpfSearch: document.getElementById("cpfSearch").value})} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Assunto</Form.Label>
                                        <Form.Control type="text" id="topicSearch" placeholder="Assunto" onChange={() => this.setState({topicSearch: document.getElementById("topicSearch").value})} />
                                    </Form.Group>
                                </Form.Row>
                                <Button variant="success" onClick={this.searchMembers} >Buscar</Button>
                                <hr />
                            </Form>
                            <p>Importar Membros pelo Excel</p>
                            <Button variant="success" onClick={this.exportFile} >Exportar planilha exemplo</Button>
                            <Alert hidden={!this.state.success} variant="success">
                                <Alert.Heading>Boa!</Alert.Heading>
                                <p>
                                    Membro cadastrado com sucesso!
                                </p>
                                <Button onClick={() => this.setState({success: false})} variant="outline-success">
                                    Ok!
                                </Button>
                            </Alert>
                            <Alert hidden={!this.state.error} variant="danger">
                                <Alert.Heading>Ocorreu um erro! ):</Alert.Heading>
                                <p>
                                    {this.state.errorMessage}
                                </p>
                                <Button onClick={() => this.setState({error: false})} variant="outline-danger">
                                    Ok :/
                                </Button>
                            </Alert>
                            <SheetJSAppMembers/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="2"/>
            </Row>
            <br />
            <hr />
            <br />
            <Row style={{' margin-left': 0, 'margin-right': 0 }}>
                <hr />
                <Col>
                    <ListMembers data={this.state.data} />
                </Col>
            </Row>
        </Grid>)

  }

}

export default PeopleInsert
