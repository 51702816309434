/* eslint-disable */
import React, { Component } from 'react';
import moment from 'moment';
import LogisticService from '../../core/services/LogisticService';
import Graph from '../../components/Logistic/graphic/graph';
import { Container, Col, Row } from 'react-bootstrap';
import LoadingSpinner from '../../components/LoadingSpinner';

class GreenAngelsSection extends Component {

    constructor () {
        super()
        this.state = {
          today: moment().format('YYYY-MM-DD'),
          loaded: false,
          lastUpdated: undefined,
          headcount:  {
            graph: undefined,
            loaded: false
          },
          turnover:  {
            graph: undefined,
            loaded: false
          },
          productivity:  {
            graph: undefined,
            loaded: false
          },
          accidentsAngels:  {
            graph: undefined,
            loaded: false
          },
        }
    }

    componentDidMount () {
        this.refreshReportData()
      }

    refreshReportData() {
        LogisticService.getLogisticMembersHistory()
        .then((response) => {
          this.buildHeadcountGraph(response.data.data)
          this.buildTurnoverGraph(response.data.data)
        })

        LogisticService.getAngelsRealizedOSs().then((realized_angels_os_response) => {
          LogisticService.getLogisticMembersHistory().then((response) => {
            LogisticService.getUsefulDays().then((useful_days) => {
              this.buildProductivityGraph(realized_angels_os_response.data.data,response.data.data,useful_days.data.data)
            })
          })
        })

        LogisticService.getAngelsAccidents()
        .then((response) => {
          this.buildAccidentsAngels(response.data.data)
        })

  }


  buildAccidentsAngels(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Acidentes',
            stack: 'Stack 0',
            backgroundColor: '#C7220F',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: '#2e3136',
          fontSize: 20,
          text: 'Acidentes na Operação',
          fontFamily: 'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 15,
            },
            color: 'white',
            formatter: function (value) {
              if (value >= 1000) {
                return String((value/1000).toFixed(1)) + 'k'
              }
              else {
                return value
              }
            }
          },
        },
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
      },
    }




    _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.date))
        graphObj.data.datasets[0].data.push(element.accidents)
      }
    )



    this.setState({
      accidentsAngels: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  renderAngelsAccidents() {
      return (
        <div>
            <Graph
              data={this.state.accidentsAngels.graph.data}
              legend={this.state.accidentsAngels.graph.legend}
              options={this.state.accidentsAngels.graph.options}
              width={this.state.isDesktop ? 500 : 350}
              height={this.state.isDesktop ? 400 : 350}
              />
        </div>)
  }


  buildProductivityGraph(angels_os_obj,headcount_obj,useful_days_obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'CSAT',
            stack: 'Stack 0',
            backgroundColor: '#00942A',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
            ticks: {
              // min: "2019-1",
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
            },
            ticks: {
              // min: 90,
              // max:100
              suggestedMax:15
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: '#2e3136',
          fontSize: 20,
          text: 'Produtividade',
          fontFamily: 'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 15,
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            formatter: function (value) {
              if (value >= 1000) {
                return String((value/1000).toFixed(1)) + 'k'
              }
              else {
                return value.toFixed(1)
              }
            },
          },
        },
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
      },
    }



    _.orderBy(headcount_obj, 'date').forEach((headcount_element) => {
      _.orderBy(angels_os_obj, 'toChar').forEach((angels_os_element) => {
        _.orderBy(useful_days_obj, 'month').forEach((useful_days_element) => {
        if(angels_os_element.toChar == headcount_element.date && useful_days_element.month ==headcount_element.date){
          console.log(headcount_element)
          console.log(angels_os_element)
          console.log(useful_days_element)
          graphObj.data.labels.push("".concat(headcount_element.date))
          graphObj.data.datasets[0].data.push(angels_os_element.osAngel/(headcount_element.activateAngels*useful_days_element.mtdUseful))
        }
      })
      })
      }
    )



    this.setState({
      productivity: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

  renderProductivityGraph() {
    if (this.state.productivity.loaded ) {
      return (
        <div>
          <Graph
            data={this.state.productivity.graph.data}
            legend={this.state.productivity.graph.legend}
            options={this.state.productivity.graph.options}
            width={this.state.isDesktop ? 500 : 350}
            height={this.state.isDesktop ? 400 : 350}
      />
        </div>)
    }

  }



    buildHeadcountGraph(obj) {
        const graphObj =
        {
        data: {
            labels: [],
            datasets: [
            {
                label: 'Contratações',
                stack: 'Stack 0',
                backgroundColor: '#18641e',
                borderWidth: 2,
                type: 'line',
                backgroundColor: '#00A32E',
                borderColor: '#00A32E',
                hoverBackgroundColor: '#00A32E',
                hoverBorderColor: '#00A32E',
                fill: false,
                data: [],
            },
            {
                label: 'Headcount',
                stack: 'Stack 1',
                backgroundColor: '#18641e',
                borderWidth: 2,
                data: [],
            },
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
            xAxes: [{
                stacked: true,
                categoryPercentage: 0.9,
                gridLines: {
                display: false,
                },
            }],
            yAxes: [{
                stacked: true,
                gridLines: {
                display: true,
                },
                ticks: {
                min: 0,
                },
            }],
            },
            tooltips: {
            mode: 'index',
            intersect: false,
            },
            title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Headcount e Contratações',
            fontFamily: 'GoBold'
            },
            plugins: {
            datalabels: {
                display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
                },
                font: {
                size: 15,
                },
                color: 'black',
                anchor: 'end',
                align: 'top',
                formatter: function (value) {
                if (value >= 1000) {
                    return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                    return value
                }
                }
            },
            },
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
            fontColor: 'black',
            usePointStyle: true
            },
        },
        }




        _.orderBy(obj, 'date').forEach((element) => {
            graphObj.data.labels.push(" ".concat(element.date))
            graphObj.data.datasets[0].data.push(element.admissions)
            graphObj.data.datasets[1].data.push(element.activateAngels)
        }
        )



        this.setState({
        headcount: {
            graph: graphObj,
            loaded: true
        },
        lastUpdated: moment(),
        })
    }

buildTurnoverGraph(obj) {
    const graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: '% Base Ativa',
            stack: 'Stack 0',
            backgroundColor: '#00A32E',
            borderWidth: 2,
            data: [],
            type: 'line',
            fill: false,
            backgroundColor: 'red',
            borderColor: 'red',
            hoverBackgroundColor: 'red',
            hoverBorderColor: 'red',
          },
          {
            label: 'Involuntário',
            stack: 'Stack 0',
            backgroundColor: '#004D16',
            borderWidth: 2,
            fill: false,
            data: [],
          },
          {
            label: 'Voluntário',
            stack: 'Stack 0',
            backgroundColor: '#00A32E',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: '#2e3136',
          fontSize: 20,
          text: 'TO Voluntário e Involuntário',
          fontFamily: 'GoBold'
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
            },
            color: 'white',
            align: 'center',
            formatter: function (value) {
              if (value >= 1000) {
                return String((value/1000).toFixed(1)) + 'k'
              }
              else {
                return value
              }
            }
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          usePointStyle: true
        },
      },
    }




    _.orderBy(obj, 'date').forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.date))
        graphObj.data.datasets[1].data.push(element.involuntaryTurnover)
        graphObj.data.datasets[2].data.push(element.voluntaryTurnover)
        graphObj.data.datasets[0].data.push((100*(element.voluntaryTurnover+element.involuntaryTurnover)/element.activateAngels).toFixed(1))
      }
    )



    this.setState({
      turnover: {
        graph: graphObj,
        loaded: true
      },
      lastUpdated: moment(),
    })
  }

    renderHeadcountGraph() {
        return (
        <div>
            <Graph
                data={this.state.headcount.graph.data}
                legend={this.state.headcount.graph.legend}
                options={this.state.headcount.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 400 : 350}
                />
        </div>)
    }



    renderTurnoverGraph() {
        return (
        <div>
            <Graph
                data={this.state.turnover.graph.data}
                legend={this.state.turnover.graph.legend}
                options={this.state.turnover.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 400 : 350}
                />
        </div>)
    }

  render() {

    if (this.state.headcount.loaded && this.state.turnover.loaded && this.state.accidentsAngels.loaded && this.state.productivity.loaded) {
    return (
      <Row style={{marginTop: '1em'}}>
        <Col xs sm md = {12} lg xl = {6}>
          {this.renderHeadcountGraph()}
        </Col>
        <Col xs sm md = {12} lg xl = {6}>
          {this.renderTurnoverGraph()}
        </Col>
        <Col xs sm md = {12} lg xl = {6}>
          {this.renderProductivityGraph()}
        </Col>
        <Col xs sm md = {12} lg xl = {6}>
          {this.renderAngelsAccidents()}
        </Col>
      </Row>
      )
    }
    return (
      <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
        <LoadingSpinner />
      </div>)

    }

}

export default GreenAngelsSection
