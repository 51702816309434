/* eslint-disable */
import React, {Component, PropTypes} from 'react'
import { Link } from 'react-router-dom'
import LogisticService from '../../core/services/LogisticService'
import { Bar, Doughnut, HorizontalBar, Pie } from 'react-chartjs-2'
import { Container, Form, Col, Card, Button, Alert, Row, Dropdown } from 'react-bootstrap'
import LoadingSpinner from '../../components/LoadingSpinner'
import CustomizedExpansionPannel from "../../custom-components/CustomizedExpansionPanel";
import Graph from '../../components/Logistic/graphic/graph'
import RefreshButton from '../../custom-components/RefreshButton'
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'
import style from './style.css'

class OTIF extends Component {
    constructor() {

        super()
        this.state = {
            loading: false,
            carrier: 'Paytec',
            geralOtif:  {
              graph: undefined,
              loaded: false
            },
            onTime:  {
              graph: undefined,
              loaded: false
            },
            onTimeInDoor:  {
              graph: undefined,
              loaded: false
            },
            onTimeTransit:  {
              graph: undefined,
              loaded: false
            },
            inFullPos:  {
              graph: undefined,
              loaded: false
            },
            inFullChips:  {
              graph: undefined,
              loaded: false
            },
            otifReasons:  {
              graph: undefined,
              loaded: false
            },
            infullPaytec:  {
              graph: undefined,
              loaded: false
            },
            onTimeBestlog:  {
              graph: undefined,
              loaded: false
            },
            infullBestlog:  {
              graph: undefined,
              loaded: false
            },
            onTimeInDoorBestlog:  {
              graph: undefined,
              loaded: false
            },
            onTimeTransitBestlog:  {
              graph: undefined,
              loaded: false
            },
            inFullPosBestlog:  {
              graph: undefined,
              loaded: false
            },
            inFullChipsBestlog:  {
              graph: undefined,
              loaded: false
            },
            infullBox:  {
              graph: undefined,
              loaded: false
            },
            inFullPosBox:  {
              graph: undefined,
              loaded: false
            },
            inFullChipsBox:  {
              graph: undefined,
              loaded: false
            },
            tma:  {
              graph: undefined,
              loaded: false
            },
        }

        LogisticService.getAllOtifNumbers().then((response) => {
          console.log(response)
            this.buildGeralOtif(response.data.otif)
            this.buildOnTimePaytec(response.data.paytec_ontime)
            this.buildOnTimePaytecInDoor(response.data.paytec_ontime)
            this.buildOnTimePaytecTransit(response.data.paytec_ontime)
            this.buildInFullPaytec(response.data.paytec_infull)
            this.buildInFullPaytecPOS(response.data.paytec_infull)
            this.buildInFullPaytecChips(response.data.paytec_infull)
            
            this.buildOnTimeBestlog(response.data.bestlog_ontime)
            this.buildOnTimeBestlogInDoor(response.data.bestlog_ontime)
            this.buildOnTimeBestlogTransit(response.data.bestlog_ontime)
            this.buildInFullBestlog(response.data.bestlog_infull)
            this.buildInFullBestlogPOS(response.data.bestlog_infull)
            this.buildInFullBestlogChips(response.data.bestlog_infull)

            this.buildInFullBox(response.data.box_infull)
            this.buildInFullBoxPOS(response.data.box_infull)
            this.buildInFullBoxChips(response.data.box_infull)

            this.buildOtifReasons(response.data.otif)
            this.buildTMAGraph(response.data.tma)
            this.setState({
                bestlog_ontime: response.data.bestlog_ontime,
                bestlog_infull: response.data.bestlog_infull,
                box_infull: response.data.box_infull,
                paytec_ontime: response.data.paytec_ontime,
                paytec_infull: response.data.paytec_infull,
                otif: response.data.otif,
            })
            
        })
        
    }

    handleChange() {
        var status = document.getElementById("status")
        status = status[status.selectedIndex].text
        this.setState({
            carrier: status
        })
    }


    buildGeralOtif(obj) {
      console.log(obj)
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'OTIF',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: false,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'OTIF',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }

    console.log(obj)


      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.otif_total*100/element.items_total).toFixed(1))
        }
      )

      this.state.geralOtif.graph = graphObj
      this.state.geralOtif.loaded = true
    }

    renderGeralOtif() {
      if (this.state.geralOtif.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {2}/>
            <Col xs sm md = {12} lg xl = {8}>
              <Graph
                data={this.state.geralOtif.graph.data}
                legend={this.state.geralOtif.graph.legend}
                options={this.state.geralOtif.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 300 : 262}
                />
              </Col>
            </Row>
          </div>)
    }

    buildTMAGraph(obj) {
      console.log(obj)
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'TMA',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: false,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'OTIF',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }

    console.log(obj)


      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.num/element.den).toFixed(1))
        }
      )

      this.state.tma.graph = graphObj
      this.state.tma.loaded = true
    }

    renderTMAGraph() {
      if (this.state.tma.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {2}/>
            <Col xs sm md = {12} lg xl = {8}>
              <Graph
                data={this.state.tma.graph.data}
                legend={this.state.tma.graph.legend}
                options={this.state.tma.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 300 : 262}
                />
              </Col>
            </Row>
          </div>)
    }

    buildOnTimePaytec(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Geral',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }



      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.ontime*100/element.total).toFixed(1))
        }
      )

      this.state.onTime.graph = graphObj
      this.state.onTime.loaded = true
    }


    renderOnTimePaytec() {
      if (this.state.onTime.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {12}>
              <Graph
                data={this.state.onTime.graph.data}
                legend={this.state.onTime.graph.legend}
                options={this.state.onTime.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 300 : 262}
                />
              </Col>
            </Row>
          </div>)
    }

    buildOnTimeBestlog(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Geral',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }



      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.ontime*100/element.total).toFixed(1))
        }
      )

      this.state.onTimeBestlog.graph = graphObj
      this.state.onTimeBestlog.loaded = true
    }


    renderOnTimeBestlog() {
      if (this.state.onTime.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {12}>
              <Graph
                data={this.state.onTimeBestlog.graph.data}
                legend={this.state.onTimeBestlog.graph.legend}
                options={this.state.onTimeBestlog.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 300 : 262}
                />
              </Col>
            </Row>
          </div>)
    }


    buildInFullPaytec(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Geral',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }



      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.infull*100/element.total).toFixed(1))
        }
      )

      this.state.infullPaytec.graph = graphObj
      this.state.infullPaytec.loaded = true
    }


    renderInFullPaytec() {
      if (this.state.infullPaytec.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {12}>
              <Graph
                data={this.state.infullPaytec.graph.data}
                legend={this.state.infullPaytec.graph.legend}
                options={this.state.infullPaytec.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 300 : 262}
                />
              </Col>
            </Row>
          </div>)
    }

    buildInFullBestlog(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Geral',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }



      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.infull*100/element.total).toFixed(1))
        }
      )

      this.state.infullBestlog.graph = graphObj
      this.state.infullBestlog.loaded = true
    }


    renderInFullBestlog() {
      if (this.state.infullBestlog.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {12}>
              <Graph
                data={this.state.infullBestlog.graph.data}
                legend={this.state.infullBestlog.graph.legend}
                options={this.state.infullBestlog.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 300 : 262}
                />
              </Col>
            </Row>
          </div>)
    }

    buildInFullBox(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Geral',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }



      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.infull*100/element.total).toFixed(1))
        }
      )

      this.state.infullBox.graph = graphObj
      this.state.infullBox.loaded = true
    }


    renderInFullBox() {
      if (this.state.infullBestlog.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {12}>
              <Graph
                data={this.state.infullBox.graph.data}
                legend={this.state.infullBox.graph.legend}
                options={this.state.infullBox.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 300 : 262}
                />
              </Col>
            </Row>
          </div>)
    }



    buildOnTimePaytecInDoor(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'In Door',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }

      



      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.indoor*100/element.total).toFixed(1))
        }
      )

      this.state.onTimeInDoor.graph = graphObj
      this.state.onTimeInDoor.loaded = true
    }


    renderOnTimeInDoor() {
      if (this.state.onTimeInDoor.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {1}/>
            <Col xs sm md = {12} lg xl = {11}>
              <Graph
                data={this.state.onTimeInDoor.graph.data}
                legend={this.state.onTimeInDoor.graph.legend}
                options={this.state.onTimeInDoor.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 150 : 131}
                />
              </Col>
            </Row>
          </div>)
    }


    buildOnTimeBestlogInDoor(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'In Door',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }

      



      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.indoor*100/element.total).toFixed(1))
        }
      )

      this.state.onTimeInDoorBestlog.graph = graphObj
      this.state.onTimeInDoorBestlog.loaded = true
    }


    renderOnTimeInDoorBestlog() {
      if (this.state.onTimeInDoor.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {1}/>
            <Col xs sm md = {12} lg xl = {11}>
              <Graph
                data={this.state.onTimeInDoorBestlog.graph.data}
                legend={this.state.onTimeInDoorBestlog.graph.legend}
                options={this.state.onTimeInDoorBestlog.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 150 : 131}
                />
              </Col>
            </Row>
          </div>)
    }



    buildOnTimePaytecTransit(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Trânsito',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }




      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.transit*100/element.total).toFixed(1))
        }
      )

      this.state.onTimeTransit.graph = graphObj
      this.state.onTimeTransit.loaded = true
    }


    renderOnTimeTransit(obj) {
      if (this.state.onTimeTransit.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {1}/>
            <Col xs sm md = {12} lg xl = {11}>
              <Graph
                data={this.state.onTimeTransit.graph.data}
                legend={this.state.onTimeTransit.graph.legend}
                options={this.state.onTimeTransit.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 150 : 131}
                />
              </Col>
            </Row>
          </div>)
    }

    buildOnTimeBestlogTransit(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Trânsito',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }




      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.transit*100/element.total).toFixed(1))
        }
      )

      this.state.onTimeTransitBestlog.graph = graphObj
      this.state.onTimeTransitBestlog.loaded = true
    }


    renderOnTimeTransitBestlog(obj) {
      if (this.state.onTimeTransit.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {1}/>
            <Col xs sm md = {12} lg xl = {11}>
              <Graph
                data={this.state.onTimeTransitBestlog.graph.data}
                legend={this.state.onTimeTransitBestlog.graph.legend}
                options={this.state.onTimeTransitBestlog.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 150 : 131}
                />
              </Col>
            </Row>
          </div>)
    }



    buildInFullPaytecPOS(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Máquinas',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }



      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.pos*100/element.total).toFixed(1))
        }
      )

      this.state.inFullPos.graph = graphObj
      this.state.inFullPos.loaded = true
    }


    renderInFullPos() {
      if (this.state.inFullPos.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {1}/>
            <Col xs sm md = {12} lg xl = {11}>
              <Graph
                data={this.state.inFullPos.graph.data}
                legend={this.state.inFullPos.graph.legend}
                options={this.state.inFullPos.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 150 : 131}
                />
              </Col>
            </Row>
          </div>)
    }

  buildInFullBestlogPOS(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Máquinas',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }



      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.pos*100/element.total).toFixed(1))
        }
      )

      this.state.inFullPosBestlog.graph = graphObj
      this.state.inFullPosBestlog.loaded = true
    }


    renderInFullPosBestlog() {
      if (this.state.inFullPos.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {1}/>
            <Col xs sm md = {12} lg xl = {11}>
              <Graph
                data={this.state.inFullPosBestlog.graph.data}
                legend={this.state.inFullPosBestlog.graph.legend}
                options={this.state.inFullPosBestlog.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 150 : 131}
                />
              </Col>
            </Row>
          </div>)
    }

    buildInFullBoxPOS(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Máquinas',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }



      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.pos*100/element.total).toFixed(1))
        }
      )

      this.state.inFullPosBox.graph = graphObj
      this.state.inFullPosBox.loaded = true
    }


    renderInFullPosBox() {
      if (this.state.inFullPos.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {1}/>
            <Col xs sm md = {12} lg xl = {11}>
              <Graph
                data={this.state.inFullPosBox.graph.data}
                legend={this.state.inFullPosBox.graph.legend}
                options={this.state.inFullPosBox.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 150 : 131}
                />
              </Col>
            </Row>
          </div>)
    }



    buildInFullPaytecChips(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Chips',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }




      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.chip*100/element.total).toFixed(1))
        }
      )

      this.state.inFullChips.graph = graphObj
      this.state.inFullChips.loaded = true
    }


    renderInFullChips() {
      if (this.state.inFullChips.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {1}/>
            <Col xs sm md = {12} lg xl = {11}>
              <Graph
                data={this.state.inFullChips.graph.data}
                legend={this.state.inFullChips.graph.legend}
                options={this.state.inFullChips.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 150 : 131}
                />
              </Col>
            </Row>
          </div>)
    }

    buildInFullBestlogChips(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Chips',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }




      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.chip*100/element.total).toFixed(1))
        }
      )

      this.state.inFullChipsBestlog.graph = graphObj
      this.state.inFullChipsBestlog.loaded = true
    }


    renderInFullChipsBestlog() {
      if (this.state.inFullChips.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {1}/>
            <Col xs sm md = {12} lg xl = {11}>
              <Graph
                data={this.state.inFullChipsBestlog.graph.data}
                legend={this.state.inFullChipsBestlog.graph.legend}
                options={this.state.inFullChipsBestlog.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 150 : 131}
                />
              </Col>
            </Row>
          </div>)
    }

    buildInFullBoxChips(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: '#2e3136',
            fontSize: 20,
            text: 'Chips',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'white',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return value
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }




      _.orderBy(obj, 'date').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.date))
          graphObj.data.datasets[0].data.push((element.chip*100/element.total).toFixed(1))
        }
      )

      this.state.inFullChipsBox.graph = graphObj
      this.state.inFullChipsBox.loaded = true
    }


    renderInFullChipsBox() {
      if (this.state.inFullChips.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {1}/>
            <Col xs sm md = {12} lg xl = {11}>
              <Graph
                data={this.state.inFullChipsBox.graph.data}
                legend={this.state.inFullChipsBox.graph.legend}
                options={this.state.inFullChipsBox.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 150 : 131}
                />
              </Col>
            </Row>
          </div>)
    }


    buildOtifReasons(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Meta',
              stack: 'Stack 0',
              backgroundColor: '#b4b3b1',
              borderWidth: 2,
              data: [],
            },
            {
              label: '',
              stack: 'Stack 1',
              backgroundColor: '#FFFFFF',
              borderWidth: 0,
              data: [],
              yAxisID: 'A',
            },
            {
              label: 'Em análise On Time',
              stack: 'Stack 1',
              backgroundColor: '#00cc6d',
              borderWidth: 2,
              data: [],
              yAxisID: 'A',
            },
            {
              label: '',
              stack: 'Stack 2',
              backgroundColor: '#FFFFFF',
              borderWidth: 0,
              data: [],
              yAxisID: 'A',
            },
            {
              label: 'In Full',
              stack: 'Stack 2',
              backgroundColor: '#67a53b',
              borderWidth: 2,
              data: [],
              yAxisID: 'A',
            },
            {
              label: '',
              stack: 'Stack 3',
              backgroundColor: '#FFFFFF',
              borderWidth: 0,
              data: [],
              yAxisID: 'A',
            },
            {
              label: 'Atraso de...',
              stack: 'Stack 3',
              backgroundColor: '#7ef482',
              borderWidth: 2,
              data: [],
              yAxisID: 'A',
            },
            {
              label: '',
              stack: 'Stack 4',
              backgroundColor: '#FFFFFF',
              borderWidth: 0,
              data: [],
              yAxisID: 'A',
            },
            {
              label: 'SEFAZ',
              stack: 'Stack 4',
              backgroundColor: '#bdecc8',
              borderWidth: 2,
              data: [],
              yAxisID: 'A',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],


    yAxes: [{
          id: 'A',
          stacked: true,
          gridLines: {
            display: false,
          },
          position: 'left',
          ticks: {
              min: 87,
              suggestedMax: 105
            }
        },
        // {
        //   id: 'B',
        //   stacked: false,
        //   gridLines: {
        //     display: false,
        //   },
        //   position: 'right',
        //   ticks: {
        //     min: 0,
        //     max: 5
        //   }
        // }
      ],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: false,
            fontColor: 'black',
            fontSize: 20,
            text: '',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 12,
              },
              color: 'black',
              anchor: 'end',
              align: 'top',
              formatter: function (value) {
                return value
                // if (value >= 1000) {
                  // return String((value/1000).toFixed(1))
                // }
                // else {
                //   return value
                // }
              }
            },
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'black',
            fontFamily: 'GoBold'
          },
        },
      }

      var results=[
        {
          'tittle': 'Meta',
          'result': 100
        },
        {
          'tittle': 'Em análise On Time',
          'result': 0.6
        },
        {
          'tittle': 'In Full',
          'result': 1.3
        },
        {
          'tittle': 'Atraso de...',
          'result': 6
        },
        {
          'tittle': 'SEFAZ',
          'result': 3.9
        },
        {
          'tittle': 'Covid-19',
          'result': 2.6
        },
        {
          'tittle': 'OTIF',
          'result': 85.5
        },
      ]


      // _.orderBy(objResume, 'date').forEach((element) => {
      //   this.state.recentCycle = ((element.screeningCycleGlobal)*100.0/element.gprsWifiStock).toFixed(1)
      //   this.state.recentStock = element.gprsWifiStock
      // })
      // results.forEach((result) => {
      //   graphObj.data.labels.push("tittle")
      // })

      graphObj.data.datasets[0].data.push(results[0].result)
      graphObj.data.datasets[1].data.push(results[0].result-results[1].result)
      graphObj.data.datasets[2].data.push(results[1].result)
      graphObj.data.datasets[3].data.push((results[0].result-results[1].result-results[2].result).toFixed(1))
      graphObj.data.datasets[4].data.push(results[2].result)
      graphObj.data.datasets[5].data.push((results[0].result-results[1].result-results[2].result-results[3].result).toFixed(1))
      graphObj.data.datasets[6].data.push(results[3].result)
      graphObj.data.datasets[7].data.push((results[0].result-results[1].result-results[2].result-results[3].result-results[4].result).toFixed(1))
      graphObj.data.datasets[8].data.push(results[3].result)
      // graphObj.data.datasets[0].data.push(results[0].result)
      // graphObj.data.datasets[0].data.push(results[0].result)
      // graphObj.data.datasets[0].data.push(results[0].result)
      // graphObj.data.datasets[0].data.push(results[0].result)
      // graphObj.data.datasets[1].data.push(this.state.recentCycle - (element.cd3days*100/this.state.recentStock).toFixed(2))
      // graphObj.data.datasets[2].data.push((element.cd3days*100/this.state.recentStock).toFixed(2))
      // graphObj.data.datasets[3].data.push((this.state.recentCycle - (element.cd3days*100/this.state.recentStock).toFixed(2) - (element.pos30days*100/this.state.recentStock).toFixed(2)).toFixed(2))
      // graphObj.data.datasets[4].data.push((element.pos30days*100/this.state.recentStock).toFixed(2))
      // graphObj.data.datasets[5].data.push((this.state.recentCycle - (element.cd3days*100/this.state.recentStock).toFixed(2) - (element.pos30days*100/this.state.recentStock).toFixed(2) - (element.transitSerials*100/this.state.recentStock).toFixed(2)).toFixed(2))
      // graphObj.data.datasets[6].data.push((element.transitSerials*100/this.state.recentStock).toFixed(2))

      this.state.otifReasons.graph = graphObj
      this.state.otifReasons.loaded = true

      // this.setState({
      //   otifReasons: {
      //     graph: graphObj,
      //     loaded: true
      //   },
      //   lastUpdated: moment(),
      // })
    }

    //Renderiza o gráfico de status de OS por data de abertura
    renderOtifReasons() {
      if (this.state.otifReasons.loaded ) {
        return (
          <div>
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {2}/>
            <Col xs sm md = {12} lg xl = {8}>
            <Graph
              data={this.state.otifReasons.graph.data}
              legend={this.state.otifReasons.graph.legend}
              options={this.state.otifReasons.graph.options}
              width={this.state.isDesktop ? 500 : 350}
              height={this.state.isDesktop ? 300 : 262}
            />
            </Col>
          </Row>
          </div>
        </div>)
      }

      return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
        <LoadingSpinner />
      </div>)
    }

    renderDetailOtif() {
      if (this.state.geralOtif.graph == undefined){
        return
      }
        return (
          <div>
          <Row>
            <Col xs sm md = {12} lg xl = {1}/>
            <Col xs sm md = {12} lg xl = {11}>
              <Graph
                data={this.state.geralOtif.graph.data}
                legend={this.state.geralOtif.graph.legend}
                options={this.state.geralOtif.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 150 : 131}
                />
              </Col>
            </Row>
          </div>)
    }

    renderPaytecDetailOnTime(){
      return(
        <Row>
          <Col xs sm md = {12} lg xl = {12}>
            {this.renderOnTimeInDoor()}
          </Col>
          <Col xs sm md = {12} lg xl = {12}>
            {this.renderOnTimeTransit()}
          </Col>
        </Row>
      )
    }

    renderBestlogDetailOnTime(){
      return(
        <Row>
          <Col xs sm md = {12} lg xl = {12}>
            {this.renderOnTimeInDoorBestlog()}
          </Col>
          <Col xs sm md = {12} lg xl = {12}>
            {this.renderOnTimeTransitBestlog()}
          </Col>
        </Row>
      )
    }

    renderPaytecDetailInFull(){
      return(
        <Row>
          <Col xs sm md = {12} lg xl = {12}>
            {this.renderInFullPos()}
          </Col>
          <Col xs sm md = {12} lg xl = {12}>
            {this.renderInFullChips()}
          </Col>
        </Row>
      )
    }

    renderBestlogDetailInFull(){
      return(
        <Row>
          <Col xs sm md = {12} lg xl = {12}>
            {this.renderInFullPosBestlog()}
          </Col>
          <Col xs sm md = {12} lg xl = {12}>
            {this.renderInFullChipsBestlog()}
          </Col>
        </Row>
      )
    }

    renderBoxDetailInFull(){
      return(
        <Row>
          <Col xs sm md = {12} lg xl = {12}>
            {this.renderInFullPosBox()}
          </Col>
          <Col xs sm md = {12} lg xl = {12}>
            {this.renderInFullChipsBox()}
          </Col>
        </Row>
      )
    }

    renderPaytec(){
      return(
        <div>
        <p align='center' style={{fontFamily: 'GoBold'}}> On Time </p>
        <Row>
          <Col xs sm md = {12} lg xl = {6}>
            {this.renderOnTimePaytec()}
          </Col>
          <Col xs sm md = {12} lg xl = {6}>
            {this.renderPaytecDetailOnTime()}
          </Col>
        </Row>
        <p align='center' style={{fontFamily: 'GoBold'}}> In Full </p>
        <Row>
          <Col xs sm md = {12} lg xl = {6}>
            {this.renderInFullPaytec()}
          </Col>
          <Col xs sm md = {12} lg xl = {6}>
            {this.renderPaytecDetailInFull()}
          </Col>
        </Row>
        </div>
      )
    }

    renderBestlog(){
      return(
        <div>
        <p align='center' style={{fontFamily: 'GoBold'}}> On Time </p>
        <Row>
          <Col xs sm md = {12} lg xl = {6}>
            {this.renderOnTimeBestlog()}
          </Col>
          <Col xs sm md = {12} lg xl = {6}>
            {this.renderBestlogDetailOnTime()}
          </Col>
        </Row>
        <p align='center' style={{fontFamily: 'GoBold'}}> In Full </p>
        <Row>
          <Col xs sm md = {12} lg xl = {6}>
            {this.renderInFullBestlog()}
          </Col>
          <Col xs sm md = {12} lg xl = {6}>
            {this.renderBestlogDetailInFull()}
          </Col>
        </Row>
        </div>
      )
    }

    renderStoneBox(){
      return(
        <div>
        <p align='center' style={{fontFamily: 'GoBold'}}> In Full </p>
        <Row>
          <Col xs sm md = {12} lg xl = {6}>
            {this.renderInFullBox()}
          </Col>
          <Col xs sm md = {12} lg xl = {6}>
            {this.renderBoxDetailInFull()}
          </Col>
        </Row>
        </div>
      )
    }

    renderCarrierMenu(){
      if(this.state.carrier == 'Paytec'){
        return(
          <div>
            <Row>
              <Col xs sm md = {12} lg xl = {4} style={{fontFamily:'GoBold', fontSize:'15px', marginLeft:'10px'}}>
                <Form.Control id="status" type="email" size="sm" placeholder="Status" as="select" onInput = {e => this.handleChange()} >
                  <option>Paytec</option>
                  <option>Bestlog</option>
                  <option>Stone Box</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col>
                {this.renderPaytec()}
              </Col>
            </Row>
          </div>
        )
      }
      if(this.state.carrier == 'Bestlog'){
        return(
          <div>
            <Row>
              <Col xs sm md = {12} lg xl = {4} style={{fontFamily:'GoBold', fontSize:'15px', marginLeft:'10px'}}>
                <Form.Control id="status" type="email" size="sm" placeholder="Status" as="select" onInput = {e => this.handleChange()} >
                  <option>Paytec</option>
                  <option>Bestlog</option>
                  <option>Stone Box</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col>
                {this.renderBestlog()}
              </Col>
            </Row>
          </div>
        )
      }
      else{
        return(
          <div>
            <Row>
              <Col xs sm md = {12} lg xl = {4} style={{fontFamily:'GoBold', fontSize:'15px', marginLeft:'10px'}}>
                <Form.Control id="status" type="email" size="sm" placeholder="Status" as="select" onInput = {e => this.handleChange()} >
                  <option>Paytec</option>
                  <option>Bestlog</option>
                  <option>Stone Box</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col>
                {this.renderStoneBox()}
              </Col>
            </Row>
          </div>
        )
      }

      return(
        <div>
          {result}
        </div>
      )
    }


    render() {
      if(this.state.otif == undefined){
        return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
        <LoadingSpinner />
      </div>)
      }
        return (
          <Container fluid>
            <CustomizedExpansionPannel
                title='OTIF - Geral'
                content={this.renderGeralOtif()}
                helpMessage={<p><b>SLA Logístico Total.</b><br/>
                  <p>Acidentes (apenas com afastamento). Em caso de um acidente com afastamento superior a 15 dias corridos, a meta também é perdida, independente</p> </p>}
                tooltip={true}
            />
            <CustomizedExpansionPannel
              title='Transportadora'
              content={this.renderCarrierMenu()}
              helpMessage={<b>SLA Logístico Total.</b>}
              tooltip={true}
            />
            <CustomizedExpansionPannel
                title='OTIF - Motivos'
                content={this.renderOtifReasons()}
                helpMessage={<p><b>SLA Logístico Total.</b><br/>
                  <p>Acidentes (apenas com afastamento). Em caso de um acidente com afastamento superior a 15 dias corridos, a meta também é perdida, independente</p> </p>}
                tooltip={true}
            />
            <CustomizedExpansionPannel
                title='TMA (dias úteis)'
                content={this.renderTMAGraph()}
                helpMessage={<p><b>SLA Logístico Total.</b><br/>
                  <p>Acidentes (apenas com afastamento). Em caso de um acidente com afastamento superior a 15 dias corridos, a meta também é perdida, independente</p> </p>}
                tooltip={true}
            />
          </Container>
        )
    }
}

export default OTIF
