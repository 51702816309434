/* eslint-disable */
import React, { Component } from 'react'

import LogisticService from '../../../core/services/LogisticService'
import style from '../style.css'
import Graph from '../../../components/Logistic/graphic/graph'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Box from '@material-ui/core/Box';


class DailyProd extends Component {
constructor(props) {
    super(props);
    this.state = {
        district: props.district,
        date: props.date,
        prod:{
        loaded:false
        },
        graph: undefined,
        loading: false,
        lastUpdated: undefined,
    };
    this.refreshReportData = this.refreshReportData.bind(this)
}

refreshReportData() {
    //Get People Daily Productive
    LogisticService.getLmpPeople('dailyProd',this.state.district,`${this.state.date}-01`,`${this.state.date}-01`).then((response)=>{ 
      // this.buildDailyProdGraph(response.data.data)  
      LogisticService.getLmpPeople('dailyProdMtd',this.state.district,`${this.state.date}-01`,`${this.state.date}-01`).then((mtd)=>{ 
          this.buildDailyProdGraph(response.data.data,mtd.data.data)  
      })
    })
}

componentDidMount() {
    this.refreshReportData()
  }
  

  buildDailyProdGraph(obj,mtd) {
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [
          {
            label: 'Produtividade/Dia',
            stack: 'Stack 0',
            type: 'line',
            yAxisID: 'B',
            borderColor: 'grey',
            backgroundColor: 'grey',
            pointBorderColor: 'grey',
            pointBackgroundColor: 'grey',
            pointHoverBackgroundColor: 'grey',
            pointHoverBorderColor: 'grey',
            pointRadius : 0,
            borderDash: [1,1],
            data: [],
            fill: false,
            display:false,
          },
          {
              label: "OS'S",
              stack: 'Stack 1',
              type: 'bar',
              yAxisID: 'A',
              backgroundColor: '#13aa4c',
              data: [],
              fill: false,
              display:false,
              
          },
          {
            label: 'Meta',
            stack: 'Stack 2',
            type: 'line',
            yAxisID: 'B',
            borderColor: 'yellow',
            backgroundColor: 'yellow',
            pointBorderColor: 'yellow',
            pointBackgroundColor: 'yellow',
            pointHoverBackgroundColor: 'yellow',
            pointHoverBorderColor: 'yellow',
            pointRadius : 0,
            borderDash: [1,1],
            data: [],
            fill: false,
            display:false,
          },
          {
            label: 'MTD Regional',
            stack: "Stack 3",
            yAxisID: 'B',
            type: "line",
            borderColor: "blue",
            backgroundColor: "blue",
            pointBorderColor: "blue",
            pointBackgroundColor: "blue",
            pointHoverBackgroundColor: "blue",
            pointHoverBorderColor: "blue",
            pointRadius : 0,
            borderDash: [1,1],
            data: [],
            fill: false,
            display:false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            categoryPercentage: 0.9,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
          {
            id: 'B',
            display:false,
            stacked: false,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
          {
            id: 'A',
            display:false,
            stacked: false,
            anchor: 'end',
            align: 'top',
            ticks: {
              min: 0,
              callback: function(value) {
                return `${value.toFixed(2)}%`
              },
            },
          },
        ]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 12,
          fontFamily: 'GoBold',
          text: 'Produtividade Diária',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
              if(graphObj.dataset.label === 'Meta'||graphObj.dataset.label === 'MTD Regional')
                return false
              else
                return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              weight: "bold",
              family: 'Gobold'
            },
            position: 'center',
            align: function (graphObj) {
                return 'center'
            },
            color:'black',
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontFamily: 'GoBold'
        },
      },
    }

    obj.forEach((element) => {
      // let regional = mtd.find(item => item.date === element.date)
      graphObj.data.labels.push(element.date)
      graphObj.data.datasets[0].data.push(((element.os*1.0)/element.angels).toFixed(1))
      graphObj.data.datasets[1].data.push(element.os)
      graphObj.data.datasets[2].data.push(11.2)
      graphObj.data.datasets[3].data.push((mtd[0].os/(mtd[0].angels*obj.length)).toFixed(1))
      }
    )  

    this.setState({
      prod:{
              graph: graphObj,
              loaded: true
        },
    })
  }
    
    renderContent(){
    if(this.state.prod.loaded){
        return(
          <div>
            <p style={{'fontSize':'0.7em'}}>* Produtividade ativa (os's feitas no dia pelos angels que deram baixa no distrito)</p>
            <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
                <Graph
                data={this.state.prod.graph.data}
                legend={this.state.prod.graph.legend}
                options={this.state.prod.graph.options}
                width={this.state.isDesktop ? 400 : 310}
                height={340}
                />
            </Box>
            </div>
        )
    }
    else{
        return(
            <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
            </div>
        )
    }
    }

    render(){
        return(this.renderContent())
    }


}
export default DailyProd