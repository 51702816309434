
import React, { Component } from 'react'
import style from './style.css'
import { withStyles } from '@material-ui/core/styles';
import LogisticService from '../../core/services/LogisticService'
import { Col } from 'react-bootstrap'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomizedTable from '../../custom-components/CustomizedTable'
import  RaioX from './img/xray-icon.png'
import EqualsService from '../../core/services/EqualsService'


const ProblemsTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#37B766',
      color: 'white',
      fontSize: '0.9em',
    },
    body: {
      fontSize: '0.8em'
    },
  }))(TableCell);

class XrayTable extends Component {

    constructor(props) {
  
        super(props)
    
        this.state = {
            stonecode: props.stonecode,
            document: undefined,
            alert: false,
            customerInfo: undefined
        }
    
        this.refreshReportData = this.refreshReportData.bind(this)
      }

    refreshReportData() {
        this.setState({
            document: undefined,
            alert: false,
            customerInfo: undefined
        });

        LogisticService.getGeneralInformation(this.state.stonecode).then((documentInfo) => {
            if(documentInfo.data.data != undefined && documentInfo.data.data != false ){
                var documentNumber = documentInfo.data.data.information.document

                    EqualsService.getMerchantInfo(documentNumber.replace('-', '').replace('/', '').replace(/\./g, '')).then((equalsInfo) => {
                        var equalsList = []
                        if(equalsInfo.data != undefined){
                            var equalsData = equalsInfo.data
                            equalsData.forEach((element) => {
                                var operator = element.dsOperadora
                                var status = element.dsStatusProcesso
                                var beginDate = element.dtInicioImplantacao
                                
                                if(operator != 'Equals Raio-X (Digital)'){
                                    equalsList.push([operator,status,beginDate])
                                }
                            })
                            this.setState({
                                customerInfo: equalsList
                            });
                        }
                            
                    })
                    .catch((error) => {
                        this.setState({
                            customerInfo: []
                        });
                    })

            }
            else{
                this.setState({
                    customerInfo: []
                });
            }
        })
    }

    componentDidMount() {
        this.refreshReportData()
    }

    buildOptionsFromArray(array,comment){
        if( array == undefined){
            return (
                    <TableRow>
                        <ProblemsTableCell>Carregando informações de Raio-X....</ProblemsTableCell>
                    </TableRow>
                    )
        }
        else if (array.length === 0)
          return (<TableRow>
            <ProblemsTableCell>{comment}</ProblemsTableCell>
          </TableRow>)
        else if (comment == 'Não há informações para carregar') {
            var results = []
            array.forEach((element) => {
                if(element.status == 'Sem status'){
                    element.status = 'Pendência'
                }
                if(element.status == 'Alerta'){
                    results.push(
                        <TableRow>
                            <ProblemsTableCell><i class="material-icons red" fontSize='24px'>warning</i>Atenção esse cliente tem um cadastro da Equals recente e deve ser feito ativação de raio-x</ProblemsTableCell>
                        </TableRow>
                    )
                }
                else{
                    results.push(
                        <TableRow>
                            <ProblemsTableCell>Adquirente: {element.operator} <br/> Status: {element.status}</ProblemsTableCell>
                        </TableRow>
                    )
                }
            })
          return results
        }
      }


    renderCustomersXraySummary(response){
        let titleXray = [{name:"Raio-X",icon:<img src={RaioX} alt="Brain" className="titleIconXray" /> }]
        if(response == undefined){{
            return (
                <CustomizedTable
                  rows ={this.buildOptionsFromArray(undefined,'Não há informações para carregar')}
                  title ={titleXray}
                  subTitle ={""}
                  colSpan ={"1"}
                  >
                </CustomizedTable>
             )
        }}
        
    
          let rows = []
          if(this.state.alert){
            rows.push({
                status: 'Alerta',
                operator: ''
            })
          }
          response.forEach(element => {
            if( response.length != 0 ){
              rows.push({
                status: element[1],
                operator: element[0]
            })
            }
    
      })
    
      let rowsXray = []
      rowsXray.push(
        this.buildOptionsFromArray(rows,'Não há informações para carregar')
      )
    
      
      return (
          <CustomizedTable
            rows ={rowsXray}
            title ={titleXray}
            subTitle ={""}
            colSpan ={"1"}
            >
          </CustomizedTable>
       )
      }

    render(){
        return(
            <Col className="customizedTable right" xs sm md = "12" lg xl = "2.5" align="right" style={{marginTop: '5px'}}>
                {this.renderCustomersXraySummary(this.state.customerInfo)}
            </Col>
        )
    }

}

export default XrayTable