import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Container, Form, Row, Col, Card, Button } from 'react-bootstrap'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomizedTable from '../../custom-components/CustomizedTable'
import  Computer from './img/computer.png'
import  Pos from './img/pos.png'
import  PortalComputer from './img/portalComputer.png'

const ProblemsTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#37B766',
      color: 'white',
      fontSize: '0.9em',
    },
    body: {
      fontSize: '0.8em'
    },
  }))(TableCell);

  class LinksTable extends Component {

    buildOptionsFromArray(array,comment){
        if (array.length === 0)
          return (<TableRow>
            <ProblemsTableCell>{comment}</ProblemsTableCell>
          </TableRow>)
        else if (comment == 'Não há Links') {
          const results = array.map(element =>
            (<TableRow>
              <ProblemsTableCell><Button className='buttonLinks' variant="success" href={element.linkbutton} target="_blank">{element.linkName}</Button></ProblemsTableCell>
            </TableRow>)
        )
          return results
        }
      }


    renderUsefulLinksPOS(){

        let rows = [
          {
            linkName: 'PRIMEIROS PASSOS S920',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Primeiros-Passos-S920'
          },
          {
            linkName: 'PRIMEIROS PASSOS D210N',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Primeiros-Passos-D210N-'
          },
          {
            linkName: 'FUNÇÕES D210N',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Funcoes-1483536836839'
          },
          {
            linkName: 'NFC',
            linkbutton: 'https://stone.force.com/ajuda/s/article/TECNOLOGIA-NFC-NA-STONE'
          },
          {
            linkName: 'SIMULADOR DE VENDAS',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Simulador-de-vendas-na-S920'
          },
          {
            linkName: 'SENHA ADM',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Como-faco-para-alterar-a-senha-administrativa-1493409315434'
          },
          {
            linkName: 'RELATÓRIO',
            linkbutton: 'https://stone.force.com/ajuda/s/article/O-que-e-a-funcao-Relatorios-1483536985900'
          },
          {
            linkName: 'CANCELAMENTO DE VENDA',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Cancelamento-de-venda-na-maquina'
          },
          {
            linkName: 'COMO TROCAR BOBINA',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Bobinas-1483536928401'
          }
        ]
  
    let rowsPOS = []
    rowsPOS.push(
      this.buildOptionsFromArray(rows,'Não há Links')
    )
  
    let titlePOS = [{name:"Funções do POS",icon:<img src={Pos} alt="Brain" className="titleIconXray" /> }]
  
    return (
        <CustomizedTable
          rows ={rowsPOS}
          title ={titlePOS}
          subTitle ={""}
          colSpan ={"1"}
          >
        </CustomizedTable>
     )
    }
  
    renderUsefulLinksPortal(){
  
        let rows = [
          {
            linkName: 'PRIMEIRO ACESSO',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Como-faco-o-primeiro-acesso-ao-portal-Stone-1493409313837'
          },
          {
            linkName: 'ESQUECI A SENHA',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Como-altero-a-senha-do-meu-portal-Stone-1493409315435'
          },
          {
            linkName: 'TOUR DO PORTAL',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Conheca-o-portal-da-Stone-1493409313185'
          },
          {
            linkName: 'ABA RESUMO',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Nova-aba-resumo-no-Portal'
          },
          {
            linkName: 'ABA VENDAS',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Como-eu-vejo-as-vendas-que-eu-realizei'
          },
          {
            linkName: 'ABA PAGAMENTOS',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Como-eu-vejo-os-meus-pagamentos'
          },
          {
            linkName: 'VERIFICAR AS TAXAS',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Como-verificar-minhas-taxas-no-Portal'
          },
          {
            linkName: 'CANCELAMENTO DE VENDA',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Cancelamento-de-venda-no-portal-do-cliente-1493409314792'
          },
          {
            linkName: 'PEDIR BOBINA',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Como-pedir-bobina-no-portal-do-cliente-1493409307759'
          },
          {
            linkName: 'MANUAL DO APP',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Manual-do-Aplicativo-Portal-Stone'
          }
        ]
  
    let rowsPortal = []
    rowsPortal.push(
      this.buildOptionsFromArray(rows,'Não há Links')
    )
  
    let titlePortal = [{name:"Portal",icon:<img src={Computer} alt="Brain" className="titleIconXray" /> }]
  
    return (
        <CustomizedTable
          rows ={rowsPortal}
          title ={titlePortal}
          subTitle ={""}
          colSpan ={"1"}
          >
        </CustomizedTable>
     )
    }
  
    renderUsefulLinksRAV(){
  
        let rows = [
          {
            linkName: 'O QUE É RAV',
            linkbutton: 'https://stone.force.com/ajuda/s/article/O-que-e-recebimento-antecipado-de-vendas-e-quais-os-beneficios'
          },
          {
            linkName: 'PONTUAL x AUTOMÁTICA',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Como-posso-antecipar'
          },
          {
            linkName: 'CÁLCULO RAV',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Como-e-o-calculo-do-recebimento-antecipado'
          },
          {
            linkName: 'RAV NA S920',
            linkbutton: 'https://stone.force.com/ajuda/s/article/app-Antecipacao-na-S920'
          },
          {
            linkName: 'RAV NA D210N',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Antecipacao-na-maquina'
          },
          {
            linkName: 'RAV NO PORTAL',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Como-realizar-antecipacao-pelo-portal-e-conferir-minhas-taxas'
          },
          {
            linkName: 'RAV NO APP – ANDROID',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Aplicativo-Portal-Stone-Como-fazer-uma-antecipacao-Android'
          },
          {
            linkName: 'RAV NO APP – IOS',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Aplicativo-Portal-Stone-Como-fazer-uma-antecipacao-iOS'
          },
          {
            linkName: 'COMO FUNCIONA OUTROS PRODUTOS',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Raio-X-e-suas-principais-funcionalidades'
          },
          {
            linkName: 'APP RAIO X',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Autosservico-no-Raio-X'
          }
          ,
          {
            linkName: 'COLLACT',
            linkbutton: 'https://stone.force.com/ajuda/s/article/Parceria-Stone-e-Collact'
          }
        ]
  
    let rowsRAV = []
    rowsRAV.push(
      this.buildOptionsFromArray(rows,'Não há Links')
    )
  
    let titleRAV = [{name:"RAV e Outros Produtos",icon:<img src={PortalComputer} alt="Brain" className="titleIconXray" /> }]
  
    return (
        <CustomizedTable
          rows ={rowsRAV}
          title ={titleRAV}
          subTitle ={""}
          colSpan ={"1"}
          >
        </CustomizedTable>
     )
    }

    render(){
        return(
            <Row>
              <Col className="customizedTable left" xs sm md = "12" lg xl = "3.8" align="left" style={{marginTop: '5px' }}>
                {this.renderUsefulLinksPOS()}
              </Col>
              <Col className="customizedTable left" xs sm md = "12" lg xl = "3.8" align="left" style={{marginTop: '5px' }}>
                {this.renderUsefulLinksPortal()}
              </Col>
              <Col className="customizedTable left" xs sm md = "12" lg xl = "3.8" align="left" style={{marginTop: '5px' }}>
                {this.renderUsefulLinksRAV()}
              </Col>
            </Row>
    )
    }
  

  }

  export default LinksTable