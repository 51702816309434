import React from "react";
import XLSX from "xlsx";
import MaterialTable from 'material-table';
import { Container, Form, Col, Card, Button, Alert, Row } from 'react-bootstrap'
import LogisticService from "../../core/services/LogisticService";
import LoadingSpinner from '../../components/LoadingSpinner'

export class SheetJSAppOmId extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sucess: false,
            error: false,
            messageerror: 'Ocorreu um erro ao salvar as expedições!'
        };
        this.handleFile = this.handleFile.bind(this);
    }
    handleFile(file /*:File*/) {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, {header: 1, range: 0, defval: ""});


            const organized_data = organize_data(data);

            if (organized_data === []) {
                this.setState({
                    messageerror: "As colunas importadas não batem com o padrão!",
                    error: true
                })
            }

            this.setState({
                loading: true
            });
            

            LogisticService.postMultipleOmIdExcel({'expedition_list': organized_data.rows}).then((response) => {
                this.setState({
                    loading: false,
                    sucess: true
                });
            }, (reason => {
                this.setState({
                    loading: false,
                    error: true
                });
            }));
        };

        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    }

    render() {
        return (
            <DragDropFile handleFile={this.handleFile}>
                <Alert hidden={!this.state.sucess} variant="success">
                    <Alert.Heading>Boa!</Alert.Heading>
                    <p>
                        Expedições cadastradas com sucesso!
                    </p>
                    <Button onClick={() => this.setState({sucess: false})} variant="outline-success">
                        Ok!
                    </Button>
                </Alert>
                <Alert hidden={!this.state.error} variant="danger">
                    <Alert.Heading>Ocorreu um erro! ):</Alert.Heading>
                    <p>
                        {this.state.messageerror}
                    </p>
                    <Button onClick={() => this.setState({error: false})} variant="outline-danger">
                        Ok :/
                    </Button>
                </Alert>
                <div hidden={!this.state.loading} style={{ width: 'center', textAlign: 'center' }}>
                    <LoadingSpinner/>
                </div>
                <Col>
                    <p></p>
                    <div className="text-center">
                        <DataInput handleFile={this.handleFile} />
                    </div>
                </Col>
            </DragDropFile>
        );
    }
}

class DragDropFile extends React.Component {
    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
    }
    suppress(evt) {
        evt.stopPropagation();
        evt.preventDefault();
    }
    onDrop(evt) {
        evt.stopPropagation();
        evt.preventDefault();
        const files = evt.dataTransfer.files;
        if (files && files[0]) this.props.handleFile(files[0]);
    }
    render() {
        return (
            <div
                onDrop={this.onDrop}
                onDragEnter={this.suppress}
                onDragOver={this.suppress}
            >
                {this.props.children}
            </div>
        );
    }
}

class  DataInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(e) {
        const files = e.target.files;
        if (files && files[0]) this.props.handleFile(files[0]);
    }
    render() {
        return (
                <Form.Group controlId="formBasicPassword">
                    <Form.Control type="file" placeholder="Password" accept={SheetJSFT}
                                  onChange={this.handleChange} />
                </Form.Group>
        );
    }
}

/* list of supported file types */
const SheetJSFT = [
    "xlsx",
    "xlsb",
    "xlsm",
    "xls",
    "xml",
    "csv",
    "txt",
    "ods",
    "fods",
    "uos",
    "sylk",
    "dif",
    "dbf",
    "prn",
    "qpw",
    "123",
    "wb*",
    "wq*",
    "html",
    "htm"
]
    .map(function(x) {
        return "." + x;
    })
    .join(",");

/* generate an array of column objects */
const organize_data = data => {

    let cols = [];
    let rows = [];

    data[0].forEach(function (col) {
        if (col === 'SOLICITAÇÃO' || col === 'EXPEDIÇÃO' || col === 'MODIFICAÇÃO') {
            cols.push({field: col, title: col, type: 'date'});
        } else {
            cols.push({field: col, title: col});
        }
    });

    data.shift();

    data.forEach(function (row) {
        let apartedRows = {};
        for (let i = 0; i < row.length; i++) {
            if (cols[i].field === 'SOLICITAÇÃO' || cols[i].field === 'EXPEDIÇÃO' || cols[i].field === 'PREVISÃO CHEGADA') {
                apartedRows[cols[i].field] = new Date(Math.round((row[i] - 25569)*86400*1000));
            } else {
                apartedRows[cols[i].field] = row[i];
            }
        }
        rows.push(apartedRows);
    });

    return {rows: rows, cols: cols};
};
