import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import LogisticService from '../../core/services/LogisticService'
import { Container, Form, Row, Col, Card, Button } from 'react-bootstrap'
import CustomizedSnackbars from '../../custom-components/SnackBars'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomizedTable from '../../custom-components/CustomizedTable'
import  Brain   from './img/brain.png'
import LoadingSpinner from '../../components/LoadingSpinner'

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: 'white',
      color: 'dark',
      fontSize: '0.9em',
    },
    body: {
      fontSize: '0.8em'
    },
  }))(TableCell);

const ProblemsTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#37B766',
      color: 'white',
      fontSize: '0.9em',
    },
    body: {
      fontSize: '0.8em'
    },
  }))(TableCell);

class XavierTable extends Component {

    constructor(props) {
  
        super(props)
    
        this.state = {
            stonecode: props.stonecode,
            custumersProblems:undefined,
            modalIsOpen: false,
            selectedProblem: undefined
        }
    
        this.refreshReportData = this.refreshReportData.bind(this)
      }

    refreshReportData() {
        this.setState({
            custumersProblems:undefined,
            modalIsOpen: false
        });

        try{
            LogisticService.getXavierCustomerInfo(this.state.stonecode).then((xavierInfo)=>{
                if(xavierInfo.data == false){
                    this.setState({
                        custumersProblems:['Problema no Xavier']
                    });
                }
                else{
                  LogisticService.getCustomerPixInfo(this.state.stonecode).then((pixInfo)=>{
                      xavierInfo.data.problems.push(pixInfo.data)
                      this.setState({
                          custumersProblems:xavierInfo.data.problems
                      });
                    })
                }
            })
        }
        catch{
            this.setState({
                custumersProblems:['Problema no Xavier']
            });
        }
        finally{
            console.log(this.state.custumersProblems)
        }
        

    }

    componentDidMount() {
        this.refreshReportData()
    }

    HandleClick(problem) {
        if(this.state.modalIsOpen){
          this.setState({modalIsOpen: false});
        }
        else{
          this.setState({
            modalIsOpen: true,
            selectedProblem: problem
          });
        }
      }
    
    buildArrayOptions(array){

        if (array.length === 0)
          return (<option>   </option>)
    
        const results = array.map(element =>
          (<option> {element} </option>)
      )
        return results
      }

    buildOptionsFromArray(array,comment){    
        if (array.length === 0)
          return (<TableRow>
            <ProblemsTableCell>{comment}</ProblemsTableCell>
          </TableRow>)
        else{
          const results = array.map(element =>
            (<TableRow>
              <ProblemsTableCell onClick={(e) => this.HandleClick(element)}><i class={element.status} fontSize='24px'>warning</i> {element.name}</ProblemsTableCell>
            </TableRow>)
            )
          return results
        }
    
      }
    
    
    renderModal(){
        var problem = this.state.selectedProblem
        if(problem.name =='Conta bancária inválida'){
          return (
            <TableRow>
              <ProblemsTableCell> Esse cliente está com a conta bancária Inválida.<br/>
              Dessa forma, está impossibilitado de receber qualquer transação.<br/>
              Entre em contato com o RC junto ao cliente para resolver a questão.<br/>
              Telefone do RC: (21) 3004-9680</ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='OS de Troca Aberta - Cliente Onboarding'){
          return (
            <TableRow>
              <ProblemsTableCell> Está aberta uma OS de troca dentro dos primeiros 30 dias do credenciamento do cliente.</ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Bounce no Email'){
          return (
            <TableRow>
              <ProblemsTableCell> Este cliente está com problema para receber emails da Stone.<br/>
              Confirme o email dele ou peça para entrar em contato com o RC.<br/>
              Telefone do RC: (21) 3004-9680 </ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Primeira Instalação'){
          return (
            <TableRow>
              <ProblemsTableCell> Está aberta a primeira OS do cliente.<br/>
              Dessa forma, vamos causar uma excelente primeira impressão!</ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Cliente detrator'){
          return (
            <TableRow>
              <ProblemsTableCell> Esse cliente é um cliente detrator.
              Dessa froma, tem um NPS baixo.</ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Elo Full,Amex ou Hipercard não habilitados'){
          var available = ''
          available = problem.data.map(element => {
            return (
              <div>
              <label>{`BANDEIRA: ${element.brand} - PRODUTO: ${element.product} - STATUS: ${element.status} `}</label>
              <br/>
              </div>
            )
          }
          )
          return (
            <TableRow>
              <ProblemsTableCell> Esse cliente possui bandeiras não habilitadas.<br/>
              {available}
              </ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Vouchers de alimentação ainda não habilitados'){
          var available = ''
          var usefull_days = '3'
          available = problem.data.map(element => {
            if(element.brand == 'Alelo' || element.brand == 'Ticket' || element.brand == 'VR'){
              usefull_days = '5'
            }
            return (
              <div>
              <label>{`VOUCHER: ${element.brand} - PRODUTO: ${element.product} - STATUS: ${element.status} - Prazo Médio de Habilitação: ${usefull_days} dias úteis`}</label>
              <br/>
              </div>
            )
          }
          )
          return (
            <TableRow>
              <ProblemsTableCell> Esse cliente possui Vouchers ainda não habilitadas.<br/>
              {available}
              </ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Cliente em churn'){
          return (
            <TableRow>
              <ProblemsTableCell> Esse cliente é um possível churn. É uma boa oportunidade para
              tentar reverter.</ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Falta de uso de vouchers'){
          return (
            <TableRow>
              <ProblemsTableCell> Esse cliente possui vouchers habilitados mas não está utilizando.<br/>
              É uma oportunidade de verificar se há algum problema ou mapear o balcão.</ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Cliente nao usa modalidade credito'){
          return (
            <TableRow>
              <ProblemsTableCell> Esse cliente não está utilizando a modalidade crédito.<br/>
              É uma oportunidade de verificar se o cliente usa apenas débito ou utiliza alguma
              outra adquirente para crédito.</ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Esse cliente possui trava com seu banco.'){
          return (
            <TableRow>
              <ProblemsTableCell> Essa é uma excelente oportunidade de falar sobre a Conta Stone.<br/>
              Caso o cliente tenha interesse, baixe com ele o App da Conta Stone!</ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Cliente acessou o portal há mais de duas semanas'){
          return (
            <TableRow>
              <ProblemsTableCell> Faz mais de 2 semanas que o cliente não acessa o portal.<br/>
              É uma ótima oportunidade de lembrá-lo do portal e acessar com ele.<br/>
              Vamos ajudar o cliente a ter uma experiência mais completa com a gente!</ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Cliente nunca acessou ao portal.'){
          return (
            <TableRow>
              <ProblemsTableCell> Esse cliente nunca acessou o portal.
              Por que não acessar com ele e mostrar a experiência completa dele com a gente?<br/>
              O email dele para acessar o portal é:<br/>
              {problem.data}<br/>
              Caso esse email esteja errado, peça que entre em contato com RC para alterar.<br/>
              Telefone do RC: (21) 3004-9680</ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Oportuninade de Vender RAV'){
          return (
            <TableRow>
              <ProblemsTableCell> Esse cliente já antecipou antes e é uma excelente oportunidades
              para ativar a antecipação automática!<br/>
              As taxas são: <br/>
              Automática: {problem.data.advanceRate}<br/>
              Spot: {problem.data.spotRate}<br/>
              Angel também faz RAV!</ProblemsTableCell>
            </TableRow>
          )
        }
        else if(problem.name =='Cliente com antecipação bloqueada'){
          return (
            <TableRow>
              <ProblemsTableCell> Esse cliente está com a antecipação bloqueada.<br/>
              Não vale tentar vender RAV.</ProblemsTableCell>
            </TableRow>
          )
        }
        else{
          return (
            <TableRow>
              <ProblemsTableCell></ProblemsTableCell>
            </TableRow>
          )
        }
      }


    renderCustomersProblemsSummary(response){
        let titleXavier = [{name:"Oportunidades do Xavier",icon:<img src={Brain} alt="Brain" className="titleIconClient" /> }]
        if(response[0] == 'Problema no Xavier' && response[1] == undefined){
            return (
                <CustomizedTable
                  rows ={[
                    (<TableRow>
                        <ProblemsTableCell>API do Xavier em lentidão, aguarde um pouco, se demorar procure o time de sistemas :)</ProblemsTableCell>
                    </TableRow>)
                  ]}
                  title ={titleXavier}
                  subTitle ={""}
                  colSpan ={"1"}
                  >
                </CustomizedTable>
             )
        }
        let rows = []
        let redList = ['Conta bancária inválida','Mapear Cancelamento','Bounce no Email','Cliente não possui Conta Stone ou PIX.','Oportunidade de ativar chaves PIX, cliente já possui conta.','Esse cliente precisa autorizar o Raio-X no portal da Rede!']
        let yellowList = ['Vouchers de alimentação ainda não habilitados','Elo Full,Amex ou Hipercard não habilitados','Cliente detrator']
        let greenList = ['Cliente acessou o portal há mais de duas semanas','Venda ativa de RAV','Cliente nunca acessou ao portal.',
      'Cliente com antecipação bloqueada','Esse cliente possui trava com seu banco.','Cliente nao usa modalidade credito','Falta de uso de vouchers',
      'Cliente em churn','Oportuninade de Vender RAV','Primeira Instalação','OS de Troca Aberta - Cliente Onboarding']
        response.forEach((element) => {
          if(element.name=='Venda ativa de RAV'){
            element.name = 'Oportuninade de Vender RAV'
          }
          if(greenList.includes(element.name) ){
            rows.push({
              name: element.name,
              status: "material-icons green",
              data: element.data
          })
          }
          else if(yellowList.includes(element.name) ){
            rows.push({
              name: element.name,
              status: "material-icons yellow",
              data: element.data
          })
          }
          else if(redList.includes(element.name) ){
            rows.push({
              name: element.name,
              status: "material-icons red",
              data: element.data
          })
          }
    }) 
  
  
    let rowsXavier = []
    rowsXavier.push(
      (this.state.modalIsOpen) ? this.renderModal(): '',
      this.buildOptionsFromArray(rows,'Nenhuma oportunidade para mostrar')
    )
  
    return (
        <CustomizedTable
          rows ={rowsXavier}
          title ={titleXavier}
          subTitle ={""}
          colSpan ={"1"}
          >
        </CustomizedTable>
     )
    }


    render(){
        if(this.state.custumersProblems == undefined){
            return(
            <Col className="customizedTable right" xs sm md = "12" lg xl = "3" align="right" style={{marginTop: '5px'}}>
                <LoadingSpinner/>
            </Col>
            )
        }
        else{
            return (
                <Col className="customizedTable right" xs sm md = "12" lg xl = "3" align="right" style={{marginTop: '5px'}}>
                    {this.renderCustomersProblemsSummary(this.state.custumersProblems)}
                </Col>
                
            )
        }
        
    }
}

export default XavierTable
