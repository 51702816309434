import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { StateProvider } from "./store.js";
import ReactGA from "react-ga";

ReactGA.initialize("UA-156091472-1");
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <StateProvider>
    <App />
  </StateProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
