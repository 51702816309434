import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from "@material-ui/core/Grid"
import React from 'react'
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap'
import XLSX from "xlsx"
import LoadingSpinner from '../../components/LoadingSpinner'
import LogisticService from '../../core/services/LogisticService'
import { SheetJSLigaTree } from "../../custom-components/SheetJs/SheetJsLigaTree"
import { TreeList } from './treeList'


class LigaTree extends React.Component {
    constructor() {

        super();

        this.state = {
            loading_search: false,
            loading_save: false,
            error: false,
            data: [],
            success: false,
            tema: null,
            categoria: null,
            assunto: null,
            ativo: false,
            filtros_search: {}
        }
    }

    searchLiga = () => {
        this.setState({
            loading_search: true,
        });

        let filtros = {}

        if (this.state.tema != undefined && this.state.tema != '') {
            filtros['tema'] = this.state.tema
        }

        if (this.state.categoria != undefined && this.state.categoria != '') {
            filtros['categoria'] = this.state.categoria
        }

        if (this.state.assunto != undefined && this.state.assunto != '') {
            filtros['assunto'] = this.state.assunto
        }

        if (this.state.ativo != undefined && this.state.ativo != '') {
            filtros['ativo'] = this.state.ativo
        }

        LogisticService.getLigaTree(filtros).then((response) => {
            this.setState({
                filtros_search: filtros,
                loading_search: false,
                data: response.data.data,
            })
        }, (reason => {
            this.setState({
                loading_search: false,
            });
        }));
    };

    saveSingleLigaInput = () => {
        this.setState({
            loading_save: true,
            error: false
        });

        let input = {}

        if (this.state.tema != undefined && this.state.tema != '') {
            input['tema'] = this.state.tema
        }

        if (this.state.categoria != undefined && this.state.categoria != '') {
            input['categoria'] = this.state.categoria
        }

        if (this.state.assunto != undefined && this.state.assunto != '') {
            input['assunto'] = this.state.assunto
        }

        input['ativo'] = this.state.ativo

        LogisticService.postLigaTree(input).then((response) => {
            this.setState({
                success: true,
                loading_save: false
            })
        }, (response) => {
            this.setState({
                error: true,
                loading_save: false
            })
        })
    };

    exportFile() {
        let worksheet_example = [['tema', 'categoria', 'assunto', 'ativo']]

        const ws = XLSX.utils.aoa_to_sheet(worksheet_example);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        XLSX.writeFile(wb, "Exemplo Arvore Liga.xlsx");
    }

    changeActiveValue() {
        this.state.ativo = !this.state.ativo;
    }

    render() {
        return (
            <Grid fluid style={{ 'paddingLeft': 0, 'paddingRight': 0 }}>
                <Row>
                    <Col lg="3" />
                    <Col lg="3">
                        <Button variant="success" onClick={this.exportFile} >Exportar planilha exemplo</Button>
                    </Col>
                    <Col lg="3">
                        <p>Importar Árvores pelo Excel</p>
                        <SheetJSLigaTree />
                    </Col>
                </Row>
                <Row style={{ 'marginLeft': '1em', 'marginRight': '1em', 'fontSize': '0.9em' }}>
                    <Col lg="2" />
                    <Col lg="4" sm="12" xs="12">
                        <Card className="text-center">
                            <Card.Header style={{ 'backgroundColor': '#103a21', 'color': 'white' }}>Inserir árvore</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Group as={Col}>
                                        <Form.Label>Tema</Form.Label>
                                        <Form.Control type="text" id="tema" placeholder="Tema" onChange={(e) => this.setState({ tema: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Categoria</Form.Label>
                                        <Form.Control type="text" id="categoria" placeholder="Categoria" onChange={(e) => this.setState({ categoria: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Assunto</Form.Label>
                                        <Form.Control type="text" id="assunto" placeholder="Assunto" onChange={(e) => this.setState({ assunto: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Ativo</Form.Label>
                                        <FormControlLabel id="ativo" style={{ margin: 0 }} control={<Checkbox onChange={e => this.changeActiveValue()} style={{ color: "green" }} />} />
                                    </Form.Group>
                                </Form>
                                <Button variant="success" onClick={this.saveSingleLigaInput} >Salvar</Button>
                                <div hidden={!this.state.loading_save} style={{ width: 'center', textAlign: 'center' }}>
                                    <LoadingSpinner />
                                </div>
                                <Alert hidden={!this.state.success} variant="success" style={{ margin: '24px' }}>
                                    <Alert.Heading>Boa!</Alert.Heading>
                                    <p>
                                        Árvore cadastrada com sucesso!
                                    </p>
                                    <Button onClick={() => this.setState({ success: false })} variant="outline-success">
                                        Ok!
                                    </Button>
                                </Alert>
                                <Alert hidden={!this.state.error} variant="danger" style={{ margin: '24px' }}>
                                    <Alert.Heading>Ocorreu um erro! ):</Alert.Heading>
                                    <p>
                                        Algo de errado ocorreu! Tente novamente mais tarde!
                                    </p>
                                    <Button onClick={() => this.setState({ error: false })} variant="outline-danger">
                                        Ok :/
                                    </Button>
                                </Alert>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4" sm="12" xs="12">
                        <Card className="text-center">
                            <Card.Header style={{ 'backgroundColor': '#103a21', 'color': 'white' }}>Buscar Árvore</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Group as={Col}>
                                        <Form.Label>Tema</Form.Label>
                                        <Form.Control type="text" id="tema" placeholder="Tema" onChange={(e) => this.setState({ tema: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Categoria</Form.Label>
                                        <Form.Control type="text" id="categoria" placeholder="Categoria" onChange={(e) => this.setState({ categoria: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Assunto</Form.Label>
                                        <Form.Control type="text" id="assunto" placeholder="Assunto" onChange={(e) => this.setState({ assunto: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Ativos</Form.Label>
                                        <Form.Control id="gender" size="sm" as="select" onChange={e => { this.setState({ ativo: e.target.value }) }} >
                                            <option>Mostrar tudo</option>
                                            <option value={"true"}>Mostrar apenas ativos</option>
                                            <option value={"false"}>Mostrar apenas inativos</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Button variant="success" onClick={this.searchLiga} >Buscar</Button>
                                    <div hidden={!this.state.loading_search} style={{ width: 'center', textAlign: 'center' }}>
                                        <LoadingSpinner />
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ 'margin-left': 0, 'margin-top': '24px', 'margin-right': 0 }}>
                    <Col>
                        <TreeList data={this.state.data} filtros={this.state.filtros_search} />
                    </Col>
                </Row>
            </Grid>
        )
    }

}


export default LigaTree;
