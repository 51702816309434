import React from "react";
import { Select } from "antd";
const { Option } = Select;

const formatarMesEAno = (mes, ano) => {
  const formattedMonth = mes < 10 ? "0".concat(mes) : mes;
  return formattedMonth + "/" + ano;
};

const PainelDeBusca = (props) => {
  const { nome, nomes, mesesDisponiveis, mes, ano } = props;

  function alterarColaborador(novoNome) {
    props.alterarColaborador(novoNome);
  }

  function alterarData(novaData) {
    props.alterarData(novaData);
  }

  return (
    <div>
      <Select
        defaultValue={nome}
        style={{ width: 300, margin: "16px" }}
        onChange={alterarColaborador}
      >
        {nomes.map((nome) => (
          <Option key={nome} value={nome}>
            {nome}
          </Option>
        ))}
      </Select>

      <Select
        defaultValue={formatarMesEAno(mes, ano)}
        style={{ width: 120, margin: "16px" }}
        onChange={alterarData}
      >
        {mesesDisponiveis
          .sort(
            (a, b) =>
              a.referenceYear - b.referenceYear ||
              a.referenceMonth - b.referenceMonth
          )
          .map((periodo) => {
            const mes = periodo.referenceMonth;
            const ano = periodo.referenceYear;
            const dataFormatada = formatarMesEAno(mes, ano);
            return (
              <Option key={Math.random()} value={{ mes, ano, dataFormatada }}>
                {dataFormatada}
              </Option>
            );
          })}
      </Select>
    </div>
  );
};

export default PainelDeBusca;
