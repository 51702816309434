import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import LogisticService from "../../../core/services/LogisticService";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Redirect } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMotorcycle,
  faMapMarkedAlt,
  faGlobeAmericas,
  faTrophy,
  faShieldAlt,
  faBoxOpen,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

// Styles
import "./style.css";

export default class VariableRemunerationSearchPage extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      activeStep: 0,
      nivelDeAngel: undefined,
      redirect: false,
    };
  }

  handleNext(event) {
    var currentStep = this.state.activeStep;

    this.setState({
      activeStep: currentStep + 1,
    });
  }

  handleBack(event) {
    var currentStep = this.state.activeStep;

    this.setState({
      activeStep: currentStep - 1,
    });
  }

  handleReset(event) {
    this.setState({
      activeStep: 0,
    });
  }

  selecionarMesApuracao(event, level) {
    if (this.state.activeStep === 0 || this.state.nivelDeAngel !== level) {
      this.setState({ loading: true });

      LogisticService.periodosDiponiveisRV(level)
        .then((response) => {
          this.setState({
            mesesDisponiveis: response.data.data,
            activeStep: 1,
            nivelDeAngel: level,
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  handleSelectedPeriod() {
    console.log("Selecionando nomes");
    var currentStep = this.state.activeStep;
    var selectedPeriod = this.state.period;
    if (!selectedPeriod) return;

    var mes = selectedPeriod.split("-")[0];
    var ano = selectedPeriod.split("-")[1];

    this.setState({ loading: true });

    if (this.state.nivelDeAngel === "angel") {
      console.log("Selecionando nomes de angel");

      LogisticService.listarGreenAngelsRV(ano, mes)
        .then((response) => {
          var districts = [];
          console.log("Consegui a lista de nomes de angel");
          response.data.data.forEach((element) => {
            if (!districts.includes(element.districtName)) {
              districts.push(element.districtName);
            }
          });

          this.setState({
            avaiableNames: response.data.data,
            avaiableDistricts: districts,
            activeStep: currentStep + 1,
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (this.state.nivelDeAngel === "host") {
      LogisticService.listarHostAngelsRV(ano, mes)
        .then((response) => {
          var districts = [];
          console.log(response);

          response.data.data.forEach((element) => {
            if (!districts.includes(element.districtName)) {
              districts.push(element.districtName);
            }
          });

          this.setState({
            avaiableNames: response.data.data,
            avaiableDistricts: districts,
            activeStep: currentStep + 1,
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (this.state.nivelDeAngel === "district") {
      LogisticService.listarDistritosRV(ano, mes)
        .then((response) => {
          this.setState({
            avaiableNames: response.data.data,
            activeStep: currentStep + 1,
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (this.state.nivelDeAngel === "region") {
      LogisticService.listarRegionaisRV(ano, mes)
        .then((response) => {
          this.setState({
            avaiableNames: response.data.data,
            activeStep: currentStep + 1,
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (this.state.nivelDeAngel === "guardian") {
      LogisticService.listarGuardiansRV(ano, mes)
        .then((response) => {
          this.setState({
            avaiableNames: response.data.data,
            activeStep: currentStep + 1,
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (this.state.nivelDeAngel === "supplier") {
      LogisticService.listarSuppliersRV(ano, mes)
        .then((response) => {
          this.setState({
            avaiableNames: response.data.data,
            activeStep: currentStep + 1,
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (this.state.nivelDeAngel === "ranking") {
      this.setState({
        activeStep: currentStep + 1,
        loading: false,
      });
    }
  }
  seletorDeNivel() {
    // if (this.state.activeStep === 0) {
    return (
      <div className="divSelection">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={2} className="colSelection">
            <div
              className="box icon-btn has-text-success"
              onClick={(e) => this.selecionarMesApuracao(e, "angel")}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faMotorcycle} />
              </span>
              <b>Green Angel</b>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={2} className="colSelection">
            <div
              className="box icon-btn has-text-success"
              onClick={(e) => this.selecionarMesApuracao(e, "host")}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faStar} />
              </span>
              <b>Host Angel</b>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={2} className="colSelection">
            <div
              className="box icon-btn has-text-success"
              onClick={(e) => this.selecionarMesApuracao(e, "supplier")}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faBoxOpen} />
              </span>
              <b>Supplier Angel</b>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={2} className="colSelection">
            <div
              className="box icon-btn has-text-success"
              onClick={(e) => this.selecionarMesApuracao(e, "guardian")}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faShieldAlt} />
              </span>
              <b>Guardian Angel</b>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={2} className="colSelection">
            <div
              className="box icon-btn has-text-success"
              onClick={(e) => this.selecionarMesApuracao(e, "district")}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faMapMarkedAlt} />
              </span>
              <b>Distrital</b>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={2} className="colSelection">
            <div
              className="box icon-btn has-text-success"
              onClick={(e) => this.selecionarMesApuracao(e, "region")}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faGlobeAmericas} />
              </span>
              <b>Regional</b>
            </div>
          </Col>
        </Row>
        {this.state.loading ? (
          <div style={{ margin: "2em" }} className="loadingSelection">
            <LoadingSpinner />
          </div>
        ) : this.state.activeStep >= 1 ? (
          <Row>
            <br />
            <br />
            <FormControl variant="outlined" className="selectBox">
              <InputLabel id="demo-simple-select-outlined-label">
                Selecione um mês
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.period}
                onChange={(e, value) =>
                  this.setState({ period: value.props.value })
                }
                labelWidth={320}
                style={{ width: 320, marginTop: "1em" }}
              >
                <MenuItem value="empty">Selecione um mês</MenuItem>
                {this.state.mesesDisponiveis
                  .sort(
                    (a, b) =>
                      a.referenceYear - b.referenceYear ||
                      a.referenceMonth - b.referenceMonth
                  )
                  .map((row) => {
                    const formattedMonth =
                      row.referenceMonth < 10
                        ? "0".concat(row.referenceMonth)
                        : row.referenceMonth;
                    return (
                      <MenuItem
                        value={row.referenceMonth + "-" + row.referenceYear}
                      >
                        {formattedMonth}/{row.referenceYear}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Row>
        ) : (
          ""
        )}
        {this.state.activeStep >= 2 &&
        this.state.avaiableNames === undefined ? (
          <div className="loadingSelection">
            <LoadingSpinner></LoadingSpinner>
          </div>
        ) : (
          ""
        )}
        {this.state.activeStep >= 2 &&
        this.state.nivelDeAngel !== "angel" &&
        this.state.avaiableNames !== undefined ? (
          <Row>
            <FormControl variant="outlined" className="selectBox">
              <InputLabel id="demo-simple-select-outlined-label">
                Selecione um nome
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.selectedName}
                onChange={(e, value) =>
                  this.setState({ selectedName: value.props.value })
                }
                labelWidth={320}
                style={{ width: 320, marginTop: "1em" }}
              >
                <MenuItem value="Selecione um nome">Selecione um nome</MenuItem>
                {this.state.avaiableNames.map((row) => {
                  if (this.state.nivelDeAngel === "host") {
                    return (
                      <MenuItem value={row.angelName}>{row.angelName}</MenuItem>
                    );
                  } else if (this.state.nivelDeAngel === "district") {
                    return (
                      <MenuItem value={row.districtName}>
                        {row.districtName}
                      </MenuItem>
                    );
                  } else if (this.state.nivelDeAngel === "region") {
                    return (
                      <MenuItem value={row.regionName}>
                        {row.regionName}
                      </MenuItem>
                    );
                  } else if (this.state.nivelDeAngel === "guardian") {
                    return (
                      <MenuItem value={row.guardianName}>
                        {row.guardianName}
                      </MenuItem>
                    );
                  } else if (this.state.nivelDeAngel === "supplier") {
                    return (
                      <MenuItem value={row.supplierName}>
                        {row.supplierName}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </Row>
        ) : (
          ""
        )}
        {this.state.activeStep >= 2 &&
        this.state.nivelDeAngel === "angel" &&
        this.state.avaiableNames !== undefined &&
        this.state.avaiableDistricts !== undefined ? (
          <Row>
            <Col xs sm md={12} lg xl={6}>
              <FormControl variant="outlined" className="selectBox">
                <InputLabel id="demo-simple-select-outlined-label">
                  Selecione um Distrito
                </InputLabel>
                <Select
                  value={this.state.selectedDistrict || ""}
                  onChange={(e, value) =>
                    this.setState({ selectedDistrict: value.props.value })
                  }
                  labelWidth={320}
                  style={{ width: 320, marginTop: "1em" }}
                >
                  <MenuItem value="Selecione um nome">Distrito</MenuItem>
                  {this.state.avaiableDistricts
                    .sort((a, b) => (a < b ? -1 : 1))
                    .map((row) => (
                      <MenuItem value={row}>{row}</MenuItem>
                    ))}{" "}
                  )}
                </Select>
              </FormControl>
            </Col>
            <Col xs sm md={12} lg xl={6}>
              <FormControl
                variant="outlined"
                margin="1em"
                className="selectBox"
              >
                <InputLabel>Selecione um Angel</InputLabel>
                <Select
                  value={this.state.selectedName || ""}
                  onChange={(e, value) =>
                    this.setState({ selectedName: value.props.value })
                  }
                  labelWidth={320}
                  style={{ width: 320, marginTop: "1em" }}
                >
                  <MenuItem value="Selecione um nome">Angel</MenuItem>
                  {this.state.avaiableNames
                    .filter(
                      (obj) => obj.districtName === this.state.selectedDistrict
                    )
                    .map((row) => (
                      <MenuItem value={row.angelName}>{row.angelName}</MenuItem>
                    ))}
                  )}
                </Select>
              </FormControl>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <div className="bottomSelection">
          {this.renderStepsController()}
          {window.innerWidth > 800 ? (
            <div
              style={{
                marginTop: "2em",
                textAlign: "center",
                fontSize: "1.2em",
              }}
            >
              <label>
                Dúvidas? Clique
                <a
                  href={process.env.PUBLIC_URL + "/dist/Manual RV Log.pdf"}
                  download
                >
                  {" "}
                  aqui{" "}
                </a>
                <br />
                para baixar a última versão do manual
              </label>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  renderStepsController() {
    var notValidPeriod =
      ((this.state.period !== undefined
        ? !this.state.period.includes("2")
        : true) ||
        this.state.selectedName === undefined ||
        this.state.selectedName === "Selecione um nome") &&
      this.state.nivelDeAngel !== "ranking";
    var buttonColor = notValidPeriod ? "" : "#28A745";

    if (
      this.state.activeStep === 2 ||
      (this.state.activeStep === 1 && this.state.nivelDeAngel === "ranking")
    ) {
      return (
        <div style={{ textAlign: "center", margin: "1em" }}>
          <Button
            color="default"
            variant="contained"
            disabled={notValidPeriod}
            style={{
              margin: "1em",
              color: "#fff",
              backgroundColor: buttonColor,
            }}
            onClick={(e) => this.handleFinalClick(e)}
          >
            Visualizar{" "}
            {this.state.nivelDeAngel === "ranking" ? "Ranking" : "Parcial"}
          </Button>
        </div>
      );
    }

    if (this.state.activeStep === 0) {
      return <div style={{ textAlign: "center", margin: "1em" }}></div>;
    }
    if (this.state.activeStep === 1) {
      var notValidPeriod =
        this.state.period !== undefined
          ? !this.state.period.includes("2")
          : true;
      var buttonColor = notValidPeriod ? "" : "#28A745";

      return (
        <div style={{ textAlign: "center", margin: "1em" }}>
          <Button
            color="default"
            variant="contained"
            style={{
              margin: "1em",
              color: "#fff",
              backgroundColor: buttonColor,
            }}
            onClick={() => this.handleSelectedPeriod()}
            disabled={notValidPeriod}
          >
            Continuar
          </Button>
        </div>
      );
    }
  }

  handleFinalClick(e) {
    if (
      this.state.nivelDeAngel === "angel" ||
      this.state.nivelDeAngel === "host" ||
      this.state.nivelDeAngel === "district" ||
      this.state.nivelDeAngel === "region" ||
      this.state.nivelDeAngel === "guardian" ||
      this.state.nivelDeAngel === "supplier" ||
      this.state.nivelDeAngel === "ranking"
    ) {
      this.setState({
        redirect: true,
      });
    }
  }
  getAngelResults;
  renderRedirect = () => {
    const year = this.state.period ? this.state.period.split("-")[1] : null;
    const month = this.state.period ? this.state.period.split("-")[0] : null;
    const novaRV = this.state.period
      ? new Date(year, month) >= new Date(2021, 3)
      : false;
    if (this.state.redirect && this.state.nivelDeAngel === "angel") {
      return (
        <Redirect
          to={{
            pathname: novaRV ? "/rv/v2/green-angels" : "/rv/green-angels",
            state: {
              name: this.state.selectedName,
              year: year,
              month: month,
              tipo: "greenAngel",
            },
          }}
        />
      );
    }
    if (this.state.redirect && this.state.nivelDeAngel === "host") {
      return (
        <Redirect
          to={{
            pathname: novaRV ? "/rv/v2/host-angels" : "/rv/host-angels",
            state: {
              name: this.state.selectedName,
              year: this.state.period.split("-")[1],
              month: this.state.period.split("-")[0],
              tipo: "hostAngel",
            },
          }}
        />
      );
    }
    if (this.state.redirect && this.state.nivelDeAngel === "district") {
      return (
        <Redirect
          to={{
            pathname: novaRV ? "/rv/v2/distritos" : "/rv/distritos",
            state: {
              name: this.state.selectedName,
              year: this.state.period.split("-")[1],
              month: this.state.period.split("-")[0],
              tipo: "distrito",
            },
          }}
        />
      );
    }
    if (this.state.redirect && this.state.nivelDeAngel === "region") {
      return (
        <Redirect
          to={{
            pathname: "/regions-variable-remuneration",
            state: {
              name: this.state.selectedName,
              year: this.state.period.split("-")[1],
              month: this.state.period.split("-")[0],
            },
          }}
        />
      );
    }
    if (this.state.redirect && this.state.nivelDeAngel === "guardian") {
      return (
        <Redirect
          to={{
            pathname: novaRV ? "/rv/v2/guardian-angels" : "/rv/guardian-angels",
            state: {
              name: this.state.selectedName,
              year: this.state.period.split("-")[1],
              month: this.state.period.split("-")[0],
              tipo: "guardianAngel",
            },
          }}
        />
      );
    }
    if (this.state.redirect && this.state.nivelDeAngel === "supplier") {
      return (
        <Redirect
          to={{
            pathname: novaRV ? "/rv/v2/supplier-angels" : "/rv/supplier-angels",
            state: {
              name: this.state.selectedName,
              year: this.state.period.split("-")[1],
              month: this.state.period.split("-")[0],
              tipo: "supplierAngel",
            },
          }}
        />
      );
    }
    if (this.state.redirect && this.state.nivelDeAngel === "ranking") {
      return (
        <Redirect
          to={{
            pathname: `/rv/v2/ranking/${year}/${month}`,
          }}
        />
      );
    }
  };

  render() {
    return (
      <Container fluid>
        {this.renderRedirect()}
        <Row
          style={{ marginTop: "2em", marginBottom: "1em", textAlign: "center" }}
        >
          <Col xs sm md lg xl={1} />
          <Col xs sm={10} md lg xl={10}>
            <div
              style={{
                marginBottom: "2em",
                fontSize: "1.5em",
                marginRight: "50%",
              }}
            >
              <Row className="titleRemuneration">
                <Col xs sm={12} md lg xl={6}>
                  <label
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Remuneração Variável
                  </label>
                </Col>
                <Col xs sm={12} md lg xl={3}>
                  <div
                    className="box icon-btn ranking"
                    onClick={(e) => this.selecionarMesApuracao(e, "ranking")}
                  >
                    <span className="icon-ranking">
                      <FontAwesomeIcon
                        className="rankingIcon"
                        icon={faTrophy}
                      />
                      Ranking
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
            {this.seletorDeNivel()}
          </Col>
          <Col xs sm lg xl={1} />
        </Row>
      </Container>
    );
  }
}
