export const styles = {
    ExpansionPanel: {
      marginBottom: '5px'
    },
    ExpansionPanelSummary: {
      height: '4.5px', 
      backgroundColor:'#28a745', 
      color: 'white', 
      margin: '0px 0px', 
      padding: '1em',
      fontSize: '0.9em',
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      }
    }
  }
  