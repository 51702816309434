import React, { Component } from "react";
import PropTypes from "prop-types";
import LogisticService from "../../../core/services/LogisticService";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Container, Col, Row } from "react-bootstrap";
import Box from "@material-ui/core/Box";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import AppBar from "@material-ui/core/AppBar";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Button } from "antd";
import MaterialTable from "material-table";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Redirect, Link } from "react-router-dom";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#103a21",
    color: "white",
    fontSize: "0.9em",
  },
  body: {
    fontSize: "0.8em",
  },
}))(TableCell);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class VariableRemuneration extends Component {
  constructor(props) {
    super();
    this.state = {
      loaded: false,
      lastUpdate: "",
      selectedTab: 0,
      guardianPosition: 0,
      guardianName: props.location.state.name,
      month: props.location.state.month,
      year: props.location.state.year,
      period: props.location.state.month + "-" + props.location.state.year,
      mesesDisponiveis: [],
      guardianResults: undefined,
    };
  }

  componentDidMount() {
    this.refreshReportData(
      this.state.month,
      this.state.year,
      this.state.guardianName
    );
    this.requestGuardiansData(
      this.state.month,
      this.state.year,
      this.state.guardianName
    );
    this.getGuardians();
    this.getMonths();
  }

  getMonths() {
    LogisticService.periodosDiponiveisRV()
      .then((response) => {
        this.setState({
          mesesDisponiveis: response.data.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getGuardians() {
    LogisticService.listarGuardiansRV(this.state.year, this.state.month)
      .then((response) => {
        this.setState({
          avaiableNames: response.data.data,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getRangkinPosition(namesRanking, guardian) {
    let position = 0;
    for (let i = 0; i <= namesRanking.length; i++) {
      if (guardian === namesRanking[i].guardianName) {
        position = i;
        return position;
      }
    }
  }

  requestGuardiansData(month, year, guardian) {
    let guardiansRanking = undefined;
    LogisticService.getGuardiansRanking(year, month).then((response) => {
      guardiansRanking = response.data.data;
      guardiansRanking = guardiansRanking.sort((a, b) =>
        a["results"]["final_score"] >= b["results"]["final_score"] ? -1 : 1
      );
      this.setState({
        guardianPosition: this.getRangkinPosition(guardiansRanking, guardian),
      });
    });
  }

  refreshReportData(month, year, guardian) {
    this.setState({
      loaded: false,
      lastUpdated: undefined,
      guardianResults: undefined,
      hubName: undefined,
      districtName: undefined,
      serviceOrdersDetails: undefined,
      accidentsDetails: undefined,
      penaltiesDetails: undefined,
      collactDetails: undefined,
      xrayDetails: undefined,
      csatDetails: undefined,
      removedTerminalsDetails: undefined,
      screenedTerminalsDetails: undefined,
      reversesDetails: undefined,
      portalDetails: undefined,
      serviceOrdersDetails: undefined,
    });

    LogisticService.getGuardianResults(year, month, guardian).then(
      (response) => {
        this.setState({
          guardianResults: response.data.data[0].results,
          hubName: response.data.data[0].results.polo_name,
          lastUpdate: moment
            .utc(response.data.data[0].dtmModifiedAt)
            .local()
            .format("DD/MM/YYYY - HH:mm"),
          districtName: response.data.data[0].results.district_name,
        });
      }
    );
  }

  ScoreCard(props) {
    var color;
    if (props.ranking !== undefined) {
      color = "#00000099";
    } else if (props.realScore / props.possibleScore >= 0.8) {
      color = "green";
    } else if (props.realScore / props.possibleScore > 0.6) {
      color = "orange";
    } else {
      color = "red";
    }

    return (
      <Card>
        <CardContent>
          <label style={{ color: "grey" }}>
            {props.ranking !== undefined ? (
              <span className="icon">
                <FontAwesomeIcon icon={faTrophy} />
              </span>
            ) : (
              ""
            )}
            {props.title}
          </label>
          <Row
            style={
              props.ranking !== undefined
                ? { marginLeft: "1em" }
                : { marginLeft: "1em", marginTop: "1em" }
            }
          >
            {props.icon}
            <Typography
              variant="h4"
              style={
                props.ranking !== undefined
                  ? { marginTop: "17px", color: color }
                  : { marginLeft: "0.5em", color: color }
              }
            >
              {props.realScore}
            </Typography>
            {props.ranking !== undefined ? (
              <div>
                <p
                  style={{
                    fontSize: "35px",
                    color: color,
                    marginBottom: "-20px",
                  }}
                >
                  °
                </p>
                <Link
                  to={{
                    pathname: "/variable-remuneration-ranking",
                    state: {
                      year: props.year,
                      month: props.month,
                      selectedTab: props.selectedTab,
                    },
                  }}
                >
                  <label
                    style={{
                      cursor: "pointer",
                      fontSize: "15px",
                      color: color,
                      marginLeft: "10px",
                      marginBottom: "-10px",
                      textDecorationLine: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    Ver detalhes
                  </label>
                </Link>
              </div>
            ) : (
              <Typography variant="h6" style={{ color: color }}>
                /{props.possibleScore}
              </Typography>
            )}
          </Row>
        </CardContent>
      </Card>
    );
  }

  SummaryBoxes(rankingPosition) {
    if (this.state.guardianResults === undefined) {
      return;
    }

    const efficiency_weight = this.state.guardianResults.efficiency_column_max;
    const attendance_weight = this.state.guardianResults.attendance_column_max;
    const activation_weight = this.state.guardianResults.activation_column_max;
    const security_weight = this.state.guardianResults.security_column_max;

    return (
      <Box>
        <Row>
          <Col xl sm={12} md lg xl={2} style={{ marginTop: "0.5em" }}>
            <this.ScoreCard
              title="Pilar Atendimento"
              icon={<InsertEmoticonIcon fontSize="large" />}
              realScore={(
                100 * this.state.guardianResults.attendance_column
              ).toFixed(1)}
              possibleScore={attendance_weight}
            />
          </Col>
          <Col xl sm={12} md lg xl={2} style={{ marginTop: "0.5em" }}>
            <this.ScoreCard
              title="Pilar Ativação"
              icon={<MobileFriendlyIcon fontSize="large" />}
              realScore={(
                100 * this.state.guardianResults.activation_column
              ).toFixed(1)}
              possibleScore={activation_weight}
            />
          </Col>
          <Col xl sm={12} md lg xl={2} style={{ marginTop: "0.5em" }}>
            <this.ScoreCard
              title="Pilar Eficiência"
              icon={<MonetizationOnIcon fontSize="large" />}
              realScore={(
                100 * this.state.guardianResults.efficiency_column
              ).toFixed(1)}
              possibleScore={efficiency_weight}
            />
          </Col>
          <Col xl sm={12} md lg xl={2} style={{ marginTop: "0.5em" }}>
            <this.ScoreCard
              title="Pilar Segurança"
              icon={<BeenhereIcon fontSize="large" />}
              realScore={(
                100 * this.state.guardianResults.security_column
              ).toFixed(1)}
              possibleScore={security_weight}
            />
          </Col>
          <Col xl sm={12} md lg xl={2} style={{ marginTop: "0.5em" }}>
            <this.ScoreCard
              title="Atingimento Total"
              realScore={(100 * this.state.guardianResults.final_score).toFixed(
                1
              )}
              possibleScore={100}
            />
          </Col>
          {this.state.guardianPosition !== undefined ? (
            <Col xl sm={12} md lg xl={2} style={{ marginTop: "0.5em" }}>
              <this.ScoreCard
                ranking="true"
                title="Colocação ranking"
                realScore={this.state.guardianPosition}
                year={this.state.year}
                month={this.state.month}
                selectedTab={1}
              />
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Box>
    );
  }

  efficiencyDetails() {
    return (
      <div
        style={{
          width: "70%",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2em",
          marginBottom: "2em",
        }}
      >
        <Table size="medium" padding="default">
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              <TableCell align="center">Atingimento</TableCell>
              <TableCell align="center">Atingimento pós-régua</TableCell>
              <TableCell align="center">Peso</TableCell>
              <TableCell align="center">Impacto no Pilar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">Inventário</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.inventory).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.inventory_bonus).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">-7,5%/+2,5%</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.inventory_bonus).toFixed(1)}%
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">Retroca</StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.guardianResults.screening_quality_score
                ).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.guardianResults.final_screening_quality_score
                ).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">-2,5%/+2,5%</StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.guardianResults.final_screening_quality_score
                ).toFixed(2)}
                %
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  attendanceDetails() {
    var initial_csat_score =
      this.state.guardianResults.csat_total_points /
      this.state.guardianResults.csat_orders_quantity;
    return (
      <div
        style={{
          width: "70%",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2em",
          marginBottom: "2em",
        }}
      >
        <Table size="medium" padding="default">
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              <TableCell align="center">Atingimento</TableCell>
              <TableCell align="center">Atingimento pós-régua</TableCell>
              <TableCell align="center">Peso</TableCell>
              <TableCell align="center">Impacto no Pilar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">SLA</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.raw_sla).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.final_sla_score).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.weights.sla).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.adjusted_sla).toFixed(1)}%
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">CSAT</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * initial_csat_score).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.final_csat_score).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.weights.csat).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.adjusted_csat).toFixed(1)}%
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">
                D-zero (distrito)
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.district_dzero).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.final_dzero).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.weights.dzero).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.adjusted_dzero).toFixed(1)}%
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  activationDetails() {
    return (
      <div
        style={{
          width: "70%",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2em",
          marginBottom: "2em",
        }}
      >
        <Table size="medium" padding="default">
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              <TableCell align="center">Atingimento</TableCell>
              <TableCell align="center">Atingimento pós-régua</TableCell>
              <TableCell align="center">Peso</TableCell>
              <TableCell align="center">Impacto no Pilar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">Novos Produtos</StyledTableCell>
              <StyledTableCell align="center">-</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.new_products_score).toFixed(
                  1
                )}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.guardianResults.weights.new_products
                ).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.guardianResults.adjusted_new_products
                ).toFixed(1)}
                %
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  securityDetails() {
    return (
      <div
        style={{
          width: "70%",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2em",
          marginBottom: "2em",
        }}
      >
        <Table size="medium" padding="default">
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              <TableCell align="center">Atingimento</TableCell>
              <TableCell align="center">Atingimento pós-régua</TableCell>
              <TableCell align="center">Peso</TableCell>
              <TableCell align="center">Impacto no Pilar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">
                Pontos por Multas
              </StyledTableCell>
              <StyledTableCell align="center">
                {this.state.guardianResults.traffic_penalties}
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.guardianResults.final_penalties_score
                ).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.weights.penalties).toFixed(
                  1
                )}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.adjusted_penalties).toFixed(
                  1
                )}
                %
              </StyledTableCell>
            </TableRow>
            {/* <TableRow hover>
              <StyledTableCell align="center">Sombras</StyledTableCell>
              <StyledTableCell align="center">{this.state.guardianResults.shadows}</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.shadow_score).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.weights.shadow).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.adjusted_shadow).toFixed(1)}%
              </StyledTableCell>
            </TableRow> */}
            <TableRow hover>
              <StyledTableCell align="center">Checklists</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.checklists_score).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.guardianResults.final_checklists_score
                ).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.weights.checklist).toFixed(
                  1
                )}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.adjusted_checklist).toFixed(
                  1
                )}
                %
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">Vvia</StyledTableCell>
              <StyledTableCell align="center">
                {this.state.guardianResults.route_speed_score.toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.guardianResults.final_route_speed_score
                ).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.guardianResults.weights.route_speed).toFixed(
                  1
                )}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.guardianResults.adjusted_route_speed
                ).toFixed(1)}
                %
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table size="medium" padding="default">
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              <TableCell align="center">Acidentes Com Afastamento</TableCell>
              <TableCell align="center">Acidentes Sem Afastamento</TableCell>
              <TableCell align="center">Acidentes Com Patrimônio</TableCell>
              <TableCell align="center">Total na Cesta</TableCell>
              <TableCell align="center">RV Máximo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">
                Cesta de Acidentes
              </StyledTableCell>
              <StyledTableCell align="center">
                {this.state.guardianResults.accidents.accidents_with_withdrawal}
              </StyledTableCell>
              <StyledTableCell align="center">
                {
                  this.state.guardianResults.accidents
                    .accidents_without_withdrawal
                }
              </StyledTableCell>
              <StyledTableCell align="center">
                {this.state.guardianResults.accidents.accidents_with_property}
              </StyledTableCell>
              <StyledTableCell align="center">
                {this.state.guardianResults.accidents_mix}
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.guardianResults.rv_max_due_to_accidents
                ).toFixed(2)}
                %
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  renderAngelSummary() {
    const efficiency_weight = this.state.guardianResults.efficiency_column_max;
    const attendance_weight = this.state.guardianResults.attendance_column_max;
    const activation_weight = this.state.guardianResults.activation_column_max;
    const security_weight = this.state.guardianResults.security_column_max;

    return (
      <div>
        <MaterialTable
          columns={[
            { title: "Pilar", field: "name" },
            { title: "Atingimento", field: "score" },
            { title: "Limite Máximo", field: "limit" },
          ]}
          options={{
            showTitle: false,
            search: false,
            paging: false,
            toolbar: false,
          }}
          data={[
            // { name: 'Eficiência', score: (100*this.state.guardianResults.efficiency_column).toFixed(1) + '%', limit: '0.0%'},
            {
              name: "Atendimento",
              score:
                (100 * this.state.guardianResults.attendance_column).toFixed(
                  1
                ) + "%",
              limit: attendance_weight + "%",
            },
            {
              name: "Ativação",
              score:
                (100 * this.state.guardianResults.activation_column).toFixed(
                  1
                ) + "%",
              limit: activation_weight + "%",
            },
            {
              name: "Eficiência",
              score:
                (100 * this.state.guardianResults.efficiency_column).toFixed(
                  1
                ) + "%",
              limit: efficiency_weight + "%",
            },
            {
              name: "Segurança",
              score:
                (100 * this.state.guardianResults.security_column).toFixed(1) +
                "%",
              limit: security_weight + "%",
            },
          ]}
          detailPanel={(rowData) => {
            if (rowData.name === "Eficiência") {
              return this.efficiencyDetails();
            } else if (rowData.name === "Atendimento") {
              return this.attendanceDetails();
            } else if (rowData.name === "Ativação") {
              return this.activationDetails();
            } else if (rowData.name === "Segurança") {
              return this.securityDetails();
            }
          }}
        />
      </div>
    );
  }

  requestOrdersDetails() {
    this.setState({
      serviceOrdersDetails: "loading",
    });

    LogisticService.getAngelOrdersData(
      this.state.year,
      this.state.month,
      this.state.guardianName,
      this.state.hubName
    ).then((response) => {
      this.setState({
        serviceOrdersDetails: response.data.data,
      });
    });
  }

  renderOrdersDetails() {
    if (this.state.serviceOrdersDetails === undefined) {
      if (this.state.selectedTab == 1) {
        this.requestOrdersDetails();
      }
      return;
    } else if (this.state.serviceOrdersDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];

    this.state.serviceOrdersDetails.map((order) =>
      rows.push({
        orderNumber: order.orderNumber,
        technicianName: order.technicianName,
        considerInSlaAccount: order.considerInSlaAccount,
        considerInTmaAccount: order.considerInTmaAccount,
        solvedWithSlaOnTime: order.solvedWithSlaOnTime,
        solvedWithSlaCustomerOnTime: order.solvedWithSlaCustomerOnTime,
        considerInDzeroAccount: order.considerInDzeroAccount,
        solvedInDzero: order.solvedInDzero,
        solvedWithAnticipation: order.solvedWithAnticipation,
        redirectPolo: order.redirectPolo,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "orderNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Angel",
            field: "technicianName",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Conta pro SLA",
            field: "considerInSlaAccount",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Conta pro TMA",
            field: "considerInTmaAccount",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "SLA no Prazo",
            field: "solvedWithSlaOnTime",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "5em" },
          },
          {
            title: "SLA Cliente no Prazo",
            field: "solvedWithSlaCustomerOnTime",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Conta pro D0",
            field: "considerInDzeroAccount",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Resolvida em D0",
            field: "solvedInDzero",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Chamado Antecipado",
            field: "solvedWithAnticipation",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Redirect",
            field: "redirectPolo",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
        ]}
        data={rows}
        title="Chamados Atendidos"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          filtering: true,
        }}
      />
    );
  }

  requestPortalDetails() {
    this.setState({
      portalDetails: "loading",
    });

    LogisticService.getAngelPortalData(
      this.state.year,
      this.state.month,
      this.state.guardianName,
      this.state.hubName
    ).then((response) => {
      this.setState({
        portalDetails: response.data.data,
      });
    });
  }

  renderPortalDetails() {
    if (this.state.portalDetails === undefined) {
      if (this.state.selectedTab == 2) {
        this.requestPortalDetails();
      }
      return;
    } else if (this.state.portalDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];

    this.state.portalDetails.map((customer) =>
      rows.push({
        customer: customer.customer,
        customerName: customer.customerName,
        // provider: customer.provider,
        neighborhood: customer.neighborhood,
        withAccess: customer.withAccess,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Stonecode",
            field: "customer",
            type: "numeric",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Nome do Cliente",
            field: "customerName",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          // { title: 'Polo', field: 'provider', cellStyle: {fontSize: '0.8em', width: '8em'}},
          {
            title: "Bairro",
            field: "neighborhood",
            cellStyle: { fontSize: "0.8em", width: "5em" },
          },
          {
            title: "Acessou o Portal",
            field: "withAccess",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Acessos ao portal"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  requestReversesDetails() {
    this.setState({
      reversesDetails: "loading",
    });

    LogisticService.getAngelReversesData(
      this.state.year,
      this.state.month,
      this.state.hubName
    ).then((response) => {
      this.setState({
        reversesDetails: response.data.data,
      });
    });
  }

  renderReversesDetails() {
    if (this.state.reversesDetails === undefined) {
      if (this.state.selectedTab == 3) {
        this.requestReversesDetails();
      }
      return;
    } else if (this.state.reversesDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];

    this.state.reversesDetails.map((customer) =>
      rows.push({
        provider: customer.provider,
        reason: customer.reason,
        scored: customer.scored,
        weekNumber: customer.weekNumber,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Polo",
            field: "provider",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Semana",
            field: "weekNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Pontuou",
            field: "scored",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Motivo",
            field: "reason",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Reversas"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  requestScreenedTerminalsDetails() {
    this.setState({
      screenedTerminalsDetails: "loading",
    });

    LogisticService.getAngelScreeningData(
      this.state.year,
      this.state.month,
      this.state.hubName
    ).then((response) => {
      this.setState({
        screenedTerminalsDetails: response.data.data,
      });
    });
  }

  renderScreenedTerminalsDetails() {
    if (this.state.screenedTerminalsDetails === undefined) {
      if (this.state.selectedTab == 4) {
        this.requestScreenedTerminalsDetails();
      }
      return;
    } else if (this.state.screenedTerminalsDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    var date;
    this.state.screenedTerminalsDetails.map(
      (terminal) => (
        (date = terminal.screeningDate.slice(0, 10).split("-")),
        rows.push({
          serial: terminal.serial,
          statusBefore: terminal.statusBefore,
          statusAfter: terminal.statusAfter,
          screeningDate: date[2] + "/" + date[1] + "/" + date[0],
          weekNumber: terminal.weekNumber,
        })
      )
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Serial",
            field: "serial",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Status Antes",
            field: "statusBefore",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Status Depois",
            field: "statusAfter",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Data da Triagem",
            field: "screeningDate",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Semana",
            field: "weekNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Triagens Good"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  requestRemovedTerminalsDetails() {
    this.setState({
      removedTerminalsDetails: "loading",
    });

    LogisticService.getAngelRemovedTerminalsData(
      this.state.year,
      this.state.month,
      this.state.hubName
    ).then((response) => {
      this.setState({
        removedTerminalsDetails: response.data.data,
      });
    });
  }

  renderRemovedTerminalsDetails() {
    if (this.state.removedTerminalsDetails === undefined) {
      if (this.state.selectedTab == 5) {
        this.requestRemovedTerminalsDetails();
      }
      return;
    } else if (this.state.removedTerminalsDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.removedTerminalsDetails.map((terminal) =>
      rows.push({
        serialUninstalled: terminal.serialUninstalled,
        statusBefore: terminal.statusBefore,
        serviceOrderNumber: terminal.serviceOrderNumber,
        removalDate: terminal.removalDate,
        weekNumber: terminal.weekNumber,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "serviceOrderNumber",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Serial",
            field: "serialUninstalled",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Data da Retirada",
            field: "removalDate",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Semana",
            field: "weekNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Terminais retirados"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  requestCSATDetails() {
    this.setState({
      csatDetails: "loading",
    });

    LogisticService.getAngelCSATData(
      this.state.year,
      this.state.month,
      this.state.guardianName
    ).then((response) => {
      this.setState({
        csatDetails: response.data.data,
      });
    });
  }

  renderCSATDetails() {
    if (this.state.csatDetails === undefined) {
      if (this.state.selectedTab == 2) {
        this.requestCSATDetails();
      }
      return;
    } else if (this.state.csatDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.csatDetails.map((os) =>
      rows.push({
        angel: os.angel,
        hub: os.hub,
        district: os.district,
        region: os.region,
        satisfaction: os.satisfaction,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Angel",
            field: "angel",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Polo",
            field: "hub",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Regional",
            field: "region",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Satisfação",
            field: "satisfaction",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="CSAT"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  requestXrayDetails() {
    this.setState({
      xrayDetails: "loading",
    });

    LogisticService.getAngelXrayData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        xrayDetails: response.data.data,
      });
    });
  }

  renderXrayDetails() {
    if (this.state.xrayDetails === undefined) {
      if (this.state.selectedTab == 3) {
        this.requestXrayDetails();
      }
      return;
    } else if (this.state.xrayDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.xrayDetails.map((os) => {
      var pendencies = os.pendencies ? "Sim" : "Não";
      var abono = os.abono ? "Sim" : "Não";

      var openingDate =
        os.openingDate.split("-")[2] +
        "/" +
        os.openingDate.split("-")[1] +
        "/" +
        os.openingDate.split("-")[0];
      var firstAccess;

      firstAccess =
        os.openingDate == null
          ? "-"
          : os.openingDate.split("-")[2] +
            "/" +
            os.openingDate.split("-")[1] +
            "/" +
            os.openingDate.split("-")[0];

      rows.push({
        xrayOrderNumber: os.xrayOrderNumber,
        customer: os.customer,
        openingDate: openingDate,
        firstAccess: firstAccess,
        pendencies: pendencies,
        abono: abono,
        angel: os.angel,
        hub: os.hub,
        district: os.district,
      });
    });

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "xrayOrderNumber",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Stonecode",
            field: "customer",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Data de Instalação",
            field: "openingDate",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Primeiro Acesso",
            field: "firstAccess",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Pendencias",
            field: "pendencies",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Abono",
            field: "abono",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Green Angel",
            field: "angel",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Polo",
            field: "hub",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="RaioX"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  requestCollactDetails() {
    this.setState({
      collactDetails: "loading",
    });
    LogisticService.getAngelCollactData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        collactDetails: response.data.data,
      });
    });
  }

  renderCollactDetails() {
    if (this.state.collactDetails === undefined) {
      if (this.state.selectedTab == 4) {
        this.requestCollactDetails();
      }
      return;
    } else if (this.state.collactDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.collactDetails.map((os) => {
      var openingDate =
        os.openingDate.split("-")[2] +
        "/" +
        os.openingDate.split("-")[1] +
        "/" +
        os.openingDate.split("-")[0];
      var firstAccess;

      firstAccess =
        os.activationDate == "0001-01-01"
          ? "-"
          : os.activationDate.split("-")[2] +
            "/" +
            os.activationDate.split("-")[1] +
            "/" +
            os.activationDate.split("-")[0];

      rows.push({
        collactOrderNumber: os.collactOrderNumber,
        customer: os.customer,
        openingDate: openingDate,
        activationDate: firstAccess,
        angel: os.angel,
        hub: os.hub,
        district: os.district,
        region: os.region,
      });
    });

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "collactOrderNumber",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Stonecode",
            field: "customer",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Data de Abertura",
            field: "openingDate",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Data de Ativação",
            field: "activationDate",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Angel",
            field: "angel",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Polo",
            field: "hub",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="CSAT"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  requestPenaltiesDetails() {
    this.setState({
      penaltiesDetails: "loading",
    });
    LogisticService.getDistrictPenaltiesData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        penaltiesDetails: response.data.data,
      });
    });
    LogisticService.getDistrictPenaltiesData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        penaltiesDetails: response.data.data,
      });
    });
  }

  renderPenaltiesDetails() {
    if (this.state.penaltiesDetails === undefined) {
      if (this.state.selectedTab == 5) {
        this.requestPenaltiesDetails();
      }
      return;
    } else if (this.state.penaltiesDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.penaltiesDetails.map((os) => {
      var date =
        os.arrivalDate.split("-")[2] +
        "/" +
        os.arrivalDate.split("-")[1] +
        "/" +
        os.arrivalDate.split("-")[0];
      rows.push({
        driver: os.driver,
        district: os.districtName,
        arrivalDate: date,
        severity: os.severity,
      });
    });

    return (
      <MaterialTable
        columns={[
          {
            title: "Motorista",
            field: "driver",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Data de Recimento",
            field: "arrivalDate",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Gravidade",
            field: "severity",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="CSAT"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  requestAccidentsDetails() {
    this.setState({
      accidentsDetails: "loading",
    });
    LogisticService.getDistrictAccidentsData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        accidentsDetails: response.data.data,
      });
    });
    LogisticService.getDistrictAccidentsData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        accidentsDetails: response.data.data,
      });
    });
  }

  renderAccidentsDetails() {
    if (this.state.accidentsDetails === undefined) {
      if (this.state.selectedTab == 6) {
        this.requestAccidentsDetails();
      }
      return;
    } else if (this.state.accidentsDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.accidentsDetails.map((os) => {
      var date =
        os.date.split("-")[2] +
        "/" +
        os.date.split("-")[1] +
        "/" +
        os.date.split("-")[0];

      rows.push({
        type: os.type,
        date: date,
        angel: os.angel,
        district: os.district,
      });
    });

    return (
      <MaterialTable
        columns={[
          {
            title: "Tipo",
            field: "type",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Data",
            field: "date",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Angel",
            field: "angel",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="CSAT"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  tabsPannel() {
    const handleChange = (event, newValue) => {
      this.setState({
        selectedTab: newValue,
      });
    };

    if (this.state.guardianResults === undefined) {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    return (
      <div style={{ marginTop: "2em" }}>
        <AppBar position="static" style={{ backgroundColor: "#103A21" }}>
          <Tabs
            value={this.state.selectedTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Resumo" />
            <Tab label="Chamados Atendidos" />
            <Tab label="CSAT" />
            <Tab label="Raio-X" />
            <Tab label="Collact" />
            <Tab label="Multas" />
            <Tab label="Acidentes" />
            {/* <DownloadButton/> */}
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.selectedTab} index={0}>
          {this.renderAngelSummary()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          {this.renderOrdersDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={2}>
          {this.renderCSATDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={3}>
          {this.renderXrayDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={4}>
          {this.renderCollactDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={5}>
          {this.renderPenaltiesDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={6}>
          {this.renderAccidentsDetails()}
        </TabPanel>
      </div>
    );
  }

  updatedData(value) {
    this.setState({
      period: value,
      year: value.split("-")[1],
      month: value.split("-")[0],
      guardianResults: undefined,
    });
    this.refreshReportData(
      value.split("-")[0],
      value.split("-")[1],
      this.state.guardianName
    );
    this.requestGuardiansData(
      value.split("-")[0],
      value.split("-")[1],
      this.state.guardianName
    );
  }

  updatedGuardianData(value) {
    if (value !== "Selecione um nome") {
      this.setState({
        guardianName: value,
      });
      this.refreshReportData(this.state.month, this.state.year, value);
      this.requestGuardiansData(this.state.month, this.state.year, value);
    }
  }

  headPanel() {
    if (this.state.guardianResults === undefined) {
      return;
    }
    return (
      <div>
        <div class="row">
          <label
            style={{
              float: "left",
              fontSize: "1.5em",
              fontWeight: "bold",
              marginLeft: "1em",
              marginBottom: "0.5em",
              marginTop: "0.5em",
            }}
          >
            Remuneração Variável
          </label>
        </div>
        <div class="row">
          <label
            style={{
              float: "left",
              fontSize: "1.5em",
              marginLeft: "1em",
              marginBottom: "0.5em",
              marginTop: "0.5em",
            }}
          >
            <Button
              color="default"
              variant="contained"
              style={{ marginRight: "1em", verticalAlign: "middle" }}
              onClick={(e) => this.setState({ redirect: true })}
            >
              Voltar
            </Button>
          </label>
        </div>
        <div class="col align-self-start" style={{ width: "100%" }}>
          <FormControl variant="outlined" margin="3em">
            <Select
              value={this.state.guardianName}
              onChange={(e, value) =>
                this.updatedGuardianData(value.props.value)
              }
              labelWidth={320}
              style={{ width: 320, marginTop: "1em" }}
            >
              <MenuItem value="Selecione um nome">Guardian</MenuItem>
              {this.state.avaiableNames
                ? this.state.avaiableNames.map((row) => {
                    return (
                      <MenuItem value={row.guardianName}>
                        {row.guardianName}
                      </MenuItem>
                    );
                  })
                : ""}
            </Select>
          </FormControl>
          <FormControl style={{ marginLeft: "21px" }}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.period}
              onChange={(e, value) => this.updatedData(value.props.value)}
              labelWidth={"130%"}
              style={{ width: "130%", marginTop: "1em" }}
            >
              <MenuItem value="empty">Selecione um mês</MenuItem>
              {this.state.mesesDisponiveis
                .sort(
                  (a, b) =>
                    a.referenceYear - b.referenceYear ||
                    a.referenceMonth - b.referenceMonth
                )
                .map((row) => {
                  const formattedMonth =
                    row.referenceMonth < 10
                      ? "0".concat(row.referenceMonth)
                      : row.referenceMonth;
                  return (
                    <MenuItem
                      value={row.referenceMonth + "-" + row.referenceYear}
                    >
                      {formattedMonth}/{row.referenceYear}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>

        <div class="row">
          <div class="col align-self-end">
            <label
              style={{
                float: "right",
                fontSize: "15px",
                marginLeft: "1em",
                marginBottom: "0.5em",
                marginTop: "1em",
                fontStyle: "italic",
              }}
            >
              {this.state.lastUpdate === ""
                ? ""
                : `Atualizado em ${this.state.lastUpdate}`}
            </label>
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
      </div>
    );
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/variable-remuneration",
          }}
        />
      );
    }

    return (
      <Container fluid>
        {this.headPanel()}
        {this.SummaryBoxes()}
        {this.tabsPannel()}
      </Container>
    );
  }
}

VariableRemuneration.propTypes = {
  name: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
};

VariableRemuneration.defaultProps = {
  name: "JANDERSON THIAGO CIRINO DE ALMEIDA",
  year: "2019",
  month: "10",
};

export default VariableRemuneration;
