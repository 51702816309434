/* eslint-disable */
import axios from "axios";
import { authenticatedAxios } from "../../http";

import appConfig from "./appConfig";
export default class LogisticService {
  static requestWithAuth(url) {
    return authenticatedAxios().get(url);
  }


  static putWithAuth(url) {
    return authenticatedAxios().put(url);
  }

  static requestWithAuthAndBody(url, body) {
    return authenticatedAxios().post(url, body);
  }

  static deleteWithAuth(url) {
    return authenticatedAxios().delete(url);
  }

  static requestWithAuthAndBody(url, data) {
    return authenticatedAxios().post(url, data);
  }

  static postWithAuth(url) {
    return authenticatedAxios().post(url);
  }

  static patchWithAuth(url) {
    return authenticatedAxios().patch(url);
  }

  static patchWithAuthAndBody(url, data) {
    return authenticatedAxios().patch(url, data);
  }

  static logIn(email, password) {
    return axios.post(`${appConfig.logisticsUrl}/authenticate`, { email, password });
  }

  static getLastMileSummary(contractor, operators) {
    const params = [];

    if (contractor) {
      params.push(`contractor=${contractor}`);
    }

    if (operators) {
      operators.forEach((operator) => params.push(`operators=${operator}`));
    }

    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/reports/last-mile/summary?${params.join("&")}`);
  }

  static getStatesWithMetrics(metrics) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/states?metrics=${metrics}`);
  }

  static getProvidersWithMetrics(stateId, metrics) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/providers?state_id=${stateId}&metrics=${metrics}`
    );
  }

  static getRegionsWithMetrics(metrics, services) {
    let servicesQuery = services.map((service) => `&additional_services=${service}`).join("");
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/regions?metrics=${metrics}${servicesQuery}`);
  }

  static getDistrictsWithMetrics(regionId, metrics, services) {
    let servicesQuery = services.map((service) => `&additional_services=${service}`).join("");
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/districts?region_id=${regionId}&metrics=${metrics}${servicesQuery}`
    );
  }

  static getHubsWithMetrics(districtId, metrics, services) {
    let servicesQuery = services.map((service) => `&additional_services=${service}`).join("");
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/hubs?district_id=${districtId}&metrics=${metrics}${servicesQuery}`
    );
  }

  static getDelayAging() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/aging`);
  }

  static getcallOpeningClosing() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/history`);
  }

  static getMtdSlaMap() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/mtdsla/map`);
  }

  static getMtdRedirectsMap() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/mtd-redirects/map`);
  }

  static getMtdRegionsSla() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/mtdsla/regions`);
  }

  static getSelfLogisticsMap() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/selflogistics/map`);
  }

  static getMtdSlaGrouped(date) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/mtd/sla/grouped/${date}`);
  }

  static getMtdSlaStoneMEI(date) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/mtd/sla/stone-mei/${date}`);
  }

  static getHistoryOfLateOrders() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/late?start_date=2017-01-01`);
  }

  static getMachineConsumption() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/machine/consumption`);
  }

  static getMachineConsumptionByHub(hubName) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/machine/consumption_by_day/${hubName}`);
  }

  static getAngelsByHub(hubName, today) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/angels/${hubName}/${today}`);
  }

  static getCapexSKUCount(positions) {
    const query = positions.map((a) => `position=${a}`).join("&");
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/capex/sku_count?${query}`);
  }

  static getCapexSummary() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/capex/summary`);
  }

  static getCapexHistory() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/capex/history`);
  }

  static getHuntHistory() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/capex/history`);
  }

  static getStockHistory() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/capex/history`);
  }

  static getLabHistory() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/capex/history`);
  }

  static getTriageHistory() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/capex/history`);
  }

  static getStockSituation(hub) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/capex/coverage?hub=${hub}`);
  }

  static getCoverageHistory() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/capex/coverage-history`);
  }

  static getTriageSituation(region, district, hub) {
    const params = [];

    if (region) {
      params.push(`?region=${region}`);
    }

    if (district) {
      params.push(`?district=${district}`);
    }

    if (hub) {
      params.push(`?provider=${hub}`);
    }

    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/capex/triage${params.join("&")}`);
  }

  static getIncomingOrders(hub) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/movimentation/incoming?destination=${hub}`);
  }

  static getTopAgingOMs() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/asset-management/top-aging-oms`);
  }

  static getPendingOrdersThirdParty(date, name) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/providers/pending?name=${name}&date=${date}`);
  }

  static getOsWallboard(group, contractor, operator, today, country_state) {
    const params = [`?groupby=${group}`, `operator=${operator}`, `contractor=${contractor}`, `today=${today}`];

    if (country_state) {
      params.push(`&country_state=${country_state}`);
    }

    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/os-wallboard${params.join("&")}`);
  }

  static getGeneralInformation(stonecode) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/customer-info/general-information?stonecode=${stonecode}`
    );
  }

  static postLeatime(data) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/save-leadtimes`, data);
  }

  static deleteLeadtime(data) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/delete-leadtime`, data);
  }

  static getPortalUsers(stonecode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/merchants/${stonecode}/portal-users`);
  }

  static getPortalUserLastAcess(email) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/merchants/portal-user/${email}/last-access`
    );
  }

  static getContacts(stonecode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/merchants/${stonecode}/contacts`);
  }

  static getStatus(stonecode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/merchants/${stonecode}/status`);
  }

  static getCaptureMethods(stonecode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/merchants/${stonecode}/capture-methods`);
  }


  static getLastTransaction(stonecode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/merchants/${stonecode}/last-transaction`);
  }

  static getRAVSearch(stonecode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/pre-payment?stonecode=${stonecode}`);
  }

  static getAllAngels(rv, month, year) {
    if (rv != undefined && month != undefined && year != undefined) {
      return LogisticService.requestWithAuth(
        `${appConfig.logisticsUrl}/all-angels?rv=${rv}&month=${month}&year=${year}`
      );
    }

    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/all-angels`);
  }

  static getAllDistricts() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/all-districts`);
  }

  static postNewPenalty(angel, hub, district, occurrenceDate, notificationDate, severity, guardian) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/post-new-penalty?driver=${angel}&hub_name=${hub}&district_name=${district}&occurrency_date=${occurrenceDate}&arrival_date=${notificationDate}&severity=${severity}&guardian=${guardian}`
    );
  }

  static getAllPenalties() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-all-penalties`);
  }

  static postNewAccident(angel, hub, district, region, occurrenceDate) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/post-new-accident?driver=${angel}&hub_name=${hub}&district_name=${district}&region_name=${region}&occurrency_date=${occurrenceDate}`
    );
  }

  static getSLACompleteHistory() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/sla/history`);
  }

  static getVariableRemuneration(angel, month, year) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/variable-remuneration?angel=${angel}&month=${month}&year=${year}`
    );
  }

  static getVariableRemunerationDistricts(district, month, year) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/variable-remuneration-districts?district=${district}&month=${month}&year=${year}`
    );
  }

  static getVariableRemunerationRegions(region, month, year) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/variable-remuneration-regions?region=${region}&month=${month}&year=${year}`
    );
  }

  static getAngelServiceOrdersAnalysis(angel, year, month, hub) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv-angel-service-orders?angel=${angel}&provider=${hub}&year=${year}&month=${month}`
    );
  }

  static getLateServiceOrdersAnalysis() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/late-service-orders`);
  }

  static getDistrictServiceOrdersAnalysis(district, year, month) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv-district-service-orders?district=${district}&year=${year}&month=${month}`
    );
  }

  static getRegionServiceOrdersAnalysis(region, year, month) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv-region-service-orders?region=${region}&year=${year}&month=${month}`
    );
  }

  static getAngelScreeningAnalysis(year, month, hub) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv-angel-screening?month=${month}&year=${year}&provider=${hub}`
    );
  }

  static getDistrictScreeningAnalysis(district) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/rv-district-screening?district=${district}`);
  }

  static getRegionScreeningAnalysis(region) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/rv-region-screening?region=${region}`);
  }

  static getAngelPortalAnalysis(angel, month, year) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv-angel-portal-orders?angel=${angel}&month=${month}&year=${year}`
    );
  }

  static getInventoryCampaignStatus(group, filter) {
    if (group == undefined && filter == undefined) {
      return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/asset-management/inventory`);
    } else if (group == undefined) {
      return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/asset-management/inventory?group_by=${group}`);
    } else {
      return LogisticService.requestWithAuth(
        `${appConfig.logisticsUrl}/asset-management/inventory?group_by=${group}&filter=${filter}`
      );
    }
  }

  static getInventoryCampaignTerminalsRelation(provider) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/asset-management/inventory?terminals=True&provider=${provider}`
    );
  }

  static getAddressForSerial(serial) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/find-terminal-address?serial=${serial}`);
  }

  static getCoordinates(address) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/find-terminal-coordinates?address=${address}`);
  }

  static getXrayServiceOrdersSummary() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-orders-summary`);
  }

  static getXrayCancellationsReasonsMTD() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-cancellations-reasons`);
  }

  static getXrayInefficiencyReasons() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-inefficiency-reasons`);
  }

  static getXrayServiceOrdersArrivaldate() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-orders-arrivaldate`);
  }

  static getXrayNewCustomers() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-newcustomers`);
  }

  static getXrayStatusOS() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-status-installations-os`);
  }

  static getXrayPendenciesReasons() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-pendencies-reasons`);
  }

  static getEqualsPanoram() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-equals-panoram`);
  }

  static getXrayActiveUsers() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-active-users`);
  }

  static getXrayAccessHistory() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-portal-history`);
  }

  static getXrayPortalAccessMap() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-portal-map`);
  }

  static getXrayPortalAccessMapRelative() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-portal-map-relative`);
  }

  static getXrayFirstAccess() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-portal-first-access`);
  }

  static getXrayRegionsTable() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-regions-table`);
  }

  static getXrayDistricts(regionId) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-districts?region_id=${regionId}`);
  }

  static getXrayHubs() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xray-hubs`);
  }

  static getBatteriesSummary() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/batteries-summary`);
  }

  static getBatteriesHistory() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/batteries-history`);
  }

  static getCriticalBatteriesHistory() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/batteries-critical-terminals-history`);
  }

  static getBatteriesConversionChannels() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/batteries-conversion-types`);
  }

  static getProgressGoals() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/idt-progress-goals`);
  }

  static getRVRanking(type, month, year, district_filter) {
    const params = [`?type=${type}`, `year=${year}`, `month=${month}`];

    if (district_filter) {
      params.push(`&district=${district_filter}`);
    }

    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/rv-ranking${params.join("&")}`);
  }
  //IDT
  static getIdtDefect(model, start_date, defect, group_by) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/idt/defects?model=${model}&start_date=${start_date}&defect=${defect}&group_by=${group_by}`
    );
  }

  static getIdtSparePart(start_date, model, group_by) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/idt/spare-parts?start_date=${start_date}&model=${model}&group_by=${group_by}`
    );
  }

  static getIDTSummary(model, group_by) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/idt/summary?model=${model}&group_by=${group_by}`);
  }

  static getIDTOnBoarding(start_date, group_by) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/idt/on-boarding?start_date=${start_date}&group_by=${group_by}`
    );
  }
  //
  static getRealizedOSHistory() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/realized-os-history`);
  }

  static getScoreCsatPerOPL() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/score-csat-per-opl`);
  }

  static getCsatAnswerPercent() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/csat-answer-percent`);
  }

  static getChallengeDistrictRanking() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/challeng-district-ranking`);
  }

  static getAngelsRealizedOSs() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/angels-realized-os`);
  }

  static getAngelsAccidents() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/accidents-angels`);
  }

  static getProductionOMs() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/movimentation/product`);
  }

  static getOSHistoryType() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/os-history-type`);
  }

  static getStockOut() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/stock-out`);
  }

  static getCSATOnePage() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/csat-answer-percent-onepage`);
  }

  static getTerminalLosts() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/terminal-losts`);
  }

  static getAngelsRealizedOSs() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/angels-realized-os`);
  }

  static getAngelsAccidents() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/accidents-angels`);
  }

  static getProductionOMs() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/movimentation/product`);
  }

  static getOSHistoryType() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/os-history-type`);
  }

  static getStockOut() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/stock-out`);
  }

  static getCSATOnePage() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/csat-answer-percent-onepage`);
  }

  static getTerminalLosts() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/terminal-losts`);
  }

  static getMovimentationIncomingCD(parameter) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/movimentation/incoming/cd?filter=${parameter}`);
  }

  static getMovimentationIncomingBase(parameter) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/movimentation/incoming/base?filter=${parameter}`);
  }

  static getMovimentationIncomingPending(parameter) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/movimentation/incoming/pending?filter=${parameter}`
    );
  }

  static getXrayCustomersAnalytics(parameter) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/new-products-xray-analytics?filter=${parameter}`);
  }

  static getCollactCustomersAnalytics() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/new-products-collact-analytics`);
  }

  static getInefficiencyServiceOrders() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/inefficiency-orders`);
  }

  static getIncorrectCloseOrders() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/incorrect-close-orders`);
  }

  static getLogisticMembersHistory() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/logistic-members`);
  }

  static getUsefulDays() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/useful-days`);
  }

  static getHistoryIDM() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/history-idm`);
  }

  static getIDTDefectGraph() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/idt-defect-graph`);
  }

  static getIDTWeekDefectGraph() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/idt-week-defect-graph`);
  }

  static getIDTBatteryGroup() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/idt-battery-group`);
  }

  static getIDTBatteryGroupWeek() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/idt-battery-group-week`);
  }

  static getIDTGroupByBattery() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/idt-group-by-battery`);
  }

  static getIDTGroupByBatteryWeek() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/idt-group-by-battery-week`);
  }

  static getIDTBacktrackMonth() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/idt-backtrack-month`);
  }

  static getAngelResults(year, month, name) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-angels-results?year=${year}&month=${month}&name=${name}`
    );
  }

  static ResultadosHostAngels(year, month, name) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/resultados/host?year=${year}&month=${month}&name=${name}`
    );
  }

  static getDistrictResults(year, month, name) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-districts-results?year=${year}&month=${month}&name=${name}`
    );
  }

  static getRegionResults(year, month, name) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-regions-results?year=${year}&month=${month}&name=${name}`
    );
  }

  static getGuardianResults(year, month, name) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-guardians-results?year=${year}&month=${month}&name=${name}`
    );
  }

  static getSupplierResults(year, month, name) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-suppliers-results?year=${year}&month=${month}&name=${name}`
    );
  }

  static getAngelPortalData(year, month, name) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-angel-portal-data?year=${year}&month=${month}&name=${name}`
    );
  }

  static getDistrictStockPhotoData(year, month, district) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-district-stock-photo-data?year=${year}&month=${month}&district=${district}`
    );
  }

  static getDistrictScreeningData(year, month, district) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-district-screening-data?year=${year}&month=${month}&district=${district}`
    );
  }

  static getDistrictSwapsData(year, month, district) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-district-swaps-data?year=${year}&month=${month}&district=${district}`
    );
  }

  static getAngelOrdersData(year, month, name, hub) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-angel-orders-data?year=${year}&month=${month}&name=${name}&hub=${hub}`
    );
  }

  static getDistrictOrdersData(year, month, district) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-district-orders-data?year=${year}&month=${month}&district=${district}`
    );
  }

  static getAngelReversesData(year, month, hub) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-angel-reverses-data?year=${year}&month=${month}&hub=${hub}`
    );
  }

  static getDistrictRemovedTerminalsData(year, month, district) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-district-removed-terminals-data?year=${year}&month=${month}&district=${district}`
    );
  }

  static getDistrictReversesPenaltiesData(year, month, district) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-district-reverses-penalties-data?year=${year}&month=${month}&district=${district}`
    );
  }

  static getAngelCSATData(year, month, angel) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-angel-csat-data?year=${year}&month=${month}&angel=${angel}`
    );
  }

  static getDistrictCSATData(year, month, district) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-district-csat-data?year=${year}&month=${month}&district=${district}`
    );
  }

  static postMultipleExpeditions(expedition) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/save_expedition_list`, expedition);
  }

  static getDistrictUninstalledData(year, month, district) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-district-uninstalled-data?year=${year}&month=${month}&district=${district}`
    );
  }

  static getDistrictAccidentsData(year, month, district) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-district-accidents-data?year=${year}&month=${month}&district=${district}`
    );
  }

  static getDistrictPenaltiesData(year, month, district) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-district-penalties-data?year=${year}&month=${month}&district=${district}`
    );
  }

  static getAngelXrayData(year, month, district) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-angel-xray-data?year=${year}&month=${month}&district=${district}`
    );
  }

  static getAngelCollactData(year, month, district) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-angel-collact-data?year=${year}&month=${month}&district=${district}`
    );
  }

  static listarGreenAngelsRV(year, month) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-avaiable-angels?year=${year}&month=${month}`);
  }

  static listarHostAngelsRV(year, month) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/rv/angels?year=${year}&month=${month}&level=host`);
  }

  static listarDistritosRV(year, month) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-avaiable-districts?year=${year}&month=${month}`
    );
  }

  static listarRegionaisRV(year, month) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-avaiable-regions?year=${year}&month=${month}`
    );
  }

  static listarGuardiansRV(year, month) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-avaiable-guardians?year=${year}&month=${month}`
    );
  }

  static listarSuppliersRV(year, month) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-avaiable-suppliers?year=${year}&month=${month}`
    );
  }

  static periodosDiponiveisRV(tipo_angel) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-avaiable-months/${tipo_angel}`);
  }

  static getRegionOrdersData(year, month, region) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-region-orders-data?year=${year}&month=${month}&region=${region}`
    );
  }

  static getRegionCSATData(year, month, region) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-region-csat-data?year=${year}&month=${month}&region=${region}`
    );
  }

  static getRegionStockPhotoData(year, month, region) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-region-stock-photo-data?year=${year}&month=${month}&region=${region}`
    );
  }

  static getRegionUninstalledData(year, month, region) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-region-uninstalled-data?year=${year}&month=${month}&region=${region}`
    );
  }

  static getRegionXrayData(year, month, region) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-region-xray-data?year=${year}&month=${month}&region=${region}`
    );
  }

  static getRegionCollactData(year, month, region) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-region-collact-data?year=${year}&month=${month}&region=${region}`
    );
  }

  static getRvRanking(ano, mes, cadeira) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-rv-ranking?year=${ano}&month=${mes}&type=${cadeira}`);
  }

  static getAngelsRanking(year, month) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-angels-ranking?year=${year}&month=${month}`);
  }

  static getGuardiansRanking(year, month) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-guardians-ranking?year=${year}&month=${month}`
    );
  }

  static getDistrictsRanking(year, month) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-districts-ranking?year=${year}&month=${month}`
    );
  }

  static getSuppliersRanking(year, month) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-suppliers-ranking?year=${year}&month=${month}`
    );
  }

  static HostAngelsRanking(year, month) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/ranking/host?year=${year}&month=${month}`
    );
  }

  static getRegionsRanking(year, month) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-regions-ranking?year=${year}&month=${month}`);
  }

  static getXavierCustomerInfo(stonecode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/xavier-customer-info?stonecode=${stonecode}`);
  }

  static getResumeTheFlash(district) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/resume-the-flash?filter=${district}`);
  }

  static getOSListTheFlash(district) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/os-list-the-flash?filter=${district}`);
  }

  static getAngelsListTheFlash(district) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/angels-list-the-flash?filter=${district}`);
  }

  static getCancellationTree(district) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/cancellation/get-tree`);
  }

  static postCancellationCase(firstInfo, secondInfo, thirdInfo, stonecode) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/cancellation/input?diag=${firstInfo}&espec=${secondInfo}&detail=${thirdInfo}&stonecode=${stonecode}`
    );
  }

  static getLeadtimes(data) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/get-leadtimes`, data);
  }

  static postLeatimeFromExcel(data) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/save-leadtimes-excel`, data);
  }

  static getCapexSummaryRefactor() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/capex/summary/refactor`);
  }

  static getCapexConsumptionRefactor() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/capex/consumptions/refactor`);
  }

  static mtdRavActivate() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/mtd-rav`);
  }

  static postMembersFromExcel(data) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/save-members-excel`, data);
  }

  static getLabCycleOMsClosed() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/lab-cycle/oms-closed`);
  }

  static getLabCycleGPRSBadCD() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/lab-cycle/gprs-bad-cd`);
  }

  static getLabCycleMTDPax() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/lab-cycle/mtd-pax`);
  }

  static getLabCycleReceivedPOS() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/lab-cycle/received-pos`);
  }

  static getLabCycleSentPOS() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/lab-cycle/sent-pos`);
  }

  static getLabCycleLabExtPOS() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/lab-cycle/lab-ext-pos`);
  }

  static getScreeningCycleResumeDay() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/screening-cycle/resume-day`);
  }

  static getScreeningCycleCDDivision() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/screening-cycle/cd-division`);
  }

  static getScreeningCycleDayDivision() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/screening-cycle/division-day`);
  }

  static getScreeningCycleResumeMonth() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/screening-cycle/resume-month`);
  }

  static mtdRavActivate() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/mtd-rav`);
  }

  static getCasesTree() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/cases/get-tree`);
  }

  static getCasesTreeMembers() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/cases/tree-members`);
  }

  static postNewCase(number, status, qty, wpp, observation, email, theme, category, topic, channel, diag, diagTopic) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/cases/input?number=${number}&status=${status}&qty=${qty}&wpp=${wpp}&observation=${observation}&email=${email}&theme=${theme}&category=${category}&topic=${topic}&channel=${channel}&diag=${diag}&diagTopic=${diagTopic}`
    );
  }

  static getScreeningCycleDivision() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/screening-cycle/division`);
  }

  static getScreeningGoodBad() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/screening-cycle/good-bad`);
  }

  static getCustomerInfoXray(stonecode) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/customer-info/xray-search?stonecode=${stonecode}`
    );
  }

  static getEasterEggsOS(stonecode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/mission-easter`);
  }

  static getMapLinkCoords(stonecode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/coords-maplink`);
  }

  static postEasterStatus(number, status, technician, provider, comments) {
    return LogisticService.patchWithAuth(
      `${appConfig.logisticsUrl}/service-orders/mission-easter?order_id=${number}&status=${status}&technician=${technician}&provider=${provider}&comments=${comments}`
    );
  }

  static getPricingOMs() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/movimentation/pricing-orders`);
  }

  static getPricingOMsGroup() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/movimentation/pricing-orders-group`);
  }

  static postRefreshWkf(data) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/movimentation/refresh-wkf`, data);
  }

  static getMembers(data) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/get-members`, data);
  }

  static getSecurityRegions() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-regions`);
  }
  static getSecurityDistricts(region) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-districts?region=${region}`);
  }
  static getSecurityAvailableMonths() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-available-months`);
  }
  //Accidents
  static getSecurityAccidents() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-accidents`);
  }
  //Penalties
  static getSecurityPenalties() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-penalties`);
  }
  //Leave Index
  static getSecurityLeaveIndex() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-leave-index`);
  }
  //Speed Limits
  static getSecuritySpeedLimits() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-speed-limits`);
  }
  //Accidents History
  static getSecurityAccidentsHistory() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-accidents-history`
    );
  }
  //Penalties History
  static getSecurityPenaltiesHistory() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/get-penalties-history`
    );
  }
  //Leave Index History
  static getSecurityLeaveIndexHistory() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/leave-index-history`
    );
  }
  //Speed Limits History
  static getSecuritySpeedLimitsHistory(dateBegin) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/speed-limits-history?dateBegin=${dateBegin}`
    );
  }
  //Top 20 Brasil
  static getSecurityTopSpeed() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/top-speed`);
  }
  //Indicators Regions
  static getSecurityIndicatorsRegions() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/indicators-regions`);
  }
  //Indicators Regions Graph
  static getSecurityIndicatorsRegionsGraph() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/indicators-regions-graph`);
  }
  //Indicators Districts
  static getSecurityIndicatorsDistricts(region, district, period) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/indicators-districts?region=${region}&district=${district}&period=${period}`
    );
  }
  //Indicators Districts Graph
  static getSecurityIndicatorsDistrictsGraph(region, district, period) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/indicators-districts-graph?region=${region}&district=${district}&period=${period}`
    );
  }
  //Indicators Districts per Angels
  static getSecurityIndicatorsAngelDistricts(region, district, period) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/indicators-districts-angels?region=${region}&district=${district}&period=${period}`
    );
  }
  //Indicators Districts Accidents
  static getSecurityIndicatorsDistrictsAccidents(region, district, period) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/indicators-districts-accidents?region=${region}&district=${district}&period=${period}`
    );
  }
  //Indicators Districts Penalties
  static getSecurityIndicatorsDistrictsPenalties(region, district, period) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/indicators-districts-penalties?region=${region}&district=${district}&period=${period}`
    );
  }
  //Indicators Districts Analitico
  static getSecurityIndicatorsDistrictsAnalytics(region, district, period) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/indicators-districts-analytics?region=${region}&district=${district}&period=${period}`
    );
  }

  //Indicators Districts Analitico
  static getMtdTmaGrouped() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/service-orders/tma-mtd`);
  }

  static getExpeditionList(body) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/expedition_list`, body);
  }

  static postExpedition(expedition) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/expedition`, expedition);
  }

  static postMultipleExpeditionsExcel(expedition) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/expedition_list_excel`, expedition);
  }

  static postMultipleOmIdExcel(expedition) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/om_id_excel`, expedition);
  }

  static getAllOtifNumbers() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/calculate_otif`);
  }

  //Liga
  //Ineficiencia
  static getLigaIneficiencia(group_by, mode, date_start) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/liga/inefficiency?group_by=${group_by}&mode=${mode}&date_start=${date_start}`
    );
  }
  //POS recuperados
  static getLigaPosRegain() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/liga/posRegain`);
  }
  //Acuracidade dos Processos
  static getLigaAccuracy() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/liga/accuracy`);
  }
  //On Time
  static getLigaOnTime(mode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/liga/on-time?mode=${mode}`);
  }
  //Off Time
  static getLigaOffTime() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/liga/off-time`);
  }

  static postChatLiga(info) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/liga/chat`, info)
  }


  //LMP
  //Attendance
  static getLmpAttendance(mode, district, group, start, end) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/LMP/attendance?mode=${mode}&district=${district}&group=${group}&start=${start}&end=${end}`)
  }
  //Efficiency
  static getLmpEfficiency(district, mode, start, end) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/LMP/efficiency?district=${district}&mode=${mode}&start=${start}&end=${end}`)
  }
  //Activation
  static getLmpActivation(district, mode, start, end) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/LMP/activation?district=${district}&mode=${mode}&start=${start}&end=${end}`)
  }
  //People
  static getLmpPeople(mode, district, start, end) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/LMP/people?district=${district}&mode=${mode}&start=${start}&end=${end}`)
  }
  //The Flash
  static getLmpFlash(district, start, end) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/LMP/flash?district=${district}&start=${start}&end=${end}`)
  }
  static getMemberAuth(email) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-member-auth?email=${email}`)
  }


  static getLabCycleDivision() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/lab-cycle/division`);
  }

  static getLabCycleDivisionOperator() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/lab-cycle/division-operator`);
  }

  static getChallengeHunterFirstRank(mode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/challenge-hunter/ranking-1?mode=${mode}`);
  }

  static getChallengeHunterOSRank(mode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/challenge-hunter/ranking-os?mode=${mode}`);
  }
  //Dash Operações
  //Attendance
  static getOperationAttendance(mode, group) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/operation/attendance?mode=${mode}&group=${group}`
    );
  }
  //Efficiency
  static getOperationEfficiency(mode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/operation/efficiency?mode=${mode}`);
  }
  //People
  static getOperationPeople(mode, group) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/operation/people?mode=${mode}&group=${group}`);
  }

  //Hunter
  //OS
  static getHunterOs(mode, group) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/hunter/os?mode=${mode}&group=${group}`)
  }
  //Cases
  static getHunterCase(mode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/hunter/case?mode=${mode}`)
  }
  //People
  static getHunterPeople(mode, group) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/hunter/people?mode=${mode}&group=${group}`)
  }
  //Stock OS
  static getHunterStock() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/hunter/stock`)
  }


  //Trucker
  static getOrigin(url) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/origin`);
  }

  static getDeadline(url) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/deadline`);
  }

  static getTruckerName(url) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/trucker_name`);
  }

  static getOriginTRA(input) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/origin_tra?trucker_name=${input.trucker_name}`);
  }

  static getDeadlineTRA(input) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/deadline_tra`);
  }

  static getOriginPRE(input) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/origin_pre?trucker_name=${input.trucker_name}`);
  }

  static getDeadlinePRE(url) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/deadline_pre`);
  }

  static updateTruckerTRA(input) {
    return LogisticService.putWithAuth(`${appConfig.logisticsUrl}/update_statusTRA?order_number=${input.order_number}&trucker_name=${input.trucker_name}`);
  }

  static updateTruckerPRE(input) {
    return LogisticService.putWithAuth(`${appConfig.logisticsUrl}/update_statusPRE?order_number=${input.order_number}&trucker_name=${input.trucker_name}&destination=${input.destination}`);
  }

  static updateTrucker(input) {
    return LogisticService.putWithAuth(`${appConfig.logisticsUrl}/update_trucker?order_number=${input.order_number}&trucker_name=${input.trucker_name}`);
  }

  static getTrucker(input) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/available_orders?trucker_name=${input.trucker_name}`)
  }

  static updateWKFStatusTRA(input) {
    return LogisticService.putWithAuth(`${appConfig.logisticsUrl}/update_status_tra?order_number=${input}`);
  }

  static updateWKFStatusPRE(input) {
    return LogisticService.putWithAuth(`${appConfig.logisticsUrl}/update_status_pre?order_number=${input}`);
  }

  static deleteExpeditions(id) {
    return LogisticService.postWithAuth(`${appConfig.logisticsUrl}/expedition_delete?id=${id}`)
  }

  //Senninha
  static getSenninhaHubs() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/senninha/hubs`)
  }

  static getSenninhaAngels(district) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/senninha/angels?district=${district}`)
  }

  static getSenninhaRoutes(provider) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/senninha/routes?provider=${provider}`)
  }

  static getSenninhaConfig(provider) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/senninha/config?provider=${provider}`)
  }

  static deleteSenninhaConfigDelete(angel) {
    return LogisticService.deleteWithAuth(`${appConfig.logisticsUrl}/senninha/config/delete?angel=${angel}`)
  }

  static getSenninhaConfigAdd(angel, hub) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/senninha/config/add?hub=${hub}`, angel)
  }

  static postSenninhaConfigLog(id, action, info) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/senninha/config/log`, id, action, info)
  }

  static postSenninhaAloc(stonecodes, email, hub) {
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/senninha/aloc?email=${email}&hub=${hub}`, stonecodes)
  }

  static getSenninhaAloc(hub) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/senninha/aloc?hub=${hub}`)
  }

  static getSenninhaDetail(hub) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/senninha/details?hub=${hub}`)
  }
  
  static postChangeRoutes(hub, first, second) {
    return LogisticService.postWithAuth(`${appConfig.logisticsUrl}/senninha/change-routes?hub=${hub}&first_route=${first}&second_route=${second}`)
  }

  static getSenninhaConfigDangerousAreas(name, district) {
    console.log(district)
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/senninha/config/dangerous-areas?name=${name}&district=${district}`)
  }

  static postSenninhaNewAreas(data, district) {
    console.log(district)
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/senninha/config/dangerous-areas?district=${district}`,data)
  }

  static patchSenninhaEditAreas(data,id) {
    return LogisticService.patchWithAuthAndBody(`${appConfig.logisticsUrl}/senninha/config/dangerous-areas?id=${id}`, data)
  }

  static deleteSenninhaArea(id) {
    return LogisticService.deleteWithAuth(`${appConfig.logisticsUrl}/senninha/config/dangerous-areas?id=${id}`)
  }

  static postAddCampanha(hub) {
    return LogisticService.postWithAuth(`${appConfig.logisticsUrl}/senninha/aloc/campanha?hub=${hub}`)
  }

  //Pix Info
  static getCustomerPixInfo(stonecode) {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/customer-info/pix?stonecode=${stonecode}`)
  }

  //post penalties rv
  static postPenalty(driver, hub_name, district_name, arrival_date, severity){
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/penalties`, {driver, hub_name, district_name, arrival_date, severity})
  }

  //post accidents rv
  static postAccidents(date, type, angel, district, region, modal){
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/accidents`, {date, type, angel, district, region, modal})
  }

  //post general results rv
  static postGeneralResults(year, month, district_name, routespeed_route, checklists_score){
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/general-results`, {year, month, district_name, routespeed_route, checklists_score})
  }

  //post multiple indicators rv
  static postMultipleIndicatorsExcel(tipo, dados){
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/seguranca/atualizacao-massiva?tipo=${tipo}`, dados);
  }

  //get district without region
  static getSecurityDistrictsWithoutRegion() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-districts`);
  }

// get regions
  static getRegions(){
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/get-regions`);
  }

// get Alocations
  static getAlocations() {
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/hubs-alocations`);
  }

// post LIGA tree
  static postLigaTree(ligaTree){
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/liga/post-tree`, ligaTree);
  }

// post LIGA tree multiple
  static postLigaTreeMultiple(ligaTrees){
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/liga/post-tree-multiple`, ligaTrees);
  }

// get LIGA tree
  static getLigaTree(filtros){
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/liga/get-tree`, filtros);
  }

// delete LIGA tree
  static deleteLigaTree(id){
    return LogisticService.deleteWithAuth(`${appConfig.logisticsUrl}/liga/delete-tree?id=${id}`);
  }

// post Notifications
  static postAppNotification(data){
    return LogisticService.requestWithAuthAndBody(`${appConfig.logisticsUrl}/app/send-notification`, data);
  }

// get Notifications
  static getAppNotifications(){
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/app/get-notifications`);
  }

// get ID and name hubs
  static getHubsList(){
    return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/app/get-hubs-list`);
  }

}
