import React, { Component } from "react";
import PropTypes from "prop-types";
import LogisticService from "../../../core/services/LogisticService";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Container, Col, Row } from "react-bootstrap";
import Box from "@material-ui/core/Box";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import AppBar from "@material-ui/core/AppBar";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Redirect, Link } from "react-router-dom";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#103a21",
    color: "white",
    fontSize: "0.9em",
  },
  body: {
    fontSize: "0.8em",
  },
}))(TableCell);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class VariableRemuneration extends Component {
  constructor(props) {
    super();
    this.state = {
      loaded: false,
      lastUpdate: "",
      selectedTab: 0,
      supplierPosition: 0,
      supplierName: props.location.state.name,
      month: props.location.state.month,
      year: props.location.state.year,
      period: props.location.state.month + "-" + props.location.state.year,
      mesesDisponiveis: [],
      avaiableNames: [],
      supplierResults: undefined,
    };
  }

  componentDidMount() {
    this.refreshReportData(
      this.state.month,
      this.state.year,
      this.state.supplierName
    );
    this.requestSuppliersData(
      this.state.month,
      this.state.year,
      this.state.supplierName
    );
    this.getSupplier();
    this.getMonths();
  }

  getMonths() {
    LogisticService.periodosDiponiveisRV()
      .then((response) => {
        this.setState({
          mesesDisponiveis: response.data.data,
        });
      })
      .catch((e) => {
        window.alert("Opa! Ocorreu um erro, por favor, tente novamente");
      });
  }

  getSupplier() {
    LogisticService.listarSuppliersRV(this.state.year, this.state.month)
      .then((response) => {
        this.setState({
          avaiableNames: response.data.data,
          loading: false,
        });
      })
      .catch((e) => {
        window.alert("Opa! Ocorreu um erro, por favor, tente novamente");
      });
  }

  getRangkinPosition(namesRanking) {
    let position = 0;
    for (let i = 1; i <= namesRanking.length; i++) {
      if (this.state.supplierName === namesRanking[i - 1].supplierName) {
        position = i;
        return position;
      }
    }
  }

  requestSuppliersData(month, year) {
    let supplierRanking = undefined;
    LogisticService.getSuppliersRanking(year, month).then((response) => {
      supplierRanking = response.data.data;
      supplierRanking = supplierRanking.sort((a, b) =>
        a["results"]["final_score"] >= b["results"]["final_score"] ? -1 : 1
      );
      this.setState({
        supplierPosition: this.getRangkinPosition(supplierRanking),
      });
    });
  }

  refreshReportData(month, year, supplierName) {
    this.setState({
      loaded: false,
      supplierResults: undefined,
      swapsDetails: undefined,
      hubName: undefined,
      districtName: undefined,
    });

    LogisticService.getSupplierResults(year, month, supplierName).then(
      (response) => {
        this.setState({
          supplierResults: response.data.data[0].results,
          hubName: response.data.data[0].results.polo_name,
          lastUpdate: moment
            .utc(response.data.data[0].dtmModifiedAt)
            .local()
            .format("DD/MM/YYYY - HH:mm"),
          districtName: response.data.data[0].results.district_name,
        });
      }
    );
  }

  ScoreCard(props) {
    var color;
    if (props.ranking !== undefined) {
      color = "#00000099";
    } else if (props.realScore / props.possibleScore >= 0.8) {
      color = "green";
    } else if (props.realScore / props.possibleScore > 0.6) {
      color = "orange";
    } else {
      color = "red";
    }

    return (
      <Card>
        <CardContent>
          <label style={{ color: "grey" }}>
            {props.ranking !== undefined ? (
              <span className="icon">
                <FontAwesomeIcon icon={faTrophy} />
              </span>
            ) : (
              ""
            )}
            {props.title}
          </label>
          <Row
            style={
              props.ranking !== undefined
                ? { marginLeft: "1em" }
                : { marginLeft: "1em", marginTop: "1em" }
            }
          >
            {props.icon}
            <Typography
              variant="h4"
              style={{ marginLeft: "0.5em", color: color }}
            >
              {props.realScore}
            </Typography>
            {props.ranking !== undefined ? (
              <div>
                <p
                  style={{
                    fontSize: "35px",
                    color: color,
                    marginBottom: "-20px",
                  }}
                >
                  °
                </p>
                <Link
                  to={{
                    pathname: "/variable-remuneration-ranking",
                    state: {
                      year: props.year,
                      month: props.month,
                      selectedTab: props.selectedTab,
                    },
                  }}
                >
                  <label
                    style={{
                      cursor: "pointer",
                      fontSize: "15px",
                      color: color,
                      marginLeft: "10px",
                      marginBottom: "-10px",
                      textDecorationLine: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    Ver detalhes
                  </label>
                </Link>
              </div>
            ) : (
              <Typography variant="h6" style={{ color: color }}>
                /{props.possibleScore}
              </Typography>
            )}
          </Row>
        </CardContent>
      </Card>
    );
  }

  SummaryBoxes() {
    if (this.state.supplierResults === undefined) {
      return;
    }

    return (
      <Box>
        <Row>
          <Col xl sm={12} md lg xl={2}>
            <this.ScoreCard
              title="Pilar Eficiência"
              icon={<MonetizationOnIcon fontSize="large" />}
              realScore={(
                100 * this.state.supplierResults.efficiency_column
              ).toFixed(0)}
              possibleScore={100}
            />
          </Col>
          <Col xl sm={12} md lg xl={5}>
            <this.ScoreCard
              title="Atingimento Total"
              realScore={(100 * this.state.supplierResults.final_score).toFixed(
                0
              )}
              possibleScore={100}
            />
          </Col>
          {this.state.supplierPosition !== undefined ? (
            <Col xl sm={12} md lg xl={5}>
              <this.ScoreCard
                ranking="true"
                title="Colocação ranking"
                realScore={this.state.supplierPosition}
                year={this.state.year}
                month={this.state.month}
                selectedTab={2}
              />
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Box>
    );
  }

  efficiencyDetails() {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2em",
          marginBottom: "2em",
        }}
      >
        <Table size="medium" padding="default">
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              <TableCell align="center">Atingimento Semana 1</TableCell>
              <TableCell align="center">Atingimento Semana 2</TableCell>
              <TableCell align="center">Atingimento Semana 3</TableCell>
              <TableCell align="center">Atingimento Semana 4</TableCell>
              <TableCell align="center">Atingimento Semana 5</TableCell>
              <TableCell align="center">Atingimento pós-régua</TableCell>
              <TableCell align="center">Peso</TableCell>
              <TableCell align="center">Impacto no Pilar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">Triagens Good</StyledTableCell>
              {this.state.supplierResults.screening_score_week1 ===
              undefined ? (
                <StyledTableCell align="center">-</StyledTableCell>
              ) : (
                <StyledTableCell align="center">
                  {(
                    100 * this.state.supplierResults.screening_score_week1
                  ).toFixed(1)}
                  %
                </StyledTableCell>
              )}
              {this.state.supplierResults.screening_score_week2 ===
              undefined ? (
                <StyledTableCell align="center">-</StyledTableCell>
              ) : (
                <StyledTableCell align="center">
                  {(
                    100 * this.state.supplierResults.screening_score_week2
                  ).toFixed(1)}
                  %
                </StyledTableCell>
              )}
              {this.state.supplierResults.screening_score_week3 ===
              undefined ? (
                <StyledTableCell align="center">-</StyledTableCell>
              ) : (
                <StyledTableCell align="center">
                  {(
                    100 * this.state.supplierResults.screening_score_week3
                  ).toFixed(1)}
                  %
                </StyledTableCell>
              )}
              {this.state.supplierResults.screening_score_week4 ===
              undefined ? (
                <StyledTableCell align="center">-</StyledTableCell>
              ) : (
                <StyledTableCell align="center">
                  {(
                    100 * this.state.supplierResults.screening_score_week4
                  ).toFixed(1)}
                  %
                </StyledTableCell>
              )}
              {this.state.supplierResults.screening_score_week5 ===
              undefined ? (
                <StyledTableCell align="center">-</StyledTableCell>
              ) : (
                <StyledTableCell align="center">
                  {(
                    100 * this.state.supplierResults.screening_score_week5
                  ).toFixed(1)}
                  %
                </StyledTableCell>
              )}
              <StyledTableCell align="center">
                {(
                  100 * this.state.supplierResults.final_screening_score
                ).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(this.state.supplierResults.weights
                  ? this.state.supplierResults.weights.screening * 100
                  : 50
                ).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  this.state.supplierResults.weights.screening *
                  100 *
                  this.state.supplierResults.final_screening_score
                ).toFixed(1)}
                %
              </StyledTableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              {!this.state.supplierResults ? (
                <></>
              ) : (
                Object.keys(this.state.supplierResults)
                  .filter((e) => e.includes("stock_photo_score_week"))
                  .sort()
                  .map((weekResult) => (
                    <TableCell align="center">
                      Atuação Semana {weekResult.slice(-1)}
                    </TableCell>
                  ))
              )}
              <TableCell align="center">Atuação Média</TableCell>
              <TableCell align="center">Meta</TableCell>
              <TableCell align="center">Atingimento normalizado</TableCell>
              <TableCell align="center">
                Atingimento normalizado (pós-régua)
              </TableCell>
              <TableCell align="center">Peso</TableCell>
              <TableCell align="center">Impacto no Pilar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">Ciclo Triagem</StyledTableCell>
              {!this.state.supplierResults ? (
                <></>
              ) : (
                Object.keys(this.state.supplierResults)
                  .filter((e) => e.includes("stock_photo_score_week"))
                  .sort()
                  .map((weekResult) => (
                    <StyledTableCell align="center">
                      {(
                        100 * this.state.supplierResults[weekResult] || 0
                      ).toFixed(2)}
                      %
                    </StyledTableCell>
                  ))
              )}
              <StyledTableCell align="center">
                {(100 * this.state.supplierResults.stock_photo_score).toFixed(
                  2
                )}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.supplierResults.stock_photo_goal).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  (100 * this.state.supplierResults.stock_photo_score) /
                  this.state.supplierResults.stock_photo_goal
                ).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.supplierResults.final_stock_photo_score
                ).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.supplierResults.weights.stock_photos
                ).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 *
                  (this.state.supplierResults.final_stock_photo_score *
                    this.state.supplierResults.weights.stock_photos)
                ).toFixed(2)}
                %
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              <TableCell align="center">Atingimento</TableCell>
              <TableCell align="center">Atingimento pós-régua</TableCell>
              <TableCell align="center">Peso</TableCell>
              <TableCell align="center">Impacto no Pilar</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">
                Qualidade da triagem (retrocas)
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.supplierResults.screening_quality_score
                ).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  this.state.supplierResults.final_screening_quality_score * 100
                ).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(this.state.supplierResults.weights
                  ? this.state.supplierResults.weights.screening_quality * 100
                  : 20
                ).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  (this.state.supplierResults.weights
                    ? this.state.supplierResults.weights.screening_quality
                    : 0.2) *
                  this.state.supplierResults.final_screening_quality_score *
                  100
                ).toFixed(1)}
                %
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">Inventário</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.supplierResults.inventory).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.supplierResults.inventory_bonus).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                -7,5%/+2,5% (Bônus)
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.supplierResults.inventory_bonus).toFixed(1)}%
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">Produtividade</StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.supplierResults.final_productivity_score
                ).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.supplierResults.final_productivity_score
                ).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {this.state.supplierResults.weights
                  ? (
                      100 * this.state.supplierResults.weights.productivity
                    ).toFixed(1)
                  : 30}
                %
              </StyledTableCell>
              <StyledTableCell align="center">
                {this.state.supplierResults.weights
                  ? (
                      100 *
                      (this.state.supplierResults.final_productivity_score *
                        this.state.supplierResults.weights.productivity)
                    ).toFixed(1)
                  : 30}
                %
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  attendanceDetails() {
    return (
      <div
        style={{
          width: "70%",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2em",
          marginBottom: "2em",
        }}
      >
        <Table size="medium" padding="default">
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              <TableCell align="center">Atingimento</TableCell>
              <TableCell align="center">Atingimento pós-régua</TableCell>
              <TableCell align="center">Peso</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">SLA</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.supplierResults.raw_sla).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.supplierResults.final_sla_score).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">20%</StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">CSAT</StyledTableCell>
              <StyledTableCell align="center">-</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.supplierResults.final_csat_score).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">20%</StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">
                D-zero (distrito)
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.supplierResults.district_dzero).toFixed(1)}%
              </StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.supplierResults.final_dzero_score).toFixed(
                  1
                )}
                %
              </StyledTableCell>
              <StyledTableCell align="center">+2,5%</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  activationDetails() {
    return (
      <div
        style={{
          width: "70%",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2em",
          marginBottom: "2em",
        }}
      >
        <Table size="medium" padding="default">
          <TableHead>
            <TableRow hover>
              <TableCell align="center">Indicador</TableCell>
              <TableCell align="center">Atingimento</TableCell>
              <TableCell align="center">Atingimento pós-régua</TableCell>
              <TableCell align="center">Peso</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="center">Novos Produtos</StyledTableCell>
              <StyledTableCell align="center">-</StyledTableCell>
              <StyledTableCell align="center">
                {(
                  100 * this.state.supplierResults.final_new_products_score
                ).toFixed(1)}
                %
              </StyledTableCell>
              <StyledTableCell align="center">20%</StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="center">Portal</StyledTableCell>
              <StyledTableCell align="center">-</StyledTableCell>
              <StyledTableCell align="center">
                {(100 * this.state.supplierResults.final_portal_score).toFixed(
                  1
                )}
                %
              </StyledTableCell>
              <StyledTableCell align="center">20%</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  renderAngelSummary() {
    return (
      <div>
        <MaterialTable
          columns={[
            { title: "Pilar", field: "name" },
            { title: "Atingimento", field: "score" },
            { title: "Limite Máximo", field: "limit" },
          ]}
          options={{
            showTitle: false,
            search: false,
            paging: false,
            toolbar: false,
          }}
          data={[
            {
              name: "Eficiência",
              score:
                (100 * this.state.supplierResults.efficiency_column).toFixed(
                  1
                ) + "%",
              limit: "100%",
            },
          ]}
          detailPanel={(rowData) => {
            if (rowData.name === "Eficiência") {
              return this.efficiencyDetails();
            }
          }}
        />
      </div>
    );
  }

  requestOrdersDetails() {
    this.setState({
      serviceOrdersDetails: "loading",
    });

    LogisticService.getAngelOrdersData(
      this.state.year,
      this.state.month,
      this.state.supplierName,
      this.state.hubName
    ).then((response) => {
      this.setState({
        serviceOrdersDetails: response.data.data,
      });
    });
  }

  renderOrdersDetails() {
    if (this.state.serviceOrdersDetails === undefined) {
      if (this.state.selectedTab == 10) {
        this.requestOrdersDetails();
      }
      return;
    } else if (this.state.serviceOrdersDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];

    this.state.serviceOrdersDetails.map((order) =>
      rows.push({
        orderNumber: order.orderNumber,
        technicianName: order.technicianName,
        considerInSlaAccount: order.considerInSlaAccount,
        considerInTmaAccount: order.considerInTmaAccount,
        solvedWithSlaOnTime: order.solvedWithSlaOnTime,
        solvedWithSlaCustomerOnTime: order.solvedWithSlaCustomerOnTime,
        considerInDzeroAccount: order.considerInDzeroAccount,
        solvedInDzero: order.solvedInDzero,
        solvedWithAnticipation: order.solvedWithAnticipation,
        redirectPolo: order.redirectPolo,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "orderNumber",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Angel",
            field: "technicianName",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Conta pro SLA",
            field: "considerInSlaAccount",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Conta pro TMA",
            field: "considerInTmaAccount",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "SLA no Prazo",
            field: "solvedWithSlaOnTime",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "5em" },
          },
          {
            title: "SLA Cliente no Prazo",
            field: "solvedWithSlaCustomerOnTime",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Conta pro D0",
            field: "considerInDzeroAccount",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Resolvida em D0",
            field: "solvedInDzero",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Chamado Antecipado",
            field: "solvedWithAnticipation",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
          {
            title: "Redirect",
            field: "redirectPolo",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "25em" },
          },
        ]}
        data={rows}
        title="Chamados Atendidos"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          filtering: true,
        }}
      />
    );
  }

  requestPortalDetails() {
    this.setState({
      portalDetails: "loading",
    });

    LogisticService.getAngelPortalData(
      this.state.year,
      this.state.month,
      this.state.supplierName,
      this.state.hubName
    ).then((response) => {
      this.setState({
        portalDetails: response.data.data,
      });
    });
  }

  renderPortalDetails() {
    if (this.state.portalDetails === undefined) {
      if (this.state.selectedTab == 2) {
        this.requestPortalDetails();
      }
      return;
    } else if (this.state.portalDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];

    this.state.portalDetails.map((customer) =>
      rows.push({
        customer: customer.customer,
        customerName: customer.customerName,
        // provider: customer.provider,
        neighborhood: customer.neighborhood,
        withAccess: customer.withAccess,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Stonecode",
            field: "customer",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Nome do Cliente",
            field: "customerName",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          // { title: 'Polo', field: 'provider', cellStyle: {fontSize: '0.8em', width: '8em'}},
          {
            title: "Bairro",
            field: "neighborhood",
            cellStyle: { fontSize: "0.8em", width: "5em" },
          },
          {
            title: "Acessou o Portal",
            field: "withAccess",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Acessos ao portal"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  requestReversesDetails() {
    this.setState({
      reversesDetails: "loading",
    });

    LogisticService.getAngelReversesData(
      this.state.year,
      this.state.month,
      this.state.hubName
    ).then((response) => {
      this.setState({
        reversesDetails: response.data.data,
      });
    });
  }

  renderReversesDetails() {
    if (this.state.reversesDetails === undefined) {
      if (this.state.selectedTab == 3) {
        this.requestReversesDetails();
      }
      return;
    } else if (this.state.reversesDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];

    this.state.reversesDetails.map((customer) =>
      rows.push({
        provider: customer.provider,
        reason: customer.reason,
        scored: customer.scored,
        weekNumber: customer.weekNumber,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Polo",
            field: "provider",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Semana",
            field: "weekNumber",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Pontuou",
            field: "scored",
            type: "boolean",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Motivo",
            field: "reason",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Reversas"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  requestScreenedTerminalsDetails() {
    this.setState({
      screenedTerminalsDetails: "loading",
    });

    LogisticService.getDistrictScreeningData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        screenedTerminalsDetails: response.data.data,
      });
    });
  }

  renderScreenedTerminalsDetails() {
    if (this.state.screenedTerminalsDetails === undefined) {
      if (this.state.selectedTab == 2) {
        this.requestScreenedTerminalsDetails();
      }
      return;
    } else if (this.state.screenedTerminalsDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    var date;
    this.state.screenedTerminalsDetails.map(
      (terminal) => (
        (date = terminal.screeningDate.slice(0, 10).split("-")),
        rows.push({
          serial: terminal.serial,
          statusBefore: terminal.statusBefore,
          statusAfter: terminal.statusAfter,
          screeningDate: date[2] + "/" + date[1] + "/" + date[0],
          weekNumber: terminal.weekNumber,
          provider: terminal.provider,
        })
      )
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Serial",
            field: "serial",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Polo",
            field: "provider",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Status Antes",
            field: "statusBefore",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Status Depois",
            field: "statusAfter",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Data da Triagem",
            field: "screeningDate",
            cellStyle: { fontSize: "0.8em", width: "7em" },
          },
          {
            title: "Semana",
            field: "weekNumber",
            cellStyle: { fontSize: "0.8em", width: "4em" },
          },
        ]}
        data={rows}
        title="Triagens Good"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          filtering: true,
        }}
      />
    );
  }

  requestRemovedTerminalsDetails() {
    this.setState({
      removedTerminalsDetails: "loading",
    });

    LogisticService.getDistrictRemovedTerminalsData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        removedTerminalsDetails: response.data.data,
      });
    });
  }

  renderRemovedTerminalsDetails() {
    if (this.state.removedTerminalsDetails === undefined) {
      if (this.state.selectedTab == 3) {
        this.requestRemovedTerminalsDetails();
      }
      return;
    } else if (this.state.removedTerminalsDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    var removalDate;
    this.state.removedTerminalsDetails.map(
      (terminal) => (
        (removalDate =
          terminal.removalDate.slice(0, 10).split("-")[2] +
          "/" +
          terminal.removalDate.slice(0, 10).split("-")[1] +
          "/" +
          terminal.removalDate.slice(0, 10).split("-")[0]),
        rows.push({
          serialUninstalled: terminal.serialUninstalled,
          statusBefore: terminal.statusBefore,
          serviceOrderNumber: terminal.serviceOrderNumber,
          serviceGroup: terminal.serviceGroup,
          removalDate: removalDate,
          weekNumber: terminal.weekNumber,
          provider: terminal.provider,
        })
      )
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "serviceOrderNumber",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Grupo de Serviço",
            field: "serviceGroup",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Serial",
            field: "serialUninstalled",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Polo",
            field: "provider",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Data da Retirada",
            field: "removalDate",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Semana",
            field: "weekNumber",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Terminais retirados"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          filtering: true,
        }}
      />
    );
  }

  requestCSATDetails() {
    this.setState({
      csatDetails: "loading",
    });

    LogisticService.getAngelCSATData(
      this.state.year,
      this.state.month,
      this.state.supplierName
    ).then((response) => {
      this.setState({
        csatDetails: response.data.data,
      });
    });
  }

  renderCSATDetails() {
    if (this.state.csatDetails === undefined) {
      if (this.state.selectedTab == 6) {
        this.requestCSATDetails();
      }
      return;
    } else if (this.state.csatDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.csatDetails.map((os) =>
      rows.push({
        angel: os.angel,
        hub: os.hub,
        district: os.district,
        region: os.region,
        satisfaction: os.satisfaction,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Angel",
            field: "angel",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Polo",
            field: "hub",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Regional",
            field: "region",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Satisfação",
            field: "satisfaction",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="CSAT"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          filtering: true,
        }}
      />
    );
  }

  requestXrayDetails() {
    this.setState({
      xrayDetails: "loading",
    });

    LogisticService.getAngelXrayData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        xrayDetails: response.data.data,
      });
    });
  }

  renderXrayDetails() {
    if (this.state.xrayDetails === undefined) {
      if (this.state.selectedTab == 7) {
        this.requestXrayDetails();
      }
      return;
    } else if (this.state.xrayDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.xrayDetails.map((os) => {
      var pendencies = os.pendencies == "False" ? "Não" : "Sim";
      var openingDate =
        os.openingDate.split("-")[2] +
        "/" +
        os.openingDate.split("-")[1] +
        "/" +
        os.openingDate.split("-")[0];
      var firstAccess;

      firstAccess =
        os.firstAccess == "0001-01-01"
          ? "-"
          : os.firstAccess.split("-")[2] +
            "/" +
            os.firstAccess.split("-")[1] +
            "/" +
            os.firstAccess.split("-")[0];

      rows.push({
        xrayOrderNumber: os.xrayOrderNumber,
        customer: os.customer,
        openingDate: openingDate,
        firstAccess: firstAccess,
        pendencies: pendencies,
        angel: os.angel,
        hub: os.hub,
        district: os.district,
      });
    });

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "xrayOrderNumber",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Stonecode",
            field: "customer",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Data de Instalação",
            field: "openingDate",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Primeiro Acesso",
            field: "firstAccess",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Pendencias",
            field: "pendencies",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Green Angel",
            field: "angel",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Polo",
            field: "hub",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="RaioX"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  requestCollactDetails() {
    this.setState({
      collactDetails: "loading",
    });
    LogisticService.getAngelCollactData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        collactDetails: response.data.data,
      });
    });
  }

  renderCollactDetails() {
    if (this.state.collactDetails === undefined) {
      if (this.state.selectedTab == 8) {
        this.requestCollactDetails();
      }
      return;
    } else if (this.state.collactDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.collactDetails.map((os) => {
      var openingDate =
        os.openingDate.split("-")[2] +
        "/" +
        os.openingDate.split("-")[1] +
        "/" +
        os.openingDate.split("-")[0];
      var firstAccess;

      firstAccess =
        os.activationDate == "0001-01-01"
          ? "-"
          : os.activationDate.split("-")[2] +
            "/" +
            os.activationDate.split("-")[1] +
            "/" +
            os.activationDate.split("-")[0];

      rows.push({
        collactOrderNumber: os.collactOrderNumber,
        customer: os.customer,
        openingDate: openingDate,
        activationDate: firstAccess,
        angel: os.angel,
        hub: os.hub,
        district: os.district,
        region: os.region,
      });
    });

    return (
      <MaterialTable
        columns={[
          {
            title: "Número da OS",
            field: "collactOrderNumber",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Stonecode",
            field: "customer",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Data de Abertura",
            field: "openingDate",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Data de Ativação",
            field: "activationDate",
            cellStyle: { fontSize: "0.8em", width: "10em" },
          },
          {
            title: "Angel",
            field: "angel",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Polo",
            field: "hub",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="CSAT"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
        }}
      />
    );
  }

  requestStockPhotoDetails() {
    this.setState({
      stockPhotoDetails: "loading",
    });

    LogisticService.getDistrictStockPhotoData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        stockPhotoDetails: response.data.data,
      });
    });
  }

  renderStockPhotoDetails() {
    if (this.state.stockPhotoDetails === undefined) {
      if (this.state.selectedTab == 1) {
        this.requestStockPhotoDetails();
      }
      return;
    } else if (this.state.stockPhotoDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];

    this.state.stockPhotoDetails.map((customer) =>
      rows.push({
        serial: customer.serial,
        status: customer.status,
        provider: customer.provider,
        district: customer.district,
        photoDate: moment(customer.photoDate).format("DD/MM/YYYY"),
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "Serial",
            field: "serial",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Status",
            field: "status",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Polo",
            field: "provider",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: { fontSize: "0.8em", width: "5em" },
          },
          {
            title: "Data da Foto",
            field: "photoDate",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Ciclo Triagem"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          filtering: true,
        }}
      />
    );
  }

  requestSwaps() {
    this.setState({
      swapsDetails: "loading",
    });

    LogisticService.getDistrictSwapsData(
      this.state.year,
      this.state.month,
      this.state.districtName
    ).then((response) => {
      this.setState({
        swapsDetails: response.data.data,
      });
    });
  }

  renderSwaps() {
    if (this.state.swapsDetails === undefined) {
      if (this.state.selectedTab === 4) {
        this.requestSwaps();
      }
      return;
    } else if (this.state.swapsDetails == "loading") {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    let rows = [];
    this.state.swapsDetails.map((os) =>
      rows.push({
        in: os.osIn,
        out: os.osOut,
        defect: os.defectOut,
        provider: os.providerOut,
        district: os.districtOut,
        dtRemoval: moment(os.dtRemoval).format("DD/MM/YYYY"),
        daysCustomer: os.daysOnCustomer,
      })
    );

    return (
      <MaterialTable
        columns={[
          {
            title: "OS In",
            field: "in",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "OS Out",
            field: "out",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Defeito",
            field: "defect",
            cellStyle: { fontSize: "0.8em", width: "3em" },
          },
          {
            title: "Polo",
            field: "provider",
            cellStyle: { fontSize: "0.8em", width: "8em" },
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: { fontSize: "0.8em", width: "5em" },
          },
          {
            title: "Data de Remoção",
            field: "dtRemoval",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
          {
            title: "Dias no Cliente",
            field: "daysCustomer",
            cellStyle: { fontSize: "0.8em", width: "15em" },
          },
        ]}
        data={rows}
        title="Retroca"
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
        options={{
          exportAllData: true,
          pageSize: 10,
          showTitle: false,
          padding: "dense",
          exportButton: true,
          maxBodyHeight: 500,
          filtering: true,
        }}
      />
    );
  }

  tabsPannel() {
    const handleChange = (event, newValue) => {
      this.setState({
        selectedTab: newValue,
      });
    };

    if (this.state.supplierResults === undefined) {
      return (
        <div style={{ textAlign: "center" }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      );
    }

    return (
      <div style={{ marginTop: "2em" }}>
        <AppBar position="static" style={{ backgroundColor: "#103A21" }}>
          <Tabs
            value={this.state.selectedTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Resumo" />
            <Tab label="Ciclo Triagem" />
            <Tab label="Terminais Triados" />
            <Tab label="Terminais Retirados" />
            {/* <Tab label="Retroca" /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.selectedTab} index={0}>
          {this.renderAngelSummary()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          {this.renderStockPhotoDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={2}>
          {this.renderScreenedTerminalsDetails()}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={3}>
          {this.renderRemovedTerminalsDetails()}
        </TabPanel>
        {/* <TabPanel value={this.state.selectedTab} index={4}>
          {this.renderSwaps()}
        </TabPanel> */}
      </div>
    );
  }

  updatedData(value) {
    if (value !== "empty") {
      this.setState({
        period: value,
        year: value.split("-")[1],
        month: value.split("-")[0],
        supplierResults: undefined,
      });
      this.refreshReportData(
        value.split("-")[0],
        value.split("-")[1],
        this.state.supplierName
      );
      this.requestSuppliersData(
        value.split("-")[0],
        value.split("-")[1],
        this.state.supplierName
      );
    }
  }

  updatedSupplierData(value) {
    if (value !== "Selecione um nome") {
      this.setState({
        supplierName: value,
      });
      this.refreshReportData(this.state.month, this.state.year, value);
      this.requestSuppliersData(this.state.month, this.state.year, value);
    }
  }

  headPanel() {
    if (this.state.supplierResults === undefined) {
      return;
    }
    return (
      <div>
        <div class="row">
          <label
            style={{
              float: "left",
              fontSize: "1.5em",
              fontWeight: "bold",
              marginLeft: "1em",
              marginBottom: "0.5em",
              marginTop: "0.5em",
            }}
          >
            Remuneração Variável
          </label>
        </div>
        <div class="row">
          <label
            style={{
              float: "left",
              fontSize: "1.5em",
              marginLeft: "1em",
              marginBottom: "0.5em",
              marginTop: "0.5em",
            }}
          >
            <Button
              color="default"
              variant="contained"
              style={{ marginRight: "1em", verticalAlign: "middle" }}
              onClick={(e) => this.setState({ redirect: true })}
            >
              Voltar
            </Button>
          </label>
        </div>
        <div class="col align-self-start" style={{ width: "100%" }}>
          <FormControl variant="outlined" margin="3em">
            <Select
              value={this.state.supplierName}
              onChange={(e, value) =>
                this.updatedSupplierData(value.props.value)
              }
              labelWidth={320}
              style={{ width: 320, marginTop: "1em" }}
            >
              <MenuItem value="Selecione um nome">Selecione um nome</MenuItem>
              {this.state.avaiableNames.map((row) => {
                return (
                  <MenuItem value={row.supplierName}>
                    {row.supplierName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl style={{ marginLeft: "21px" }}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.period}
              onChange={(e, value) => this.updatedData(value.props.value)}
              labelWidth={"130%"}
              style={{ width: "130%", marginTop: "1em" }}
            >
              <MenuItem value="empty">Selecione um mês</MenuItem>
              {this.state.mesesDisponiveis.map((row) => (
                <MenuItem value={row.referenceMonth + "-" + row.referenceYear}>
                  {row.referenceMonth}/{row.referenceYear}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div class="row">
          <div class="col align-self-end">
            <label
              style={{
                float: "right",
                fontSize: "15px",
                marginLeft: "1em",
                marginBottom: "0.5em",
                marginTop: "1em",
                fontStyle: "italic",
              }}
            >
              {this.state.lastUpdate === ""
                ? ""
                : `Atualizado em ${this.state.lastUpdate}`}
            </label>
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
      </div>
    );
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/variable-remuneration",
          }}
        />
      );
    }
    return (
      <Container fluid>
        {this.headPanel()}
        {this.SummaryBoxes()}
        {this.tabsPannel()}
      </Container>
    );
  }
}

VariableRemuneration.propTypes = {
  name: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
};

VariableRemuneration.defaultProps = {
  name: "JANDERSON THIAGO CIRINO DE ALMEIDA",
  year: "2019",
  month: "10",
};

export default VariableRemuneration;
