import React, { useEffect, useState } from "react";
import * as SegurancaAPI from "../../core/services/Seguranca";
import LogisticService from "../../core/services/LogisticService";
import { Tabs, Button } from "antd";
import MaterialTable from "material-table";
import XLSX from "xlsx";
import { SheetJsInidicator } from "../../custom-components/SheetJs/SheetJsIndicators";
import { getUserEmail } from "../../storage";

const defaultTableOptions = {
  padding: "dense",
  pageSize: 10,
  exportButton: true,
  exportAllData: true,
};

const localization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsSelect: "linhas",
    nextAriaLabel: "Próxima página",
    nextTooltip: "Próxima página",
    previousAriaLabel: "Página anterior",
    previousTooltip: "Página anterior",
    lastAriaLabel: "Última página",
    lastTooltip: "Última página",
    firstAriaLabel: "Primeira página",
    firstTooltip: "Primeira página",
  },
  header: {
    actions: "",
  },
  toolbar: {
    searchTooltip: "Buscar",
    searchPlaceholder: "Buscar",
    addTooltip: "Adicionar",
    exportTitle: "Download",
    exportAriaLabel: "Download",
    exportName: "Download como CSV",
  },
  body: {
    emptyDataSourceMessage: "Sem registros para exibir",
    addTooltip: "Adicionar",
    deleteTooltip: "Deletar",
    editTooltip: "Editar",
    filterRow: {
      filterTooltip: "Filtrar",
    },
    editRow: {
      deleteText: "Tem certeza que quer deletar?",
      saveTooltip: "Salvar",
      cancelTooltip: "Cancelar",
    },
  },
};

function exportFile(type) {
  const wb = XLSX.utils.book_new();
  let worksheetName;
  let worksheet;

  if (type === "acidentes") {
    worksheetName = "Acidentes";
    worksheet = XLSX.utils.aoa_to_sheet([
      [
        "Data do Acidente",
        "Tipo",
        "Angel(acidente)",
        "Distrito(acidente)",
        "Região",
        "Modal",
        "Dias Operação",
      ],
      [
        "2019-04-27",
        "Com/Sem Patrimônio",
        "Sistemas Teste",
        "Distrito OSP",
        "ISP",
        "Carro",
        284,
      ],
    ]);
  } else if (type === "multas") {
    worksheetName = "Multas";
    worksheet = XLSX.utils.aoa_to_sheet([
      [
        "Angel(multa)",
        "Polo",
        "Distrito(multa)",
        "Data de Recebimento",
        "Gravidade",
      ],
      [
        "Sistemas Teste",
        "RJ - Volta Redonda Stone Pagamentos",
        "Distrito VP Interior",
        "2010/01/30",
        "GRAVE - 5",
      ],
    ]);
  } else if (type === "indicadores") {
    worksheetName = "Indicadores";
    worksheet = XLSX.utils.aoa_to_sheet([
      ["Ano", "Mês", "Distrito Resultado Geral", "Vvia", "Checklist"],
      ["2020", "1", "Distrito BA", "9.9", "2.4"],
    ]);
  }

  XLSX.utils.book_append_sheet(wb, worksheet, worksheetName);
  XLSX.writeFile(wb, `Segurança_${worksheetName}.xlsx`);
}

const InputDadosSeguranca = () => {
  const [acidentes, setAcidentes] = useState(undefined);
  const [multas, setMultas] = useState(undefined);
  const [indicadores, setIndicadores] = useState(undefined);
  const [distritos, setDistritos] = useState(undefined);
  const [regionais, setRegionais] = useState(undefined);

  useEffect(() => {
    SegurancaAPI.buscarAcidentes().then((response) => {
      setAcidentes(response.data);
    });
    LogisticService.getAllDistricts().then((response) => {
      const objDistritos = {};
      const objRegionais = {};
      response.data.data.forEach((distrito) => {
        objDistritos[distrito.name] = distrito.name;
        objRegionais[distrito.region] = distrito.region;
      });
      setDistritos(objDistritos);
      setRegionais(objRegionais);
    });
  }, []);

  const buscarDadosSeguranca = (indicador) => {
    if (indicador === "acidentes") {
      SegurancaAPI.buscarAcidentes().then((response) => {
        setAcidentes(response.data);
      });
    } else if (indicador === "multas") {
      SegurancaAPI.buscarMultas().then((response) => {
        setMultas(response.data);
      });
    } else if (indicador === "indicadores") {
      SegurancaAPI.buscarIndicadores().then((response) => {
        setIndicadores(response.data);
      });
    }
  };

  const handleTabChange = (abaSelecionada) => {
    if (abaSelecionada === "acidentes" && !acidentes) {
      buscarDadosSeguranca("acidentes");
    } else if (abaSelecionada === "multas" && !multas) {
      buscarDadosSeguranca("multas");
    } else if (abaSelecionada === "indicadores" && !indicadores) {
      buscarDadosSeguranca("indicadores");
    }
  };

  const TabelaDeAcidentes = () => {
    return (
      <MaterialTable
        title="Acidentes"
        isLoading={!distritos || !acidentes}
        localization={localization}
        options={defaultTableOptions}
        columns={[
          { title: "ID", field: "id", hidden: true },
          {
            title: "Angel",
            field: "angel",
            cellStyle: {
              width: 400,
            },
            validate: (rowData) =>
              !rowData.angel
                ? "Campo obrigatório"
                : rowData.angel === ""
                ? "Campo obrigatório"
                : "",
          },
          {
            title: "Distrito",
            field: "district",
            cellStyle: {
              width: 150,
            },
            lookup: distritos,
            validate: (rowData) =>
              !rowData.district ? "Campo obrigatório" : "",
          },
          {
            title: "Regional",
            field: "region",
            cellStyle: {
              width: 150,
            },
            lookup: regionais,
            validate: (rowData) => (!rowData.region ? "Campo obrigatório" : ""),
          },
          {
            title: "Data",
            field: "date",
            type: "date",
            cellStyle: {
              width: 150,
            },
            validate: (rowData) => (!rowData.date ? "Campo obrigatório" : ""),
            render: (value) => new Date(value.date).toLocaleDateString("pt-BR"),
          },
          {
            title: "Tipo",
            field: "type",
            cellStyle: {
              width: 150,
            },
            validate: (rowData) => (!rowData.type ? "Campo obrigatório" : ""),
            lookup: {
              "Com patrimônio": "Com patrimônio",
              "Sem afastamento": "Sem afastamento",
              "Com afastamento": "Com afastamento",
            },
          },
          {
            title: "Dias de afastamento",
            field: "daysApart",
            type: "numeric",
            cellStyle: {
              width: 150,
            },
            validate: (rowData) =>
              rowData.daysApart === ""
                ? "Campo obrigatório"
                : rowData.daysApart < 0
                ? "Número inválido"
                : "",
          },
          {
            title: "Modal",
            field: "modal",
            cellStyle: {
              width: 150,
            },
            validate: (rowData) => (!rowData.modal ? "Campo obrigatório" : ""),
            lookup: {
              Carro: "Carro",
              Moto: "Moto",
            },
          },
          {
            title: "Dias de Operação",
            field: "operationDays",
            type: "numeric",
            cellStyle: {
              width: 150,
            },
            validate: (rowData) =>
              !rowData.operationDays
                ? "Campo obrigatório"
                : rowData.operationDays < 0
                ? "Número inválido"
                : "",
          },
        ]}
        data={acidentes || []}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                newData["email"] = getUserEmail();
                SegurancaAPI.atualizarAcidente(oldData.id, newData).then(() =>
                  buscarDadosSeguranca("acidentes")
                );
                resolve();
              }, 600);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                SegurancaAPI.removerAcidente(oldData.id).then(() =>
                  buscarDadosSeguranca("acidentes")
                );
                resolve();
              }, 600);
            }),
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                newData["email"] = getUserEmail();
                SegurancaAPI.inserirAcidente(newData).then(() =>
                  buscarDadosSeguranca("acidentes")
                );
                resolve();
              }, 1000);
            }),
        }}
      />
    );
  };

  const TabelaDeMultas = () => {
    return (
      <MaterialTable
        title="Multas"
        isLoading={!distritos || !multas}
        localization={localization}
        options={defaultTableOptions}
        columns={[
          { title: "ID", field: "id", hidden: true },
          {
            title: "Angel",
            field: "driver",
            cellStyle: {
              width: 400,
            },
            validate: (rowData) =>
              !rowData.driver || rowData.driver === ""
                ? "Campo obrigatório"
                : "",
          },
          {
            title: "Distrito",
            field: "districtName",
            cellStyle: {
              width: 150,
            },
            lookup: distritos,
            validate: (rowData) =>
              !rowData.districtName || rowData.districtName === ""
                ? "Campo obrigatório"
                : "",
          },
          {
            title: "Data",
            field: "arrivalDate",
            type: "date",
            cellStyle: {
              width: 150,
            },
            validate: (rowData) =>
              !rowData.arrivalDate || rowData.arrivalDate === ""
                ? "Campo obrigatório"
                : "",
            render: (value) =>
              new Date(value.arrivalDate).toLocaleDateString("pt-BR"),
          },
          {
            title: "Pontos",
            field: "severity",
            cellStyle: {
              width: 150,
            },
            validate: (rowData) =>
              !rowData.severity || rowData.severity === ""
                ? "Campo obrigatório"
                : "",
            lookup: {
              "Leve - 3 pontos": "Leve - 3 pontos",
              "Média - 4 pontos": "Média - 4 pontos",
              "Grave - 5 pontos": "Grave - 5 pontos",
              "Gravíssima - 7 pontos": "Gravíssima - 7 pontos",
            },
          },
        ]}
        data={multas || []}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                newData["email"] = getUserEmail();
                SegurancaAPI.atualizarMulta(oldData.id, newData).then(() =>
                  buscarDadosSeguranca("multas")
                );
                resolve();
              }, 600);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                SegurancaAPI.removerMulta(oldData.id).then(() =>
                  buscarDadosSeguranca("multas")
                );
                resolve();
              }, 600);
            }),
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                newData["email"] = getUserEmail();
                SegurancaAPI.inserirMulta(newData).then(() =>
                  buscarDadosSeguranca("multas")
                );
                resolve();
              }, 1000);
            }),
        }}
      />
    );
  };

  const TabelaDeIndicadores = () => {
    return (
      <MaterialTable
        title="Indicadores de Segurança"
        isLoading={!distritos || !indicadores}
        localization={localization}
        options={defaultTableOptions}
        columns={[
          { title: "ID", field: "id", hidden: true },
          {
            title: "Distrito",
            field: "district",
            cellStyle: {
              width: 250,
            },
            lookup: distritos,
            validate: (rowData) =>
              !rowData.district || rowData.district === ""
                ? "Campo obrigatório"
                : "",
          },
          {
            title: "Mês",
            field: "month",
            type: "numeric",
            cellStyle: {
              width: 150,
            },
            validate: (rowData) =>
              !rowData.month || rowData.month === "" ? "Campo obrigatório" : "",
          },
          {
            title: "Ano",
            field: "year",
            type: "numeric",
            cellStyle: {
              width: 150,
            },
            validate: (rowData) =>
              !rowData.year || rowData.year === "" ? "Campo obrigatório" : "",
          },
          {
            title: "Checklist",
            field: "pontuacaoChecklist",
            type: "numeric",
            cellStyle: {
              width: 150,
            },
            render: (rowData) => rowData.pontuacaoChecklist * 100 + "%",
            validate: (rowData) =>
              !rowData.pontuacaoChecklist || rowData.pontuacaoChecklist === ""
                ? "Campo obrigatório"
                : "",
          },
          {
            title: "Taxa VVIA",
            field: "taxaVvia",
            cellStyle: {
              width: 150,
            },
            validate: (rowData) =>
              !rowData.taxaVvia || rowData.taxaVvia === ""
                ? "Campo obrigatório"
                : "",
          },
        ]}
        data={indicadores || []}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                newData["email"] = getUserEmail();
                SegurancaAPI.atualizarIndicador(oldData.id, newData).then(() =>
                  buscarDadosSeguranca("indicadores")
                );
                resolve();
              }, 600);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                SegurancaAPI.removerIndicador(oldData.id).then(() =>
                  buscarDadosSeguranca("indicadores")
                );
                resolve();
              }, 600);
            }),
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                newData["email"] = getUserEmail();
                SegurancaAPI.inserirIndicador(newData).then(() =>
                  buscarDadosSeguranca("indicadores")
                );
                resolve();
              }, 1000);
            }),
        }}
      />
    );
  };

  return (
    <>
      <Tabs defaultActiveKey="acidentes" onChange={handleTabChange}>
        <Tabs.TabPane
          tab="Acidentes"
          key="acidentes"
          style={{
            padding: "0px  5px",
          }}
        >
          <TabelaDeAcidentes />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ margin: 10 }}>
              <SheetJsInidicator
                tipo="acidentes"
                onUpdate={() => buscarDadosSeguranca("acidentes")}
              />
            </div>
            <Button
              variant="success"
              onClick={() => exportFile("acidentes")}
              style={{ marginBottom: 10 }}
            >
              Exportar exemplo
            </Button>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Multas" key="multas">
          <TabelaDeMultas />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ margin: 10 }}>
              <SheetJsInidicator
                tipo="multas"
                onUpdate={() => buscarDadosSeguranca("multas")}
              />
            </div>
            <Button
              variant="success"
              onClick={() => exportFile("multas")}
              style={{ marginBottom: 10, margin: 10 }}
            >
              Exportar exemplo
            </Button>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Indicadores" key="indicadores">
          <TabelaDeIndicadores />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ margin: 10 }}>
              <SheetJsInidicator
                tipo="indicadores"
                onUpdate={() => buscarDadosSeguranca("indicadores")}
              />
            </div>
            <Button
              variant="success"
              onClick={() => exportFile("indicadores")}
              style={{ marginBottom: 10, margin: 10 }}
            >
              Exportar exemplo
            </Button>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default InputDadosSeguranca;
