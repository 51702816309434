/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import style from "./style.css";
import LogisticService from "../../core/services/LogisticService";
import { Container, Form, Row, Col, Card, Button } from 'react-bootstrap'
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import LoadingSpinner from "../../components/LoadingSpinner";
import JSONToCSVConversor from "../../custom-components/JSONToCSVConversor/index.js";
import { SheetJSOms } from "../../custom-components/SheetJs/SheetJsOms";
import XLSX from "xlsx";
class PricingMovementOrders extends Component {
  constructor() {
    super();
    this.state = {
      today: moment().format("YYYY-MM-DD"),
      providerName: "",
      loaded: false,
      pendingOrders: undefined,
      lastUpdated: undefined,
      selectedRow: undefined,
    };
  }

  componentDidMount() {
    this.refreshReportData();
  }

  refreshReportData() {
    this.setState({
      today: moment().format("YYYY-MM-DD"),
      providerName: name,
      loaded: false,
      pendingOrders: undefined,
      modalIsOpen: false,
      advancesTable: undefined,
      advancesTableGroup: undefined,
      lastUpdated: moment(),
    });

    LogisticService.getPricingOMs().then((oms_CD) => {
      let advancesTable = this.buildAdvancesTable(oms_CD.data);

      this.setState({
        advancesTable: advancesTable,
        lastUpdated: moment(),
      });
    });

    LogisticService.getPricingOMsGroup().then((oms_CD) => {
      let advancesTableGroup = this.buildAdvancesTableGroup(oms_CD.data);

      this.setState({
        advancesTableGroup: advancesTableGroup,
        lastUpdated: moment(),
      });
    });

  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  downloadMovimentOrdersAnalysisGeral() {
    this.openModal();

    LogisticService.getMovimentationIncomingPending(
      "getMovimentationIncomingBase"
    ).then((pendingresponse) => {
      LogisticService.getMovimentationIncomingCD("noFilter").then(
        (cdresponse) => {
          LogisticService.getMovimentationIncomingBase("noFilter").then(
            (baseresponse) => {
              this.closeModal();
              JSONToCSVConversor(
                pendingresponse.data.data.concat(
                  cdresponse.data.data,
                  baseresponse.data.data
                ),
                `analitico de OMs`,
                true
              );
            }
          );
        }
      );
    });
  }

  buildAdvancesTable(oms) {
    let rows = [];
    oms.data.forEach((om) => {
      rows.push({
        orderNumber: om.orderNumber,
        origin: om.origin,
        transp: om.carrier,
        solicitaionDate: om.availableDate,
        qty: om.quantity,
      });
    });

    return (
      <MaterialTable
        title="OMs"
        columns={[
          {
            title: "OM",
            field: "orderNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Origem",
            field: "origin",
            cellStyle: { fontSize: "0.8em", whiteSpace: "nowrap" },
          },
          {
            title: "Transportadora",
            field: "transp",
            cellStyle: { fontSize: "0.8em", whiteSpace: "nowrap" },
          },
          { title: "Data de Solicitação", field: "solicitaionDate", cellStyle: { fontSize: "0.8em" } },
          { title: "Quantidade", field: "qty", cellStyle: { fontSize: "0.8em" } }
        ]}
        data={rows}
        options={{
          showTitle: true,
          exportAllData: true,
          pageSize: 7,
          padding: "dense",
          exportButton: true,

          // maxBodyHeight: 500,
          // headerStyle: {
          //   backgroundColor: '#103a21',
          //   color: '#FFF',
          //   textAlign: 'center'
          // }
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem OMs para mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
      />
    );
  }

  buildAdvancesTableGroup(oms) {
    let rows = [];
    oms.data.forEach((om) => {
      rows.push({
        origin: om.origin,
        qty: om.quantity,
      });
    });

    return (
      <MaterialTable
        title="Em Cotação"
        columns={[
          {
            title: "Origem",
            field: "origin",
            cellStyle: { fontSize: "0.8em", whiteSpace: "nowrap" },
          },
          { title: "Quantidade", field: "qty", cellStyle: { fontSize: "0.8em" } }
        ]}
        data={rows}
        options={{
          showTitle: true,
          exportAllData: true,
          pageSize: 6,
          padding: "dense",
          exportButton: true,

          // maxBodyHeight: 500,
          // headerStyle: {
          //   backgroundColor: '#103a21',
          //   color: '#FFF',
          //   textAlign: 'center'
          // }
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem OMs para mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
      />
    );
  }

  renderIncomingOMSCD() {
    if (this.state.advancesTable != undefined) {
      return (
        // <Box style={{margin: '1em'}}>
        this.state.advancesTable
        // </Box>
      );
    }
    return (
      <div style={{ margin: "2em", textAlign: "center" }}>
        <LoadingSpinner />
      </div>
    );
  }

  renderIncomingOMSCDGroup() {
    if (this.state.advancesTableGroup != undefined) {
      return (
        // <Box style={{margin: '1em'}}>
        this.state.advancesTableGroup
        // </Box>
      );
    }
    return (
      <div style={{ margin: "2em", textAlign: "center" }}>
        <LoadingSpinner />
      </div>
    );
  }


  renderModal() {
    return (
      <Modal
        open={open}
        onClose={this.closeModal.bind(this)}
        showCloseIcon={false}
      >
        <h2>Preparando seu download...</h2>
        <div
          style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}
        >
          <LoadingSpinner />
        </div>
      </Modal>
    );
  }

  exportFile() {
      let worksheet_example = [['OM', 'Transportadora'], [0,'EM COTACAO']]

      const ws = XLSX.utils.aoa_to_sheet(worksheet_example);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      XLSX.writeFile(wb, "Exemplo.xlsx");
  }

  render() {
    return (
      <Container fluid>
        <Row style={{marginTop: '3%'}}>
          <Col lg="3" sm="12" xs="12">
            <Button variant="success" onClick={this.exportFile} >Exportar planilha exemplo</Button>
          </Col>
          <Col lg="5" sm="12" xs="12">
            <p>Para alterar a transportadora massivamente, insira seu excel ao lado. <br/> Por favor, siga o padrão na planilha exemplo.</p>
          </Col>
          <Col lg="4" sm="12" xs="12">
            <SheetJSOms/>
          </Col>
          <Col lg="8" sm="12" xs="12">
            <div style={{ color: "black" }}>
                {this.renderIncomingOMSCD()}
            </div>
          </Col>
          <Col lg="4" sm="12" xs="12">
            <div style={{ color: "black" }}>
              {this.renderIncomingOMSCDGroup()}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PricingMovementOrders;
