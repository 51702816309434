import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import '../style.css'

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#103a21',
      color: 'white',
      fontSize: '1.1em',
      fontFamily: 'GoBold',
      textAlign: 'center'
    },
    body: {
      fontSize: '0.7em',
      textAlign: 'center'
    },
  }))(TableCell);

const StockPositionTable = props =>  {
      
        var {data, supplyChainPosition, consumptionData} = props;

        if (!data || !consumptionData || !supplyChainPosition) return null


        data = data.map(e => _.omit(e, ['Posição', 'Prestador', 'Status Estoque', 'Modelo', 'Operador Logístico', 'Contratante']))
        var newData = _.reverse(_.sortBy(_.filter(data, function(o) {return o['Posição Cadeia'] === supplyChainPosition }), ['qtd']))
        
        newData = _.groupBy(newData, function(o) { return o['Tecnologia'] })
        Object.keys(newData).forEach(tech => newData[tech] = _.sumBy(newData[tech], function(o) { return o['qtd'] }))

        var dataArray = []

        Object.keys(newData).forEach(key => dataArray.push({'Tecnologia': key, 'Quantidade': newData[key]}))

        const tableData = _.reverse(_.sortBy(dataArray, ['Quantidade'])).map(e => {

          const consumptionDataFiltered = _.find(consumptionData, function(o) { return o.technology === e['Tecnologia'] });
          const consumption = (!consumptionDataFiltered ? 0 : consumptionDataFiltered.consumption)
          let coverage = e['Quantidade'] / consumption
          if (coverage > 1000) {
            coverage = <AllInclusiveIcon/>
          } else {
            coverage = coverage.toFixed(1)
          }

          return (
            <TableRow hover>
              <StyledTableCell style={{backgroundColor: '#fff'}}>{e['Tecnologia']}</StyledTableCell>
              <StyledTableCell style={{backgroundColor: '#fff'}}>{(e['Quantidade']).toLocaleString('pt-br')}</StyledTableCell>
              <StyledTableCell style={{backgroundColor: '#fff'}}>{(consumption).toLocaleString('pt-br')}</StyledTableCell>
              <StyledTableCell style={{backgroundColor: '#fff'}}>{coverage}</StyledTableCell>
            </TableRow>
          )
        })


        return (
          <Paper style={{width: '100%', overflowX: 'auto', marginTop: '1em'}}>
            <Table size="small">
              <TableHead>
                <TableRow hover>
                  <StyledTableCell align="center" colSpan="5">
                      {supplyChainPosition}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover align='center'>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>MÁQUINAS (QTD)</StyledTableCell>
                  <StyledTableCell>CONSUMO DIÁRIO</StyledTableCell>
                  <StyledTableCell>COBERTURA (DIAS)</StyledTableCell>
                </TableRow>
                <TableRow hover>
                  <StyledTableCell style={{backgroundColor: '#99BC95', fontWeight: 'bold'}}>TOTAL</StyledTableCell>
                  <StyledTableCell style={{backgroundColor: '#99BC95', fontWeight: 'bold'}}>
                    {_.sumBy(dataArray, function(o) { return o['Quantidade']; }).toLocaleString('pt-br')}
                  </StyledTableCell>
                  <StyledTableCell style={{backgroundColor: '#99BC95', fontWeight: 'bold'}}>-</StyledTableCell>
                  <StyledTableCell style={{backgroundColor: '#99BC95', fontWeight: 'bold'}}>-</StyledTableCell>
                </TableRow>
                {tableData}
              </TableBody>
            </Table>
            </Paper>
        )
    }

export default StockPositionTable