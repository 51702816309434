import React from 'react'
import Graph from '../../../components/Logistic/graphic/graph'
import Box from '@material-ui/core/Box';
import _ from 'lodash'
import moment from 'moment'
import '../style.css'


const CoverageHistoryGraph = props =>  {
      
        const {coverageHistory} = props;

        if (!coverageHistory) return null

        const graphObj = {
          data: {
            labels: [],
            datasets: [
              {
                label: 'Cobertura Transp',
                backgroundColor: '#a6a6a6',
                borderColor: '#2c3e50',
                borderWidth: 0.5,
                hoverBackgroundColor: '#a6a6a6',
                hoverBorderColor: '#a6a6a6',
                data: [],
                type: 'line',
                pointRadius: 0,
              },
              {
                label: 'Cobertura Ponta',
                backgroundColor: '#053b1c',
                borderColor: '#2c3e50',
                borderWidth: 0.5,
                hoverBackgroundColor: '#053b1c',
                hoverBorderColor: '#053b1c',
                data: [],
                type: 'line',
                pointRadius: 0,
              },
              {
                label: 'Cobertura no CD',
                backgroundColor: '#a9d18e',
                borderColor: '#2c3e50',
                borderWidth: 0.5,
                hoverBackgroundColor: '#a9d18e',
                hoverBorderColor: '#a9d18e',
                data: [],
                type: 'line',
                pointRadius: 0,
              },
            ]
          },
          options: {
            responsive: undefined,
            maintainAspectRatio: false,
            tooltips: {
              mode: 'index',
              intersect: false,
            },
            scales: {
              xAxes: [{
                gridLines: {
                  display: false,
                },
              }],
              yAxes: [{
                stacked: true,
                ticks: {
                  min: 0,
                  callback: function (value) {
                               return value
                             },
                },
              }],
            },
            title: {
              display: true,
              fontColor: 'black',
              fontSize: 20,
              text: 'Cobertura Cadeia',
              fontFamily: 'GoBold'
            },
            plugins: {
              datalabels: {
                font: {
                  size: 0,
                },
                color: 'black',
              },
            },
            pan: {
              enabled: true,
              mode: 'x',
              speed: 200.2
            },
            zoom: {
              enabled: true,
              mode: 'x',
              speed: 0.9
            }
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: 'black',
            },
          },
        }
    
        _.orderBy(coverageHistory, 'date').forEach((element) => {
          graphObj.data.labels.push(moment(element.date).format('DD/MM/YYYY'))
          graphObj.data.datasets[0].data.push((element.carryingCoverage ).toFixed(1))
          graphObj.data.datasets[1].data.push((element.hubsCoverage     ).toFixed(1))
          graphObj.data.datasets[2].data.push((element.cdCoverage       ).toFixed(1))
        })
      
        const isDesktop =  window.innerWidth > 1300

        return (
            <Box width={isDesktop ? "80%" : "100%"} height="100%">
              <Graph
                data={graphObj.data}
                legend={graphObj.legend}
                options={graphObj.options}
                width={isDesktop ? window.innerWidth/2.2 : 300}
                height={isDesktop ? 500 : 400}
            />
            </Box>
          )
    }

export default CoverageHistoryGraph