/* eslint-disable */
import React, { Component } from "react";
import moment from "moment";
import LogisticService from "../../core/services/LogisticService";
import Graph from "../../components/Logistic/graphic/graph";
import { Col, Row } from "antd";
import LoadingSpinner from "../../components/LoadingSpinner";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#103a21",
    color: "white",
    fontSize: "1.1em",
    fontFamily: "GoBold",
  },
  body: {
    fontSize: "1.1em",
  },
}))(TableCell);

class CapexSection extends Component {
  constructor() {
    super();
    this.state = {
      today: moment().format("YYYY-MM-DD"),
      loaded: false,
      lastUpdated: undefined,
      efficiencyCapex: {
        graph: undefined,
        loaded: false,
      },
    };
  }

  componentDidMount() {
    this.refreshReportData();
  }

  refreshReportData() {
    LogisticService.getTerminalLosts().then((response) => {
      this.buildTerminalLostsGraph(response.data.data);
    });

    LogisticService.getCapexHistory().then((response) => {
      this.buildCapexEfficiencyHistory(response.data.data);
    });

    // LogisticService.getCapexSummary()
    // .then((response) => {
    //   this.setState({
    //     loading: false,
    //     lastUpdated: moment(),
    //     summary: response.data.data,
    //     parque: response.data.data,
    //
    //   })
    // })

    LogisticService.getCapexSummaryRefactor().then((response) => {
      const summary = response.data.data;
      const stoneSummary = summary.filter((a) =>
        ["ELAVON BRASIL", "STONE PAGAMENTOS"].includes(a["Contratante"])
      );
      this.setState({ summary: stoneSummary });
    });
  }

  buildCapexEfficiencyHistory(obj) {
    const graphObj = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Meta",
            stack: "Stack 0",
            data: [],
            type: "line",
            pointRadius: 0,
            fill: false,
            backgroundColor: "red",
            borderColor: "red",
            hoverBackgroundColor: "red",
            hoverBorderColor: "red",
          },
          {
            label: "Eficiência",
            stack: "Stack 1",
            backgroundColor: "#18641e",
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
              ticks: {
                min: "01-2018",
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
              },
              ticks: {
                min: 50,
                max: 75,
              },
            },
          ],
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        title: {
          display: true,
          fontColor: "#2e3136",
          fontSize: 20,
          text: "Evolução da Eficiência",
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0;
            },
            font: {
              size: 10,
            },
            color: "",
            formatter: function (value) {
              if (value >= 1000) {
                return String((value / 1000).toFixed(1)) + "k";
              } else {
                return value;
              }
            },
            align: "start",
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "black",
          usePointStyle: true,
        },
      },
    };

    _.orderBy(obj, "date").forEach((element) => {
      graphObj.data.labels.push(moment(element.date).format("MM-YYYY"));
      graphObj.data.datasets[1].data.push(element.efficiency.toFixed(1));
      graphObj.data.datasets[0].data.push(element.goal);
    });

    this.setState({
      efficiencyCapex: {
        graph: graphObj,
        loaded: true,
      },
      lastUpdated: moment(),
    });
  }

  buildTerminalLostsGraph(obj) {
    const graphObj = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Meta",
            stack: "Stack 1",
            backgroundColor: "red",
            borderWidth: 2,
            data: [],
            type: "line",
            fill: false,
            backgroundColor: "red",
            borderColor: "red",
            hoverBackgroundColor: "red",
            hoverBorderColor: "red",
            // borderColor: 'red',
            // backgroundColor: 'red',
            // pointBorderColor: 'red',
            // pointBackgroundColor: 'red',
            // pointHoverBackgroundColor: 'red',
            // pointHoverBorderColor: 'red',
            // pointRadius: 3,
            // pointHoverRadius: 5,
          },
          {
            label: "Realizado",
            stack: "Stack 0",
            backgroundColor: "#18641E",
            borderWidth: 2,
            data: [],
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              type: "linear",
              stacked: false,
              gridLines: {
                display: true,
              },
              ticks: {
                callback: function t(value) {
                  return `${value}%`;
                },
                suggestedMax: 1.2,
              },
            },
          ],
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        title: {
          display: true,
          fontColor: "#2e3136",
          fontSize: 20,
          text: "% Perdas",
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0
                ? "auto"
                : false;
            },
            anchor: "end",
            align: "top",
            font: {
              size: 11,
              weight: "bold",
            },
            color: "black",
            formatter: function (value) {
              return `${value}%`;
            },
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "black",
          usePointStyle: true,
        },
      },
    };

    _.orderBy(obj, "date").forEach((element) => {
      graphObj.data.labels.push(element.date);
      graphObj.data.datasets[1].data.push(element.realized);
      graphObj.data.datasets[0].data.push(element.goal);
    });

    this.setState({
      terminalLosts: {
        graph: graphObj,
        loaded: true,
      },
      lastUpdated: moment(),
    });
  }

  renderCapexEfficiencyHistory() {
    if (this.state.efficiencyCapex.loaded) {
      return (
        <div>
          <Graph
            data={this.state.efficiencyCapex.graph.data}
            legend={this.state.efficiencyCapex.graph.legend}
            options={this.state.efficiencyCapex.graph.options}
            width={this.state.isDesktop ? 500 : 350}
            height={this.state.isDesktop ? 400 : 350}
          />
        </div>
      );
    }
  }

  renderTerminalLostsGraph() {
    if (this.state.terminalLosts.loaded) {
      return (
        <div>
          <Graph
            data={this.state.terminalLosts.graph.data}
            legend={this.state.terminalLosts.graph.legend}
            options={this.state.terminalLosts.graph.options}
            width={this.state.isDesktop ? 500 : 350}
            height={this.state.isDesktop ? 400 : 350}
          />
        </div>
      );
    }
  }

  summarySection() {
    const { summary } = this.state;

    if (summary !== undefined) {
      const baixoGiro = summary.filter(
        (a) =>
          a["Posição Cadeia"] !== "INDISPONIVEL" &&
          a["Tecnologia"] !== "GPRS-WIFI"
      );
      const altoGiro = summary.filter(
        (a) =>
          a["Posição Cadeia"] !== "INDISPONIVEL" &&
          a["Tecnologia"] === "GPRS-WIFI"
      );

      const baixoGiroInstalado = _.sumBy(
        baixoGiro.filter((a) => a["Posição Cadeia"] === "EM PRODUÇÃO"),
        function (o) {
          return o["qtd"];
        }
      );
      const baixoGiroNaoInstalado = _.sumBy(
        baixoGiro.filter((a) => a["Posição Cadeia"] !== "EM PRODUÇÃO"),
        function (o) {
          return o["qtd"];
        }
      );

      const baixoGiroAtivo = _.sumBy(
        baixoGiro.filter((a) => a["Posição"] === "CLIENTE ATIVO"),
        function (o) {
          return o["qtd"];
        }
      );
      const altoGiroAtivo = _.sumBy(
        altoGiro.filter((a) => a["Posição"] === "CLIENTE ATIVO"),
        function (o) {
          return o["qtd"];
        }
      );

      const altoGiroInstalado = _.sumBy(
        altoGiro.filter((a) => a["Posição Cadeia"] === "EM PRODUÇÃO"),
        function (o) {
          return o["qtd"];
        }
      );
      const altoGiroNaoInstalado = _.sumBy(
        altoGiro.filter((a) => a["Posição Cadeia"] !== "EM PRODUÇÃO"),
        function (o) {
          return o["qtd"];
        }
      );

      const goodBaixoGiro = _.sumBy(
        baixoGiro.filter((a) =>
          ["AVANÇO", "DISPONÍVEL BASE", "DISPONÍVEL CD"].includes(
            a["Posição Cadeia"]
          )
        ),
        function (o) {
          return o["qtd"];
        }
      );
      const goodAltoGiro = _.sumBy(
        altoGiro.filter((a) =>
          ["AVANÇO", "DISPONÍVEL BASE", "DISPONÍVEL CD"].includes(
            a["Posição Cadeia"]
          )
        ),
        function (o) {
          return o["qtd"];
        }
      );

      const badBaixoGiro = _.sumBy(
        baixoGiro.filter((a) =>
          ["BAD CD", "CICLO TRIAGEM", "CICLO LAB"].includes(a["Posição Cadeia"])
        ),
        function (o) {
          return o["qtd"];
        }
      );
      const badAltoGiro = _.sumBy(
        altoGiro.filter((a) =>
          ["BAD CD", "CICLO TRIAGEM", "CICLO LAB"].includes(a["Posição Cadeia"])
        ),
        function (o) {
          return o["qtd"];
        }
      );

      const divergenciaBaixoGiro = _.sumBy(
        baixoGiro.filter((a) => a["Posição Cadeia"] === "DIVERGÊNCIA"),
        function (o) {
          return o["qtd"];
        }
      );
      const divergenciaAltoGiro = _.sumBy(
        altoGiro.filter((a) => a["Posição Cadeia"] === "DIVERGÊNCIA"),
        function (o) {
          return o["qtd"];
        }
      );

      return (
        <Table size="small">
          <TableHead>
            <TableRow hover>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center">BAIXO GIRO</StyledTableCell>
              <StyledTableCell align="center">ALTO GIRO</StyledTableCell>
              <StyledTableCell align="center">TOTAL</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <StyledTableCell align="left" style={{ backgroundColor: "#fff" }}>
                EFICIÊNCIA DA CADEIA
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#fff" }}
              >
                {(
                  (baixoGiroAtivo * 100) /
                  (baixoGiroInstalado + badBaixoGiro + goodBaixoGiro)
                ).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#fff" }}
              >
                {(
                  (altoGiroAtivo * 100) /
                  (altoGiroInstalado + badAltoGiro + goodAltoGiro)
                ).toFixed(2)}
                %
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#fff" }}
              >
                {(
                  ((altoGiroAtivo + baixoGiroAtivo) /
                    (altoGiroInstalado +
                      baixoGiroInstalado +
                      badAltoGiro +
                      goodAltoGiro +
                      badBaixoGiro +
                      goodBaixoGiro)) *
                  100
                ).toFixed(2)}
                %
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell align="left" style={{ backgroundColor: "#fff" }}>
                PARQUE TOTAL
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#fff" }}
              >
                {(
                  baixoGiroInstalado +
                  badBaixoGiro +
                  goodBaixoGiro
                ).toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#fff" }}
              >
                {(
                  altoGiroInstalado +
                  +badAltoGiro +
                  goodAltoGiro
                ).toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#fff" }}
              >
                {(
                  altoGiroInstalado +
                  baixoGiroInstalado +
                  goodBaixoGiro +
                  badBaixoGiro +
                  goodAltoGiro +
                  badAltoGiro
                ).toLocaleString("pt")}
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell
                align="left"
                style={{ backgroundColor: "#99bc95" }}
              >
                INSTALADOS
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#99bc95" }}
              >
                {baixoGiroInstalado.toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#99bc95" }}
              >
                {altoGiroInstalado.toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#99bc95" }}
              >
                {(baixoGiroInstalado + altoGiroInstalado).toLocaleString("pt")}
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell>ATIVO</StyledTableCell>
              <StyledTableCell align="center">
                {baixoGiroAtivo.toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {altoGiroAtivo.toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {(baixoGiroAtivo + altoGiroAtivo).toLocaleString("pt")}
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell>0 TPV</StyledTableCell>
              <StyledTableCell align="center">
                {(baixoGiroInstalado - baixoGiroAtivo).toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {(altoGiroInstalado - altoGiroAtivo).toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {(
                  baixoGiroInstalado -
                  baixoGiroAtivo +
                  (altoGiroInstalado - altoGiroAtivo)
                ).toLocaleString("pt")}
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell
                align="left"
                style={{ backgroundColor: "#99bc95" }}
              >
                NÃO INSTALADOS
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#99bc95" }}
              >
                {(goodBaixoGiro + badBaixoGiro).toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#99bc95" }}
              >
                {(goodAltoGiro + badAltoGiro).toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ backgroundColor: "#99bc95" }}
              >
                {(
                  goodBaixoGiro +
                  badBaixoGiro +
                  goodAltoGiro +
                  badAltoGiro
                ).toLocaleString("pt")}
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell>CICLO GOOD</StyledTableCell>
              <StyledTableCell align="center">
                {goodBaixoGiro.toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {goodAltoGiro.toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {(goodBaixoGiro + goodAltoGiro).toLocaleString("pt")}
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell>CICLO BAD</StyledTableCell>
              <StyledTableCell align="center">
                {badBaixoGiro.toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {badAltoGiro.toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {(badBaixoGiro + badAltoGiro).toLocaleString("pt")}
              </StyledTableCell>
            </TableRow>
            <TableRow hover>
              <StyledTableCell>DIVERGÊNCIA</StyledTableCell>
              <StyledTableCell align="center">
                {divergenciaBaixoGiro.toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {divergenciaAltoGiro.toLocaleString("pt")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {(divergenciaBaixoGiro + divergenciaAltoGiro).toLocaleString(
                  "pt"
                )}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
  }

  render() {
    if (
      this.state.efficiencyCapex.loaded &&
      this.state.terminalLosts.loaded &&
      this.state.summary !== undefined
    ) {
      return (
        <div>
          <Row
            gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}
            style={{ marginTop: "1em" }}
          >
            <Col span={12}>{this.renderCapexEfficiencyHistory()}</Col>
            <Col span={12}>{this.renderTerminalLostsGraph()}</Col>
          </Row>
          <Row
            gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}
            style={{ marginTop: "1em" }}
          >
            <Col span={6} />
            <Col span={12}>{this.summarySection()}</Col>
            <Col span={6} />
          </Row>
        </div>
      );
    }
    return (
      <Table size="small">
        <TableHead>
          <TableRow hover>
            <StyledTableCell align="center"></StyledTableCell>
            <StyledTableCell align="center">BAIXO GIRO</StyledTableCell>
            <StyledTableCell align="center">ALTO GIRO</StyledTableCell>
            <StyledTableCell align="center">TOTAL</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover>
            <StyledTableCell align="left" style={{ backgroundColor: "#fff" }}>
              EFICIÊNCIA DA CADEIA
            </StyledTableCell>
            <StyledTableCell align="center" style={{ backgroundColor: "#fff" }}>
              {(
                (baixoGiroAtivo * 100) /
                (baixoGiroInstalado + badBaixoGiro + goodBaixoGiro)
              ).toFixed(2)}
              %
            </StyledTableCell>
            <StyledTableCell align="center" style={{ backgroundColor: "#fff" }}>
              {(
                (altoGiroAtivo * 100) /
                (altoGiroInstalado + badAltoGiro + goodAltoGiro)
              ).toFixed(2)}
              %
            </StyledTableCell>
            <StyledTableCell align="center" style={{ backgroundColor: "#fff" }}>
              {(
                ((altoGiroAtivo + baixoGiroAtivo) /
                  (altoGiroInstalado +
                    baixoGiroInstalado +
                    badAltoGiro +
                    goodAltoGiro +
                    badBaixoGiro +
                    goodBaixoGiro +
                    divergenciaBaixoGiro +
                    divergenciaAltoGiro)) *
                100
              ).toFixed(2)}
              %
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell align="left" style={{ backgroundColor: "#fff" }}>
              PARQUE TOTAL
            </StyledTableCell>
            <StyledTableCell align="center" style={{ backgroundColor: "#fff" }}>
              {(
                baixoGiroInstalado +
                badBaixoGiro +
                goodBaixoGiro
              ).toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center" style={{ backgroundColor: "#fff" }}>
              {(altoGiroInstalado + +badAltoGiro + goodAltoGiro).toLocaleString(
                "pt"
              )}
            </StyledTableCell>
            <StyledTableCell align="center" style={{ backgroundColor: "#fff" }}>
              {(
                altoGiroInstalado +
                baixoGiroInstalado +
                goodBaixoGiro +
                badBaixoGiro +
                goodAltoGiro +
                badAltoGiro
              ).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell
              align="left"
              style={{ backgroundColor: "#99bc95" }}
            >
              INSTALADOS
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ backgroundColor: "#99bc95" }}
            >
              {baixoGiroInstalado.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ backgroundColor: "#99bc95" }}
            >
              {altoGiroInstalado.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ backgroundColor: "#99bc95" }}
            >
              {(baixoGiroInstalado + altoGiroInstalado).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell>ATIVO</StyledTableCell>
            <StyledTableCell align="center">
              {baixoGiroAtivo.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {altoGiroAtivo.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {(baixoGiroAtivo + altoGiroAtivo).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell>0 TPV</StyledTableCell>
            <StyledTableCell align="center">
              {(baixoGiroInstalado - baixoGiroAtivo).toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {(altoGiroInstalado - altoGiroAtivo).toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {(
                baixoGiroInstalado -
                baixoGiroAtivo +
                (altoGiroInstalado - altoGiroAtivo)
              ).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell
              align="left"
              style={{ backgroundColor: "#99bc95" }}
            >
              NÃO INSTALADOS
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ backgroundColor: "#99bc95" }}
            >
              {(goodBaixoGiro + badBaixoGiro).toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ backgroundColor: "#99bc95" }}
            >
              {(goodAltoGiro + badAltoGiro).toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ backgroundColor: "#99bc95" }}
            >
              {(
                goodBaixoGiro +
                badBaixoGiro +
                goodAltoGiro +
                badAltoGiro
              ).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell>CICLO GOOD</StyledTableCell>
            <StyledTableCell align="center">
              {goodBaixoGiro.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {goodAltoGiro.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {(goodBaixoGiro + goodAltoGiro).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell>CICLO BAD</StyledTableCell>
            <StyledTableCell align="center">
              {badBaixoGiro.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {badAltoGiro.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {(badBaixoGiro + badAltoGiro).toLocaleString("pt")}
            </StyledTableCell>
          </TableRow>
          <TableRow hover>
            <StyledTableCell>DIVERGÊNCIA</StyledTableCell>
            <StyledTableCell align="center">
              {divergenciaBaixoGiro.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {divergenciaAltoGiro.toLocaleString("pt")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {(divergenciaBaixoGiro + divergenciaAltoGiro).toLocaleString(
                "pt"
              )}
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default CapexSection;
