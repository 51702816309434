/*global google*/
import React, { Component } from 'react';
const { compose, withProps } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
} = require("react-google-maps");
const { DrawingManager } = require("react-google-maps/lib/components/drawing/DrawingManager");

const findPath = (data) => {
  if (typeof data != 'object'){
    return []
  }
  else{
    return Object.keys(data)
  }
}


const MapWithADrawingManager = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDLrUDA6WaLk5PVutJV2h2-TOoPe894TMA&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement:<div style={{ height: '72vh' }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    defaultZoom={10}
    defaultCenter={new google.maps.LatLng(props.center.lat, props.center.long)}

  >
    <DrawingManager
     onPolygonComplete={e => {
       console.log(Object.keys(e.getPath())[0])
       console.log(Object.values(e.getPath())[0])
        props.saveNewPolygon(Object.values(e.getPath())[0],
        props.routesTerritories, props.name, props.district)
      }
      }
      editable={true}
      defaultOptions={{
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON
          ],
        },


      }}
    />
    {props.polys}
    <div id="legend" style={{"zIndex": 0, "position": "absolute", "top": "50vh", "left": "1vw", "backgroundColor":"white", "width":"13vw"}}>
        <h3 style={{marginLeft:"2em"}}>Legenda</h3>
        {props.leg}
    </div>
  </GoogleMap>
);



export default MapWithADrawingManager
