import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import LogisticService from '../../core/services/LogisticService'
import {Col} from 'react-bootstrap'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomizedTable from '../../custom-components/CustomizedTable'
import  IconCard   from   './img/Icon-card.png'
import  Email  from './img/Icon-email.png'
import  IconPerson  from './img/Icon-person.png'
import  Anticipation from './img/baseline.png'
import  Computer from './img/computer.png'

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: 'white',
      color: 'dark',
      fontSize: '0.9em',
    },
    body: {
      fontSize: '0.8em'
    },
  }))(TableCell);

class SummaryTable extends Component {

    constructor(props) {
  
        super(props)
    
        this.state = {
            stonecode: props.stonecode,
            customerEmail:undefined,
            customerLastAccess:undefined,
            lastTransactionData:undefined,
            automaticReceivable:undefined,
            statusData: undefined
        }
    
        this.refreshReportData = this.refreshReportData.bind(this)
      }
    
    refreshReportData() {
        this.setState({
            customerEmail:undefined,
            customerLastAccess:undefined,
            lastTransactionData:undefined,
            automaticReceivable:undefined,
            statusData: undefined
        });
    
        LogisticService.getPortalUsers(this.state.stonecode).then((response) => {
          // console.log(portalUsersInfo)
          let portalUsersInfo = response.data.data;
          var lastAccessDate = "Sem acesso"

          if(portalUsersInfo != undefined){
            LogisticService.getPortalUserLastAcess(portalUsersInfo[0]['email']).then((accessInfo) => {
              // console.log(accessInfo)
              if (accessInfo.data !== undefined && accessInfo.data.data !== undefined && accessInfo.data.data.lastAccessDate !== null) {
                lastAccessDate = accessInfo.data.data.lastAccessDate.substring(0,10)
              }
              this.setState({
                customerEmail: portalUsersInfo[0]['email'],
                lastAccessDate: lastAccessDate
              });
  
            })
          }
        });

        LogisticService.getStatus(this.state.stonecode).then((statusInfo) => {
            // console.log(status)
            var customerStatus = ''  
            var status = statusInfo.data.data
            if(status != undefined){
              customerStatus = status['description']
            }
            this.setState({
              statusData: customerStatus
            });
        })

        LogisticService.getLastTransaction(this.state.stonecode).then((statusTransaction) => {
            // console.log(statusTransaction)
            var customerTransactionDate = ''
            var transaction = statusTransaction.data.data
            if(transaction != undefined && transaction.date != undefined){
              customerTransactionDate = transaction.date.substring(0,10)
            }
            this.setState({
              lastTransactionData: customerTransactionDate
            });
        })

        LogisticService.getRAVSearch(this.state.stonecode).then((statusRAV) => {
            // console.log(statusRAV)
            var customerStatusRAV = 'Desativada'
            var rav = statusRAV.data.data
            if(rav != undefined && rav[0] != undefined){
              var customerRAV = rav[0]['hasAutomaticReceivableAdvance']
              if(customerRAV == true){
                customerStatusRAV = 'Ativada'
              }
            }
            this.setState({
              automaticReceivable: customerStatusRAV
            });
        })
    }
    
    componentDidMount() {
      this.refreshReportData()
    }


    renderCustomerSummary () {
        let rowsSummary = []
          rowsSummary.push(
                [<TableRow>
                <StyledTableCell>
                <img src={Email} alt="email" className="iconClient" /> Email</StyledTableCell>
                <StyledTableCell>{this.state.customerEmail}</StyledTableCell>
                </TableRow>,
                <TableRow>
                <StyledTableCell>
                <img src={Computer} alt="Card" className="iconClientComputer" /> Último acesso ao portal</StyledTableCell>
                <StyledTableCell>{this.state.lastAccessDate}</StyledTableCell>
                </TableRow>,
                <TableRow>
                <StyledTableCell>
                <img src={IconCard} alt="Card" className="iconClient" /> Data da última transação</StyledTableCell>
                <StyledTableCell>{this.state.lastTransactionData}</StyledTableCell>
                </TableRow>,
                <TableRow>
                <StyledTableCell>
                <img src={Anticipation} alt="Anticipation" className="iconClient" /> Antecipação Automática</StyledTableCell>
                <StyledTableCell>{this.state.automaticReceivable}</StyledTableCell>
                </TableRow>]
            )
    
        //Title
        let titleSummary = [{name:"Stonecode - "+this.state.stonecode,icon:"" }]
    
        //Sub Titles
        let subTitleSummary = [{name:"  Status de Cadastro", icon:<img src={IconPerson} alt="IconPerson" className="iconClient" />},{name:this.state.statusData, icon:""}]
    
        return (
          <CustomizedTable
           rows ={rowsSummary}
           title ={titleSummary}
           subTitle ={subTitleSummary}
           colSpan ={"2"}
           >
         </CustomizedTable>
        )
      }

    render(){        
            return(
              <Col className="customizedTable left" xs sm md = "12" lg xl = "6" align="left" style={{marginTop: '5px' }}>
                {this.renderCustomerSummary()}
              </Col>
            )
        
    }

}

export default SummaryTable