import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faMotorcycle,
  faMapMarkedAlt,
  faGlobeAmericas,
  faTrophy,
  faShieldAlt,
  faBoxOpen,
} from "@fortawesome/free-solid-svg-icons";
import { AuthMiddleware } from 'stonelog-auth-components';
import { BrowserRouter } from "react-router-dom";

import routes from "./routes";
import "./styles/index.css";
import "./index.css";
import StoneNavBar from "./custom-components/NavBar";
import config from "./core/services/appConfig";

// Font Awesome
library.add(fab, faMotorcycle, faMapMarkedAlt, faGlobeAmericas, faTrophy, faShieldAlt, faBoxOpen);

const App = () => {
  const loginRoutes = [
    "/login",
    "/logout",
    "/callback"
  ]

  const containerPadding = loginRoutes.includes(window.location.pathname) ? "0em 0em 0em 0em" : "5em 0em 0em 0em";

  return (
    <BrowserRouter>
      <AuthMiddleware authConfig={{
        authApiURL: `${config.logisticsUrl}/api/auth/{function}`,
        afterLoginUrlRoute: '/reports/last-mile'
      }}>
        {loginRoutes.includes(window.location.pathname) ? <></> : <StoneNavBar /> }
        <div style={{ padding: containerPadding }}>{routes}</div>
      </AuthMiddleware>
    </BrowserRouter>
  );
};

export default App;
