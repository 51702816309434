/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import style from "./style.css";
import LogisticService from "../../core/services/LogisticService";
import { Container } from "react-bootstrap";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import LoadingSpinner from "../../components/LoadingSpinner";
import JSONToCSVConversor from "../../custom-components/JSONToCSVConversor/index.js";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
class MovementOrdersDetail extends Component {
  constructor() {
    super();
    this.state = {
      today: moment().format("YYYY-MM-DD"),
      providerName: "",
      loaded: false,
      pendingOrders: undefined,
      lastUpdated: undefined,
      selectedRow: undefined,
    };
  }

  componentDidMount() {
    this.refreshReportData();
  }

  refreshReportData() {
    this.setState({
      today: moment().format("YYYY-MM-DD"),
      providerName: name,
      loaded: false,
      pendingOrders: undefined,
      modalIsOpen: false,
      advancesTable: undefined,
      reversesTable: undefined,
      divergencesTable: undefined,
      lastUpdated: moment(),
    });

    LogisticService.getMovimentationIncomingCD("filter").then((oms_CD) => {
      let advancesTable = this.buildAdvancesTable(oms_CD.data);

      this.setState({
        advancesTable: advancesTable,
        lastUpdated: moment(),
      });
    });

    LogisticService.getMovimentationIncomingBase("filter").then((oms_Base) => {
      const reversesTable = this.buildReversesTable(oms_Base.data);

      this.setState({
        reversesTable: reversesTable,
        lastUpdated: moment(),
      });
    });

    LogisticService.getMovimentationIncomingPending("filter").then(
      (oms_Pending) => {
        let divergencesTable = this.buildDivergencesTable(oms_Pending.data);

        this.setState({
          divergencesTable: divergencesTable,
          lastUpdated: moment(),
        });
      }
    );
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  downloadMovimentOrdersAnalysisGeral() {
    this.openModal();

    LogisticService.getMovimentationIncomingPending(
      "getMovimentationIncomingBase"
    ).then((pendingresponse) => {
      LogisticService.getMovimentationIncomingCD("noFilter").then(
        (cdresponse) => {
          LogisticService.getMovimentationIncomingBase("noFilter").then(
            (baseresponse) => {
              this.closeModal();
              JSONToCSVConversor(
                pendingresponse.data.data.concat(
                  cdresponse.data.data,
                  baseresponse.data.data
                ),
                `analitico de OMs`,
                true
              );
            }
          );
        }
      );
    });
  }

  buildAdvancesTable(oms) {
    let rows = [];
    oms.data.forEach((om) => {
      rows.push({
        orderNumber: om.orderNumber,
        status: om.status,
        transitDate: om.transitDate,
        limitdate: om.limitdate,
        origin: om.origin,
        destination: om.destination,
        s920: om.s920,
        d210n: om.d210n,
        gprs: om.gprs,
        pinpad: om.pinpad,
        bluetooth: om.bluetooth,
        ethernet: om.ethernet,
        vivo: om.vivo,
        claro: om.claro,
        oi: om.oi,
        tim: om.tim,
        arqia: om.arqia,
        kit01: om.kit01,
        kit14: om.kit14,
        kit06: om.kit06,
        kit150: om.kit150,
        collact: om.collact,
      });
    });

    return (
      <MaterialTable
        title="Avanços"
        columns={[
          {
            title: "Número da OM",
            field: "orderNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Status",
            field: "status",
            cellStyle: { fontSize: "0.8em", whiteSpace: "nowrap" },
          },
          {
            title: "Data de Trânsito",
            field: "transitDate",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Data Limite",
            field: "limitdate",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Origem",
            field: "origin",
            cellStyle: { fontSize: "0.8em", whiteSpace: "nowrap" },
          },
          {
            title: "Destino",
            field: "destination",
            cellStyle: { fontSize: "0.8em", whiteSpace: "nowrap" },
          },
          { title: "S920", field: "s920", cellStyle: { fontSize: "0.8em" } },
          { title: "D210N", field: "d210n", cellStyle: { fontSize: "0.8em" } },
          { title: "GPRS", field: "gprs", cellStyle: { fontSize: "0.8em" } },
          {
            title: "PINPAD",
            field: "pinpad",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Bluetooth",
            field: "bluetooth",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Ethernet",
            field: "ethernet",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Chip Vivo",
            field: "vivo",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Chip Claro",
            field: "claro",
            cellStyle: { fontSize: "0.8em" },
          },
          { title: "Chip Oi", field: "oi", cellStyle: { fontSize: "0.8em" } },
          { title: "Chip Tim", field: "tim", cellStyle: { fontSize: "0.8em" } },
          {
            title: "Chip Arqia",
            field: "arqia",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Kit Unitário",
            field: "kit01",
            cellStyle: { fontSize: "0.8em" },
          },
          { title: "Kit 06", field: "kit06", cellStyle: { fontSize: "0.8em" } },
          {
            title: "Kit 150",
            field: "kit150",
            cellStyle: { fontSize: "0.8em" },
          },
          { title: "Kit 14", field: "kit14", cellStyle: { fontSize: "0.8em" } },
          {
            title: "Kit Collact",
            field: "collact",
            cellStyle: { fontSize: "0.8em" },
          },
        ]}
        data={rows}
        options={{
          showTitle: false,
          exportAllData: true,
          pageSize: 10,
          padding: "dense",
          exportButton: true,

          // maxBodyHeight: 500,
          // headerStyle: {
          //   backgroundColor: '#103a21',
          //   color: '#FFF',
          //   textAlign: 'center'
          // }
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem OMs para mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
      />
    );
  }

  buildReversesTable(oms) {
    let rows = [];
    oms.data.forEach((om) => {
      rows.push({
        orderNumber: om.orderNumber,
        status: om.status,
        transitDate: om.transitDate,
        limitdate: om.limitdate,
        origin: om.origin,
        destination: om.destination,
        s920: om.s920,
        d210n: om.d210n,
        gprs: om.gprs,
        pinpad: om.pinpad,
        bluetooth: om.bluetooth,
        ethernet: om.ethernet,
        vivo: om.vivo,
        claro: om.claro,
        oi: om.oi,
        tim: om.tim,
        arqia: om.arqia,
        kit01: om.kit01,
        kit14: om.kit14,
        kit06: om.kit06,
        kit150: om.kit150,
        collact: om.collact,
      });
    });

    return (
      <MaterialTable
        title="Reversas"
        columns={[
          {
            title: "Número da OM",
            field: "orderNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Status",
            field: "status",
            hidden: true,
            cellStyle: { fontSize: "0.8em", whiteSpace: "nowrap" },
          },
          {
            title: "Data de Trânsito",
            field: "transitDate",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Data Limite",
            field: "limitdate",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Origem",
            field: "origin",
            cellStyle: { fontSize: "0.8em", whiteSpace: "nowrap" },
          },
          {
            title: "Destino",
            field: "destination",
            cellStyle: { fontSize: "0.8em", whiteSpace: "nowrap" },
          },
          { title: "S920", field: "s920", cellStyle: { fontSize: "0.8em" } },
          { title: "D210N", field: "d210n", cellStyle: { fontSize: "0.8em" } },
          { title: "GPRS", field: "gprs", cellStyle: { fontSize: "0.8em" } },
          {
            title: "PINPAD",
            field: "pinpad",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Bluetooth",
            field: "bluetooth",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Ethernet",
            field: "ethernet",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Chip Vivo",
            field: "vivo",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Chip Claro",
            field: "claro",
            cellStyle: { fontSize: "0.8em" },
          },
          { title: "Chip Oi", field: "oi", cellStyle: { fontSize: "0.8em" } },
          { title: "Chip Tim", field: "tim", cellStyle: { fontSize: "0.8em" } },
          {
            title: "Chip Arqia",
            field: "arqia",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Kit Unitário",
            field: "kit01",
            cellStyle: { fontSize: "0.8em" },
          },
          { title: "Kit 06", field: "kit06", cellStyle: { fontSize: "0.8em" } },
          {
            title: "Kit 150",
            field: "kit150",
            cellStyle: { fontSize: "0.8em" },
          },
          { title: "Kit 14", field: "kit14", cellStyle: { fontSize: "0.8em" } },
          {
            title: "Kit Collact",
            field: "collact",
            cellStyle: { fontSize: "0.8em" },
          },
        ]}
        data={rows}
        options={{
          showTitle: false,
          exportAllData: true,
          pageSize: 10,
          padding: "dense",
          exportButton: true,

          // maxBodyHeight: 500,

          // headerStyle: {
          //     backgroundColor: '#103a21',
          //     color: '#FFF'
          //     }
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem OMs para mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
      />
    );
  }

  buildDivergencesTable(oms) {
    let rows = [];
    oms.data.forEach((om) => {
      rows.push({
        orderNumber: om.orderNumber,
        type: om.type,
        status: om.status,
        createdDate: om.createdDate,
        transitDate: om.transitDate,
        origin: om.origin,
        destination: om.destination,
      });
    });

    return (
      <MaterialTable
        title="OMs Pendentes de Revisão"
        columns={[
          {
            title: "Número da OM",
            field: "orderNumber",
            type: "numeric",
            cellStyle: { fontSize: "0.8em" },
          },
          { title: "Tipo", field: "type", cellStyle: { fontSize: "0.8em" } },
          {
            title: "Status",
            field: "status",
            cellStyle: { fontSize: "0.8em", whiteSpace: "nowrap" },
          },
          {
            title: "Data de Criação",
            field: "createdDate",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Data de Trânsito",
            field: "transitDate",
            cellStyle: { fontSize: "0.8em" },
          },
          {
            title: "Origem",
            field: "origin",
            cellStyle: { fontSize: "0.8em", whiteSpace: "nowrap" },
          },
          {
            title: "Destino",
            field: "destination",
            cellStyle: { fontSize: "0.8em", whiteSpace: "nowrap" },
          },
        ]}
        data={rows}
        options={{
          showTitle: false,
          exportAllData: true,
          pageSize: 10,
          padding: "dense",
          exportButton: true,
          toolbar: true,
          // maxBodyHeight: 500,
          rowStyle: (rowData) => ({
            backgroundColor:
              this.state.selectedRow &&
              this.state.selectedRow.tableData.id === rowData.tableData.id
                ? "#EEE"
                : "#FFF",
          }),
          // headerStyle: {
          //   backgroundColor: '#103a21',
          //   color: '#FFF'
          // },
        }}
        localization={{
          toolbar: {
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem OMs para mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
      />
    );
  }

  renderIncomingOMSCD() {
    if (this.state.advancesTable != undefined) {
      return (
        // <Box style={{margin: '1em'}}>
        this.state.advancesTable
        // </Box>
      );
    }
    return (
      <div style={{ margin: "2em", textAlign: "center" }}>
        <LoadingSpinner />
      </div>
    );
  }

  renderIncomingOMSBase() {
    if (this.state.reversesTable != undefined) {
      return this.state.reversesTable;
    }
    return (
      <div style={{ margin: "2em", textAlign: "center" }}>
        <LoadingSpinner />
      </div>
    );
  }

  renderIncomingOMSPending() {
    if (this.state.divergencesTable != undefined) {
      return this.state.divergencesTable;
    }
    return (
      <div style={{ margin: "2em", textAlign: "center" }}>
        <LoadingSpinner />
      </div>
    );
  }

  renderModal() {
    return (
      <Modal
        open={open}
        onClose={this.closeModal.bind(this)}
        showCloseIcon={false}
      >
        <h2>Preparando seu download...</h2>
        <div
          style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}
        >
          <LoadingSpinner />
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <Container fluid>
        <div style={{ color: "black" }}>
          <Tabs
            defaultActiveKey="advances"
            id="noanim-tab-example"
            variant="tabs"
            style={{ backgroundColor: "#103a21", color: "#fff" }}
          >
            <Tab eventKey="advances" title="Avanços" variant="success">
              {this.renderIncomingOMSCD()}
            </Tab>
            <Tab eventKey="reverses" title="Reversas">
              {this.renderIncomingOMSBase()}
            </Tab>
            <Tab eventKey="treatment" title="Tratativas">
              {this.renderIncomingOMSPending()}
            </Tab>
          </Tabs>
        </div>
      </Container>
    );
  }
}

export default MovementOrdersDetail;
