import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row, Form } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "react-responsive-modal";

import MaterialTable from "material-table";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import SwapHorizOutlinedIcon from "@material-ui/icons/SwapHorizOutlined";
import MapIcon from "@material-ui/icons/Map";

import style from "./style.css";
import Maps from "./mapRoute";
import ConfigTable from "./configTable";
import AlocTable from "./alocTable";
import senninha from "./senninhaIcon.png";
import LogisticService from "../../core/services/LogisticService";
import { element } from "prop-types";
import LoadingSpinner from "../../components/LoadingSpinner";

class RoutesMaps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selctedHub: undefined,
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      mapsList: undefined,
      tableList: undefined,
      alocData: [],
      hubList: [],
      angelsAloc: undefined,
    };

    this.refreshReportData = this.refreshReportData.bind(this);
  }

  refreshReportData() {
    LogisticService.getSenninhaHubs().then((response) => {
      var districts = response.data.data[0];
      var districtList = Object.keys(districts);
      this.setState({
        districts: districts,
        districtList: districtList,
      });
    });
  }

  componentDidMount() {
    this.refreshReportData();
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  alocButton() {
    this.setState({
      alocModal: true,
      alocData: window.localStorage.alocadas,
    });
  }

  changeRoutesButton() {
    this.setState({
      changeRoutesModal: true,
    });
  }

  onCheckChange(rows, parameter) {
    if (parameter == "aloc") {
      this.setState({
        alocData: rows,
      });
    } else if (parameter == "map") {
      var angelsList = rows.map((element) => element.Angel);

      var auxList = [];
      var tableAux = [];
      this.state.tableList.forEach((element) => {
        if (angelsList.includes(element.Nome)) {
          element.checked = true;
          auxList.push(element);
          tableAux.push(element);
        } else {
          element.checked = false;
          tableAux.push(element);
        }
      });
      this.setState({
        mapsList: auxList,
        tableList: tableAux,
      });

      var angelsList = rows.map((element) => element.Angel);

      var auxList = [];
      var tableAux = [];
      this.state.tableList.forEach((element) => {
        if (angelsList.includes(element.Nome)) {
          element.checked = true;
          auxList.push(element);
          tableAux.push(element);
        } else {
          element.checked = false;
          tableAux.push(element);
        }
      });
      this.setState({
        mapsList: auxList,
        tableList: tableAux,
      });
    }
  }

  buildOptionsFromArray(array) {
    if (array.length === 0) return <option> </option>;

    const results = array.map((element) => <option> {element} </option>);
    return results;
  }

  selectDistrict() {
    var selectDistrict = document.getElementById("districtChoose");
    selectDistrict = selectDistrict[selectDistrict.selectedIndex].text;
    var hubsList = this.state.districts[selectDistrict];
    this.setState({
      hubList: hubsList,
      selectedDistrict: selectDistrict,
    });
  }

  selectHub() {
    var selectHub = document.getElementById("hubChoose");
    selectHub = selectHub[selectHub.selectedIndex].text;
    this.setState({
      selctedHub: selectHub,
      loading: true,
    });

    LogisticService.getSenninhaConfig(selectHub).then((response) => {
      this.setState({
        config: response.data.data,
      });
    });
    LogisticService.getSenninhaRoutes(selectHub).then((response) => {
      this.setState({
        mapsList: response.data.data,
        tableList: response.data.data,
        loading: false,
      });
    });

    LogisticService.getSenninhaDetail(selectHub).then((response) => {
      var detailsData = [];
      var k = 0;

      var data = response.data.data;
      Object.keys(data).forEach((element) => {
        detailsData.push({
          name: element,
          valor: "",
        });
        detailsData.push({
          name: "Tempo",
          valor: `${(Object.values(data)[k].tempo_total / 60).toFixed(
            2
          )} horas`,
          parent: element,
        });
        detailsData.push({
          name: "Distância",
          valor: `${Object.values(data)[k].distancia_total.toFixed(1)} km`,
          parent: element,
        });
        detailsData.push({
          name: "OSs Atendidas",
          valor: `${Object.values(data)[k].baixadas}/${
            Object.values(data)[k].total
          }`,
          parent: element,
        });
        k += 1;
      });

      this.setState({
        detailTable: detailsData,
      });
    });
  }

  chooseHubModal() {
    return (
      <div className="chooseBox">
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "1em" }}
          >
            {" "}
          </Col>
          <Col>
            <Form.Label size="md">Distrito</Form.Label>
            <Form.Control
              id="districtChoose"
              size="sm"
              as="select"
              onChange={(e) => this.selectDistrict(e)}
            >
              <option> </option>
              {this.buildOptionsFromArray(this.state.districtList)}
            </Form.Control>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "1em" }}
          >
            {" "}
          </Col>
          <Col>
            <Form.Label size="md">Polo</Form.Label>
            <Form.Control
              id="hubChoose"
              size="sm"
              as="select"
              onChange={(e) => this.selectHub(e)}
            >
              <option> </option>
              {this.buildOptionsFromArray(this.state.hubList)}
            </Form.Control>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "1em" }}
          >
            {" "}
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {this.state.loading ? <LoadingSpinner /> : ""}
          </Col>
        </Row>
      </div>
    );
  }

  renderModal() {
    if (this.state.config == undefined) {
      return <LoadingSpinner />;
    }
    return (
      <Modal
        open={true}
        onClose={(e) => this.setState({ configModal: false })}
        showCloseIcon={true}
        center={true}
        className="modalConfig"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2em",
            width: "fitContent",
            maxWidth: "100vw !important",
          }}
        >
          <ConfigTable
            id="configTable"
            style={{ width: "fit-content" }}
            data={this.state.config}
            hub={this.state.selctedHub}
            // subtitleTable ={'subTitleDistrict'}
            // title ={[{name:''}]}
            // subTitle ={[{name:''},{name:''}]}
            // colSpan ={2}
            // rowSpan = {6}
            // // func = {e => this.renderClickTable()}
            // hiddenButton = {true}
            // buttonText = 'Alterar Mapa'
            // onChange = {e => this.onCheckChange(e)}
          />
        </div>
      </Modal>
    );
  }

  alocCustomers(email, stonecodes) {
    this.setState({
      alocLoading: true,
    });

    console.log(email);

    LogisticService.postSenninhaAloc(
      stonecodes,
      email,
      this.state.selctedHub
    ).then((response) => {
      if (response.data.success) {
        LogisticService.getSenninhaRoutes(this.state.selctedHub).then(
          (response_tables) => {
            this.setState({
              mapsList: response_tables.data.data,
              tableList: response_tables.data.data,
              alocLoading: false,
              alocSucess: true,
            });
          }
        );
      } else {
        LogisticService.getSenninhaRoutes(this.state.selctedHub).then(
          (response_tables) => {
            this.setState({
              mapsList: response_tables.data.data,
              tableList: response_tables.data.data,
              alocLoading: false,
              alocSucess: false,
              alocMessage: response.data.message,
            });
          }
        );
      }
    });
  }

  changeRoutes(firstRoute, secondRoute) {
    this.setState({
      changeRoutesLoading: true,
    });

    LogisticService.postChangeRoutes(
      this.state.selctedHub,
      firstRoute,
      secondRoute
    ).then((response) => {
      if (response.data.success) {
        LogisticService.getSenninhaRoutes(this.state.selctedHub).then(
          (response_tables) => {
            this.setState({
              mapsList: response_tables.data.data,
              tableList: response_tables.data.data,
              changeRoutesLoading: false,
              changeRoutesSucess: true,
            });
          }
        );
      } else {
        LogisticService.getSenninhaRoutes(this.state.selctedHub).then(
          (response_tables) => {
            this.setState({
              mapsList: response_tables.data.data,
              tableList: response_tables.data.data,
              changeRoutesLoading: false,
              changeRoutesSucess: false,
              changeRoutesMessage: response.data.message,
            });
          }
        );
      }
    });
  }

  alocCampanha() {
    this.setState({
      campanhaLoading: true,
      campanhaModal: true
    });

    LogisticService.postAddCampanha(this.state.selctedHub).then((response) => {
      if (response.data.success) {
        LogisticService.getSenninhaRoutes(this.state.selctedHub).then(
          (response_tables) => {
            this.setState({
              mapsList: response_tables.data.data,
              tableList: response_tables.data.data,
              campanhaLoading: false
            });
          }
        );
      } else {
        LogisticService.getSenninhaRoutes(this.state.selctedHub).then(
          (response_tables) => {
            this.setState({
              mapsList: response_tables.data.data,
              tableList: response_tables.data.data,
              campanhaLoading: false
            });
          }
        );
      }
    });
  }

  renderAlocModal() {
    if (this.state.angelsAloc == undefined) {
      LogisticService.getSenninhaAloc(this.state.selctedHub).then(
        (response) => {
          console.log(response.data.angels);
          this.setState({
            angelsAloc: response.data.angels,
          });
        }
      );
      return (
        <Modal
          open={true}
          onClose={(e) =>
            this.setState({
              alocModal: false,
              alocSucess: undefined,
              alocLoading: false,
            })
          }
          showCloseIcon={true}
          center={true}
          className="modalConfig"
        >
          <br />
          <p>Carregando...</p>
          <LoadingSpinner />
        </Modal>
      );
    } else if (
      this.state.alocData == undefined ||
      this.state.alocLoading == true
    ) {
      return (
        <Modal
          open={true}
          onClose={(e) =>
            this.setState({
              alocModal: false,
              alocSucess: undefined,
              alocLoading: false,
            })
          }
          showCloseIcon={true}
          center={true}
          className="modalConfig"
        >
          <br />
          <p>Alocando stonecodes, aguarde...</p>
          <LoadingSpinner />
        </Modal>
      );
    } else if (this.state.alocSucess == true) {
      return (
        <Modal
          open={true}
          onClose={(e) => {
            this.setState({ alocModal: false, alocSucess: undefined });
          }}
          showCloseIcon={true}
          center={true}
          className="modalConfig"
        >
          <p />
          <br />
          <p>Alocado com sucesso! :D</p>
        </Modal>
      );
    } else if (this.state.alocSucess == false) {
      return (
        <Modal
          open={true}
          onClose={(e) =>
            this.setState({ alocModal: false, alocSucess: undefined })
          }
          showCloseIcon={true}
          center={true}
          className="modalConfig"
        >
          <p />
          <br />
          <p>{this.state.alocMessage}</p>
        </Modal>
      );
    }

    var aux = [];
    this.state.alocData.split(",").forEach((element) => {
      aux.push({ stonecode: element });
    });

    return (
      <Modal
        open={true}
        onClose={(e) =>
          this.setState({ alocModal: false, alocSucess: undefined })
        }
        showCloseIcon={true}
        center={true}
        className="modalConfig"
      >
        {/* <div style={{display: "flex", alignItems: "center", justifyContent: "center",marginTop:"2em", width: 'fitContent' }}> */}
        <AlocTable
          id="alocTable"
          data={aux}
          hub={this.state.selctedHub}
          // subtitleTable ={'subTitleDistrict'}
          // title ={[{name:''}]}
          // subTitle ={[{name:''},{name:''}]}
          // colSpan ={2}
          // rowSpan = {6}
          // // func = {e => this.renderClickTable()}
          // hiddenButton = {true}
          // buttonText = 'Alterar Mapa'
          // onChange = {e => this.onCheckChange(e)}
        />
        {/* </div> */}
        <div>
          {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <p><br/></p>
                <button onClick = {e => this.alocCustomers('DELIVERY',{'stonecodes':aux})}>Alocar DELIVERY</button>
             </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Form.Control
              id="aloc"
              as="select"
              type="email"
              size="sm"
              placeholder="Email do Angel"
              onInput={(e) => {}}
            >
              <option> </option>
              {this.buildOptionsFromArray(this.state.angelsAloc)}
            </Form.Control>
            <button
              onClick={(e) =>
                this.alocCustomers(
                  document.getElementById("aloc")[
                    document.getElementById("aloc").selectedIndex
                  ].text,
                  { stonecodes: aux }
                )
              }
            >
              Alocar
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  renderChangeRoutesModal(atendimentos) {
    if (this.state.changeRoutesLoading == true) {
      return (
        <Modal
          open={true}
          onClose={(e) =>
            this.setState({
              changeRoutesModal: false,
              changeRoutesSucess: undefined,
              changeRoutesLoading: false,
            })
          }
          showCloseIcon={true}
          center={true}
          className="modalConfig"
        >
          <br />
          <p>Alocando stonecodes, aguarde...</p>
          <LoadingSpinner />
        </Modal>
      );
    } else if (this.state.changeRoutesSucess == true) {
      return (
        <Modal
          open={true}
          onClose={(e) => {
            this.setState({
              changeRoutesModal: false,
              changeRoutesSucess: undefined,
            });
          }}
          showCloseIcon={true}
          center={true}
          className="modalConfig"
        >
          <p />
          <br />
          <p>Alocado com sucesso! :D</p>
        </Modal>
      );
    } else if (this.state.changeRoutesSucess == false) {
      return (
        <Modal
          open={true}
          onClose={(e) =>
            this.setState({
              changeRoutesModal: false,
              changeRoutesSucess: undefined,
            })
          }
          showCloseIcon={true}
          center={true}
          className="modalConfig"
        >
          <p />
          <br />
          <p>{this.state.changeRoutesMessage}</p>
        </Modal>
      );
    }
    var list = [];
    atendimentos.forEach((element) => {
      if (
        element.Angel != "Baixadas" &&
        element.Angel != "Não Alocada" &&
        element.Angel != "Aberta Hoje"
      ) {
        list.push(element.Angel);
      }
    });

    return (
      <Modal
        open={true}
        onClose={(e) =>
          this.setState({
            changeRoutesModal: false,
            changeRoutesSucess: undefined,
          })
        }
        showCloseIcon={true}
        center={true}
        className="modalConfig"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2em",
            width: "fitContent",
          }}
        >
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: "1em" }}
            >
              {" "}
            </Col>
            <Col>
              <Form.Label size="md">Primeira Rota</Form.Label>
              <Form.Control id="firstRouteChange" size="sm" as="select">
                <option> </option>
                {this.buildOptionsFromArray(list)}
              </Form.Control>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: "1em" }}
            >
              {" "}
            </Col>
            <Col>
              <Form.Label size="md">Segunda Rota</Form.Label>
              <Form.Control id="secondRouteChange" size="sm" as="select">
                <option> </option>
                {this.buildOptionsFromArray(list)}
              </Form.Control>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: "1em" }}
            >
              {" "}
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {this.state.loadingRoutes ? (
                <LoadingSpinner />
              ) : (
                <button
                  className="sectionButton"
                  style={{
                    padding: "0 1vw",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                  onClick={(e) =>
                    this.changeRoutes(
                      document.getElementById("firstRouteChange")[
                        document.getElementById("firstRouteChange")
                          .selectedIndex
                      ].text,
                      document.getElementById("secondRouteChange")[
                        document.getElementById("secondRouteChange")
                          .selectedIndex
                      ].text
                    )
                  }
                >
                  {" "}
                  <SwapHorizOutlinedIcon />{" "}
                </button>
              )}
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }

  renderCampanhaModal() {
    if (this.state.campanhaLoading == true) {
      return (
        <Modal
          open={true}
          onClose={(e) =>
            this.setState({
              campanhaModal: false,
              campanhaLoading: false,
            })
          }
          showCloseIcon={true}
          center={true}
          className="modalConfig"
        >
          <br />
          <p>Alocando OSs, aguarde...</p>
          <LoadingSpinner />
        </Modal>
      );
    } else {
      return (
        <Modal
          open={true}
          onClose={(e) => {
            this.setState({
              campanhaModal: false
            });
          }}
          showCloseIcon={true}
          center={true}
          className="modalConfig"
        >
          <p />
          <br />
          <p>OSs alocadas com sucesso! :D</p>
        </Modal>
      );
    }
  }

  render() {
    if (this.state.districtList == undefined) {
      return (
        <Container
          width="100vw"
          fluid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingSpinner />
        </Container>
      );
    }

    if (this.state.tableList == undefined) {
      return (
        <Container fluid className="routesContainer">
          {this.chooseHubModal()}
          <div className="chooseIcon">
            <img className="iconSenninha" src={senninha}></img>
            {/* <senninha className="iconSenninha"/> */}
          </div>
        </Container>
      );
    }

    var aux = [];
    var j = 0;
    this.state.tableList.forEach((element) => {
      if (aux.length == 0) {
        if (element.checked != undefined) {
          aux.push({
            Angel: element.Nome,
            teste: "elementCheck",
            tableData: { checked: element.checked, color: element.color },
          });
        } else {
          aux.push({
            Angel: element.Nome,
            teste: "elementCheck",
            tableData: { checked: true, color: element.color },
          });
        }
      } else if (aux[j]["Angel"] != element.Nome) {
        if (element.checked != undefined) {
          aux.push({
            Angel: element.Nome,
            teste: "elementCheck",
            tableData: { checked: element.checked, color: element.color },
          });
        } else {
          aux.push({
            Angel: element.Nome,
            teste: "elementCheck",
            tableData: { checked: true, color: element.color },
          });
        }
        j += 1;
      }
    });

    var atendimentos = [];
    var k = 0;
    this.state.tableList.forEach((element) => {
      if (atendimentos.length == 0) {
        atendimentos.push({
          Angel: element.Nome,
          key: element.Nome,
          deadline: "",
          customerName: "",
          customerDeadline: "",
          aloc: false,
        });
        atendimentos.push({
          Angel: element.Nome,
          key: element.stonecode,
          parent: element.Nome,
          deadline: element.customerDeadlineDate,
          customerName: element.customerName,
          customerDeadline: element.customerDeadlineDate,
          aloc: true,
        });
        k += 1;
      } else if (atendimentos[k]["Angel"] != element.Nome) {
        atendimentos.push({
          Angel: element.Nome,
          key: element.Nome,
          deadline: "",
        });
        atendimentos.push({
          Angel: element.Nome,
          key: element.stonecode,
          parent: element.Nome,
          deadline: element.customerDeadlineDate,
          customerName: element.customerName,
          customerDeadline: element.customerDeadlineDate,
          aloc: true,
        });
        k += 2;
      } else {
        atendimentos.push({
          Angel: element.Nome,
          key: element.stonecode,
          parent: element.Nome,
          deadline: element.customerDeadlineDate,
          customerName: element.customerName,
          customerDeadline: element.customerDeadlineDate,
          aloc: true,
        });
        k += 1;
      }
    });

    var mapObj = [];
    this.state.mapsList.forEach((element) => {
      mapObj.push(element);
    });

    return (
      <Container fluid className="routesContainer">
        <div className="mapRoute">
          <Maps
            coordsList={mapObj}
            onMarkerClick={this.onMarkerClick}
            onMapClicked={this.onMapClicked}
            activeMarker={this.state.activeMarker}
            showingInfoWindow={this.state.showingInfoWindow}
            selectedPlace={this.state.selectedPlace}
          ></Maps>
        </div>

        <div className="tableRoute">
          <Tabs
            defaultActiveKey="map"
            id="noanim-tab-example"
            variant="tabs"
            style={{ backgroundColor: "#c8cfcc", color: "#fff" }}
          >
            <Tab eventKey="map" title="Mapa" variant="success">
              <MaterialTable
                title=""
                columns={[{ title: "Name", field: "Angel" }]}
                data={aux}
                options={{
                  selection: true,
                  selectionProps: (rowData) => ({
                    color: "black",
                    value: "",
                    backgroundColor: "#000",
                  }),
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.tableData.color,
                    // size: 2
                  }),
                  pageSize: 10,
                }}
                onSelectionChange={(rows) => this.onCheckChange(rows, "map")}
              />
            </Tab>

            <Tab eventKey="Atendimentos" title="Atendimentos" variant="success">
              <MaterialTable
                title=""
                columns={[
                  { title: "Rotas", field: "key" },
                  { title: "Limite", field: "deadline" },
                  { title: "Limite Cliente", field: "customerDeadline" },
                  { title: "Nome Cliente", field: "customerName" },
                ]}
                data={atendimentos}
                parentChildData={(row, rows) =>
                  rows.find((a) => a.key === row.parent)
                }
                onRowClick={(evt, selectedRow) => console.log(selectedRow)}
                options={{
                  selection: true,
                  selectionProps: (rowData) => ({
                    color: "black",
                    value: "",
                  }),
                  // rowStyle: rowData => ({
                  //   fontSize: 10
                  // }),
                  pageSize: 10,
                }}
                // onSelectionChange={(rows) => this.onCheckChange(rows)}
                onSelectionChange={(alocRows) => {
                  var listSelect = [];
                  alocRows.forEach((angel) => {
                    if (
                      listSelect.indexOf(angel.key) == -1 &&
                      angel.key != angel.Angel
                    ) {
                      listSelect.push(angel.key);
                    }
                  });
                  localStorage.setItem("alocadas", listSelect);
                }}
              />
            </Tab>

            <Tab eventKey="detail" title="Detalhes" variant="success">
              {this.state.detailTable == undefined ? (
                <LoadingSpinner />
              ) : (
                <div>
                  <MaterialTable
                    title=""
                    columns={[
                      { title: "Angels", field: "name" },
                      { title: "", field: "valor" },
                    ]}
                    data={this.state.detailTable}
                    parentChildData={(row, rows) =>
                      rows.find((a) => a.name === row.parent)
                    }
                    onRowClick={(evt, selectedRow) => console.log(selectedRow)}
                    options={{
                      selection: false,
                      selectionProps: (rowData) => ({
                        color: "black",
                        value: "",
                      }),
                      // rowStyle: rowData => ({
                      //   fontSize: 10
                      // }),
                      pageSize: 10,
                    }}
                  />
                </div>
              )}
            </Tab>
          </Tabs>
        </div>

        <button
          className="sectionButton"
          style={{
            padding: "0 1vw",
            width: "fit-content",
            height: "fit-content",
          }}
          onClick={(e) =>
            this.setState({ selctedHub: undefined, tableList: undefined })
          }
        >
          {" "}
          <ArrowBackOutlinedIcon />{" "}
        </button>
        <button
          className="sectionButton"
          style={{
            width: "fit-content",
            height: "fit-content",
            gridColumn: 93,
            padding: "0 1vw",
          }}
          onClick={(e) => this.setState({ configModal: true })}
        >
          {" "}
          <SettingsRoundedIcon />{" "}
        </button>
        <button
          className="sectionButton"
          style={{ width: "10vw", height: "4vh", gridColumn: 18 }}
          onClick={(e) => this.alocButton()}
        >
          Alocar da Tabela
        </button>
        <button
          className="sectionButton"
          style={{ width: "17vw", height: "4vh", gridColumn: 30 }}
          onClick={(e) => this.changeRoutesButton()}
        >
          Trocar Rotas Completas
        </button>
        <button
          className="sectionButton"
          style={{ width: "17vw", height: "4vh", gridColumn: 50 }}
          onClick={(e) => this.alocCampanha()}
        >
          Encaixar OSs da Campanha
        </button>
        <Link
          to={{
            pathname: `/routes-maps-config`,
            state: { district: this.state.selectedDistrict, mapObj: mapObj[0] },
          }}
          style={{ gridColumn: 88, gridRow: 1 }}
        >
          <button
            className="sectionButton"
            style={{
              width: "fit-content",
              height: "fit-content",
              padding: "0 1vw",
            }}
          >
            {" "}
            <MapIcon />{" "}
          </button>
        </Link>
        {/* <div className="extraInfos"> INFORMAÇÕES GERAIS </div> */}
        {this.state.configModal ? this.renderModal() : <div></div>}
        {this.state.campanhaModal ? this.renderCampanhaModal() : <div></div>}
        {this.state.alocModal ? this.renderAlocModal() : <div></div>}
        {this.state.changeRoutesModal ? (
          this.renderChangeRoutesModal(aux)
        ) : (
          <div></div>
        )}
      </Container>
    );
  }
}

export default RoutesMaps;
