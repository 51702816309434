/* eslint-disable */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import style from './style.css'
import { Container, Col, Row} from 'react-bootstrap'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Divider,Popover} from 'antd';
import LogisticService from '../../core/services/LogisticService'
import Senninha from './newImagens/senninhaIcon.png'
import Waring from './newImagens/waringIcon.png'
import Typography from '@material-ui/core/Typography';
import Spinner from 'react-bootstrap/Spinner'
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';

class SenninhaComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
        district: props.district,
        date: props.date,
        theFlash:{
        data : '',
        loaded:false
        },
        countTheFlash: '-'
    };
    this.refreshReportData = this.refreshReportData.bind(this)
  }
  refreshReportData() {
      LogisticService.getLmpFlash(this.state.district,`${this.state.date}-01`,`${this.state.date}-01`).then((response)=>{   
        this.setState({
          theFlash:{
              data:this.ContentSenninha(response.data.data),
              loaded:false
            },
          countTheFlash: response.data.data.length
        })
        console.log(this.state.countTheFlash)
      })
    }

    componentDidMount() {
      this.refreshReportData()
    }

  ContentSenninha(data){
    let content = []
    data.forEach((item)=>{
      content.push(
        <div>
          <Typography color="inherit">
            <img className="iconCard" src={Waring} style={{marginRight:'5px'}}></img>
            <label className="waringTitle">Alerta</label>
          </Typography>
          <p>Você tem uma OS The Flash, {item.os} no Polo {item.hub} </p>
          <Divider/>
        </div>
      )
    })
    return(
      <React.Fragment>
        <div class="overflow-auto" style={{height:"530px",width:"230px"}} >
          {content}
        </div>
      </React.Fragment>
    )
  }
render(){

  return(
    <Popover placement="top"  content={this.state.theFlash.data} trigger="click">
      <Card className={'sideBarSenninha'}>
            <CardContent  className="senninhaContent">
                <Row style={{alignItems: "center", marginLeft:"0%"}}>
                  <Col xs sm ={2} md lg xl = {2}>
                  <p>
                    {/* <img className="iconSenninha" src={Senninha}></img> */}
                    {<i class="material-icons greenBolt">bolt</i>}
                  </p>
                  </Col>
                  <Col xs sm = {2} md lg xl = {4} style={{marginLeft:"10px"}}>
                    <label style={{fontWeight: "bold"}}>
                      Notificações
                    </label>  
                  </Col>
                  <Col xs sm = {2} md lg xl = {4}>
                    <div class="notificationCircle">
                      <label>
                        {this.state.countTheFlash}
                      </label>
                    </div>
                  </Col>
                </Row>
            </CardContent>
          </Card>
        </Popover>
  )

}


}

export default SenninhaComponent
