/* eslint-disable */

import React, {Component, PropTypes} from 'react'
import moment from 'moment'
import _ from 'lodash'
import style from './style.css'
import Graph from '../../components/Logistic/graphic/graph'
import LogisticService from '../../core/services/LogisticService'
import Button from '../../components/Button'
import LoadingSpinner from '../../components/LoadingSpinner'
import RefreshButton from '../../custom-components/RefreshButton'
import DownloadButton from '../../custom-components/DownloadButton'
import { Container, Col, Row, Form } from 'react-bootstrap'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CustomizedExpansionPannel from '../../custom-components/CustomizedExpansionPanel/index'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { shadows } from '@material-ui/system';
import Box from '@material-ui/core/Box';


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#103a21',
    color: 'white',
    fontSize: '0.9em',
  },
  body: {
    fontSize: '0.8em'
  },
}))(TableCell);


class XRayReport extends Component {
  constructor() {
    super();

    this.state = {
      today: moment().format('YYYY-MM-DD'),
      loading: false,
      hubsTable: undefined,
      preTable:undefined,
      lastUpdated: undefined,
      regionTable: undefined,
      selectedDate: '2019-10',
      avaiableRegions: [],
      avaiableDistricts: [],
      avaiableHubs: [],
      selectedRegion: '',
      selectedDistrict: '',
      selectedHub: '',
      accessNoPendencies: {
        graph: undefined,
        loaded: false
      },
      accessEvolution: {
        graph: undefined,
        loaded: false
      },
      accessPendingAccess: {
        graph: undefined,
        loaded: false
      },
      newProductsEvolution:  {
        graph: undefined,
        loaded: false
      },
      collactEvolution: {
        graph: undefined,
        loaded: false
      },
    }

    this.refreshReportData = this.refreshReportData.bind(this)
  }

  componentDidMount() {
    this.updatePredicate();
    this.refreshReportData()
    this.organizeHubs()
  }


  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 1300 });
  }


  refreshReportData() {
    const {today} = this.state
    this.setState({
      // loading: true,
      lastUpdated: undefined,
      regionTable: undefined,
      accessNoPendencies: {
        graph: undefined,
        loaded: false
      },
      accessEvolution: {
        graph: undefined,
        loaded: false
      },
      accessPendingAccess: {
        graph: undefined,
        loaded: false
      },
      newProductsEvolution: {
        graph: undefined,
        loaded: false
      },
      collactEvolution: {
        graph: undefined,
        loaded: false
      },
    })


  LogisticService.getXrayHubs()
    .then((response) => {
      this.hubsTable = response.data.data
      this.preTable = response.data.data
      if (this.state.selectedRegion != ''){
        this.buildDataFilter(this.hubsTable)
        this.buildAccessNoPendencies(this.responseData)
        this.buildAccessEvolution(this.responseData)
        this.buildPendenciesFromAccess(this.responseData)
        this.buildNewProductsEvolution(this.responseData)
        this.buildCollactEvolution(this.responseData)
        if(this.state.selectedDistrict == ''){
          this.groupByRegion(this.preTable,'district')
        }
        else{
          this.groupByRegion(this.preTable,'hub')
        }

      }else{
        // this.groupByRegion(this.hubsTable)
        this.hubsTable = this.groupByDate(this.hubsTable)
        this.buildAccessNoPendencies(this.hubsTable)
        this.buildAccessEvolution(this.hubsTable)
        this.buildPendenciesFromAccess(this.hubsTable)
        this.buildNewProductsEvolution(this.hubsTable)
        this.buildCollactEvolution(this.hubsTable)
        this.groupByRegion(this.preTable,'region')
      }
      }
    )

  }

  groupByDate(response){
    var viewResults = {}
    for(var i=0; i<response.length; i++){
      if(response[i]['referenceDate'] in viewResults){
        viewResults[response[i]['referenceDate']]['total']+=response[i]['total']
        viewResults[response[i]['referenceDate']]['pendingAccess']+=response[i]['pendingAccess']
        viewResults[response[i]['referenceDate']]['pending']+=response[i]['pending']
        viewResults[response[i]['referenceDate']]['doublePending']+=response[i]['doublePending']
        viewResults[response[i]['referenceDate']]['totalCollact']+=response[i]['totalCollact']
        viewResults[response[i]['referenceDate']]['activateCollact']+=response[i]['activateCollact']
      }else{
        var dataView = {
          'total': response[i]['total'],
          'pendingAccess': response[i]['pendingAccess'],
          'pending': response[i]['pending'],
          'doublePending': response[i]['doublePending'],
          'referenceDate': response[i]['referenceDate'],
          'totalCollact': response[i]['totalCollact'],
          'activateCollact': response[i]['activateCollact']
        }
        viewResults[response[i]['referenceDate']]=dataView
      }
    }
    return viewResults
    this.responseData=viewResults
  }


  groupByRegion(response,parameter){
    var now = new Date
    if(now.getMonth()+1 < 10){
        var selectedDate = String(`${now.getFullYear()}`)+'-0'+String(`${now.getMonth()+1}`)
    }
    else{
      var selectedDate = String(`${now.getFullYear()}`)+'-'+String(`${now.getMonth()+1}`)
    }

    if(parameter == 'region'){
      var test = 'name'
    }
    else if(parameter == 'district') {
      var test = 'district'
    }
    else if(parameter == 'hub') {
      var test = 'hubs'
    }
    var viewResults = {}
    for(var i=0; i<response.length; i++){
      if(response[i][test] in viewResults && response[i]['referenceDate']== selectedDate){
        if(test=='district' && response[i]['name']==this.state.selectedRegion){
          viewResults[response[i][test]]['total']+=response[i]['total']
          viewResults[response[i][test]]['pendingAccess']+=response[i]['pendingAccess']
          viewResults[response[i][test]]['pending']+=response[i]['pending']
          viewResults[response[i][test]]['doublePending']+=response[i]['doublePending']
          viewResults[response[i][test]]['totalCollact']+=response[i]['totalCollact']
          viewResults[response[i][test]]['activateCollact']+=response[i]['activateCollact']
        }
        else if(test=='hubs' && response[i]['district']==this.state.selectedDistrict && viewResults[response[i][test]]!=undefined){
          viewResults[response[i][test]]['total']+=response[i]['total']
          viewResults[response[i][test]]['pendingAccess']+=response[i]['pendingAccess']
          viewResults[response[i][test]]['pending']+=response[i]['pending']
          viewResults[response[i][test]]['doublePending']+=response[i]['doublePending']
          viewResults[response[i][test]]['totalCollact']+=response[i]['totalCollact']
          viewResults[response[i][test]]['activateCollact']+=response[i]['activateCollact']
        }
        else if(test!='district' && test !='hubs'){
          viewResults[response[i][test]]['total']+=response[i]['total']
          viewResults[response[i][test]]['pendingAccess']+=response[i]['pendingAccess']
          viewResults[response[i][test]]['pending']+=response[i]['pending']
          viewResults[response[i][test]]['doublePending']+=response[i]['doublePending']
          viewResults[response[i][test]]['totalCollact']+=response[i]['totalCollact']
          viewResults[response[i][test]]['activateCollact']+=response[i]['activateCollact']
        }
      }else if(response[i]['referenceDate']== selectedDate){
        if(test=='district' && response[i]['name']==this.state.selectedRegion){
          var dataView = {
            'name': response[i][test],
            'total': response[i]['total'],
            'pendingAccess': response[i]['pendingAccess'],
            'pending': response[i]['pending'],
            'doublePending': response[i]['doublePending'],
            'referenceDate': response[i]['referenceDate'],
            'totalCollact': response[i]['totalCollact'],
            'activateCollact': response[i]['activateCollact']
          }
        }
        else if(test=='hubs' && response[i]['district']==this.state.selectedDistrict){
          var dataView = {
            'name': response[i][test],
            'total': response[i]['total'],
            'pendingAccess': response[i]['pendingAccess'],
            'pending': response[i]['pending'],
            'doublePending': response[i]['doublePending'],
            'referenceDate': response[i]['referenceDate'],
            'totalCollact': response[i]['totalCollact'],
            'activateCollact': response[i]['activateCollact']
          }
        }
        else if(test!='district' && test!='hubs'){
          var dataView = {
            'name': response[i][test],
            'total': response[i]['total'],
            'pendingAccess': response[i]['pendingAccess'],
            'pending': response[i]['pending'],
            'doublePending': response[i]['doublePending'],
            'referenceDate': response[i]['referenceDate'],
            'totalCollact': response[i]['totalCollact'],
            'activateCollact': response[i]['activateCollact']
          }
        }
        if(dataView!= undefined){
            viewResults[response[i][test]]=dataView
        }

      }
    }
    this.state.regionTable=viewResults
  }


  buildDataFilter(obj) {
    var response = this.hubsTable
    var finalData = []
    var results = {}
    var viewResults = {}
    var j = 0
    for(var i=0; i<response.length;i++){
      if(this.state.selectedRegion != ''){
        if(this.state.selectedDistrict!= ''){
          if(this.state.selectedHub!= ''){
            if(response[i]['hubs']==this.state.selectedHub){
              j+=1
              finalData.push(response[i])
            }
          }
          else{
            if(response[i]['district']==this.state.selectedDistrict){
              j+=1
              finalData.push(response[i])
            }
          }
        }
        else if(response[i]['name']==this.state.selectedRegion){
          j+=1
          finalData.push(response[i])
        }
      }
      else{
        j+=1
        finalData=this.responseData
      }
    }
    this.responseData = this.groupByDate(finalData)
  }

  //
  // buildTableFilter(obj) {
  //   var response = this.hubsTable
  //   var finalData = []
  //   var results = {}
  //   var viewResults = {}
  //   var j = 0
  //   for(var i=0; i<response.length;i++){
  //     if(this.state.selectedRegion != ''){
  //       if(this.state.selectedDistrict!= ''){
  //         if(this.state.selectedHub!= ''){
  //           if(response[i]['hubs']==this.state.selectedHub){
  //             j+=1
  //             finalData.push(response[i])
  //           }
  //         }
  //         else{
  //           if(response[i]['district']==this.state.selectedDistrict){
  //             j+=1
  //             finalData.push(response[i])
  //           }
  //         }
  //       }
  //       else if(response[i]['name']==this.state.selectedRegion){
  //         j+=1
  //         finalData.push(response[i])
  //       }
  //     }
  //     else{
  //       j+=1
  //       finalData=this.responseData
  //     }
  //   }
  //   this.regionTable=finalData
  // }
  //


  organizeHubs() {

    LogisticService.getAllAngels(undefined, undefined, undefined).then((response) => {
      //This dict will organize angels inside hubs, hubs inside districts and districts inside regions
      let hubs = {}
      //Storing regions
      response.data.data.forEach(element =>{
          hubs[element.region] = {}
      })
      //Storing districts
      response.data.data.forEach(element =>{
          hubs[element.region][element.district] = {hubs: {} }
      })
      //Storing hubs
      response.data.data.forEach(element =>{
          hubs[element.region][element.district].hubs[element.hub] = {angels: []}
      })

        // All regions must be avaiable to choose, but we want to start without any option selected
        let avaiableRegions = [' '].concat(Object.keys(hubs))

        this.setState({
         hubs: hubs,
         avaiableRegions: avaiableRegions,
       })
})
}




  refreshOptions() {
    const region = document.getElementById("region")
    const district = document.getElementById("district")
    const hub = document.getElementById("hub")

    const selectedRegion = region[region.selectedIndex].text
    const selectedDistrict = district[district.selectedIndex].text
    const selectedHub = hub[hub.selectedIndex].text

    let avaiableDistricts = []
    let avaiableHubs = []
    let avaiableAngels = []

    if ((this.state.hubs[selectedRegion]) != undefined)
      avaiableDistricts = [' '].concat(Object.keys(this.state.hubs[selectedRegion]))

    if ((this.state.hubs[selectedRegion]) != undefined)
      if ((this.state.hubs[selectedRegion][selectedDistrict]) != undefined)
        avaiableHubs = [' '].concat(Object.keys(this.state.hubs[selectedRegion][selectedDistrict].hubs))



    this.setState({
       avaiableDistricts: avaiableDistricts,
       avaiableHubs: avaiableHubs,
       selectedRegion: selectedRegion,
       selectedDistrict: selectedDistrict,
       selectedHub: selectedHub,
       accessNoPendencies:{
         loaded:false
       }
      })
      this.refreshReportData()
    }


  buildOptionsFromArray(array){

    if (array.length === 0)
      return (<option>   </option>)

    const results = array.map(element =>
      (<option> {element} </option>)
  )
    return results
  }

  downloadXrayAnalysis() {

    LogisticService.getXrayCustomersAnalytics(this.state.selectedDate).then((response) => {

      this.JSONToCSVConvertor(response.data.data, `Analitico Raio-X`, true)

    })


  }

  downloadCollactAnalysis() {

    LogisticService.getCollactCustomersAnalytics().then((response) => {

      this.JSONToCSVConvertor(response.data.data, `Analitico Collact`, true)

    })


  }


  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

    var CSV = 'sep=,'.concat('\r\n');

    //This condition will generate the Label/Header
    if (ShowLabel) {
        var row = "";

        //This loop will extract the label from 1st index of on array
        for (var index in arrData[0]) {

            //Now convert each value to string and comma-seprated
            row += index + ',';
        }

        row = row.slice(0, -1);

        //append Label row with line break
        CSV += row + '\r\n';
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
        row = "";

        //2nd loop will extract each column and convert it in string comma-seprated
        for (index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
        }

        row.slice(0, row.length - 1);

        //add a line break after each row
        CSV += row + '\r\n';
    }

    if (CSV === '') {
        alert("Invalid data");
        return;
    }

    //Generate a file name
    var fileName = "";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g,"_");

    //Initialize file format you want csv or xls
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}



  inputBlock () {
    return (
      <Form>
        <Form.Row>
          <Col sm="12" lg="4">
            <Form.Group as={Col} controlId="formGridState" onInput = {e => this.refreshOptions()} >
              <Form.Label>Regional</Form.Label>
              <Form.Control id="region" as="select" onChange = {e => this.refreshOptions()} onInput = {e => this.refreshOptions()}>
                {this.buildOptionsFromArray(this.state.avaiableRegions)}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm="12" lg="4">
          <Form.Group as={Col} controlId="formGridState" onInput = {e => this.refreshOptions()} >
            <Form.Label>Distrito</Form.Label>
            <Form.Control id="district" as="select" onChange = {e => this.refreshOptions()} onInput = {e => this.refreshOptions()}>
              {this.buildOptionsFromArray(this.state.avaiableDistricts)}
            </Form.Control>
          </Form.Group>
          </Col>
          <Col sm="12" lg="4">
          <Form.Group as={Col} controlId="formGridState" onInput = {e => this.refreshOptions()} >
            <Form.Label>Polo</Form.Label>
            <Form.Control id="hub" as="select" onChange = {e => this.refreshOptions()} onInput = {e => this.refreshOptions()}>
              {this.buildOptionsFromArray(this.state.avaiableHubs)}
            </Form.Control>
          </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    )}



    buildAccessNoPendencies(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Ativados e Sem Pendência de Informação',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: 'black',
            fontSize: 20,
            text: 'Implantação de Raio-X',
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'black',
              anchor: 'end',
              align: 'top',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return parseFloat(value*100).toFixed(1)
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
          },
        },
      }




      _.orderBy(obj, 'referenceDate').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.referenceDate))
          graphObj.data.datasets[0].data.push((element.total-element.pendingAccess-element.pending+element.doublePending)/element.total)
        }
      )



      this.setState({
        accessNoPendencies: {
          graph: graphObj,
          loaded: true
        },
        lastUpdated: moment(),
      })
    }

    //Renderiza o gráfico de status de OS por data de abertura
    renderAccessNoPendencies() {
      if (this.state.accessNoPendencies.loaded ) {
        return (
          <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
            <Graph
              data={this.state.accessNoPendencies.graph.data}
              legend={this.state.accessNoPendencies.graph.legend}
              options={this.state.accessNoPendencies.graph.options}
              width={this.state.isDesktop ? 400 : 310}
              height={380}
            />
          </Box>)
      }

      return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
        <LoadingSpinner />
      </div>)
    }


    buildAccessEvolution(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Ativados (D+5)',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
                suggestedMax:0.7
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: 'black',
            fontSize: 20,
            text: 'Evolução do Primeiro Acesso de Raio-X (D+5)',
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'black',
              anchor: 'end',
              align: 'top',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return parseFloat(value*100).toFixed(1)
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
          },
        },
      }




      _.orderBy(obj, 'referenceDate').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.referenceDate))
          graphObj.data.datasets[0].data.push((element.total-element.pendingAccess)/element.total)
        }
      )



      this.setState({
        accessEvolution: {
          graph: graphObj,
          loaded: true
        },
        lastUpdated: moment(),
      })
    }

    //Renderiza o gráfico de status de OS por data de abertura
    renderAccessEvolution() {
      if (this.state.accessEvolution.loaded ) {
        return (
          <div>
            <Graph
              data={this.state.accessEvolution.graph.data}
              legend={this.state.accessEvolution.graph.legend}
              options={this.state.accessEvolution.graph.options}
              width={this.state.isDesktop ? 400 : 310}
              height={380}
            />
          </div>)
      }

      return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
        <LoadingSpinner />
      </div>)
    }


    buildPendenciesFromAccess(obj) {
      const graphObj =
      {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Sem Pendência',
              stack: 'Stack 0',
              backgroundColor: '#18641e',
              borderWidth: 2,
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
                suggestedMax:0.9
              },
            }],
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          title: {
            display: true,
            fontColor: 'black',
            fontSize: 20,
            text: 'Clientes Sem Pendências dentre os Ativados',
          },
          plugins: {
            datalabels: {
              display: function (graphObj) {
                return graphObj.dataset.data[graphObj.dataIndex] > 0
              },
              font: {
                size: 15,
              },
              color: 'black',
              anchor: 'end',
              align: 'top',
              formatter: function (value) {
                if (value >= 1000) {
                  return String((value/1000).toFixed(1)) + 'k'
                }
                else {
                  return parseFloat(value*100).toFixed(1)
                }
              }
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'black',
          },
        },
      }




      _.orderBy(obj, 'referenceDate').forEach((element) => {
          graphObj.data.labels.push(" ".concat(element.referenceDate))
          graphObj.data.datasets[0].data.push((element.total-element.pendingAccess-element.pending+element.doublePending)/(element.total-element.pendingAccess))
        }
      )



      this.setState({
        accessPendingAccess: {
          graph: graphObj,
          loaded: true
        },
        lastUpdated: moment(),
      })
    }

    //Renderiza o gráfico de status de OS por data de abertura
    renderPendingAccess() {
      if (this.state.accessPendingAccess.loaded ) {
        return (
          <div>
            <Graph
              data={this.state.accessPendingAccess.graph.data}
              legend={this.state.accessPendingAccess.graph.legend}
              options={this.state.accessPendingAccess.graph.options}
              width={this.state.isDesktop ? 400 : 310}
              height={380}
            />
          </div>)
      }

      return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
        <LoadingSpinner />
      </div>)
    }


  summaryTable(){


  var regionTable2 = []
  var regionTable = this.state.regionTable
  var testDistrict=''
  // (var key in object)
  for (var row in regionTable){
    if(regionTable[row] != testDistrict){
        testDistrict=regionTable[row]
        regionTable2.push(regionTable[row])
    }
  }
  const results = regionTable2.map(regionTable2 => (
    <TableRow>
      <StyledTableCell align="center">{regionTable2.name}</StyledTableCell>
      <StyledTableCell align="center">{regionTable2.total}</StyledTableCell>
      <StyledTableCell align="center">{`${String((((regionTable2.total - regionTable2.pendingAccess - regionTable2.pending+regionTable2.doublePending)/regionTable2.total)*100).toFixed(1))}% `}</StyledTableCell>
      <StyledTableCell align="center">{regionTable2.totalCollact}</StyledTableCell>
      <StyledTableCell align="center">{`${String(((regionTable2.activateCollact/regionTable2.totalCollact)*100).toFixed(1))}% `}</StyledTableCell>
      <StyledTableCell align="center">{regionTable2.totalCollact+regionTable2.total}</StyledTableCell>
      <StyledTableCell align="center">{`${String((((regionTable2.activateCollact+regionTable2.total - regionTable2.pendingAccess - regionTable2.pending+regionTable2.doublePending)/(regionTable2.totalCollact+regionTable2.total))*100).toFixed(1))}% `}</StyledTableCell>
    </TableRow>
  ))

  return (
    <Box mx="auto" width="80%" margin='1em' overflow='auto'>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" colSpan="8">Resultado da Ativação por Regional (MTD)</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center" colSpan="1" className={style.spacingDash}></StyledTableCell>
            <StyledTableCell align="center" colSpan="2" className={style.spacingDash}>Raio-X</StyledTableCell>
            <StyledTableCell align="center" colSpan="2" className={style.spacingDash}>Collact</StyledTableCell>
            <StyledTableCell align="center" colSpan="2">Novos Produtos</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center" className={style.spacingDash}>Regional</StyledTableCell>
            <StyledTableCell align="center">Total</StyledTableCell>
            <StyledTableCell align="center" className={style.spacingDash}>Ativados</StyledTableCell>
            <StyledTableCell align="center">Total</StyledTableCell>
            <StyledTableCell align="center" className={style.spacingDash}>Ativados</StyledTableCell>
            <StyledTableCell align="center">Total</StyledTableCell>
            <StyledTableCell align="center">Ativados</StyledTableCell>
          </TableRow>

        </TableHead>
        <TableBody>
          {results}
        </TableBody>
      </Table>
    </Box>
  )
}


// Renderiza a tabela principal, contendo todas as regiões e suas respectivas estatísticas
renderSummaryTable(){

  if (this.state.regionTable !== undefined) {
    return (
      <Box>
        {this.summaryTable()}
      </Box>
    )
    return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
    <LoadingSpinner />
  </div>)
  }
}


buildNewProductsEvolution(obj) {
  const graphObj =
  {
    data: {
      labels: [],
      datasets: [
        {
          label: 'Ativados (D+5)',
          stack: 'Stack 0',
          backgroundColor: '#18641e',
          borderWidth: 2,
          data: [],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          categoryPercentage: 0.9,
          gridLines: {
            display: false,
          },
        }],
        yAxes: [{
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            min: 0,
          },
        }],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: true,
        fontColor: 'black',
        fontSize: 20,
        text: 'Evolução da Ativação Novos Produtos (D+5)',
      },
      plugins: {
        datalabels: {
          display: function (graphObj) {
            return graphObj.dataset.data[graphObj.dataIndex] > 0
          },
          font: {
            size: 15,
          },
          color: 'black',
          anchor: 'end',
          align: 'top',
          formatter: function (value) {
            if (value >= 1000) {
              return String((value/1000).toFixed(1)) + 'k'
            }
            else {
              return parseFloat(value*100).toFixed(1)
            }
          }
        },
      },
    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        fontColor: 'black',
      },
    },
  }




  _.orderBy(obj, 'referenceDate').forEach((element) => {
      graphObj.data.labels.push(" ".concat(element.referenceDate))
      // graphObj.data.datasets[0].data.push((element.total-element.pendingAccess+element.collactActivate)/(element.total+element.totalCollact))
      graphObj.data.datasets[0].data.push((element.activateCollact+element.total - element.pendingAccess - element.pending+element.doublePending)/(element.totalCollact+element.total))
    }
  )



  this.setState({
    newProductsEvolution: {
      graph: graphObj,
      loaded: true
    },
    lastUpdated: moment(),
  })
}

//Renderiza o gráfico de status de OS por data de abertura
renderNewProductsEvolution() {
  if (this.state.newProductsEvolution.loaded ) {
    return (
      <Box width={this.state.isDesktop ? "80%" : "100%"} height="80%" mx="auto">
        <Graph
          data={this.state.newProductsEvolution.graph.data}
          legend={this.state.newProductsEvolution.graph.legend}
          options={this.state.newProductsEvolution.graph.options}
          width={500}
          height={450}
        />
      </Box>)
  }

  return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
    <LoadingSpinner />
  </div>)
}


buildCollactEvolution(obj) {
  const graphObj =
  {
    data: {
      labels: [],
      datasets: [
        {
          label: 'Ativados (D+5)',
          stack: 'Stack 0',
          backgroundColor: '#18641e',
          borderWidth: 2,
          data: [],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          categoryPercentage: 0.9,
          gridLines: {
            display: false,
          },
        }],
        yAxes: [{
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            min: 0,
            suggestedMax:0.8
          },
        }],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: true,
        fontColor: 'black',
        fontSize: 20,
        text: 'Evolução da Ativação Collact (D+5)',
      },
      plugins: {
        datalabels: {
          display: function (graphObj) {
            return graphObj.dataset.data[graphObj.dataIndex] > 0
          },
          font: {
            size: 15,
          },
          color: 'black',
          anchor: 'end',
          align: 'top',
          formatter: function (value) {
            if (value >= 1000) {
              return String((value/1000).toFixed(1)) + 'k'
            }
            else {
              return parseFloat(value*100).toFixed(1)
            }
          }
        },
      },
    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        fontColor: 'black',
      },
    },
  }




  _.orderBy(obj, 'referenceDate').forEach((element) => {
      graphObj.data.labels.push(" ".concat(element.referenceDate))
      // graphObj.data.datasets[0].data.push((element.total-element.pendingAccess+element.collactActivate)/(element.total+element.totalCollact))
      graphObj.data.datasets[0].data.push(element.activateCollact/element.totalCollact)
    }
  )



  this.setState({
    collactEvolution: {
      graph: graphObj,
      loaded: true
    },
    lastUpdated: moment(),
  })
}

//Renderiza o gráfico de status de OS por data de abertura
renderCollactEvolution() {
  if (this.state.collactEvolution.loaded ) {
    return (
      <Box width={this.state.isDesktop ? "80%" : "100%"} height="80%" mx="auto">
        <Graph
          data={this.state.collactEvolution.graph.data}
          legend={this.state.collactEvolution.graph.legend}
          options={this.state.collactEvolution.graph.options}
          width={this.state.isDesktop ? 400 : 310}
          height={380}
    />
      </Box>)
  }

  return (<div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
    <LoadingSpinner />
  </div>)
}



  renderXRayBlock() {
    return (
      <Row>
        <Col xl sm md lg xl = {12}>
        {this.renderAccessNoPendencies()}
        </Col>
        <Col xl sm md = {12} lg xl={6}>
          {this.renderAccessEvolution()}
        </Col>
        <Col xl sm md = {12} lg xl={6}>
          {this.renderPendingAccess()}
        </Col>
      </Row>
    )
  }


  renderCollactBlock() {
    return (
      <Box width={this.state.isDesktop ? "80%" : "100%"} height="80%" mx="auto">
        {this.renderCollactEvolution()}
      </Box>
    )
  }

  returnFilter(){
    let start_date = new Date(2019,9,1)
    let now = new Date()
    let filter = []
    while(start_date.getFullYear() !== now.getFullYear() || start_date.getMonth() <= now.getMonth()){
      filter.push(<option>{start_date.getFullYear().toString()+'-'+(start_date.getMonth()+1 < 10?'0'+(start_date.getMonth()+1).toString():(start_date.getMonth()+1).toString())}</option>)
      if(start_date.getMonth()+1 === 12)
        start_date = new Date(start_date.getFullYear()+1,0,1)
      else
      start_date = new Date(start_date.getFullYear(),start_date.getMonth()+1,1)
    }
    return(
      <Form.Group as={Col} controlId="formGridState" >
          <Form.Control id="date" as="select" onChange = {e => this.setState({selectedDate: e.target.value})} >
            {filter}
          </Form.Control>
        </Form.Group>
    )
  }



  render() {
    let refreshContent
    let lastUpdatedText = ''

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format('DD/MM HH:mm')}`
    }

    if (!this.state.loading) {
      refreshContent = (
        <div style={{textAlign: 'right', marginBottom: '1em'}}>
          <label style={{margin:'1em'}}><i>{lastUpdatedText}</i></label>
          <RefreshButton onClick={() => { this.refreshReportData() }}/>
        </div>
      )
    }

      return (
      <Container fluid>

      <Row>
      <Col sm="12" lg="4">
        {this.returnFilter()}
      </Col>
        <Col lg="0.8">
          <h6> Raio-X: </h6>
        </Col>
        <Col lg="2">
          <DownloadButton
            id="serviceOrdersDownload"
            onClick={ e => this.downloadXrayAnalysis()}
          />
        </Col>
        <Col lg="0.8">
          <h6> Collact: </h6>
        </Col>
        <Col lg="2">
          <DownloadButton
            id="serviceOrdersDownload"
            onClick={ e => this.downloadCollactAnalysis()}
          />
        </Col>
        </Row>
        <Col lg="12" xs="12" sm="12">
          {refreshContent}
          <Box
            boxShadow={3}>
            {this.inputBlock()}
          </Box>
          <CustomizedExpansionPannel
            title="Ativação de Novos Produtos"
            content={this.renderNewProductsEvolution()}
            />
          <CustomizedExpansionPannel
            title="Raio X"
            content={this.renderXRayBlock()}
          />
          <CustomizedExpansionPannel
            title="Collact"
            content={this.renderCollactBlock()}
          />
          <CustomizedExpansionPannel
            title="Resumo Geral"
            content={this.renderSummaryTable()}
          />

        </Col>
      </Container>)

  }









}

export default XRayReport
