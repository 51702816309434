import React from "react";
import { Statistic, Icon, Card } from "antd";

const SumarioDeResultados = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      {Object.keys(props.pilares).map((pilar) => {
        return (
          <CardDeAtingimentoDoPilar
            key={Math.random()}
            atingimento={props.pilares[pilar].atingimento}
            limite={props.pilares[pilar].limite}
            nomeDoPilar={props.pilares[pilar].nome}
          />
        );
      })}
      <CardDeAtingimentoDoPilar
        atingimento={props.resultadoFinal}
        limite={100.0}
        nomeDoPilar="Atingimento Total"
      />
    </div>
  );
};

const CardDeAtingimentoDoPilar = (props) => {
  const { icon, atingimento, limite, nomeDoPilar } = props;
  return (
    <Card style={{ minWidth: 180, margin: "18px" }}>
      <Statistic
        title={<h3>{nomeDoPilar}</h3>}
        value={atingimento + "/" + limite}
        prefix={
          <Icon
            type={icon || "none"}
            theme="twoTone"
            // twoToneColor="silver"
            style={{ verticalAlign: "middle" }}
          />
        }
      />
    </Card>
  );
};

export { CardDeAtingimentoDoPilar, SumarioDeResultados };
