import React, { Component } from "react";
import Graph from "../../components/Logistic/graphic/graph";
import moment from "moment";
import _ from "lodash";
import LogisticService from "../../core/services/LogisticService";
import BrazilCmp from "../../custom-components/BrazilMap/brazil-cmp";
import CustomizedExpansionPannel from "../../custom-components/CustomizedExpansionPanel/index";
import LoadingSpinner from "../../components/LoadingSpinner";
import RefreshButton from "../../custom-components/RefreshButton";
import DownloadButton from "../../custom-components/DownloadButton";
import { Container, Col, Row } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Carousel from "react-bootstrap/Carousel";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Typography from "@material-ui/core/Typography";
import Badge from "react-bootstrap/Badge";

// import 'typeface-roboto';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#103a21",
    color: "white",
    fontSize: "0.9em",
  },
  body: {
    fontSize: "0.8em",
  },
}))(TableCell);

class LastMileReport extends Component {
  constructor() {
    super();

    this.state = {
      today: moment().format("YYYY-MM-DD"),
      loading: false,
      isDesktop: window.innerWidth > 1300,
      reportData: undefined,
      lastUpdated: undefined,
      modalIsOpen: false,
      aging: {
        graph: undefined,
        loaded: false,
        dailyAverage: undefined,
      },
      callOpeningClosing: {
        graph: undefined,
        loaded: false,
      },
      redirectsMap: {
        redirects_data: undefined,
        redirects_loaded: false,
      },
      tmaMap: {
        data: undefined,
        loaded: false,
      },
      mtdSlaMap: {
        data: undefined,
        regions: undefined,
        loaded: false,
      },
      selfLogisticsMap: {
        data: undefined,
        loaded: false,
      },
      mtdSlaGrouped: {
        graph: undefined,
        data: undefined,
        loaded: false,
        slaByOpl: undefined,
      },
      historyOfLateOrders: {
        graph: undefined,
        loaded: false,
      },
      machineConsumption: {
        graph: undefined,
        loaded: false,
        tableMachineConsumption: undefined,
      },
      completeSLAHistory: {
        graph: undefined,
        loaded: false,
      },
      realizedHistory: {
        graph: undefined,
        data: undefined,
        loaded: false,
      },
      scoreCsat: {
        graph: undefined,
        data: undefined,
        loaded: false,
      },
      csatAnswer: {
        graph: undefined,
        data: undefined,
        loaded: false,
      },
    };

    this.refreshReportData = this.refreshReportData.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    this.refreshReportData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 1300 });
  }

 
  refreshLastMileSummaries() {
    const finalResult = [];

    Promise.all([
      //PRIMEIO ARGUMENTO = CONTRATANTE
      //SEGUNDO ARGUMENTO = OPERADOR
      LogisticService.getLastMileSummary("STONE PAGAMENTOS", null),
      LogisticService.getLastMileSummary("STONE PAGAMENTOS", ["STONE PAGAMENTOS"]),
      LogisticService.getLastMileSummary("STONE PAGAMENTOS", ["PAYTEC"]),
      LogisticService.getLastMileSummary("STONE PAGAMENTOS", ["STONE FRANQUIA"]),
      LogisticService.getLastMileSummary("STONE PAGAMENTOS", ["FLASH COURIER"]),
      LogisticService.getLastMileSummary("STONE MEI", null), //STONE MEI = STONE MAIS
      LogisticService.getLastMileSummary("STONE PAGAMENTOS", ["PAYTEC", "STONE FRANQUIA"]),
    ]).then((values) => {
      values[0].data.data.historic_cadence_data.name = "TOTAL";
      values[1].data.data.historic_cadence_data.name = "STONE PAGAMENTOS";
      values[2].data.data.historic_cadence_data.name = "PAYTEC";
      values[3].data.data.historic_cadence_data.name = "STONE FRANQUIA";
      values[4].data.data.historic_cadence_data.name = "ENTREGA DIRETA";
      values[5].data.data.historic_cadence_data.name = "STONE MEI";
      values[6].data.data.historic_cadence_data.name = "OPERAÇÃO TERCEIRA";

      values.forEach((a) => finalResult.push(a.data.data.historic_cadence_data));

      this.setState({
        loading: false,
        reportData: {
          historic_cadence_data: finalResult,
        },
        lastUpdated: moment(),
      });
    });
  }

  refreshReportData() {
    const { today } = this.state;
    this.setState({
      loading: true,
      reportData: undefined,
      lastUpdated: undefined,
      aging: {
        loaded: false,
        graph: undefined,
        dailyAverage: undefined,
      },
      callOpeningClosing: {
        graph: undefined,
        loaded: false,
      },
      tmaMap: {
        data: undefined,
        loaded: false,
      },
      redirectsMap: {
        redirects_data: undefined,
        redirects_loaded: false,
      },
      mtdSlaMap: {
        data: undefined,
        regions: undefined,
        loaded: false,
      },
      selfLogisticsMap: {
        data: undefined,
        loaded: false,
      },
      mtdSlaGrouped: {
        graph: undefined,
        data: undefined,
        loaded: false,
        slaByOpl: undefined,
      },
      historyOfLateOrders: {
        graph: undefined,
        loaded: false,
      },
      machineConsumption: {
        graph: undefined,
        loaded: false,
        tableMachineConsumption: undefined,
      },
    });

    this.refreshLastMileSummaries();

    //Requisição para o gráfico de aging dos chamados atrasados
    LogisticService.getDelayAging().then((response) => {
      this.buildAgingDataGraph(response.data);
    });

    //Requisição para o gráfico histórico de Abertura vs Fechamento OSs
    LogisticService.getcallOpeningClosing().then((response) => {
      this.buildCallOpeningClosingGraph(response.data);
    });

    LogisticService.getMtdTmaGrouped().then((response) => {
      console.log(response);
      this.setState({
        tmaMap: {
          data: response.data.data,
          loaded: true,
        },
      });
    });

    //Requisição para o mapa de Deamanda Dia/Cadência Histórica
    LogisticService.getMtdRedirectsMap().then((response) => {
      this.setState({
        redirectsMap: {
          redirects_data: response,
          redirects_loaded: true,
        },
      });
    });

    //Requisição para o mapa de %SLA MTD
    LogisticService.getMtdSlaMap().then((map_response) => {
      //Requisição para o SLA MTD das Regiões
      LogisticService.getMtdRegionsSla().then((regions_response) => {
        this.setState({
          mtdSlaMap: {
            data: map_response.data,
            regions: regions_response.data,
            loaded: true,
          },
        });
      });
    });

    //Requisição para o mapa %Operação Própria
    LogisticService.getSelfLogisticsMap().then((response) => {
      this.setState({
        selfLogisticsMap: {
          data: response.data,
          loaded: true,
        },
      });
    });

    //Requisição para o mapa de SLA por estado
    LogisticService.getMtdSlaGrouped(today).then((response) => {
      //Requisição para o SLA STONE MEI
      LogisticService.getMtdSlaStoneMEI(today).then((stone_mei_response) => {
        this.buildMtdSlaGrouped(response.data, stone_mei_response.data);
      });
    });

    //Requisição para o gráfico de chamados atrasados
    LogisticService.getHistoryOfLateOrders().then((response) => {
      this.buildHistoryOfLateOrders(response.data);
    });

    //Requisição para o gráfico com o histórico completo do SLA
    LogisticService.getSLACompleteHistory().then((response) => {
      this.buildCompleteSLAHistory(response.data);
    });

    //Requisição para o gráfico de histórico de consumo de terminais
    LogisticService.getMachineConsumption().then((response) => {
      this.buildMachineConsumption(response.data);
    });

    LogisticService.getRealizedOSHistory().then((response) => {
      this.buildRealizedOSHistory(response.data.data);
    });

    LogisticService.getScoreCsatPerOPL().then((response) => {
      this.buildScoreCsatPerOPL(response.data.data);
    });

    LogisticService.getCsatAnswerPercent().then((response) => {
      this.buildCsatAnswerPercent(response.data.data);
    });
  }

  reportContentFor(type, headerStyle, alternativeName = null, totalRow = false) {
    const { reportData } = this.state;
    const cadenceData = reportData.historic_cadence_data.filter((d) => d.name === type)[0];

    const shortTermCadence = cadenceData.short_term_past_cadence;
    const futureOrdersCadence = cadenceData.future_orders_histogram;
    if (totalRow === true) {
      return (
        <TableRow hover style={{ backgroundColor: "#8A8E89" }}>
          <StyledTableCell align="center" style={{ backgroundColor: "#8A8E89" }}>
            {alternativeName ? alternativeName : type}
          </StyledTableCell>
          <StyledTableCell align="center" style={{ backgroundColor: "#8A8E89" }}>
            {shortTermCadence[4].qty.toLocaleString()}
          </StyledTableCell>
          <StyledTableCell align="center" style={{ backgroundColor: "#8A8E89" }}>
            {shortTermCadence[3].qty.toLocaleString()}
          </StyledTableCell>
          <StyledTableCell align="center" style={{ backgroundColor: "#8A8E89" }}>
            {shortTermCadence[2].qty.toLocaleString()}
          </StyledTableCell>
          <StyledTableCell align="center" style={{ backgroundColor: "#8A8E89" }}>
            {shortTermCadence[1].qty.toLocaleString()}
          </StyledTableCell>
          <StyledTableCell align="center" style={{ backgroundColor: "#8A8E89" }}>
            {shortTermCadence[0].qty.toLocaleString()}
          </StyledTableCell>

          <StyledTableCell align="center" style={{ backgroundColor: "#8A8E89" }} />

          <StyledTableCell align="center" style={{ backgroundColor: "#ff5e57", fontWeight: "bold", color: "white" }}>
            {cadenceData.late_day_start.toLocaleString()}
          </StyledTableCell>
          <StyledTableCell align="center" style={{ backgroundColor: "#8A8E89" }}>
            {cadenceData.completed_today.toLocaleString()}
          </StyledTableCell>
          <StyledTableCell align="center" style={{ backgroundColor: "#ff5e57", fontWeight: "bold", color: "white" }}>
            {cadenceData.late_day_end.toLocaleString()}
          </StyledTableCell>

          <StyledTableCell align="center" style={{ backgroundColor: "#8A8E89" }} />

          <StyledTableCell align="center" style={{ backgroundColor: "#8A8E89" }}>
            {futureOrdersCadence[0].qty.toLocaleString()}
          </StyledTableCell>
          <StyledTableCell align="center" style={{ backgroundColor: "#8A8E89" }}>
            {futureOrdersCadence[1].qty.toLocaleString()}
          </StyledTableCell>
          <StyledTableCell align="center" style={{ backgroundColor: "#8A8E89" }}>
            {futureOrdersCadence[2].qty.toLocaleString()}
          </StyledTableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow hover style={{ borderStyle: "none", borderWidth: "0px" }}>
          <StyledTableCell align="center">{alternativeName ? alternativeName : type}</StyledTableCell>
          <StyledTableCell align="center">{shortTermCadence[4].qty.toLocaleString()}</StyledTableCell>
          <StyledTableCell align="center">{shortTermCadence[3].qty.toLocaleString()}</StyledTableCell>
          <StyledTableCell align="center">{shortTermCadence[2].qty.toLocaleString()}</StyledTableCell>
          <StyledTableCell align="center">{shortTermCadence[1].qty.toLocaleString()}</StyledTableCell>
          <StyledTableCell align="center">{shortTermCadence[0].qty.toLocaleString()}</StyledTableCell>

          <StyledTableCell align="center" />

          <StyledTableCell
            align="center"
            style={{ backgroundColor: "#ff5e57", fontWeight: "bold", color: "white", borderWidth: "0px" }}
          >
            {cadenceData.late_day_start.toLocaleString()}
          </StyledTableCell>
          <StyledTableCell align="center">{cadenceData.completed_today.toLocaleString()}</StyledTableCell>
          <StyledTableCell
            align="center"
            style={{ backgroundColor: "#ff5e57", fontWeight: "bold", color: "white", borderWidth: "0px" }}
          >
            {cadenceData.late_day_end.toLocaleString()}
          </StyledTableCell>

          <StyledTableCell align="center" />

          <StyledTableCell align="center">{futureOrdersCadence[0].qty.toLocaleString()}</StyledTableCell>
          <StyledTableCell align="center">{futureOrdersCadence[1].qty.toLocaleString()}</StyledTableCell>
          <StyledTableCell align="center">{futureOrdersCadence[2].qty.toLocaleString()}</StyledTableCell>
        </TableRow>
      );
    }
  }

  reportEmptyRow() {
    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: "#103a21",
        color: "white",
        fontSize: "0.9em",
      },
      body: {
        fontSize: "0.8em",
      },
    }))(TableCell);

    return (
      <TableRow>
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center" />
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center" />
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center"></StyledTableCell>
      </TableRow>
    );
  }

  //Construtor da tabela de Cadência histórica e Panorama atual.
  //Os dados são inseridos com a função reportContentFor

  reportContent() {
    if (this.state.loading === true || !this.state.reportData) {
      return (
        <Col xs sm md lg xl="12" style={{ textAlign: "center" }}>
          <LoadingSpinner />
        </Col>
      );
    }

    return (
      <Box width={window.innerWidth < 1300 ? "100%" : "95%"} mx="auto">
        <Paper style={{ width: "100%", overflowX: "auto", marginTop: "1em", marginBottom: "1em" }}>
          <Table size="small" padding="default">
            <TableHead>
              <TableRow hover>
                <StyledTableCell align="center" />
                <StyledTableCell align="center" colSpan="5">
                  Cadência passado no curto prazo
                </StyledTableCell>
                <StyledTableCell align="center" />
                <StyledTableCell align="center" colSpan="3">
                  Situação Hoje
                </StyledTableCell>
                <StyledTableCell align="center" />
                <StyledTableCell align="center" colSpan="3">
                  Vencimentos não realizados
                </StyledTableCell>
              </TableRow>
              <TableRow hover style={{ textAlign: "center" }}>
                <StyledTableCell align="center" />
                <StyledTableCell variant="head" align="center">
                  D-5
                </StyledTableCell>
                <StyledTableCell align="center">D-4</StyledTableCell>
                <StyledTableCell align="center">D-3</StyledTableCell>
                <StyledTableCell align="center">D-2</StyledTableCell>
                <StyledTableCell align="center">D-1</StyledTableCell>
                <StyledTableCell align="center" />
                <StyledTableCell align="center">
                  Atrasados
                  <br />
                  (Início dia)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Realizados
                  <br />
                  no dia
                </StyledTableCell>
                <StyledTableCell align="center">
                  Atrasados
                  <br />
                  (Final dia)
                </StyledTableCell>
                <StyledTableCell align="center" />
                <StyledTableCell align="center">D+1</StyledTableCell>
                <StyledTableCell align="center">D+2</StyledTableCell>
                <StyledTableCell align="center">{"D>=3"}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.reportContentFor("TOTAL", undefined, undefined, true)}
              {this.reportContentFor("STONE PAGAMENTOS")}
              {this.reportContentFor("PAYTEC")}
              {this.reportContentFor("STONE FRANQUIA")}
              {this.reportEmptyRow()}
              {this.reportContentFor("ENTREGA DIRETA", undefined, "ENTREGA DIRETA")}
              {this.reportContentFor("STONE MEI", undefined, "STONE MAIS")}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Row style={{ marginBottom: "1em" }}>
          <Col lg="10" />
          <Col lg="2">
            <DownloadButton id="serviceOrdersDownload" onClick={(e) => this.downloadServiceOrdersAnalysis()} />
          </Col>
        </Row>
      </Box>
    );
  }

  downloadServiceOrdersAnalysis() {
    this.openModal();

    LogisticService.getLateServiceOrdersAnalysis().then((response) => {
      this.closeModal();
      this.JSONToCSVConvertor(response.data.data, `analitico de oss`, true);
    });
  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "sep=,".concat("\r\n");

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  //Construtor do gráfico de aging dos chamados atrasados
  buildAgingDataGraph(obj) {

    const diasEmAtraso = [];
    const opPropria = [];
    const opTerceira = [];

    obj.data.map(item => ((
      diasEmAtraso.push(item.diasAtraso),
      opPropria.push(item.opPropria),
      opTerceira.push(item.opTerceira)
    )));

    const graphObj = {
      data: {
        labels: diasEmAtraso,
        datasets: [
          {
            label: 'Operação Própria',
            backgroundColor: "#033921",
          data: opPropria,
          },
          {
            label: 'Operação Terceira',
            backgroundColor: "#0DB14B",
          data: opTerceira,
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            stacked: true,
            gridLines: false,
            ticks: {
              padding: 16
            }
          }],
          xAxes: [{
            stacked: true,
            gridLines: false,
            ticks: {
              padding: 16
            }
          }],
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        title: {
          display: false,
          fontColor: "black",
          fontSize: 20,
          text: "Aging dos Chamados em Atraso",
        },
        plugins: {
          datalabels: {
            font: {
              size: 18,
            },
            color: "white",
            anchor: "center",
            align: "center"
          },
        },
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          fontColor: "black",
        },
      },
    };

    const dailyAverageAgingTable = (
      <Table style={{ width: "100%" }}>
        <TableBody>
          <TableRow>
            <TableCell>TMA do dia (Chamados pendentes)</TableCell>
            <TableCell>{obj.dailyAverageAging.toFixed(1)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>TMA MTD</TableCell>
            <TableCell>{obj.mtdAverageAging}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );

    this.setState({
      aging: {
        graph: graphObj,
        loaded: true,
        dailyAverage: dailyAverageAgingTable,
      },
      lastUpdated: moment(),
    });
  }

  //Construtor do gráfico de chamados abertos x chamados fechados
  buildCallOpeningClosingGraph(obj) {
    const openCalls = 0;
    const finishedCalls = 1;
    const pendingTotal = 2;

    const graphObj = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Chamados Abertos",
            backgroundColor: "#DA2200",
            borderColor: "#DA2200",
            hoverBackgroundColor: "#DA2200",
            hoverBorderColor: "#DA2200",
            data: [],
            hidden: true,
          },
          {
            label: "Chamados Finalizados",
            backgroundColor: "#38BB00",
            hoverBackgroundColor: "#38BB00",
            hoverBorderColor: "#38BB00",
            data: [],
          },
          {
            label: "Total Pendentes",
            stack: "Stack 0",
            data: [],
            type: "line",
            fill: false,
            borderColor: "#A8A9AD",
            backgroundColor: "#A8A9AD",
            pointBorderColor: "#A8A9AD",
            pointBackgroundColor: "#A8A9AD",
            pointHoverBackgroundColor: "#A8A9AD",
            pointHoverBorderColor: "#A8A9AD",
            pointRadius: 3,
            pointHoverRadius: 5,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
              },
              ticks: {
                min: 0,
                max: 14,
              },
            },
          ],
        },
        title: {
          display: false,
          fontColor: "black",
          fontSize: 20,
          text: "Abertura Vs Fechamento (k.OSs)",
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0;
            },
            font: {
              size: 15,
            },
            color: "black",
            anchor: "end",
            align: "top",
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "black",
        },
      },
    };

    _.orderBy(obj.data, "date").forEach((element) => {
      graphObj.data.labels.push(moment(element.date).format("DD/MM/YYYY"));
      graphObj.data.datasets[openCalls].data.push(element.opened);
      graphObj.data.datasets[finishedCalls].data.push(element.closed);
      graphObj.data.datasets[pendingTotal].data.push(element.pendingTotal);
    });

    this.setState({
      callOpeningClosing: {
        graph: graphObj,
        loaded: true,
      },
      lastUpdated: moment(),
    });
  }

  //Construtor do gráfico de histórico de chamados atrasados
  buildHistoryOfLateOrders(obj) {
    const movingAverage = 0;
    const lateOrders = 1;

    const graphObj = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Cham. Atrasados / Vencimentos dos últimos 15 dias",
            stack: "Stack 1",
            fill: false,
            hidden: true,
            type: "line",
            yAxisID: "B",
            backgroundColor: "#EC932F",
            borderColor: "#EC932F",
            pointRadius: 0,
            hoverBackgroundColor: "#EC932F",
            hoverBorderColor: "#EC932F",
            data: [],
          },
          {
            label: "Cham. Atrasados",
            stack: "Stack 0",
            fill: true,
            type: "line",
            yAxisID: "A",
            backgroundColor: "#bdecc8",
            borderColor: "#13aa4c",
            hoverBackgroundColor: "#bdecc8",
            hoverBorderColor: "#bdecc8",
            pointRadius: 0,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: (tooltipItem, data) => {
              if ([0].includes(tooltipItem.datasetIndex)) {
                return `${(tooltipItem.yLabel * 100).toFixed(1)}%`;
              }
              return tooltipItem.yLabel;
            },
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              barPercentage: 0.4,
              gridLines: {
                display: true,
                drawBroder: true,
                drawOnChartArea: false,
              },
              ticks: {
                min: 0,
                reverse: true,
              },
            },
          ],
          yAxes: [
            {
              id: "A",
              stacked: false,
              position: "left",
            },
            {
              id: "B",
              stacked: false,
              position: "right",
              ticks: {
                min: 0,
                max: 0.04,
                callback: function (value) {
                  return (value * 100).toFixed(1) + "%"; // convert it to percentage
                },
              },
            },
          ],
        },
        title: {
          display: false,
          fontColor: "black",
          fontSize: 20,
          text: "Histórico de chamados atrasados",
        },
        plugins: {
          datalabels: {
            font: {
              size: 0,
            },
            color: "black",
          },
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            enabled: true,
            mode: "x",
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "black",
        },
      },
    };

    if (this.state.isDesktop) {
      _.orderBy(obj.data, "historyDate", "asc").forEach((element) => {
        graphObj.data.labels.push(moment(element.historyDate).format("DD/MM/YYYY"));
        graphObj.data.datasets[lateOrders].data.push(element.count);

        if (element.historyDate < "2018-06-10") {
          graphObj.data.datasets[movingAverage].data.push(null); //We have trustful information only since june 2018
        } else {
          graphObj.data.datasets[movingAverage].data.push(element.count / element.fortnightDemand);
        }
      });
    } else {
      _.orderBy(obj.data, "historyDate", "asc").forEach((element) => {
        graphObj.data.labels.push(moment(element.historyDate).format("DD/MM/YYYY"));
        graphObj.data.datasets[lateOrders].data.push(element.count);

        if (element.historyDate < "2019-02-10") {
          graphObj.data.datasets[movingAverage].data.push(null); //We have trustful information only since june 2018
        } else {
          graphObj.data.datasets[movingAverage].data.push(element.count / element.fortnightDemand);
        }
      });
    }

    this.setState({
      historyOfLateOrders: {
        graph: graphObj,
        loaded: true,
      },
      lastUpdated: moment(),
    });
  }

  //Construtor do gráfico de histórico completo do SLA
  buildCompleteSLAHistory(obj) {
    const graphObj = {
      data: {
        labels: [],
        datasets: [
          {
            label: "SLA Geral",
            stack: "Stack 0",
            data: [],
            type: "line",
            fill: false,
            borderColor: "#13aa4c",
            backgroundColor: "#13aa4c",
            pointBorderColor: "#13aa4c",
            pointBackgroundColor: "#13aa4c",
            pointHoverBackgroundColor: "#13aa4c",
            pointHoverBorderColor: "#13aa4c",
            pointRadius: 3,
            pointHoverRadius: 5,
            lineTension: 0,
          },
          {
            label: "SLA Op. Própria",
            stack: "Stack 0",
            fill: false,
            hidden: true,
            backgroundColor: "#006600",
            type: "line",
            borderColor: "#006600",
            hoverBackgroundColor: "#006600",
            hoverBorderColor: "#006600",
            pointRadius: 3,
            pointHoverRadius: 5,
            lineTension: 0,
            data: [],
          },
          {
            label: "SLA Op. Terceira",
            stack: "Stack 0",
            fill: false,
            hidden: true,
            backgroundColor: "#000000",
            type: "line",
            borderColor: "#000000",
            hoverBackgroundColor: "#000000",
            hoverBorderColor: "#000000",
            pointRadius: 3,
            pointHoverRadius: 5,
            lineTension: 0,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: (tooltipItem, data) => {
              if ([0, 1, 2].includes(tooltipItem.datasetIndex)) {
                return `${tooltipItem.yLabel.toFixed(1)}%`;
              }
              return `${tooltipItem.yLabel.toFixed(1)}%`;
            },
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              barPercentage: 0.4,
              gridLines: {
                display: true,
                drawBroder: true,
                drawOnChartArea: false,
              },
              ticks: {
                min: "07-2017",
              },
            },
          ],
          yAxes: [
            {
              stacked: false,
              ticks: {
                max: 102,
                min: 75,
                suggestedMin: 5,
                beginAtZero: true,
                callback: function (value) {
                  return value + "%"; // convert to percentage
                },
              },
            },
          ],
        },
        title: {
          display: false,
          fontColor: "black",
          fontSize: 20,
          text: "SLA Histórico",
        },
        plugins: {
          datalabels: {
            font: {
              size: 11,
              weight: "bold",
            },
            color: "black",
            formatter: function (value) {
              return value.toFixed(1);
            },
            align: "top",
            // display: 'auto'
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
            },
            zoom: {
              enabled: true,
              mode: "x",
              speed: 0.1,
            },
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "black",
        },
      },
    };

    const indexSLAGeral = 0;
    const indexSLASelfLogistics = 1;
    const indexSLAThirdParty = 2;

    var slaHistory = obj;
    slaHistory.forEach((element) => {
      element.reference = moment(String(element.month).concat("-", element.year), "MM-YYYY");
      element.globalSla = element.globalSla * 100;
      element.selfLogisticsSla = element.selfLogisticsSla * 100;
      element.thirdOperationSla = element.thirdOperationSla * 100;
    });

    _.orderBy(slaHistory, "reference").forEach((element) => {
      if (element.globalSla !== 0 && element.selfLogisticsSla !== 0 && element.thirdOperationSla !== 0) {
        graphObj.data.labels.push(moment(element.reference).format("MM-YYYY"));
        graphObj.data.datasets[indexSLAGeral].data.push(element.globalSla);
        graphObj.data.datasets[indexSLASelfLogistics].data.push(element.selfLogisticsSla);
        graphObj.data.datasets[indexSLAThirdParty].data.push(element.thirdOperationSla);
      }
    });

    this.setState({
      completeSLAHistory: {
        graph: graphObj,
        loaded: true,
      },
      lastUpdated: moment(),
    });
  }

  //Construtor do gráfico de SLA por operador logístico
  buildMtdSlaGrouped(obj, stone_mei_obj) {
    const sla = 0;
    const revisits = 1;
    const late = 2;
    const graphObj = {
      data: {
        labels: ["STONE PAGAMENTOS", "OPERAÇÃO TERCEIRA", "TOTAL"],
        datasets: [
          {
            label: "%SLA MTD",
            stack: "Stack 0",
            backgroundColor: "#38BB00",
            borderWidth: 2,
            data: [],
          },
          {
            label: "%Revisitas MTD",
            stack: "Stack 0",
            backgroundColor: "#FBE433",
            borderWidth: 2,
            data: [],
          },
          {
            label: "%Atrasados MTD",
            stack: "Stack 0",
            backgroundColor: "#c31900",
            borderColor: "#c31900",
            // hoverBackgroundColor: '#c31900',
            // hoverBorderColor: '#c31900',
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              categoryPercentage: 0.7,
              gridLines: {
                display: true,
              },
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
              },
              ticks: {
                min: 0,
                max: 100,
                callback: function t(value) {
                  return `${value}%`;
                },
              },
            },
          ],
        },
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.datasets[tooltipItem.datasetIndex].label || "";
              if (label) {
                label += ": ";
              }
              const value = `${tooltipItem.yLabel.toFixed(1)}%`;
              return `${label}${value}`;
            },
          },
        },
        title: {
          display: true,
          fontColor: "black",
          fontSize: 20,
          text: "SLA MTD",
        },
        plugins: {
          datalabels: {
            display: (context) => context.datasetIndex !== 1,
            font: {
              size: 20,
            },
            color: "black",
            formatter: (value) => `${value.toFixed(1)}%`,
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "black",
        },
      },
    };

    const stonePayments = obj.data.find((element) => element.operator === "STONE PAGAMENTOS") || {
      mtdLate: 0,
      mtdRevisits: 0,
      mtdSla: 100,
      operator: "STONE PAGAMENTOS",
      total: 0,
    };
    const thirdOperation = obj.data.find((element) => element.operator === "OPERAÇÃO TERCEIRA") || {
      mtdLate: 0,
      mtdRevisits: 0,
      mtdSla: 33,
      operator: "OPERAÇÃO TERCEIRA",
      total: 0,
    };
    const total = obj.data.find((element) => element.operator === "TOTAL") || {
      mtdLate: 0,
      mtdRevisits: 0,
      mtdSla: 33,
      operator: "TOTAL",
      total: 0,
    };
    const stoneFranquia = obj.data.find((element) => element.operator === "STONE FRANQUIA") || {
      mtdLate: 0,
      mtdRevisits: 0,
      mtdSla: 33,
      operator: "STONE FRANQUIA",
      total: 0,
    };
    const PAYTEC = obj.data.find((element) => element.operator === "PAYTEC") || {
      mtdLate: 0,
      mtdRevisits: 0,
      mtdSla: 33,
      operator: "PAYTEC",
      total: 0,
    };
    const stone_mei = stone_mei_obj.data.find((element) => element.operator === "TOTAL") || {
      mtdLate: 0,
      mtdRevisits: 0,
      mtdSla: 100,
      operator: "TOTAL",
      total: 0,
    };

    let entregaDireta = "-";
    const { reportData } = this.state;
    if (reportData && reportData.historic_cadence_data.filter((d) => d.name === "ENTREGA DIRETA").length > 0) {
      entregaDireta = reportData.historic_cadence_data.filter((d) => d.name === "ENTREGA DIRETA")[0];
      entregaDireta = `${parseFloat(entregaDireta.mtd_sla.onTimePct).toFixed(1)}%`;
    }

    if (stonePayments.total > 0 && thirdOperation.total > 0 && total.total > 0) {
      graphObj.data.datasets[sla].data = [stonePayments.mtdSla, thirdOperation.mtdSla, total.mtdSla];
      graphObj.data.datasets[revisits].data = [
        stonePayments.mtdRevisits,
        thirdOperation.mtdRevisits,
        total.mtdRevisits,
      ];
      graphObj.data.datasets[late].data = [stonePayments.mtdLate, thirdOperation.mtdLate, total.mtdLate];
    }

    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: "#103a21",
        color: "white",
        fontSize: "0.9em",
      },
      body: {
        fontSize: "0.8em",
      },
    }))(TableCell);

    const slaByOplTable = (
      <Paper style={{ width: "100%", overflowX: "auto", marginTop: "1em" }}>
        <Table size="medium" padding="default">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">PAYTEC</StyledTableCell>
              <StyledTableCell align="center">STONE FRANQUIA</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell align="center">{PAYTEC.mtdSla.toFixed(1)}%</StyledTableCell>
              <StyledTableCell align="center">{stoneFranquia.mtdSla.toFixed(1)}%</StyledTableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">ENTREGA DIRETA</StyledTableCell>
              <StyledTableCell align="center">STONE MAIS</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell align="center">{entregaDireta}</StyledTableCell>
              <StyledTableCell align="center">{stone_mei.mtdSla.toFixed(1)}%</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );

    this.setState({
      mtdSlaGrouped: {
        graph: graphObj,
        loaded: true,
        slaByOpl: slaByOplTable,
      },
      lastUpdated: moment(),
    });
  }

  //Construtor do gráfico de consumo de máquinas
  buildMachineConsumption(obj) {
    const installation = 0;
    const swap = 1;
    const graphObj = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Instalação",
            stack: "Stack 0",
            backgroundColor: "#38BB00",
            borderWidth: 2,
            data: [],
          },
          {
            label: "Troca",
            stack: "Stack 0",
            backgroundColor: "#DA2200",
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
              },
              ticks: {
                min: 0,
              },
            },
          ],
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        title: {
          display: false,
          fontColor: "black",
          fontSize: 20,
          text: "Consumo Máquina (k.OSs)",
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0;
            },
            font: {
              size: 14,
            },
            color: "black",
            formatter: function (value) {
              return parseFloat(value).toFixed(1);
            },
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "black",
        },
      },
    };

    _.orderBy(obj.consumption, "date").forEach((element) => {
      graphObj.data.labels.push(moment(element.date).format("DD/MM/YYYY"));
      graphObj.data.datasets[installation].data.push(element.installation);
      graphObj.data.datasets[swap].data.push(element.swap);
    });

    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: "#103a21",
        color: "white",
        fontSize: "0.9em",
      },
      body: {
        fontSize: "0.8em",
      },
    }))(TableCell);

    //Tabela, com consumo médio de máquinas em dias úteis e não úteis, com quebra por tipo de OS
    const tableMachineConsumption = (
      <Paper style={{ width: "100%", overflowX: "auto", marginTop: "1em" }}>
        <Table size="small" padding="default">
          <TableHead>
            <TableRow>
              <StyledTableCell>Tipo</StyledTableCell>
              <StyledTableCell>Dia útil</StyledTableCell>
              <StyledTableCell>Dia não útil</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>Instalação</StyledTableCell>
              <StyledTableCell align="center">
                {obj.average.averageInstallationWorkingDays.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </StyledTableCell>
              <StyledTableCell align="center">
                {obj.average.averageInstallationWeekends.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Troca</StyledTableCell>
              <StyledTableCell align="center">
                {obj.average.averageExchangeWorkingDays.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </StyledTableCell>
              <StyledTableCell align="center">
                {obj.average.averageExchangeWeekends.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Desinstalação</StyledTableCell>
              <StyledTableCell align="center">
                {obj.average.averageUninstallationWorkingDays.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </StyledTableCell>
              <StyledTableCell align="center">
                {obj.average.averageUninstallationWeekends.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell style={{ fontWeight: "bold" }}>Consumo Médio</StyledTableCell>
              <StyledTableCell align="center" style={{ fontWeight: "bold" }}>
                {obj.average.averageConsumedWorkingDays.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ fontWeight: "bold" }}>
                {obj.average.averageConsumedWeekends.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell style={{ fontWeight: "bold" }}>Retorno Médio</StyledTableCell>
              <StyledTableCell align="center" style={{ fontWeight: "bold" }}>
                {obj.average.averageReturnedWorkingDays.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ fontWeight: "bold" }}>
                {obj.average.averageReturnedWeekends.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell style={{ fontWeight: "bold" }}>Consumo Líquido</StyledTableCell>
              <StyledTableCell align="center" style={{ fontWeight: "bold" }}>
                {(
                  obj.average.averageConsumedWorkingDays - obj.average.averageReturnedWorkingDays
                ).toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ fontWeight: "bold" }}>
                {(obj.average.averageConsumedWeekends - obj.average.averageReturnedWeekends).toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div style={{ textAlign: "right" }}>
          <span style={{ fontSize: "0.8em", marginRight: "1em" }}>* últimos 14 dias corridos</span>
        </div>
      </Paper>
    );

    this.setState({
      machineConsumption: {
        graph: graphObj,
        loaded: true,
        tableMachineConsumption: tableMachineConsumption,
      },
      lastUpdated: moment(),
    });
  }

  buildRealizedOSHistory(obj) {
    const graphObj = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Redirect",
            stack: "Stack 0",
            backgroundColor: "#E0B42F",
            borderWidth: 2,
            data: [],
          },
          {
            label: "Angels",
            stack: "Stack 0",
            backgroundColor: "#018f01",
            borderWidth: 2,
            data: [],
          },
          {
            label: "Terceira",
            stack: "Stack 0",
            backgroundColor: "#004C09",
            borderWidth: 2,
            data: [],
          },
          {
            label: "Franquias",
            stack: "Stack 0",
            backgroundColor: "#0070D0",
            borderWidth: 2,
            data: [],
          },
          {
            label: "Delivery",
            stack: "Stack 0",
            backgroundColor: "#8E968C",
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              type: "linear",
              stacked: true,
              gridLines: {
                display: true,
              },
              ticks: {
                max: 100,
                min: 0,
                callback: function t(value) {
                  return `${parseFloat(value).toFixed(1)}%`;
                },
              },
            },
          ],
        },
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";

              // if (label) {
              //     label += ': ';
              // }
              // label += Math.round(tooltipItem.yLabel * 100) / 100;
              label = tooltipItem.yLabel.toFixed(1) + "%";
              return label;
            },
          },
        },
        title: {
          display: false,
          fontColor: "black",
          fontSize: 20,
          text: "Histórico de OSs Realizadas por Angels",
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0;
            },
            align: "center",
            font: {
              size: 12,
            },
            color: "white",
            formatter: function (value) {
              return `${parseFloat(value).toFixed(1)}%`;
            },
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "black",
        },
      },
    };

    _.orderBy(obj, "semana").forEach((element) => {
      var data = element.periodo.split('-');
      var mes = data[1];
      var ano = data[0];
      graphObj.data.labels.push(mes + "/" + ano);
      graphObj.data.datasets[0].data.push(element.redirect * 100);
      graphObj.data.datasets[1].data.push(element.angels * 100);
      graphObj.data.datasets[2].data.push(element.third * 100);
      graphObj.data.datasets[3].data.push(element.franchise * 100);
      graphObj.data.datasets[4].data.push(element.delivery * 100);
    });

    this.setState({
      realizedHistory: {
        graph: graphObj,
        loaded: true,
      },
      lastUpdated: moment(),
    });
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderRealizedOSHistory() {
    if (this.state.realizedHistory.loaded) {
      return (
        <Box width={this.state.isDesktop ? "80%" : "100%"} height="80%" mx="auto">
          <h3 style={{ textAlign: "center" }}>Histórico de OSs Realizadas por Angels</h3>
          <div style={{ padding: "1em" }}>
            <Graph
              data={this.state.realizedHistory.graph.data}
              legend={this.state.realizedHistory.graph.legend}
              options={this.state.realizedHistory.graph.options}
              width={this.state.isDesktop ? 500 : 310}
              height={450}
            />
          </div>
        </Box>
      );
    }

    return (
      <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
        <LoadingSpinner />
      </div>
    );
  }

  //Renderiza o gráfico com aging dos chamados em atraso
  renderAgingGraph() {
    if (this.state.aging.loaded) {
      return (
        <div>
          <h3 style={{ textAlign: "center" }}>Aging dos Chamados em Atraso</h3>
          <div>
            <Graph
              data={this.state.aging.graph.data}
              width={this.state.isDesktop ? 500 : 310}
              height={450}
              legend={this.state.aging.graph.legend}
              options={this.state.aging.graph.options}
            />
          </div>
          {this.renderDailyAverageTable()}
        </div>
      );
    }

    return (
      <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
        <LoadingSpinner />
      </div>
    );
  }

  //Renderiza o gráfico com histórico de chamados abertos e fechados
  renderCallOpeningClosingGraph() {
    if (this.state.callOpeningClosing.loaded) {
      return (
        <div>
          <h3 style={{ textAlign: "center" }}>Abertura Vs Fechamento (k.OSs)</h3>
          <div>
            <Graph
              data={this.state.callOpeningClosing.graph.data}
              legend={this.state.callOpeningClosing.graph.legend}
              options={this.state.callOpeningClosing.graph.options}
              width={this.state.isDesktop ? 500 : 310}
              height={450}
            />
          </div>
        </div>
      );
    }

    return (
      <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
        <LoadingSpinner />
      </div>
    );
  }

  //Renderiza 2 mapas (Demanda Dia/Cadência Histórica e % de chamados ainda não realizados)
  renderMaps(viewBox, width, height) {
    const { data, loaded } = this.state.tmaMap;
    const { redirects_data, redirects_loaded } = this.state.redirectsMap;
    if (loaded === false || redirects_loaded === false || redirects_data === undefined) {
      return (
        <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
          <LoadingSpinner />
        </div>
      );
    }

    const redirects_states = redirects_data.data;

    var total_service_orders = 0;
    var redirect_service_orders = 0;

    Object.keys(redirects_states).forEach((state) => {
      total_service_orders += redirects_states[state].total_qty;
      redirect_service_orders += redirects_states[state].redirects_qty;
    });

    const pending_states = data;

    var total_pending = 0;
    var total_demand = 0;

    Object.keys(pending_states).forEach((state) => {
      total_pending += pending_states[state].todays_pending;
      total_demand += pending_states[state].todays_demands;
    });

    const redirects_percentage = redirect_service_orders / total_service_orders;
    const pending_percentage = total_pending / total_demand;
    return (
      <Row>
        <Col xs sm md="12" lg xl="6" align="center">
          <Carousel
            indicators={false}
            nextIcon={<NavigateNextIcon color="secondary" />}
            prevIcon={<NavigateBeforeIcon color="secondary" />}
          >
            <Carousel.Item style={{ height: "576px" }}>
              <h2 style={{ textAlign: "center" }}>% de Redirects por UF</h2>
              <BrazilCmp data={redirects_states} type={5} viewBox={viewBox} width={width} height={height} />
              <div style={{ textAlign: "center" }}>
                <span style={{ color: "#18641e", fontWeight: "bold" }}> 0 - 5% </span>
                <span style={{ color: "#000000", fontWeight: "bold" }}>|</span>
                <span style={{ color: "#c2b600", fontWeight: "bold" }}> 5% - 10% </span>
                <span style={{ color: "#000000", fontWeight: "bold" }}>|</span>
                <span style={{ color: "#c31900", fontWeight: "bold" }}> 10% - 100% </span>
              </div>
            </Carousel.Item>
            <Carousel.Item style={{ height: "576px" }}>
              <h2 style={{ textAlign: "center" }}>% de Redirects GERAL</h2>
              <Box width="60%" mx="auto" marginTop="20%" marginBottom="auto">
                <Typography variant="h1" component="h2">
                  {(100 * redirects_percentage).toFixed(1)}%
                </Typography>
              </Box>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col xs sm md="12" lg xl="6" align="center">
          <Carousel
            indicators={false}
            nextIcon={<NavigateNextIcon color="secondary" />}
            prevIcon={<NavigateBeforeIcon color="secondary" />}
          >
            <Carousel.Item style={{ height: "576px" }}>
              <h2 style={{ textAlign: "center" }}>Tempo Médio de Atendimento (TMA)</h2>
              <Box width="60%" mx="auto" marginTop="20%" marginBottom="auto">
                <Typography variant="h1" component="h2">
                  {data.find((e) => e.countryState === null)["tma"].toFixed(2)}
                </Typography>
              </Box>
            </Carousel.Item>
            <Carousel.Item style={{ height: "576px" }}>
              <h2 style={{ textAlign: "center" }}>Tempo Médio de Atendimento (TMA)</h2>
              <BrazilCmp data={data} type={9} viewBox={viewBox} width={width} height={height} />
              <div style={{ textAlign: "center" }}>
                <span style={{ color: "#18641e", fontWeight: "bold" }}> Excelente </span>
                <span style={{ color: "#000000", fontWeight: "bold" }}>|</span>
                <span style={{ color: "#12a505", fontWeight: "bold" }}> Muito bom </span>
                <span style={{ color: "#000000", fontWeight: "bold" }}>|</span>
                <span style={{ color: "#c2b600", fontWeight: "bold" }}> Atenção </span>
                <span style={{ color: "#000000", fontWeight: "bold" }}>|</span>
                <span style={{ color: "#c31900", fontWeight: "bold" }}> Risco</span>
              </div>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    );
  }

  //Renderiza o mapa de SLA por estado
  renderSlaMap(viewBox, width, height) {
    const { data, regions, loaded } = this.state.mtdSlaMap;
    if (loaded === false) {
      return (
        <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
          <LoadingSpinner />
        </div>
      );
    }

    var sortable = [];
    for (var region in regions) {
      sortable.push([region, regions[region]["sla"]]);
    }

    sortable.sort(function (a, b) {
      return a[1] - b[1];
    });

    sortable.reverse();

    var counter = 0;
    return (
      <div>
        <h2 style={{ margin: "24px 0 10px 0" }}>% SLA MTD</h2>
        <Carousel
          indicators={false}
          nextIcon={<NavigateNextIcon color="secondary" />}
          prevIcon={<NavigateBeforeIcon color="secondary" />}
        >
          <Carousel.Item style={{ height: "576px" }}>
            <Box width="60%" mx="auto" marginTop="20%" marginBottom="auto">
              <Table size="small">
                <TableHead>
                  <StyledTableCell align="center">#</StyledTableCell>
                  <StyledTableCell align="center">Regional</StyledTableCell>
                  <StyledTableCell align="center">%</StyledTableCell>
                </TableHead>
                <TableBody>
                  {sortable.map((region) => {
                    if (region[0] !== "ESCRITORIO" && region[0] !== "null") {
                      counter = counter + 1;
                      return (
                        <TableRow>
                          <StyledTableCell align="center">{counter}</StyledTableCell>
                          <StyledTableCell align="center">{region[0]}</StyledTableCell>
                          <StyledTableCell align="center">{region[1]}%</StyledTableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  })}
                </TableBody>
              </Table>
            </Box>
          </Carousel.Item>
          <Carousel.Item style={{ height: "576px" }}>
            <BrazilCmp data={data} type={3} viewBox={viewBox} width={width} height={height} />
            <div style={{ textAlign: "center" }}>
              <span style={{ color: "#A8A9AD", fontWeight: "bold" }}> # chamados {"<"} 0,5% do total </span>
              <span style={{ color: "#000000", fontWeight: "bold" }}>|</span>
              <span style={{ color: "#c31900", fontWeight: "bold" }}> 0 - 92% </span>
              <span style={{ color: "#000000", fontWeight: "bold" }}>|</span>
              <span style={{ color: "#FBE433", fontWeight: "bold" }}> 92 - 95% </span>
              <span style={{ color: "#000000", fontWeight: "bold" }}>|</span>
              <span style={{ color: "#18641e", fontWeight: "bold" }}> 95 - 100%</span>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }

  renderSelfLogisticsMap(viewBox, width, height) {
    const { data, loaded } = this.state.selfLogisticsMap;
    if (loaded === false) {
      return (
        <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
          <LoadingSpinner />
        </div>
      );
    }
    let selfLogisticsCount = 0;
    let franchiseCount = 0;
    let serviceOrdersTotal = 0;
    Object.keys(data).forEach((key) => {
      selfLogisticsCount += data[key].self_logistics_count;
      franchiseCount += data[key].franchise_count;
      serviceOrdersTotal += data[key].service_orders_total;
    });
    let totalWithoutFranchise = 0;
    let totalWithFranchise = 0;
    if (serviceOrdersTotal !== 0) {
      // totalPercentage = _.round(100* (totalSelfLogistics/serviceOrdersTotal), 1)
      totalWithoutFranchise = _.round(100 * (selfLogisticsCount / serviceOrdersTotal), 1);
      totalWithFranchise = _.round(100 * ((selfLogisticsCount + franchiseCount) / serviceOrdersTotal), 1);
    }

    return (
      <div>
        <h2 style={{ margin: "24px 0 10px 0" }}>% Operação Própria </h2>
        <Carousel
          indicators={false}
          nextIcon={<NavigateNextIcon color="secondary" />}
          prevIcon={<NavigateBeforeIcon color="secondary" />}
        >
          <Carousel.Item style={{ height: "576px" }}>
            <BrazilCmp data={data} type={4} viewBox={viewBox} width={width} height={height} />
            <div style={{ textAlign: "center" }}>
              <span style={{ color: "#C31900", fontWeight: "bold" }}> 0 - 80% </span>
              <span style={{ color: "#000000", fontWeight: "bold" }}>|</span>
              <span style={{ color: "#FBE433", fontWeight: "bold" }}> 80 - 90% </span>
              <span style={{ color: "#000000", fontWeight: "bold" }}>|</span>
              <span style={{ color: "#93CC2F", fontWeight: "bold" }}> 90 - 95% </span>

              <span style={{ color: "#000000", fontWeight: "bold" }}>|</span>
              <span style={{ color: "#18641e", fontWeight: "bold" }}> 95 - 100%</span>
            </div>
          </Carousel.Item>
          <Carousel.Item style={{ height: "576px" }}>
            <Box width="60%" mx="auto" marginTop="20%" marginBottom="auto">
              <p style={{ fontSize: "1.4em" }}>
                <b>Sem</b> Franquias <Badge variant="secondary">{totalWithoutFranchise}%</Badge>
              </p>
              <p style={{ fontSize: "1.4em" }}>
                <b>Com</b> Franquias <Badge variant="secondary">{totalWithFranchise}%</Badge>
              </p>
            </Box>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }

  //Renderiza o gráfico com todo o SLA histórico
  renderSLACompleteHistory() {
    if (this.state.completeSLAHistory.loaded) {
      return (
        <Box width={this.state.isDesktop ? "80%" : "100%"} height="80%" mx="auto">
          <div>
            <Graph
              data={this.state.completeSLAHistory.graph.data}
              legend={this.state.completeSLAHistory.graph.legend}
              options={this.state.completeSLAHistory.graph.options}
              width={100}
              height={500}
            />
          </div>
        </Box>
      );
    }
  }

  //Renderiza o gráfico com histórico de chamados atrasados
  renderHistoryOfLate() {
    if (this.state.historyOfLateOrders.loaded) {
      return (
        <Box width={this.state.isDesktop ? "80%" : "100%"} height="80%" mx="auto">
          <h3 style={{ textAlign: "center" }}>Histórico de chamados atrasados</h3>
          <div>
            <Graph
              data={this.state.historyOfLateOrders.graph.data}
              legend={this.state.historyOfLateOrders.graph.legend}
              options={this.state.historyOfLateOrders.graph.options}
              width={100}
              height={500}
            />
          </div>
        </Box>
      );
    }

    return (
      <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
        <LoadingSpinner />
      </div>
    );
  }

  //Renderiza o gráfico em barras do SLA quebrado por operador logístico
  renderMtdSla() {
    if (this.state.mtdSlaGrouped.loaded) {
      return (
        <div>
          <Graph
            data={this.state.mtdSlaGrouped.graph.data}
            width={100}
            height={500}
            legend={this.state.mtdSlaGrouped.graph.legend}
            options={this.state.mtdSlaGrouped.graph.options}
          />
        </div>
      );
    }

    return (
      <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
        <LoadingSpinner />
      </div>
    );
  }

  //Renderiza a tabela de SLA quebrado por operador logístico (Operação terceira)
  renderMtdSlaTable() {
    if (this.state.mtdSlaGrouped.loaded) {
      return (
        <Box width={this.state.isDesktop ? "90%" : "100%"} mx="auto">
          <div>{this.state.mtdSlaGrouped.slaByOpl}</div>
        </Box>
      );
    }

    return (
      <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
        <LoadingSpinner />
      </div>
    );
  }

  renderDailyAverageTable() {
    if (this.state.aging.loaded) {
      return <div>{this.state.aging.dailyAverage}</div>;
    }

    return (
      <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
        <LoadingSpinner />
      </div>
    );
  }

  //Renderiza o gráfico em barras com consumo histórico de terminais
  renderMachineConsumption() {
    if (this.state.machineConsumption.loaded) {
      return (
        <div>
          <h3 style={{ textAlign: "center" }}>Consumo Máquina (k.OSs)</h3>
          <div>
            <Graph
              data={this.state.machineConsumption.graph.data}
              legend={this.state.machineConsumption.graph.legend}
              options={this.state.machineConsumption.graph.options}
              width={this.state.isDesktop ? 500 : 310}
              height={450}
            />
          </div>
        </div>
      );
    }

    return (
      <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
        <LoadingSpinner />
      </div>
    );
  }

  //Renderiza a tabela, com consumo médio de máquinas em dias úteis e não úteis, com quebra por tipo de OS
  renderMachineConsumptionTable() {
    if (this.state.machineConsumption.loaded) {
      return <div>{this.state.machineConsumption.tableMachineConsumption}</div>;
    }

    return (
      <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
        <LoadingSpinner />
      </div>
    );
  }

  renderSLABlock() {
    if (!this.state.completeSLAHistory.loaded || !this.state.mtdSlaGrouped.loaded) {
      return (
        <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <Row>
        <Col xl="8" xs sm md lg="12">
          <h3 style={{ textAlign: "center" }}>SLA - Histórico</h3>
          {this.renderSLACompleteHistory()}
        </Col>
        <Col lg xl="4" xs sm md="12">
          <h3 style={{ textAlign: "center" }}>SLA - Terceiros</h3>
          <div style={{ marginTop: "20%" }}>{this.renderMtdSlaTable()}</div>
        </Col>
      </Row>
    );
  }

  renderMapsBlock() {
    if (
      !this.state.tmaMap.loaded ||
      !this.state.redirectsMap.redirects_loaded ||
      !this.state.mtdSlaMap.loaded ||
      !this.state.selfLogisticsMap.loaded
    ) {
      return (
        <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
          <LoadingSpinner />
        </div>
      );
    }

    let viewBox = undefined;
    let width = undefined;
    let height = undefined;

    if (window.screen.availWidth < 1300) {
      viewBox = "0 0 500 500";
      width = "310";
      height = "400";
    } else {
      viewBox = "0 0 480 500";
      width = "460";
      height = "480";
    }

    return (
      <div>
        {this.renderMaps(viewBox, width, height)}
        <Row>
          <Col xs sm md="12" lg xl="6" align="center">
            {this.renderSlaMap(viewBox, width, height)}
          </Col>
          <Col xs sm md="12" lg xl="6" align="center">
            {this.renderSelfLogisticsMap(viewBox, width, height)}
          </Col>
        </Row>
      </div>
    );
  }

  renderAgingBlock() {
    if (!this.state.aging.loaded || !this.state.callOpeningClosing.loaded) {
      return (
        <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <Row>
        <Col xs sm md="12" lg xl="4" align="center">
          {this.renderAgingGraph()}
        </Col>
        <Col xs sm md="12" lg xl="8" align="center">
          {this.renderCallOpeningClosingGraph()}
        </Col>
      </Row>
    );
  }

  renderMachineConsumptionBlock() {
    if (!this.state.machineConsumption.loaded) {
      return (
        <Col xs sm md lg xl="12" style={{ textAlign: "center" }}>
          <LoadingSpinner />
        </Col>
      );
    }

    return (
      <Row>
        <Col md lg xl="7" xs sm="12">
          <Box width="90%" height="50%" mx="auto">
            {this.renderMachineConsumption()}
          </Box>
        </Col>
        <Col md lg xl="5" xs sm="12" style={{ marginTop: "auto", marginBottom: "auto" }}>
          <Box width="80%" mx="auto">
            {this.renderMachineConsumptionTable()}
          </Box>
        </Col>
      </Row>
    );
  }

  buildScoreCsatPerOPL(obj) {
    const graphObj = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Total",
            stack: "Stack 0",
            borderColor: "red",
            backgroundColor: "red",
            pointBorderColor: "red",
            pointBackgroundColor: "red",
            pointHoverBackgroundColor: "red",
            pointHoverBorderColor: "red",
            borderWidth: 2,
            data: [],
            type: "line",
            fill: false,
          },
          {
            label: "Pontuação",
            stack: "Stack 0",
            backgroundColor: "#008000",
            borderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
              },
              ticks: {
                max: 100,
              },
            },
          ],
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        title: {
          display: false,
          fontColor: "black",
          fontSize: 20,
          text: "Pontuação - Operadores Logísticos",
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0;
            },
            // display: false,
            font: {
              size: 13,
            },
            color: "black",
            // anchor: 'end',
            align: "top",
            formatter: function (value) {
              return value;
            },
          },
        },
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          fontColor: "black",
        },
      },
    };

    _.orderBy(obj, "score")
      .reverse()
      .forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.operator));
        graphObj.data.datasets[1].data.push(element.score);
        graphObj.data.datasets[0].data.push(element.total);
      });

    this.setState({
      scoreCsat: {
        graph: graphObj,
        loaded: true,
      },
      lastUpdated: moment(),
    });
  }

  //Renderiza o gráfico de status de OS por data de abertura
  renderScoreCsatPerOPL() {
    if (this.state.scoreCsat.loaded) {
      return (
        <Box width={this.state.isDesktop ? "80%" : "100%"} height="80%" mx="auto">
          <h3 style={{ textAlign: "center" }}>Pontuação - Operadores Logísticos</h3>
          <div>
            <Graph
              data={this.state.scoreCsat.graph.data}
              legend={this.state.scoreCsat.graph.legend}
              options={this.state.scoreCsat.graph.options}
              width={this.state.isDesktop ? 500 : 310}
              height={500}
            />
          </div>
        </Box>
      );
    }

    return (
      <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
        <LoadingSpinner />
      </div>
    );
  }

  buildCsatAnswerPercent(obj) {
    const graphObj = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Taxa de Resposta",
            stack: "Stack 0",
            data: [],
            type: "line",
            yAxisID: "B",
            fill: false,
            borderColor: "red",
            backgroundColor: "red",
            pointBorderColor: "red",
            pointBackgroundColor: "red",
            pointHoverBackgroundColor: "red",
            pointHoverBorderColor: "red",
            pointRadius: 3,
            pointHoverRadius: 5,
          },
          {
            label: "Número de Respostas",
            stack: "Stack 0",
            backgroundColor: "green",
            borderWidth: 2,
            data: [],
            hidden: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              categoryPercentage: 0.9,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              id: "A",
              stacked: true,
              position: "left",
              ticks: {
                min: 0,
                suggestedMax: 10000,
                callback: function t(value) {
                  return `${value}`;
                },
              },
            },
            {
              id: "B",
              stacked: true,
              position: "right",
              ticks: {
                min: 0,
                suggestedMax: 15,
                callback: function t(value) {
                  return `${value}%`;
                },
              },
            },
          ],
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        title: {
          display: false,
          fontColor: "black",
          fontSize: 20,
          text: "Taxa de Respostas - Operadores Logísticos",
        },
        plugins: {
          datalabels: {
            display: function (graphObj) {
              return graphObj.dataset.data[graphObj.dataIndex] > 0;
            },
            font: {
              size: 12,
              color: "black",
            },
            color: "black",
            anchor: "end",
            align: "top",
            formatter: function (value) {
              return parseFloat(value).toFixed(1);
            },
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "black",
        },
      },
    };

    _.orderBy(obj, "answers")
      .reverse()
      .forEach((element) => {
        graphObj.data.labels.push(" ".concat(element.logisticOperator));
        graphObj.data.datasets[1].data.push(element.answers);
        graphObj.data.datasets[0].data.push(element.percent);
      });

    this.setState({
      csatAnswer: {
        graph: graphObj,
        loaded: true,
      },
      lastUpdated: moment(),
    });
  }

  //Renderiza o gráfico de histórico de acessos
  renderCsatAnswerPercent(obj) {
    if (this.state.csatAnswer.loaded) {
      return (
        <Box width={this.state.isDesktop ? "80%" : "100%"} height="80%" mx="auto">
          <h3 style={{ textAlign: "center" }}>Taxa de Respostas - Operadores Logísticos</h3>
          <div>
            <Graph
              data={this.state.csatAnswer.graph.data}
              legend={this.state.csatAnswer.graph.legend}
              options={this.state.csatAnswer.graph.options}
              width={this.state.isDesktop ? 500 : 310}
              height={500}
            />
          </div>
        </Box>
      );
    }

    return (
      <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
        <LoadingSpinner />
      </div>
    );
  }

  renderCsatBlock() {
    if (!this.state.scoreCsat.loaded) {
      return (
        <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}>
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <Row>
        <Col xl lg md="6" xs sm="12">
          {this.renderScoreCsatPerOPL()}
        </Col>
        <Col xl lg md="6" xs sm="12">
          {this.renderCsatAnswerPercent()}
        </Col>
      </Row>
    );
  }

  render() {
    let refreshContent;
    let lastUpdatedText = "";

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format("DD/MM HH:mm")}`;
    }

    if (!this.state.loading) {
      refreshContent = (
        <div style={{ textAlign: "right", marginBottom: "1em" }}>
          <label style={{ marginRight: "1em" }}>{lastUpdatedText}</label>
          <RefreshButton
            onClick={() => {
              this.refreshReportData();
            }}
          />
        </div>
      );
    }

    return (
      <Container fluid>
        <Col lg="12" xs="12" sm="12">
          {refreshContent}
          <CustomizedExpansionPannel
            title="Panorama Atual"
            content={this.reportContent()}
            helpMessage={
              <p>
                Visão Resumida dos chamados. Leva em conta apenas demanda BAU (Instalação, Desinstalação, Troca e
                Manutenção).
                <br />
                <b>Atrasados (Início dia):</b> Chamados com data limite cliente menor ou igual à data de hoje.
                <br />
                <b>Atrasados (Final dia):</b> Chamados atrasados + Chamados que vencem hoje
              </p>
            }
            tooltip={true}
          />
          <CustomizedExpansionPannel title="SLA" content={this.renderSLABlock()} />
          <CustomizedExpansionPannel title="Chamados Atrasados" content={this.renderHistoryOfLate()} />
          <CustomizedExpansionPannel title="Panorama por UF" content={this.renderMapsBlock()} />
          <CustomizedExpansionPannel title="Chamados Abertos x Fechados" content={this.renderAgingBlock()} />
          <CustomizedExpansionPannel title="Consumo de Máquinas" content={this.renderMachineConsumptionBlock()} />
          <CustomizedExpansionPannel title="Própria e Redirect" content={this.renderRealizedOSHistory()} />
          <CustomizedExpansionPannel title="Pesquisa de satisfação" content={this.renderCsatBlock()} />
        </Col>
      </Container>
    );
  }
}

export default LastMileReport;
