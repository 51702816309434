/* eslint-disable */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { styles } from './style'
import PropTypes from 'prop-types'
import LogisticService from '../../core/services/LogisticService'
import LoadingSpinner from '../../components/LoadingSpinner'
import RefreshButton from '../../custom-components/RefreshButton'
import { Container, Col, Row } from 'react-bootstrap'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#103a21',
    color: 'white',
    fontSize: '0.9em',
    textAlign: 'center'
  },
  body: {
    fontSize: '0.9em',
    textAlign: 'center'
  },
}))(TableCell);

class PaytecProvidersWallboard extends Component {
  constructor () {
    super()

    this.state = {
      today: moment().format('YYYY-MM-DD'),
      state: '',
      loading: false,
      providers: undefined,
      lastUpdated: undefined,
    }

    this.refreshReportData = this.refreshReportData.bind(this)
  }

  componentDidMount () {
    this.refreshReportData()
  }

  refreshReportData () {

    this.setState({
      today: moment().format('YYYY-MM-DD'),
      loading: true,
      providers: undefined,
      lastUpdated: undefined,
    })

    LogisticService.getOsWallboard('provider', 'STONE PAGAMENTOS', 'PAYTEC', this.state.today, this.props.match.params.stateName )
      .then((response) => {
          console.log(this.props)
            this.setState({
              lastUpdated: moment(),
              providers: response.data.data,
            })

          })

  }

  reportContent () {
    if (!this.state.providers) {
      return (
        <div style={{margin: '2em', textAlign: 'center'}}>
          <LoadingSpinner/>
        </div>
      )
    }

    const orderedProviders = this.state.providers.sort(function(a, b){
        let keyA = a.atrasada,
            keyB = b.atrasada;
        // Compare the 2 keys
        if(keyA < keyB) return 1;
        if(keyA > keyB) return -1;
        return 0;
    });


    const results = orderedProviders.map(provider => {
      if (!((provider.atendidasHoje == 0 && provider.atrasada == 0
         && provider.carteiraDia == 0) || provider.groupName == null)) {
        return (
            <TableRow>
              <StyledTableCell>
                <Link to={`/paytec/provider/${this.props.match.params.stateName}/${provider.groupName}`}>
                  {provider.groupName}
                </Link>
              </StyledTableCell>
              <StyledTableCell>{provider.atendidasHoje}</StyledTableCell>
              <StyledTableCell>{provider.atrasada}</StyledTableCell>
              <StyledTableCell>{provider.atrasadaEmCampo}</StyledTableCell>
              <StyledTableCell>{provider.carteiraDia}</StyledTableCell>
              <StyledTableCell>{provider.carteiraDiaEmCampo}</StyledTableCell>
              <StyledTableCell>{(provider.slaMtd *100).toFixed(1)}%</StyledTableCell>
            </TableRow>
          )
        }
        else
          return
        })

    return (
      <Paper style={{width: '100%', overflowX: 'auto', marginTop: '1em'}}>
        <Table>
          <TableHead>
          <TableRow>
              <StyledTableCell colSpan="2" style={{backgroundColor: 'white'}}/>
              <StyledTableCell colSpan="2" style={{ backgroundColor: 'white', color: 'gray'}}>Atrasados</StyledTableCell>
              <StyledTableCell colSpan="2" style={{ backgroundColor: 'white', color: 'gray'}}>Vencem hoje</StyledTableCell>
              <StyledTableCell style={{backgroundColor: 'white'}}/>
            </TableRow>
            <TableRow>
              <StyledTableCell>Base</StyledTableCell>
              <StyledTableCell>Feitos no dia</StyledTableCell>
              <StyledTableCell style={{ borderLeft: 'solid' }}>Total</StyledTableCell>
              <StyledTableCell>Em Campo</StyledTableCell>
              <StyledTableCell style={{ borderLeft: 'solid' }}>Total</StyledTableCell>
              <StyledTableCell>Em Campo</StyledTableCell>
              <StyledTableCell style={{ borderLeft: 'solid' }}>SLA MTD</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>TOTAL</StyledTableCell>
              <StyledTableCell>{orderedProviders.reduce((acumulator,currentValue) => acumulator + currentValue.atendidasHoje, 0)/2}</StyledTableCell>
              <StyledTableCell>{orderedProviders.reduce((acumulator,currentValue) => acumulator + currentValue.atrasada, 0)/2}</StyledTableCell>
              <StyledTableCell>{orderedProviders.reduce((acumulator,currentValue) => acumulator + currentValue.atrasadaEmCampo, 0)/2}</StyledTableCell>
              <StyledTableCell>{orderedProviders.reduce((acumulator,currentValue) => acumulator + currentValue.carteiraDia, 0)/2}</StyledTableCell>
              <StyledTableCell>{orderedProviders.reduce((acumulator,currentValue) => acumulator + currentValue.carteiraDiaEmCampo, 0)/2}</StyledTableCell>
              <StyledTableCell>{(orderedProviders[0].slaMtd * 100).toFixed(1)}%</StyledTableCell>
            </TableRow>
            {results}
          </TableBody>
        </Table>
      </Paper>
    )
  }

  render () {
    let refreshContent
    let lastUpdatedText = ''

    if (this.state.lastUpdated) {
      lastUpdatedText = ` Atualizado ${this.state.lastUpdated.format('DD/MM HH:mm')}`
    }

    // if (!this.state.states) {
      refreshContent = (
        <div style={{textAlign: 'right', marginBottom: '1em'}}>
          <label style={{margin:'1em'}}><i>{lastUpdatedText}</i></label>
          <RefreshButton onClick={() => { this.refreshReportData() }}/>
        </div>
      )
    // }

    return (
      <Container fluid>
        <Col xs sm md lg xl="12">
          {refreshContent}
        </Col>
        <Col xs sm md lg xl="12">
          {this.reportContent()}
        </Col>
      </Container>
    )
  }
}


PaytecProvidersWallboard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
}


export default PaytecProvidersWallboard
