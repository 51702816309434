/* eslint-disable */
import React, { Component } from 'react'
import moment from 'moment'
import LogisticService from '../../../core/services/LogisticService'
import style from '../style.css'
import Graph from '../../../components/Logistic/graphic/graph'
import LoadingSpinner from '../../../components/LoadingSpinner'

import Box from '@material-ui/core/Box';

class D0 extends Component {
constructor(props) {
    super(props);
    this.state = {
        district: props.district,
        date: props.date,
        d0:{
        loaded:false
        },
        graph: undefined,
        loading: false,
        lastUpdated: undefined,
    };
    this.refreshReportData = this.refreshReportData.bind(this)
}
refreshReportData() {
    //Get Attendance D0
    LogisticService.getLmpAttendance('d0',this.state.district,null,`${this.state.date}-01`,`${this.state.date}-01`).then((response)=>{
      this.setState({
        d0:{
                graph: this.buildD0Graph(response.data.data),
                loaded: true
          },
      })
    })
  }

  componentDidMount() {
    this.refreshReportData()
  }

  buildD0Graph(obj) {   
    let colors = ['#13aa4d','#26c965','#3bd476','#5ae891','#00bd00','#009400','#006600','#005900','#002e00'] 
    var graphObj =
    {
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: (tooltipItem, data) => {
              if ([0, 1, 2].includes(tooltipItem.datasetIndex)) {
                return `${tooltipItem.yLabel.toFixed(1)}`;
              }
              return `${tooltipItem.yLabel.toFixed(1)}`;
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              font: {
                size: 10,
                weight: "bold",
              },
            },
          ],
          yAxes: [
            {
              display:false,
              ticks: {
                callback: function (value) {
                  return value 
                },
              },
            },
          ],
        },
        title: {
          display: true,
          fontColor: 'black',
          fontSize: 12,
          fontFamily: 'Gobold',
          text: 'D0 Diário',
        },
        plugins:{
          datalabels: {
            display: function (graphObj) {
             return graphObj.dataset.data[graphObj.dataIndex] > 0
            },
            font: {
              size: 12,
              weight: "bold",
            },
            position: 'center',
            align: function (graphObj) {
                return 'center'
            },
            color:'black',
          },
        },
  
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
          fontSize: 8,
          fontFamily: 'GoBold'
        },
      },
    }
    let i = 0
    obj.forEach((item)=>{
     if(graphObj.data.datasets.filter(content => content.label === item.provider).length > 0 && graphObj.data.datasets.length > 0){}
     else{
        graphObj.data.datasets.push(
          {
            label: item.provider,
            stack: 'Stack 0',
            // type: 'line',
            borderColor: colors[i],
            backgroundColor: colors[i],
            pointRadius : 0,
            // showLine:false,
            data: [],
            fill: false,
            display:false,
            
          }
        )
        i++
      }
    }),
    // graphObj.data.datasets.push(
    // {
    //   label: 'D0 Antecipado',
    //   stack: 'Stack 0',
    //   type: 'line',
    //   borderColor: 'grey',
    //   backgroundColor: 'white',
    //   pointRadius : 0,
    //   // showLine:false,
    //   borderDash: [1,1],
    //   data: [],
    //   fill: false,
    //   display:false,
      
    // },
    // {
    //   label: '% D0 Antecipado',
    //   stack: 'Stack 0',
    //   type: 'line',
    //   borderColor: 'yellow',
    //   backgroundColor: 'white',
    //   pointRadius : 0,
    //   // showLine:false,
    //   borderDash: [1,1],
    //   data: [],
    //   fill: false,
    //   display:false,
      
    // })

    // var result = []

    _.orderBy(obj, 'day').forEach((element) => {
      
      if(graphObj.data.labels.includes(element.day) && element.provider != 'district'){
        graphObj.data.datasets.filter(item => item.label === element.provider)[0].data.push(element.d0)       
        
      }
      // else if(element.provider == 'district'){
      //   graphObj.data.datasets[graphObj.data.datasets.length - 2].data.push(element.d0)
      //   graphObj.data.datasets[graphObj.data.datasets.length - 1].data.push((element.d0*100/element.totalD0).toFixed(1))

      // }
      else if(element.provider != 'district'){
        graphObj.data.labels.push(element.day)
        graphObj.data.datasets.filter(item => item.label === element.provider)[0].data.push(element.d0)
      }
    })

    return graphObj
  }

  

  renderContent(){
    if(this.state.d0.loaded){
       return(
            <Box width={this.state.isDesktop ? "80%" : "100%"} mx="auto">
                <Graph
                data={this.state.d0.graph.data}
                legend={this.state.d0.graph.legend}
                options={this.state.d0.graph.options}
                width={this.state.isDesktop ? 400 : 310}
                height={340}
                />
            </Box>
        )
    }
    else{
        return(
            <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                <LoadingSpinner/>
            </div>
        )
    }
  }

  render(){
        return(this.renderContent())
    }

}
export default D0