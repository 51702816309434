/* eslint-disable */
import React, { Component } from 'react'
import moment from 'moment'
import LogisticService from '../../../core/services/LogisticService'
import style from '../style.css'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { withStyles } from '@material-ui/core/styles';
import Attention from '../newImagens/attention.png'
import Bad from '../newImagens/bad.png'
import Bomb from '../newImagens/bomb.png'
import Good from '../newImagens/good.png'
import Star from '../newImagens/star.png'

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CustomizedTable from '../../../custom-components/CustomizedTable';
import TablePaging from '../../../custom-components/CustomizedTablePagingSort';
import Box from '@material-ui/core/Box';

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: "#103a21",
      color: "white",
      fontSize: "0.9em",
      textalign: "center",
    },
    body: {
      fontSize: "0.13em",
      textalign: "center",
    },
    }))(TableCell);
class EfficiencyPrincipal extends Component {
constructor(props) {
    super(props);
    this.state = {
        district: props.district,
        date: props.date,
        sla:{
        loaded:false
        },
        delayReason:{
        loaded:false
        },
        tma:{
        loaded:false
        },
        graph: undefined,
        loading: false,
        lastUpdated: undefined,
    };
    this.refreshReportData = this.refreshReportData.bind(this)
}

refreshReportData() {

    LogisticService.getLmpEfficiency(this.state.district,'geral',`${this.state.date}-01`,`${this.state.date}-01`).then((response)=>{  
        this.setState({
            Goals:{
                    data:response.data.data,
                    loaded: true
                    },
                })
        })
    
}

componentDidMount() {
    this.refreshReportData()
  }


returnStatus (status) {
    if (status > 3) return <img className="iconCard" src={Star}></img>
    if (status == 3) return <img className="iconCard" src={Good}></img>
    if (status == 2) return <img className="iconCard" src={Attention}></img>
    if (status == 1) return <img className="iconCard" src={Bad}></img>
    if (status > 0){
        return <img className="iconCard" src={Bomb}></img>
    } 
    return ''
}

createQuadrantsTable(title,subtitle,dataTitle,data,status,type){
    let rows =[]
    if(type === 'Principal'){
    for(let i = 0; i < dataTitle.length;i++){ 
        rows.push(
        <TableRow >
            <StyledTableCell>{dataTitle[i]}</StyledTableCell>
            <StyledTableCell>{data[i]}</StyledTableCell>
            <StyledTableCell>{this.returnStatus(status[i])}</StyledTableCell>
        </TableRow>
        )
    }
        return(
        <CustomizedTable
            rows ={rows}
            title ={title?title:[{name:''}]}
            subTitle ={subtitle?subtitle:''}
            colSpan ={"2"}
        /> 
        )
    }
    else{
        data.forEach((item)=>{
            let aux = []
            for(let i = 0; i < item.length ; i++)
            aux.push(item[i])
            rows.push(aux)
        })
        return(
        <TablePaging
            data ={rows}
            title ={title?title:[{name:''}]}
            subTitle ={subtitle?subtitle:[{name:''}]}
            colSpan ={rows.length}
            rowSpan = {5}
        /> 
        )
    }
}

    render(){
        if(this.state.Goals != undefined){
            let efficiencyContent = []
            let tableTitle = [{name:'' }]
            let rowsTitle =['C.Triagem','Triagem Good','Reversa']
            let response = this.state.Goals.data[0]
            let data = [(response['stockPhoto']*100).toFixed(1).toString().concat('%'),(response['goodScreening']*100).toFixed(1).toString().concat('%'),'-']
            let status = [response['stockPhotoScore'],response['goodScreeningScore']*1.0,'']
            return(this.createQuadrantsTable(tableTitle,undefined,rowsTitle,data,status,'Principal'))
        }
        else{
            return(
                <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
                    <LoadingSpinner/>
                </div>
            )
            
        }
        
    }

}
export default EfficiencyPrincipal