import React, { Component } from 'react';
import MapWithADrawingManager from './maps'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Modal from 'react-responsive-modal';


import LogisticService from '../../../core/services/LogisticService';
const {
    Polygon,
    InfoWindow
  } = require("react-google-maps");


class RiskAreasMap extends Component {
    constructor(props) {
      super(props);

      this.state = {
        editable: false,
        configModal: false,
        selectedDistrict: this.props.district,
        center: this.props.center
    
    };
    
    this.refreshReportData = this.refreshReportData.bind(this)

    }

    refreshReportData() {

        LogisticService.getSenninhaConfigDangerousAreas(this.props.name, this.props.district).then((response) => {
            var routesTerritories = response.data.data
            const leg = []
            const polys = []

            routesTerritories.forEach(t2 => {

                const handleDelete = (territoryId) => {
                    LogisticService.deleteSenninhaArea(territoryId).then((response) => {
                      if(response.data.success == true){
                        window.alert("Área deletada com sucesso")
                        this.refreshReportData()
                      }
                      else{
                        window.alert(response.data.message)
                      }
                    })
                }

                const handleEdit = (territoryId) => {
                  const t1 = polys.filter(x => x.props.children.props.id === territoryId)[0];
                  polys.splice(polys.indexOf(t1), 1)
                  const t2 = routesTerritories.filter(x => x.id === territoryId)[0];
                  polys.push(
                    <div>
                      <Polygon
                        id={t2.id}
                        path={t2.bounds}
                        editable={true}
                        // onMouseUp={e => handleInteraction(e, t2.id)}
                        onClick={e => handleClick(t2.id)}
                        ref={(ref) => { t2.ref = ref }}
                        options={{
                          fillColor: t2.color,
                          fillOpacity: 0.6,
                          strokeColor: "#000",
                          strokeOpacity: 1,
                          strokeWeight: 1
                        }}
                      />
                      <InfoWindow
                      position={{ lat: t2.bounds[0].lat, lng: t2.bounds[0].lng }}
                      onCloseClick={e => handleInteraction(e, t2.id)}>
                        <div style={{width:'10vw', height:'10vh'}}>
                                      <h1 style={{fontSize:'1.5em', marginBottom:'0', marginTop:'0.5em'}}>{t2.name}</h1>
                                      <h2 className="polygonButton" style={{fontSize:'1em'}} onClick={e => handleInteraction(e, t2.id)}><SaveIcon/> Salvar alterações
                                      </h2>
                                      {/* <h2 style={{fontSize:'1em'}}>Angel: {selectedPlace.angel} <br/> Vencimento: {selectedPlace.deadline} <br/>
                                          Vencimento Cliente: {selectedPlace.customerDeadline} <br/> Nome Cliente: {selectedPlace.customerName} </h2> */}
                        </div>
                    </InfoWindow>
                    </div>
                  )
                  
                  // var editablePolygon = t2
                  // editablePolygon.props.children.props.editable = true
                  // polys.push(editablePolygon)
                  this.setState({
                    polys: polys
                  })
                }
                
                const handleClick = (territoryId) => {
                  const t2 = routesTerritories.filter(x => x.id === territoryId)[0];
                  polys.push(
                    <InfoWindow
                      position={{ lat: t2.bounds[0].lat, lng: t2.bounds[0].lng }}>
                        <div style={{width:'10vw', height:'10vh'}}>
                                      <h1 style={{fontSize:'1.5em', marginBottom:'0', marginTop:'0.5em'}}>{t2.name}</h1>
                                      <h2><DeleteIcon className="polygonButton" onClick={e => handleDelete(t2.id)}/>
                                          <EditIcon className="polygonButton" onClick={e => handleEdit(t2.id)}/> 
                                      </h2>
                                      {/* <h2 style={{fontSize:'1em'}}>Angel: {selectedPlace.angel} <br/> Vencimento: {selectedPlace.deadline} <br/>
                                          Vencimento Cliente: {selectedPlace.customerDeadline} <br/> Nome Cliente: {selectedPlace.customerName} </h2> */}
                        </div>
                    </InfoWindow>
                    
                  )
                  this.setState({
                    polys: polys
                  })
              }

                const handleInteraction = (e, territoryId, shouldDeleteVertex) => {
                // Gets the territory being handled
                const t2 = routesTerritories.filter(x => x.id === territoryId)[0];
            
                // newBounds are on the map
                const newBounds = t2.ref.getPath().getArray().map(a => {
                  return { lat: a.lat(), lng: a.lng() }
                });
                // currentBounds are on the props
                const currentBounds = t2.bounds.map(a => {
                  return { lat: a.lat, lng: a.lng }
                });
                if(JSON.stringify(currentBounds) !== JSON.stringify(newBounds)){
                      var editPolygon = [{
                        'bounds':newBounds,
                        'name':t2.name
                      }]
                    LogisticService.patchSenninhaEditAreas({'newArea':editPolygon}, territoryId).then((response) => {
                        if(response.data.success == true){
                          window.alert("Área alterada com sucesso")
                          this.refreshReportData()
                        }
                        else{
                          window.alert(response.data.message)
                        }
                    })

                }
                this.refreshReportData()
              }

              if(leg.length == 0){
                leg.push(
                  <div>
                  <div style={{display:"flex"}}>
                    <div style={{"width":"2em","height":"1em", "backgroundColor":"#c7220f", "marginLeft":"1em"}}/>
                    <div style={{"marginLeft":"1em"}}>Área de Risco</div>
                  </div>
                  <div style={{display:"flex"}}>
                    <div style={{"width":"2em","height":"1em", "backgroundColor":"#37b766", "marginLeft":"1em"}}/>
                    <div style={{"marginLeft":"1em"}}>Permitido Moto</div>
                  </div>
                  <div style={{display:"flex"}}>
                  <div style={{"width":"2em","height":"1em", "backgroundColor":"#1164a3", "marginLeft":"1em"}}/>
                    <div style={{"marginLeft":"1em"}}>Proibido Carro</div>
                </div>
                </div>

                )
              }
            
                polys.push(
                  <div>
                    <Polygon
                      id={t2.id}
                      path={t2.bounds}
                      editable={false}
                      // onMouseUp={e => handleInteraction(e, t2.id)}
                      onClick={e => handleClick(t2.id)}
                      ref={(ref) => { t2.ref = ref }}
                      options={{
                        fillColor: t2.color,
                        fillOpacity: 0.6,
                        strokeColor: "#000",
                        strokeOpacity: 1,
                        strokeWeight: 1
                      }}
                    />
                  </div>
                )
              })
              this.setState({
                  polys: polys,
                  routesTerritories: routesTerritories,
                  leg:leg
              })
        })
  
      
    }

    componentDidMount() {
        this.refreshReportData()
    }

    saveNewPolygon(listCoords, routesTerritories, name, district){
        var finalList = []
        listCoords.forEach((element) => {
          finalList.push({lat: element.lat(), lng: element.lng()})
        })
        var newPolygon = [{
                            'bounds':finalList,
                            'name':name
                        }]
        LogisticService.postSenninhaNewAreas({'newArea':newPolygon}, district).then((response) => {
            if(response.data.success == true){
                window.alert("Área cadastrada com sucesso")
                window.location.reload();
            }
            else{
              window.alert(response.data.message)
            }
        })
        
    }


    render(){

        var date = new Date();
        return(
          <div>
            <MapWithADrawingManager
            saveNewPolygon={this.saveNewPolygon}
            name={this.props.name}
            district={this.state.selectedDistrict}
            polys={this.state.polys}
            routesTerritories={this.state.routesTerritories}
            leg={this.state.leg}
            lastDraw={date}
            center={this.state.center}
            />
          </div>
            
        )
    }
}

export default RiskAreasMap
