/* eslint-disable */
import React, { Component } from 'react';
import moment from 'moment';
import LogisticService from '../../core/services/LogisticService';
import Graph from '../../components/Logistic/graphic/graph';
import { Container, Col, Row } from 'react-bootstrap';
import LoadingSpinner from '../../components/LoadingSpinner';

class ServiceOrdersSection extends Component {

    constructor () {
        super()
        this.state = {
          today: moment().format('YYYY-MM-DD'),
          loaded: false,
          lastUpdated: undefined,
          globalSLA:  {
            graph: undefined,
            loaded: false
          },
          stoneSLA:  {
            graph: undefined,
            loaded: false
          },
          thirdSLA:  {
            graph: undefined,
            loaded: false
          }
    
        }
    }

    componentDidMount () {
        this.refreshReportData()
      }
    
    refreshReportData() {

        LogisticService.getSLACompleteHistory()
        .then((response) => {
            this.buildSLAGeralHistory(response.data)
            this.buildSLAStoneHistory(response.data)
            this.buildSLAThirdHistory(response.data)
        })
    }


    buildSLAGeralHistory(obj) {
        const graphObj =
        {
          data: {
            labels: [],
            datasets: [
              {
                label: 'SLA Geral',
                stack: 'Stack 0',
                backgroundColor: '#00A32E',
                borderWidth: 2,
                data: [],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [{
                stacked: true,
                categoryPercentage: 0.9,
                gridLines: {
                  display: false,
                },
                ticks: {
                  min: '09-2018',
                  reverse: true,
                },
              }],
              yAxes: [{
                stacked: true,
                gridLines: {
                  display: true,
                },
                ticks: {
                  min: 75,
                },
              }],
            },
            tooltips: {
              mode: 'index',
              intersect: false,
            },
            title: {
              display: true,
              fontColor: '#2e3136',
              fontSize: 20,
              text: 'Geral',
              fontFamily: 'GoBold'
            },
            plugins: {
              datalabels: {
                display: function (graphObj) {
                  return graphObj.dataset.data[graphObj.dataIndex] > 0 ? 'auto' : false
                },
                display: true,
                font: {
                    size: 11,
                    weight: 'bold',
                  },
                  color: 'black',
                formatter: function (value) {
                  if (value >= 1000) {
                    return String((value/1000).toFixed(1)) + 'k'
                  }
                  else {
                    return value.toFixed(1)
                  }
                },
                anchor: 'end',
                align: 'top',
                min: 'auto'
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: 'x',
                },
                zoom: {
                  enabled: true,
                  mode: 'x',
                  speed: 0.1
                }
              }
            },
          },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              fontColor: 'black',
            },
          },
        }
  
        var slaHistory = obj
        slaHistory.forEach((element) => {
            element.reference = moment(String(element.month).concat("-", element.year), 'MM-YYYY')
            element.globalSla = (element.globalSla*100)
        })
  
        _.orderBy(slaHistory, 'reference').forEach((element) => {
            graphObj.data.labels.push(moment(element.reference).format("MM-YYYY"))
            graphObj.data.datasets[0].data.push(element.globalSla)
          }
        )
  
  
  
        this.setState({
          globalSLA: {
            graph: graphObj,
            loaded: true
          },
          lastUpdated: moment(),
        })
      }
  

    renderSLAGeralHistory() {
        if (this.state.globalSLA.loaded ) {
          return (
            <div>
              <Graph
                data={this.state.globalSLA.graph.data}
                legend={this.state.globalSLA.graph.legend}
                options={this.state.globalSLA.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 360 : 350}
              />
            </div>)
        }
      }

      buildSLAStoneHistory(obj) {
        const graphObj =
        {
          data: {
            labels: [],
            datasets: [
              {
                label: 'SLA Própria',
                stack: 'Stack 0',
                backgroundColor: '#00A32E',
                borderWidth: 2,
                data: [],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [{
                stacked: true,
                categoryPercentage: 0.9,
                gridLines: {
                  display: false,
                },
                ticks: {
                  min: '09-2018',
                },
              }],
              yAxes: [{
                stacked: true,
                gridLines: {
                  display: true,
                },
                ticks: {
                  min: 75,
                },
              }],
            },
            tooltips: {
              mode: 'index',
              intersect: false,
            },
            title: {
              display: true,
              fontColor: '#2e3136',
              fontSize: 20,
              text: 'Operação Própria',
              fontFamily: 'GoBold'
            },
            plugins: {
              datalabels: {
                // display: function (graphObj) {
                //   return graphObj.dataset.data[graphObj.dataIndex] > 0 ? 'auto' : false
                // },
                display: true,
                font: {
                  size: 11,
                  weight: 'bold',
                },
                color: 'black',
                formatter: function (value) {
                  if (value >= 1000) {
                    return String((value/1000).toFixed(1)) + 'k'
                  }
                  else {
                    return value.toFixed(1)
                  }
                },
                anchor: 'end',
                align: 'top',
                min: 'auto'
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: 'x',
                },
                zoom: {
                  enabled: true,
                  mode: 'x',
                  speed: 0.1
                }
              }
            },
          },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              fontColor: 'black',
            },
          },
        }
  
        var slaHistory = obj
        slaHistory.forEach((element) => {
            element.reference = moment(String(element.month).concat("-", element.year), 'MM-YYYY')
            element.selfLogisticsSla = (element.selfLogisticsSla*100)
        })
  
        _.orderBy(slaHistory, 'reference').forEach((element) => {
            graphObj.data.labels.push(moment(element.reference).format("MM-YYYY"))
            graphObj.data.datasets[0].data.push(element.selfLogisticsSla)
          }
        )
  
  
  
        this.setState({
          stoneSLA: {
            graph: graphObj,
            loaded: true
          },
          lastUpdated: moment(),
        })
      }
  
    renderSLAStoneHistory() {
        if (this.state.stoneSLA.loaded ) {
  
          return (
            <div>
              <Graph
                data={this.state.stoneSLA.graph.data}
                legend={this.state.stoneSLA.graph.legend}
                options={this.state.stoneSLA.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 360 : 350}
              />
            </div>)
        }
      }



    buildSLAThirdHistory(obj) {
        const graphObj =
        {
          data: {
            labels: [],
            datasets: [
              {
                label: 'SLA Terceira',
                stack: 'Stack 0',
                backgroundColor: '#00A32E',
                borderWidth: 2,
                data: [],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [{
                stacked: true,
                categoryPercentage: 0.9,
                gridLines: {
                  display: false,
                },
                ticks: {
                  min: '09-2018',
                },
              }],
              yAxes: [{
                stacked: true,
                gridLines: {
                  display: true,
                },
                ticks: {
                  min: 75,
                  max:100
                },
              }],
            },
            tooltips: {
              mode: 'index',
              intersect: false,
            },
            title: {
              display: true,
              fontColor: '#2e3136',
              fontSize: 20,
              text: 'Operação Terceira',
              fontFamily: 'GoBold'
            },
            plugins: {
              datalabels: {
                display: function (graphObj) {
                  return graphObj.dataset.data[graphObj.dataIndex] > 0 ? 'auto' : 'auto'
                },
                display: true,
                font: {
                  size: 11,
                  weight: 'bold',
                },
                color: 'black',
                formatter: function (value) {
                  if (value >= 1000) {
                    return String((value/1000).toFixed(1)) + 'k'
                  }
                  else {
                    return value.toFixed(1)
                  }
                },
                anchor: 'end',
                align: 'top',
                min: 'auto'
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: 'x',
                },
                zoom: {
                  enabled: true,
                  mode: 'x',
                  speed: 0.1
                }
              }
            },
          },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              fontColor: 'black',
            },
          },
        }
  
        var slaHistory = obj
        slaHistory.forEach((element) => {
            element.reference = moment(String(element.month).concat("-", element.year), 'MM-YYYY')
            element.thirdOperationSla = (element.thirdOperationSla*100)
        })
  
        _.orderBy(slaHistory, 'reference').forEach((element) => {
            graphObj.data.labels.push(moment(element.reference).format("MM-YYYY"))
            graphObj.data.datasets[0].data.push(element.thirdOperationSla)
          }
        )
  
  
  
        this.setState({
          thirdSLA: {
            graph: graphObj,
            loaded: true
          },
          lastUpdated: moment(),
        })
      }
  
    renderSLAThirdHistory() {
        if (this.state.thirdSLA.loaded ) {
          return (
            <div>
              <Graph
                data={this.state.thirdSLA.graph.data}
                legend={this.state.thirdSLA.graph.legend}
                options={this.state.thirdSLA.graph.options}
                width={this.state.isDesktop ? 500 : 350}
                height={this.state.isDesktop ? 360 : 350}
              />
            </div>)
        }
      }



      render () {
        if (this.state.globalSLA.loaded && this.state.stoneSLA.loaded && this.state.thirdSLA.loaded) {
        return (
          <Row style={{marginTop: '1em'}}>
            <Col xs sm md = {12} lg xl = {4}>
            {this.renderSLAGeralHistory()}
            </Col>
            <Col xs sm md = {12} lg xl = {4}>
            {this.renderSLAStoneHistory()}
            </Col>
            <Col xs sm md = {12} lg xl = {4}>
            {this.renderSLAThirdHistory()}
            </Col>
          </Row>
          )
        }

        return (
          <div style={{ 'textAlign': 'center', 'marginTop': '1em', 'marginBottom': '1em' }}>
            <LoadingSpinner />
          </div>)
      
      }
      
}
      
export default ServiceOrdersSection