import Grid from "@material-ui/core/Grid";
import React from 'react';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import XLSX from "xlsx";
import LoadingSpinner from "../../components/LoadingSpinner";
import LogisticService from '../../core/services/LogisticService';
import { SheetJSAppLeadtimes } from "../../custom-components/SheetJs/SheetJsLeadtimes";
import { getUserEmail } from "../../storage";
import { ListLeadtimes } from './materialTable';

class Leadtime extends React.Component {
    constructor() {

        super();

        this.state = {
            loading_search: false,
            loading_save: false,
            success: false,
            provider: null,
            carrier: null,
            mode: null,
            type: null,
            days: 0,
            data: [],
            filtros_search: {}
        }
    }

    componentDidMount() {
        let hasAccess = ['gabriel.xara@stone.com.br', 'boneves@stone.com.br', 'mtourinho@stone.com.br', 'mariana.brito@stone.com.br',
            'mtourinho@stone.com.br', 'ymarinho@stone.com.br', 'trossignoli@stone.com.br', 'murilo.trassi@stone.com.br', 'maria.ssantos@stone.com.br',
            'beatriz.marques@stone.com.br', 'marcelo.gavioli@stone.com.br']
        let user = getUserEmail()

        if (!hasAccess.includes(user)) {
            this.props.history.push('reports/last-mile')
        }
    }

    handleChange(event) {
        this.setState({
            provider: document.getElementById("provider").value,
            carrier: document.getElementById("carrier").value,
            mode: document.getElementById("mode").value,
            type: document.getElementById("type").value,
            days: document.getElementById("days").value
        })
    }

    searchLeadtime = () => {
        this.setState({
            loading_search: true,
        });

        let filtros = {}

        if (this.state.provider_filter && this.state.provider_filter != '') {
            filtros['hub'] = this.state.provider_filter;
        }

        if (this.state.carrier_filter && this.state.carrier_filter != '') {
            filtros['carrier'] = this.state.carrier_filter;
        }

        if (this.state.mode_filter && this.state.mode_filter != '') {
            filtros['mode'] = this.state.mode_filter;
        }

        if (this.state.type_filter && this.state.type_filter != '') {
            filtros['type'] = this.state.type_filter;
        }

        if (this.state.days_filter && this.state.days_filter != '') {
            filtros['days'] = this.state.days_filter;
        }

        LogisticService.getLeadtimes(filtros).then((response) => {
            this.setState({
                loading_search: false,
                filtros_search: filtros,
                data: response.data.data
            })


        }, (reason => {
            this.setState({
                loading_search: false,
            });
        }));
    };

    exportFile() {
        let worksheet_example = [['Polo', 'Transportadora', 'Modo', 'Tipo', 'Dias'],
        ['AC - RIO BRANCO STONE PAGAMENTOS', 'BESTLOG', 'convencional', 'avanco', 6],
        ['ES - VITORIA STONE PAGAMENTOS', 'PAYTEC', 'express rodoviario', 'avanco', 3],
        ['AC - RIO BRANCO STONE PAGAMENTOS', 'PAYTEC', 'express rodoviario', 'reversa', 19],
        ['AL - ARAPIRACA STONE PAGAMENTOS', 'BESTLOG', 'emergencial', 'avanco', 3]]

        const ws = XLSX.utils.aoa_to_sheet(worksheet_example);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, "Exemplo Leadtimes.xlsx");
    }

    saveSingleLeadtime = () => {
        this.setState({
            loading_save: true,
            error: false
        });

        var leadtime = {
            'hub': this.state.provider,
            'carrier': this.state.carrier,
            'mode': this.state.mode,
            'type': this.state.type,
            'days': this.state.days
        };

        LogisticService.postLeatime({ 'leadtime_list': [leadtime] }).then((response) => {
            this.setState({
                success: true,
                loading_save: false
            })
        }, (response) => {
            this.setState({
                error: true,
                loading_save: false
            })
        })
    };

    render() {
        return (
            <Grid fluid style={{ 'paddingLeft': 0, 'paddingRight': 0 }}>
                <Row>
                    <Col lg="3" />
                    <Col lg="3">
                        <Button variant="success" onClick={this.exportFile} >Exportar planilha exemplo</Button>
                    </Col>
                    <Col lg="3">
                        <p>Importar Leadtimes pelo Excel</p>
                        <SheetJSAppLeadtimes />
                    </Col>
                </Row>
                <Row style={{ ' margin-left': 0, 'margin-right': 0, 'marginTop': '1em' }}>
                    <Col lg="2" />

                    <Col lg="4" sm="12" xs="12">
                        <Card className="text-center">
                            <Card.Header style={{ 'backgroundColor': '#103a21', 'color': 'white' }}>Inserir Leadtime</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Polo</Form.Label>
                                            <Form.Control type="text" id="provider" placeholder="Polo" onChange={() => this.setState({ provider: document.getElementById("provider").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <hr />
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Transportadora</Form.Label>
                                            <Form.Control type="text" id="carrier" placeholder="Transportadora" onChange={() => this.setState({ carrier: document.getElementById("carrier").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Tipo</Form.Label>
                                            <Form.Control type="text" id="type" placeholder="Tipo" onChange={() => this.setState({ type: document.getElementById("type").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Modo</Form.Label>
                                            <Form.Control type="text" id="mode" placeholder="Modo" onChange={() => this.setState({ mode: document.getElementById("mode").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Dias</Form.Label>
                                            <Form.Control type="number" min={0} id="days" placeholder="Valor" onChange={() => this.setState({ days: document.getElementById("days").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                </Form>
                                <Button variant="success" disabled={!this.state.days} onClick={this.saveSingleLeadtime} >Salvar</Button>
                                <div hidden={!this.state.loading_save} style={{ width: 'center', textAlign: 'center' }}>
                                    <LoadingSpinner />
                                </div>
                                <Alert hidden={!this.state.success} variant="success">
                                    <Alert.Heading>Boa!</Alert.Heading>
                                    <p>
                                        Leadtime cadastrado com sucesso!
                                    </p>
                                    <Button onClick={() => this.setState({ success: false })} variant="outline-success">
                                        Ok!
                                    </Button>
                                </Alert>
                                <Alert hidden={!this.state.error} variant="danger">
                                    <Alert.Heading>Ocorreu um erro! ):</Alert.Heading>
                                    <p>
                                        {this.state.errorMessage}
                                    </p>
                                    <Button onClick={() => this.setState({ error: false })} variant="outline-danger">
                                        Ok :/
                                    </Button>
                                </Alert>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4" sm="12" xs="12">
                        <Card className="text-center">
                            <Card.Header style={{ 'backgroundColor': '#103a21', 'color': 'white' }}>Buscar Leadtimes</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Polo</Form.Label>
                                            <Form.Control type="text" id="provider_filter" placeholder="Polo" onChange={() => this.setState({ provider_filter: document.getElementById("provider_filter").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <hr />
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Transportadora</Form.Label>
                                            <Form.Control type="text" id="carrier_filter" placeholder="Transportadora" onChange={() => this.setState({ carrier_filter: document.getElementById("carrier_filter").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Tipo</Form.Label>
                                            <Form.Control type="text" id="type_filter" placeholder="Tipo" onChange={() => this.setState({ type_filter: document.getElementById("type_filter").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Modo</Form.Label>
                                            <Form.Control type="text" id="mode_filter" placeholder="Modo" onChange={() => this.setState({ mode_filter: document.getElementById("mode_filter").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Dias</Form.Label>
                                            <Form.Control type="number" min={0} id="days_filter" placeholder="Valor" onChange={() => this.setState({ days_filter: document.getElementById("days_filter").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <div hidden={!this.state.loading_search} style={{ width: 'center', textAlign: 'center', margin: '8px' }}>
                                        <LoadingSpinner />
                                    </div>
                                    <Button variant="success" onClick={this.searchLeadtime} >Buscar</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ 'margin-left': 0, 'margin-top': '24px', 'margin-right': 0 }}>
                    <Col>
                        <ListLeadtimes data={this.state.data} filtros={this.state.filtros_search} />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default Leadtime;
