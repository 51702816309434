import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: {
    background: '#28a745',
    color: 'white',
  },
  label: {
    textTransform: 'capitalize',
  },
});

export default function RefreshButton(props) {
  const classes = useStyles();

  return (
    <Button
      onClick={props.onClick}
      variant="contained"
      classes={{
        root: classes.root, // class name, e.g. `classes-nesting-root-x`
        label: classes.label, // class name, e.g. `classes-nesting-label-x`
      }}
      style={{fontFamily: 'GoBold', lineHeight: '1', marginBottom: '0.31em'}}
    >
      Atualizar  
    <i class="material-icons"> refresh </i>
    </Button>
  );
}


