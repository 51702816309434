import React, { useState, useEffect } from "react";
import LogisticService from "../../core/services/LogisticService";
import DetalhamentoGreenAngels from "./AngelsResults/AbasDeDetalhamento";
import DetalhamentoGuardianAngels from "./GuardiansResults/AbasDeDetalhamento";
import DetalhamentoHostAngels from "./HostAngelsResults/AbasDeDetalhamento";
import DetalhamentoSupplierAngels from "./SuppliersResults/AbasDeDetalhamento";
import DetalhamentoDistritos from "./DistrictsResults/AbasDeDetalhamento";
import { SumarioDeResultados } from "./SumarioDeResultados";
import PainelDeBusca from "./PainelDeBusca";
import moment from "moment";
import LoadingSpinner from "../../components/LoadingSpinner/index";
import { Redirect } from "react-router-dom";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const formatarNomePilar = (str) =>
  capitalize(str).replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace("-", " ").replace("_", " ")
  );

const NovaRV = (props) => {
  const [nome, setNome] = useState(props.location.state.name);
  const [outrosNomesDisponiveis, setOutrosNomesDisponiveis] = useState([]);

  const [mesReferencia, setMesReferencia] = useState(
    props.location.state.month
  );
  const [anoReferencia, setAnoReferencia] = useState(props.location.state.year);
  const [tipo, setTipo] = useState(props.location.state.tipo);
  const [mesesDisponiveis, setMesesDisponiveis] = useState([]);

  const [carregando, setCarregando] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [resultado, setResultado] = useState({});
  const [dataDeAtualizacao, setDataDeAtualizacao] = useState("");

  useEffect(() => {
    const rvAntiga =
      new Date(anoReferencia, mesReferencia) <= new Date(2021, 2);

    if (rvAntiga) {
      setRedirect(true);
      return;
    }

    setCarregando(true);

    LogisticService.periodosDiponiveisRV().then((response) => {
      setMesesDisponiveis(response.data.data);
    });

    const buscarDadosDaRVFactory = (tipo) =>
      ({
        greenAngel: LogisticService.getAngelResults,
        guardianAngel: LogisticService.getGuardianResults,
        supplierAngel: LogisticService.getSupplierResults,
        hostAngel: LogisticService.ResultadosHostAngels,
        distrito: LogisticService.getDistrictResults,
      }[tipo]);

    const buscarDadosDaRV = buscarDadosDaRVFactory(tipo);

    buscarDadosDaRV(anoReferencia, mesReferencia, nome)
      .then((response) => {
        setResultado(response.data.data[0].results);
        setDataDeAtualizacao(
          moment
            .utc(response.data.data[0].dtmModifiedAt)
            .format("DD/MM/YYYY - HH:mm")
        );
      })
      .then(() => setCarregando(false));
  }, [nome, anoReferencia, mesReferencia]);

  useEffect(() => {
    const buscarOutrosColaboradoresFactory = (tipo) =>
      ({
        greenAngel: LogisticService.listarGreenAngelsRV,
        guardianAngel: LogisticService.listarGuardiansRV,
        supplierAngel: LogisticService.listarSuppliersRV,
        hostAngel: LogisticService.listarHostAngelsRV,
        distrito: LogisticService.listarDistritosRV,
      }[tipo]);

    const buscarOutrosColaboradores = buscarOutrosColaboradoresFactory(tipo);

    buscarOutrosColaboradores(anoReferencia, mesReferencia).then((response) => {
      if (tipo === "greenAngel") {
        setOutrosNomesDisponiveis(
          response.data.data
            .filter((obj) => obj.districtName === resultado.distrito)
            .map((e) => e.angelName)
        );
      } else if (tipo === "hostAngel") {
        setOutrosNomesDisponiveis(response.data.data.map((e) => e.angelName));
      } else if (tipo === "guardianAngel") {
        setOutrosNomesDisponiveis(
          response.data.data.map((e) => e.guardianName)
        );
      } else if (tipo === "supplierAngel") {
        setOutrosNomesDisponiveis(
          response.data.data.map((e) => e.supplierName)
        );
      } else if (tipo === "distrito") {
        setOutrosNomesDisponiveis(
          response.data.data.map((e) => e.districtName)
        );
      }
    });
  }, [resultado]);

  const alterarColaborador = (novoNome) => {
    setNome(novoNome);
  };

  const alterarData = (novaData) => {
    setMesReferencia(novaData.mes);
    setAnoReferencia(novaData.ano);
  };

  function retornarPilares(resultados) {
    let pilares = [];

    if (resultados) {
      Object.keys(resultados).forEach((k) => {
        if (k.includes("pilar") && resultados[k] !== null) {
          pilares.push({
            ...resultados[k],
            nome: formatarNomePilar(k),
            atingimento: (resultados[k].atingimento * 100).toFixed(1),
            limite: (
              100 *
              resultados[k].indicadores
                .filter((i) => i.tipo !== "alavanca")
                .map((i) => parseFloat(i.peso))
                .reduce((a, b) => a + b, 0)
            ).toFixed(1),
            indicadores: resultados[k].indicadores.map((i) => ({
              ...i,
              resultado: i.detalhes.resultado,
              atingimento: (i.atingimento * 100).toFixed(1),
              peso: (i.peso * 100).toFixed(1),
            })),
          });
        }
      });
    }

    return pilares;
  }

  if (carregando)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "60vh",
          fontFamily: "open-sans",
        }}
      >
        <LoadingSpinner />
      </div>
    );

  const AbasDeDetalhamento = () => {
    if (window.location.pathname.includes("green-angels")) {
      return (
        <DetalhamentoGreenAngels
          ano={anoReferencia}
          mes={mesReferencia}
          nome={nome}
          polo={resultado ? resultado.polo : undefined}
          distrito={resultado ? resultado.distrito : undefined}
          resultado={!resultado ? null : retornarPilares(resultado)}
        />
      );
    } else if (window.location.pathname.includes("guardian-angels")) {
      return (
        <DetalhamentoGuardianAngels
          ano={anoReferencia}
          mes={mesReferencia}
          nome={nome}
          polo={resultado ? resultado.polo : undefined}
          distrito={resultado ? resultado.distrito : undefined}
          resultado={!resultado ? null : retornarPilares(resultado)}
        />
      );
    } else if (window.location.pathname.includes("supplier-angels")) {
      return (
        <DetalhamentoSupplierAngels
          ano={anoReferencia}
          mes={mesReferencia}
          nome={nome}
          polo={resultado ? resultado.polo : undefined}
          distrito={resultado ? resultado.distrito : undefined}
          resultado={!resultado ? null : retornarPilares(resultado)}
        />
      );
    } else if (window.location.pathname.includes("host-angels")) {
      return (
        <DetalhamentoHostAngels
          ano={anoReferencia}
          mes={mesReferencia}
          nome={nome}
          polo={resultado ? resultado.polo : undefined}
          distrito={resultado ? resultado.distrito : undefined}
          resultado={!resultado ? null : retornarPilares(resultado)}
        />
      );
    } else if (window.location.pathname.includes("distritos")) {
      return (
        <DetalhamentoDistritos
          ano={anoReferencia}
          mes={mesReferencia}
          nome={nome}
          polo={resultado ? resultado.polo : undefined}
          distrito={resultado ? resultado.distrito : undefined}
          resultado={!resultado ? null : retornarPilares(resultado)}
        />
      );
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0px 18px",
        }}
      >
        <div>
          Remuneração Variável - {nome} <br />
          Data da última atualização: {dataDeAtualizacao}
        </div>
        <PainelDeBusca
          nome={nome}
          nomes={outrosNomesDisponiveis}
          mesesDisponiveis={mesesDisponiveis}
          alterarColaborador={alterarColaborador}
          alterarData={alterarData}
          ano={anoReferencia}
          mes={mesReferencia}
        />
      </div>
      <SumarioDeResultados
        pilares={retornarPilares(resultado)}
        resultadoFinal={(100 * resultado.resultado_final).toFixed(1)}
      />
      <AbasDeDetalhamento
        ano={anoReferencia}
        mes={mesReferencia}
        nome={nome}
        polo={resultado ? resultado.polo : undefined}
        distrito={resultado ? resultado.distrito : undefined}
        resultado={!resultado ? null : retornarPilares(resultado)}
      />
      {!redirect ? (
        <></>
      ) : (
        <Redirect
          to={{
            pathname: window.location.pathname.replace("/v2", ""),
            state: {
              name: nome,
              year: anoReferencia,
              month: mesReferencia,
            },
          }}
        />
      )}
    </div>
  );
};

export default NovaRV;
