/* eslint-disable */
import React, { Component } from "react";
import LogisticService from "../../../core/services/LogisticService";
import SortingTable from "../../../custom-components/SortingTable";

const columnsDelay = [
  {
    title: "Motivo do Atraso",
    dataIndex: "reason",
    sorter: (a, b) => (a.reason < b.reason ? -1 : 1),
  },
  {
    title: "Atraso",
    dataIndex: "delay",
    sorter: (a, b) => a.delay - b.delay,
  },
  {
    title: "Impacto",
    dataIndex: "impactPercentualView",
    sorter: (a, b) => a.impactPercentual - b.impactPercentual,
  },
];

export class Delay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      district: props.district,
      date: props.date,
      delay: {
        loaded: false,
      },
      graph: undefined,
      loading: false,
      lastUpdated: undefined,
    };
  }

  componentDidMount() {
    LogisticService.getLmpAttendance(
      "Delay",
      this.state.district,
      null,
      `${this.state.date}-01`,
      `${this.state.date}-01`
    ).then((response) => {
      let totalOs = 0;
      response.data.data.forEach((item) => {
        totalOs += item.total;
      });
      response.data.data.forEach((item) => {
        item.impactPercentual = (((item.delay * 1.0) / totalOs) * 100).toFixed(1);
        item.impactPercentualView = `${(((item.delay * 1.0) / totalOs) * 100).toFixed(1)}%`;
      });
      console.log(totalOs);
      this.setState({
        delayData: response.data.data,
        loaded: true,
      });
    });
  }

  render() {
    return (
      <SortingTable
        title="Motivos de Atraso"
        columns={columnsDelay}
        data={this.state.delayData}
      />
    );
  }
}

export default Delay;
