import Grid from "@material-ui/core/Grid"
import moment from 'moment'
import React from 'react'
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap'
import XLSX from "xlsx"
import LoadingSpinner from '../../components/LoadingSpinner'
import LogisticService from '../../core/services/LogisticService'
import { SheetJSApp } from "../../custom-components/SheetJs/SheetJs"
import { SheetJSAppOmId } from "../../custom-components/SheetJs/SheetJsOmId"
import { ListExpeditions } from './materialTable'

class Expedition extends React.Component {
    constructor() {

        super();

        this.state = {
            today: moment().format('YYYY-MM-DD'),
            loading_search: false,
            loading_save: false,
            modalIsOpen: false,
            estimated_arrival: '',
            solicitation_date: '',
            expedition_date: '',
            origin: '',
            destiny: '',
            operator: '',
            situation: '',
            shipping: '',
            transportation: '',
            error: false,
            data: [],
            observation: '',
            oi: 0,
            tim: 0,
            vivo: 0,
            claro: 0,
            microOi: 0,
            microTim: 0,
            microVivo: 0,
            microClaro: 0,
            s920: 0,
            d210n: 0,
            pinpad: 0,
            ethernet: 0,
            q92: 0,
            mobpin5: 0,
            v240m: 0,
            a8: 0,
            gpos7: 0,
            mp35p: 0,
            ppc930: 0,
            totalBips: 0,
            kit: 0,
            inbulkBox: 0,
            s920Charger: 0,
            d210nLabel: 0,
            ipp320Label: 0,
            ppc920Label: 0,
            stoneSticker: 0,
            welcomeKit: 0,
            success: false,
            filtros_search: {}
        }
    }

    handleSolicitationDate() {
        this.setState({
            filter_solicitation_date: document.getElementById("filter_solicitation_date").value
        })
    }
    handleExpeditionDate() {
        this.setState({
            filter_expedition_date: document.getElementById("filter_expedition_date").value
        })
    }
    handleDestination() {
        this.setState({
            filter_destination: document.getElementById("filter_destination").value
        })
    }
    handleOmNumber() {
        this.setState({
            filter_om_number: document.getElementById("filter_om_number").value
        })
    }

    searchExpeditions = () => {
        this.setState({
            loading_search: true,
        });

        let filtros = {}

        if (this.state.filter_solicitation_date && this.state.filter_solicitation_date != '') {
            filtros['solicitation_date'] = this.state.filter_solicitation_date;
        }

        if (this.state.filter_expedition_date && this.state.filter_expedition_date != '') {
            filtros['expedition_date'] = this.state.filter_expedition_date;
        }

        if (this.state.filter_destination && this.state.filter_destination != '') {
            filtros['destination'] = this.state.filter_destination;
        }

        if (this.state.filter_om_number && this.state.filter_om_number != '') {
            filtros['om_number'] = this.state.filter_om_number;
        }

        LogisticService.getExpeditionList(filtros).then((response) => {
            this.setState({
                loading_search: false,
                filtros_search: filtros,
                data: response.data.data
            })


        }, (reason => {
            this.setState({
                loading_search: false,
            });
        }));
    };

    saveSingleExpedition = () => {
        this.setState({
            loading_save: true,
            error: false
        });

        var expedition = {
            origin: this.state.origin,
            destination: this.state.destiny,
            logisticOperator: this.state.operator,
            shipping: this.state.shipping,
            carrier: this.state.transportation,
            observations: this.state.observation,
            oi: this.state.oi,
            tim: this.state.tim,
            vivo: this.state.vivo,
            claro: this.state.claro,
            microOi: this.state.microOi,
            microTim: this.state.microTim,
            microVivo: this.state.microVivo,
            microClaro: this.state.microClaro,
            s920: this.state.s920,
            d210n: this.state.d210n,
            pinpad: this.state.pinpad,
            ethernet: this.state.ethernet,
            q92: this.state.q92,
            mobpin5: this.state.mobpin5,
            v240m: this.state.v240m,
            a8: this.state.a8,
            gpos7: this.state.gpos7,
            mp35p: this.state.mp35p,
            ppc930: this.state.ppc930,
            totalBips: this.state.totalBips,
            kit: this.state.kit,
            welcomeKit: this.state.welcomeKit,
            inbulkBox: this.state.inbulkBox,
            s920Charger: this.state.s920Charger,
            d210nLabel: this.state.d210nLabel,
            ipp320Label: this.state.ipp320Label,
            ppc920Label: this.state.ppc920Label,
            stoneSticker: this.state.stoneSticker,
        };

        LogisticService.postExpedition(expedition).then((response) => {
            this.setState({
                success: true,
                loading_save: false
            })
        }, (response) => {
            this.setState({
                error: true,
                loading_save: false
            })
        })
    };

    exportFile() {
        let worksheet_example = [['Origem', 'Destino', 'Operador Logístico', 'Modo', 'Transportadora', 'Observações', 'pinpad',
            's920', 'd210n', 'ethernet', 'Q92', 'MOBPIN 5', 'V240M', 'A8', 'GPOS700', 'MP35P', 'PPC 930',
            'label d210n', 'adesivo stone', 'kit 14', 'welcome kit',
            'caixa avulsa pos', 'carregador s920', 'label ipp320', 'label ppc920', 'oi', 'tim', 'vivo', 'claro', 'micro oi',
            'micro tim', 'micro vivo', 'micro claro']]

        const ws = XLSX.utils.aoa_to_sheet(worksheet_example);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, "Exemplo Expedição.xlsx");
    }

    exportFileOMId() {
        let worksheet_example = [['OM', 'ID']]

        const ws = XLSX.utils.aoa_to_sheet(worksheet_example);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, "Exemplo OM-ID.xlsx");
    }

    render() {
        return (
            <Grid fluid style={{ 'paddingLeft': 0, 'paddingRight': 0 }}>
                <Row style={{ 'fontSize': '0.8em' }}>
                    <Col lg="1" />
                    <Col lg="8">
                        <p>Importar número das OMs por ID</p>
                        <SheetJSAppOmId />
                    </Col>
                    <Col lg="3">
                        <Button variant="success" onClick={this.exportFileOMId} >Planilha exemplo (OM/ID)</Button>
                    </Col>
                </Row>
                <Row style={{ 'marginLeft': '1em', 'marginRight': '1em', 'fontSize': '0.9em' }}>

                    <Col lg="8" sm="12" xs="12">
                        <Card className="text-center">
                            <Card.Header style={{ 'backgroundColor': '#103a21', 'color': 'white' }}>Inserir expedição</Card.Header>
                            <Card.Body>
                                <Form>
                                    {/* <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Expedição</Form.Label>
                                            <Form.Control type="date" id="expedition" placeholder="Expedição" onChange={() => this.setState({expedition: document.getElementById("expedition").value})} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Previsão de chegada</Form.Label>
                                            <Form.Control type="date" id="estimated_arrival" placeholder="Expedição" onChange={() => this.setState({estimated_arrival: document.getElementById("estimated_arrival").value})} />
                                        </Form.Group>
                                    </Form.Row>
                                    <hr /> */}
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Control type="text" id="origin" placeholder="Origem" onChange={() => this.setState({ origin: document.getElementById("origin").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Control type="text" id="destiny" placeholder="Destino" onChange={() => this.setState({ destiny: document.getElementById("destiny").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Control type="text" id="operator" placeholder="Operador Logístico" onChange={() => this.setState({ operator: document.getElementById("operator").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Control type="text" id="shipping" placeholder="Modo Transporte" onChange={() => this.setState({ shipping: document.getElementById("shipping").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Control type="text" id="transportation" placeholder="Transportadora" onChange={() => this.setState({ transportation: document.getElementById("transportation").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Control type="text" id="observation" placeholder="Observações" onChange={() => this.setState({ observation: document.getElementById("observation").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <hr />
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Pinpad</Form.Label>
                                            <Form.Control type="number" id="pinpad" onChange={() => this.setState({ pinpad: document.getElementById("pinpad").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>S920</Form.Label>
                                            <Form.Control type="number" id="s920" onChange={() => this.setState({ s920: document.getElementById("s920").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>D210N</Form.Label>
                                            <Form.Control type="number" id="d210n" onChange={() => this.setState({ d210n: document.getElementById("d210n").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Ethernet</Form.Label>
                                            <Form.Control type="number" id="ethernet" onChange={() => this.setState({ ethernet: document.getElementById("ethernet").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Label D210N</Form.Label>
                                            <Form.Control type="number" id="d210nLabel" onChange={() => this.setState({ d210nLabel: document.getElementById("d210nLabel").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Adesivo Stone</Form.Label>
                                            <Form.Control type="number" id="stoneSticker" onChange={() => this.setState({ stoneSticker: document.getElementById("stoneSticker").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Q92</Form.Label>
                                            <Form.Control type="number" id="q92" onChange={() => this.setState({ q92: document.getElementById("q92").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>MOBPIN 5</Form.Label>
                                            <Form.Control type="number" id="mobpin5" onChange={() => this.setState({ mobpin5: document.getElementById("mobpin5").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>V240M</Form.Label>
                                            <Form.Control type="number" id="v240m" onChange={() => this.setState({ v240m: document.getElementById("v240m").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>A8</Form.Label>
                                            <Form.Control type="number" id="a8" onChange={() => this.setState({ a8: document.getElementById("a8").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>GPOS7</Form.Label>
                                            <Form.Control type="number" id="gpos7" onChange={() => this.setState({ gpos7: document.getElementById("gpos7").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>MP35P</Form.Label>
                                            <Form.Control type="number" id="mp35p" onChange={() => this.setState({ mp35p: document.getElementById("mp35p").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>PPC 930</Form.Label>
                                            <Form.Control type="number" id="ppc930" onChange={() => this.setState({ ppc930: document.getElementById("ppc930").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Kit 14</Form.Label>
                                            <Form.Control type="number" id="kit" onChange={() => this.setState({ kit: document.getElementById("kit").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Welcome Kit</Form.Label>
                                            <Form.Control type="number" id="welcomeKit" onChange={() => this.setState({ welcomeKit: document.getElementById("welcomeKit").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Caixa Avulsa POS</Form.Label>
                                            <Form.Control type="number" id="inbulkBox" onChange={() => this.setState({ inbulkBox: document.getElementById("inbulkBox").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Carregador S920</Form.Label>
                                            <Form.Control type="number" id="s920Charger" onChange={() => this.setState({ s920Charger: document.getElementById("s920Charger").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Label IPP320</Form.Label>
                                            <Form.Control type="number" id="ipp320Label" onChange={() => this.setState({ ipp320Label: document.getElementById("ipp320Label").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Label PPC920</Form.Label>
                                            <Form.Control type="number" id="ppc920Label" onChange={() => this.setState({ ppc920Label: document.getElementById("ppc920Label").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Oi</Form.Label>
                                            <Form.Control type="number" id="oi" onChange={() => this.setState({ oi: document.getElementById("oi").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Tim</Form.Label>
                                            <Form.Control type="number" id="tim" onChange={() => this.setState({ tim: document.getElementById("tim").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Vivo</Form.Label>
                                            <Form.Control type="number" id="vivo" onChange={() => this.setState({ vivo: document.getElementById("vivo").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Claro</Form.Label>
                                            <Form.Control type="number" id="claro" onChange={() => this.setState({ claro: document.getElementById("claro").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Micro Oi</Form.Label>
                                            <Form.Control type="number" id="microOi" onChange={() => this.setState({ microOi: document.getElementById("microOi").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Micro Tim</Form.Label>
                                            <Form.Control type="number" id="microTim" onChange={() => this.setState({ microTim: document.getElementById("microTim").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Micro Vivo</Form.Label>
                                            <Form.Control type="number" id="microVivo" onChange={() => this.setState({ microVivo: document.getElementById("microVivo").value })} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Micro Claro</Form.Label>
                                            <Form.Control type="number" id="microClaro" onChange={() => this.setState({ microClaro: document.getElementById("microClaro").value })} />
                                        </Form.Group>
                                    </Form.Row>
                                </Form>
                                <Button variant="success" disabled={!this.state.claro} onClick={this.saveSingleExpedition} >Salvar</Button>
                                <div hidden={!this.state.loading_save} style={{ width: 'center', textAlign: 'center' }}>
                                    <LoadingSpinner />
                                </div>
                                <Alert hidden={!this.state.success} variant="success" style={{ margin: '24px' }}>
                                    <Alert.Heading>Boa!</Alert.Heading>
                                    <p>
                                        Expedição cadastrada com sucesso!
                                    </p>
                                    <Button onClick={() => this.setState({ success: false })} variant="outline-success">
                                        Ok!
                                    </Button>
                                </Alert>
                                <Alert hidden={!this.state.error} variant="danger" style={{ margin: '24px' }}>
                                    <Alert.Heading>Ocorreu um erro! ):</Alert.Heading>
                                    <p>
                                        Algo de errado ocorreu! Tente novamente mais tarde!
                                    </p>
                                    <Button onClick={() => this.setState({ error: false })} variant="outline-danger">
                                        Ok :/
                                    </Button>
                                </Alert>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4" sm="12" xs="12">
                        <Card className="text-center">
                            <Card.Header style={{ 'backgroundColor': '#103a21', 'color': 'white' }}>Buscar Expedições</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Data de solicitação</Form.Label>
                                            <Form.Control type="date" id="filter_solicitation_date" placeholder="Insira a data" onChange={e => this.handleSolicitationDate()} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>Data de expedição</Form.Label>
                                            <Form.Control type="date" id="filter_expedition_date" placeholder="Insira a data" onChange={e => this.handleExpeditionDate()} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridDestiny">
                                            <Form.Label>Destino</Form.Label>
                                            <Form.Control id="filter_destination" type="text" placeholder="Insira o destino" onChange={e => this.handleDestination()} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridDestiny">
                                            <Form.Label>Número OM</Form.Label>
                                            <Form.Control type="text" id="filter_om_number" placeholder="Insira a OM" onChange={e => this.handleOmNumber()} />
                                        </Form.Group>
                                    </Form.Row>
                                    <div hidden={!this.state.loading_search} style={{ width: 'center', textAlign: 'center', margin: '8px' }}>
                                        <LoadingSpinner />
                                    </div>
                                    <Button variant="success" onClick={this.searchExpeditions} >Buscar</Button>
                                    <hr />
                                    <p>Importar multiplas expedições para o Banco</p>
                                    <SheetJSApp />
                                    <Button variant="success" onClick={this.exportFile} >Exportar planilha exemplo</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <hr />
                </Row>
                <Row style={{ 'margin-left': 0, 'margin-top': '24px', 'margin-right': 0 }}>
                    <Col>
                        <ListExpeditions data={this.state.data} filtros={this.state.filtros_search} />
                    </Col>
                </Row>

            </Grid>
        )
    }

}


export default Expedition
