/* eslint-disable */
import React, { Component } from "react";
import style from "./style.css";
import pandaLiga from "./pandaLiga3.jpg";
import workfinity from "./workfinity.png";
import amigoStone from "./amigoStone.png";
import adp from "./adp.png";
import workplace from "./workplace.jpg";
import oracle from "./oracle.png";
import stica from "./stica.png";
import stone from "./stone.png";
import checklist from "./checklist.png";
import safeangels from "./safe_angels.png";
import orelhao from "./orelhao2.png";
import pitstop from "./pitstop.png";
import ajudaai from "./ajudaai.png";
import chatliga from './chatliga.png';
import { Container, Col, Row } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

class UsefulLinks extends Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
      lastUpdated: undefined,
    };
  }

  componentDidMount() {
    this.refreshReportData();
  }

  refreshReportData() {
    this.setState({
      loaded: false,
      lastUpdated: undefined,
    });
  }

  refreshOptions() {
    this.setState({});
  }

  customCard(imagesrc, link, title, text) {
    return (
      <Card>
        <Card.Header style={{ height: "160px" }}>
          <div style={{ textAlign: "center" }}>
            <Image rounded fluid variant="top" src={imagesrc} />
          </div>
        </Card.Header>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{text}</Card.Text>
          <div style={{ textAlign: "center" }}>
            <Button variant="success" href={link} target="_blank">
              Visitar
            </Button>
          </div>
        </Card.Body>
      </Card>
    );
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Col md lg xl={3}>
            {this.customCard(pandaLiga, "http://bit.ly/Logform", "Liga", "Central de suporte da logística")}
          </Col>
          <Col md lg xl={3}>
            {this.customCard(
              adp,
              "https://www.adpweb.com.br/rhweb20/",
              "ADP",
              "Portal de RH. Contracheques, informe de rendimentos, benefícios..."
            )}
          </Col>
          <Col md lg xl={3}>
            {this.customCard(
              amigoStone,
              "https://amigo.stone.com.br/support/home",
              "Amigo/SOS",
              "STONE OFFICE SUPPORT - Solicitações de serviços e equipamentos"
            )}
          </Col>
          <Col md lg xl={3}>
            {this.customCard(stica, "http://stone.com.br/studa", "Studa", "Plataforma de cursos da Stone")}
          </Col>
        </Row>
        <Row>
          <Col md lg xl={3}>
            {this.customCard(
              checklist,
              "https://docs.google.com/forms/d/e/1FAIpQLSdTocVvqrTg11BmYY-PKdKCOZapfk-llMDQB2UiOjLVO_uAsw/viewform",
              "Checklist",
              "Primeiro teste de cartão"
            )}
          </Col>
          <Col md lg xl={3}>
            {this.customCard(
              workfinity,
              "https://stone.workfinity.com.br/",
              "Workfinity",
              "Sistema com os principais dados da Logística"
            )}
          </Col>
          <Col md lg xl={3}>
            {this.customCard(
              workplace,
              "https://stone-co.facebook.com/",
              "Workplace",
              "Ferramenta de colaboração e comunicação"
            )}
          </Col>
          <Col md lg xl={3}>
            {this.customCard(stone, "https://www.stone.com.br", "Stone", "Site oficial da Stone")}
          </Col>
        </Row>
        <Row>
          <Col md lg xl={3}>
            {this.customCard(
              oracle,
              "http://eeqr.fa.us6.oraclecloud.com/",
              "Oracle",
              "Aprovações de compras, passagens e pagamentos"
            )}
          </Col>
          <Col md lg xl={3}>
            {this.customCard(
              safeangels,
              "http://stone.com.br/safeangels",
              "Safe Angels",
              "Uma plataforma com todos os conteúdos que você precisa para se manter informado"
            )}
          </Col>
          <Col md lg xl={3}>
            {this.customCard(
              orelhao,
              "https://canalconfidencial.com.br/orelhaostone/",
              "Orelhão",
              "Canal exclusivo para comunicação segura de condutas antiéticas ou que violem os princípios éticos."
            )}
          </Col>
          <Col md lg xl={3}>
            {this.customCard(
              pitstop,
              "https://sites.google.com/stone.com.br/pitstop/",
              "Pit Stop",
              "Fique por dentro das novidades da logística"
            )}
          </Col>
        </Row>
        <Row>
          <Col md lg xl={3}>
            {this.customCard(
              ajudaai,
              "https://drive.google.com/drive/folders/1b9mnwk2qHzur2UwnEEAHhD2ta9xj0Rv2",
              "Ajuda Aí",
              "Cartilha da LIGA com a explicação dos fluxos de atendimento ao angel conforme o assunto"
            )}
          </Col>
          <Col md lg xl={3}>
          {this.customCard(
              chatliga,
              "http://lp.stone.com.br/atendimento-angels/",
              "Chat Liga",
              "Este é o espaço exclusivo para atendimento dos angels por todo o Brasil. "
            )}
          </Col>
          <Col md lg xl={3}></Col>
          <Col md lg xl={3}></Col>
        </Row>
      </Container>
    );
  }
}

export default UsefulLinks;
