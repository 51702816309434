import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

export default function CustomizedTable(props) {
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor:props.HeadColor?props.HeadColor:"white",
      color: props.fontColor?props.fontColor:"dark",
      fontSize: "0.9em",
    },
    body: {
      fontSize: "1.0em",
    },
  }))(TableCell);

  let subTitle = [];

  for (let i = 0; i < props.subTitle.length; i++) {
    subTitle.push(
      <StyledTableCell>
        {props.subTitle[i].icon} {props.subTitle[i].name}
      </StyledTableCell>
    );
  }

  return (
    <Box
      mx="auto"
      width={window.innerWidth < 1300 ? "100%" : "100%"}
      margin="1em"
      overflow="auto"
    >
      <Table>
        <TableHead>
          {props.title[0].name === ""?<TableRow>{subTitle}</TableRow>:
          <TableRow>
            <StyledTableCell
              align="center"
              className="titleClient"
              colSpan={props.colSpan}
            >
              {props.title[0].icon}
              {props.title[0].name}
            </StyledTableCell>
          </TableRow>
        }
        </TableHead>
        <TableBody>
          {props.title[0].name === ""?'':<TableRow>{subTitle}</TableRow>}
          {props.rows}
        </TableBody>
      </Table>
    </Box>
  );
}
