import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import React,{useState, useEffect} from 'react'
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Pagination } from 'antd';

export default function CustomizedTable(props){
  //Define Table Cells style 
    const StyledTableCell = withStyles(theme => ({
        head: {
          backgroundColor: 'white',
          color: 'dark',
          fontSize: '0.9em',
        },
        body: {
          fontSize: '0.8em'
        },
      }))(TableCell);

    //Create variable for table subTitles
    let subTitle = []

    for(let i = 0;  i < props.subTitle.length; i++){
        subTitle.push(<StyledTableCell className="subtitleTable">{props.subTitle[i].icon} {props.subTitle[i].name}</StyledTableCell>)
    }

    const [page, setPage] = useState(1)
    let [rows, setRows] = useState([])
    let [data, setData] = useState([])

     //Get data using paging to filter
      function defineData(){
        data = []
        for(let i = ((page * props.rowSpan)-props.rowSpan);i < props.data.length;i++)
        {let step = []
          for(let j = 0 ; j < (props.dataQtd === undefined? 3: props.dataQtd);j++){
              step.push( props.data[i][j])
          }
          data.push(
            step
          )
          if(i === page * props.rowSpan - 1 )
           break
        }
      }
      //Using filtered data to create table rows
      function defineRows(){
        rows = []
        for(let i = 0; i < data.length;i++){
          let step = []
          for(let j = 0 ; j < (props.dataQtd === undefined? 3: props.dataQtd);j++){
            step.push(
              <StyledTableCell>{data[i][j]}</StyledTableCell>
            )
          }
          rows.push(  
            <TableRow>              
            {step}
            </TableRow>  
          )
        } 
      }
      
      //execute in the first time
      defineData()
      defineRows()
      
    //When the page is changed, execute this one
    useEffect(()=>{
      defineData()
      defineRows()
    })
    
    return (
        <Box mx="auto" width={window.innerWidth < 1300 ? '100%' : '100%'} margin='1em' overflow='auto'>        
          <Table>
            {props.title !== undefined ?
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" className="titleClient" colSpan={props.colSpan}>{props.title[0].icon}{props.title[0].name}</StyledTableCell>
              </TableRow>
            </TableHead>:''}
            <TableBody>
            <TableRow>                      
              {subTitle}               
            </TableRow>                   
              {rows}
            </TableBody>           
           
          </Table>
          <br/>
          <div className="float-right">
            <Pagination defaultCurrent={1} page={page} total={props.data.length} defaultPageSize={props.rowSpan} onChange={setPage}/>
          </div>
        </Box>
      );
    
}