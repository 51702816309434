import React, { Component } from 'react'
import Graph from '../../../components/Logistic/graphic/graph'
import moment from 'moment'
import _ from 'lodash'
import { Col, Row } from 'antd'
import Box from '@material-ui/core/Box';
import StockSummaryTable from './summaryTable'
import '../style.css'

export class SummarySection extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          summary: this.props.summary,
          capexHistory: this.props.capexHistory
        }
      }

    capexHistoryGraph () {
      const { capexHistory } = this.state
  
      const graphObj = {
        data: {
          labels: [],
          datasets: [
            {
              label: 'Meta',
              backgroundColor: '#e74c3c',
              borderColor: '#e74c3c',
              hoverBackgroundColor: '#e74c3c',
              hoverBorderColor: '#e74c3c',
              data: [],
              type: 'line',
              pointRadius: 0,
              fill: false,
            },
            {
              label: 'Realizado',
              backgroundColor: '#28A745',
              borderColor: '#28A745',
              hoverBackgroundColor: '#28A745',
              hoverBorderColor: '#28A745',
              data: [],
              type: 'line',
              pointRadius: 0,
            },
          ]
        },
        options: {
          responsive: undefined,
          maintainAspectRatio: false,
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: (tooltipItem, data) => `${tooltipItem.yLabel.toFixed(1)}%`
            }
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              gridLines: {
                display: true,
              },
              ticks: {
                        callback: function (value) {
                          return value + '%'; // convert it to percentage
                      },
                    },
            }],
          },
          title: {
            display: true,
            fontColor: 'black',
            fontSize: 20,
            text: 'Eficiência do CAPEX',
            fontFamily: 'GoBold'
          },
          plugins: {
            datalabels: {
              font: {
                size: 0,
              },
              color: 'black',
            },
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'black',
          },
        },
      }
  
      _.orderBy(capexHistory, 'date').forEach((element) => {
        graphObj.data.labels.push(moment(element.date).format('DD/MM/YYYY'))
        graphObj.data.datasets[1].data.push(element.efficiency)
        graphObj.data.datasets[0].data.push(element.goal)
      })
  
  
  
        return (
          <Box>
            <Graph
              data={graphObj.data}
              legend={graphObj.legend}
              options={graphObj.options}
              width={window.innerWidth > 1000 ? window.innerWidth * 12.5 /24 : 400}
              height={window.innerWidth > 1000 ? 400 : 400}
            />
          </Box>)
    }


    render() {

      if (!this.state.summary) {
        return null
      }


        return (
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]} style={{marginTop: '1em'}}> 
              <Col md xl = {10}>
                <div style={{overflowX: 'auto'}} >
                  <StockSummaryTable summary={this.state.summary} />
                </div>
              </Col>
              <Col md lg xl = {14} sm xs = {24}>
                {this.capexHistoryGraph()}
              </Col>
          </Row>
        )
    }
}

export default SummarySection
