import React, { Component } from 'react'
import LogisticService from '../../core/services/LogisticService'
import CaptureMethodsTable from '../../core/models/CaptureMethodsTable'
import { Col} from 'react-bootstrap'
import TablePaging from '../../custom-components/CustomizedTablePaging'
import  Buyer  from './img/buyer.png'


class POSTable extends Component {

    constructor(props) {
  
        super(props)
    
        this.state = {
            stonecode:props.stonecode
        }
    
        this.refreshReportData = this.refreshReportData.bind(this)
      }
    
    refreshReportData() {
        this.setState({

        });

    
    
    LogisticService.getCaptureMethods(this.state.stonecode).then((captureMethosInfo) => {
        const capthureMethods = captureMethosInfo.data.data.map(captureMethod => new CaptureMethodsTable(captureMethod))
        let dataMethods = []

        for(var i = 0; i < capthureMethods.length;i++){
          dataMethods.push(
            [
              capthureMethods[i].serialNumber,
              capthureMethods[i].modelName,
              capthureMethods[i].statusName
            ]
          )
        }

        
        //Title
        let titleMethods = [{name:" Maquinas do Cliente",icon:<img src={Buyer} alt="Buyer" className="titleIconClient" />}]

        //Sub Titles
        let subTitleMethods = [{name:"Serial", icon:""},{name:"Modelo", icon:""},{name:"Status", icon:""}]



        const capthureMethodsData =
         <TablePaging
           data ={dataMethods}
           title ={titleMethods}
           subTitle ={subTitleMethods}
           colSpan ={"3"}
           totalPages = {(capthureMethods.length / 3) + (capthureMethods.length % 3 == 0 ? 0 : 1)}
           rowSpan = {3}
           >
         </TablePaging>

        this.setState({
          captureMethodsData: capthureMethodsData
        })
    })
    
    }

    componentDidMount() {
        this.refreshReportData()
    }

    render(){
        return(
            <Col className="customizedTable left"  xs sm md = "12" lg xl = "5.1" align="left" style={{marginTop: '5px'}}>
                {this.state.captureMethodsData}
            </Col>
        )
    }

      
}

export default POSTable