/* eslint-disable */

import React from 'react'
import { Bar, Doughnut, HorizontalBar } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import PluginChartJsDataLabel from 'chartjs-plugin-datalabels'
import 'chartjs-plugin-zoom'

export default class Graph extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      width: this.props.width,
      height: this.props.height,
      data: this.props.data,
      options: this.props.options,
      legend: this.props.legend,
      type: this.props.type? this.props.type:'Bar'
    }
  }
  render () {
    if (this.state.type == 'Doughnut'){
      return (
        <Doughnut
          data={this.state.data}
          options={this.state.options}
          legend={this.state.legend}
          width={this.state.width}
          height={this.state.height}
        />
      )
    }
    else if (this.state.type == 'horizontalBar'){
      return (
        <HorizontalBar
          data={this.state.data}
          options={this.state.options}
          legend={this.state.legend}
          width={this.state.width}
          height={this.state.height}
        />
      )
    }
    return (
      <Bar
        data={this.state.data}
        options={this.state.options}
        legend={this.state.legend}
        width={this.state.width}
        height={this.state.height}
      />
    )
  }
}

Graph.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({}),
  }).isRequired,
  options: PropTypes.shape({}),
  legend: PropTypes.shape({}),
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

Graph.defaultProps = {
  options: {},
  legend: {},
}
