/* eslint-disable */
import React, { createContext, useReducer } from "react";

const initialState = {
  loggedIn: false,
  sessionState: "active",
};

const store = createContext(initialState);

const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SESSION_EXPIRED":
        return { loggedIn: false, sessionState: "expired" };
      case "LOGIN":
        window.localStorage.setItem("token", `${action.token}`);
        window.localStorage.setItem("userEmail", `${action.email}`);
        return { loggedIn: true, sessionState: "active" };
      case "LOGOUT":
        window.localStorage.clear();
        return { loggedIn: false, sessionState: "unknown_user" };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
