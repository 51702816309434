import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
// import { useLocation } from "react-router-dom";
import MaterialTable from "material-table";
import LogisticService from "../../core/services/LogisticService";

const { TabPane } = Tabs;

const Ranking = (props) => {
  const [greenAngels, setGreenAngels] = useState(undefined);
  const [guardiansAngels, setGuardiansAngels] = useState(undefined);
  const [suppliersAngels, setSuppliersAngels] = useState(undefined);
  const [hostAngels, setHostAngels] = useState(undefined);
  const [liderDistrital, setLiderDistrital] = useState(undefined);

  const { ano, mes } = props.match.params;

  console.log(props);
  useEffect(() => {
    fetchRankingData("greenAngel");
  }, []);

  async function fetchRankingData(cadeira) {
    const response = await LogisticService.getRvRanking(ano, mes, cadeira);
    const data = response.data;

    if (cadeira === "greenAngel") {
      setGreenAngels(data);
    } else if (cadeira === "guardianAngel") {
      setGuardiansAngels(data);
    } else if (cadeira === "supplierAngel") {
      setSuppliersAngels(data);
    } else if (cadeira === "hostAngel") {
      setHostAngels(data);
    } else if (cadeira === "liderDistrital") {
      setLiderDistrital(data);
    }
  }

  function callback(key) {
    if (key === "greenAngel" && !greenAngels) {
      fetchRankingData(key);
    } else if (key === "guardianAngel" && !guardiansAngels) {
      fetchRankingData(key);
    } else if (key === "supplierAngel" && !suppliersAngels) {
      fetchRankingData(key);
    } else if (key === "hostAngel" && !hostAngels) {
      fetchRankingData(key);
    } else if (key === "liderDistrital" && !liderDistrital) {
      fetchRankingData(key);
    }
  }

  const prepararColunas = (data) => {
    const template = data[0];

    const colunasDisponiveis = Object.keys(template);

    const colunasParaDescartar = [
      "Ano",
      "Mês",
      "pilarAtendimentoIndicadores",
      "pilarAtivacaoIndicadores",
      "pilarEficienciaIndicadores",
      "pilarHostIndicadores",
      "pilarSegurancaIndicadores",
    ];

    const colunasParaExibir = colunasDisponiveis.filter(
      (coluna) => !colunasParaDescartar.includes(coluna)
    );

    const ordemDasColunas = [
      "Nome",
      "Polo",
      "Distrito",
      "Regional",
      "Pontuação Final",
      "Pilar Eficiência",
      "Pilar Atendimento",
      "Pilar Ativação",
      "Pilar Host",
      "Pilar Segurança",
    ];

    function sortFunc(a, b) {
      return ordemDasColunas.indexOf(a) - ordemDasColunas.indexOf(b);
    }

    let colunas = colunasParaExibir.map((coluna) => ({
      title: coluna,
      dataIndex: coluna,
      key: coluna,
      field: coluna,
      width: ["Nome", "Polo"].includes(coluna) ? 300 : 200,
    }));
    colunas.sort((a, b) => sortFunc(a.title, b.title));

    colunas.forEach((coluna) =>
      ["Nome", "Polo", "Distrito", "Regional"].includes(coluna.title)
        ? (coluna.sorting = false)
        : (coluna.sorting = true)
    );

    colunasDisponiveis.forEach((coluna) => {
      if (coluna.includes("Indicadores")) {
        const indicadores = JSON.parse(template[coluna]) || [];
        indicadores.forEach((indicador) =>
          colunas.push({
            title: indicador.nome,
            dataIndex: indicador.nome,
            key: indicador.nome,
            field: indicador.nome,
            align: "center",
            width: 150,
          })
        );
      }
    });

    colunas.forEach((coluna) => (coluna.filtering = false));

    colunas.unshift({
      title: "#",
      dataIndex: "position",
      key: "position",
      field: "position",
      filtering: false,
      align: "center",
      width: 100,
      render: (rowData) => {
        if (rowData.position === 1) {
          return (
            <span className="icon">
              <FontAwesomeIcon icon={faTrophy} color="gold" />
              {rowData.position}
            </span>
          );
        }
        if (rowData.position === 2) {
          return (
            <span className="icon">
              <FontAwesomeIcon icon={faTrophy} color="silver" />
              {rowData.position}
            </span>
          );
        }
        if (rowData.position === 3) {
          return (
            <span className="icon">
              <FontAwesomeIcon icon={faTrophy} color="orange" />
              {rowData.position}
            </span>
          );
        }

        return rowData.position;
      },
    });

    colunasDisponiveis.forEach((coluna) => {
      const dados = template[coluna];
      if (!dados) {
        colunas = colunas.filter((c) => c.title !== coluna);
      }
    });

    return colunas;
  };

  const prepararLinhas = (data) => {
    const dadosFormatados = data.map((obj) => {
      const position = data.indexOf(obj) + 1;

      const objetoFormatado = {};

      Object.keys(obj).forEach((key) => {
        const result = obj[key];
        if (Number(result) === result && result % 1 !== 0) {
          objetoFormatado[key] = result.toLocaleString("pt-BR", {
            minimumFractionDigits: 1,
            style: "percent",
          });
        } else if (key.includes("Indicadores")) {
          const indicadores = JSON.parse(result) || [];
          indicadores.forEach((indicador) => {
            objetoFormatado[indicador.nome] = indicador.atingimento
              ? indicador.atingimento.toLocaleString("pt-BR", {
                  minimumFractionDigits: 1,
                  style: "percent",
                })
              : "-";
          });
        } else {
          objetoFormatado[key] = result;
        }
      });

      objetoFormatado.position = position;
      return objetoFormatado;
    });

    return dadosFormatados;
  };

  const RankingTable = (props) => {
    const { innerHeight } = window;

    return (
      <MaterialTable
        columns={props.data ? prepararColunas(props.data) : []}
        data={props.data ? prepararLinhas(props.data) : []}
        isLoading={!props.data}
        title={props.title}
        options={{
          exportAllData: true,
          pageSize: 20,
          showTitle: true,
          padding: "dense",
          filtering: true,
          exportButton: true,
          maxBodyHeight: 0.6 * innerHeight,
          tableLayout: "fixed",
          columns: {
            align: "center",
          },
          // fixedColumns: {
          //   left: 5,
          //   right: 0,
          // },
        }}
        localization={{
          toolbar: {
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
            exportName: "Baixar CSV",
            exportTitle: "Download",
          },
          pagination: {
            labelRowsSelect: "linhas",
            labelDisplayedRows: "{from}-{to} de {count}",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
          },
          body: {
            emptyDataSourceMessage: "Nada para mostrar",
          },
        }}
      />
    );
  };

  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Green Angel" key="greenAngel">
        <RankingTable data={greenAngels} title="Green Angels" />
      </TabPane>
      <TabPane tab="Guardian Angel" key="guardianAngel">
        <RankingTable data={guardiansAngels} title="Guardian Angels" />
      </TabPane>
      <TabPane tab="Supplier Angel" key="supplierAngel">
        <RankingTable data={suppliersAngels} title="Supplier Angels" />
      </TabPane>
      <TabPane tab="Host Angel" key="hostAngel">
        <RankingTable data={hostAngels} title="Host Angels" />
      </TabPane>
      <TabPane tab="Distritos" key="liderDistrital">
        <RankingTable data={liderDistrital} title="Distritos" />
      </TabPane>
    </Tabs>
  );
};

export default Ranking;
