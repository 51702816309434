export const styles = {
  ExpansionPanel: {
    marginBottom: '5px'
  },
  ExpansionPanelSummary: {
    height: '4.5px', 
    backgroundColor:'#28a745', 
    color: 'white', 
    margin: '0px 0px', 
    padding: '0px 0px',
    fontSize: '0.9em',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    }
  },
  redDot: {
    height: '25px',
    width: '25px',
    backgroundColor: '#e74c3c',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 'thin',
    display: 'inline-block',
  },
  yellowDot: {
    height: '25px',
    width: '25px',
    backgroundColor: '#f1c40f',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 'thin',
    display: 'inline-block',
  },
  greenDot: {
    height: '25px',
    width: '25px',
    backgroundColor: '#2ecc71',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 'thin',
    display: 'inline-block',
  }
}