import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import React,{useState, useEffect} from 'react'
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Pagination } from 'antd';
import Trophy from './img/trophy.png'
import Silver from './img/medal_silver.png'
import Bronze from './img/medal_bronze.png'

export default function CustomizedTable(props){
  //Define Table Cells style 
    const StyledTableCell = withStyles(theme => ({
        head: {
          backgroundColor: 'white',
          color: 'dark',
          fontSize: '0.8em',
          whiteSpace: 'nowrap',
          margin:0
        },
        body: {
            textAlign: 'center',
            fontSize: '0.8em',
            whiteSpace: 'nowrap',
            padding:0
        },
      }))(TableCell);

    //Create variable for table subTitles
    let subTitle = []

    for(let i = 0;  i < props.subTitle.length; i++){
        subTitle.push(<StyledTableCell>{props.subTitle[i].icon} {props.subTitle[i].name}</StyledTableCell>)
    }

    const [page, setPage] = useState(1)
    let [rows, setRows] = useState([])
    let [data, setData] = useState([])

     //Get data using paging to filter
      function defineData(){
        data = []
        for(let i = ((page * props.rowSpan)-props.rowSpan);i < props.data.length;i++)
        {let step = []
          for(let j = 0 ; j < (props.dataQtd === undefined? 3: props.dataQtd);j++){
              step.push( props.data[i][j])
          }
          data.push(
            step
          )
          if(i === page * props.rowSpan - 1 )
           break
        }
      }
      //Using filtered data to create table rows
      function defineRows(){
        rows = []
        for(let i = 0; i < data.length;i++){
          let step = []
          for(let j = 0 ; j < (props.dataQtd === undefined? 3: props.dataQtd);j++){
            if(j == 0 && data[i][j] == 1 ){
                step.push(
                    <StyledTableCell>{<img className="trophyIcon" src={Trophy} />}</StyledTableCell>
                  )    
            }
            else if(j == 0 && data[i][j] == 2 ){
                step.push(
                    <StyledTableCell>{<img className="medalIcon" src={Silver} />}</StyledTableCell>
                  )    
            }
            else if(j == 0 && data[i][j] == 3 ){
                step.push(
                    <StyledTableCell>{<img className="medalIcon" src={Bronze} />}</StyledTableCell>
                  )    
            }
            else{
                step.push(
                    <StyledTableCell>{data[i][j]}</StyledTableCell>
                  )
            }
          }
          rows.push(  
            <TableRow>              
            {step}
            </TableRow>  
          )
        } 
      }
      
      //execute in the first time
      defineData()
      defineRows()
      
    //When the page is changed, execute this one
    useEffect(()=>{
      defineData()
      defineRows()
    })
    
    return (
        <Box mx="auto" width={window.innerWidth < 1300 ? '100%' : '100%'} margin='0.5em' overflow='auto'>     
            {props.title !== undefined ?
          <p align="center" sytle={{color:'black'}} colSpan={props.colSpan}>{props.title[0].icon}{props.title[0].name}</p>     
            :''}
          <Table>
            <TableBody>
            <TableRow style={{backgroundColor: '#bcf7a1'}}>                      
              {subTitle}               
            </TableRow>                   
              {rows}
            </TableBody>           
           
          </Table>
        </Box>
      );
    
}